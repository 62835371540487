import React, { useEffect, useState } from "react";
import { Card, Button, Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { userAuthorized } from "../../../actions/isAuthorized";

// import axios from "axios";

import { Redirect, Link } from "react-router-dom";

//CSS imports
import "../../../assets/scss/Login/enterPassword.scss";
import LeanmoreFooter from "../../LandingLearnMore/LeanmoreFooter";
import LoginNavbar from "../../LandingLearnMore/LoginNavbar";

//image import
import x from "../../../assets/svg/x.svg";

//axiosInstance import
import axiosInstance from "../../../axios/axios";

import { BASEURL } from "../../../config";

import { useTranslation } from "react-i18next";

const SendLinkPassword = (props) => {
  const [Email, setEmail] = useState("");
  const [sendlinkbtn, setSendLinkBtn] = useState(false);
  const [sendLinktoEmail, setSendLinkToEmail] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const googleResponse = useSelector((state) => state.googleResponse.response);
  const FBResponse = useSelector((state) => state.FbResponseData.response);
  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // const newUserToken = useSelector(state => state.newUserToken.data);
  const nexmoVerificationResponse = useSelector(
    (state) => state.nexmoVerificationResponse
  );
  const [userExternalId, setUserExternalId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [homePage, setHomePage] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  // useEffect(() => {
  //   if (FBResponse) {
  //     // setIsLoading(false)
  //     let user_external_id = FBResponse.data.user_external_id;
  //     setUserExternalId(user_external_id);
  //   } else if (googleResponse) {
  //     // setIsLoading(false)
  //     let user_external_id = googleResponse.data.user_external_id;
  //     setUserExternalId(user_external_id);
  //     // let token = googleResponse.data.login_type ? newUserToken.response.data.accessToken : googleResponse.data.accessToken
  //     // dispatch(getTokenDetails(user_external_id));
  //   } else if (nexmoVerificationResponse.response) {
  //     // setIsLoading(false)
  //     let user_external_id = nexmoVerificationResponse.response.data.login_type
  //       ? ""
  //       : nexmoVerificationResponse.response.data.user_external_id;
  //     setUserExternalId(user_external_id);
  //     // let token = nexmoVerificationResponse.response.data.login_type ? newUserToken.response.data.accessToken : nexmoVerificationResponse.response.data.accessToken
  //     // dispatch(getTokenDetails(user_external_id));
  //   }

  // }, []);
  useEffect(() => {
    console.log(isAuthorized);

    if (isAuthorized === true) setHomePage(true);
  });

  useEffect(() => {
    if (Email.length > 0) {
      setSendLinkBtn(true);
      // setSendLinkToEmail(true);
    } else {
      setSendLinkBtn(false);
      setSendLinkToEmail(false);
    }
  }, [Email]);

  const sendLink = (e, user_email) => {
    e.preventDefault();
    // console.log(`${user_email} ${userExternalId}`)
    return axiosInstance({
      method: "POST",
      url: `${BASEURL}/v1/login/resetpasswordlink`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        // userexternalid: userExternalId,
      },
      data: {
        user_email: user_email,
        // user_external_id: userExternalId,
      },
      withCredentials: true,
    })
      .then((response) => {
        return response;
        // setSuccessMessage(response.data.response.data.message);
        // setError(response.data.reponse.error);
      })
      .catch((err) => err.response);
  };

  const handleSendLink = async (e, user_email) => {
    let res = await sendLink(e, user_email);
    if (res) {
      if (
        res.data &&
        res.data.response &&
        res.data.response.statusCode === 200
      ) {
        setSuccessMessage(res.data.response.data.message);
        setSendLinkBtn(false);
        setError(false);
      } else if (
        res.data &&
        res.data.response &&
        res.data.response.statusCode === 400
      ) {
        setSuccessMessage("");
        setErrorMessage("Please enter a valid Email id");
        setError(true);
      } else {
        setError(true);
        setErrorMessage("Something went wrong. Please try again later");
      }
    } else {
      setError(true);
      setErrorMessage("Something went wrong. Please try again later");
    }
  };
  const handleClearUserDetail = () => {
    localStorage.clear(); //clear data from localstorage
    props.history.push("/login");
    window.location.reload(true);

    // window.location.reload(false);
  };

  return (
    <div className="password-container">
      <LoginNavbar />
      <Container>
        <div className="row">
          <div className="col-sm-12">
            <Card className="password-card">
              <Card.Header className="password-header">
                {t("forgotPassword.title")}
                {/* <Link to={successMessage ? "#" : "/login"} onClick={successMessage ? "" : handleClearUserDetail} className="password-close">
                  <img src={x} alt="close-button" />
                </Link> */}
                <Link
                  to="/login"
                  onClick={handleClearUserDetail}
                  className="password-close"
                >
                  <img src={x} alt="close-button" />
                </Link>
              </Card.Header>
              <Card.Body>
                <Card.Text className="email-text">
                  {t("forgotPassword.subTitle")}
                </Card.Text>
                <Form
                  className="password-form"
                  onSubmit={(e) => handleSendLink(e, Email)}
                >
                  <Form.Group className="email">
                    <Form.Label className="password-form-label">
                      {t("forgotPassword.email")}
                    </Form.Label>

                    <Form.Control
                      size="lg"
                      type="email"
                      id="myInput"
                      placeholder={t("forgotPassword.email")}
                      onChange={(e) => setEmail(e.target.value)}
                      value={Email}
                    />
                  </Form.Group>
                  {successMessage ? (
                    <p style={{ color: "#12944E", fontFamily: "DM Sans" }}>
                      {t("forgotPassword.resetLinkMessage")}
                    </p>
                  ) : null}
                  {error ? (
                    <p style={{ color: "red", fontFamily: "DM Sans" }}>
                      {errorMessage}
                    </p>
                  ) : null}
                  {sendlinkbtn ? (
                    <Button
                      className="password-enable-button"
                      type="submit"
                      style={{ marginTop: "40px" }}
                    >
                      {t("forgotPassword.sendLink")}
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="password-enable-button"
                      type="submit"
                      style={{ marginTop: "40px" }}
                    >
                      {t("forgotPassword.sendLink")}
                    </Button>
                  )}
                </Form>
                {sendLinktoEmail && (
                  <Redirect
                    to={{
                      pathname: "/reset-password",
                    }}
                  />
                )}
                {homePage && (
                  <Redirect
                    to={{
                      pathname: "/home",
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
      <LeanmoreFooter />
    </div>
  );
};
export default SendLinkPassword;
