import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastMessage = ({ image, message }) => {
  const Msg = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={image} alt="toast" />
      <p style={{ color: "black", margin: "0 1rem" }}>{message}</p>
    </div>
  );

  const displayMsg = () => {
    toast(<Msg />, {
      toastId: "007",
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // toast(Msg) would also work
  };

  return (
    <div>
      {displayMsg()}
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ToastMessage;

// const notify = () => toast('copied successfully!', {
//     position: "bottom-left",
//     autoClose: 5000,
//     hideProgressBar: true,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
// });
