import axios from 'axios';
import { BASEURL } from "../../config";
import { GET_OTHER_USER_PROFILE_DETAILS_BY_ID } from "../../constants/action-types";

export const getOtherUserDetailsByUserId = (otherUserExternalID) => dispatch => {
    axios({
        method: 'GET',
        url: `${BASEURL}/v1/users/displayOthersProfile/${otherUserExternalID}`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
    })
        .then(otherUserProfileResponse =>
            dispatch({
                type: GET_OTHER_USER_PROFILE_DETAILS_BY_ID,
                payload: otherUserProfileResponse.data.response
            }))
        .catch(err => console.error(err))
}

