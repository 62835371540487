import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { loadFbLoginApi } from "../../sdk/FaceBookSDK";
import { facebookSignUp } from "../../actions/login";
import { storeFacebookData } from "../../actions/storingUserData";

import { Button } from "react-bootstrap";
import "../../assets/scss/Login/facebookLogin.scss";
import { userFBLogin } from "../../api/UserLoginApi";
import { storeUserDetails } from "../../actions/storingUserData";
import { userAuthorized } from "../../actions/isAuthorized";

const FacebookLogin = (props) => {
  const [signedInWithFB, setsignedInWithFB] = useState(false);
  const [isloggedInUser, setLoggedInUser] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const dispatch = useDispatch(); //for action to happen in redux
  const history = useHistory();

  useEffect(() => {
    loadFbLoginApi();
  }, []);
  const userfbLogin = async (token) => {
    let res = await userFBLogin(token);
    if (res.response.statusCode == 200) {
      localStorage.setItem("accessToken", res.response.data.accessToken);
      localStorage.setItem("refreshToken", res.response.data.refreshToken);
      localStorage.setItem(
        "user_external_id",
        res.response.data.user_external_id
      );
      dispatch(storeUserDetails(res.response.data.user_profile));
      dispatch(userAuthorized(true));
      setLoggedInUser(true);
    } else if (res.response.statusCode == 404) {
      setNewUser(true);
      setTimeout(() => {
        return history.push("/"), localStorage.clear();
      }, 5000);
      window.location.reload();
    }
  };
  const checkLoginState = () => {
    window.FB.Event.subscribe(
      "auth.login",
      function (response) {
        if (response.authResponse) {
          var input_token = response.authResponse.accessToken; //token from facebook

          window.FB.api(
            "/me",
            { fields: "name, email, picture.width(200).height(200)" },
            function (response) {
              // console.log(response.picture)
              // let emailAddress = response.email
              const fbUser = {
                name: response.name,
                email: response.email,
                id: response.id,
                picture: response.picture.data.url,
              };
              if (props.isfblogin) {
                userfbLogin(input_token);
              }
              if (props.isfbSignUp === true) {
                dispatch(facebookSignUp(input_token));
                setsignedInWithFB(true);
              }
              //dispatch(facebookLogin(input_token)); //sending it to our app server
              dispatch(storeFacebookData(fbUser)); // storeFacebookData() is the action that will run
            }
          );
        } else {
          // alert("Cancelled by user");
        }
      },
      {
        scope: ["email", "public_profile"],
        return_scopes: true,
        auth_type: "rerequest",
      } //Ask for the email permission in the login process
    );
  };

  const handleFBLogin = () => {
    window.FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire.
        // props.history.push('/p')
        // console.log(response)
        // window.FB.login(checkLoginState());

        var input_token = response.authResponse.accessToken;
        window.FB.api(
          "/me",
          { fields: "name, email, picture.width(200).height(200)" },
          function (response) {
            // console.log(response.picture)
            // let emailAddress = response.email
            const fbUser = {
              name: response.name,
              email: response.email,
              id: response.id,
              picture: response.picture.data.url,
            };
            if (props.isfblogin) {
              userfbLogin(input_token);
            }
            if (props.isfbSignUp === true) {
              dispatch(facebookSignUp(input_token));
              setsignedInWithFB(true);
            }
            //setsignedInWithFB(true);
            // dispatch(facebookLogin(input_token)); //sending it to our app server
            dispatch(storeFacebookData(fbUser)); // storeFacebookData() is the action that will run
          }
        );
        // var uid = response.authResponse.userID;
      }
      // else
      //   if (response.status === 'not_authorized') {
      //   console.log('not_authorized')
      //   // The user hasn't authorized your application.  They
      //   // must click the Login button, or you must call FB.login
      //   // in response to a user gesture, to launch a login dialog.
      //   window.FB.login(checkLoginState());
      // }
      else {
        // console.log('last')
        window.FB.login(checkLoginState(), { scope: "email,public_profile" });
        // The user isn't logged in to Facebook. You can launch a
        // login dialog with a user gesture, but the user may have
        // to log in to Facebook before authorizing your application.
      }
    });
  };

  return (
    <>
      <Button
        className="facebook-login-media-button"
        onClick={handleFBLogin}
        disabled={props.cookiesNotAccepted ? true : false}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M22.6753 24C23.4068 24 23.9998 23.4069 23.9998 22.6754V1.32457C23.9998 0.592862 23.4067 0 22.6753 0H1.32457C0.592862 0 0 0.592952 0 1.32457V22.6753C0 23.4069 0.592862 23.9999 1.32457 23.9999H22.6753V24Z"
              fill="#485A96"
            />
            <path
              d="M16.5596 24V14.7059H19.6792L20.1462 11.0838H16.5595V8.77123C16.5595 7.72255 16.8506 7.00793 18.3545 7.00793L20.2725 7.00703V3.76746C19.9408 3.7234 18.8021 3.62476 17.4777 3.62476C14.7123 3.62476 12.819 5.3127 12.819 8.41261V11.0838H9.69141V14.7059H12.819V23.9999H16.5596V24Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Facebook
      </Button>
      {signedInWithFB && (
        <Redirect
          to={{
            pathname: "/profile-setup",
            state: { signedInWithFB: signedInWithFB },
          }}
        />
      )}
      {isNewUser ? (
        <Redirect
          to={{
            pathname: "/signup",
            state: "error",
          }}
        />
      ) : null}
      {isloggedInUser ? <Redirect to="/home" /> : null}
    </>
  );
};

export default FacebookLogin;
