import { useState, useEffect } from "react";
import { BASEURL } from "../../config";
import axios from "axios";

export default function useUserPosts(
  pageNo,
  dateValue,
  charityValue,
  deleteContent,
  editedSuccessfully,
  otherUserExternalId
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentsArray, setContentsArray] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setContentsArray([]);
  }, [charityValue, dateValue, otherUserExternalId, editedSuccessfully]);

  useEffect(() => {
    setContentsArray(
      contentsArray.filter((item) => item.content_external_id !== deleteContent)
    );
  }, [deleteContent]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (otherUserExternalId) {
      axios({
        method: "POST",
        url: `${BASEURL}/v1/getUserContent`,
        params: {
          page: pageNo,
          year: dateValue,
          cause_external_id: charityValue,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
        data: {
          user_external_id: otherUserExternalId,
        },
      })
        .then((response) => {
          // console.log(response);
          setLoading(true);
          setContentsArray((prevContentsArray) => {
            if (response.data.response.statusCode === 200) {
              return [...prevContentsArray, ...response.data.response.data];
            } else {
              return [...prevContentsArray];
            }
          });
          setHasMore(response.data.response.data.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
        });
    } else {
      axios({
        method: "POST",
        url: `${BASEURL}/v1/getUserContent`,
        params: {
          page: pageNo,
          year: dateValue,
          cause_external_id: charityValue,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
        data: {
          user_external_id: localStorage.getItem("user_external_id"),
        },
      })
        .then((response) => {
          setLoading(true);
          setContentsArray((prevContentsArray) => {
            if (response.data.response.statusCode === 200) {
              return [...prevContentsArray, ...response.data.response.data];
            } else {
              return [...prevContentsArray];
            }
          });
          setHasMore(response.data.response.data.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [
    pageNo,
    dateValue,
    charityValue,
    otherUserExternalId,
    editedSuccessfully,
  ]);
  return { loading, error, contentsArray, hasMore };
}
