import axiosCreateContent from "../axios/createContentAxios";
import axios from "axios";
import { BASEURL } from "../config";

// const getSignedURL = (token, external_Id, contentType, fileName) => {
//   return axiosCreateContent({
//     url: `${BASEURL}/v1/signedUrlForContents`,
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Access-Control-Allow-Origin": "*",
//       // 'Authorization': `Bearer ${token}`,
//       // 'userexternalid': external_Id,
//     },
//     data: {
//       fileName: fileName,
//       contentType: contentType,
//     },
//     withCredentials: true,
//   })
//     .then((res) => res.data)
//     .catch((err) => console.error(err));
// };

export const createPost = (dataToPost) => {
  return axiosCreateContent({
    url: `${BASEURL}/v1/content`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      user_id: dataToPost.user_external_id,
      cause_id: dataToPost.cause_id,
      country_id: dataToPost.country_id,
      content_description: dataToPost.description,
      content_album_id: dataToPost.album_id,
      files: dataToPost.files,
    },
    withCredentials: true,
  })
    .then((response) => response.data.response)
    .catch((err) => console.log(err));
};

export const deletePost = (content_external_id) => {
  axios({
    url: `${BASEURL}/v1/deleteContent/${content_external_id}`,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data.response.statusCode)
    .catch((err) => console.log(err));
};
