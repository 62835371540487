import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Image, Form, Button } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

import userImage1 from "../../assets/images/userImage1.png";
import userImage2 from "../../assets/images/userImage2.png";
import userImage3 from "../../assets/images/userImage3.png";
import Linkify from "linkifyjs/react";

//api imports
// import { userPosts } from '../../api/UserPosts';
// import { userPooledPosts } from '../../api/UsersPooledAPI';
import { userTaggedCauseList } from "../../api/UsersTaggedCauseApi";
// import { FollowUser } from '../../api/FollowUserAPI';
// import { UnfollowUser } from '../../api/UnFollowUserAPI';

//action imports
import { getFollowersList } from "../../actions/follower_action";
import { getFollowingList } from "../../actions/following_action";

//component imports
import SharingOnMedia from "../Contents/SharingOnMedia";
import Comments from "../Contents/Activity/comments";
import ReportPost from "../Contents/ReportPost";
import ContentSlider from "../Contents/Activity/ContentSlider";
import EmojiSlider from "../EmojiSlider/EmojiSlider";
// import useOtherUserPosts from "../../actions/customHooks/useOtherUserPosts";
import useUserPosts from "../../actions/customHooks/useUserPosts";
import PeoplePooledModal from "../UserProfile/PeoplePooledModal";

//css imports
import "../../assets/scss/OtherUserProfile/otherUserProfile.scss";
import "../../assets/scss/Contents/activityPage.scss";
import "../../assets/scss/OtherUserProfile/otherUserFollow.scss";
import "../../assets/scss/postContent.scss";
// import '../../assets/scss/UserProfile/profile.scss';

//png and svg imports from assets
import CharityLogo from "../../assets/images/charity logo.png";
import avatar from "../../assets/images/avatar.png";
import x from "../../assets/svg/x.svg";

//css imports
import "../../assets/scss/UserProfile/profile.scss";
import "../../assets/scss/Contents/activityPage.scss";
import "../../assets/scss/UserProfile/follow.scss";
import pooledCollect from "../../assets/svg/pooledCollect.svg";
import CharityInfo from "../CharityInfo/CharityInfo";
import { useTranslation } from "react-i18next";

/*Style applied to body element */
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
/*end**********************/
const OtherUserProfile = ({ otherUserExternalId }) => {
  // const userProfileData = useSelector((state) => state.userProfileDetails.data);
  // const otherUserExternalId = useSelector(state => state.otherUserProfileDetails.data.userDetails);
  // const passwordDetails = useSelector((state) => state.verifyEnteredPassword.data);
  const userDetails = useSelector((state) => state.userDetails);
  const charitiesList = useSelector((state) => state.charityNames.data);

  const [pageNo, setPageNo] = useState(1);
  const [showCharityDes, setShowCharityDes] = useState();

  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const len = 90;
  // const userexternalid = `${localStorage.getItem('user_external_id')}`
  const [charity, setCharity] = useState("");
  const [date, setDate] = useState("");
  // const [showReport, setShowReport] = useState(false);
  // const [userPostData, setUserPostData] = useState([]);
  // const [usersPooledData, setUserPooledData] = useState([]);
  // const [pooledCount, setPooledCount] = useState(null);
  const [Commentsection, setCommentSection] = useState(-1);
  const [showUserDeletebtn, setDeleteBtn] = useState(true);
  const [userTaggedCause, setUserTaggedCause] = useState([]);
  const [showmodal, setShowModal] = useState(false);

  const [contentExternalId, setcontentExternalId] = useState(null);
  const dispatch = useDispatch();

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const observer = useRef();

  // let user_country = passwordDetails.response.data.user_profile.country_name;

  // useEffect(() => {
  //     async function fetchData() {
  //         let charityValue = charity ? charity : null;
  //         let dateValue = date ? date : null;
  //         let UserPostResponse = await userPosts(userDetails.user_external_id, otherUserExternalId.user_external_id, dateValue, charityValue);
  //         // console.log(UserPostResponse);
  //         setUserPostData(UserPostResponse.data);
  //     }
  //     fetchData();

  // }, [charity, date])

  let charityValue = charity ? charity : null;
  let dateValue = date ? date : null;

  useEffect(() => {
    setPageNo(1);
  }, [otherUserExternalId]);

  const { loading, error, contentsArray, hasMore } = useUserPosts(
    pageNo,
    dateValue,
    charityValue,
    null,
    null,
    otherUserExternalId
  );
  // console.log(contentsArray);

  const lastElementInContent = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNo((prevpageNo) => prevpageNo + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  useEffect(() => {
    setPageNo(1);
  }, [charityValue, dateValue]);

  // useEffect(() => {
  //     const userContent = async () => {
  //         let UserPostResponse = await userPosts(userDetails.user_external_id, otherUserExternalId.user_external_id);
  //         setUserPostData(UserPostResponse.data);
  //     }
  //     userContent();
  // }, [otherUserExternalId.user_external_id])

  useEffect(() => {
    async function LoadCharitybasedOnUserTagged() {
      let charityList = await userTaggedCauseList(otherUserExternalId);
      if (charityList.data.length > 0) {
        setUserTaggedCause(charityList.data);
      } else {
        setUserTaggedCause([]);
      }
    }
    LoadCharitybasedOnUserTagged();
  }, []);

  const ShowComments = (index) => {
    setCommentSection(index);
  };
  const handleModal = (contentExternalId) => {
    setcontentExternalId(contentExternalId);
    setShowModal(!showmodal);
  };
  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return (
          <span className="timeline">
            {month} {t("post.monthAgo")}
          </span>
        );
      } else {
        return (
          <span className="timeline">
            {month} {t("post.monthsAgo")}
          </span>
        );
      }
    } else if (days) {
      if (days === 1)
        return (
          <span className="timeline">
            {days} {t("post.dayAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {days} {t("post.daysAgo")}
        </span>
      );
    } else if (hours) {
      if (hours === 1)
        return (
          <span className="timeline">
            {hours} {t("post.hourAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {hours} {t("post.hoursAgo")}
        </span>
      );
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return (
          <span className="timeline">
            {minutes} {t("post.minuteAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {minutes} {t("post.minutesAgo")}
        </span>
      );
    }
  };

  let activityCards = contentsArray.map((item, index) => {
    if (contentsArray.length === index + 1) {
      return (
        <>
          <Card
            className="postcontent-card"
            style={{ marginTop: "1rem" }}
            key={item.content_external_id}
            key={index}
            ref={lastElementInContent}
          >
            <Card.Header className="postcontent-header">
              <div className="pic-username-report">
                {/* <span className="image-display-pic" style={{ verticalAlign: "top" }}> */}
                <Image
                  className="profile-picture"
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${otherUserExternalId}.jpg`}
                  roundedCircle
                  style={{ width: "51px", height: "51px" }}
                />
                {/* </span> */}
                {/* {item.user_profile_pic !== null ? <Image className="profile-picture" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                                  <Image src={avatar} alt="profile-photo" className="profile-picture" roundedCircle style={{ width: "51px", height: "51px" }} />} */}
                {/* <Image className="profile-picture" src={avatar} roundedCircle /> */}
                <ul>
                  <li className="postcontent-username">{item.username}</li>
                  <li className="postcontent-duration">
                    {getPostedTime(item.content_created_on)}
                  </li>
                </ul>
                <ReportPost contentExternalId={item.content_external_id} />
              </div>
            </Card.Header>
            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && (
                    <Card.Text>
                      <Linkify tagName="span">
                        {item.content_description}
                      </Linkify>
                    </Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description.substring(0, len) + "..."}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          {t("post.seeMore")}
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          {t("post.seeLess")}
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>

            {/* <br /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "49px",
                padding: "0 1rem",
              }}
            >
              <div>
                <div className="profile-comment-pooled-raised">
                  {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                  <div
                    onClick={() => {
                      handleModal(item.content_external_id);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div className="userImageWrapper">
                      <ul className="hx_flex-avatar-stack">
                        {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                              .slice(0, 4)
                              .map((people_pooled, index) => {
                                return (
                                  <li className="hx_flex-avatar-stack-item">
                                    <img
                                      className="avatar avatar-user"
                                      alt="@jqn"
                                      onError={(ev) =>
                                        (ev.target.src = `${avatar}`)
                                      }
                                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                      width="20"
                                      height="20"
                                    />
                                  </li>
                                );
                              })
                          : ""}
                      </ul>
                    </div>
                    <span className="profile-pooled">
                      {item.total_people_pooled} {t("post.peoplePooled")}
                    </span>
                  </div>
                  <span style={{ margin: "0 0.5rem" }}> • </span>
                  <img src={pooledCollect} alt="pooledCollect.svg" />

                  <p className="profile-pooled">
                    <span className="profile-user-pooled-value">
                      {userDetails.country_wallet_currency_sign}
                      {item.total_amount_pooled.toFixed(2)}
                    </span>
                    {t("post.poolCollected")}
                  </p>
                  {/* <span className="profile-pooled"> Pool collected</span> */}
                </div>
              </div>
              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}
                  {item.totalcomments > "1"
                    ? t("post.commentPlural")
                    : t("post.commentSingular")}
                </span>
              </div>
            </div>
            <div className="profile-charity">
              <img
                className="profile-charity-logo"
                src={item.cause_image}
                onError={(ev) => (ev.target.src = `${CharityLogo}`)}
              />
              <ul>
                <li className="postcontent-charityName">{item.cause_name}</li>
                <li className="postcontent-charityLocation">
                  {item.country_name}
                </li>
              </ul>
              {charitiesList.map((charity) => {
                if (item.cause_name == charity.cause_name) {
                  return (
                    <>
                      <CharityInfo
                        contentExternalID={item.content_external_id}
                        causeImage={charity.cause_image}
                        causeName={charity.cause_name}
                        causeCountryName={charity.country_name}
                        causeDescription={charity.cause_description}
                        causeURL={charity.cause_url}
                      />
                    </>
                  );
                }
              })}
            </div>
            {/* < div className="comments-share" onClick={() => { ShowComments(index) }}> */}
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.haspooled}
              goldenBuzzer={item.has_golden_buzzered}
            />

            <div className="comments-share">
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
              />
              {Commentsection == index ? (
                <Comments
                  contentExternalId={item.content_external_id}
                  isUserComments={showUserDeletebtn}
                />
              ) : (
                ""
              )}
              {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
            </div>
            {/* <SharingOnMedia url={item.content_url.signedUrl} text={item.content_description} /> */}
          </Card>
        </>
      );
    } else if(item !== null){
      return (
        <>
          <Card className="postcontent-card" key={item.content_external_id}>
            <Card.Header className="postcontent-header">
              <div className="pic-username-report">
                {/* <span className="image-display-pic" style={{ verticalAlign: "top" }}> */}
                <Image
                  className="profile-picture"
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${otherUserExternalId}.jpg`}
                  roundedCircle
                  style={{ width: "51px", height: "51px" }}
                />
                {/* </span> */}
                {/* {item.user_profile_pic !== null ? <Image className="profile-picture" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                                  <Image src={avatar} alt="profile-photo" className="profile-picture" roundedCircle style={{ width: "51px", height: "51px" }} />} */}
                {/* <Image className="profile-picture" src={avatar} roundedCircle /> */}
                <ul>
                  <li className="postcontent-username">{item.username}</li>
                  <li className="postcontent-duration">
                    {getPostedTime(item.content_created_on)}
                  </li>
                </ul>
                <ReportPost contentExternalId={item.content_external_id} />
              </div>
            </Card.Header>
            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && (
                    <Card.Text>
                      <Linkify tagName="span">
                        {item.content_description}
                      </Linkify>
                    </Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description.substring(0, len) + "..."}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          {t("post.seeMore")}
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          {t("post.seeLess")}
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>

            {/* <br /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "49px",
                padding: "0 1rem",
              }}
            >
              <div>
                <div className="profile-comment-pooled-raised">
                  {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                  <div
                    onClick={() => {
                      handleModal(item.content_external_id);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div className="userImageWrapper">
                      <ul className="hx_flex-avatar-stack">
                        {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                              .slice(0, 4)
                              .map((people_pooled, index) => {
                                return (
                                  <li className="hx_flex-avatar-stack-item">
                                    <img
                                      className="avatar avatar-user"
                                      alt="@jqn"
                                      onError={(ev) =>
                                        (ev.target.src = `${avatar}`)
                                      }
                                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                      width="20"
                                      height="20"
                                    />
                                  </li>
                                );
                              })
                          : ""}
                      </ul>
                    </div>
                    <span className="profile-pooled">
                      {item.total_people_pooled} {t("post.peoplePooled")}
                    </span>
                  </div>
                  <span style={{ margin: "0 0.5rem" }}> • </span>
                  <img src={pooledCollect} alt="pooledCollect.svg" />

                  <p className="profile-pooled">
                    <span className="profile-user-pooled-value">
                      {userDetails.country_wallet_currency_sign}
                      {item.total_amount_pooled.toFixed(2)}{" "}
                    </span>
                    {t("post.poolCollected")}
                  </p>
                  {/* <span className="profile-pooled"> Pool collected</span> */}
                </div>
              </div>
              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}
                  {item.totalcomments > "1"
                    ? t("post.commentPlural")
                    : t("post.commentSingular")}
                </span>
              </div>
            </div>
            <div className="profile-charity">
              <img
                className="profile-charity-logo"
                src={item.cause_image}
                onError={(ev) => (ev.target.src = `${CharityLogo}`)}
              />
              <ul>
                <li className="postcontent-charityName">{item.cause_name}</li>
                <li className="postcontent-charityLocation">
                  {item.country_name}
                </li>
              </ul>
              {charitiesList.map((charity) => {
                if (item.cause_name == charity.cause_name) {
                  return (
                    <>
                      <CharityInfo
                        contentExternalID={item.content_external_id}
                        causeImage={charity.cause_image}
                        causeName={charity.cause_name}
                        causeCountryName={charity.country_name}
                        causeDescription={charity.cause_description}
                        causeURL={charity.cause_url}
                      />
                    </>
                  );
                }
              })}
            </div>
            {/* < div className="comments-share" onClick={() => { ShowComments(index) }}> */}
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.haspooled}
              goldenBuzzer={item.has_golden_buzzered}
            />

            <div className="comments-share">
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
              />
              {Commentsection == index ? (
                <Comments
                  contentExternalId={item.content_external_id}
                  isUserComments={showUserDeletebtn}
                />
              ) : (
                ""
              )}
              {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
            </div>
            {/* <SharingOnMedia url={item.content_url.signedUrl} text={item.content_description} /> */}
          </Card>
        </>
      );
    }
  });

  const showMessage = () => {
    if (loading) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>Please Wait...</div>
      );
    }
    if (error) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>
          Something went wrong. Please try reloading the page to logging in
          again
        </div>
      );
    }
    if (!hasMore) {
      return <div style={{ height: "50px", fontWeight: "700" }}></div>;
    }
  };

  const handleCharityInfo = (contentExternalID) => {
    setShowCharityDes(contentExternalID);
    // console.log(`${description}`)
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <span className="profile-heading-post">{t("profile.posts")}</span>
        <div style={{ display: "flex" }}>
          <Form.Control
            className="dropdown-btn"
            as="select"
            onChange={(e) => setCharity(e.target.value)}
            value={charity}
            style={{ backgroundColor: "#e5e5e5" }}
          >
            <option value="">{t("profile.filters.charity")}</option>
            {userTaggedCause.map((cause, index) => {
              return (
                <option
                  value={cause.cause_external_id}
                  key={cause.cause_external_id}
                >
                  {cause.cause_name}
                </option>
              );
            })}
          </Form.Control>
          <Form.Control
            className="dropdown-btn"
            as="select"
            onChange={(e) => setDate(e.target.value)}
            value={date}
            style={{ backgroundColor: "#e5e5e5", marginLeft: "2px" }}
          >
            <option defaultValue>
              {t("profile.filters.duration.placeholder")}
            </option>
            <option value="1">{t("profile.filters.duration.today")}</option>
            <option value="7">{t("profile.filters.duration.thisWeek")}</option>
            <option value="30">
              {t("profile.filters.duration.thisMonth")}
            </option>
            <option value="90">{t("profile.filters.duration.3months")}</option>
          </Form.Control>
        </div>
      </div>
      {activityCards}
      {showMessage()}
      {showmodal ? (
        <PeoplePooledModal contentExternalId={contentExternalId} />
      ) : null}
    </>
  );
};
export default OtherUserProfile;
