import axios from 'axios';
import { BASEURL } from "../config";
import { GET_FOLLOWERS_LIST, GET_FOLLOWERS_COUNT } from "../constants/action-types";

export const getFollowersList = (otherExternalId) => dispatch => {
    if (otherExternalId) {
        axios({
            method: 'GET',
            url: `${BASEURL}/v1/followers`,
            params: {
                user_external_id: otherExternalId,
            },
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                // 'Authorization': `Bearer ${token}`,
                // 'userexternalid': externalId
            },
            withCredentials: true,
        })
            .then(followerResponse =>
                dispatch({
                    type: GET_FOLLOWERS_LIST,
                    payload: followerResponse.data.response
                }))
            .catch(err => console.error(err))

    }
    else {
        axios({
            method: 'GET',
            url: `${BASEURL}/v1/followers`,
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                // 'Authorization': `Bearer ${token}`,
                // 'userexternalid': externalId
            },
            withCredentials: true
        })
            .then(followerResponse =>

                dispatch({
                    type: GET_FOLLOWERS_LIST,
                    payload: followerResponse.data.response
                }))
            .catch(err => console.error(err))

    }
}




