import React, { Component, createRef } from "react";
import { Image } from "react-bootstrap";
import avatar from "../../../assets/images/avatar.png";
// localhost:3001
class ReplyCommentUi extends Component {
    constructor(props) {
        super(props);

        this.textareaRef = createRef()

        this.state = {
            replyCommentarr: props.replyComment,
            newRepliedComment: "",
            commentExternalId: props.commentExternalId,
            indexValue: props.replyBtn,
            contentExternalId: props.contentExternalId,
            repliedusername: props.userName,
            isreplyPost: false
        };

        this.handleKeyUp = this.handleKeyUp.bind(this);
    }
    handleRepliedComment = (e) => {
        this.setState({
            newRepliedComment: e.target.value
        }, () => {
            localStorage.setItem('userComment', this.state.newRepliedComment);
        })
    }


    handleReplyComments = (e) => {
        console.log("this.state.newRepliedComment", this.state.newRepliedComment);
        e.preventDefault();
        this.props.PostReplyComment(this.state.newRepliedComment, this.state.commentExternalId, this.state.isreplyPost)
        this.props.showComment(this.state.contentExternalId);
        this.setState({
            newRepliedComment: ""
        })
    }

    handleKeyUp() {
        var box = this.textareaRef.current;
        let height = box.offsetHeight;

        if (height < box.scrollHeight) {
            box.style.height = box.scrollHeight + "px";
        }
    }

    render() {
        return (
            <>
                < div className="comment-section-reply-item">
                    <Image
                        onError={(ev) => (ev.target.src = `${avatar}`)}
                        src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${localStorage.getItem(
                            "user_external_id"
                        )}.jpg`}
                        roundedCircle
                        width="32"
                        height="32"
                    />
                    <form onSubmit={(e) => this.handleReplyComments(e)}>
                        <textarea
                            ref={this.textareaRef}
                            onKeyUp={this.handleKeyUp}
                            rows={1}
                            value={this.state.newRepliedComment}
                            placeholder={`Reply to ${this.state.repliedusername}`}
                            className="comment-section-input"
                            onChange={this.handleRepliedComment}
                        />
                        <button type="submit" className="comment-post-btn">
                            Post
                    </button>
                        <div></div>
                    </form>
                </div >
            </>
        );
    }
}
export default ReplyCommentUi;
