import firebase from 'firebase';
var firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: "gopoolit.firebaseapp.com",
    databaseURL: "https://gopoolit.firebaseio.com",
    projectId: "gopoolit",
    storageBucket: "gopoolit.appspot.com",
    messagingSenderId: "9508555666",
    appId: "1:9508555666:web:a65f8588a623311183cf4b",
    measurementId: "G-HPG4X996BV"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;