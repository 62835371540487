import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import { NavLink, useLocation, withRouter, useHistory } from "react-router-dom";
import { InputBase, Button } from "@material-ui/core";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

import Logout from "../Logout";
// import Loader from "../Loader";
// import { getTokenDetails } from "../../actions/getTokenDetails";
// import { userProfileDetails } from '../../actions/userProfile_action';
// import CreateContent from "../../components/CreateContent/CreateContent";
import CreateContentOld from "../../components/CreateContent/CreateContentOld";
// import UnAuthContentNavbar from "./UnAuthContentNavbar";
// import SearchComponent from "./SearchComponent";
import x from "../../assets/svg/x.svg";
import brandLogo from "../../assets/svg/brandLogo.svg";
import caret from "../../assets/svg/caret.svg";
import avatar from "../../assets/images/avatar.png";
import "../../assets/scss/ContentNavBar/contentNavBar.scss";
// import data from "../Notifications/notifications_data.json";
import { GetNotification } from "../../api/NotificationApi";
// import { walletApi } from "../../api/WalletAPI";
import Feedback from "../../components/SettingsPrivacy/Feedback";
import { userBalance } from "../../actions/storingUserData";
import { storeAllowedCountriesList } from "../../actions/countryList";
import { getPreferences } from "../../api/PreferencesApi";
import { hashTagInfo } from "../../actions/hashtags/hashTagInfo";
import { storeUserDefaultAmount } from "../../actions/storingUserData";
import Tour from "../Tour/Tour";
import { showCreatePost } from "../../actions/createPost";
import SearchComponent from "../ContentNavbar/SearchComponent";
import { searchInfo } from "../../actions/searchComponents/searchInfo";
import search from "../../assets/svg/search.svg";
import { useTranslation } from "react-i18next";
// import classes from "*.module.css";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    width: "100%",
    padding: "5px 20px",
    borderBottom: "1px solid #ccc",
  },
}));

const ContentNavBar = (props) => {
  const classes = useStyles();

  const path = props.location.pathname;
  const userExternalId = `${localStorage.getItem("user_external_id")}`;
  const [userName, setUserName] = useState("");
  const [newNotification, setNewNotification] = useState([]);
  const [oldNotification, setOldNotification] = useState([]);
  const [showMsg, setShowMsg] = useState("");
  const [notificationButtonActive, setNotificationButtonActive] = useState(
    false
  );
  const [mobileNotification, setMobileNotification] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [show, setShow] = useState(false);
  const showBalance = useSelector((state) => state.ShowBalanceReducer.show);
  const profiledetails = useSelector((state) => state.profileDetailsById);
  const userProfileData = useSelector((state) => state.userProfileDetails.data);
  const userDetails = useSelector((state) => state.userDetails);
  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );
  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );
  const showCreateContent = useSelector(
    (state) => state.CreatePostPopup.showPopup
  );

  const SearchInfo = useSelector((state) => state.searchInfo);
  const { searchItem } = SearchInfo;

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const { pathname } = useLocation();
  const dispatch = useDispatch(); //Used to dispatch the actions from the component
  const history = useHistory();
  const [showBalanceOnNavbar, setShowBalanceOnNavbar] = useState(showBalance);
  const [balance, setBalance] = useState("");
  const [countryCurrencySign, setCountryCurrencySign] = useState("");

  const userDetailsCurrency = useSelector(
    (state) => state.userDetails.country_wallet_currency_sign
  );

  const balanceDetails = useSelector((state) => state.BalanceReducer);
  const [showSearchInput, setShowSearchInput] = useState(true);

  const searchInputRef = useRef(createRef());

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (
      path == "/otheruserprofile" ||
      path == "/home" ||
      path == "/settingAndPrivacy" ||
      /^\/share/.test(path)
    ) {
      async function fetchData() {
        const res = await getPreferences();
        if (res.response.statusCode === 200) {
          setShowBalanceOnNavbar(
            res.response.data.settings.settings_wallet_icon
          );
          dispatch(
            storeUserDefaultAmount(
              res.response.data.settings.settings_pooling_amount
            )
          );
        }
      }
      fetchData();
    }
  }, [showBalance]);

  useEffect(() => {
    dispatch(storeAllowedCountriesList());
    dispatch(userBalance());

    setCountryCurrencySign(userDetailsCurrency);
    setBalance(balanceDetails.balance);
  }, [balanceDetails.balance]);

  const handleNotification = () => {
    if (authorizedUser) {
      setNotificationButtonActive(!notificationButtonActive);
    } else {
      props.history.push("/login");
    }
  };

  const handleShowMobileNotification = () => {
    setMobileNotification(!mobileNotification);
    // setButtonActive(true);
  };

  const buttonActiveStyle = buttonActive ? "create-active-button" : "";

  useEffect(() => {
    async function fetchNotification() {
      if (isAuthorized) {
        let res = await GetNotification();
        if (res) {
          if (res.length > 0) {
            let currentDate = new Date();
            let day = currentDate.getDate();
            let month = currentDate.getMonth() + 1;
            let year = currentDate.getFullYear();
            if (day < 10) {
              day = "0" + day;
            }
            if (month < 10) {
              month = "0" + month;
            }
            let Today = year + "-" + month + "-" + day;
            let Newnotify = res.filter((item) => {
              let data = item.notification_created_on.split("T");
              let newdate = data[0];
              return newdate == Today;
            });
            setNewNotification(Newnotify);
            let oldNotify = res.filter((item) => {
              let data = item.notification_created_on.split("T");
              let newdate = data[0];
              return newdate != Today;
            });
            setOldNotification(oldNotify);
          } else {
            setShowMsg("You have no new notification yet");
            setOldNotification([]);
            setNewNotification([]);
          }
        }
      }
    }
    fetchNotification();
  }, [isAuthorized]);

  const BodyStyle = () => {
    useEffect(() => {
      // document.body.classList.add(MODAL_OPEN_CLASS);
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return (
          <span className="timeline">
            {month} {t("contentNavBar.monthAgo")}
          </span>
        );
      } else {
        return (
          <span className="timeline">
            {month} {t("contentNavBar.monthsAgo")}
          </span>
        );
      }
    } else if (days) {
      if (days === 1)
        return (
          <span className="timeline">
            {days} {t("contentNavBar.dayAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {days} {t("contentNavBar.daysAgo")}
        </span>
      );
    } else if (hours) {
      if (hours === 1)
        return (
          <span className="timeline">
            {hours} {t("contentNavBar.hourAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {hours} {t("contentNavBar.hoursAgo")}
        </span>
      );
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return (
          <span className="timeline">
            {minutes} {t("contentNavBar.minuteAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {minutes} {t("contentNavBar.minutesAgo")}
        </span>
      );
    }
  };

  const handleLogoClick = () => {
    dispatch(searchInfo(false, null));
  };

  const handleHomeButton = () => {
    dispatch(hashTagInfo(false, null, 0));
    dispatch(searchInfo(false, null));
  };

  const handleShow = () => {
    if (!showCreateContent) {
      dispatch(showCreatePost(true));
      console.log("inside");
    }
    console.log("outside");
  };

  const handleHide = () => {
    dispatch(showCreatePost(false));
  };

  const handleSearch = () => {
    setShowSearchInput(!showSearchInput);

    toggleSearch();
  };

  const toggleSearch = () => {
    const searchInput = searchInputRef.current;

    if (showSearchInput === true) {
      searchInput.style.display = "block";
    } else if (showSearchInput === false) {
      searchInput.style.display = "none";
    }
  };

  const [searchValue, setSearchValue] = useState(
    searchItem !== null ? searchItem : ""
  );

  const handleSearchField = async (e) => {
    e.preventDefault();
    const searchText = searchValue.trim();

    if (searchText.length === 0) {
      // alert("Type something in search");
      dispatch(searchInfo(false, null));
    } else {
      dispatch(searchInfo(true, searchText));
      // const postsData = await getSearchPost(searchText);
      // console.log(postsData);
      // dispatch(searchPosts(searchText));
      // dispatch(searchCharity(searchText));
      // dispatch(searchUsers(searchText));
    }
  };

  //to get
  // useEffect(() => {
  //   if (profiledetails) {
  //     if (profiledetails.data) {
  //       setUserName(true);
  //     }
  //   }
  // }, [profiledetails]);

  // useEffect(() => {
  //   if (!tokenDetails.token) {
  //     let token = localStorage.getItem('accessToken')
  //     let refreshToken = localStorage.getItem('refreshToken')
  //     let user_external_id = localStorage.getItem('user_external_id');
  //     dispatch(getTokenDetails(token, refreshToken, user_external_id));
  //   }
  // }, [])

  // if (!tokenDetails) return <Loader />;

  // useEffect(() => {
  //   dispatch(userProfileDetails(tokenDetails.token, tokenDetails.user_external_id));
  // }, [])

  // useEffect(() => {
  //   // if (!tokenDetails.token) {
  //   if (FBResponse) {
  //     let token = FBResponse.data.user_external_id ? passwordDetails.response.data.accessToken : (newUserToken ? newUserToken.response.data.accessToken : <Loader />)
  //     let user_external_id = FBResponse.data.user_external_id ? FBResponse.data.user_external_id : (newUserToken ? newUserToken.response.data.user_external_id : <Loader />)
  //     let refreshToken = FBResponse.data.user_external_id ? passwordDetails.response.data.refreshToken : (newUserToken ? newUserToken.response.data.refreshToken : <Loader />)
  //     dispatch(getTokenDetails(token, refreshToken, user_external_id));
  //     localStorage.setItem('accessToken', token)
  //     localStorage.setItem('refreshToken', refreshToken)
  //     localStorage.setItem('user_external_id', user_external_id);
  //   }

  //   else if (googleResponse) {
  //     let token = googleResponse.data.message ? (newUserToken ? newUserToken.response.data.accessToken : <Loader />) : passwordDetails.response.data.accessToken
  //     let refreshToken = googleResponse.data.message ? (newUserToken ? newUserToken.response.data.refreshToken : <Loader />) : passwordDetails.response.data.refreshToken
  //     let user_external_id = googleResponse.data.message ? (newUserToken ? newUserToken.response.data.user_external_id : <Loader />) : googleResponse.data.user_external_id
  //     dispatch(getTokenDetails(token, refreshToken, user_external_id));
  //     localStorage.setItem('accessToken', token)
  //     localStorage.setItem('refreshToken', refreshToken)
  //     localStorage.setItem('user_external_id', user_external_id);
  //   }

  //   else if (nexmoVerificationResponse.response) {
  //     let token = nexmoVerificationResponse.response.data.user_external_id ?
  //       passwordDetails.response.data.accessToken : (newUserToken ? newUserToken.response.data.accessToken : <Loader />)
  //     let user_external_id = nexmoVerificationResponse.response.data.user_external_id ?
  //       nexmoVerificationResponse.response.data.user_external_id : (newUserToken ? newUserToken.response.data.user_external_id : <Loader />)
  //     let refreshToken = nexmoVerificationResponse.response.data.user_external_id ?
  //       passwordDetails.response.data.refreshToken : (newUserToken ? newUserToken.response.data.refreshToken : <Loader />)
  //     dispatch(getTokenDetails(token, refreshToken, user_external_id));
  //     localStorage.setItem('accessToken', token)
  //     localStorage.setItem('refreshToken', refreshToken)
  //     localStorage.setItem('user_external_id', user_external_id);
  //   }
  // }
  // }, [])
  // }, [newUserToken, googleResponse, FBResponse, nexmoVerificationResponse])

  const homeSvg = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M1.80875 23.2538C1.80875 23.2538 1.78133 24.0001 2.45542 24.0001C3.29405 24.0001 10.2372 23.9902 10.2372 23.9902L10.2487 17.0946C10.2487 17.0946 10.139 15.958 11.1593 15.958H14.3881C15.5946 15.958 15.5203 17.0946 15.5203 17.0946L15.5066 23.9679C15.5066 23.9679 22.0899 23.9679 23.1239 23.9679C23.9797 23.9679 23.9408 23.0388 23.9408 23.0388V10.3264L13.2056 -0.000244141L1.80875 10.3264V23.2538Z"
          fill="#9E9E9E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const createContentButton = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="createPost-button"
    >
      <circle cx="12.0001" cy="11.9998" r="12.0001" fill="#9E9E9E" />
      <rect
        x="6.33359"
        y="12.4406"
        width="0.882034"
        height="11.3308"
        transform="rotate(-90 6.33359 12.4406)"
        stroke="white"
        stroke-width="0.882034"
      />
      <rect
        x="11.5556"
        y="6.33588"
        width="0.884648"
        height="11.3282"
        stroke="white"
        stroke-width="0.884648"
      />
    </svg>
  );

  const leaderboardSvg = (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4752 26.1696H3.02566C2.89282 26.1696 2.78516 26.062 2.78516 25.9291V24.7788C2.78516 24.646 2.89282 24.5383 3.02566 24.5383H26.4752C26.608 24.5383 26.7157 24.646 26.7157 24.7788V25.9291C26.7157 26.0619 26.608 26.1696 26.4752 26.1696Z"
        fill="#9E9E9E"
      />
      <path
        d="M9.65779 24.5381H5.50977V19.137C5.50977 19.0041 5.61743 18.8965 5.75027 18.8965H9.41729C9.55013 18.8965 9.65779 19.0041 9.65779 19.137V24.5381Z"
        fill="#9E9E9E"
      />
      <path
        d="M16.8238 24.5381H12.6758V15.1917C12.6758 15.0588 12.7834 14.9512 12.9163 14.9512H16.5833C16.7161 14.9512 16.8238 15.0588 16.8238 15.1917V24.5381Z"
        fill="#9E9E9E"
      />
      <path
        d="M23.9918 24.5381H19.8438V10.6245C19.8438 10.4917 19.9514 10.384 20.0843 10.384H23.7513C23.8841 10.384 23.9918 10.4917 23.9918 10.6245V24.5381Z"
        fill="#9E9E9E"
      />
      <path
        d="M8.05548 11.7173L8.62045 12.8621C8.65752 12.9372 8.72917 12.9893 8.81208 13.0013L10.0754 13.1849C10.5076 13.2477 10.6802 13.7789 10.3675 14.0838L9.45332 14.9748C9.39333 15.0333 9.36598 15.1176 9.38013 15.2001L9.59593 16.4583C9.66975 16.8888 9.21792 17.2171 8.83134 17.0138L7.70139 16.4198C7.62724 16.3808 7.53865 16.3808 7.46455 16.4198L6.33461 17.0138C5.94802 17.2171 5.4962 16.8888 5.57001 16.4583L5.78581 15.2001C5.79997 15.1176 5.77262 15.0333 5.71263 14.9748L4.79838 14.0837C4.48561 13.7788 4.65822 13.2477 5.09045 13.1849L6.35377 13.0013C6.43668 12.9893 6.50832 12.9372 6.5454 12.862L7.11037 11.7173C7.30369 11.3257 7.86216 11.3257 8.05548 11.7173Z"
        fill="#9E9E9E"
      />
      <path
        d="M15.2233 7.32746L15.7883 8.47223C15.8254 8.54734 15.897 8.59939 15.98 8.61148L17.2433 8.79507C17.6755 8.85785 17.8481 9.38902 17.5353 9.6939L16.6212 10.585C16.5612 10.6434 16.5339 10.7277 16.548 10.8103L16.7638 12.0685C16.8376 12.499 16.3858 12.8272 15.9992 12.624L14.8693 12.0299C14.7951 11.9909 14.7065 11.9909 14.6324 12.0299L13.5025 12.624C13.1159 12.8272 12.6641 12.4989 12.7379 12.0685L12.9537 10.8103C12.9678 10.7277 12.9405 10.6435 12.8805 10.585L11.9664 9.6939C11.6536 9.38902 11.8262 8.85785 12.2584 8.79507L13.5217 8.61148C13.6046 8.59944 13.6763 8.54739 13.7134 8.47223L14.2783 7.32746C14.4716 6.93577 15.0301 6.93577 15.2233 7.32746Z"
        fill="#9E9E9E"
      />
      <path
        d="M22.3894 2.63874L22.9543 3.78351C22.9914 3.85862 23.0631 3.91067 23.146 3.92276L24.4093 4.10635C24.8415 4.16913 25.0141 4.7003 24.7013 5.00518L23.7872 5.89626C23.7272 5.95471 23.6999 6.03897 23.714 6.12155L23.9298 7.37976C24.0036 7.81025 23.5518 8.13853 23.1652 7.93525L22.0353 7.34119C21.9611 7.30219 21.8725 7.30219 21.7984 7.34119L20.6685 7.93525C20.2819 8.13848 19.8301 7.81021 19.9039 7.37976L20.1197 6.12155C20.1339 6.03897 20.1065 5.95476 20.0465 5.89626L19.1324 5.00518C18.8196 4.7003 18.9922 4.16913 19.4244 4.10635L20.6878 3.92276C20.7707 3.91072 20.8423 3.85867 20.8794 3.78351L21.4444 2.63874C21.6376 2.24705 22.1961 2.24705 22.3894 2.63874Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  const charitySvg = (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1606 14.9461C17.7823 15.3079 17.7824 15.8945 18.1608 16.2563C18.1629 16.2582 18.1634 16.261 18.1655 16.263L21.5903 19.538C22.0635 19.1097 22.5192 18.668 22.951 18.2168L19.5307 14.9458C19.1522 14.5841 18.5388 14.5842 18.1606 14.9461Z"
        fill="#9E9E9E"
      />
      <path
        d="M9.84359 16.2562C10.222 15.8945 10.2221 15.3079 9.84386 14.946C9.46557 14.5842 8.85216 14.584 8.47377 14.9458L5.05469 18.2157C5.48638 18.667 5.94201 19.1088 6.41503 19.5372L9.83888 16.263C9.84099 16.261 9.84152 16.2582 9.84359 16.2562Z"
        fill="#9E9E9E"
      />
      <path
        d="M7.44885 10.6834C7.82194 10.3209 7.82003 9.73856 7.44461 9.37828C7.06172 9.02723 6.45752 9.02723 6.07468 9.37828C6.07055 9.38229 6.06488 9.38345 6.06059 9.3872L4.38072 10.9936C4.37362 11.0006 4.36901 11.0089 4.3618 11.0158C4.35804 11.0194 4.35296 11.0204 4.34914 11.0238L1.82227 13.4402C2.05406 13.9459 2.30752 14.4535 2.5978 14.9618C2.6387 15.0386 2.68999 15.1146 2.73343 15.1913L7.44318 10.6869L7.44885 10.6834Z"
        fill="#9E9E9E"
      />
      <path
        d="M3.66535 9.03985C3.28558 8.68759 2.68043 8.69246 2.30686 9.05075L2.30119 9.05434L0.789062 10.5004C0.95537 11.1827 1.16835 11.8538 1.42669 12.5093L3.69576 10.3389C4.05868 9.97085 4.04512 9.39211 3.66535 9.03985Z"
        fill="#9E9E9E"
      />
      <path
        d="M7.78747 14.2911L7.78805 14.2907L8.81493 13.3086C9.19062 12.9497 9.19385 12.3685 8.82224 12.0058C8.45058 11.6431 7.84283 11.6345 7.46005 11.9864L7.44834 11.9976L3.24609 16.0115C3.60462 16.5347 3.99519 17.037 4.41576 17.516L7.78726 14.2915L7.78747 14.2911Z"
        fill="#9E9E9E"
      />
      <path
        d="M24.3292 9.04881C23.9612 9.39696 23.9461 9.96095 24.2951 10.3266L24.2973 10.3301C24.3065 10.3399 24.3161 10.3493 24.3244 10.3596L26.5774 12.5099C26.8358 11.854 27.0488 11.1825 27.2152 10.4999L25.6977 9.04881C25.315 8.69857 24.7119 8.69857 24.3292 9.04881Z"
        fill="#9E9E9E"
      />
      <path
        d="M25.504 2.58026C23.9627 1.30502 21.904 0.759049 19.896 1.09288C17.6197 1.45478 15.6774 2.86029 14.4267 5.04973C14.3342 5.18876 14.1737 5.27296 14.0014 5.27296C13.8291 5.27296 13.6687 5.18876 13.5761 5.04973C12.3254 2.86029 10.3831 1.45478 8.10679 1.09288C7.72935 1.03315 7.34741 1.00315 6.96483 1.00331C5.32407 0.985776 3.73405 1.54725 2.49891 2.58026C0.782269 4.08309 0.143954 6.55591 0.572252 9.39711L1.62191 8.39364C1.62567 8.38999 1.63092 8.38892 1.63479 8.38543C2.39289 7.67343 3.60918 7.67809 4.36129 8.39592C4.60029 8.62715 4.77063 8.91503 4.85476 9.23002L5.38499 8.72296C5.3931 8.71516 5.40391 8.71257 5.41244 8.70548C6.18013 8.02272 7.37136 8.03057 8.12915 8.72342C8.70855 9.28099 8.8561 10.1279 8.49694 10.835C9.16397 10.9572 9.7146 11.4059 9.94666 12.0163C10.1787 12.6267 10.058 13.3088 9.62877 13.8121C9.96822 13.8908 10.2788 14.0565 10.527 14.2916C11.2779 15.0122 11.2817 16.1767 10.5355 16.9018C10.5319 16.9054 10.5308 16.9104 10.527 16.914L7.12833 20.164C9.27412 21.9559 11.5732 23.5725 14.0015 24.9968C16.4298 23.5724 18.7292 21.9561 20.8754 20.1647L17.476 16.9139C17.4722 16.9103 17.4712 16.9054 17.4675 16.9017C16.7213 16.1767 16.7251 15.0121 17.476 14.2915C17.724 14.0563 18.0346 13.8905 18.3741 13.8119C17.9447 13.3084 17.8241 12.6259 18.0566 12.0154C18.2891 11.4049 18.8404 10.9564 19.5078 10.8347C19.0952 10.0276 19.3504 9.0536 20.1122 8.52785C20.874 8.00215 21.9211 8.07744 22.5921 8.70629C22.6 8.71288 22.61 8.71526 22.6175 8.7224L23.1437 9.22561C23.2311 8.91133 23.4031 8.62442 23.6426 8.39354C24.4088 7.69395 25.6148 7.69395 26.381 8.39354L27.4296 9.39605C27.8583 6.55383 27.2198 4.08238 25.504 2.58026Z"
        fill="#9E9E9E"
      />
      <path
        d="M20.5582 11.9988C20.3134 11.7648 19.9566 11.6734 19.6223 11.7592C19.288 11.8449 19.0268 12.0947 18.9373 12.4144C18.8478 12.7342 18.9434 13.0753 19.1882 13.3093L20.2151 14.2914L20.2157 14.2918L20.2159 14.2921L23.5888 17.5179C24.0094 17.0389 24.4 16.5364 24.7583 16.0131L20.564 12.0025L20.5582 11.9988Z"
        fill="#9E9E9E"
      />
      <path
        d="M23.6426 11.0175L23.6425 11.0175L23.6418 11.0167C23.616 10.992 23.5954 10.9714 23.5758 10.9492L21.9418 9.38655C21.9375 9.38275 21.9319 9.38158 21.9277 9.37763C21.5498 9.01613 20.937 9.01603 20.559 9.37743C20.181 9.73882 20.1808 10.3248 20.5588 10.6864L20.5602 10.6873L21.2338 11.3358C21.2369 11.3388 21.2407 11.3407 21.2438 11.3436L21.2475 11.349L25.2704 15.1923C25.3116 15.1194 25.3605 15.047 25.3995 14.9739C25.692 14.4615 25.9475 13.95 26.1807 13.4405L23.6431 11.018L23.6426 11.0175Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  const badgeSvg = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12 0C8.14 0 5 3.14 5 7C5 10.86 8.14 14 12 14C15.86 14 19 10.86 19 7C19 3.14 15.86 0 12 0ZM15.787 6.757L14.426 8.152L14.749 10.13C14.795 10.413 14.676 10.698 14.44 10.864C14.312 10.954 14.16 11 14.009 11C13.884 11 13.759 10.969 13.646 10.906L12 9.997L10.354 10.907C10.103 11.046 9.793 11.03 9.559 10.864C9.324 10.698 9.205 10.413 9.25 10.13L9.573 8.152L8.213 6.757C8.017 6.556 7.949 6.261 8.039 5.995C8.128 5.728 8.358 5.535 8.637 5.492L10.488 5.209L11.322 3.432C11.568 2.905 12.433 2.905 12.679 3.432L13.513 5.209L15.364 5.492C15.642 5.534 15.873 5.728 15.962 5.995C16.051 6.261 15.983 6.556 15.787 6.757Z"
          fill="#9E9E9E"
        />
        <path
          d="M5.96054 12.98L2.09054 19.93C1.96054 20.17 1.97054 20.47 2.12054 20.7C2.27054 20.93 2.53054 21.07 2.81054 21.04L5.89054 20.81L7.25054 23.58C7.38054 23.83 7.63054 23.99 7.90054 24H7.92054C8.19054 24 8.45054 23.85 8.58054 23.62L11.1005 19.14L8.65054 14.81C7.64054 14.38 6.73054 13.75 5.96054 12.98Z"
          fill="#9E9E9E"
        />
        <path
          d="M21.9009 19.93L18.0009 13.02C16.7309 14.29 15.0609 15.15 13.2009 15.41C12.8109 15.47 12.4109 15.5 12.0009 15.5C11.5609 15.5 11.1309 15.47 10.7109 15.4L11.9609 17.61L15.3509 23.62C15.4809 23.85 15.7309 24 16.0009 24H16.0209C16.3009 23.99 16.5509 23.83 16.6809 23.58L18.0509 20.81L21.1909 21.05C21.4709 21.07 21.7309 20.94 21.8809 20.7C22.0309 20.47 22.0409 20.17 21.9009 19.93Z"
          fill="#9E9E9E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const notificationSvg = (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59922 24.0001C10.9192 24.0001 11.9992 22.8924 11.9992 21.5386H7.19922C7.19922 22.8924 8.26722 24.0001 9.59922 24.0001ZM16.8 16.6154V10.4615C16.8 6.68308 14.832 3.52 11.4 2.68308V1.84615C11.4 0.824615 10.596 0 9.6 0C8.604 0 7.8 0.824615 7.8 1.84615V2.68308C4.356 3.52 2.4 6.67077 2.4 10.4615V16.6154L0 19.0769V20.3077H19.2V19.0769L16.8 16.6154Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  const walletSvg = (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1298 15.6556C20.9446 15.9005 20.7257 16.1209 20.4844 16.2985V19.0414H20.7987C21.4609 19.0414 21.9941 18.4597 21.9997 17.7434V14.4312L21.1298 15.6556Z"
        fill="#9E9E9E"
      />
      <path
        d="M15.8265 9.79596H19.0648C19.1602 9.79596 19.2612 9.78372 19.3622 9.75923V6.25719C19.3622 5.53474 18.8235 4.95923 18.1668 4.95923H1.20102C0.538776 4.95923 0.00561225 5.54086 0 6.25719V20.296C0.00561225 21.0184 0.538776 21.6062 1.20102 21.6123H18.1668C18.8291 21.6062 19.3622 21.0184 19.3622 20.296L19.3566 16.7388C19.2612 16.7511 19.1602 16.7633 19.0592 16.7694H15.8265C14.0643 16.7633 12.6332 15.2021 12.6332 13.2796C12.6332 11.3572 14.0643 9.79596 15.8265 9.79596Z"
        fill="#9E9E9E"
      />
      <path
        d="M20.7979 2.38794H3.83213C3.16988 2.39406 2.63672 2.98182 2.63672 3.70427V3.73488H18.1658C19.4454 3.73488 20.4837 4.86141 20.4837 6.25733V8.81651L21.999 6.6859V3.70427C21.9934 2.98182 21.4602 2.39406 20.7979 2.38794Z"
        fill="#9E9E9E"
      />
      <path
        d="M21.9927 8.6875L21.1228 9.91199C20.8702 10.2671 20.5447 10.5548 20.1743 10.7446C19.8264 10.9283 19.4447 11.0202 19.0575 11.0202H15.8248C14.6799 11.0263 13.7539 12.0365 13.7539 13.2855C13.7539 14.5344 14.6799 15.5446 15.8248 15.5508H19.0631C19.5233 15.5446 19.9611 15.312 20.2417 14.914L21.9983 12.4283L21.9927 8.6875ZM17.082 13.8977H15.735C15.4264 13.8977 15.1738 13.6222 15.1738 13.2855C15.1738 12.9487 15.4264 12.6732 15.735 12.6732H17.082C17.3906 12.6732 17.6432 12.9487 17.6432 13.2855C17.6432 13.6222 17.3906 13.8977 17.082 13.8977Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  const profileDropdownHelp = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7891 3H5.78906C4.68906 3 3.78906 3.9 3.78906 5V19C3.78906 20.1 4.68906 21 5.78906 21H19.7891C20.8891 21 21.7891 20.1 21.7891 19V5C21.7891 3.9 20.8891 3 19.7891 3ZM12.7991 18C12.0991 18 11.5391 17.44 11.5391 16.74C11.5391 16.03 12.0991 15.49 12.7991 15.49C13.5091 15.49 14.0491 16.03 14.0491 16.74C14.0391 17.43 13.5091 18 12.7991 18ZM15.8091 10.6C15.0491 11.71 14.3291 12.06 13.9391 12.77C13.7791 13.06 13.7191 13.25 13.7191 14.18H11.8991C11.8991 13.69 11.8191 12.89 12.2091 12.2C12.6991 11.33 13.6291 10.81 14.1691 10.04C14.7391 9.23 14.4191 7.71 12.7991 7.71C11.7391 7.71 11.2191 8.51 10.9991 9.19L9.34906 8.49C9.79906 7.15 11.0091 6 12.7791 6C14.2591 6 15.2691 6.67 15.7891 7.52C16.2291 8.24 16.4891 9.59 15.8091 10.6Z"
        fill="#55A3C6"
      />
    </svg>
  );
  const sheild = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.04297 8.82897C8.11437 9.68318 8.27835 10.8533 8.64938 12.0819C9.43737 14.691 10.8294 16.5462 12.789 17.6005C14.7485 16.5462 16.1406 14.691 16.9285 12.0819C17.2996 10.8534 17.4635 9.68325 17.535 8.82897L12.789 6.45596L8.04297 8.82897Z"
        fill="#55A3C6"
      />
      <path
        d="M20.7571 7.18995C20.7472 6.99965 20.6357 6.82936 20.4653 6.74416L13.0259 3.02442C12.8769 2.94995 12.7015 2.94995 12.5526 3.02442L5.11311 6.74416C4.94268 6.82936 4.8312 6.99965 4.82132 7.18995C4.81567 7.29826 4.69333 9.87708 5.57181 12.8711C6.09161 14.6426 6.85617 16.1898 7.84427 17.4698C9.08944 19.0829 10.6898 20.2695 12.601 20.9968C12.6616 21.0198 12.7254 21.0314 12.7892 21.0314C12.853 21.0314 12.9168 21.0198 12.9774 20.9968C14.8886 20.2695 16.489 19.0829 17.7342 17.4698C18.7223 16.1898 19.4868 14.6426 20.0066 12.8711C20.8851 9.87708 20.7628 7.29826 20.7571 7.18995ZM13.0237 18.6702C12.9498 18.7068 12.8695 18.725 12.7892 18.725C12.7089 18.725 12.6286 18.7068 12.5547 18.6702C10.1983 17.5052 8.5436 15.3915 7.63651 12.3879C7.18282 10.8856 7.01902 9.46999 6.96152 8.54702C6.94829 8.33507 7.06308 8.13578 7.25303 8.04081L12.5526 5.39104C12.7016 5.3166 12.8769 5.31657 13.0259 5.39104L18.3255 8.04081C18.5154 8.13574 18.6302 8.3351 18.617 8.54706C18.5595 9.4701 18.3957 10.8857 17.942 12.3879C17.0348 15.3914 15.3801 17.5051 13.0237 18.6702Z"
        fill="#55A3C6"
      />
    </svg>
  );
  const terms = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49575 21.0314H18.0794C18.9548 21.0314 19.6669 20.3192 19.6669 19.4438V8.26045H15.9627C15.0872 8.26045 14.3751 7.54831 14.3751 6.6729V2.96863H7.49575C6.62034 2.96863 5.9082 3.68077 5.9082 4.55617V19.4438C5.9082 20.3192 6.62034 21.0314 7.49575 21.0314ZM9.61247 10.4125H15.9627C16.2552 10.4125 16.4918 10.6491 16.4918 10.9416C16.4918 11.2341 16.2552 11.4708 15.9627 11.4708H9.61247C9.31998 11.4708 9.08329 11.2341 9.08329 10.9416C9.08329 10.6491 9.31998 10.4125 9.61247 10.4125ZM9.61247 12.5292H15.9627C16.2552 12.5292 16.4918 12.7659 16.4918 13.0584C16.4918 13.3509 16.2552 13.5875 15.9627 13.5875H9.61247C9.31998 13.5875 9.08329 13.3509 9.08329 13.0584C9.08329 12.7659 9.31998 12.5292 9.61247 12.5292ZM9.61247 14.6459H15.9627C16.2552 14.6459 16.4918 14.8826 16.4918 15.1751C16.4918 15.4676 16.2552 15.7043 15.9627 15.7043H9.61247C9.31998 15.7043 9.08329 15.4676 9.08329 15.1751C9.08329 14.8826 9.31998 14.6459 9.61247 14.6459ZM9.61247 16.7626H13.8459C14.1384 16.7626 14.3751 16.9993 14.3751 17.2918C14.3751 17.5843 14.1384 17.821 13.8459 17.821H9.61247C9.31998 17.821 9.08329 17.5843 9.08329 17.2918C9.08329 16.9993 9.31998 16.7626 9.61247 16.7626Z"
        fill="#55A3C6"
      />
      <path
        d="M15.9647 7.20208H19.3589L15.4355 3.27869V6.67289C15.4355 6.96486 15.6728 7.20208 15.9647 7.20208Z"
        fill="#55A3C6"
      />
    </svg>
  );
  const profileDropdownSettings = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6742 12.8775C19.7089 12.5962 19.7321 12.3037 19.7321 12C19.7321 11.6962 19.7089 11.4037 19.6627 11.1225L21.6183 9.63747C21.7918 9.50247 21.8381 9.25497 21.734 9.06372L19.8825 5.94747C19.7668 5.74497 19.5238 5.67747 19.3155 5.74497L17.0127 6.64497C16.5267 6.28497 16.0176 5.99247 15.4506 5.76747L15.1034 3.38247C15.0687 3.15747 14.872 2.99997 14.6405 2.99997H10.9376C10.7062 2.99997 10.521 3.15747 10.4863 3.38247L10.1391 5.76747C9.57213 5.99247 9.0514 6.29622 8.57696 6.64497L6.2742 5.74497C6.0659 5.66622 5.8229 5.74497 5.70718 5.94747L3.85571 9.06372C3.73999 9.26622 3.78628 9.50247 3.97143 9.63747L5.92704 11.1225C5.88076 11.4037 5.84604 11.7075 5.84604 12C5.84604 12.2925 5.86919 12.5962 5.91547 12.8775L3.95986 14.3625C3.78628 14.4975 3.73999 14.745 3.84414 14.9362L5.69561 18.0525C5.81133 18.255 6.05433 18.3225 6.26262 18.255L8.56539 17.355C9.0514 17.715 9.56056 18.0075 10.1276 18.2325L10.4747 20.6175C10.521 20.8425 10.7062 21 10.9376 21H14.6405C14.872 21 15.0687 20.8425 15.0918 20.6175L15.439 18.2325C16.006 18.0075 16.5267 17.7037 17.0012 17.355L19.3039 18.255C19.5122 18.3337 19.7552 18.255 19.8709 18.0525L21.7224 14.9362C21.8381 14.7337 21.7918 14.4975 21.6067 14.3625L19.6742 12.8775ZM12.7879 15.375C10.8786 15.375 9.31641 13.8563 9.31641 12C9.31641 10.1438 10.8786 8.62501 12.7879 8.62501C14.6972 8.62501 16.2594 10.1438 16.2594 12C16.2594 13.8563 14.6972 15.375 12.7879 15.375Z"
        fill="#55A3C6"
      />
    </svg>
  );

  const profileDropdownAbout = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7891 3C7.82106 3 3.78906 7.032 3.78906 12C3.78906 16.968 7.82106 21 12.7891 21C17.7571 21 21.7891 16.968 21.7891 12C21.7891 7.032 17.7571 3 12.7891 3ZM13.6891 16.5H11.8891V11.1H13.6891V16.5ZM13.6891 9.3H11.8891V7.5H13.6891V9.3Z"
        fill="#55A3C6"
      />
    </svg>
  );

  const profileDropdownFAQs = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7891 15.1897C21.7891 12.9591 20.5094 10.9683 18.5929 10C18.5334 14.2768 15.0659 17.7443 10.7891 17.8038C11.7574 19.7203 13.7481 21 15.9787 21C17.0245 21 18.0416 20.7215 18.9352 20.1922L21.7637 20.9746L20.9813 18.1461C21.5105 17.2525 21.7891 16.2355 21.7891 15.1897Z"
        fill="#55A3C6"
      />
      <path
        d="M17.7891 10C17.7891 6.14013 14.6489 3 10.7891 3C6.92919 3 3.78906 6.14013 3.78906 10C3.78906 11.258 4.1239 12.4828 4.75973 13.558L3.8148 16.9741L7.23102 16.0293C8.30631 16.6652 9.53111 17 10.7891 17C14.6489 17 17.7891 13.8599 17.7891 10ZM9.71489 8.37084H8.64073C8.64073 7.18618 9.6044 6.22251 10.7891 6.22251C11.9737 6.22251 12.9374 7.18618 12.9374 8.37084C12.9374 8.97213 12.6828 9.55005 12.2388 9.95622L11.3261 10.7915V11.6292H10.252V10.3185L11.5136 9.16374C11.739 8.95744 11.8632 8.67589 11.8632 8.37084C11.8632 7.77851 11.3814 7.29668 10.7891 7.29668C10.1967 7.29668 9.71489 7.77851 9.71489 8.37084ZM10.252 12.7033H11.3261V13.7775H10.252V12.7033Z"
        fill="#55A3C6"
      />
    </svg>
  );

  const profileDropdownFeedback = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9891 3H5.58906C4.59906 3 3.79806 3.81 3.79806 4.8L3.78906 21L7.38906 17.4H19.9891C20.9791 17.4 21.7891 16.59 21.7891 15.6V4.8C21.7891 3.81 20.9791 3 19.9891 3ZM13.6891 13.8H11.8891V12H13.6891V13.8ZM13.6891 10.2H11.8891V6.6H13.6891V10.2Z"
        fill="#55A3C6"
      />
    </svg>
  );

  let tagPath =
    "/profile" ==
    path; /*"showActiveButton" Button active only for profile page path */
  let stylesReport = notificationButtonActive ? "buttonActiveNotification" : "";

  let styleMobileNotificationList = mobileNotification
    ? { display: "block" }
    : { display: "none" }; // mobile notification view

  const showContent = (contentExternalId) => {
    history.push(`/notification/${contentExternalId}`);
    window.location.reload();
  };

  const NewNotification = () => {
    return (
      <>
        {oldNotification.length || newNotification.length ? (
          <div className="notification-list">
            {oldNotification.length && newNotification.length ? (
              <p>{t("contentNavBar.notification.new")} </p>
            ) : (
                ""
              )}
            {newNotification.map((item, index) => {
              return (
                <div key={index} className="notification-item">
                  <div className="notification-details-left-content">
                    <Image
                      className="notification-profile"
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.follower_id}.jpg`}
                      roundedCircle
                    // style={{ width: "51px", height: "51px" }}
                    />
                    <div className="notification-details">
                      <p>
                        <span> {item.notification_content} </span>
                      </p>
                      <p>{getPostedTime(item.notification_created_on)}</p>
                    </div>
                  </div>
                  <div className="notification-details-image">
                    {item.content_url
                      ? item.content_url.map((content) => {
                        return content.contentType === "image/jpeg" ||
                          content.contentType === "image/png" ? (
                            <img
                              src={content.signedUrl}
                              onClick={() =>
                                showContent(item.content_external_id)
                              }
                            />
                          ) : (
                            <video
                              src={content.signedUrl}
                              type="video/mp4"
                              onClick={() =>
                                showContent(item.content_external_id)
                              }
                            />
                          );
                      })
                      : ""}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
            ""
          )}
      </>
    );
  };

  const OldNotification = () => {
    return (
      <>
        <div className="notification-list">
          {oldNotification.length && newNotification.length ? (
            <p>{t("contentNavBar.notification.old")} </p>
          ) : (
              ""
            )}
          {oldNotification.map((item, index) => {
            return (
              <>
                <div key={index} className="notification-item">
                  <div className="notification-details-left-content">
                    <Image
                      className="notification-profile"
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.follower_id}.jpg`}
                      roundedCircle
                    // style={{ width: "51px", height: "51px" }}
                    />
                    <div className="notification-details">
                      <p>
                        {" "}
                        <span> {item.notification_content} </span>
                      </p>
                      <p>{getPostedTime(item.notification_created_on)}</p>
                    </div>
                  </div>
                  <div className="notification-details-image">
                    {item.content_url
                      ? item.content_url.map((content) => {
                        return content.contentType === "image/jpeg" ||
                          content.contentType === "image/png" ? (
                            <img
                              src={content.signedUrl}
                              onClick={() =>
                                showContent(item.content_external_id)
                              }
                            />
                          ) : (
                            <video
                              src={content.signedUrl}
                              type="video/mp4"
                              onClick={() =>
                                showContent(item.content_external_id)
                              }
                            />
                          );
                      })
                      : ""}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      {/* <div className="d-block d-md-none">
        <UnAuthContentNavbar />
      </div> */}
      {mobileNotification && <BodyStyle />}
      <div style={{ position: "sticky", top: "0", zIndex: "2" }}>
        <div className="content-navbar-wrapper">
          <Navbar className="content-navbar" expand="lg">
            <Navbar.Brand href="/home">
              <img
                src={brandLogo}
                // width="66"
                // height="48"
                alt="brand-logo"
                onClick={handleLogoClick}
                className="brandLogo"
              />
            </Navbar.Brand>

            {/* the search bar is visible for home */}
            {isAuthorized && (
              <div className="search-input-lg">
                {(path === "/home" ||
                  path === "/search/posts" ||
                  path === "/search/people" ||
                  path === "/search/charity") && <SearchComponent />}
              </div>
            )}

            <div className="content-navbar-dropDown content-navbar-sm ml-auto mr-3">
              {/*"showActiveButton" Button active only for profile page */}
              {isAuthorized && (
                <div
                  style={{
                    margin: 0,
                    marginRight: "40px",
                  }}
                  className="search-input-sm"
                  onClick={handleSearch}
                >
                  {(path === "/home" ||
                    path === "/search/posts" ||
                    path === "/search/people" ||
                    path === "/search/charity") && <SearchComponent />}
                </div>
              )}

              <div className="mr-3">
                <NavLink
                  id="wallet-button"
                  activeClassName="active-button"
                  className="navbar__link"
                  to="/addbalance"
                  isActive={() => ["/addbalance", "/pay"].includes(pathname)} //multiple path name for same navlink
                >
                  <div className="navbar-icon-name-wrapper">
                    <div className="navbar-icon tour-mobile-wallet">
                      {walletSvg}
                    </div>
                    {/* <div className="navbar-menu-name">Your Pool</div> */}
                  </div>

                  {showBalanceOnNavbar && (
                    <span className="balanceAmount">
                      {countryCurrencySign + " "}
                      {balance && Math.abs(balance) > 999
                        ? Math.sign(balance) *
                        (Math.abs(balance) / 1000).toFixed(1) +
                        "k"
                        : balance && Math.sign(balance) * Math.abs(balance)}
                    </span>
                  )}
                  {/* <span className="tooltiptext">Your Pool</span> */}
                </NavLink>
              </div>

              <div className="tour-mobile-profile">
                <NavDropdown
                  className={tagPath ? "showActiveButton" : ""}
                  title={
                    <div className="userImage-userProfilePic">
                      {/* {Contentuserimage()} */}
                      <Image
                        className="contentNavbar-thumbnail-image mr-2"
                        onError={(ev) => (ev.target.src = `${avatar}`)}
                        src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`}
                        roundedCircle
                        width="24"
                        height="24"
                      />

                      {/* <img src='https://gpi-public-data.s3.eu-west-1.amazonaws.com/images/profiles/dbc96790-1d18-4f1d-bb46-2b5663bce6bd.jpg' /> */}
                      {/* <span className="contentNavbar-userName">
                    {userDetails.username}
                  </span> */}
                      {/* {userName ? <span className="contentNavbar-userName">{userDetails.username}</span> : <span className="contentNavbar-userName"> </span>} */}
                      <span className="contentNavbar-caret">
                        <Image className="caret" src={caret} alt="caret" />
                      </span>
                    </div>
                  }
                  alignRight
                  id="dropdown-menu-align-right"
                >
                  <NavLink
                    id="profile-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/profile"
                  >
                    <span className="dropdown-user-profile-pic">
                      <Image
                        className="contentNavbar-thumbnail-image"
                        onError={(ev) => (ev.target.src = `${avatar}`)}
                        src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`}
                        roundedCircle
                        width="24"
                        height="24"
                      />
                      <span className="contentNavbar-userName">
                        {userDetails.username
                          ? userDetails.username
                          : t("contentNavBar.profile")}
                      </span>
                      {/* {userDetails ? (
                      <span className="contentNavbar-userName">
                        {userDetails.username}
                      </span>
                    ) : (
                      <span className="contentNavbar-userName">username</span>
                    )} */}
                    </span>
                  </NavLink>
                  <NavLink
                    id="settings-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/settingAndPrivacy"
                  >
                    {profileDropdownSettings}&nbsp;
                    <span>{t("contentNavBar.settings")}</span>
                  </NavLink>
                  <NavLink
                    id="settings-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/helpAndSupport"
                  >
                    {profileDropdownHelp}&nbsp;
                    <span>{t("contentNavBar.helpAndSupport")}</span>
                  </NavLink>

                  <NavLink
                    id="security-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/privacy"
                  >
                    {sheild}&nbsp;
                    <span>{t("contentNavBar.securityAndPrivacy")}</span>
                  </NavLink>
                  <NavLink
                    id="privacy-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/terms"
                  >
                    {terms}&nbsp;
                    <span>{t("contentNavBar.termsAndConditions")}</span>
                  </NavLink>

                  <NavLink
                    id="about-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/ourPeople"
                  >
                    {profileDropdownAbout}&nbsp;
                    <span>{t("contentNavBar.ourPeople")}</span>
                  </NavLink>

                  <NavLink
                    id="faqs-button"
                    activeClassName="active-dropdown-button"
                    className="navbar__link"
                    to="/faqs"
                  >
                    {profileDropdownFAQs}&nbsp;
                    <span>{t("contentNavBar.faq")}</span>
                  </NavLink>

                  {isAuthorized ? (
                    <Feedback propsDetails={props} />
                  ) : (
                      <NavLink
                        id="feedback-button"
                        // activeClassName="active-dropdown-button"
                        className="navbar__link"
                        to="/"
                      >
                        {profileDropdownFeedback}&nbsp;
                        <span>{t("contentNavBar.feedback")}</span>
                      </NavLink>
                    )}
                  {isAuthorized && (
                    <NavDropdown.Item>
                      <Logout />
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </div>
            </div>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <NavLink
                    id="home-button"
                    activeClassName="active-button"
                    className="navbar__link"
                    to="/home"
                    onClick={handleHomeButton}
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon">{homeSvg}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.home")}
                      </div>
                    </div>

                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.home")}
                    </span>
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink
                    activeClassName="active-button"
                    className="navbar__link"
                    to="/leaderboard"
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon">{leaderboardSvg}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.leaderboard")}
                      </div>
                    </div>

                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.leaderboard")}
                    </span>
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink
                    id="charity-button"
                    activeClassName="active-button"
                    className="navbar__link"
                    to="/charity"
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon">{charitySvg}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.charity")}
                      </div>
                    </div>

                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.charity")}
                    </span>
                  </NavLink>
                </Nav.Item>

                {authorizedUser ? (
                  <Nav.Item
                    className={`create-hover-button ${buttonActiveStyle}`}
                    onClick={handleShow}
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon">{createContentButton}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.createPost")}
                      </div>
                    </div>
                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.createPost")}
                    </span>
                    {/* <CreateContentOld
                    handleNewPostCreated={props.handleNewPostCreated}
                    handleHide={handleHide}
                  /> */}
                  </Nav.Item>
                ) : (
                    <Nav.Item
                      className={`create-hover-button`}
                      onClick={handleNotification}
                    >
                      <div className="navbar-icon-name-wrapper">
                        <div className="navbar-icon">{createContentButton}</div>
                        <div className="navbar-menu-name">
                          {t("contentNavBar.navMenu.createPost")}
                        </div>
                      </div>
                      <span className="tooltiptext">
                        {t("contentNavBar.navMenu.createPost")}
                      </span>
                    </Nav.Item>
                  )}

                <Nav.Item>
                  <NavLink
                    id="badge-button"
                    activeClassName="active-button"
                    className="navbar__link"
                    to="/achievement"
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon">{badgeSvg}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.achievements")}
                      </div>
                    </div>

                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.achievements")}
                    </span>
                  </NavLink>
                </Nav.Item>
                {authorizedUser ? (
                  <Nav.Item
                    className={`mobile-notification`}
                    // ${notificationButtonActiveStyle}
                    onClick={handleShowMobileNotification}
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon">{notificationSvg}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.notifications")}
                      </div>
                    </div>

                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.notifications")}
                    </span>
                    <div
                      className="mobile-notification-list"
                      style={styleMobileNotificationList}
                    >
                      <p className="notification-header">
                        {t("contentNavBar.navMenu.notifications")}{" "}
                        <img
                          src={x}
                          alt="close-button"
                          onClick={handleShowMobileNotification}
                        />
                      </p>
                      <p style={{ marginLeft: "1rem" }}>{showMsg}</p>
                      <div className="notification-content-wrapper">
                        {NewNotification()}
                        {OldNotification()}
                      </div>
                    </div>
                  </Nav.Item>
                ) : (
                    <Nav.Item
                      className={`mobile-notification`}
                      onClick={handleNotification}
                    >
                      <span className="tooltiptext">
                        {t("contentNavBar.navMenu.notifications")}
                      </span>
                      <div className="navbar-icon-name-wrapper">
                        <div className="navbar-icon">{notificationSvg}</div>
                        <div className="navbar-menu-name">
                          {t("contentNavBar.navMenu.notifications")}
                        </div>
                      </div>
                    </Nav.Item>
                  )}

                <div
                  className={`content-notification-dropDown ${stylesReport}`}
                >
                  <NavDropdown
                    onClick={handleNotification}
                    title={
                      <div className="userImage-userProfilePic">
                        {notificationSvg}
                        <span className="tooltiptext">
                          {t("contentNavBar.navMenu.notifications")}
                        </span>
                      </div>
                    }
                    alignRight
                    id="dropdown-menu-align-right"
                  >
                    <p className="notification-header">
                      {t("contentNavBar.navMenu.notifications")}
                    </p>
                    <p style={{ marginLeft: "1rem" }}>{showMsg}</p>
                    {NewNotification()}
                    {OldNotification()}
                  </NavDropdown>
                </div>
                <Nav.Item className="d-none d-lg-block">
                  <NavLink
                    id="wallet-button"
                    activeClassName="active-button"
                    className="navbar__link"
                    to="/addbalance"
                    isActive={() => ["/addbalance", "/pay"].includes(pathname)} //multiple path name for same navlink
                  >
                    <div className="navbar-icon-name-wrapper">
                      <div className="navbar-icon tour-wallet">{walletSvg}</div>
                      <div className="navbar-menu-name">
                        {t("contentNavBar.navMenu.yourPool")}
                      </div>
                    </div>

                    {showBalanceOnNavbar && (
                      <span className="balanceAmount">
                        {countryCurrencySign + " "}
                        {balance && Math.abs(balance) > 999
                          ? Math.sign(balance) *
                          (Math.abs(balance) / 1000).toFixed(1) +
                          "k"
                          : balance && Math.sign(balance) * Math.abs(balance)}
                      </span>
                    )}
                    <span className="tooltiptext">
                      {t("contentNavBar.navMenu.yourPool")}
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>

            {/*Dropdown*********################################################################################ */}
            <div className="content-navbar-dropDown content-navbar-lg tour-profile-view">
              {/*"showActiveButton" Button active only for profile page */}
              <NavDropdown
                className={tagPath ? "showActiveButton" : ""}
                title={
                  <div className="userImage-userProfilePic tour-profile">
                    {/* {Contentuserimage()} */}
                    <Image
                      className="contentNavbar-thumbnail-image"
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`}
                      roundedCircle
                      width="24"
                      height="24"
                    />

                    {/* <img src='https://gpi-public-data.s3.eu-west-1.amazonaws.com/images/profiles/dbc96790-1d18-4f1d-bb46-2b5663bce6bd.jpg' /> */}
                    <span className="contentNavbar-userName">
                      {userDetails.username}
                    </span>
                    {/* {userName ? <span className="contentNavbar-userName">{userDetails.username}</span> : <span className="contentNavbar-userName"> </span>} */}
                    <span className="contentNavbar-caret">
                      <Image className="caret" src={caret} alt="caret" />
                    </span>
                  </div>
                }
                alignRight
                id="dropdown-menu-align-right"
              >
                <NavLink
                  id="profile-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/profile"
                >
                  <span className="dropdown-user-profile-pic">
                    <Image
                      className="contentNavbar-thumbnail-image"
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`}
                      roundedCircle
                      width="24"
                      height="24"
                    />
                    {userDetails ? (
                      <span className="contentNavbar-userName">
                        {userDetails.username}
                      </span>
                    ) : (
                        <span className="contentNavbar-userName">
                          {t("contentNavBar.username")}
                        </span>
                      )}
                  </span>
                </NavLink>
                <NavLink
                  id="settings-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/settingAndPrivacy"
                >
                  {profileDropdownSettings}&nbsp;
                  <span>{t("contentNavBar.settings")}</span>
                </NavLink>
                <NavLink
                  id="settings-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/helpAndSupport"
                >
                  {profileDropdownHelp}&nbsp;
                  <span>{t("contentNavBar.helpAndSupport")}</span>
                </NavLink>

                <NavLink
                  id="security-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/privacy"
                >
                  {sheild}&nbsp;
                  <span>{t("contentNavBar.securityAndPrivacy")}</span>
                </NavLink>
                <NavLink
                  id="privacy-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/terms"
                >
                  {terms}&nbsp;
                  <span>{t("contentNavBar.termsAndConditions")}</span>
                </NavLink>

                <NavLink
                  id="about-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/ourPeople"
                >
                  {profileDropdownAbout}&nbsp;
                  <span>{t("contentNavBar.ourPeople")}</span>
                </NavLink>

                <NavLink
                  id="faqs-button"
                  activeClassName="active-dropdown-button"
                  className="navbar__link"
                  to="/faqs"
                >
                  {profileDropdownFAQs}&nbsp;
                  <span>{t("contentNavBar.faq")}</span>
                </NavLink>

                {isAuthorized ? (
                  <Feedback propsDetails={props} />
                ) : (
                    <NavLink
                      id="feedback-button"
                      // activeClassName="active-dropdown-button"
                      className="navbar__link"
                      to="/"
                    >
                      {profileDropdownFeedback}&nbsp;
                      <span>{t("contentNavBar.feedback")}</span>
                    </NavLink>
                  )}
                {isAuthorized && (
                  <NavDropdown.Item>
                    <Logout />
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </div>
          </Navbar>
        </div>
        <div ref={searchInputRef} className="searchBar-responsive">
          <form onSubmit={handleSearchField}>
            <InputBase
              placeholder={t("contentNavBar.search")}
              // classes={{
              //   root: classes.inputRoot,
              //   input: classes.inputInput,
              // }}
              className={classes.searchInput}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
            {/* <Button variant="contained" type="submit">
            <img src={search} />
          </Button> */}
            {/* <div>
            <Image src={search} />
          </div> */}
          </form>
        </div>
      </div>

      {/* <CreateContentOld handleHide={handleHide} /> */}
      {isAuthorized && <CreateContentOld handleHide={handleHide} />}

    </React.Fragment>
  );
};

export default withRouter(ContentNavBar);
