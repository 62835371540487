import axios from "axios";
import { BASEURL } from "../config";
import {
  STORE_USER_DATA,
  USER_PROFILE_DETAILS,
} from "../constants/action-types";

export const getUserDetailsByUserId = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASEURL}/v1/displayProfile?external_id=${localStorage.getItem(
      "user_external_id"
    )}`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      // 'userexternalid': `${localStorage.getItem('user_external_id')}`,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  })
    .then((userProfileResponse) =>
      dispatch({
        type: STORE_USER_DATA,
        payload: userProfileResponse.data.response.data,
      })
    )
    .catch((err) => console.error(err));
};

// export const updateProfilePicture = (picUrl) => dispatch => {
//     var data = picUrl.split(",");
//     axios({
//         method: 'POST',
//         url: `${BASEURL}/v1/updateProfilePic`,
//         headers: {
//             'Accept': 'application/json',
//             'Access-Control-Allow-Origin': '*',
//             // 'Authorization': `Bearer ${token}`,
//             // 'userexternalid': externalId
//         },
//         data: {
//             base64image: `${data[1]}`
//         },
//         withCredentials: true,
//     })
//         .then(username =>
//             dispatch({
//                 type: UPDATE_PROFILE_PICTUTRE,
//                 payload: username
//             }))
//         .catch(e => {
//             console.error(e)
//         })
// }

export const userProfileDetails = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASEURL}/v1/display/profilePage`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': `Bearer ${token}`,
      // 'userexternalid': externalId
    },
    withCredentials: true,
  })
    .then((response) =>
      dispatch({
        type: USER_PROFILE_DETAILS,
        payload: response.data.response,
      })
    )
    .catch((e) => {
      console.error(e);
    });
};
