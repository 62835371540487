import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BASEURL } from "../../config";
import axios from "axios";

export default function usePagination(pageNumber, newPostCreated) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentsArray, setContentsArray] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setContentsArray([]);
  }, [newPostCreated]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: `${BASEURL}/v1/contents`,
      params: {
        pageNo: pageNumber,
      },
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        // 'Authorization': `Bearer ${token}`,
        // 'userexternalid': external_Id
        // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        // 'userexternalid': `${localStorage.getItem('user_external_id')}`
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((response) => {
        setLoading(true);
        setContentsArray((prevContentsArray) => {
          if (response.data.response.statusCode === 200) {
            return [
              ...prevContentsArray,
              ...response.data.response.data.contents,
            ];
          } else {
            return [...prevContentsArray];
          }
        });
        setHasMore(response.data.response.statusCode === 200);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [pageNumber, newPostCreated]);
  return { loading, error, contentsArray, hasMore };
}
