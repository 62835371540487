import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Linkify from "linkifyjs/react";

//components imports
import SharingOnMedia from "../../Contents/SharingOnMedia";
import ReportPost from "../../Contents/ReportPost";
import Loader from "../../Loader";
// import Comments from "../Activity/comments";
import EmojiSlider from "../../EmojiSlider/EmojiSlider";
import ContentSlider from "./ContentSlider";
import { getOtherUserDetailsByUserId } from "../../../actions/otherUserProfile/otherUserProfile_action";

//css imports
import { Card, Image } from "react-bootstrap";
import usePagination from "../../../actions/customHooks/usePagination";

//image imports
import avatar from "../../../assets/images/avatar.png";
import pooledCollect from "../../../assets/svg/pooledCollect.svg";

import CharityInfo from "../../CharityInfo/CharityInfo";
import { useTranslation } from "react-i18next";

const Contents = () => {
  const [pageNo, setPageNo] = useState(1);
  const [showCharityDes, setShowCharityDes] = useState();
  // const [showComments, setShowComments] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const len = 90;

  // const userContentData = useSelector((state) => state.userContent);
  // const tokenDetails = useSelector((state) => state.tokenDetails);
  const userDetails = useSelector((state) => state.userDetails); //Converted the profileDetailsById to userDetails
  const charitiesList = useSelector((state) => state.charityNames.data);
  const newPostCreated = useSelector((state) => state.newPost.newPost);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const observer = useRef();
  // const nextDate = userContentData.next;
  // console.log(nextDate);
  const { loading, error, contentsArray, hasMore } = usePagination(
    pageNo,
    newPostCreated
  );

  const lastElementInContent = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNo((prevpageNo) => prevpageNo + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    setPageNo(1);
  }, [newPostCreated]);

  // if (!userContentData) return <Loader />
  // if (contentsArray.length === 0) return <Loader />;

  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return (
          <p className="timeline">
            {month} {t("post.monthAgo")}
          </p>
        );
      } else {
        return (
          <p className="timeline">
            {month} {t("post.monthsAgo")}
          </p>
        );
      }
    } else if (days) {
      if (days === 1)
        return (
          <p className="timeline">
            {days} {t("post.dayAgo")}
          </p>
        );
      return (
        <p className="timeline">
          {days} {t("post.daysAgo")}
        </p>
      );
    } else if (hours) {
      if (hours === 1)
        return (
          <p className="timeline">
            {hours} {t("post.hourAgo")}
          </p>
        );
      return (
        <p className="timeline">
          {hours} {t("post.hoursAgo")}
        </p>
      );
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return (
          <p className="timeline">
            {minutes} {t("post.minuteAgo")}
          </p>
        );
      return (
        <p className="timeline">
          {minutes} {t("post.minutesAgo")}
        </p>
      );
    }
  };

  const handleOtherUser = (otherUserExternalId) => {
    history.push({
      pathname: "/otheruserprofile",
      state: { otherUserExternalId: otherUserExternalId },
    });
    // history.push("/otheruserprofile");
    dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
  };

  if (!userDetails) return <Loader />;

  let activityCards = contentsArray.map((item, index) => {
    if (contentsArray.length === index + 1) {
      return (
        <>
          <Card
            className="Activitypage-card"
            key={item.content_external_id}
            ref={lastElementInContent}
          >
            <Card.Header className="activitypage-header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.content_creator_id === userDetails.user_external_id ? (
                    <Image
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      className="profile-picture"
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                      roundedCircle
                      width="56"
                      height="56"
                    />
                  ) : (
                    <Image
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      onClick={() => handleOtherUser(item.content_creator_id)}
                      className="profile-picture"
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                      roundedCircle
                      width="56"
                      height="56"
                    />
                  )}

                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    {item.content_creator_id ===
                    userDetails.user_external_id ? (
                      <span className="contentCreatorName">
                        {item.username}
                      </span>
                    ) : (
                      <span
                        className="contentCreatorName"
                        onClick={() => handleOtherUser(item.content_creator_id)}
                      >
                        {item.username}
                      </span>
                    )}

                    {item.content_creator_id ===
                    userDetails.user_external_id ? (
                      ""
                    ) : (
                      <>
                        {item.is_following ? (
                          <>
                            <span style={{ margin: "0 0.5rem" }}>•</span>{" "}
                            <span
                              style={{ color: "#5b5b5b", fontWeight: "400" }}
                            >
                              {" "}
                              {t("post.following")}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>
                      {getPostedTime(item.content_created_on)}
                    </div>
                  </div>
                </div>
                {item.content_creator_id === userDetails.user_external_id ? (
                  ""
                ) : (
                  <ReportPost contentExternalId={item.content_external_id} />
                )}
                {/* <ReportPost /> */}
              </div>
            </Card.Header>
            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && (
                    <Card.Text>
                      <Linkify tagName="span">
                        {item.content_description}
                      </Linkify>
                    </Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description.substring(0, len) + "..."}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          {t("post.seeMore")}
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          {t("post.seeLess")}
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>
            {/*pool info */}
            <div className="post-info">
              <div>
                <div className="profile-comment-pooled-raised">
                  {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                  <div
                    // onClick={() => {
                    //   handleModal(item.content_external_id);
                    // }}
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <div className="userImageWrapper">
                      <ul className="hx_flex-avatar-stack">
                        {/* {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                            .slice(0, 4)
                            .map((people_pooled, index) => {
                              return (
                                <li className="hx_flex-avatar-stack-item">
                                  <img
                                    className="avatar avatar-user"
                                    alt="@jqn"
                                    onError={(ev) =>
                                      (ev.target.src = `${avatar}`)
                                    }
                                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                    width="20"
                                    height="20"
                                  />
                                </li>
                              );
                            })
                          : ""} */}
                      </ul>
                    </div>
                    <span className="profile-pooled">
                      {" "}
                      {item.total_people_pooled} People pooled
                    </span>
                  </div>
                  <span style={{ margin: "0 0.5rem" }}> • </span>
                  <img src={pooledCollect} alt="pooledCollect.svg" />

                  <p className="profile-pooled">
                    <span className="profile-user-pooled-value">
                      {" "}
                      {userDetails.country_wallet_currency_sign}
                      {item.total_amount_pooled_converted}{" "}
                    </span>
                    Pool collected
                  </p>
                  {/* <span className="profile-pooled"> Pool collected</span> */}
                </div>
              </div>

              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}{" "}
                  {item.totalcomments > "1"
                    ? t("post.commentPlural")
                    : t("post.commentSingular")}
                </span>
              </div>
            </div>


            <div className="activity-page-charity">
              <img
                className="charity-logo"
                src={item.cause_image}
                alt="charity-logo"
              />
              <ul className="activity-page-content">
                <li className="activity-page-charityName">{item.cause_name}</li>

                <li className="activity-page-charityLocation">
                  {item.country_name}
                </li>
              </ul>
              {charitiesList &&
                charitiesList.map((charity) => {
                  if (item.cause_name == charity.cause_name) {
                    return (
                      <>
                        <CharityInfo
                          contentExternalID={item.content_external_id}
                          causeImage={charity.cause_image}
                          causeName={charity.cause_name}
                          causeCountryName={charity.country_name}
                          causeDescription={charity.cause_description}
                          causeURL={charity.cause_url}
                        />
                      </>
                    );
                  }
                })}
            </div>

            {/**Slider starts***************************************** */}
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.transaction_amount}
            />
            {/*Slider ends *********************************************/}
            {/* comment count:{item.totalcomments} */}
            <div className="comments-share">
              {/* < div className="comments-share"> */}
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
                contentCreatorId={item.content_creator_id}
              />
              {/* <Comments contentExternalId={item.content_external_id} contentCreatorId={item.content_creator_id} /> */}
            </div>
          </Card>
        </>
      );
    } else {
      return (
        <>
          {/* {(disablebtn === index) ? <Confetti width={width} recycle={showConfetti} /> : ''} */}
          {/* {showConfettiCard ? <Confetti width={width} recycle={false} /> : ''} */}
          <Card
            className="Activitypage-card"
            key={item.content_external_id}
            style={{ marginBottom: "1rem" }}
          >
            <Card.Header className="activitypage-header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.content_creator_id === userDetails.user_external_id ? (
                    <Image
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      className="profile-picture"
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                      roundedCircle
                      width="56"
                      height="56"
                    />
                  ) : (
                    <Image
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      onClick={() => handleOtherUser(item.content_creator_id)}
                      className="profile-picture"
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                      roundedCircle
                      width="56"
                      height="56"
                    />
                  )}
                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    {item.content_creator_id ===
                    userDetails.user_external_id ? (
                      <span className="contentCreatorName">
                        {item.username}
                      </span>
                    ) : (
                      <span
                        className="contentCreatorName"
                        onClick={() => handleOtherUser(item.content_creator_id)}
                      >
                        {item.username}
                      </span>
                    )}

                    {/* {item.content_creator_id === userDetails.user_external_id ? "" : <FollowUnfollow followInfo={item.is_following} />} */}
                    {/* <FollowUnfollow /> */}
                    {item.content_creator_id ===
                    userDetails.user_external_id ? (
                      ""
                    ) : (
                      <>
                        {item.is_following ? (
                          <>
                            <span style={{ margin: "0 0.5rem" }}>•</span>{" "}
                            <span
                              style={{ color: "#5b5b5b", fontWeight: "400" }}
                            >
                              {" "}
                              {t("post.following")}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>
                      {getPostedTime(item.content_created_on)}
                    </div>
                  </div>
                </div>
                {item.content_creator_id === userDetails.user_external_id ? (
                  ""
                ) : (
                  <ReportPost contentExternalId={item.content_external_id} />
                )}
              </div>
            </Card.Header>

            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && (
                    <Card.Text>
                      <Linkify tagName="span">
                        {item.content_description}
                      </Linkify>
                    </Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description.substring(0, len) + "..."}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          {t("post.seeMore")}
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          {t("post.seeLess")}
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}
            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>

            {/*pool info */}
            <div className="post-info">
              <div>
                <div className="profile-comment-pooled-raised">
                  {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                  <div
                    // onClick={() => {
                    //   handleModal(item.content_external_id);
                    // }}
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <div className="userImageWrapper">
                      <ul className="hx_flex-avatar-stack">
                        {/* {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                            .slice(0, 4)
                            .map((people_pooled, index) => {
                              return (
                                <li className="hx_flex-avatar-stack-item">
                                  <img
                                    className="avatar avatar-user"
                                    alt="@jqn"
                                    onError={(ev) =>
                                      (ev.target.src = `${avatar}`)
                                    }
                                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                    width="20"
                                    height="20"
                                  />
                                </li>
                              );
                            })
                          : ""} */}
                      </ul>
                    </div>
                    <span className="profile-pooled">
                      {" "}
                      {item.total_people_pooled} {t("post.peoplePooled")}
                    </span>
                  </div>
                  <span style={{ margin: "0 0.5rem" }}> • </span>
                  <img src={pooledCollect} alt="pooledCollect.svg" />

                  <p className="profile-pooled">
                    <span className="profile-user-pooled-value">
                      {" "}
                      {userDetails.country_wallet_currency_sign}
                      {item.total_amount_pooled_converted}{" "}
                    </span>
                    {t("post.poolCollected")}
                  </p>
                  {/* <span className="profile-pooled"> Pool collected</span> */}
                </div>
              </div>

              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}{" "}
                  {item.totalcomments > "1"
                    ? t("post.commentPlural")
                    : t("post.commentSingular")}
                </span>
              </div>
            </div>

            <div className="activity-page-charity">
              <img
                className="charity-logo"
                src={item.cause_image}
                alt="charity-logo"
              />

              <ul className="activity-page-content">
                <li className="activity-page-charityName">
                  {item.cause_name}{" "}
                </li>

                <li className="activity-page-charityLocation">
                  {item.country_name}
                </li>
              </ul>
              {charitiesList &&
                charitiesList.map((charity) => {
                  // console.log(charity)
                  if (item.cause_name == charity.cause_name) {
                    return (
                      <>
                        <CharityInfo
                          contentExternalID={item.content_external_id}
                          causeImage={charity.cause_image}
                          causeName={charity.cause_name}
                          causeCountryName={charity.country_name}
                          causeDescription={charity.cause_description}
                          causeURL={charity.cause_url}
                        />
                      </>
                    );
                  }
                })}
            </div>
            {/* <br /> */}

            {/**Slider starts***************************************** */}
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.transaction_amount}
              goldenBuzzer={item.is_goldenbuzzer}
            />
            {/*Slider ends *********************************************/}
            <div className="comments-share">
              {/* < div className="comments-share"> */}
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
                contentCreatorId={item.content_creator_id}
                // Commentcount={item.totalcomments}
              />
              {/* {(Commentsection == index) ? <Comments contentExternalId={item.content_external_id} contentCreatorId={item.content_creator_id} /> : ""} */}
            </div>
          </Card>
        </>
      );
    }
  });

  const showMessage = () => {
    if (loading) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>Please Wait...</div>
      );
    }
    if (error) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>
          Something went wrong. Please try reloading the page to logging in
          again
        </div>
      );
    }
    if (!hasMore) {
      return <div style={{ height: "50px", fontWeight: "700" }}></div>;
    }
  };

  return (
    <>
      {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
      {activityCards}
      {showMessage()}
    </>
  );
};

export default Contents;
