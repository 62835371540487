import { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, Avatar, CardContent, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import x from "../../assets/svg/x.svg";
import infoIcon from "../../assets/svg/infoIcon.svg";
// import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    headerBorder: {
        boxShadow: '0px 0px 2px 0px'
    },
    CardWrapper: {
        position: 'absolute',
        bottom: '3rem',
        zIndex: '1'
    },
    charityInfoWrapper: {
        position: 'relative'
    },
    arrowInfo: {
        color: '#fff',
        textShadow: '0px 2px 2px #b5b8ba',
        position: 'absolute',
        bottom: '11px',
        left: '4px',
        zIndex: '1',
    },
    charityLogo: {
        maxWidth: '4rem',
        minWidth: '2rem',
        height: '40px',
        objectFit: 'contain',
    }
    , paraMargin: {
        marginTop: '0.6rem'
    },
    charityHeader: {
        fontSize: '20px',
        fontWeight: '500',
        color: '#3B3B3B',
        fontFamily: 'DM Sans',
    },
    charityURL: {
        color: '#2077B1',
        fontWeight: '500'
    }
}))


const CharityInfo = ({ contentExternalID, causeImage, causeName, causeCountryName, causeDescription, causeURL }) => {
    const classes = useStyles();
    const [showInfo, setShowInfo] = useState("");
    const CharityInfoRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // console.log(entry.isIntersecting)

                if (entry.isIntersecting) {
                    setShowInfo("")
                }
            },
            // { threshold: [1] }
        );

        if (CharityInfoRef.current) {
            observer.observe(CharityInfoRef.current);
        }

    }, [CharityInfoRef])

    // console.log(CharityInfoRef.target)
    const handleCharityInfo = () => {
        setShowInfo(contentExternalID)
    }

    const handleClose = () => {
        setShowInfo("");
    }

    return (
        <>
            <span className={classes.charityInfoWrapper}>
                {showInfo === contentExternalID ? <span className={classes.arrowInfo}>▼
                </span> : ''}
                <img src={infoIcon} alt="info-icon" onClick={handleCharityInfo} ref={CharityInfoRef} />
            </span>

            {showInfo === contentExternalID ? <Card className={classes.CardWrapper} >
                <CardHeader className={classes.headerBorder}
                    avatar={
                        <img alt="cause-image" src={`${causeImage}`} className={classes.charityLogo} />
                    }
                    title={<p className={classes.charityHeader}>{`${causeName}`}</p>}
                    subheader={`${causeCountryName}`}
                    action={
                        <IconButton
                            onClick={handleClose}
                        >
                            <img className="charity-close-button" src={x} alt="close-button" />
                        </IconButton>
                    }
                />
                <CardContent>
                    <h6>About</h6>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {causeDescription}
                        <p className={classes.paraMargin}>Find out more about charity at{" "}
                            <a
                                className={classes.charityURL}
                                href={`${causeURL}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {causeURL}
                            </a></p>
                    </Typography>
                </CardContent>
            </Card> : ""
            }

        </>
    )
}

export default CharityInfo
