import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/scss/Charity/Charity.scss";
import { Row, Col, Dropdown, Container, DropdownButton } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import charityData from "./Data.json";
import dropdownButtonArrow from "../../assets/svg/dropdownButtonArrow.svg";
import banner from "../../assets/images/charity-banner-new.jpg";
import charityLogo1 from "../../assets/images/charity-profile-1.png";
import charityLogo2 from "../../assets/images/charity-profile-2.png";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Charity = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//
  const userDetails = useSelector((state) => state.userDetails); //Converted the profileDetailsById to userDetails
  const charities = useSelector((state) => state.charityNames);
  const [selectedCharities, setSelectedCharities] = useState(() => charities.data);
  const [charityColumn1, setCharityColumn1] = useState("");
  const [charityColumn2, setCharityColumn2] = useState("");
  const [charityID, setCharityID] = useState("");
  const [charity, setCharity] = useState("");
  const [countryID, setCountryID] = useState("");
  const allowedCountries = useSelector((state) => state.allowedCountries);
  const allowedCountryName = Object.values(allowedCountries);
  const [countryName, setCountry] = useState("");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  const useStyles = makeStyles((theme) => ({
    dropdown: {
      '& [class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    selectedCharities.forEach((charityInfo, index) => {
      charityInfo.serial = index + 1;
    });
  }, [selectedCharities, countryName])

  useEffect(() => {
    if (countryName) {
      let selectedCharitiesArr = charities.data.filter(cause => cause.country_name === countryName)
      setSelectedCharities(selectedCharitiesArr)
    } else {
      setSelectedCharities(charities.data)
    }
  }, [charities.data, countryName])

  console.log({ selectedCharities })

  // selectedCharities.forEach((charityInfo, index) => {
  //   charityInfo.serial = index + 1;
  // });

  // console.log(charities)
  // Creating Refs for charity cards
  // let column1Ref = useRef([createRef(), createRef(), createRef()]);
  // let column2Ref = useRef([createRef(), createRef(), createRef(),createRef(), createRef(), createRef(),createRef(), createRef(), createRef()]);



  let arr = [];
  for (let i = 0; i <= selectedCharities.length / 2; i++) {
    arr.push(createRef());
  }
  let column1Ref = useRef(arr);

  let arr2 = [];
  for (let i = 0; i < selectedCharities.length / 2; i++) {
    arr2.push(createRef());
  }
  let column2Ref = useRef(arr2);

  // Creating Refs for filter tabs
  let tabRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);
  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  // const handleCountry = (e) => {
  //   if (e !== null) {
  //     const country = e;
  //     const country_arr = country.split(",");
  //     setCountryID(country_arr[0]);
  //     setCountryName(country_arr[1]);
  //     setCharity("");
  //     setCharityID("");
  //   } else {
  //     setCountryID("");
  //     setCountryName("");
  //   }
  // };

  const handleCountry = (e, value) => {
    // console.log(value);
    if (value != null) {
      let countryExternalId = value.country_external_id;

      if (countryExternalId !== "") {
        setCountryID(countryExternalId);
        Object.values(allowedCountries).map((country) => {
          if (country.country_external_id === countryExternalId) {
            setCountry(country.country_name);
            setCharity("");
            setCharityID("");
          }
        });
      } else if (countryExternalId === "") {
        setCountryID("");
        setCountry("");
        setCharity("");
        setCharityID("");
      }
    } else if (value == null) {
      setCountry(null)
      setCountryID(null)
      // setCountryID(userDetails.country_external_id);
      // setCountry(userDetails.country_name);
    }
  }
  //--------------- Filter tab scroll functionality -------------------//
  const scrollLeft = (element, change, duration) => {
    var start = element.scrollLeft,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  //Horizontal scroll added
  const rightArrowRef = useRef(null);

  //To scroll left
  const handleScrollLeft = () => {
    scrollLeft(rightArrowRef.current, -200, 1000);
    //1st parameter refer div wrapper, 2nd parameter distance cover, 3rd parameter duration
  };

  //to scroll right
  const handleScrollRight = () => {
    scrollLeft(rightArrowRef.current, 200, 1000);
  };

  //--------------------- END ----------------------//

  //--------------------- Filter Tabs ---------------------//

  const filterTabs = [
    { text: "All", id: "1" },
    { text: "Animals & pets", id: "2" },
    { text: "Art & Culture", id: "3" },
    { text: "Education", id: "4" },
    { text: "Disability", id: "5" },
    { text: "Health & Medical", id: "6" },
    { text: "International Aid", id: "7" },
    { text: "Filter Tab", id: "8" },
    { text: "Filter Tab", id: "9" },
  ];

  // Highlighting the filter tab that is clicked
  const changebtnColor = (buttonId) => {
    const current = tabRefs.current[buttonId - 1].current;

    let siblings = [];
    let sibling = current.parentNode.firstChild;

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== current) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }

    siblings.forEach((sibling) => {
      sibling.style.color = "black";
    });

    current.style.color = "#2077B1";
  };

  //-------------------------- END -------------------------//

  const upArrow = (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="up-arrow"
    >
      <path
        d="M1 1L5 5L9 1"
        stroke="#9E9E9E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  // Toggle (hide & show) charity card
  const toggleHandler = (id) => {
    // let targetDiv = document.getElementById(id);
    let targetDiv;
    const mid = findMidIndex(); //3
    try {
      if (id > mid) {
        targetDiv = column2Ref.current[id - (mid + 1)].current;
        // targetDiv = column2Ref.current;
      } else {
        targetDiv = column1Ref.current[id - 1].current;
        // targetDiv = column1Ref.current;
      }

      if (targetDiv.style.height === "auto") {
        targetDiv.style.height = "100px";
        targetDiv.style.overflow = "hidden";
        targetDiv.classList.remove("down-arrow");
      } else {
        targetDiv.style.height = "auto";
        targetDiv.classList.add("down-arrow");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedCharities) {
      setCharity1();
      setCharity2();
    }
    // tabRefs.current[0].current.style.color = '#2077B1';
  }, [selectedCharities]);

  const findMidIndex = () => {
    let dataLength = selectedCharities.length;
    let middleIndex = Math.ceil(dataLength / 2);
    return middleIndex;
  };

  const setCharity1 = () => {
    const mid = findMidIndex();

    const tempArr = [];
    for (let i = 0; i < mid; i++) {
      tempArr.push(selectedCharities[i]);
    }

    setCharityColumn1(tempArr);
  };

  const setCharity2 = () => {
    const mid = findMidIndex();

    const tempArr = [];
    for (let i = mid; i < selectedCharities.length; i++) {
      tempArr.push(selectedCharities[i]);
    }
    setCharityColumn2(tempArr);
  };

  const rightArrow = (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="#555"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g filter="url(#filter0_d)"> */}
      <g filter="">
        {/* <rect x="4" y="4" width="24" height="24" rx="12" fill="#555" /> */}
        <path
          d="M15 20L19 16L15 12"
          stroke="#2077B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.12549 0 0 0 0 0.466667 0 0 0 0 0.694118 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const leftArrow = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="#555"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="">
        {/* <rect x="28" y="28" width="24" height="24" rx="12" transform="rotate(-180 28 28)" fill="" /> */}
        <path
          d="M17 12L13 16L17 20"
          stroke="#2077B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.12549 0 0 0 0 0.466667 0 0 0 0 0.694118 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const renderCharity1 =
    charityColumn1 &&
    charityColumn1.map((charity, index) => {
      return (
        <div
          className="charity-section"
          ref={column1Ref.current[index]}
          id={charity.serial}
          key={charity.cause_external_id}

        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src={charity.cause_image}
                  onError={(ev) => (ev.target.src = `${charityLogo1}`)}
                  alt="Charity logo"
                  className="charity-logo"
                />
              </div>

              <div className="ml-3">
                <h3 className="charity-name">{charity.cause_name}</h3>
                <p className="charity-location">{charity.country_name}</p>
              </div>
            </div>

            <div
              className="charity-arrow"
              id="charity-arrow"
              onClick={() => toggleHandler(charity.serial)}
            >
              {upArrow}
            </div>
          </div>

          <div className="charity-info-section-1 pl-3">
            <h3 className="about-title">About</h3>
            <p>{charity.cause_description}</p>
          </div>
          <div className="charity-info-section-2 pl-3">
            {/* <p className='charity-reg-no'>Charity Registration No. <span>{charity.registrationNo}</span></p> */}
            {/* <p>Find out more about charity at <a href="#">{charity.link}</a></p> */}
            {charity.cause_url && (
              <p>
                Find out more about charity at{" "}
                <a
                  href={`${charity.cause_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {charity.cause_url}
                </a>
              </p>
            )}
          </div>
        </div>
      );
    });

  // const renderCharity2 =
  //   charityColumn2 &&
  //   charityColumn2.map((charity, index) => {
  //     return (
  //       <div
  //         className="charity-section"
  //         ref={column2Ref.current[index]}
  //         id={charity.id}
  //         key={charity.id}
  //       >
  //         <div className="d-flex justify-content-between align-items-center">
  //           <div className="d-flex align-items-center">
  //             <div>
  //               <img
  //                 src={charityLogo1}
  //                 alt="Charity logo"
  //                 className="charity-logo"
  //               />
  //             </div>
  //           </div>
  //         </div>

  //         {/* <div className='charity-info-section-2'>
  //         <p className='charity-reg-no'>Charity Registration No. <span>{charity.registrationNo}</span></p>
  //         <p>Find out more about charity at <a href="#">{charity.link}</a></p>
  //       </div> */}
  //       </div>
  //     );
  //   });

  const renderCharity2 =
    charityColumn2 &&
    charityColumn2.map((charity, index) => {
      return (
        <div
          className="charity-section"
          ref={column2Ref.current[index]}
          id={charity.serial}
          key={charity.cause_external_id}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src={charity.cause_image}
                  onError={(ev) => (ev.target.src = `${charityLogo1}`)}
                  alt="Charity logo"
                  className="charity-logo"
                />
              </div>

              <div className="ml-3">
                <h3 className="charity-name">{charity.cause_name}</h3>
                <p className="charity-location">{charity.country_name}</p>
              </div>
            </div>

            <div
              className="charity-arrow"
              id="charity-arrow"
              onClick={() => toggleHandler(charity.serial)}
            >
              {upArrow}
            </div>
          </div>

          <div className="charity-info-section-1 pl-3">
            <h3 className="about-title">About</h3>
            <p>{charity.cause_description}</p>
          </div>

          <div className="charity-info-section-2 pl-3">
            {/* <p className='charity-reg-no'>Charity Registration No. <span>{charity.registrationNo}</span></p> */}
            {/* <p>Find out more about charity at <a href="#">{charity.link}</a></p> */}
            {charity.cause_url && (
              <p>
                Find out more about charity at{" "}
                <a
                  href={`${charity.cause_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {charity.cause_url}
                </a>
              </p>
            )}
          </div>
        </div>
      );
    });

  return (
    <>
      <div>
        <section className="charity-container">
          <ContentNavBar />

          <div className="banner-container">
            <img src={banner} alt="Banner" className="banner-image" />
            <h3 className="banner-text">"{t("charity.bannerTitle")}"</h3>
          </div>
          {/* <Container className="header-contanier"> */}
          {/* <Container className="mt-4"> */}
          <Row className="m-0 p-0">
            <Col md={9} className="mx-auto">
              {/* <Col md={6}></Col> */}
              <Col className="offset-md-6 col-md-6 p-0" style={{ marginTop: '1rem' }}>
                <div className="dropdown-country ml-auto">
                  {/* <div> */}
                  <Autocomplete
                    id="countryFilter"
                    options={allowedCountryName}
                    onChange={(e, value) => handleCountry(e, value)}
                    getOptionLabel={(option) => option.country_name}
                    className={classes.dropdown}
                    renderInput={(params) => {
                      return (
                        <>
                          {userDetails.country_name === null ? (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder={t("charity.country")}
                              fullWidth
                            />
                          ) : (
                            <TextField
                              {...params}
                              variant="outlined"
                              // placeholder={`${userDetails.country_name}`}
                              // placeholder="Country"
                              placeholder={t("charity.country")}
                              fullWidth
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </Col>

            </Col>

          </Row>
          {/* <h3 className="charity-header ml-4">List Of Charities</h3> */}
          {/* <div className="dropdown-container dropdown-country">
                    <img
                      className="dropdown-arrow"
                      src={dropdownButtonArrow}
                      alt="dropdownButtonArrow"
                    />

                    <DropdownButton
                      className="dropdown-btn"
                      onSelect={handleCountry}
                      title={
                        <span className="dropdown-span">
                          {countryName
                            ? text_truncate(countryName, 13)
                            : "Country"}
                        </span>
                      }
                    >
                      <div className="dropdown-options">
                        <Dropdown.Item eventkey=" ">Country</Dropdown.Item>
                        {Object.values(allowedCountries).map(
                          (countryData, index) => (
                            <Dropdown.Item
                              eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                              key={countryData.country_id}
                            >
                              {countryData.country_name}
                            </Dropdown.Item>
                          )
                        )}
                      </div>
                    </DropdownButton>
                  </div>
           */}

          {/* </Container> */}
          {/* <Row className = 'm-0 filterTab-container'>
            <Col md={9} className = 'p-0 mx-auto'>
              <div className="filterTab-div">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleScrollLeft}
                  className="filter-arrows"
                >
                  {leftArrow}
                </span>
                <div ref={rightArrowRef} className="scroll-menu">
                  {filterTabs.map((filterTab, index) => {
                    return (
                      // <p key={index} onClick={() => setCaption(caption + filterTab.text + ' ')} className="filterTab">{filterTab.text}</p>

                      <p
                        style={{ backgroundColor: "#F4F4F4", color: "black" }}
                        key={index + 1}
                        className="filterTab"
                        onClick={() => changebtnColor(filterTab.id)}
                        ref={tabRefs.current[index]}
                      >
                        {filterTab.text}
                      </p>
                    );
                  })}
                </div>
                <span style={{ cursor: "pointer" }} onClick={handleScrollRight} className = 'filter-arrows tab-right-arrow'>{rightArrow}</span>
                </div>
              </Col>
          </Row> */}

          <Row className="m-0 p-0">
            <Col md={9} className="mx-auto">
              <div className="charity-main-container">
                <div className="charity-section-container">
                  {renderCharity1}
                </div>

                <div className="charity-section-container">
                  {renderCharity2}
                </div>
              </div>
            </Col>
          </Row>
          <LeanmoreFooter />
        </section>
      </div>
    </>
  );
};

export default Charity;
