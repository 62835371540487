import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
//comment
import ScrollMemory from "react-router-scroll-memory";
//Importing the components from the index file in components
import {
  LandingPage,
  LoginPage,
  SignUpPage,
  NexmoOTP,
  HomePage,
  OtherUserProfile,
  PageNotFound,
  AddBalance,
  TransactionDetails,
  LeaderBoard,
  ProfileSetup,
  Profile,
  // ProfileEdit,
  EnterPassword,
  ProfilePassword,
  ResetPassword,
  SendLinkPassword,
  Notification,
  Charity,
  Achievements,
  ErrorBoundary,
  RapydCheckout,
  PaymentGateway,
  StripePayment,
  // StripePaymentApple,
  ShareSheet,
  SettingPrivacy,
  RazorpayCheckout,
  Faqs,
  People,
  Contact,
  CharityContact,
  SearchPosts,
  SearchUser,
  SearchCharity,
} from "./components";

//Importing Other files
import {
  NexmoPrivateRoute,
  JWTPrivateRoute,
  LoginUserPrivateRoute,
  UserExternalIdPrivateRoute,
  SmallDevices,
} from "./PrivateRoutes";
import store from "./store";
import IdleTimer from "./components/IdleTimerContainer";
import TermsAndConditions from "./components/SettingsPrivacy/TermsAndConditions";
import PrivacyPolicy from "./components/SettingsPrivacy/PrivacyPolicy";
import SuccessfulPayment from "./components/LandingLearnMore/SuccessfulPayment";
import HelpAndSupport from "./components/Login/HelpAndSupport";
// import PeoplePooledModal from "./components/UserProfile/PeoplePooledModal";
// import StripePaymentCheckout from "./components/Stripe/StripePaymentCheckout";
// import AppleCheckoutForm from "./components/Stripe/AppleCheckoutForm";
import LoginPageNew from "./components/Login/LoginPageNew";
import PostApple from "./components/iOS/PostApple";
import SearchPost from "./components/SearchFilter/SearchPost";
// import ContentNavbar from "./components/ContentNavbar/ContentNavbar";

function App() {
  // React.useEffect(() => {
  //   const msg = firebase.messaging();
  //   msg.requestPermission().then(() => {
  //     return msg.getToken();
  //   }).then((data) => {
  //     console.warn("firebase-token", data)
  //   })
  // })

  return (
    <>
      <Provider store={store}>
        <ErrorBoundary>
          <Router>
            <ScrollMemory />
            <IdleTimer />

            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Route path="/successPayment" component={SuccessfulPayment} />

              {/* <SmallDevices path="/login" exact component={LoginPage} />
              <SmallDevices path="/signup" exact component={SignUpPage} /> */}
              {/* <Route path="/login" component={LoginPage} /> */}
              {/* <JWTPrivateRoute path="/login" component={LoginPageNew} /> */}
              <Route path="/login" component={LoginPageNew} />
              {/* <JWTPrivateRoute path="/signup" component={SignUpPage} /> */}
              <Route path="/signup" component={SignUpPage} />

              <NexmoPrivateRoute path="/verify" component={NexmoOTP} />
              <UserExternalIdPrivateRoute
                path="/profile-password"
                component={ProfilePassword}
              />
              <UserExternalIdPrivateRoute
                path="/password"
                component={EnterPassword}
              />
              <Route
                path="/send-password-link"
                component={SendLinkPassword}
              />
              <Route path="/reset-password" component={ResetPassword} />
              <UserExternalIdPrivateRoute
                path="/profile-setup"
                component={ProfileSetup}
              />
              {/* <JWTPrivateRoute path='/loginpassword' exact component={LoginPassword} /> */}
              {/* <SmallDevices path="/home" component={HomePage} /> */}
              <Route path="/home" component={HomePage} />
              <LoginUserPrivateRoute
                path="/otheruserprofile"
                component={OtherUserProfile}
              />
              <LoginUserPrivateRoute
                path="/leaderboard"
                component={LeaderBoard}
              />
              <LoginUserPrivateRoute path="/charity" component={Charity} />
              <LoginUserPrivateRoute
                path="/achievement"
                component={Achievements}
              />

              <LoginUserPrivateRoute
                path="/notifications"
                component={Notification}
              />
              <LoginUserPrivateRoute
                path="/addbalance"
                component={AddBalance}
              />
              <LoginUserPrivateRoute
                path="/transactionDetails"
                component={TransactionDetails}
              />
              <LoginUserPrivateRoute path="/pay" component={PaymentGateway} />
              <Route path="/ios/pay" component={StripePayment} />
              <LoginUserPrivateRoute path="/profile" component={Profile} />
              {/* <JWTPrivateRoute path='/profile-edit' component={ProfileEdit} /> */}
              <LoginUserPrivateRoute
                path="/settingAndPrivacy"
                component={SettingPrivacy}
              />
              <LoginUserPrivateRoute
                path="/helpAndSupport"
                component={HelpAndSupport}
              />

              {/* <JWTPrivateRoute path='/following' component={Following} /> */}
              {/* <Route path='/create' component={ContentNavbar} /> */}
              <LoginUserPrivateRoute path="/rapyd" component={RapydCheckout} />
              <LoginUserPrivateRoute path="/stripe" component={StripePayment} />
              <LoginUserPrivateRoute
                path="/razorpay"
                component={RazorpayCheckout}
              />
              <Route
                path="/share/:content_external_id"
                component={ShareSheet}
              />
              <Route
                path="/posts/:content_external_id"
                component={ShareSheet}
              />
              <Route
                path="/slider/:content_external_id"
                component={PostApple}
              />
              <Route
                path="/notification/:content_external_id"
                component={ShareSheet}
              />
              <Route path="/terms" component={TermsAndConditions} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/faqs" component={Faqs} />
              <Route path="/ourPeople" component={People} />
              <Route path="/charityContact" component={CharityContact} />
              <Route path="/contact" component={Contact} />
              <Route path="/search/posts" component={SearchPosts} />
              <Route path="/search/people" component={SearchUser} />
              <Route path="/search/charity" component={SearchCharity} />
              <JWTPrivateRoute component={PageNotFound} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </Provider>
    </>
  );
}

export default App;
