import React, { useState, useRef, useEffect, useCallback } from 'react';
import useIncomingPagination from '../../actions/customHooks/useIncomingPagination';

//css imports
import { Row, Col } from "react-bootstrap";

import transactionDownArrow from "../../assets/svg/transaction-down-arrow.svg";
// import transactionUpArrow from "../../assets/svg/transaction-up-arrow.svg";

const OutgoingTransaction = ({ type, countryCurrencySign, fromDate, toDate }) => {
    // console.log(props)
    const [pageNo, setPageNo] = useState(1);
    const observer = useRef();

    useEffect(() => {
        setPageNo(1)
    }, [toDate])

    const { loading, error, contentsArray, hasMore } = useIncomingPagination(pageNo, type, fromDate, toDate);

    const lastElementInContent = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPageNo((prevpageNo) => prevpageNo + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const outgoingTransaction = contentsArray.map((obj, index) => {
        // console.log(obj)
        let transactionDuration = obj.transaction_created_on;
        let date = new Date(transactionDuration);

        let dateFormat = date.toDateString();
        let finalDate = dateFormat.split(" ");

        var hours = date.getHours(); //UTC give you the hours without the timezone offset.
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        let dayMonthYear =
            finalDate[2] +
            " " +
            finalDate[1] +
            " " +
            finalDate[3] +
            "," +
            " " +
            strTime;

        if (contentsArray.length === index + 1) {
            return (
                <Row key={index} className="transaction-body"
                    ref={lastElementInContent}
                >
                    <Col
                        sm={12}
                        md={12}
                        lg={12}
                        className="transaction-content-wrapper"
                    >
                        <div className="transaction-content">
                            <div className="transaction-content-column">
                                <div className="img-transaction">
                                    <img
                                        src={transactionDownArrow}
                                        alt="transactionArrow"
                                    />
                                </div>
                                <div className="transaction-detail-content">
                                    {/* <p>Contributed To {transactionDetails[obj].charity_name}</p> */}
                                    <p>Pooled to {obj.username}'s post</p>
                                    <span style={{ textTransform: "uppercase" }}>
                                        {dayMonthYear}
                                    </span>
                                    <div className="transaction-card-id">
                                        <span className="transaction-id">
                                            Money debited from Your Pool
                          </span>
                                    </div>
                                </div>
                            </div>
                            <div className="transaction-amount">
                                -{countryCurrencySign}
                                {obj.transaction_amount}
                            </div>
                        </div>
                    </Col>

                </Row>
            )
        }
        return (
            <Row key={index} className="transaction-body">
                <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="transaction-content-wrapper"
                >
                    <div className="transaction-content">
                        <div className="transaction-content-column">
                            <div className="img-transaction">
                                <img
                                    src={transactionDownArrow}
                                    alt="transactionArrow"
                                />
                            </div>
                            <div className="transaction-detail-content">
                                {/* <p>Contributed To {transactionDetails[obj].charity_name}</p> */}
                                <p>Pooled to {obj.username}'s post</p>
                                <span style={{ textTransform: "uppercase" }}>
                                    {dayMonthYear}
                                </span>
                                <div className="transaction-card-id">
                                    <span className="transaction-id">
                                        Money debited from Your Pool
                          </span>
                                </div>
                            </div>
                        </div>
                        <div className="transaction-amount">
                            -{countryCurrencySign}
                            {obj.transaction_amount}
                        </div>
                    </div>
                </Col>

            </Row>

        );
        // };
    });
    const showMessage = () => {
        if (loading) {
            return (
                <div style={{ height: "50px", fontWeight: "700" }}>...</div>
            );
        }
        if (error) {
            return (
                <div style={{ height: "50px", fontWeight: "700" }}>
                    Something went wrong. Please try reloading the page to logging in
                    again
                </div>
            );
        }
        if (!hasMore) {
            return <div style={{ height: "50px", fontWeight: "700" }}></div>
        }
    };
    return (
        <>
            {outgoingTransaction}
            {showMessage()}
        </>
    )

}

export default OutgoingTransaction
