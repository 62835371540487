import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NexmoPrivateRoute = ({ component: Component, ...rest}) => { 

    const phoneNumber = useSelector(state => state.nexmoPhoneNumber.phone)

    return (
        <Route 
            {...rest}
            render = {props => {
                if (!phoneNumber) {
                    return <Redirect to="/login" />
                } else {
                    return <Component {...props} />
                }
            }}
        />
    )
}

export default NexmoPrivateRoute