import { USER_PROFILE_DETAILS } from '../../constants/action-types'

const initState = {}

const UserProfileDetailsByIdReducer = (state = initState, action) => {
    if (action.type === USER_PROFILE_DETAILS) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state;
}
export default UserProfileDetailsByIdReducer;