import axios from 'axios'
import { BASEURL } from '../config'

export const AddNotificationFcmId = (fcmId) => {
    return axios({
        method: 'POST',
        url: `${BASEURL}/v1/add/fcmid`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': externalId
        },
        withCredentials: true,
        data: {
            user_fcm_id: fcmId,
            device: "Web"
        }
    })
        .then(response => response)
        .catch(e => {
            console.error(e)
        })
}
export const GetNotification = () => {
    return axios({
        method: 'POST',
        url: `${BASEURL}/v1/getUserNotifications`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true,
    })
        .then(response => response.data.response.data)
        .catch(e => {
            console.error(e)
        })
}