import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { useDispatch } from "react-redux";

import "../../assets/scss/LandingLearnMore/passwordScreenStyle.scss";

import brandLogo from "../../assets/svg/brandLogo.svg";

const SuccessfulPayment = () => {
  return (
    <section className="password-screen">
      <Container>
        <Row>
          <Col sm={8} md={6} className="mx-auto">
            <div className="mobileView">
              <img src={brandLogo} width="66" height="48" alt="brand-logo" />
              <p>Payment Successful</p>
              <p>Please close window to continue</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SuccessfulPayment;
