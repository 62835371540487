import { TEMP_PASSWORD_DATA } from '../constants/action-types';

const initState = {}

const TempPasswordDataReducer = (state = initState, action) => {
    if(action.type === TEMP_PASSWORD_DATA){
        return{
            ...state,
            ...action.payload
        }
    }
    return state
}

export default TempPasswordDataReducer;