import { NEXMO_OTP_SENT } from '../../constants/action-types'

const initState = {}

const nexmoOTPReducer = (state = initState, action) => {
    if (action.type === NEXMO_OTP_SENT) {
        return {
            ...state,
            ...action.payload.data
        }
    }
    return state
}

export default nexmoOTPReducer