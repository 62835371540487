import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Button,
  Container,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import { UPDATE_PASSWORD } from "../../../constants/action-types";
// import axios from 'axios';
//axiosInstance import
import axiosInstance from "../../../axios/axios";

//file imports
import { updatePassword } from "../../../actions/login";

//CSS imports
import "../../../assets/scss/Login/enterPassword.scss";
import LeanmoreFooter from "../../LandingLearnMore/LeanmoreFooter";
import LoginNavbar from "../../LandingLearnMore/LoginNavbar";
import x from "../../../assets/svg/x.svg";

import correctPassword from "../../../assets/svg/correct-password.svg";
import mismatchPassword from "../../../assets/svg/mismatch-password.svg";

import { BASEURL } from "../../../config";
import { useTranslation } from "react-i18next";


const passwordChecks = {
  lowercase: "",
  uppercase: "",
  number: "",
  special: "",
  eightChars: "",
};

const ResetPassword = (props) => {
  const updatePasswordResponse = useSelector(
    (state) => state.resetPassword.data
  );

// Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const [Password, setPassword] = useState("");
  const [ReTypedPassword, setReTypedPassword] = useState("");
  const [showPasswordIcon, setPasswordIcon] = useState(false);
  const [showReenterPasswordIcon, setReenterPasswordIcon] = useState(false);
  const [changePasswordBtn, setChangePasswordBtn] = useState(false);
  const [passwordMessage, setpasswordMessage] = useState("");
  const [passwordMatchedmessage, setPasswordMatchedMessage] = useState("");
  const [resetPwdResponseMsg, setresetPwdResponseMsg] = useState("");
  const [enterPwdScreen, setEnterPwdScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [passwordRuleMsg, setPasswordRuleMsg] = useState(
    t("resetPassword.passwordRuleMsg")
  );
  const [passwordValidation, setPasswordValidation] = useState("");
  const [pwdSuccessMsg, setPwdSuccessMsg] = useState("");

  const [resetToken, setResetToken] = useState("");
  const [userExternalID, setUserExternalID] = useState("");

  const [showPasswordOptions, setShowPasswordOptions] = useState(false);
  const [togglePasswordOptions, setTogglePasswordOptions] = useState(false);



  const dispatch = useDispatch();
  const inputRef = useRef();

  const hidePassword = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 1L17 17"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const showPassword = (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (Password === ReTypedPassword && Password.length > 0) {
      setChangePasswordBtn(true);
      setIsLoading(false);
      setpasswordMessage("");
      setPasswordMatchedMessage(
        t("resetPassword.formData.validations.pwdMatch")
      );
    } else if (ReTypedPassword.length > 0) {
      setChangePasswordBtn(false);
      setIsLoading(false);
      setpasswordMessage(t("resetPassword.formData.validations.pwdNotMatch"));
      setPasswordMatchedMessage("");
    }
  }, [Password, ReTypedPassword]);

  useEffect(() => {
    if (Password === ReTypedPassword && Password.length > 0) {
      setChangePasswordBtn(true);
    }
  }, [Password, ReTypedPassword]);

  useEffect(() => {
    // console.log({updatePasswordResponse})
    if (updatePasswordResponse) {
      if (updatePasswordResponse.response.statusCode === 200) {
        setresetPwdResponseMsg(
          t("resetPassword.formData.pwdUpdateSuccessText")
        );
        setEnterPwdScreen(true);
      } else {
        setresetPwdResponseMsg(
          t("resetPassword.formData.pwdUpdateFailureText")
        );
        setEnterPwdScreen(false);
      }
    }
  }, [updatePasswordResponse]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    // console.log(query)
    const resetToken = query.get("resetToken");
    const user_external_id = query.get("user_external_id");
    // console.log(`${resetToken} ${user_external_id}`)
    setResetToken(resetToken);
    setUserExternalID(user_external_id);
  }, []);

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const myFunction = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      setPasswordIcon(true);
    } else {
      x.type = "password";
      setPasswordIcon(false);
      setReenterPasswordIcon(false);
    }
  };
  const reEnterPassword = () => {
    var x = document.getElementById("ReEnterPasswordInput");
    if (x.type === "password") {
      x.type = "text";
      setReenterPasswordIcon(true);
    } else {
      x.type = "password";
      setReenterPasswordIcon(false);
    }
  };
  const passwordValidate = () => {
    if (Password.length > 0) {
      var lower = /(?=.*[a-z])/;
      var upper = /(?=.*[A-Z])/;
      var value = /(?=.*\d)/;
      var spclchar = /(?=.*[!@#$%^&*])/;
      if (Password.length < 8) {
        inputRef.current.focus();
        setPasswordValidation(
          t("resetPassword.formData.validations.pwdMinChars")
        );
        setPasswordRuleMsg("");
        // setPwdSuccessMsg("");
        return false;
      } else if (!lower.test(Password)) {
        setPasswordValidation(
          t("resetPassword.formData.validations.pwdLowercase")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (!upper.test(Password)) {
        setPasswordValidation(
          t("resetPassword.formData.validations.pwdUppercase")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (!value.test(Password)) {
        setPasswordValidation(
          t("resetPassword.formData.validations.pwdNumber")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (!spclchar.test(Password)) {
        setPasswordValidation(
          t("resetPassword.formData.validations.pwdSplChars")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else {
        setPasswordValidation("");
        // setPwdSuccessMsg("Password is strong");
        setPasswordRuleMsg("");
        return true;
      }
    }
  };

  const handlePwdKeyUp = () => {
    setShowPasswordOptions(true);
    setPasswordRuleMsg("");
    //   let lower = /(?=.*[a-z])/;
    //   let upper = /(?=.*[A-Z])/;
    //   let numbers = /(?=.*\d)/;
    //   let spclchar = /(?=.*[!@#$%^&*])/;

    const lower = /[a-z]/g;
    const spclchar = /([@!#$%^&_*])/g;
    const numbers = /[0-9]/g;
    const upper = /[A-Z]/g;

    //validate lowerCase
    if (inputRef.current.defaultValue.match(lower)) {
      passwordChecks.lowercase = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("resetPassword.formData.passwordHints.lowercase")}
        </li>
      );
    } else {
      passwordChecks.lowercase = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("resetPassword.formData.passwordHints.lowercase")}
        </li>
      );
    }

    //validate Uppercase
    if (inputRef.current.defaultValue.match(upper)) {
      passwordChecks.uppercase = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("resetPassword.formData.passwordHints.uppercase")}
        </li>
      );
    } else {
      passwordChecks.uppercase = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("resetPassword.formData.passwordHints.uppercase")}
        </li>
      );
    }

    // Validate numbers
    if (inputRef.current.defaultValue.match(numbers)) {
      passwordChecks.number = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("resetPassword.formData.passwordHints.number")}
        </li>
      );
    } else {
      passwordChecks.number = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("resetPassword.formData.passwordHints.number")}
        </li>
      );
    }

    // Validate special character
    if (inputRef.current.defaultValue.match(spclchar)) {
      passwordChecks.special = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("resetPassword.formData.passwordHints.specialChar")}
        </li>
      );
    } else {
      passwordChecks.special = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("resetPassword.formData.passwordHints.specialChar")}
        </li>
      );
    }

    //Validate password length
    if (Password.length >= 8) {
      passwordChecks.eightChars = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("resetPassword.formData.passwordHints.characters")}
        </li>
      );
    } else {
      passwordChecks.eightChars = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("resetPassword.formData.passwordHints.characters")}
        </li>
      );
    }

    setTogglePasswordOptions(!togglePasswordOptions); //To render the updated passwordChecks values
  };

  const handleSubmit = (e, Password, ReTypedPassword) => {
    e.preventDefault();
    if (passwordValidate() === true) {
      // setIsLoading(true);
      axiosInstance({
        method: "POST",
        url: `${BASEURL}/v1/resetpassword`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${resetToken}`,
          userexternalid: `${userExternalID}`,
        },
        data: {
          user_external_id: userExternalID,
          password: Password,
        },
        withCredentials: true,
      })
        .then((response) =>
          dispatch({
            type: UPDATE_PASSWORD,
            payload: response,
          })
        )
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="password-container">
      <LoginNavbar />
      <Container>
        <div className="row">
          <div className="col-sm-12">
            <Card className="password-card">
              <Card.Header className="password-header">
                {t("resetPassword.resetPwdText")}
                <Link to="/login" className="password-close">
                  <img src={x} alt="close-button" />
                </Link>
              </Card.Header>
              <Card.Body>
                <Form
                  className="password-form"
                  onSubmit={(e) => handleSubmit(e, Password)}
                >
                  <Form.Label className="password-form-label">
                    {t("resetPassword.formData.setPwd")} *
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      size="lg"
                      type="password"
                      id="myInput"
                      placeholder={t(
                        "resetPassword.formData.setPwdPlaceholder"
                      )}
                      onKeyUp={handlePwdKeyUp}
                      onChange={(e) => setPassword(e.target.value)}
                      ref={inputRef}
                      value={Password}
                    />

                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        {showPasswordIcon ? (
                          <span onClick={myFunction}>{showPassword}</span>
                        ) : (
                          <span onClick={myFunction}>{hidePassword}</span>
                        )}
                      </InputGroup.Text>
                    </InputGroup.Append>

                    {/* </Form.Group> */}
                  </InputGroup>
                  <span
                    style={{
                      fontSize: "12px",
                      lineHeight: "18px",
                      display: "block",
                    }}
                  >
                    {passwordRuleMsg}
                  </span>

                  {showPasswordOptions ? (
                    <div className="passwordOptions">
                      <ul>
                        {Object.values(passwordChecks).map((val) => (
                          <span>{val}</span>
                        ))}
                      </ul>
                      {/* <p className="error-message">Use 8 or more characters with a mix of letters, numbers, symbols and not the same as your username</p> */}
                    </div>
                  ) : null}

                  <span className="error-message">{passwordValidation}</span>
                  <span style={{ color: "green" }}>{pwdSuccessMsg}</span>
                  <Form.Group>
                    <Form.Label className="password-form-label">
                      {t("resetPassword.formData.reTypePwd")} *
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        size="lg"
                        type="password"
                        placeholder={t(
                          "resetPassword.formData.reTypePwdPlaceholder"
                        )}
                        id="ReEnterPasswordInput"
                        onChange={(e) => setReTypedPassword(e.target.value)}
                        value={ReTypedPassword}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon3">
                          {showReenterPasswordIcon ? (
                            <span onClick={reEnterPassword}>
                              {showPassword}
                            </span>
                          ) : (
                            <span onClick={reEnterPassword}>
                              {hidePassword}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <span style={{ color: "red" }}>{passwordMessage}</span>
                    <span style={{ color: "green" }}>
                      {passwordMatchedmessage}
                    </span>
                  </Form.Group>
                  {/* {disablebtn ? <Button className="profilesetup-button" type="submit" disabled>Save</Button> : <Button className="profilesetup-button" type="submit">Save</Button>} */}
                  {changePasswordBtn ? (
                    <Button
                      className="password-enable-button"
                      style={{ marginTop: "40px" }}
                      type="submit"
                    >
                      {t("resetPassword.formData.changePwd")}
                    </Button>
                  ) : (
                    <Button
                      style={{ marginTop: "40px" }}
                      className="password-button"
                      type="submit"
                      disabled
                    >
                      {t("resetPassword.formData.changePwd")}
                    </Button>
                  )}
                  <span style={{ color: "green" }}>{resetPwdResponseMsg}</span>
                </Form>

                {enterPwdScreen && (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
      <div className="resetPassword">
        <LeanmoreFooter />
      </div>
    </div>
  );
};
export default ResetPassword;
