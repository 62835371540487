//USER AUTHORIZED
export const USER_AUTHORIZED = "USER_AUTHORIZED";

export const LOG_OUT = "LOG_OUT";

// GOOGLE
export const GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN";
export const GOOGLE_SIGN_OUT = "GOOGLE_SIGN_OUT";
export const GOOGLE_USER_DATA = "GOOGLE_USER_DATA";

// FACEBOOK
export const FACEBOOK_SIGN_IN = "FACEBOOK_SIGN_IN";
export const FACEBOOK_SIGN_OUT = "FACEBOOK_SIGN_OUT";
export const FACEBOOK_USER_DATA = "FACEBOOK_USER_DATA";

//APPLE
// export const APPLE_SIGN_IN = "APPLE_SIGN_IN";
// export const APPLE_SIGN_OUT = "APPLE_SIGN_OUT";
// export const APPLE_USER_DATA = "APPLE_USER_DATA";

// NEXMO
export const NEXMO_OTP_SENT = "NEXMO_OTP_SENT";
export const NEXMO_SIGN_IN = "NEXMO_SIGN_IN";
export const NEXMO_PHONE_STORED = "NEXMO_PHONE_STORED";

//Country List
export const All_COUNTRY_LIST = "All_COUNTRY_LIST";
export const ALLOWED_COUNTRY_LIST = "ALLOWED_COUNTRY_LIST";

// CONTENT
export const USER_CONTENT_DATA = "USER_CONTENT_DATA";

//PROFILE
export const SETUP_PROFILE_DATA = "SETUP_PROFILE_DATA";
export const USER_INFO_DETAILS = "USER_INFO_DETAILS";
export const UPDATE_PROFILE_PICTUTRE = "UPDATE_PROFILE_PICTUTRE";
export const VALIDATE_USERNAME = "VALIDATE_USERNAME";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const GET_FOLLOWING_LIST = "GET_FOLLOWING_LIST";
// export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const GET_FOLLOWERS_LIST = "GET_FOLLOWERS_LIST";
export const GET_FOLLOWERS_COUNT = "GET_FOLLOWERS_COUNT";
export const GET_USER_PROFILE_PICTURE = "GET_USER_PROFILE_PICTURE";
export const GENERATE_NEW_TOKEN = "GENERATE_NEW_TOKEN";

export const STORE_USER_DATA = "STORE_USER_DATA";
export const STORE_USER_DEFAULT_AMT = "STORE_USER_DEFAULT_AMT";
export const SHOW_BALANCE = "SHOW_BALANCE";
export const BALANCE_AMT = "BALANCE_AMT";

//TEMPORARY_PASSWORD
export const TEMP_PASSWORD_DATA = "TEMP_PASSWORD_DATA";

//CREATE POST
export const CREATE_POST_POPUP = "CREATE_POST_POPUP";
export const NEW_POST_CREATE = "NEW_POST_CREATE";

//STORING USERS DETAILS WHO HAVE LOGGED IN
export const GET_TOKEN_DETAILS = "GET_TOKEN_DETAILS";

//PROFILE DETAILS BASED ON EXTERNAL_ID
export const GET_PROFILE_DETAILS_BY_ID = "GET_PROFILE_DETAILS_BY_ID";
export const UPDATE_USER_DETAILS_BY_ID = "UPDATE_USER_DETAILS_BY_ID";
export const USER_PROFILE_DETAILS = "USER_PROFILE_DETAILS";
export const GET_OTHER_USER_PROFILE_DETAILS_BY_ID =
  "GET_OTHER_USER_PROFILE_DETAILS_BY_ID";
// GETTING THE CHARITY NAMES
export const GET_CHARITY_NAMES = "GET_CHARITY_NAMES";

//HASH TAGS
export const HASH_TAG_INFO = "HASH_TAG_INFO";
export const STORE_HASH_TAGS = "STORE_HASH_TAGS";

//razorpay
export const GET_PAYMENT_ORDER_ID = "GET_PAYMENT_ORDER_ID";
export const VERIFY_RAZORPAY_PAYMENT = "VERIFY_RAZORPAY_PAYMENT";

//Content ID
export const STORE_CONTENT_ID = "STORE_CONTENT_ID";

//SEARCH COMPONENT
export const GET_SEARCH_POST = "GET_SEARCH_POST";
export const GET_SEARCH_USER = "GET_SEARCH_USER";
export const GET_SEARCH_CHARITY = "GET_SEARCH_CHARITY";
export const SEARCH_INFO = "SEARCH_INFO";
