export const loadFbLoginApi = () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: 356355962003640, //GoPoolit
      // appId: 587720495272653,
      cookie: true, // enable cookies to allow the server to access the session
      xfbml: true, // parse social plugins on this page
      version: "v2.5", // use version 2.1
      status: true,
    });
  };

  // Load the SDK asynchronously
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    // console.log(fjs);
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};
