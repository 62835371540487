import React, { useState, useEffect, createRef, useRef } from "react";
import { useSelector } from "react-redux";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useTranslation } from "react-i18next";

// Define the Tour component
const Tour = () => {
  const [existingUser, setExistingUser] = useState(true);

  const googleResponse = useSelector((state) => state.googleResponse.response);
  const FBResponse = useSelector((state) => state.FbResponseData.response);
  // const AppleResponse = useSelector((state) => state.AppleResponseData.response);
  const nexmoVerificationResponse = useSelector(
    (state) => state.nexmoVerificationResponse.response
  );

  const country_wallet_currency_sign = useSelector(
    (state) => state.userDetails.country_wallet_currency_sign
  );

  const country_min_amount = useSelector(
    (state) => state.userDetails.country_min_amount
  );
  const country_max_amount = useSelector(
    (state) => state.userDetails.country_max_amount
  );
  const country_golden_buzzer_amount = useSelector(
    (state) => state.userDetails.country_golden_buzzer_amount
  );

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  // const myRef = useRef(createRef());

  var TOUR_STEPS;

  const countryMaxAmount = country_max_amount
    ? (1 * country_max_amount) % 1 === 0
      ? 1 * country_max_amount
      : (1 * country_max_amount).toFixed(2)
    : " ";

  const countryGoldenBuzzerAmount = country_golden_buzzer_amount
    ? (1 * country_golden_buzzer_amount) % 1 === 0
      ? 1 * country_golden_buzzer_amount
      : (1 * country_golden_buzzer_amount).toFixed(2)
    : " ";

  if (window.innerWidth >= 992) {
    TOUR_STEPS = [
      {
        target: ".tour-most-pooled",
        content: t("tours.content.mostPooled"),
        disableBeacon: true,
      },
      {
        target: ".tour-pool",
        content: `${t(
          "tours.content.pool.p1"
        )} ${country_wallet_currency_sign}${country_min_amount} ${t(
          "tours.content.pool.p2"
        )} ${country_wallet_currency_sign}${countryMaxAmount} ${t(
          "tours.content.pool.p3"
        )}`,
        disableBeacon: true,
      },
      {
        target: ".tour-golden-buzzer",
        content: `${t(
          "tours.content.goldenBuzzer"
        )} ${country_wallet_currency_sign}${countryGoldenBuzzerAmount}.`,
        disableBeacon: true,
      },
      {
        target: ".tour-wallet",
        content: t("tours.content.wallet"),
        disableBeacon: true,
      },
      {
        target: ".tour-profile",
        content: t("tours.content.profile"),
        disableBeacon: true,
      },
      {
        target: ".tour-post",
        content: t("tours.content.post"),
        disableBeacon: true,
      },
    ];
  } else if (window.innerWidth > 768 || window.innerWidth <= 991) {
    TOUR_STEPS = [
      {
        target: ".tour-most-pooled",
        content: t("tours.content.mostPooled"),
        disableBeacon: true,
      },
      {
        target: ".tour-pool",
        content: `${t(
          "tours.content.pool.p1"
        )} ${country_wallet_currency_sign}${country_min_amount} ${t(
          "tours.content.pool.p2"
        )} ${country_wallet_currency_sign}${countryMaxAmount} ${t(
          "tours.content.pool.p3"
        )}`,
        disableBeacon: true,
      },
      {
        target: ".tour-golden-buzzer",
        content: `${t(
          "tours.content.goldenBuzzer"
        )} ${country_wallet_currency_sign}${countryGoldenBuzzerAmount}.`,
        disableBeacon: true,
      },
      {
        target: ".tour-mobile-wallet",
        content: t("tours.content.wallet"),
        disableBeacon: true,
      },
      {
        target: ".tour-mobile-profile",
        content: t("tours.content.profile"),
        disableBeacon: true,
      },
      {
        target: ".tour-post",
        content: t("tours.content.post"),
        disableBeacon: true,
      },
    ];
  } else if (window.innerWidth <= 768) {
    TOUR_STEPS = [
      {
        target: ".tour-pool",
        content: `${t(
          "tours.content.pool.p1"
        )} ${country_wallet_currency_sign}${country_min_amount} ${t(
          "tours.content.pool.p2"
        )} ${country_wallet_currency_sign}${countryMaxAmount} ${t(
          "tours.content.pool.p3"
        )}`,
        disableBeacon: true,
      },
      {
        target: ".tour-golden-buzzer",
        content: `${t(
          "tours.content.goldenBuzzer"
        )} ${country_wallet_currency_sign}${countryGoldenBuzzerAmount}.`,
        disableBeacon: true,
      },
      {
        target: ".tour-mobile-wallet",
        content: t("tours.content.wallet"),
        disableBeacon: true,
      },
      {
        target: ".tour-mobile-profile",
        content: t("tours.content.profile"),
        disableBeacon: true,
      },
      {
        target: ".tour-post",
        content: t("tours.content.post"),
        disableBeacon: true,
      },
    ];
  }

  useEffect(() => {
    setTimeout(() => {
      const tourStatus = JSON.parse(localStorage.getItem("tourStatus"));

      if (tourStatus !== true) {
        if (googleResponse) {
          if (googleResponse.data.message) {
            setExistingUser(false);
            localStorage.setItem("tourStatus", true);
            // setTimeout(() => {
            //   myRef.current.style.transition = "all 0.5s ease";
            //   myRef.current.style.opacity = 0;
            // }, 7000);

            setTimeout(() => {
              document
                .getElementsByClassName("react-joyride__beacon")[0]
                .click();
            }, 2000);
          } else if (googleResponse.data.user_external_id) {
            setExistingUser(true);
          }
        } else if (FBResponse) {
          if (FBResponse.data.message) {
            setExistingUser(false);
            localStorage.setItem("tourStatus", true);
            setTimeout(() => {
              document
                .getElementsByClassName("react-joyride__beacon")[0]
                .click();
            }, 2000);
          } else if (FBResponse.data.user_external_id) {
            setExistingUser(true);
          }
        }

        // if (AppleResponse) {
        //   if (AppleResponse.data.message) {
        //     setExistingUser(false);
        //       dispatch({ type: "START" });
        //   } else if (AppleResponse.data.user_external_id) {
        //     setExistingUser(true);
        //   }
        // }
        else if (nexmoVerificationResponse) {
          if (nexmoVerificationResponse.data.user_external_id) {
            setExistingUser(true);
          } else if (nexmoVerificationResponse.data.message) {
            setExistingUser(false);
            localStorage.setItem("tourStatus", true);
            setTimeout(() => {
              document
                .getElementsByClassName("react-joyride__beacon")[0]
                .click();
            }, 2000);
          }
        } else {
          setExistingUser(true);
        }
      } else {
        setExistingUser(true);
      }
    }, 3000);
  }, []);

  const renderJoyride = () => {
    return (
      <>
        {/* <button className="btn btn-primary" ref={myRef}>
          Click on the red target to start the tour
        </button> */}
        <JoyRide
          steps={TOUR_STEPS}
          continuous={true}
          showSkipButton={true}
          scrollOffset={220}
          styles={{
            tooltipContainer: {
              textAlign: "left",
            },
            buttonNext: {
              backgroundColor: "#2077b1",
            },
            buttonBack: {
              marginRight: 10,
            },
            zIndex: 50000,
          }}
          locale={{
            last: t("tours.content.endTour"),
            skip: t("tours.content.closeTour"),
          }}
        />
      </>
    );
  };

  return <>{existingUser === false ? renderJoyride() : null}</>;
};

export default Tour;
