import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASEURL } from "../../config";

export const useSearchPosts = (searchValue, pgNo) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setResponseData([]);
  }, [searchValue]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "POST",
      url: `${BASEURL}/v1/search/posts`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        searchParam: searchValue,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((response) => {
        setLoading(true);
        setResponseData(response.data.response);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [searchValue]);
  return { loading, error, responseData, hasMore };
};

export const useSearchCharity = (searchValue, pgNo) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setResponseData([]);
  }, [searchValue]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "POST",
      url: `${BASEURL}/v1/search/charity`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        searchParam: searchValue,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((response) => {
        setLoading(true);
        setResponseData(response.data.response);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [searchValue]);
  return { loading, error, responseData, hasMore };
};

export const useSearchUsers = (searchValue, pgNo) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setResponseData([]);
  }, [searchValue]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "POST",
      url: `${BASEURL}/v1/search/users`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        searchParam: searchValue,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((response) => {
        setLoading(true);
        setResponseData(response.data.response);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [searchValue]);
  return { loading, error, responseData, hasMore };
};
