import React, { useRef, createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navbar, Nav, NavLink, Row, Col, Table } from "react-bootstrap";

import brandLogo from "../../assets/svg/brandLogo.svg";

import "../../assets/scss/Faqs/faqs.scss";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import { useTranslation } from "react-i18next";
import english from "../../locate/en/translate.json";
import spanish from "../../locate/es/translate.json";
import portuguese from "../../locate/pt/translate.json";

const Faqs = () => {
  const [data, setData] = useState("");

  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Refs for common questions
  let wrapperRef1 = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  // Refs for Charity questions
  let wrapperRef2 = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  // Ref for journalist question
  let wrapperRef3 = useRef([createRef()]);

  // Handling click on arrow (card open/close) from common questions sections
  const handleArrowClick1 = (id) => {
    let targetDiv;
    targetDiv = wrapperRef1.current[id - 1].current;

    addStyles(targetDiv);
  };

  // Handling click on arrow (card open/close) from charity questions sections
  const handleArrowClick2 = (id) => {
    let targetDiv;
    targetDiv = wrapperRef2.current[id - 1].current;

    addStyles(targetDiv);
  };

  // Handling click on arrow (card open/close) from Journalist question sections
  const handleArrowClick3 = (id) => {
    let targetDiv;
    targetDiv = wrapperRef3.current[id - 1].current;

    addStyles(targetDiv);
  };

  // Adding styles on card open/close
  const addStyles = (targetDiv) => {
    if (targetDiv.style.height === "auto") {
      targetDiv.style.height = "55px";
      targetDiv.style.overflow = "hidden";
      targetDiv.classList.remove("up-arrow");
    } else {
      targetDiv.style.height = "auto";
      targetDiv.classList.add("up-arrow");
    }
  };

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);

    if (language === "en") {
      setData(english.faq);
    } else if (language === "es") {
      setData(spanish.faq);
    } else if (language === "pt") {
      setData(portuguese.faq);
    }
  }, [language]);

  useEffect(() => {
    if (language === null || language === "fr") {
      setData(english.faq);
    }
  }, []);

  const downArrow = (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="down-arrow"
    >
      <path
        d="M1 1L5 5L9 1"
        stroke="#9E9E9E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const charityData = !authorizedUser ? (
    <>
      <h3>{data && data.sectionTitle.charity}</h3>
      {data &&
        data.charity.map((item, index) => {
          return (
            <div
              className="faq-question-card"
              ref={wrapperRef2.current[index]}
              key={item.index}
            >
              <div className="faq-question-wrapper">
                <p id="question">{item.question}</p>
                <div
                  className="arrow"
                  onClick={() => handleArrowClick2(item.index)}
                >
                  {downArrow}
                </div>
              </div>

              <div className="faq-answer">
                {item.hasOwnProperty("linkWithin") ? (
                  <div>
                    <p>
                      {item.answer[0]} <a href="/contact">{item.answer[1]}</a>
                      <span>{item.answer[2]}</span>
                      <a href="/ourPeople">{item.answer[3]}</a>
                    </p>
                  </div>
                ) : (
                  item.answer.map((content, index) => (
                    <>
                      <p key={index}>{content}</p>
                    </>
                  ))
                )}

                {item.hasOwnProperty("dataTable") ? (
                  <Table borderless responsive="md">
                    <tr>
                      {item.dataTable.tableHead.map((content, index) => (
                        <th key={index}>{content}</th>
                      ))}
                    </tr>
                    {item.dataTable.tableBody.map((content, index) => (
                      <tr key={index}>
                        <th>{content.country}</th>
                        <td>{content.min}</td>
                        <td>{content.max}</td>
                        <td>{content.gBuzzer}</td>
                        <td>{content.currency}</td>
                      </tr>
                    ))}
                  </Table>
                ) : null}
              </div>
            </div>
          );
        })}
    </>
  ) : null;

  const journalistData = !authorizedUser ? (
    <>
      <h3>{data && data.sectionTitle.journalist}</h3>
      {data &&
        data.journalist.map((item, index) => {
          return (
            <div
              className="faq-question-card"
              ref={wrapperRef3.current[index]}
              key={item.index}
            >
              <div className="faq-question-wrapper">
                <p id="question">{item.question}</p>
                <div
                  className="arrow"
                  onClick={() => handleArrowClick3(item.index)}
                >
                  {downArrow}
                </div>
              </div>

              <div className="faq-answer">
                <p>
                  {item.answer.p1}{" "}
                  <a href="mailto: media@gopoolit.com">media@gopoolit.com</a>{" "}
                  {item.answer.p2}
                </p>
                <p>{item.answer.p3} </p>
              </div>
            </div>
          );
        })}
    </>
  ) : null;

  return (
    <div className="faq-wrapper">
      <div className="faq-navbar-wrapper">
        <Navbar className="faq-navbar">
          <Navbar.Brand href="/home">
            <img src={brandLogo} width="66" height="48" alt="brand-logo" />
          </Navbar.Brand>

          <Nav className="ml-auto menu-button">
            <Nav.Item>
              {authorizedUser ? (
                <NavLink className="faq-navlink" href="/home">
                  {t("faq.backBtn")}
                </NavLink>
              ) : (
                <NavLink className="faq-navlink" href="/">
                  {t("faq.backBtn")}
                </NavLink>
              )}
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>

      <div className="faq-body-wrapper">
        <div className="faq-header-wrapper">
          <h3>{t("faq.title")}</h3>
          {/* <p>
            Here are some of the frequently asked questions about the GoPoolit.
          </p> */}
        </div>

        <Row className="m-0">
          <Col md={8} className="mx-auto p-0">
            <div className="faq-questions-wrapper">
              {data &&
                data.common.map((item, index) => (
                  <div
                    className="faq-question-card"
                    ref={wrapperRef1.current[index]}
                    key={item.index}
                  >
                    <div className="faq-question-wrapper">
                      <p id="question">{item.question}</p>
                      <div
                        className="arrow"
                        onClick={() => handleArrowClick1(item.index)}
                      >
                        {downArrow}
                      </div>
                    </div>

                    <div className="faq-answer">
                      {item.hasOwnProperty("linkWithin") ? (
                        <div>
                          <p>
                            {item.answer[0]}{" "}
                            <a href="/contact">{item.answer[1]}</a>
                            <span>{item.answer[2]}</span>
                          </p>
                        </div>
                      ) : (
                        item.answer.map((content, index) => (
                          <>
                            <p key={index}>
                              {content}
                              {item.hasOwnProperty("link") ? (
                                <a href={item.link.src}>{item.link.text}</a>
                              ) : null}
                            </p>
                          </>
                        ))
                      )}

                      {/* {item.answer.map((content, index) => (
                      <>
                        <p key={index}>
                          {content}
                          {item.hasOwnProperty("link") ? (
                            <a href={item.link.src}>{item.link.text}</a>
                          ) : null}
                        </p>
                      </>
                    ))} */}

                      {item.hasOwnProperty("dataTable") ? (
                        <Table borderless responsive="md">
                          <tr>
                            {item.dataTable.tableHead.map((content, index) => (
                              <th key={index}>{content}</th>
                            ))}
                          </tr>
                          {item.dataTable.tableBody.map((content, index) => (
                            <tr key={index}>
                              <th>{content.country}</th>
                              <td>{content.min}</td>
                              <td>{content.max}</td>
                              <td>{content.gBuzzer}</td>
                              <td>{content.currency}</td>
                            </tr>
                          ))}
                        </Table>
                      ) : null}
                    </div>
                  </div>
                ))}
              <div className="faq-category-section">{charityData}</div>
              <div className="faq-category-section">{journalistData}</div>
            </div>
          </Col>
        </Row>
        <LeanmoreFooter />
      </div>
    </div>
  );
};

export default Faqs;
