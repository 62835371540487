import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "../../assets/scss/SettingsPrivacy/preferences.scss";
import {
  storeUserDefaultAmount,
  showBalance,
} from "../../actions/storingUserData";

import {
  setPreferences,
  getPreferences
} from '../../api/PreferencesApi'

import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";

const Preferences = () => {
  const dispatch = useDispatch();
  // const userDefaultAmt = useSelector(
  //   (state) => state.userDefaultAmount.defaultAmount
  // );
  const [showToastMsg, setShowToastMsg] = useState(false);
  const sliderDefaultVal = useSelector(
    (state) => state.userDefaultAmount.sliderDefaultValue
  );
  const [stepCount, setStepCount] = useState(sliderDefaultVal ? sliderDefaultVal : 5);
  // const showBalanceOnNavbar = useSelector(
  //   (state) => state.ShowBalanceReducer.show
  // );

  const [showbalanceAmount, setShowBalanceAmount] = useState(false); //Boolean value indicating whether to show balance below wallet icon
  const userDetails = useSelector((state) => state.userDetails);
  const userDetailsCurrency = useSelector((state) => state.userDetails);
  let country_min_amount = +userDetails.country_min_amount;
  let country_max_amount = +userDetails.country_max_amount;

  // country_min_amount =0.4;
  // country_max_amount =4;

  const [defaultAmount, setDefaultAmount] = useState(() => country_min_amount * 5);

  const [t, i18n] = useTranslation("translate");

  const language = localStorage.getItem("lang");

  useEffect(() => {
    async function fetchData() {
      const res = await getPreferences()
      if (res.response.statusCode === 200) {
        setDefaultAmount((res.response.data.settings.settings_pooling_amount * 1) * (country_min_amount * 1))
        setShowBalanceAmount(res.response.data.settings.settings_wallet_icon)
        setStepCount(res.response.data.settings.settings_pooling_amount * 1)
      }
    }
    fetchData();
  }, []);

  let country_wallet_currency_sign =
    userDetailsCurrency.country_wallet_currency_sign;
  // let stepValue = 0;
  // let diff = (country_max_amount - country_min_amount);

  // console.log({defaultAmount})



  const handleSwitchChange = () => {
    setShowBalanceAmount(!showbalanceAmount);
  };

  const decrement = () => {
    setStepCount((stepCount) => {
      if (stepCount <= 1) return 1;
      return stepCount - 1;
    });
    if (defaultAmount <= country_min_amount) return;
    return setDefaultAmount((defaultAmount) => {
      if (stepCount === 1) return country_min_amount;
      return +(defaultAmount - country_min_amount).toFixed(2)
    });
  };
  const increment = () => {
    setStepCount((stepCount) => {
      if (stepCount >= 10) return 10;
      return stepCount + 1;
    });
    if (defaultAmount >= country_max_amount) return;
    return setDefaultAmount((defaultAmount) => {
      if (stepCount === 10) return country_max_amount;
      return +(defaultAmount + country_min_amount).toFixed(2)
    });
  };

  const savePreferences = async () => {
    dispatch(storeUserDefaultAmount(stepCount));
    const result = await setPreferences(stepCount, showbalanceAmount)
    dispatch(showBalance(showbalanceAmount));
    // const res = await getPreferences()
    if (result && result.response && result.response.statusCode === 201) {
      setShowToastMsg(true);
      setTimeout(() => {
        setShowToastMsg(false);
      }, 3000);
    }

  };

  return (
    <>
      <Card className="preferences">
        <Card.Header className="heading">
          {t("settingsAndPrivacy.preferences.preferencesTitle")}</Card.Header>
        <Card.Body className="preferences-container">
          <div className="set-default">


            <p>{t("settingsAndPrivacy.preferences.defaultPoolAmount")}</p>
            <div className="set-default-btn">
              <span>
                {" "}
                <Button onClick={() => decrement()}>-</Button>
              </span>
              <span className="setPoolAmount">
                {country_wallet_currency_sign}{defaultAmount % 1 === 0 ? defaultAmount : defaultAmount.toFixed(2)}
                {/* {defaultAmount} */}
              </span>
              <span>
                {" "}
                <Button onClick={() => increment()}>+</Button>
              </span>
            </div>
          </div>

          <div className="balance">
            <label>{t("settingsAndPrivacy.preferences.showBalance")}</label>

            <div className="custom-control custom-switch toggleBtn">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitches"
                checked={showbalanceAmount}
                onChange={handleSwitchChange}
                readOnly
              />
              <label className="custom-control-label" htmlFor="customSwitches">
                {" "}
              </label>
            </div>
          </div>
          <Button
            onClick={() => savePreferences()}
            className="preferences-savebtn mt-4"
            type="submit"
          >{t("settingsAndPrivacy.preferences.saveButton")}
          </Button>
        </Card.Body>
      </Card>
      {showToastMsg ? (
        <ToastMessage
          image={greenTickMark}
          message={t("settingsAndPrivacy.preferences.successPreferencesMessage")}
        />
      ) : null}
    </>
  );
};

export default Preferences;
