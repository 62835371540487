import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

//components import
import ToastMessage from "../ToastMessage";
import { newPostCreated } from "../../actions/createPost";

//css imports
import "../../assets/scss/Contents/sharingOnMedia.scss";

//image imports
import x from "../../assets/svg/x.svg";
import whatsappIcon from "../../assets/images/whatsapp-icon.png";
import facebookIcon from "../../assets/images/facebook-icon.png";
import facebookMessengerIcon from "../../assets/images/facebook-messenger-icon.png";
import twitterIcon from "../../assets/images/twitter-icon.png";
import telegramIcon from "../../assets/images/telegram-icon.png";
import linkedinIcon from "../../assets/images/linkedin-icon.png";
import greenTickMark from "../../assets/svg/greenTickMark.svg";

const PostCreatedModal = ({ showModal, hideModal, contentExternalID }) => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const newPost = useSelector((state) => state.newPost.newPost);
  const dispatch = useDispatch();

  let url = `https://www.gopoolit.com/share/${contentExternalID}`;
  const copyURL = (url) => {
    var textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    setShowCopiedMessage(true);
  };

  /*Style applied to body element */
  // const BodyStyle = () => {
  //   useEffect(() => {
  //     document.getElementById("body").className = "bodyStyleModal";
  //     return () => {
  //       document.getElementById("body").className = " ";
  //     };
  //   }, []);
  //   return <></>;
  // };
  /*end**********************/

  const copyUrl = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#999999" />
      <path
        d="M18.3346 9.58337H11.3346C10.693 9.58337 10.168 10.1084 10.168 10.75V18.9167H11.3346V10.75H18.3346V9.58337ZM20.0846 11.9167H13.668C13.0263 11.9167 12.5013 12.4417 12.5013 13.0834V21.25C12.5013 21.8917 13.0263 22.4167 13.668 22.4167H20.0846C20.7263 22.4167 21.2513 21.8917 21.2513 21.25V13.0834C21.2513 12.4417 20.7263 11.9167 20.0846 11.9167ZM20.0846 21.25H13.668V13.0834H20.0846V21.25Z"
        fill="white"
      />
    </svg>
  );

  const closeModal = () => {
    hideModal();
    dispatch(newPostCreated(!newPost));
  };
  let stylesShare = showModal
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };
  return (
    <>
      {showCopiedMessage ? (
        <ToastMessage message="Copied to Clip Board!" image={greenTickMark} />
      ) : (
          ""
        )}

      {/* {showModal && <BodyStyle />} */}
      {showModal && <div className="modal-backdrop"></div>}

      <div
        id="shareModal"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesShare}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Post Created Succesfully</h4>

              <button className="close-share" onClick={closeModal}>
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="report-message">
              <div className="modal-body">
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "400",
                    marginBottom: "24px",
                    color: "#757575",
                  }}
                >
                  Hey, where do you want to share this?
                </h4>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                  <div className="share-content" style={{ marginTop: "5px" }}>
                    <span onClick={() => copyURL(url)}>
                      {copyUrl}
                      <span
                        id="pwd_spn"
                        style={{ display: "none" }}
                        className="password-span"
                      >
                        {url}
                      </span>
                      <span>Copy link</span>
                    </span>
                  </div>
                  <div className="share-content">
                    <WhatsappShareButton url={url} title="">
                      <img src={whatsappIcon} alt="whatsappIcon" />
                      <span>WhatsApp</span>
                    </WhatsappShareButton>
                  </div>
                  <div className="share-content">
                    <FacebookShareButton url={url} quote="">
                      <img src={facebookIcon} alt="facebook-icon" />
                      <span>Facebook</span>
                    </FacebookShareButton>
                  </div>
                  <div className="share-content">
                    <FacebookMessengerShareButton
                      appId="646324019294890"
                      url={url}
                      quote=""
                    >
                      <img
                        src={facebookMessengerIcon}
                        alt="facebookMessengerIcon"
                      />
                      <span>Messenger</span>
                    </FacebookMessengerShareButton>
                  </div>
                  <div className="share-content">
                    <TwitterShareButton url={url} title="">
                      <img src={twitterIcon} alt="twitterIcon" />
                      <span>Twitter</span>
                    </TwitterShareButton>
                  </div>
                  <div className="share-content">
                    <TelegramShareButton url={url} title="">
                      <img src={telegramIcon} alt="telegramIcon" />
                      <span>Telegram</span>
                    </TelegramShareButton>
                  </div>
                  <div className="share-content">
                    <LinkedinShareButton url={url} title="">
                      <img src={linkedinIcon} alt="linkedinIcon" />
                      <span>LinkedIn</span>
                    </LinkedinShareButton>
                  </div>
                </div>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "24px",
                    color: "#2077B1",
                    cursor: "pointer",
                  }}
                  onClick={closeModal}
                >
                  I’ll do it later
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCreatedModal;
