import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Dropdown } from "react-bootstrap";

//api imports
import {
  reportTypeProfileApi,
  reportProfileApi,
} from "../../api/ReportProfileAPI";

//css imports
import "../../assets/scss/OtherUserProfile/otherUserSharingOnMedia.scss";

//image imports
import x from "../../assets/svg/x.svg";
import horizontalDots from "../../assets/svg/moreDot-horizontal.svg";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
// import OtherUserProfileShare from "./OtherUserProfileShare";
let isLoading = true;
const shareArrow = (
  <svg
    className="social-media-icon"
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2222 4.53333V0L20 7.93333L12.2222 15.8667V11.22C6.66667 11.22 2.77778 13.0333 0 17C1.11111 11.3333 4.44444 5.66667 12.2222 4.53333Z"
      fill="#3B3B3B"
    />
  </svg>
);

const reportpost = (
  <svg
    className="social-media-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3156 4H8.68444L4 8.68444V15.3156L8.68444 20H15.3156L20 15.3156V8.68444L15.3156 4ZM12 16.7111C11.36 16.7111 10.8444 16.1956 10.8444 15.5556C10.8444 14.9156 11.36 14.4 12 14.4C12.64 14.4 13.1556 14.9156 13.1556 15.5556C13.1556 16.1956 12.64 16.7111 12 16.7111ZM12.8889 12.8889H11.1111V7.55556H12.8889V12.8889Z"
      fill="#9E9E9E"
    />
  </svg>
);

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <a
    href=""
    ref={ref}
    className="sharing_report_dots"
    onClick={(e) => {
      e.preventDefault();
      handleReportProfileOpen();
      onClick(e);
    }}
  >
    {/* {console.log(`${userExternalId} ${userToken}`)} */}

    <img src={horizontalDots} />
  </a>
));

let reportArrayList;

const handleReportProfileOpen = async () => {
  // console.log('hello its me');
  let reportOptionResponses = await reportTypeProfileApi();
  // console.log(reportOptionResponses)
  if (reportOptionResponses) {
    isLoading = false;
    // console.log(reportOptionResponses.response.data);
    reportArrayList = reportOptionResponses.response.data;
  }

  // console.log(reportOptionResponses);
};

/*Style applied to body element */
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
/*end**********************/

const OtherUserReportProfile = (props) => {
  const [showReport, setShowReport] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [reportOption, setReportOption] = useState("");
  const [reportList, setReportList] = useState(null);
  const [reportDescription, setReportDescription] = useState("");
  const [showToastMsg, setShowToastMsg] = useState(false);
  // const userDetails = useSelector(state => state.otherUserProfileDetails.data);

  // const handleShare = () => {
  //     setShowShare(true)
  // }

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    setReportList(reportArrayList);
  }, [reportArrayList]);

  const handleSubmitReport = async (e, reportOption, reportDescription) => {
    e.preventDefault();
    let res = await reportProfileApi(
      reportOption,
      props.otherUserExternalId,
      reportDescription
    );
    if (res.data.response.statusCode === 201) {
      setShowToastMsg(true);
      setTimeout(() => {
        setShowToastMsg(false);
        setShowReport(false);
      }, 3000);
      setReportDescription("");
      setReportOption("");
    }
  };

  const handleReportProfileOption = (e) => {
    if (e.target.checked) {
      setDisabled(false);
      setReportOption(e.target.value);
    }
  };

  // const hideShow = () => {
  //     setOpenModal(false);
  // }

  const handleReport = (e) => {
    setShowReport(true);
    setReportOption(null);
    setDisabled(true);
  };
  let showReportUi = null;
  if (reportArrayList) {
    if (reportArrayList.length === 0) {
      showReportUi = "loading";
    } else {
      showReportUi = reportArrayList.map((reportDetail) => {
        return (
          <Form.Check
            checked={reportOption === reportDetail.report_type_id}
            key={`${reportDetail.report_type_id}`}
            className="mb-1"
            type="radio"
            id={`${reportDetail.report_type_id}`}
            label={`${reportDetail.report_type_name}`}
            name="reportOptions"
            value={`${reportDetail.report_type_id}`}
            onChange={handleReportProfileOption}
            required
          />
        );
      });
    }
  }

  let stylesReport = showReport
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };
  // let styleOthers = (reportOption === "others") ? { visibility: "visible", resize: "none" } : { visibility: "hidden", resize: "none" }
  return (
    <>
      <Dropdown
        className="sharing_report"
        alignRight
        title=""
        id="dropdown-menu-align-right"
      >
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu size="lg" className="sharingOnMedia-menu">
          {/* <Dropdown.Item style={{ padding: "0 27px 0 18px" }} onClick={showModal}>
                        {shareArrow}<span>Share Profile</span>
                    </Dropdown.Item> */}
          <Dropdown.Item
            style={{ padding: "0 27px 0 18px" }}
            onClick={handleReport}
          >
            {reportpost}
            <span>{t("profile.report.reportProfile")}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/*Report Modal*********************************************** */}
      {/* <OtherUserProfileShare showShareModal={openModal} hide={hideShow} /> */}
      {showReport && <BodyStyle />}
      {showReport && <div className="modal-backdrop"></div>}

      <div
        id="otherUserReportModal"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesReport}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t("profile.report.title")}</h4>

              <button className="close" onClick={() => setShowReport(false)}>
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="report-message">
              <div className="modal-body">
                <p style={{ fontWeight: "500" }}>
                  {t("profile.report.reportQuestion1")}
                </p>
                <Form
                  className="reportForm"
                  onSubmit={(e) =>
                    handleSubmitReport(e, reportOption, reportDescription)
                  }
                >
                  {isLoading ? <Loader /> : showReportUi}
                  <div style={{ marginTop: "2rem" }}>
                    <p>{t("profile.report.reportQuestion2")}</p>

                    <Form.Control
                      as="textarea"
                      placeholder="Type here"
                      rows="3"
                      style={{ resize: "none" }}
                    />
                  </div>
                  <div className="modal-footer">
                    <Button
                      variant="primary"
                      className="blue-buttonActive"
                      type="submit"
                      disabled={disabled}
                      style={{ width: "100%" }}
                    >
                      {t("profile.report.submit")}
                    </Button>
                  </div>
                </Form>
                {showToastMsg ? (
                  <ToastMessage
                    image={greenTickMark}
                    message="Profile reported succesfully"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherUserReportProfile;
