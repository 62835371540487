import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, NavLink, Spinner } from "react-bootstrap";
import axios from "axios";
import ToastMessage from ".././ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";

const Feedback = () => {
  const [show, setShow] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const userDetails = useSelector((state) => state.userDetails);
  const [showToastMsg, setShowToastMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    user_email_address: userEmail,
    country_name: userCountry,
    user_full_name: userFullName,
  } = userDetails;

  const profileDropdownFeedback = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9891 3H5.58906C4.59906 3 3.79806 3.81 3.79806 4.8L3.78906 21L7.38906 17.4H19.9891C20.9791 17.4 21.7891 16.59 21.7891 15.6V4.8C21.7891 3.81 20.9791 3 19.9891 3ZM13.6891 13.8H11.8891V12H13.6891V13.8ZM13.6891 10.2H11.8891V6.6H13.6891V10.2Z" fill="#55A3C6" />
    </svg>

  );
  const closeButton = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const [buttonActive, setButtonActive] = useState(false);
  const dispatch = useDispatch();

  //On modal, disable scroll on background body
  const BodyStyle = () => {
    useEffect(() => {
      // document.body.classList.add(MODAL_OPEN_CLASS);
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const handleClose = () => {
    setFeedbackText("");
    setEnableSubmit(false);
    setShow(false);
  };

  let styles = show
    ? { display: "block", overflow: "auto" }
    : { display: "none" };

  const openModal = () => {
    setShow(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios({
      // url:"https://api.gopoolit.com/api/v1/emailSendingForSupport",
      url: "https://api.gopoolit.com/api/v1/support",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({
        subject: "Feedback",
        Name: `${userFullName}`,
        Email_Address: `${userEmail}`,
        Country: `${userCountry}`,
        Name_Of_Organisation: "NA",
        Message: `${feedbackText}`,
      }),
    })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setShowToastMsg(true);
          setIsLoading(false);
          setEnableSubmit(false);
          setTimeout(() => {
            setShow(false);
            setFeedbackText("");
            setEnableSubmit(false);
            setShowToastMsg(false);
          }, 5000);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (e) => {
    setEnableSubmit(true);
    setFeedbackText(e.target.value);
    let input = e.target.value;
    if (input == "") setEnableSubmit(false);
  };
  return (
    <>
      <NavLink
        id="feedback-button"
        activeClassName="active-dropdown-button"
        className="navbar__link"
      // to="/feedback"
      // onClick={setShow(true)}
      >
        <div onClick={openModal}>
          {" "}
          {profileDropdownFeedback}&nbsp;<span>Feedback</span>
        </div>
      </NavLink>
      {show && <BodyStyle />}
      <div id="createContentModal">
        <div className="modal in back-drop " role="dialog" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Send Feedback</h4>
                <button type="button" onClick={handleClose} className="close">
                  {closeButton}
                </button>
              </div>
              <div style={{ borderRadius: "1rem" }} className="modal-body">
                <Form
                  style={{ borderRadius: "20%" }}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>
                      Have feedback/suggestion? Please write to us, we'd be
                      happy to hear it from you
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={(e) => handleChange(e)}
                      placeholder="Type here..."
                      rows={8}
                      value={feedbackText}
                    />
                  </Form.Group>

                  {enableSubmit ? (
                    isLoading ? (
                      <Button
                        className="password-enable-button"
                        style={{
                          width: "100%",
                          backgroundColor: "#2077b1",
                          borderRadius: "4px",
                        }}
                        type="submit"
                      >
                        Submit <Spinner animation="border" />
                      </Button>
                    ) : (
                        <Button
                          className="password-enable-button"
                          style={{
                            width: "100%",
                            backgroundColor: "#2077b1",
                            borderRadius: "4px",
                          }}
                          type="submit"
                        >
                          Submit
                        </Button>
                      )
                  ) : (
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: "#9e9e9e",
                          borderRadius: "4px",
                        }}
                        className="password-button"
                        type="submit"
                        disabled
                      >
                        Submit
                      </Button>
                    )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showToastMsg ? (
        <ToastMessage image={greenTickMark} message="Thanks for the feedback" />
      ) : null}
    </>
  );
};

export default Feedback;
