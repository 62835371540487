import { UPDATE_USER_DETAILS_BY_ID } from '../../constants/action-types'

const initState = {}

const UpdateProfileDetailsByIdReducer = (state = initState, action) => {
    if (action.type === UPDATE_USER_DETAILS_BY_ID) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state;
}
export default UpdateProfileDetailsByIdReducer;