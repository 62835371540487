import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

//image imports
import whiteBrandLogo from "../../assets/svg/white-brandLogo.svg";

import blueBrandLogo from "../../assets/svg/blue-brandLogo.svg";

//css imports
import { Navbar, Nav } from "react-bootstrap";
import "../../assets/scss/LandingLearnMore/loginNavbar.scss";

import { useTranslation } from "react-i18next";

const LoginUserButton = (props) => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const handleClearUserDetail = () => {
    localStorage.clear(); //clear data from localstorage
    props.history.push("/login");
    window.location.reload(true);

    // window.location.reload(false);
  };
  const handleClearUserDetailSignUp = () => {
    // localStorage.clear(); //clear data from localstorage
    props.history.push("/signup");
    window.location.reload(true);

    // window.location.reload(false);
  };

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  return (
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        <Nav.Link
          href="/login"
          id="learn-more-page-login-button"
          // onClick={handleClearUserDetail}
        >
          {t("components.loginNavBar.loginText")}
        </Nav.Link>
        <Nav.Link
          href="/signup"
          id="learn-more-page-signup-button"
          onClick={handleClearUserDetailSignUp}
        >
          {t("components.loginNavBar.signUpText")}
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

const LoginNavbar = (props) => {
  const path = props.location.pathname; //gives current path

  return (
    <section className="learn-more-nav-wrapper">
      <Navbar className="learn-more-nav">
        <Navbar.Brand href="/" className="brand-logo">
          {/* {path === "/" ? < img src={whiteBrandLogo} alr="brand-logo" /> : <img src={blueBrandLogo} alr="brand-logo" />} */}
          {<img src={blueBrandLogo} alr="brand-logo" />}
        </Navbar.Brand>

        {/*the search bar is visible for learn more page  */}
        {/* {path !== "/profile-setup" && <LoginUserButton />} */}
        {path !== "/profile-setup" && path !== "/profile-password" && (
          <LoginUserButton />
        )}
      </Navbar>
    </section>
  );
};

export default withRouter(LoginNavbar);
