import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import Linkify from 'linkifyjs/react';

//components imports
import SharingOnMedia from "../../Contents/SharingOnMedia";
import ReportPost from "../../Contents/ReportPost";
// import Loader from "../../Loader";
import Comments from "../Activity/comments";
import EmojiSlider from "../../EmojiSlider/EmojiSlider";
import ContentSlider from "./ContentSlider";
// import { FollowUser } from '../../../api/FollowUserAPI';
// import { UnfollowUser } from '../../../api/UnFollowUserAPI';
// import { getFollowersList } from '../../../actions/follower_action';
import { getOtherUserDetailsByUserId } from "../../../actions/otherUserProfile/otherUserProfile_action";

//css imports
import { Card, Image } from "react-bootstrap";
// import 'emoji-slider';

//confetti npm
// import Confetti from 'react-confetti';

//actions imports
// import { loadContent } from '../../../actions/activity';
import useHashtags from "../../../actions/customHooks/useHashtags";

//image imports
// import CharityLogo from "../../../assets/images/charity logo.png";
import avatar from "../../../assets/images/avatar.png";
import CharityInfo from "../../CharityInfo/CharityInfo";

// import dots from '../../../assets/svg/emojis/Group 302.svg';

const HashTagContents = ({ hashTagInfo }) => {
  // const userContentData = useSelector((state) => state.userContent);
  const userProfileData = useSelector((state) => state.userProfileDetails.data);
  const charitiesList = useSelector(state => state.charityNames.data)

  const userExternalID = localStorage.getItem("user_external_id");
  const userDetails = useSelector((state) => state.userDetails);
  const [pageNo, setPageNo] = useState(1);
  const [showCharityDes, setShowCharityDes] = useState();
  // const [showComments, setShowComments] = useState(false);
  const [Commentsection, setCommentSection] = useState(-1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const len = 90
  const observer = useRef();
  // const nextDate = userContentData.next;
  // console.log(nextDate);
  const { loading, error, contentsArray, hasMore } = useHashtags(
    pageNo,
    hashTagInfo
  );
  // console.log(contentsArray);

  const lastElementInContent = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNo((prevpageNo) => prevpageNo + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  useEffect(() => {
    setPageNo(1);
  }, [hashTagInfo]);

  const ShowComments = (index) => {
    setCommentSection(index);
  };

  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return <p className="timeline">{month} month ago</p>;
      } else {
        return <p className="timeline">{month} months ago</p>;
      }
    } else if (days) {
      if (days === 1) return <p className="timeline">{days} day ago</p>;
      return <p className="timeline">{days} days ago</p>;
    } else if (hours) {
      if (hours === 1) return <p className="timeline">{hours} hour ago</p>;
      return <p className="timeline">{hours} hours ago</p>;
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return <p className="timeline">{minutes} minute ago</p>;
      return <p className="timeline">{minutes} minutes ago</p>;
    }
  };

  const handleOtherUser = (otherUserExternalId) => {
    history.push({
      pathname: "/otheruserprofile",
      state: { otherUserExternalId: otherUserExternalId },
    });
    // history.push("/otheruserprofile");
    dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
  };

  const handleCharityInfo = (contentExternalID) => {
    setShowCharityDes(contentExternalID)
    // console.log(`${description}`)
  }

  let activityCards = contentsArray.map((item, index) => {
    if (contentsArray.length === index + 1) {
      return (
        <>
          <Card
            className="Activitypage-card"
            key={item.content_external_id}
            ref={lastElementInContent}
          >
            <Card.Header className="activitypage-header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.content_creator_id === userExternalID ? (
                    <span>
                      <Image
                        className="profile-picture"
                        onError={(ev) => (ev.target.src = `${avatar}`)}
                        src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                        roundedCircle
                        width="56"
                        height="56"
                      />
                    </span>
                  ) : (
                      <span
                        onClick={() => handleOtherUser(item.content_creator_id)}
                      >
                        <Image
                          className="profile-picture"
                          onError={(ev) => (ev.target.src = `${avatar}`)}
                          src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                          roundedCircle
                          width="56"
                          height="56"
                        />
                      </span>
                    )}
                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    {item.content_creator_id ===
                      userDetails.user_external_id ? (
                        <span className="contentCreatorName">
                          {item.username}
                        </span>
                      ) : (
                        <span
                          className="contentCreatorName"
                          onClick={() => handleOtherUser(item.content_creator_id)}
                        >
                          {item.username}
                        </span>
                      )}

                    {item.content_creator_id === userExternalID ? (
                      ""
                    ) : (
                        <>
                          {item.is_following ? (
                            <>
                              <span style={{ margin: "0 0.5rem" }}>•</span>{" "}
                              <span
                                style={{ color: "#5b5b5b", fontWeight: "400" }}
                              >
                                {" "}
                              Following
                            </span>
                            </>
                          ) : (
                              ""
                            )}
                        </>
                      )}
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>
                      {getPostedTime(item.content_created_on)}
                    </div>
                  </div>
                </div>
                {item.content_creator_id === userExternalID ? (
                  ""
                ) : (
                    <ReportPost contentExternalId={item.content_external_id} />
                  )}
                {/* <ReportPost /> */}
              </div>
            </Card.Header>
            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && <Card.Text><Linkify tagName="span">{ item.content_description}</Linkify></Card.Text>}
                  {item.content_description.length > len && showFullDesc.id !== item.content_external_id && <Card.Text><Linkify tagName="span">{ item.content_description.substring(0, len) + "..."}</Linkify><span style={{ cursor: "pointer", color: "#2077B1" }} onClick={() => setShowFullDesc({ ...showFullDesc, id: item.content_external_id })}> See more</span></Card.Text>}
                  {item.content_description.length > len && showFullDesc.id === item.content_external_id && <Card.Text><Linkify tagName="span">{ item.content_description}</Linkify><span style={{ cursor: "pointer", color: "#2077B1" }} onClick={() => setShowFullDesc({ ...showFullDesc, id: "" })}> See less</span></Card.Text>}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>
            <div
              className="post-info"
            >
              <div>

              </div>
              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}{" "}
                  {item.totalcomments > "1" ? "comments" : "comment"}
                </span>
              </div>
            </div>
            <div
              style={{ backgroundColor: "rgba(32, 119, 177, 0.08)" }}
              className="activity-page-charity"
            >
              <img
                className="charity-logo"
                src={item.cause_image}
              />
              <ul className="activity-page-content">
                <li className="activity-page-charityName">{item.cause_name}</li>
                <li className="activity-page-charityLocation">
                  {item.country_name}
                </li>
              </ul>
              {charitiesList.map((charity) => {
                if (item.cause_name == charity.cause_name) {
                  return (
                    <>
                      <CharityInfo contentExternalID={item.content_external_id} causeImage={charity.cause_image} causeName={charity.cause_name} causeCountryName={charity.country_name} causeDescription={charity.cause_description} causeURL={charity.cause_url} />
                    </>
                  );
                }
              })}
            </div>

            {/**Slider starts***************************************** */}
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.transaction_amount}
            />
            {/*Slider ends *********************************************/}
            <div
              className="comments-share"
              onClick={() => {
                ShowComments(index);
              }}
            >
              {/* < div className="comments-share"> */}
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
              // Commentcount={item.totalcomments}
              />
              {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
            </div>
          </Card>
        </>
      );
    } else {
      return (
        <>
          {/* {(disablebtn === index) ? <Confetti width={width} recycle={showConfetti} /> : ''} */}
          {/* {showConfettiCard ? <Confetti width={width} recycle={false} /> : ''} */}

          <Card
            className="Activitypage-card"
            key={item.content_external_id}
            style={{ marginBottom: "1rem" }}
          >
            <Card.Header className="activitypage-header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.content_creator_id === userExternalID ? (
                    <span>
                      <Image
                        className="profile-picture"
                        onError={(ev) => (ev.target.src = `${avatar}`)}
                        src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                        roundedCircle
                        width="56"
                        height="56"
                      />
                    </span>
                  ) : (
                      <span
                        onClick={() => handleOtherUser(item.content_creator_id)}
                      >
                        <Image
                          className="profile-picture"
                          onError={(ev) => (ev.target.src = `${avatar}`)}
                          src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                          roundedCircle
                          width="56"
                          height="56"
                        />
                      </span>
                    )}
                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    {item.content_creator_id ===
                      userDetails.user_external_id ? (
                        <span className="contentCreatorName">
                          {item.username}
                        </span>
                      ) : (
                        <span
                          className="contentCreatorName"
                          onClick={() => handleOtherUser(item.content_creator_id)}
                        >
                          {item.username}
                        </span>
                      )}

                    {/* {item.content_creator_id === profileDetailsById.user_external_id ? "" : <FollowUnfollow followInfo={item.is_following} />} */}
                    {/* <FollowUnfollow /> */}
                    {item.content_creator_id === userExternalID ? (
                      ""
                    ) : (
                        <>
                          {item.is_following ? (
                            <>
                              <span style={{ margin: "0 0.5rem" }}>•</span>{" "}
                              <span
                                style={{ color: "#5b5b5b", fontWeight: "400" }}
                              >
                                {" "}
                              Following
                            </span>
                            </>
                          ) : (
                              ""
                            )}
                        </>
                      )}
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>
                      {getPostedTime(item.content_created_on)}
                    </div>
                  </div>
                </div>
                {item.content_creator_id === userExternalID ? (
                  ""
                ) : (
                    <ReportPost contentExternalId={item.content_external_id} />
                  )}
              </div>
            </Card.Header>

            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && (
                    <Card.Text><Linkify tagName="span">{ item.content_description}</Linkify></Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">{item.content_description.substring(0, len) + "..."}</Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          See more
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">{ item.content_description}</Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          See less
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>
            <div
              className="post-info"
            >
              <div>

              </div>
              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}{" "}
                  {item.totalcomments > "1" ? "comments" : "comment"}
                </span>
              </div>
            </div>
            <div
              style={{ backgroundColor: "rgba(32, 119, 177, 0.08)" }}
              className="activity-page-charity"
            >
              <img
                className="charity-logo"
                src={item.cause_image}
              />
              <ul className="activity-page-content">
                <li className="activity-page-charityName">{item.cause_name}</li>
                <li className="activity-page-charityLocation">
                  {item.country_name}
                </li>
              </ul>
              {charitiesList.map((charity) => {
                if (item.cause_name == charity.cause_name) {
                  return (
                    <>
                      <CharityInfo contentExternalID={item.content_external_id} causeImage={charity.cause_image} causeName={charity.cause_name} causeCountryName={charity.country_name} causeDescription={charity.cause_description} causeURL={charity.cause_url} />
                    </>
                  );
                }
              })}
            </div>
            {/* <br /> */}

            {/**Slider starts***************************************** */}
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.transaction_amount}
              goldenBuzzer={item.is_goldenbuzzer}
            />
            {/*Slider ends *********************************************/}
            <div
              className="comments-share"
              onClick={() => {
                ShowComments(index);
              }}
            >
              {/* < div className="comments-share"> */}
              <SharingOnMedia
                url={item.content_url[0].signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
              />

              {Commentsection === index ? (
                <Comments
                  contentExternalId={item.content_external_id}
                  userprofilePic={userProfileData.user_profile_pic}
                  contentCreatorId={item.content_creator_id}
                />
              ) : (
                  ""
                )}
            </div>
          </Card>
        </>
      );
    }
  });

  const showMessage = () => {
    if (loading) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>Please Wait...</div>
      );
    }
    if (error) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>
          Something went wrong. Please try reloading the page to logging in
          again
        </div>
      );
    }
    if (!hasMore) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}></div>
      );
    }
  };
  return (
    <div>
      {activityCards}
      {showMessage()}
    </div>
  );
};

export default HashTagContents;
