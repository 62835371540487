import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button, Card, Image, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'

//component import
import ContentNavbar from '../ContentNavbar/ContentNavbar'
import RazorPay from '../RazorPay/PayByRazorPay';
import RapydCheckout from '../Rapyd/RapydCheckout'
import StripePayment from '../Stripe/StripePayment'
import ToastMessage from '../ToastMessage';
import RazorpayCheckout from '../RazorPay/RazorpayCheckout';

//css import
import '../../assets/scss/Razorpay/razorpay.scss';
import { paymentGatewayAPI } from '../../api/PaymentGatewayAPI';

//image import 
import Loader from '../Loader';



const PaymentGateway = () => {
  // const userDetails = useSelector(state => state.userDetails)
  const [paymentGateway, setPaymentGateway] = useState();
  const [paymentGatewayError, setPaymentGatewayError] = useState();

  useEffect(() => {
    const fetchPaymentGateway = async () => {
      const response = await paymentGatewayAPI();
      const data = response.response.statusCode;
      console.log(data)
      if (data === 200) {
        setPaymentGateway(response.response.data.pools_source_external_id)
      } else {
        setPaymentGatewayError(true)
      }
    }
    fetchPaymentGateway();
  }, [])

  const choosePaymentGateway = () => {
    if (paymentGateway === "7bd8e566-4470-4b0a-ac30-afc7856fbdbf") {
      return <StripePayment />
    } else if (paymentGateway === "2163300e-74c4-4fb3-87ac-57c4e2bc8b43") {
      return <RapydCheckout />
    } else if (paymentGateway === "554bd975-5b39-4b8e-b56d-3147ca418054") {
      return <RazorpayCheckout />
    } else {
      return (<>
        <ContentNavbar />
        <Container>
          <Row style={{ justifyContent: "space-around", marginTop: "2rem" }}>
            <Col md={8} className="mx-auto" style={{ textAlign: "center" }}>
              {/* Payment Gateway is down. Please try again later */}
              <Loader />
            </Col>
          </Row>
        </Container>
      </>)
    }
  }

  return (
    <>
      {choosePaymentGateway()}
      {paymentGatewayError ? <><Container>
        <Row style={{ justifyContent: "space-around", marginTop: "2rem" }}>
          <Col md={8} className="mx-auto" style={{ textAlign: "center" }}>
            Payment Gateway is down. Please try again later
          </Col>
        </Row>
      </Container></>
        : null}
    </>
  )
}

export default PaymentGateway
