import axios from 'axios';
import { BASEURL } from '../config';

export const paymentGatewayAPI = () => {
    return axios({
        url: `${BASEURL}/v1/payment/paymentPreference`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': user_external_Id,
            // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // 'userexternalid': `${localStorage.getItem('user_external_id')}`
        },
        withCredentials: true,
    }).then(res => res.data)
        .catch(err => err.response.data)
}

