import React, { useState, useEffect } from "react";
import { Button, Card, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//api import
import { UnfollowUser } from "../../api/UnFollowUserAPI";

//action import
import { getFollowingList } from "../../actions/following_action";
import { useHistory } from "react-router-dom";

//css import
import "../../assets/scss/UserProfile/follow.scss";

//image import
import search from "../../assets/svg/search.svg";
import avatar from "../../assets/images/avatar.png";
import follower1 from "../../assets/images/follower1.png";
import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";
import { useTranslation } from "react-i18next";

const Following = () => {
  const [searchValue, setSearchValue] = useState("");
  const [SearchArrayData, setSearchArrayData] = useState([]);
  const followingDetails = useSelector((state) => state.followingList.data);
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector((state) => state.userDetails);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const lowerCased = searchValue.toLowerCase();
    let searchFilter = followingDetails.filter((item) =>
      item.username.toLowerCase().startsWith(lowerCased)
    );
    setSearchArrayData(searchFilter);
  }, [searchValue, followingDetails]);

  const unfollowFunction = async (user_following_externalId, index, flag) => {
    if (flag === true) {
      await UnfollowUser(user_following_externalId);
      dispatch(getFollowingList(null));
    }
  };

  const handleOtherUser = (otherUserExternalId) => {
    if (userDetails.user_external_id !== otherUserExternalId) {
      history.push({
        pathname: "/otheruserprofile",
        state: { otherUserExternalId: otherUserExternalId },
      });
      // history.push("/otheruserprofile");
      dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
    }
  };

  // if (!followingDetails) return <Loader />
  let followingList = null;
  if (SearchArrayData.length === 0) {
    followingList = null;
  } else {
    followingList = SearchArrayData.map((item, index) => {
      return (
        <div>
          <div className="followers-following-card">
            <div className="followers-following-image-username">
              {/* <span className="image-display-pic"> */}
              {/* <img src={follower1} className="image" /> */}
              <span>
                <Image
                  className="image"
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`}
                  roundedCircle
                  style={{ width: "51px", height: "51px", cursor: "pointer" }}
                  onClick={() => handleOtherUser(item.user_external_id)}
                />
              </span>

              {/* </span> */}
              {/* {item.user_profile_pic !== null ? <Image className="image" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                                <Image src={avatar} alt="profile-photo" className="image" roundedCircle style={{ width: "51px", height: "51px" }} />} */}

              <div style={{ display: "inline-block" }}>
                <p className="user-caption">{item.username}</p>
                <p className="user-Name">{item.user_full_name}</p>
              </div>
            </div>
            <Button
              className="button-following"
              onClick={() => {
                unfollowFunction(item.user_external_id, index, item.is_active);
              }}
            >
              <span className="button-name">{t("profile.following")}</span>
            </Button>
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {SearchArrayData.length ? (
          <span className="followers">
            {SearchArrayData.length} {t("profile.following")}
          </span>
        ) : (
          <span className="followers">0 {t("profile.following")}</span>
        )}
        <span className="search-people">
          <img src={search} />
          <input
            type="text"
            className="search-people-text"
            placeholder="Search with Username"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </span>
      </div>
      <Card style={{ padding: "1rem" }}>{followingList}</Card>
    </>
  );
};
export default Following;
