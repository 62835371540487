import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import search from "../../assets/svg/search.svg";
import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { searchInfo } from "../../actions/searchComponents/searchInfo";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: "2rem",
    border: "1px solid #ccc",
    marginRight: theme.spacing(3),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      display: "block",
    },

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchSm: {
    position: "relative",
    borderRadius: "2rem",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginLeft: 0,
      marginRight: "10px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  searchIconSm: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const SearchComponent = () => {
  const SearchInfo = useSelector((state) => state.searchInfo);
  const { searchItem } = SearchInfo;

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const [searchValue, setSearchValue] = useState(
    searchItem !== null ? searchItem : ""
  );

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const dispatch = useDispatch();

  const handleSearch = async (e) => {
    e.preventDefault();
    const searchText = searchValue.trim();

    if (searchText.length === 0) {
      dispatch(searchInfo(false, null));
    } else {
      dispatch(searchInfo(true, searchText));
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const searchLg = () => {
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Image src={search} />
        </div>
        <form onSubmit={handleSearch} className={classes.searchField}>
          <InputBase
            placeholder={t("contentNavBar.search")}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </form>
      </div>
    );
  };

  const searchSm = () => {
    return (
      <div className={classes.searchSm}>
        <div className={classes.searchIconSm}>
          <Image src={search} />
        </div>
      </div>
    );
  };

  return <div>{matches ? searchLg() : searchSm()}</div>;
};

export default SearchComponent;
