import React from "react";
import { useSelector } from "react-redux";
import {
    Container,
    Accordion,
    AccordionSummary,
    Typography,
    Avatar,
    Box,
    AccordionDetails,
    Link,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSearchCharity } from "../../actions/customHooks/useSearchData";
import SearchTabs from "./SearchTabs";
import ContentNavBar from "../ContentNavbar/ContentNavbar";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        padding: "0 2rem",
        "& .MuiAccordion-root": {
            position: "static",
        },
    },
    divContainer: {
        width: "100%",
    },
    container: {
        padding: 0,
    },
    accordion: {
        boxShadow: "none",
        marginBottom: "10px",
        border: "none",
    },
    accordionSummary: {
        alignItems: "center",
    },
    avatar: {
        marginRight: "10px",
    },
    mainTitle: {
        color: "#3B3B3B",
        fontWeight: "500",
        fontSize: "16px",
        fontFamily: "DM Sans",
    },
    accordionDetails: {
        display: "block",
    },
    subTitle1: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        color: "#575757",
    },
    greyedText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        color: "#575757",
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        backgroundColor: "#f1f1f1",
        minHeight: "100vh",
        padding: "20px 0",
        marginTop: "20px",
    },
    link: {
        color: "#2077b1",
    },
}));

const SearchCharity = () => {
    const classes = useStyles();

    const searchInfo = useSelector((state) => state.searchInfo);
    const { searchItem } = searchInfo;

    const { loading, error, responseData, hasMore } = useSearchCharity(
        searchItem
    );

    const renderData =
        responseData.statusCode === 200 ? (
            responseData.data.map((item, index) => (
                <Accordion className={classes.accordion} key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.accordionSummary}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                                alt="Charity logo"
                                src={item.cause_image}
                                mr={2}
                                className={classes.avatar}
                            />
                            <div>
                                <Typography className={classes.mainTitle}>
                                    {item.cause_name}
                                </Typography>

                                {/* <Typography>MISSING CAUSE LOCATION</Typography> */}
                            </div>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails className={classes.accordionDetails}>
                        <Box mb={1}>
                            <Typography
                                variant="h6"
                                component="div"
                                className={classes.mainTitle}
                            >
                                About
              </Typography>
                        </Box>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            className={classes.subTitle1}
                        >
                            {item.cause_description}
                        </Typography>

                        {/* <Box>
                <Typography>Charity Registration No.</Typography>
                <Typography
                variant="subtitle1"
                component="div"
                className={classes.subTitle1}
                >
                MISSING CAUSE REG NO
                </Typography>
            </Box> */}

                        <Box className={classes.flexContainer} mt={1}>
                            <Typography className={classes.greyedText}>
                                Find out more about charity at
              </Typography>
                            <Box
                                variant="subtitle1"
                                component="div"
                                className={classes.subTitle1}
                                ml={1}
                            >
                                <a
                                    component="button"
                                    variant="subtitle1"
                                    className={classes.link}
                                    href={item.cause_url}
                                    target="_blank"
                                >
                                    {item.cause_url}
                                </a>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))
        ) : responseData.statusCode === 204 ? (
            <Box textAlign="center" mt={3}>
                <Typography variant="subtitle1">No charity found!</Typography>
            </Box>
        ) : null;

    return (
        <div className={classes.divContainer}>
            <ContentNavBar />
            <SearchTabs activeTabValue="2" />
            <div className={classes.wrapper}>
                <Container maxWidth="sm" className={classes.container}>
                    {loading ? (
                        <Box textAlign="center" my={3}>
                            <CircularProgress style={{ color: "#2077b1" }} />
                        </Box>
                    ) : (
                            <div style={{ margin: "20px 16px 0 16px" }}>{renderData}</div>
                        )}
                </Container>
            </div>
        </div>
    );
};

export default SearchCharity;
