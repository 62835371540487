import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

//css imports
import "../../../assets/scss/Contents/sharingOnMedia.scss";

//image imports
import x from "../../../assets/svg/x.svg";
// import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";
import Loader from "../../Loader";
import ToastMessage from "../../ToastMessage";
import greenTickMark from "../../../assets/svg/greenTickMark.svg";

//api import
import {
  getReportCommentType,
  reportComment,
} from "../../../api/ReportPostAPI";

// const CustomToggle = React.forwardRef(({ onClick }, ref) => (
//   <a
//     href=""
//     ref={ref}
//     onClick={(e) => {
//       e.preventDefault();
//       handleReportOpen();
//       onClick(e);
//     }}
//   >
//     {/* {console.log(`${userExternalId} ${userToken}`)} */}
//     <img src={horizontalDots} />
//   </a>
// ));

/*Style applied to body element */
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
/*end**********************/

const ReportComment = ({
  showReportModal,
  handleReportModal,
  commentExternalId,
  commentCreatorId,
}) => {
  //   const [showReport, setShowReport] = useState(true);
  // const [disabled, setDisabled] = useState(true);
  const [reportOption, setReportOption] = useState("");
  // const [reportList, setReportList] = useState(null);
  const [reportDescription, setReportDescription] = useState("");
  const [showToastMsg, setShowToastMsg] = useState(false);

  const [commentTypes, setCommentTypes] = useState([]);

  // const reportpost = (
  //   <svg
  //     className="social-media-icon"
  //     width="24"
  //     height="24"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M15.3156 4H8.68444L4 8.68444V15.3156L8.68444 20H15.3156L20 15.3156V8.68444L15.3156 4ZM12 16.7111C11.36 16.7111 10.8444 16.1956 10.8444 15.5556C10.8444 14.9156 11.36 14.4 12 14.4C12.64 14.4 13.1556 14.9156 13.1556 15.5556C13.1556 16.1956 12.64 16.7111 12 16.7111ZM12.8889 12.8889H11.1111V7.55556H12.8889V12.8889Z"
  //       fill="#9E9E9E"
  //     />
  //   </svg>
  // );

  useEffect(() => {
    const commentType = async () => {
      let response = await getReportCommentType();
      setCommentTypes(response);
    };
    commentType();
  }, []);

  const handleSubmitReport = async (e, reportOption, reportDescription) => {
    e.preventDefault();
    let res = await reportComment(
      reportOption,
      commentExternalId,
      commentCreatorId,
      reportDescription
    );
    if (res.statusCode === 201) {
      setShowToastMsg(true);
      setTimeout(() => {
        setShowToastMsg(false);
        // setShowReport(false);
        handleReportModal(false);
      }, 3000);
      setReportDescription("");
      setReportOption("");
    }
  };
  const handleReportOption = (e) => {
    if (e.target.checked) {
      // setDisabled(false);
      setReportOption(e.target.value);
    }
  };

  //   const handleReport = (e) => {
  //     // setShowReport(true);
  //     setReportOption(null);
  //     setDisabled(true);
  //   };

  let stylesReport = showReportModal
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };
  return (
    <>
      {/* <Dropdown
        className="sharingOnMedia-main"
        alignRight
        title=""
        id="dropdown-menu-align-right"
      >
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu size="lg" className="sharingOnMedia-menu">
          <Dropdown.Item
            style={{ padding: "0 27px 0 18px" }}
            onClick={handleReport}
          >
            {reportpost}
            <span>Report this Post</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}

      {/*Report Modal*********************************************** */}
      {showReportModal && <BodyStyle />}
      {showReportModal && <div className="modal-backdrop"></div>}

      <div
        id="reportModal"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesReport}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Report Comment</h4>

              <button
                className="close"
                onClick={() => handleReportModal(false)}
              >
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="report-message">
              <div className="modal-body">
                <p style={{ fontWeight: "500" }}>
                  Please tell us why you want to report this comment?
                </p>
                <Form
                  className="reportForm"
                  onSubmit={(e) =>
                    handleSubmitReport(e, reportOption, reportDescription)
                  }
                >
                  {!commentTypes ? (
                    <Loader />
                  ) : (
                    commentTypes.map((reportDetail) => {
                      return (
                        <Form.Check
                          checked={
                            reportOption ===
                            reportDetail.report_type_external_id
                          }
                          key={`${reportDetail.report_type_external_id}`}
                          className="mb-1"
                          type="radio"
                          id={`${reportDetail.report_external_type_id}`}
                          label={`${reportDetail.report_type_name}`}
                          name="reportOptions"
                          value={`${reportDetail.report_type_external_id}`}
                          onChange={handleReportOption}
                          required
                        />
                      );
                    })
                  )}

                  <div style={{ marginTop: "2rem" }}>
                    <p>Please tell us why you want to report this comment?</p>

                    <Form.Control
                      as="textarea"
                      placeholder="Please tell us the reason"
                      rows="3"
                      style={{ resize: "none" }}
                      value={reportDescription}
                      onChange={(e) => setReportDescription(e.target.value)}
                    />
                  </div>
                  <Button
                    variant="primary"
                    className="blue-buttonActive"
                    type="submit"
                    disabled={!reportDescription.length}
                    style={{ width: "100%", marginTop: "1rem" }}
                  >
                    Submit
                  </Button>
                </Form>
              </div>
              {showToastMsg ? (
                <ToastMessage
                  image={greenTickMark}
                  message="Comment reported succesfully"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportComment;
