import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//components imports
import { GoogleLogin, FacebookLogin, Nexmo } from "../index";
// import { AppleLogin } from "./AppleLogin";

import LoginNavbar from "../LandingLearnMore/LoginNavbar";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import CookieConsent from "../CookieConsent/CookieConsent";

//css imports
import { Container, Row, Col, Card } from "react-bootstrap";
// import "../../assets/scss/Login/signupPage.scss";
import "../../assets/scss/Login/loginPage.scss";

import x from "../../assets/svg/x.svg";

//action import
import { logoutUser } from "../../actions/storingUserData.js";
import { storeCountriesList } from "../../actions/countryList";

import { useTranslation } from "react-i18next";

const SignUpPage = (props) => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const [isSignUpPage, setIsSignUpPage] = useState(true);
  const [isFbSignUpPage, setIsFbSignUpPage] = useState(true);
  const [cookiesNotAccepted, setCookiesNotAccepted] = useState(false);
  const [fromLogin, setFromLogin] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState("");

  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  const dispatch = useDispatch();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    if (
      cookieStorage.getItem("cookie_consent") === "false" ||
      cookieStorage.getItem("cookie_consent") === undefined
    ) {
      setCookiesNotAccepted(true);
    } else {
      setCookiesNotAccepted(false);
    }

    dispatch(storeCountriesList());
    dispatch(logoutUser());

    if (isAuthorized) {
      props.history.push("/home");
    }
  }, []);

  useEffect(() => {
    if (redirectUrl === "/") {
      props.history.push("/");
    }
  }, [redirectUrl]);

  const isFacebookApp = () => {
    let ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  };

  let isFacebook = isFacebookApp();

  const cookieStorage = {
    getItem: (key) => {
      const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[key];
    },
    deleteItem: () => {
      let multiple = document.cookie.split(";");
      for (let i = 0; i < multiple.length; i++) {
        let key = multiple[i].split("=");
        document.cookie =
          key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }
    },
  };

  const handleCookieMessage = () => {
    cookieStorage.deleteItem();
    props.history.push("/");
  };

  const cookieMessageModal = () => {
    return (
      <div className="cookie-message-modal">
        <div className="cookie-message">
          <p>
            Please accept the cookies in order to signup into the application.
          </p>

          <button
            type="button"
            className="btn btn-primary mt-3 float-right"
            onClick={handleCookieMessage}
          >
            Ok
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* {cookiesNotAccepted ? cookieMessageModal() : null} */}
      {cookiesNotAccepted ? (
        <CookieConsent fromLogin={fromLogin} setRedirectUrl={setRedirectUrl} />
      ) : null}
      <section className="login-page">
        <LoginNavbar /> {/*navbar */}
        <Container className="login-page-container">
          <Row>
            {/* <Col sm={5} className="login-page-left-content">
           <LoginUserImage />
          </Col> */}

            <Col sm={12} className="login-page-right-content">
              <Card>
                <Card.Header className="h5">
                  <p className="login-page-gopoolit ">
                    {t("login.signUpText")}
                  </p>
                  <Link to="/home" className="login-close">
                    <img src={x} alt="close-button" />
                  </Link>
                </Card.Header>
                <Card.Body>
                  {/* <Card.Title>Login with Social media</Card.Title> */}
                  <Card.Text className="mb-3">
                    {t("login.signUpWithSocialMedia")}
                  </Card.Text>
                  {props.location.state == "error" ? (
                    <p style={{ color: "red", marginBottom: "5px" }}>
                      This account does not exist. Please sign up below
                    </p>
                  ) : (
                    ""
                  )}
                  {props.location.state == "existing_user" ? (
                    <p style={{ color: "green", marginBottom: "5px" }}>
                      You are already a Gopoolit user, please login
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="login-page-social-media">
                    {isFacebook ? (
                      " "
                    ) : (
                      <GoogleLogin
                        isSignUp={isSignUpPage}
                        cookiesNotAccepted={cookiesNotAccepted}
                      />
                    )}
                    <FacebookLogin
                      isfbSignUp={isFbSignUpPage}
                      cookiesNotAccepted={cookiesNotAccepted}
                    />
                    {/* <AppleLogin /> */}
                  </div>
                  <div className="login-page-or-content">
                    <hr />
                    <span>or</span>
                    <hr />
                  </div>
                  <Nexmo cookiesNotAccepted={cookiesNotAccepted} />
                </Card.Body>
                <Card.Footer className="text-muted">
                  <p className="login-page-terms-condition-footer">
                    {t("login.loginCondition.commonText")}
                    <a href="/terms">
                      <span> {t("login.loginCondition.termsOfService")}</span>
                    </a>{" "}
                    {t("login.loginCondition.and")}
                    <a href="/privacy">
                      <span> {t("login.loginCondition.privacyPolicy")}</span>
                    </a>
                    .
                  </p>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
        <LeanmoreFooter />
      </section>
    </>
  );
};

export default SignUpPage;
