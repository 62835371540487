import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Dropdown, Spinner } from "react-bootstrap";

//css imports
import "../../assets/scss/Contents/sharingOnMedia.scss";

//image imports
import x from "../../assets/svg/x.svg";
import horizontalDots from "../../assets/svg/moreDot-horizontal.svg";
import Loader from "../Loader";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import info from "../../assets/svg/info.svg";
import { useTranslation } from "react-i18next";

//api import
import { reportTypePostApi, reportPostApi } from "../../api/ReportPostAPI";
let isloading = true;
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      handleReportOpen();
      onClick(e);
    }}
  >
    {/* {console.log(`${userExternalId} ${userToken}`)} */}

    <img src={horizontalDots} />
  </a>
));

let reportArrayList;

const handleReportOpen = async () => {
  // console.log('hello its me');
  let reportOptionResponses = await reportTypePostApi();

  //isloading = true;
  // console.log(reportOptionResponses)
  if (reportOptionResponses.response.data.length > 0) {
    reportArrayList = reportOptionResponses.response.data;
    isloading = false;
  } else {
    reportArrayList = [];
    isloading = false;
  }

  // console.log(reportOptionResponses);
};

/*Style applied to body element */
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
/*end**********************/

const reportpost = (
  <svg
    className="social-media-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3156 4H8.68444L4 8.68444V15.3156L8.68444 20H15.3156L20 15.3156V8.68444L15.3156 4ZM12 16.7111C11.36 16.7111 10.8444 16.1956 10.8444 15.5556C10.8444 14.9156 11.36 14.4 12 14.4C12.64 14.4 13.1556 14.9156 13.1556 15.5556C13.1556 16.1956 12.64 16.7111 12 16.7111ZM12.8889 12.8889H11.1111V7.55556H12.8889V12.8889Z"
      fill="#9E9E9E"
    />
  </svg>
);

const ReportPost = ({ contentExternalId }) => {
  const [showReport, setShowReport] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [reportOption, setReportOption] = useState("");
  const [reportList, setReportList] = useState(null);
  const [reportDescription, setReportDescription] = useState("");
  const [showToastMsg, setShowToastMsg] = useState(false);
  const [noMoreReport, setNoMoreReport] = useState(false);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    setReportList(reportArrayList);
  }, [reportArrayList]);

  const handleSubmitReport = async (e, reportOption, reportDescription) => {
    e.preventDefault();
    let res = await reportPostApi(
      reportOption,
      contentExternalId,
      reportDescription
    );
    if (res.data.response.statusCode === 201) {
      setShowToastMsg(true);
      setTimeout(() => {
        setShowToastMsg(false);
        setShowReport(false);
      }, 3000);
      setReportDescription("");
      setReportOption("");
    } else if (res.data.response.statusCode === 200) {
      setNoMoreReport(true);
      setTimeout(() => {
        setNoMoreReport(false);
        setShowReport(false);
      }, 4000);
      // setReportDescription("");
      // setReportOption("");
    }
  };
  const handleReportOption = (e) => {
    if (e.target.checked) {
      setDisabled(false);
      // console.log(e.target.value);
      setReportOption(e.target.value);
    }
  };

  const handleReport = (e) => {
    setShowReport(true);
    setReportOption(null);
    setDisabled(true);
  };

  let stylesReport = showReport
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };
  return (
    <>
      <Dropdown
        className="sharingOnMedia-main"
        alignRight
        title=""
        id="dropdown-menu-align-right"
      >
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu size="lg" className="sharingOnMedia-menu">
          <Dropdown.Item
            style={{ padding: "0 27px 0 18px" }}
            onClick={handleReport}
          >
            {reportpost}
            <span>{t("post.report.reportPost")}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/*Report Modal*********************************************** */}
      {showReport && <BodyStyle />}
      {showReport && <div className="modal-backdrop"></div>}

      <div
        id="reportModal"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesReport}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t("post.report.title")}</h4>

              <button className="close" onClick={() => setShowReport(false)}>
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="report-message">
              <div className="modal-body">
                <p style={{ fontWeight: "500" }}>
                  {t("post.report.reportQuestion")}
                </p>
                <Form
                  className="reportForm"
                  onSubmit={(e) =>
                    handleSubmitReport(e, reportOption, reportDescription)
                  }
                >
                  {isloading ? (
                    <Loader />
                  ) : (
                    reportArrayList.map((reportDetail) => {
                      console.log(reportDetail);
                      return (
                        <Form.Check
                          checked={reportOption === reportDetail.report_type_id}
                          key={`${reportDetail.report_type_id}`}
                          className="mb-1"
                          type="radio"
                          id={`${reportDetail.report_type_id}`}
                          label={`${reportDetail.report_type_name}`}
                          name="reportOptions"
                          value={`${reportDetail.report_type_id}`}
                          onChange={handleReportOption}
                          required
                        />
                      );
                    })
                  )}

                  <div style={{ marginTop: "2rem" }}>
                    <p>{t("post.report.reportQuestion")}</p>

                    <Form.Control
                      as="textarea"
                      placeholder={t("post.report.reportReason")}
                      rows="3"
                      style={{ resize: "none" }}
                      value={reportDescription}
                      onChange={(e) => setReportDescription(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <Button
                      variant="primary"
                      className="blue-buttonActive"
                      type="submit"
                      disabled={disabled}
                      style={{ width: "100%" }}
                    >
                      {t("post.report.submit")}
                    </Button>
                  </div>
                </Form>
                {showToastMsg ? (
                  <ToastMessage
                    image={greenTickMark}
                    message={t("post.report.postReportSuccess")}
                  />
                ) : null}
                {noMoreReport ? (
                  <ToastMessage
                    image={info}
                    message={t("post.report.postReported")}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPost;
