import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//api imports
import { PoolApi } from "../../api/TransactionAPI";
import { GoldenBuzzerApi } from "../../api/TransactionAPI";
import { storeContentId } from "../../actions/storeContentId";
import { userBalance } from "../../actions/storingUserData";

//css imports
import "../../assets/scss/EmojiSlider/emojiSlider.scss";

//image imports
import { getUserDetailsByUserId } from "../../actions/userProfile_action";
import Goldenheart from "../../assets/svg/GoldenHeart.svg";
import successIcon from "../../assets/svg/successIcon.svg";
import x from "../../assets/svg/x.svg";

//confetti package import
import Confetti from "react-confetti";
import StorePopup from "../StorePopup";
import { useTranslation } from "react-i18next";
// import Loader from "../Loader";
import { getPreferences, setPreferences } from "../../api/PreferencesApi";
const EmojiSlider = (props) => {
  // const userDefaultAmt = useSelector((state) => state.userDefaultAmount.defaultAmount);
  const sliderDefaultVal = useSelector(
    (state) => state.userDefaultAmount.sliderDefaultValue
  );
  const [showNoBalance, setShowNoBalance] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  // const [successBtn, setSuccessBtn] = useState(true);
  const [storePopup, setStorePopup] = useState(false);
  // const [disablebtn, setDisable] = useState(-1);
  // const [maxPooledAmt, setMaxPooledAmt] = useState(0);
  const [showGoldenHeart, setShowGoldenHeart] = useState(false);
  // const [inputValue, setInputValue] = useState(0);
  // const [countryMinAmount, setCountryMinAmount] = useState();
  const [showConfetti, setshowConfetti] = useState(false);
  // const [goldenheartDisable, setgoldenHeartDisable] = useState(true);
  // const [transcationMsg, setTranscationMsg] = useState("");
  // const [transAmt, setTransAmt] = useState(props.transcationAmt);
  // const [hoursState, setHoursState] = useState(null);
  // const [buzzer, setBuzzer] = useState(false);
  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const showBalance = useSelector((state) => state.ShowBalanceReducer.show);
  let country_min_amount = +userDetails.country_min_amount;
  const [value, setValue] = useState(
    sliderDefaultVal ? sliderDefaultVal * 1 : 5
  );
  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await getPreferences()
  //     if (res && res.response && res.response.data && res.response.data.settings)
  //       setValue(res.response.data.settings.settings_pooling_amount * 1)
  //   }
  //   fetchData();
  // }, []);

  // country_min_amount = 0.4;

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  let transAmt = props.transcationAmt || props.goldenBuzzer;
  const [poolStatus, setPoolStatus] = useState("");
  const [disablePoolBtn, setDisablePoolBtn] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  // console.log(transAmt);
  const width = "800px";
  // let slider = 'slider';
  // const inputRef = useRef(null);
  // const amountRef = useRef(null);
  // const amountRefParent = useRef(null);

  // const handleChange = (e) => {
  //     // setInputValue(e.target.value);
  //     var inputValueForScroll = e.target.value;
  //     var max = 90;
  //     var value = inputValueForScroll;
  //     var percent = value / max;
  //     var parent_height = amountRefParent.current.getBoundingClientRect().height;
  //     var height = amountRef.current.getBoundingClientRect().height;
  //     var top = (parent_height - height) * percent;
  //     // console.log(top);
  //     if (percent <= 1)
  //         amountRef.current.style.top = `${top}px`; //change the value of top
  //     /** */
  //     if (e.target.value < 10) {
  //         setInputRangeValue(1);
  //     } else if (e.target.value <= 20) {
  //         setInputRangeValue(2);

  //     } else if (e.target.value <= 30) {
  //         setInputRangeValue(3);

  //     } else if (e.target.value <= 40) {
  //         setInputRangeValue(4);

  //     } else if (e.target.value <= 50) {
  //         setInputRangeValue(5);

  //     } else if (e.target.value <= 60) {
  //         setInputRangeValue(6);

  //     } else if (e.target.value <= 70) {
  //         setInputRangeValue(7);

  //     } else if (e.target.value <= 80) {
  //         setInputRangeValue(8);

  //     } else if (e.target.value < 90) {
  //         setInputRangeValue(9);

  //     } else if (e.target.value == 90) {
  //         setInputRangeValue(10);

  //     }
  // }

  function classList(...classes) {
    return classes.filter((item) => !!item).join(" ");
  }

  const goldenheartvalue = async () => {
    setDisablePoolBtn(true);
    if (authorizedUser) {
      setPoolStatus("processing");
      // setgoldenHeartDisable(false);
      // let amt = (inputRangeValue * 5);
      // setMaxPooledAmt(amt);
      let obj = {
        type: "goldenBuzzer",
        cause_id: props.causeId,
        content_id: props.contentExternalId,
        country_id: props.countryId,
        //scale: amt
      };
      // console.log(obj);
      // setSuccessBtn(false);
      let PoolResponse = await GoldenBuzzerApi(obj);
      // setTranscationMsg(PoolResponse);
      // console.log(PoolResponse);
      if (PoolResponse.response && PoolResponse.response.statusCode === 201) {
        dispatch(userBalance());
        setPoolStatus("success");
        setShowNoBalance(false);
        setshowConfetti(!showConfetti);
      } else if (
        PoolResponse.response &&
        PoolResponse.response.statusCode === 200
      ) {
        setPoolStatus("failure");
        setShowNoBalance(true);
      }
      dispatch(getUserDetailsByUserId());

      // setshowConfetti(!showConfetti);
      // setTimeout(() => {
      //     setgoldenHeartDisable(true);
      // }, 6000);
    } else {
      if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
        setStorePopup(true);
        // dispatch(storeContentId(props.contentExternalId));
        localStorage.setItem("content_external_id", props.contentExternalId);
      } else {
        history.push("/login");
        // dispatch(storeContentId(props.contentExternalId));
        localStorage.setItem("content_external_id", props.contentExternalId);
      }
    }
    setDisablePoolBtn(false);
  };

  // const GoldenHeartStyle = () => {
  //     useEffect(() => {
  //         document.getElementById('goldenHeart').className = 'goldenHeartStyle';
  //         document.getElementById('pool-Btn').className = 'poolBtnStyle'

  //         return () => {
  //             document.getElementById('goldenHeart').className = ' ';
  //             document.getElementById('pool-Btn').className = 'pool'
  //         }
  //     }, []);

  //     return (
  //         <></>
  //     )
  // }

  /*------------------ Emoji Slider ------------------*/
  // const [value, setValue] = useState(() =>
  //   sliderDefaultVal ? sliderDefaultVal : 1
  // ); //to set value as per preferences
  // const [value, setValue] = useState(1);
  const genSlideStyle = (value) => {
    return {
      point: {
        left:
          value === 1
            ? `calc(10% - ${5 + 0 * value}px)`
            : `calc(${value * 10}% - ${5 + 0 * value}px)`,
      },
      range: {
        // width: value === 1 ? `0%` : `${value * 10}%`,
        width: `${value * 10}%`,
      },
    };
  };

  const handleSliderChange = (e) => {
    setValue(e.target.value);
  };
  const Pool = async () => {
    setDisablePoolBtn(true);
    // console.log(value);
    if (authorizedUser) {
      setPoolStatus("processing");
      let obj = {
        type: "pool",
        cause_id: props.causeId,
        content_id: props.contentExternalId,
        country_id: props.countryId,
        scale: value,
      };
      // console.log(obj);
      let PoolResponse = await PoolApi(obj);
      // console.log(PoolResponse)
      if (PoolResponse.response && PoolResponse.response.statusCode === 201) {
        dispatch(userBalance());
        setPoolStatus("success");
      } else if (
        PoolResponse.response &&
        PoolResponse.response.statusCode === 200
      ) {
        setPoolStatus("failure");
        setShowNoBalance(true);
      }
      // setSuccessBtn(false);
    } else {
      if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
        setStorePopup(true);
        // dispatch(storeContentId(props.contentExternalId));
        localStorage.setItem("content_external_id", props.contentExternalId);
      } else {
        history.push("/login");
        localStorage.setItem("content_external_id", props.contentExternalId);
        // dispatch(storeContentId(props.contentExternalId));
      }
    }
    setDisablePoolBtn(false);
  };

  const slideStyle = genSlideStyle(value);
  /*--------------------------------------------------*/

  // useEffect(() => {
  //   let date1 = new Date(userDetails.lastGolderBuzzerDate);
  //   // console.log(date1);

  //   let diffInMilliseconds = Date.now() - date1.getTime();
  //   // console.log(diffInMilliseconds)

  //   const hours = Math.round(diffInMilliseconds / 3600000);
  //   // console.log({ hours })

  //   // let hours = 25;
  //   if (
  //     // (userDetails.lastGolderBuzzerDate != null && hours >= 24) || userDetails.lastGolderBuzzerDate == null
  //     (userDetails.lastGolderBuzzerDate != null && hours >= 24) || userDetails.lastGolderBuzzerDate == null
  //   ) {
  //     // setBuzzer(true);
  //     setShowGoldenHeart(true);
  //     // console.log(">24")
  //   } else {
  //     setShowGoldenHeart(false);
  //     // setBuzzer(false);
  //   }
  // }, [userDetails.lastGolderBuzzerDate]);

  useEffect(() => {
    // let date1 = new Date(userDetails.lastGolderBuzzerDate);
    let date1 = new Date();
    let today = new Date();
    if (userDetails.lastGolderBuzzerDate) {
      date1 = new Date(userDetails.lastGolderBuzzerDate);
    }
    // console.log({date1});
    // console.log({today})

    let todayDate = today.getDate();
    let lastGBDate = date1.getDate();

    let showBuzz = false;
    if (
      todayDate !== lastGBDate ||
      (todayDate === lastGBDate && date1.getMonth() !== today.getMonth()) ||
      (todayDate === lastGBDate &&
        date1.getMonth() === today.getMonth() &&
        date1.getFullYear() !== today.getFullYear())
    ) {
      // console.log("Show Buzzer")
      showBuzz = true;
    }

    if (
      // (userDetails.lastGolderBuzzerDate != null && hours >= 24) || userDetails.lastGolderBuzzerDate == null
      (userDetails.lastGolderBuzzerDate !== null && showBuzz) ||
      userDetails.lastGolderBuzzerDate === null
    ) {
      // setBuzzer(true);
      setShowGoldenHeart(true);
      // console.log(">24")
    } else {
      setShowGoldenHeart(false);
      // setBuzzer(false);
    }
  }, [userDetails.lastGolderBuzzerDate]);

  // if (!userDetails) return <Loader />

  /*Style applied to body element */
  const BodyStyle = () => {
    useEffect(() => {
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const handleClose = () => {
    setShowNoBalance(false);
    setStorePopup(false);
    localStorage.removeItem("content_external_id");
  };

  let stylesReport = showNoBalance
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };

  return (
    <>
      {showConfetti ? (
        <Confetti width={width} recycle={false} numberOfPieces={1000} />
      ) : (
        ""
      )}
      {/* {!goldenheartDisable && <GoldenHeartStyle />} */}
      <div className="poolBtn-emoji-slider">
        {transAmt ? (
          <div className="success-poolBtn-emoji-slider">
            <img src={successIcon} alt="successIcon" />
            <p>Thank you for pooling</p>
            {/* <p>{transcationMsg}</p> */}
          </div>
        ) : (
          <div>
            {poolStatus === "processing" ? (
              <div className="success-poolBtn-emoji-slider">
                <p>Processing...</p>
              </div>
            ) : poolStatus === "success" ? (
              <div className="success-poolBtn-emoji-slider">
                <img src={successIcon} alt="successIcon" />
                <p>Thank you for pooling</p>
              </div>
            ) : (
              <div className="poolBtn-container">
                <div className="emojiLeft">
                  <div className="emoji-container-slider-wrapper">
                    <div className="emoji-container-slider">
                      <div className="empty-div"></div>
                      {/* <div className="emoji">
                                <input ref={inputRef} type="range" className={classList(
                                    'slider',
                                    (inputValue < 10) && 'slider1',
                                    (inputValue >= 10) && 'slider2',
                                    (inputValue >= 20) && 'slider3',
                                    (inputValue >= 30) && 'slider4',
                                    (inputValue >= 40) && 'slider5',
                                    (inputValue >= 50) && 'slider6',
                                    (inputValue >= 60) && 'slider7',
                                    (inputValue >= 70) && 'slider8',
                                    (inputValue >= 80) && 'slider9',
                                    (inputValue >= 90) && 'slider10'
                                )} value={inputValue} min={0} max={90} onChange={(e) => handleChange(e)} />
                                <img className="dots" src={dots} />
                            </div> */}

                      <div className="range">
                        {/* <span className="bullet" /> */}
                        {/* <span className="bullet-1" /> */}
                        <span className="bullet-2" />
                        <span className="bullet-3" />
                        <span className="bullet-4" />
                        <span className="bullet-5" />
                        <span className="bullet-6" />
                        <span className="bullet-7" />
                        <span className="bullet-8" />
                        <span className="bullet-9" />
                        <span
                          className="range-value"
                          style={slideStyle.range}
                        />
                        <span
                          className={classList(
                            "circle",
                            // (value < 1) && 'slider1',
                            value >= 1 && "slider1",
                            value >= 2 && "slider2",
                            value >= 3 && "slider3",
                            value >= 4 && "slider4",
                            value >= 5 && "slider5",
                            value >= 6 && "slider6",
                            value >= 7 && "slider7",
                            value >= 8 && "slider8",
                            value >= 9 && "slider9",
                            value >= 10 && "slider10"
                          )}
                          style={slideStyle.point}
                        />
                        <input
                          className="range-slide"
                          name="range"
                          type="range"
                          min="1"
                          max="10"
                          value={value}
                          step="1"
                          onChange={handleSliderChange}
                        />
                      </div>

                      <div className="scaleEmojiSlider">
                        {/* <span style={{ marginLeft: "3px" }}>1</span> */}
                        <span>1</span>
                        <span>10</span>
                      </div>
                    </div>

                    {/* <div className="amountSliderScroll">
                            <div className="amountScrollParent" ref={amountRefParent}>
                                <div ref={amountRef} className="amountScroll">
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                    <span>6</span>
                                    <span>7</span>
                                    <span>8</span>
                                    <span>9</span>
                                    <span>10</span>
                                </div>
                            </div>
                            <span>¢</span>
                        </div> */}
                    <span className="slide-amount">
                      <span className="slide-currency-symbol">
                        {userDetails.country_wallet_currency_sign}
                      </span>
                      {/* {value * country_min_amount} */}
                      {country_min_amount
                        ? (value * country_min_amount) % 1 === 0
                          ? value * country_min_amount
                          : (value * country_min_amount).toFixed(2)
                        : " "}
                    </span>
                  </div>
                </div>
                <div className="poolBtnRight">
                  {/* <Button onClick={Pool} id="pool-Btn" className="pool">
                    Pool
                  </Button> */}
                  {disablePoolBtn ? (
                    <Button id="pool-Btn" className="pool tour-pool" disabled>
                      Pool
                    </Button>
                  ) : (
                    <Button
                      onClick={Pool}
                      id="pool-Btn"
                      className="pool tour-pool"
                    >
                      Pool
                    </Button>
                  )}
                  {/* {(userDetails.lastGolderBuzzerDate != null || hoursState <= 24) ? "" : < span id="goldenHeart" onClick={goldenheartvalue}>
                            <img src={Goldenheart} className="golden-heart" /></span>} */}
                  {showGoldenHeart ? (
                    disablePoolBtn ? (
                      <span id="goldenHeart">
                        <img
                          src={Goldenheart}
                          alt="golden-heart"
                          className="tour-golden-buzzer"
                        />
                      </span>
                    ) : (
                      <span id="goldenHeart" onClick={goldenheartvalue}>
                        <img
                          src={Goldenheart}
                          alt="golden-heart"
                          className="tour-golden-buzzer"
                        />
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>

                {/* {hoursState > 24 ? < span id="goldenHeart" onClick={goldenheartvalue}>
                            <img src={Goldenheart} className="golden-heart" /></span> : ""} */}
              </div>
            )}
          </div>
        )}
      </div>
      {showNoBalance && <BodyStyle />}
      {showNoBalance && <div className="modal-backdrop"></div>}

      {storePopup ? (
        <StorePopup storePopupShow={storePopup} handleClose={handleClose} />
      ) : (
        " "
      )}

      {/* Insufficient Modal Popup */}
      <div
        id="insufficient-balance-modal"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesReport}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ textAlign: "left" }}>
                Insufficient balance
              </h4>

              <button className="close" onClick={handleClose}>
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="modal-body">
              <p>Hey there! Please add balance to continue pooling.</p>
            </div>
            <div className="modal-footer">
              <Button
                className="white-buttonActive"
                size="md"
                variant="primary"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Link className="blue-activeLink" to="/addbalance">
                Add Balance
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmojiSlider;
