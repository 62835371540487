import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

//File imports
import transactionDownArrow from "../../assets/svg/transaction-down-arrow.svg";
import transactionUpArrow from "../../assets/svg/transaction-up-arrow.svg";
import "../../assets/scss/Razorpay/razorpay.scss";

//css imports
import { Row, Col, Form } from "react-bootstrap";
import "../../assets/scss/AddBalance/addBalance.scss";
import DatePicker from "react-datepicker";
// import axios from "axios";

//api imports
// import { incomingApi } from "../../api/TransactionDetailsAPI";
// import { outgoingApi } from "../../api/TransactionDetailsAPI";
import Loader from "../Loader";
import IncomingTransaction from "./IncomingTransaction";
import OutgoingTransaction from "./OutgoingTransaction";

import { useTranslation } from "react-i18next";

const TransactionDetails = (props) => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const [key, setKey] = useState("INCOMING");
  const [transactionType, setTransactionType] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // const [
  //   incomingTransactionDetails,
  //   setIncomingTransactionDetails,
  // ] = useState();
  // const [
  //   outgoingTransactionDetails,
  //   setOutgoingTransactionDetails,
  // ] = useState();
  // const [balance, setBalance] = useState("");

  // const incomingArrow = (
  //   <svg
  //     width="40"
  //     height="40"
  //     viewBox="0 0 40 40"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g filter="url(#filter0_d)">
  //       <circle cx="20" cy="20" r="16" fill="white" />
  //     </g>
  //     <path
  //       fill-rule="evenodd"
  //       clip-rule="evenodd"
  //       d="M16.0496 17.0205L21.9224 22.8933L18.0101 23.2092C17.6293 23.2395 17.3521 23.5742 17.3908 23.9552C17.4295 24.3372 17.7695 24.6209 18.1503 24.5906L23.5344 24.1562C23.5765 24.1527 23.6126 24.1354 23.6521 24.1246C23.6844 24.1161 23.7162 24.113 23.7474 24.0996C23.8254 24.0661 23.8975 24.0208 23.9566 23.9617C23.9571 23.9612 23.9586 23.9607 23.9591 23.9602C23.9595 23.9597 23.96 23.9583 23.9605 23.9578C24.0197 23.8986 24.065 23.8266 24.0984 23.7486C24.1118 23.7173 24.1149 23.6855 24.1234 23.6533C24.1343 23.6137 24.1516 23.5776 24.155 23.5356L24.5894 18.1514C24.6058 17.938 24.5249 17.7378 24.3833 17.5962C24.2715 17.4844 24.1223 17.4089 23.9541 17.3919C23.573 17.3532 23.2384 17.6305 23.208 18.0113L22.8922 21.9236L17.0193 16.0507C16.7461 15.7775 16.3071 15.773 16.0395 16.0406C15.7718 16.3083 15.7763 16.7472 16.0496 17.0205Z"
  //       fill="#2077B1"
  //     />
  //     <defs>
  //       <filter
  //         id="filter0_d"
  //         x="0"
  //         y="0"
  //         width="40"
  //         height="40"
  //         filterUnits="userSpaceOnUse"
  //         color-interpolation-filters="sRGB"
  //       >
  //         <feFlood flood-opacity="0" result="BackgroundImageFix" />
  //         <feColorMatrix
  //           in="SourceAlpha"
  //           type="matrix"
  //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
  //         />
  //         <feOffset />
  //         <feGaussianBlur stdDeviation="2" />
  //         <feColorMatrix
  //           type="matrix"
  //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
  //         />
  //         <feBlend
  //           mode="normal"
  //           in2="BackgroundImageFix"
  //           result="effect1_dropShadow"
  //         />
  //         <feBlend
  //           mode="normal"
  //           in="SourceGraphic"
  //           in2="effect1_dropShadow"
  //           result="shape"
  //         />
  //       </filter>
  //     </defs>
  //   </svg>
  // );

  // const outgoingArrow = (
  //   <svg
  //     width="40"
  //     height="40"
  //     viewBox="0 0 40 40"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g filter="url(#filter0_d)">
  //       <circle cx="20" cy="20" r="16" fill="white" />
  //     </g>
  //     <path
  //       fill-rule="evenodd"
  //       clip-rule="evenodd"
  //       d="M17.0185 23.9502L22.8914 18.0773L23.2072 21.9896C23.2376 22.3704 23.5722 22.6477 23.9533 22.609C24.3353 22.5703 24.619 22.2303 24.5886 21.8495L24.1542 16.4654C24.1508 16.4233 24.1335 16.3872 24.1226 16.3476C24.1141 16.3154 24.111 16.2836 24.0977 16.2524C24.0642 16.1743 24.0189 16.1023 23.9597 16.0431C23.9592 16.0427 23.9587 16.0412 23.9583 16.0407C23.9578 16.0402 23.9563 16.0397 23.9558 16.0393C23.8967 15.9801 23.8246 15.9348 23.7466 15.9013C23.7154 15.8879 23.6836 15.8848 23.6513 15.8763C23.6118 15.8655 23.5757 15.8482 23.5336 15.8447L18.1495 15.4103C17.9361 15.3939 17.7358 15.4749 17.5943 15.6164C17.4824 15.7283 17.4069 15.8775 17.39 16.0457C17.3513 16.4267 17.6285 16.7614 18.0093 16.7918L21.9216 17.1076L16.0488 22.9804C15.7755 23.2537 15.771 23.6926 16.0387 23.9603C16.3063 24.2279 16.7453 24.2234 17.0185 23.9502Z"
  //       fill="#2077B1"
  //     />
  //     <defs>
  //       <filter
  //         id="filter0_d"
  //         x="0"
  //         y="0"
  //         width="40"
  //         height="40"
  //         filterUnits="userSpaceOnUse"
  //         color-interpolation-filters="sRGB"
  //       >
  //         <feFlood flood-opacity="0" result="BackgroundImageFix" />
  //         <feColorMatrix
  //           in="SourceAlpha"
  //           type="matrix"
  //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
  //         />
  //         <feOffset />
  //         <feGaussianBlur stdDeviation="2" />
  //         <feColorMatrix
  //           type="matrix"
  //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
  //         />
  //         <feBlend
  //           mode="normal"
  //           in2="BackgroundImageFix"
  //           result="effect1_dropShadow"
  //         />
  //         <feBlend
  //           mode="normal"
  //           in="SourceGraphic"
  //           in2="effect1_dropShadow"
  //           result="shape"
  //         />
  //       </filter>
  //     </defs>
  //   </svg>
  // );

  // let accessToken= profileDetailsId.user_external_id;
  // const handleSelect = (k) => {
  //   setKey(k);
  // };

  // useEffect(() => {
  //   const fetchIncomingData = async () => {
  //     let incoming = await incomingApi(1);
  //     let data = incoming.response.data;
  //     setIncomingTransactionDetails(data);
  //   };
  //   fetchIncomingData();
  // }, []);

  const handleTransactions = (type) => {
    async function fetchTransactionData() {
      if (type === 1) {
        setKey("INCOMING");
        setTransactionType(1);
        setFromDate("");
        setToDate("");
      } else {
        setTransactionType(2);
        setKey("OUTGOING");
        setFromDate("");
        setToDate("");
      }
    }

    fetchTransactionData();
  };

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  // if (!incomingTransactionDetails) return <Loader />;

  return (
    <>
      <Row>
        <Col sm={12} md={9} lg={9} className="mx-auto">
          <div>
            <Row className="my-3">
              <Col xs={12} md={5} lg={6}>
                <p>
                  <span style={{ fontSize: "20px", fontWeight: "500" }}>
                    {t("addBalance.allTransaction")}
                  </span>
                </p>
                {/* </Col> */}
                {/* </Row> */}
                {/* <p style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>hello</p> */}
              </Col>
              <Col xs={12} md={7} lg={6} className="mt-2">
                {/* <Row className="mx-auto"> */}
                {/* <Col sm={6} className=""> */}
                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <Form.Label
                    className="profilesetup-form-label"
                    column
                    xs="4"
                    md="4"
                    lg="4"
                  >
                    {t("addBalance.fromDate")}
                  </Form.Label>
                  <Col xs="8" md="8" lg="8">
                    <DatePicker
                      selected={fromDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setFromDate(date)}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      peekNextMonth
                      showMonthDropdown
                      maxDate={new Date()}
                      minDate={new Date("1890-04-11T10:20:30Z")}
                      showYearDropdown
                      dropdownMode="select"
                      required
                      placeholderText="dd/mm/yy"
                    />
                  </Col>

                  {/* <DatePicker selected={startDate} onChange={date => underAgeValidate(date)} placeholderText="dd/mm/yy" maxDate={new Date()} className={`form-control-lg ${validateAge && "error-input"}`} dateFormat="dd/MM/yyyy" /> */}
                </Form.Group>
                {/* </Col> */}
                {/* <Col sm={6} className=""> */}
                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <Form.Label
                    className="profilesetup-form-label"
                    column
                    xs="4"
                    md="4"
                    lg="4"
                  >
                    {t("addBalance.toDate")}
                  </Form.Label>
                  <Col xs="8" md="8" lg="8">
                    <DatePicker
                      selected={toDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setToDate(date)}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      peekNextMonth
                      showMonthDropdown
                      maxDate={new Date()}
                      minDate={new Date("1890-04-11T10:20:30Z")}
                      showYearDropdown
                      dropdownMode="select"
                      required
                      placeholderText="dd/mm/yy"
                    />
                  </Col>

                  {/* <DatePicker selected={startDate} onChange={date => underAgeValidate(date)} placeholderText="dd/mm/yy" maxDate={new Date()} className={`form-control-lg ${validateAge && "error-input"}`} dateFormat="dd/MM/yyyy" /> */}
                </Form.Group>
              </Col>
            </Row>

            <div className="transaction-details">
              <Row className="transaction-header">
                <Col className="transaction">
                  <ul className="nav nav-tabs">
                    <li
                      className={`nav-item nav-link ${key === "INCOMING" ? "active" : ""
                        }`}
                      onClick={() => handleTransactions(1)}
                    >
                      {<span> {t("addBalance.incoming")} </span>}
                    </li>
                    {/* <li className={`nav-item nav-link ${(key === "INCOMING") ? 'active' : ''}`} >{<span> Incoming </span>}</li> */}
                    <li
                      className={`nav-item nav-link ${key === "OUTGOING" ? "active" : ""
                        }`}
                      onClick={() => handleTransactions(2)}
                    >
                      {<span> {t("addBalance.outgoing")} </span>}
                    </li>
                  </ul>
                  {key === "INCOMING" ? (
                    <div className="transaction-detail-container">
                      {/* {incomingTransaction} */}
                      <IncomingTransaction
                        type={transactionType}
                        countryCurrencySign={props.countryCurrencySign}
                        fromDate={fromDate}
                        toDate={toDate}
                      />
                    </div>
                  ) : (
                      <div className="transaction-detail-container">
                        {/* {outgoingTransaction()} */}
                        <OutgoingTransaction
                          type={transactionType}
                          countryCurrencySign={props.countryCurrencySign}
                          fromDate={fromDate}
                          toDate={toDate}
                        />
                      </div>
                    )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(TransactionDetails);
