import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector } from "react-redux";
import "../../assets/scss/CookieConsent/CookieConsent.scss";
import { useTranslation } from "react-i18next";

const CookieConsent = (props) => {
  /*----- Cookie consent --------*/
  let cookieRef = useRef([createRef()]);
  const [showCookie, setShowCookie] = useState();

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const cookieStorage = {
    getItem: (key) => {
      const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[key];
    },

    setItem: (key, value) => {
      var now = new Date();
      now.setMonth(now.getMonth() + 1);
      document.cookie = `${key}=${value};expires=${now.toUTCString()}`;
    },
  };

  const acceptCookie = () => {
    if (props.fromLogin) {
      const cookieDiv = cookieRef.current[0].current;
      cookieDiv.classList.add("hide");
      cookieStorage.setItem("cookie_consent", true);
      window.location.reload(true);
    } else {
      const cookieDiv = cookieRef.current[0].current;
      cookieDiv.classList.add("hide");
      cookieStorage.setItem("cookie_consent", true);
    }
  };

  const declineCookie = () => {
    if (props.fromLogin) {
      props.setRedirectUrl("/");
      // const cookieDiv = cookieRef.current[0].current;
      // cookieDiv.classList.add("hide");
      // cookieStorage.setItem("cookie_consent", false);
      // setShowCookie(false);
    } else {
      const cookieDiv = cookieRef.current[0].current;
      cookieDiv.classList.add("hide");
      cookieStorage.setItem("cookie_consent", false);
      setShowCookie(false);
    }
  };

  useEffect(() => {
    if (props.fromLogin) {
      if (cookieStorage.getItem("cookie_consent") === "true") {
        setShowCookie(false);
      } else if (cookieStorage.getItem("cookie_consent") === "false") {
        setShowCookie(true);
      } else if (!cookieStorage.getItem("cookie_consent")) {
        setTimeout(() => {
          setShowCookie(true);
        }, 500);
      }
    } else {
      if (cookieStorage.getItem("cookie_consent") === "true") {
        setShowCookie(false);
      } else if (cookieStorage.getItem("cookie_consent") === "false") {
        setShowCookie(false);
      } else if (!cookieStorage.getItem("cookie_consent")) {
        setTimeout(() => {
          setShowCookie(true);
        }, 5000);
      }
    }
  }, []);

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <>
      {showCookie ? (
        <div className="cookie-consent-container" ref={cookieRef.current[0]}>
          <div className="cookie-popup">
            <h3 className="main-header">GoPoolit</h3>
            <p>{t("cookieConsent.text")}</p>

            <div className="cookie-inner-wrapper">
              <a onClick={acceptCookie} className="accept-cookie-btn">
                <div>{t("cookieConsent.accept")}</div>
              </a>

              <a onClick={declineCookie} className="decline-cookie-btn">
                <div>{t("cookieConsent.decline")}</div>
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookieConsent;
