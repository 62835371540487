import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

//css imports
import { Button, Card, Image, Row, Col, Container } from "react-bootstrap";
import "../../assets/scss/OtherUserProfile/otherUserProfile.scss";

//image imports
import avatar from "../../assets/images/avatar.png";

//components imports
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import OtherUserProfilePost from "./OtherUserProfilePost";
import OtherUserProfileFollowing from "./OtherUserProfileFollowing";
import OtherUserProfileFollowers from "./OtherUserProfileFollowers";
import OtherUserReportProfile from "./OtherUserReportProfile";
import Contents from "../Contents/Activity/contents";
import Loader from "../Loader";

//action import
import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";
import { FollowUser } from "../../api/FollowUserAPI";
import { UnfollowUser } from "../../api/UnFollowUserAPI";
import { getFollowersList } from "../../actions/follower_action";
import { getFollowingList } from "../../actions/following_action";
import { useTranslation } from "react-i18next";

const OtherUserProfile = (props) => {
  console.log("props", props);
  const userDetails = useSelector(
    (state) => state.otherUserProfileDetails.data
  );
  const userDetailsCurrency = useSelector((state) => state.userDetails);
  // const passwordDetails = useSelector((state) => state.verifyEnteredPassword.data); // to get country_currency_symbol
  const [followers, setFollowers] = useState(false);
  const [following, setFollowing] = useState(false);
  const [post, setPost] = useState(true);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const dispatch = useDispatch(); //Used to dispatch the actions from the component

  let country_wallet_currency_sign =
    userDetailsCurrency.country_wallet_currency_sign;

  //svg to show  location icon.
  const locationsvg = (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.07027 0C2.27451 0 0 2.27451 0 5.07025C0 8.53985 4.5374 13.6334 4.73058 13.8486C4.91204 14.0507 5.22884 14.0503 5.40997 13.8486C5.60315 13.6334 10.1405 8.53985 10.1405 5.07025C10.1405 2.27451 7.86601 0 5.07027 0ZM5.07027 7.62123C3.66366 7.62123 2.51932 6.47687 2.51932 5.07025C2.51932 3.66363 3.66368 2.51929 5.07027 2.51929C6.47687 2.51929 7.6212 3.66366 7.6212 5.07028C7.6212 6.47689 6.47687 7.62123 5.07027 7.62123Z"
        fill="#575757"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(
      getOtherUserDetailsByUserId(props.location.state.otherUserExternalId)
    );
  }, []);

  //function to display only post
  const handlePost = () => {
    setPost(true);
    setFollowers(false);
    setFollowing(false);
  };
  //function to display only Followers
  const handleFollowers = () => {
    setPost(false);
    setFollowers(true);
    setFollowing(false);
  };
  //function to display only Following
  const handleFollowing = () => {
    setPost(false);
    setFollowers(false);
    setFollowing(true);
  };

  const handleFollow = async (e, user_following_externalId, flag) => {
    e.preventDefault();
    if (flag === true) {
      await UnfollowUser(user_following_externalId);
      dispatch(getFollowersList(userDetails.userDetails.user_external_id));
      dispatch(getFollowingList(userDetails.userDetails.user_external_id));
      dispatch(
        getOtherUserDetailsByUserId(userDetails.userDetails.user_external_id)
      );
    }
    if (flag === false) {
      await FollowUser(user_following_externalId);
      dispatch(getFollowersList(userDetails.userDetails.user_external_id));
      dispatch(getFollowingList(userDetails.userDetails.user_external_id));
      dispatch(
        getOtherUserDetailsByUserId(userDetails.userDetails.user_external_id)
      );
    }
  };
  useEffect(() => {
    dispatch(getFollowingList(props.location.state.otherUserExternalId));
    dispatch(getFollowersList(props.location.state.otherUserExternalId));
  }, [userDetails]);
  if (!userDetails) return <Loader />;

  return (
    <section className="otherUserProfilePost-contribute-raised">
      {/* code to display navbar by importing Component */}
      <ContentNavBar />
      {/* code to display dashboard of user  */}
      <Card>
        <Container style={{ paddingTop: "2.5rem" }}>
          <Row>
            <Col
              sm={12}
              md={10}
              lg={8}
              className="mx-auto profile-header-wrapper"
            >
              <div className="profile-header">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    <Image
                      className="profiledashboard-profilepic"
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      // style={{ height: "174px", width: "178px" }}
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.userDetails.user_external_id}.jpg`}
                      roundedCircle
                    />
                  </span>
                  {/* {userDetails.userDetails.user_profile_pic !== null ? <Image className="profiledashboard-profilepic" style={{ height: "174px", width: "178px" }} src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.userDetails.user_external_id}.jpg`} roundedCircle /> :
                    <Image src={avatar} alt="profile-photo" className="profiledashboard-profilepic" style={{ height: "174px", width: "178px" }} roundedCircle />} */}
                  {/* <Image className="profiledashboard-profilepic" src={avatar} roundedCircle /> */}
                  <div className="profiledashboard-wrapper">
                    <p className="profiledashboard-user">
                      {userDetails.userDetails.user_full_name}
                    </p>
                    <div style={{ display: "flex" }}>
                      <span className="profile-dashboard-userName-location">
                        {userDetails.userDetails.username}
                      </span>
                      <span className="vertical-line">|</span>
                      <span className="profile-dashboard-userName-location">
                        {locationsvg}&nbsp;
                        {userDetails.userDetails.country_name}
                      </span>
                    </div>

                    {/* <p>Level 2</p> */}
                  </div>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  {/* {userDetails.userDetails.isfollowing ? <span style={{ color: "#2077b1", cursor: "pointer" }} onClick={(e) => { handleFollow(e, userDetails.userDetails.user_external_id, userDetails.userDetails.isfollowing) }}> Following</span > :
                    <span style={{ color: "#2077b1", cursor: "pointer" }} onClick={(e) => { handleFollow(e, userDetails.userDetails.user_external_id, userDetails.userDetails.isfollowing) }}> Follow </span >} */}

                  {userDetails.userDetails.isfollowing ? (
                    <Button
                      className="following-button"
                      onClick={(e) => {
                        handleFollow(
                          e,
                          userDetails.userDetails.user_external_id,
                          userDetails.userDetails.isfollowing
                        );
                      }}
                    >
                      {" "}
                      {t("profile.following")}
                    </Button>
                  ) : (
                    <Button
                      className="follow-button"
                      onClick={(e) => {
                        handleFollow(
                          e,
                          userDetails.userDetails.user_external_id,
                          userDetails.userDetails.isfollowing
                        );
                      }}
                    >
                      {" "}
                      {t("profile.follow")}{" "}
                    </Button>
                  )}
                  <OtherUserReportProfile
                    otherUserExternalId={
                      userDetails.userDetails.user_external_id
                    }
                  />
                </div>

                {/* <img src={horizontalDots} /> */}
              </div>

              <div className="flex-container">
                <div onClick={handlePost}>
                  <span className="profile-dashboard-noOfPost">
                    {userDetails.userDetails.totalposts}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {" "}
                    {t("profile.posts")}
                  </p>
                </div>

                <div>
                  <span className="profile-dashboard-noOfPost">
                    {country_wallet_currency_sign}
                    {Number(userDetails.userDetails.totalPooled).toFixed(2)}

                    {/* {Math.abs(userDetails.userDetails.totalPooled) > 999 ? Math.sign(userDetails.userDetails.totalPooled) * ((Math.abs(userDetails.userDetails.totalPooled) / 1000).toFixed(2)) + 'k' : Math.sign(userDetails.userDetails.totalPooled) * Math.abs(userDetails.userDetails.totalPooled)} */}
                    {/* {userDetails.userDetails.totalPooled} */}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {" "}
                    {t("profile.poolCollected")}
                  </p>
                </div>

                <div onClick={handleFollowers}>
                  <span className="profile-dashboard-noOfPost">
                    {userDetails.userDetails.totalfollowers}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {t("profile.followers")}
                  </p>
                </div>

                <div onClick={handleFollowing}>
                  <span className="profile-dashboard-noOfPost">
                    {userDetails.userDetails.totalfollowing}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {t("profile.following")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Card>

      {/* code to render post,followers,following ui based on condition */}
      <Container>
        <Row style={{ paddingTop: "1rem" }}>
          <Col sm={12} md={10} lg={8} className="mx-auto">
            {following ? (
              <OtherUserProfileFollowing
                otherUserExternalId={userDetails.userDetails.user_external_id}
              />
            ) : (
              ""
            )}
            {followers ? (
              <OtherUserProfileFollowers
                otherUserExternalId={userDetails.userDetails.user_external_id}
              />
            ) : (
              ""
            )}
            {post ? (
              <OtherUserProfilePost
                otherUserExternalId={props.location.state.otherUserExternalId}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OtherUserProfile;
