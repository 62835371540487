import { NEXMO_PHONE_STORED } from '../../constants/action-types'

const initState = {
    phone: ''
}

const storingPhoneNumberReducer = ( state = initState, action ) => {
    if (action.type === NEXMO_PHONE_STORED) {
        return {
            ...state,
            phone: action.payload
        }
    }
    return state
}

export default storingPhoneNumberReducer