import axios from 'axios';
import { GET_PAYMENT_ORDER_ID, VERIFY_RAZORPAY_PAYMENT } from '../constants/action-types';
import { BASEURL } from '../config';

export const getPaymentOrderId = (amount, currency, receiptId) => dispatch => {
    axios({
        method: 'POST',
        url: `${BASEURL}/v1/razorpayorderid`,
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        data: {
            amount: amount,
            currency: currency,
            receipt: receiptId
        }
    })
        .then(response =>
            dispatch({
                type: GET_PAYMENT_ORDER_ID,
                payload: response.data
            }))
        .catch(err => console.error(err))
}

export const verifyPayment = (orderId, razorpay_Payment_Id, razorpay_Signature) => dispatch => {
    axios({
        method: 'POST',
        url: `${BASEURL}/v1/signatureverification`,
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        data: {
            order_id: orderId,
            razorpay_payment_id: razorpay_Payment_Id,
            razorpay_signature: razorpay_Signature
        }
    })
        .then(response =>
            dispatch({
                type: VERIFY_RAZORPAY_PAYMENT,
                payload: response.data.response
            }))
        .catch(err => console.error(err))
}