import React, { useState, useEffect, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { storeGoogleData } from "../../actions/storingUserData";
import { googleSignUp } from "../../actions/login";

import { Button } from "react-bootstrap";
import "../../assets/scss/Login/googleLogin.scss";
import { userGoogleLogin } from "../../api/UserLoginApi";
import { storeUserDetails } from "../../actions/storingUserData";
import { userAuthorized } from "../../actions/isAuthorized";
// import Loader from "../Loader";

const GoogleLogin = (props) => {
  const [signedInWithGoogle, setsignedInWithGoogle] = useState(false);
  const [isloggedInUser, setLoggedInUser] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const myRef = useRef(null);

  useEffect(() => {
    googleSDK();
  }, []);
  const googleSDK = () => {
    window["googleSDKLoaded"] = () => {
      window["gapi"].load("auth2", () => {
        window.auth2 = window["gapi"].auth2.init({
          client_id:
            "9508555666-i7b8mi9mfkkvb93t5t431jioj9p03r5j.apps.googleusercontent.com",
          cookiepolicy: "single_host_origin",
          // cookiepolicy: "https://www.gopoolit.com",
          scope: "profile email",
        });
        prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  };
  const userlogin = async (token) => {
    let res = await userGoogleLogin(token);
    if (res.response.statusCode == 200) {
      localStorage.setItem("accessToken", res.response.data.accessToken);
      localStorage.setItem("refreshToken", res.response.data.refreshToken);
      localStorage.setItem(
        "user_external_id",
        res.response.data.user_external_id
      );
      dispatch(storeUserDetails(res.response.data.user_profile));
      dispatch(userAuthorized(true));
      setLoggedInUser(true);
    } else if (res.response.statusCode == 404) {
      setNewUser(true);
      setTimeout(() => {
        return history.push("/"), localStorage.clear();
      }, 5000);
      window.location.reload();
    }
  };
  const prepareLoginButton = async () => {
    const element = myRef.current;
    window.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        // console.log('what');
        let profile = googleUser.getBasicProfile();
        let token = googleUser.getAuthResponse().id_token;
        // console.log(profile);

        //pass the profile object to an action and store the data in redux store in form of array
        let googleValues = [];
        for (let x in profile) {
          googleValues.push(profile[x]);
        }
        //Pass the profile object to an action and store the data in redux store
        dispatch(storeGoogleData(googleValues)); // pass in array format
        // dispatch(storeGoogleData(profile));
        let photoUrl = profile.getImageUrl();
        profile.PK = photoUrl.replace(/s96-c/, "s200-c");

        //Send the id token to an api call to the backend and receive the JWT and save it in the store
        if (props.isSignUp === true) {
          return dispatch(googleSignUp(token)), setsignedInWithGoogle(true);
        }
        if (props.isLogin === true) {
          userlogin(token);
        }

        // alert("Login Complete", signedInWithGoogle);
      },
      (error) => {
        alert(error.error);
        // alert("Please enable cookies if you are in incognito or use another login platform")
        // console.log(JSON.stringify(error, undefined, 2));
      }
    );
  };

  return (
    <>
      <Button
        className="google-login-media-button"
        ref={myRef}
        disabled={props.cookiesNotAccepted ? true : false}
      >
        <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9873 12.2247C22.9873 11.2414 22.909 10.5239 22.7397 9.77979H11.7285V14.2178H18.1918C18.0616 15.3207 17.3579 16.9816 15.7942 18.0977L15.7722 18.2463L19.2538 20.9965L19.495 21.021C21.7102 18.9349 22.9873 15.8654 22.9873 12.2247Z"
            fill="#4285F4"
          />
          <path
            d="M11.7289 23.9176C14.8954 23.9176 17.5537 22.8545 19.4954 21.0209L15.7946 18.0976C14.8042 18.8018 13.475 19.2934 11.7289 19.2934C8.62755 19.2934 5.99531 17.2074 5.05699 14.324L4.91945 14.3359L1.2993 17.1927L1.25195 17.3269C3.18052 21.2334 7.14196 23.9176 11.7289 23.9176Z"
            fill="#34A853"
          />
          <path
            d="M5.05793 14.3238C4.81034 13.5797 4.66706 12.7824 4.66706 11.9586C4.66706 11.1347 4.81034 10.3375 5.0449 9.59342L5.03834 9.43494L1.37283 6.53223L1.2529 6.59039C0.458043 8.21149 0.00195312 10.0319 0.00195312 11.9586C0.00195312 13.8853 0.458043 15.7056 1.2529 17.3267L5.05793 14.3238Z"
            fill="#FBBC05"
          />
          <path
            d="M11.7289 4.62403C13.9311 4.62403 15.4166 5.59402 16.2637 6.40461L19.5735 3.10928C17.5407 1.1826 14.8954 0 11.7289 0C7.14195 0 3.18052 2.68406 1.25195 6.59056L5.04396 9.59359C5.9953 6.7102 8.62754 4.62403 11.7289 4.62403Z"
            fill="#EB4335"
          />
        </svg>
        Google
      </Button>
      {/* redirect to profile setup when user tries to signup */}
      {signedInWithGoogle && (
        <Redirect
          to={{
            pathname: "/profile-setup",
          }}
        />
      )}

      {/* redirecting to sign up page when new user tries to login with google*/}
      {isNewUser ? (
        <Redirect
          to={{
            pathname: "/signup",
            state: "error",
          }}
        />
      ) : null}

      {/* redirecting to home page when existing user tries to login  */}
      {isloggedInUser ? <Redirect to="/home" /> : null}
    </>
  );
};

export default GoogleLogin;
