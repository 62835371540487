import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Loader";
import { useHistory } from "react-router-dom";

import { Form } from "react-bootstrap";

//api import
import {
  MostPooledApi,
  MostPooledDefault,
  MostPooledForCountry,
  MostPooledDuration,
} from "../../../api/MostPooledAPI";

//component image
import ReportPost from "../ReportPost";
import PooledModal from "./PooledModal";
import Tour from "../../Tour/Tour";
//image import
import avatar from "../../../assets/images/avatar.png";
import unsupport from "../../../assets/images/unsupport.png";

//action import
import { getOtherUserDetailsByUserId } from "../../../actions/otherUserProfile/otherUserProfile_action";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "purple",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
  },

  dropdown: {
    '& [class*="MuiOutlinedInput-root"]': {
      background: '#fff',
      padding: 0,
    },
  },
}));

const PooledContentPage = () => {
  const classes = useStyles();

  // const countriesList = useSelector((state) => state.countriesList);
  const allowedCountries = useSelector((state) => state.allowedCountries);
  const charityNames = useSelector((state) => state.charityNames);
  const userDetails = useSelector((state) => state.userDetails);

  const [targetItem, setTargetItem] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [changeCountry, setChangeCountry] = useState(userDetails);
  const [changeCharity, setChangeCharity] = useState(userDetails);

  const [mostPooledData, setMostPooledData] = useState("");
  const [date, setDate] = useState("");
  const [dateValue, setDateValue] = useState("1");
  const [countryName, setCountry] = useState("");
  const [countryID, setCountryID] = useState("");
  const [charity, setCharity] = useState("");
  const [charityID, setCharityID] = useState("");
  const [loading, setLoading] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [defaultPosts, setDefaultPosts] = useState([]);
  const [defaultPostStatus, setDefaultPostStatus] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    if (userDetails.cause_name === null && userDetails.country_name === null) {
      setCharity("");
      setCharityID("");
      setCountry("");
      setCountryID("");
    } else {
      setCharity(userDetails.cause_name);
      setCharityID(userDetails.cause_external_id);
      setCountry(userDetails.country_name);
      setCountryID(userDetails.country_external_id);
    }
  }, []);

  useEffect(() => {
    // console.log(countryName + " || " + charity + " || " + dateValue);
    handleFilterCall();
  }, [countryName, charity, dateValue]);

  const handleFilterCall = () => {
    if (dateValue !== "" && countryName === "" && charity === "") {
      getDataForDuration();
    } else if (dateValue !== "" && countryName !== "" && charity === "") {
      getDataForCountry();
    } else if (dateValue !== "" && countryName !== "" && charity !== "") {
      mostPooled();
    }
  };

  //------- API call to load default data -------//
  // useEffect(() => {
  //   initialRender();
  // }, []);

  // const initialRender = async () => {
  //   setLoading(true);
  //   let response = await MostPooledDefault();
  //   setInitialLoading(true);
  //   // console.log(response);
  //   if (response.statusCode === 200) {
  //     setDefaultPosts(response.data);
  //   } else {
  //     setDefaultPostStatus(true);
  //   }

  //   setLoading(false);
  // };

  //------ API call to load data based on the filters ------//

  const mostPooled = async () => {
    if (countryName !== "" && charity !== "" && dateValue !== "") {
      setLoading(true);
      let mostPooledResponse = await MostPooledApi(
        charityID,
        dateValue,
        countryID
      );

      if (mostPooledResponse.statusCode === 204) {
        setEmptyData(true);
        setMostPooledData("");
        setInitialLoading(false);
      } else {
        setMostPooledData(mostPooledResponse.data);
        setEmptyData(false);
        setInitialLoading(false);
      }
      setLoading(false);
    }
  };

  //------ API call to load the data based on the country and duration -------//

  const getDataForCountry = async () => {
    if (countryName !== "" && dateValue !== "" && charity === "") {
      setLoading(true);
      const response = await MostPooledForCountry(dateValue, countryID);

      if (response.statusCode === 204) {
        setEmptyData(true);
        setMostPooledData("");
        setInitialLoading(false);
      } else {
        setMostPooledData(response.data);
        setEmptyData(false);
        setInitialLoading(false);
      }
      setLoading(false);
    }
  };

  //------ API call to load the data based on duration -------//

  const getDataForDuration = async () => {
    if (dateValue !== "" && countryID === "" && charity === "") {
      setLoading(true);
      const response = await MostPooledDuration(dateValue, countryID);

      if (response.statusCode === 204) {
        setEmptyData(true);
        setMostPooledData("");
        setInitialLoading(false);
      } else {
        setMostPooledData(response.data);
        setEmptyData(false);
        setInitialLoading(false);
      }
      setLoading(false);
    }
  };

  // -------- Filter handlers -------- //
  const handleDate = (e) => {
    setDateValue(e.target.value);

    switch (e.target.value) {
      case "1":
        setDate("Today");
        break;
      case "7":
        setDate("This week");
        break;
      case "30":
        setDate("This month");
        break;
      case "90":
        setDate("3 months");
        break;
      default:
        setDate("Today");
    }
  };

  const handleCountry = (e, value) => {
    // console.log(value);
    if (value != null) {
      let countryExternalId = value.country_external_id;

      if (countryExternalId !== "") {
        setCountryID(countryExternalId);
        Object.values(allowedCountries).map((country) => {
          if (country.country_external_id === countryExternalId) {
            setCountry(country.country_name);
            setCharity("");
            setCharityID("");
          }
        });
        setChangeCountry(value);
        setChangeCharity([{ cause_name: null }])
      } else if (countryExternalId === "") {
        setCountryID("");
        setCountry("");
        setCharity("");
        setCharityID("");
      }
    } else if (value == null) {
      setCountryID("");
      setCountry("");
    }
  };

  // const handleCharity = (e) => {
  //   if (e !== null) {
  //     const charity = e;
  //     const name = charity.split(",");
  //     setCharityID(name[0]);
  //     setCharity(name[1]);
  //   } else {
  //     setCharityID("");
  //     setCharity("");
  //   }
  // };

  const handleCharity = (e, value) => {
    // console.log(value);
    // console.log(changeCharity)
    if (value != null) {
      let causeExternalId = value.cause_external_id;
      if (causeExternalId !== "") {
        setCharityID(causeExternalId);
        charityNames.data.map((cause) => {
          if (cause.cause_external_id === causeExternalId) {
            setCharity(cause.cause_name);
          }
        });
        setChangeCharity(value)
      } else {
        setCharityID("");
        setCharity("");
      }
    }else{
      setChangeCharity(value)
      setCharity("")
    }
  };
  // ---------------------------------- //

  const modalHandler = (id) => {
    if (initialLoading) {
      const defaultItems = defaultPosts.find(
        (item) => item.content_external_id === id
      );
      setTargetItem(defaultItems);
    } else {
      const modalItem = mostPooledData.find(
        (item) => item.content_external_id === id
      );
      setTargetItem(modalItem);
    }
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const hideShow = () => {
    setOpenModal(false);
    setTargetItem("");
    handleFilterCall();
  };



  const handleOtherUser = (otherUserExternalId) => {
    history.push({
      pathname: "/otheruserprofile",
      state: { otherUserExternalId: otherUserExternalId },
    });
    // history.push("/otheruserprofile");
    dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
  };

  const duration = [
    { title: "Today", value: "1" },
    { title: "This month", value: "30" },
    { title: "3 months", value: "90" },
  ];

  const allowedCountryName = Object.values(allowedCountries);

  const charities = charityNames.data;
  // console.log(charities);

  const renderDefault = loading ? (
    <Loader />
  ) : defaultPostStatus === false ? (
    defaultPosts.map((item, index) => {
      return (
        <div type="" className="p-0" key={index}>
          <div
            key={index}
            className="most-pooled-item tour-logo"
            onClick={() => modalHandler(item.content_external_id)}
          >
            <div className="most-pooled-item-container">
              <div
                className="most-pooled-main"
                onClick={() => handleOtherUser(item.content_creator_id)}
              >
                <div>
                  <span className="image-display-pic mr-3">
                    <img
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      className="profile-picture"
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                      roundedCircle
                      width="40"
                      height="40"
                      style={{ objectFit: "cover", borderRadius: "50%" }}
                    />
                  </span>
                </div>

                <div>
                  <h5 className="most-pooled-name">{item.username}</h5>
                </div>
              </div>
              <div>
                {/* {item.content_creator_id === userExternalID ? (
                    ""
                  ) : (
                      <ReportPost contentExternalId={item.content_external_id} />
                    )} */}
              </div>
            </div>
            {/* <div>
                <img
                  src={item.content_url[0].signedUrl}
                  alt="content image"
                  className="most-pooled-display-picture"
                  onClick={showModal}
                />
              </div> */}

            <div>
              {item.content_url[0].contentType === "image/jpeg" ||
                item.content_url[0].contentType === "image/png" ? (
                <img
                  onError={(ev) => (ev.target.src = `${unsupport}`)}
                  src={item.content_url[0].signedUrl}
                  alt="content image"
                  className="most-pooled-display-picture"
                  onClick={showModal}
                />
              ) : (
                <video
                  className="most-pooled-display-video"
                  style={{ width: "100%" }}
                  src={item.content_url[0].signedUrl}
                  controls
                  muted
                  onClick={showModal}
                ></video>
              )}
            </div>
          </div>
        </div>
      );
    })
  ) : (
    // "No data to show"
    t("mostPooled.noPost")
  );

  const renderData = emptyData ? (
    <p className="mostpooled-emptydata-text">{t("mostPooled.noPost")}</p>
  ) : (
    mostPooledData &&
    mostPooledData.map((item, index) => {
      return (
        <div type="" className="p-0">
          <div
            key={index}
            className="most-pooled-item"
            onClick={() => modalHandler(item.content_external_id)}
          >
            <div className="most-pooled-item-container">
              <div
                className="most-pooled-main"
                onClick={() => handleOtherUser(item.content_creator_id)}
              >
                <div>
                  {/* <span className="image-display-pic mr-3"> */}
                  <img
                    onError={(ev) => (ev.target.src = `${avatar}`)}
                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                    roundedCircle
                    width="40"
                    height="40"
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="most-pooled-profile-pic"
                  />
                  {/* </span> */}
                </div>

                <div>
                  <h5 className="most-pooled-name">{item.username}</h5>
                </div>
              </div>
              {/* <div>
                  {item.content_creator_id === userExternalID ? (
                    ""
                  ) : (
                      <ReportPost contentExternalId={item.content_external_id} />
                    )}
                </div> */}
            </div>
            <div>
              {item.content_url[0].contentType === "image/jpeg" ||
                item.content_url[0].contentType === "image/png" ? (
                <img
                  onError={(ev) => (ev.target.src = `${unsupport}`)}
                  src={item.content_url[0].signedUrl}
                  alt="content image"
                  className="most-pooled-display-picture"
                  onClick={showModal}
                />
              ) : (
                <video
                  className="most-pooled-display-video"
                  style={{ width: "100%" }}
                  src={item.content_url[0].signedUrl}
                  controls
                  // autoPlay="autoplay"
                  muted
                  onClick={showModal}
                ></video>
              )}
            </div>
          </div>
        </div>
      );
    })
  );

  if (!charityNames.data) return null;
  if (!allowedCountries) return null;

  return (
    <>
      <Tour />
      <aside className="activityPage-aside-posts">
        <p className="tour-most-pooled">{t("mostPooled.title")}</p>
        <div className="dropdown-outer-container">
          {/* --------- Duration/Date dropdown -------- */}
          <div className="dropdown-container">
            {/* <Autocomplete
              id="durationFilter"
              options={duration}
              onChange={(e, value) => handleDate(e, value)}
              // defaultValue={dateValue}
              // value={dateValue}
              getOptionLabel={option => option.title}
              // style={{ width: 300 }}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Today"
                    fullWidth
                  />
                );
              }} /> */}

            <Form.Control
              id="dropdown-button"
              className="dropdown-btn"
              as="select"
              onChange={(e) => handleDate(e)}
              value={dateValue}
            >
              <option value="1">
                {t("mostPooled.filters.duration.today")}
              </option>
              <option value="7">
                {t("mostPooled.filters.duration.thisWeek")}
              </option>
              <option value="30">
                {t("mostPooled.filters.duration.thisMonth")}
              </option>
              <option value="90">
                {t("mostPooled.filters.duration.3months")}
              </option>
            </Form.Control>
          </div>

          {/* --------- Country dropdown -------- */}
          <div className="dropdown-container">
            <Autocomplete
              id="countryFilter"
              options={allowedCountryName}
              onChange={(e, value) => handleCountry(e, value)}
              className={classes.dropdown}
              // defaultValue={dateValue}
              value={changeCountry}
              getOptionLabel={(option) => option.country_name ? option.country_name : ""}

              // value={dateValue}              // style={{ width: 300 }}
              renderInput={(params) => {
                return (
                  <>
                    {userDetails.country_name === null ? (
                      <TextField
                        {...params}
                        variant="outlined"
                        // placeholder="Country"
                        placeholder={t("mostPooled.filters.country")}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={`${userDetails.country_name}`}
                        fullWidth
                      />
                    )}
                  </>
                );
              }}
            />

            {/* <Form.Control
              // disabled={causeName === null ? true : false}
              id="dropdown-button"
              className="dropdown-btn"
              as="select"
              onChange={(e) => handleCountry(e)}
              value={countryID}
            >
              {userDetails.country_name === null ? (
                <option value="">{t("mostPooled.filters.country")}</option>
              ) : (
                <>
                  <option value="">Country</option>
                  <option value={userDetails.country_external_id}>
                    {userDetails.country_name}
                  </option>
                </>
              )}

              {Object.values(allowedCountries)
                .filter(
                  (item) =>
                    item.country_external_id !== userDetails.country_external_id
                )
                .map((countryData, index) => {
                  return (
                    <option
                      value={countryData.country_external_id}
                      key={countryData.country_external_id}
                    >
                      {countryData.country_name}
                    </option>
                  );
                }
                )
              }
            </Form.Control>
           */}
          </div>
        </div>

        {/* --------- Charity dropdown -------- */}
        <div className="dropdown-container dropdown-charity">
          <Autocomplete
            disabled={countryName === "" ? true : false}
            id="countryFilter"
            className={classes.dropdown}
            // return
            // countryName == charities.country_name ? (charities) : ("")
            // }

            // options={charities.map((cause) => {
            //   return countryName === cause.country_name ? charities : "";
            // })}
            options={charities.filter(
              (cause) => cause.country_name === countryName
            )}
            onChange={(e, value) => handleCharity(e, value)}
            value={changeCharity}
            getOptionLabel={(option) => option.cause_name ? option.cause_name : ""}
            renderInput={(params) => {
              return (
                <>
                  {userDetails.cause_name === null ? (
                    <TextField
                      {...params}
                      variant="outlined"
                      // placeholder="Cause"
                      placeholder={t("mostPooled.filters.charity")}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'cause', // disable autocomplete and autofill
                      }}
                    />
                  ) : (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={`${userDetails.cause_name}`}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'cause', // disable autocomplete and autofill
                      }}
                    />
                  )}
                </>
              );
            }}
          />

          {/* <Form.Control
            disabled={countryName === "" ? true : false}
            id="dropdown-button"
            className="dropdown-btn"
            as="select"
            onChange={(e) => handleCharity(e)}
            value={charityID}
          >
            {userDetails.cause_name === null ? (
              <option value="">{t("mostPooled.filters.charity")}</option>
            ) : (
              <>
                <option value="">{t("mostPooled.filters.charity")}</option>
                <option value={userDetails.cause_external_id}>
                  {userDetails.cause_name}
                </option>
              </>
            )}
            {charityNames.data
              .filter(
                (item) =>
                  item.cause_external_id !== userDetails.cause_external_id
              )
              .map((cause, index) => {
                return countryName === cause.country_name ? (
                  <option
                    value={cause.cause_external_id}
                    key={cause.cause_external_id}
                  >
                    {cause.cause_name}
                  </option>
                ) : (
                  ""
                );
              })}
          </Form.Control> */}
        </div>

        <div className="activityPage-pooled-post">
          {loading ? <Loader /> : initialLoading ? renderDefault : renderData}
        </div>
      </aside>
      {defaultPostStatus !== true ? (
        <PooledModal
          data={initialLoading ? defaultPosts : mostPooledData}
          targetItem={targetItem}
          show={openModal}
          hide={hideShow}
          countryName={countryName}
          charity={charity}
          dateValue={dateValue}
        />
      ) : null}
    </>
  );
};
export default PooledContentPage;
