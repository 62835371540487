import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Image, Row, Col, Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Tabs, Tab, Box } from "@material-ui/core";

//import component
import ContentNavBar from "../ContentNavbar/ContentNavbar";

//import image
import achievementPolygon from "../../assets/svg/achievementPolygon.svg";

//import api
import { achievementApi } from "../../api/AchievementAPI";

//import css
import "../../assets/scss/Achievement/achievement.scss";
import Loader from "../Loader";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import UpcomingBadges from "./UpcomingBadges";
import AchievedBadges from "./AchievedBadges";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#2077b1",
    },
    "& .PrivateTabIndicator-colorPrimary-3": {
      background: "#2077b1",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      background: "#2077b1",
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#C4C4C4",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

const Achievements = () => {
  // Language Translation

  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const [key, setKey] = useState("upcomingBadges");
  // const userDetails = useSelector((state) => state.userDetails);
  const userExternalId = `${localStorage.getItem("user_external_id")}`;
  const [badges, setBadges] = useState();
  const [achievedBadges, setAchieveedBadges] = useState();
  const [upcomingBadges, setUpcomingBadges] = useState();
  const [userLevelInfo, setUserLevelInfo] = useState();
  // const [startProgress, setStartProgress] = useState(0);

  // const [endProgress, setEndProgress] = useState(50);

  const [progress, setProgress] = useState(0);
  // const [progressTrue, setProgressTrue] = useState(true);
  const userDetails = useSelector((state) => state.userDetails);

  const [value, setValue] = useState(0);

  const [pointsAchieved, setPointsAchieved] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    const achievementsResponse = async () => {
      const response = await achievementApi();
      const badgeDataResponse = await response.response.data.userBadges;
      const userLevelResponse = await response.response.data.userLevel;
      const achieveDataResponse = await response.response.data.userAchievements;
      const upcomingResponse = await response.response.data.upcomingBadges;
      setBadges(badgeDataResponse);
      setAchieveedBadges(achieveDataResponse);
      setUpcomingBadges(upcomingResponse);
      setUserLevelInfo(userLevelResponse);
      // setPointsAchieved(+userLevelResponse[0].user_current_point)
      setPointsAchieved(
        (+userLevelResponse[0].user_current_point /
          +userLevelResponse[0].next_level_point) *
        100
      );
    };
    achievementsResponse();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === pointsAchieved) {
          return pointsAchieved;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, pointsAchieved);
      });
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, [userLevelInfo, pointsAchieved]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // function to close modal popup based on true or false
  const handleAcheivedBadges = (type) => {
    async function fetchTransactionData() {
      if (type === "upcomingBadges") {
        setKey("upcomingBadges");
        // setTransactionType(1)
      } else {
        // setTransactionType(2)
        setKey("achievedBadges");
      }
    }
    fetchTransactionData();
  };

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <div className="achievement-main">
      <ContentNavBar />
      {/* {badges && achievedBadges ? ( */}
      <>
        <div style={{ backgroundColor: "#E5E5E5", minHeight: "86vh" }}>
          <Container className="mb-3">
            <Row>
              <Col sm={12} md={12} lg={8} className="mx-auto">
                <Card className="achievement-card">
                  <div className="achievement-details">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ position: "relative" }}>
                        <p className="achieveLevel">
                          {t("achievements.level")}
                        </p>
                        <Image
                          className="achievement"
                          src={achievementPolygon}
                        />
                        {userLevelInfo &&
                          userLevelInfo.map((level) => {
                            return (
                              <span className="acheiveUserLevel">
                                {level.achievement_level}
                              </span>
                            );
                          })}
                      </div>
                      <div className="achieveLevelInfoWrapper">
                        <p className="achievement-user">
                          {userDetails.user_full_name}
                        </p>
                        <div className={classes.root}>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                          />
                        </div>
                        <div className="achieveLevelInfo">
                          <>
                            <span className="achieveLevelPoint">
                              {t("achievements.pointsForNextLevel")}:{" "}
                              <span></span>
                              {userLevelInfo &&
                                userLevelInfo.map((level) => {
                                  return <>{level.user_required_point}</>;
                                })}
                            </span>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>

                <div className="badges-container">
                  <Row className="badges-header">
                    <Col className="badges-tab">
                      {/* <ul className="nav nav-tabs">
                        <li
                          className={`nav-item nav-link ${key === "upcomingBadges" ? "active" : ""
                            }`}
                          onClick={() => handleAcheivedBadges('upcomingBadges')}
                        >
                          {<span> Upcoming Badges </span>}
                        </li>
                        <li
                          className={`nav-item nav-link ${key === "achievedBadges" ? "active" : ""
                            }`}
                          onClick={() => handleAcheivedBadges('achievedBadges')}
                        >
                          {<span> Achieved Badges </span>}
                        </li>
                      </ul>
                       */}
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                      // centered
                      >
                        <Tab label={t("achievements.upcomingBadges")} />
                        <Tab label={t("achievements.achievedBadges")} />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                        {upcomingBadges && (
                          <UpcomingBadges upcomingBadgesList={upcomingBadges} />
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        {achievedBadges && achievedBadges.length > 0 ? (
                          <AchievedBadges achievedBadgesList={achievedBadges} />
                        ) : (
                            <div className="noAchievement">
                              <h5>You haven’t achieved any badge till now</h5>
                              <p>
                                You haven’t done anything yet, start pooling,
                                creating post, get new followers, get golden
                                buzzer’s for your posts, share your posts to get
                                badge’s
                            </p>
                            </div>
                          )}
                      </TabPanel>

                      {/* {key === "upcomingBadges" ? (
                        <>
                          {upcomingBadges && <UpcomingBadges upcomingBadgesList={upcomingBadges} />}
                        </>
                      ) : (
                          <>
                            {achievedBadges.length > 0 ? <AchievedBadges achievedBadgesList={achievedBadges} /> : <div className="noAchievement"><h5>You haven’t achieved any badge till now</h5><p>You haven’t done anything yet, start pooling, creating post, get new followers, get golden buzzer’s for your posts, share your posts to get badge’s</p></div>}

                          </>
                        )} */}
                    </Col>
                  </Row>
                </div>

                {/* {Modal()}
                  {modalEachBadge ? openModal() : null} */}
              </Col>
            </Row>
          </Container>
        </div>
        <LeanmoreFooter />
      </>
      {/* ) : (
          <Loader />
        )} */}
    </div>
  );
};
export default Achievements;
