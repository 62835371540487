import { UPDATE_PROFILE_PICTUTRE } from '../../constants/action-types'

const initState = {}

const ProfilePicUpdateReducer = (state = initState, action) => {
    if (action.type === UPDATE_PROFILE_PICTUTRE) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state
}
export default ProfilePicUpdateReducer