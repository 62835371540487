import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Image, Form } from "react-bootstrap";
import { userTaggedCauseList } from "../../api/UsersTaggedCauseApi";
import { FaInfoCircle } from "react-icons/fa";
import axios from "axios";

//File imports
import SharingOnMedia from "../Contents/SharingOnMedia";
import ContentSlider from "../Contents/Activity/ContentSlider";
import ToastMessage from "../ToastMessage";
import EmojiSlider from "../EmojiSlider/EmojiSlider";

//api call
import useUserPosts from "../../actions/customHooks/useUserPosts";
import { BASEURL } from "../../config";
import { getUserDetailsByUserId } from "../../actions/userProfile_action";
import Linkify from "linkifyjs/react";

//css imports
import "../../assets/scss/UserProfile/profile.scss";
import "../../assets/scss/Contents/activityPage.scss";
import "../../assets/scss/UserProfile/follow.scss";

//png and svg imports from assets
import CharityLogo from "../../assets/images/charity logo.png";
// import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg';
import pooledCollect from "../../assets/svg/pooledCollect.svg";
// import userImage1 from "../../assets/images/userImage1.png";
// import userImage2 from "../../assets/images/userImage2.png";
// import userImage3 from "../../assets/images/userImage3.png";
import PeoplePooledModal from "./PeoplePooledModal";
import avatar from "../../assets/images/avatar.png";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import DeleteContent from "../Contents/DeleteContent";
import CharityInfo from "../CharityInfo/CharityInfo";
import { useTranslation } from "react-i18next";

const Post = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // const userProfileData = useSelector((state) => state.userProfileDetails.data);
  const userexternalid = `${localStorage.getItem("user_external_id")}`;
  const [pageNo, setPageNo] = useState(1);
  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const len = 90;
  // const userPosts = useSelector(state => state.userProfilePostDetails.data);
  const [charity, setCharity] = useState("");
  const [date, setDate] = useState("");
  // const [userPostData, setUserPostData] = useState([]);
  const [Commentsection, setCommentSection] = useState(-1);
  const [showCharityDes, setShowCharityDes] = useState();

  const [showUserDeletebtn, setDeleteBtn] = useState(true);
  const [userTaggedCause, setUserTaggedCause] = useState([]);
  const [showmodal, setShowModal] = useState(false);
  const [contentExternalId, setcontentExternalId] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [deleteContent, setDeleteContent] = useState();
  const [contentEditable, setContentEditable] = useState(false);
  const [contentDescription, setContentDescription] = useState("");
  const [editCEID, setEditCEID] = useState("");
  const [editedSuccessfully, setEditedSuccessfully] = useState(false);
  const [editedContentId, setEditedContentId] = useState();

  const userDetails = useSelector((state) => state.userDetails);
  const charitiesList = useSelector((state) => state.charityNames.data);

  const observer = useRef();
  const cardElement = useRef();

  const dispatch = useDispatch();

  //-------------- Slick slider --------------//
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  // const ShowComments = (index) => {
  //   setCommentSection(index);
  // };
  const handleModal = (contentExternalId) => {
    setcontentExternalId(contentExternalId);
    setShowModal(!showmodal);
  };
  // useEffect(() => {
  //     async function fetchData() {
  //         let charityValue = charity ? charity : null;
  //         let dateValue = date ? date : null;
  //         let UserPostResponse = await userPosts(userexternalid, null, dateValue, charityValue);
  //         setUserPostData(UserPostResponse.data);
  //     }
  //     fetchData();

  // }, [charity, date])
  let charityValue = charity ? charity : null;
  let dateValue = date ? date : null;

  const { loading, error, contentsArray, hasMore } = useUserPosts(
    pageNo,
    dateValue,
    charityValue,
    deleteContent,
    editedSuccessfully
  );

  const lastElementInContent = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNo((prevpageNo) => prevpageNo + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    setPageNo(1);
    // console.log("fxcgvhbjnk");
  }, [charityValue, dateValue]);

  useEffect(() => {
    async function LoadCharitybasedOnUserTagged() {
      let charityList = await userTaggedCauseList(userexternalid);
      if (charityList.data.length > 0) {
        setUserTaggedCause(charityList.data);
      } else {
        setUserTaggedCause([]);
      }
    }
    LoadCharitybasedOnUserTagged();
  }, []);

  //onSelect function to select time from dropdown
  // const handleDate = (e) => {
  //   setDate(e);
  // };
  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return (
          <span className="timeline">
            {month} {t("post.monthAgo")}
          </span>
        );
      } else {
        return (
          <span className="timeline">
            {month} {t("post.monthsAgo")}
          </span>
        );
      }
    } else if (days) {
      if (days === 1)
        return (
          <span className="timeline">
            {days} {t("post.dayAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {days} {t("post.daysAgo")}
        </span>
      );
    } else if (hours) {
      if (hours === 1)
        return (
          <span className="timeline">
            {hours} {t("post.hourAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {hours} {t("post.hoursAgo")}
        </span>
      );
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return (
          <span className="timeline">
            {minutes} {t("post.minuteAgo")}
          </span>
        );
      return (
        <span className="timeline">
          {minutes} {t("post.minutesAgo")}
        </span>
      );
    }
  };

  const handleContentExternalID = (content_external_id) => {
    setDeleteContent(content_external_id);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 6000);
  };

  const handleContentEditable = (
    value,
    content_external_id,
    content_description
  ) => {
    setContentEditable(value);
    setContentDescription(content_description);
    setEditCEID(content_external_id);
  };

  const handleEditToPost = async (content_external_id) => {
    console.log(contentDescription);
    axios({
      url: `${BASEURL}/v1/editContent/${content_external_id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        content_description: contentDescription,
      },
    })
      .then((res) => {
        if (res.data.response.statusCode === 200) {
          setContentEditable(false);
          setEditedSuccessfully(!editedSuccessfully);
          // setTimeout(() => {
          //   setEditedSuccessfully(false);
          // }, 1000);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCharityInfo = (contentExternalID) => {
    setShowCharityDes(contentExternalID);
    // console.log(`${description}`)
  };

  let activityCards = contentsArray.map((item, index) => {
    if (contentsArray.length === index + 1) {
      return (
        <>
          <Card
            className="profile-postcontent-card"
            style={{ marginTop: "1rem" }}
            key={item.content_external_id}
            ref={lastElementInContent}
          >
            <Card.Header className="profile-postcontent-header">
              <div>
                {/* <span className="image-display-pic" style={{ verticalAlign: "top" }}> */}
                <Image
                  className="profile-picture"
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`}
                  roundedCircle
                  style={{ width: "51px", height: "51px" }}
                />
                {/* </span> */}
                {/* {item.user_profile_pic !== null ? <Image className="profile-picture" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                                  <Image src={avatar} alt="profile-photo" className="profile-picture" roundedCircle style={{ width: "51px", height: "51px" }} />} */}
                {/* <Image className="profile-picture" src={avatar} roundedCircle /> */}
                <ul>
                  <li>{item.username}</li>
                  <li>{getPostedTime(item.content_created_on)}</li>
                </ul>
                <DeleteContent
                  item={item}
                  handleContentExternalID={handleContentExternalID}
                />
              </div>
            </Card.Header>
            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                  {item.content_description.length <= len && (
                    <Card.Text>
                      <Linkify tagName="span">
                        {item.content_description}
                      </Linkify>
                    </Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description.substring(0, len) + "..."}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          {t("post.seeMore")}
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description}
                        </Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          {t("post.seeLess")}
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>

            {/* <br /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "49px",
                padding: "0 1rem",
              }}
            >
              <div>
                <div className="profile-comment-pooled-raised">
                  {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                  <div
                    onClick={() => {
                      handleModal(item.content_external_id);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div className="userImageWrapper">
                      <ul className="hx_flex-avatar-stack">
                        {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                              .slice(0, 4)
                              .map((people_pooled, index) => {
                                return (
                                  <li
                                    className="hx_flex-avatar-stack-item"
                                    key={index}
                                  >
                                    <img
                                      className="avatar avatar-user"
                                      alt="@jqn"
                                      onError={(ev) =>
                                        (ev.target.src = `${avatar}`)
                                      }
                                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                      width="20"
                                      height="20"
                                    />
                                  </li>
                                );
                              })
                          : ""}
                      </ul>
                    </div>
                    <span className="profile-pooled">
                      {" "}
                      {item.total_people_pooled} {t("post.peoplePooled")}
                    </span>
                  </div>
                  <span style={{ margin: "0 0.5rem" }}> • </span>
                  <img src={pooledCollect} alt="pooledCollect.svg" />

                  <p className="profile-pooled">
                    <span className="profile-user-pooled-value">
                      {" "}
                      {userDetails.country_wallet_currency_sign}
                      {item.total_amount_pooled.toFixed(2)}{" "}
                    </span>
                    {t("post.poolCollected")}
                  </p>
                  {/* <span className="profile-pooled"> Pool collected</span> */}
                </div>
              </div>
              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}{" "}
                  {item.totalcomments > "1"
                    ? t("post.commentPlural")
                    : t("post.commentSingular")}
                </span>
              </div>
            </div>
            <div className="profile-charity">
              <img
                className="profile-charity-logo"
                src={item.cause_image}
                onError={(ev) => (ev.target.src = `${CharityLogo}`)}
                alt="charity-logo"
              />
              <ul>
                <li className="profile-charityName">{item.cause_name}</li>
                <li className="profile-charityLocation">{item.country_name}</li>
              </ul>
              {charitiesList.map((charity) => {
                if (item.cause_name == charity.cause_name) {
                  return (
                    <>
                      <CharityInfo
                        contentExternalID={item.content_external_id}
                        causeImage={charity.cause_image}
                        causeName={charity.cause_name}
                        causeCountryName={charity.country_name}
                        causeDescription={charity.cause_description}
                        causeURL={charity.cause_url}
                      />
                    </>
                  );
                }
              })}
            </div>
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.haspooled}
              goldenBuzzer={item.has_golden_buzzered}
            />
            {/* < div className="comments-share" onClick={() => { ShowComments(index) }}> */}
            <div className="comments-share">
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
                contentCreatorId={item.content_creator_id}
              />
              {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
            </div>
            {/* <SharingOnMedia url={item.content_url.signedUrl} text={item.content_description} /> */}
          </Card>
        </>
      );
    } else {
      return (
        <>
          <Card
            className="profile-postcontent-card"
            style={{ marginTop: "1rem" }}
            key={item.content_external_id}
            ref={cardElement}
          >
            <Card.Header className="profile-postcontent-header">
              <div>
                {/* <span className="image-display-pic" style={{ verticalAlign: "top" }}> */}
                <Image
                  className="profile-picture"
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`}
                  roundedCircle
                  style={{ width: "51px", height: "51px" }}
                />

                {/* </span> */}
                {/* {item.user_profile_pic !== null ? <Image className="profile-picture" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                                  <Image src={avatar} alt="profile-photo" className="profile-picture" roundedCircle style={{ width: "51px", height: "51px" }} />} */}
                {/* <Image className="profile-picture" src={avatar} roundedCircle /> */}
                <ul>
                  <li>{item.username}</li>
                  <li>{getPostedTime(item.content_created_on)}</li>
                </ul>
                <DeleteContent
                  item={item}
                  handleContentExternalID={handleContentExternalID}
                  handleContentEditable={handleContentEditable}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Text
                style={
                  contentEditable && editCEID === item.content_external_id
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {item.content_description ? (
                  <>
                    {item.content_description.length <= len && (
                      <Card.Text>
                        <Linkify tagName="span">
                          {item.content_description}
                        </Linkify>
                      </Card.Text>
                    )}
                    {item.content_description.length > len &&
                      showFullDesc.id !== item.content_external_id && (
                        <Card.Text>
                          <Linkify tagName="span">
                            {item.content_description.substring(0, len) + "..."}
                          </Linkify>
                          <span
                            style={{ cursor: "pointer", color: "#2077B1" }}
                            onClick={() =>
                              setShowFullDesc({
                                ...showFullDesc,
                                id: item.content_external_id,
                              })
                            }
                          >
                            {" "}
                            {t("post.seeMore")}
                          </span>
                        </Card.Text>
                      )}
                    {item.content_description.length > len &&
                      showFullDesc.id === item.content_external_id && (
                        <Card.Text>
                          <Linkify tagName="span">
                            {item.content_description}
                          </Linkify>
                          <span
                            style={{ cursor: "pointer", color: "#2077B1" }}
                            onClick={() =>
                              setShowFullDesc({ ...showFullDesc, id: "" })
                            }
                          >
                            {" "}
                            {t("post.seeLess")}
                          </span>
                        </Card.Text>
                      )}
                  </>
                ) : null}
              </Card.Text>
              <div>
                {contentEditable && editCEID === item.content_external_id ? (
                  <React.Fragment>
                    <textarea
                      value={contentDescription}
                      onChange={(e) => setContentDescription(e.target.value)}
                      style={{
                        resize: "none",
                        width: "100%",
                        height: "inherit",
                      }}
                    ></textarea>
                    <button
                      type="button"
                      className="btn-sm btn-outline-primary content-editable-cancel"
                      onClick={() => handleContentEditable(false)}
                    >
                      {t("post.edit.cancel")}
                    </button>
                    <button
                      type="button"
                      className="btn-sm btn-primary content-editable"
                      onClick={() => handleEditToPost(item.content_external_id)}
                    >
                      {t("post.edit.save")}
                    </button>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </Card.Body>

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>
            {/* <br /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "49px",
                padding: "0 1rem",
              }}
            >
              <div>
                <div className="profile-comment-pooled-raised">
                  {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                  <div
                    onClick={() => {
                      handleModal(item.content_external_id);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div className="userImageWrapper">
                      <ul className="hx_flex-avatar-stack">
                        {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                              .slice(0, 4)
                              .map((people_pooled, index) => {
                                return (
                                  <li className="hx_flex-avatar-stack-item">
                                    <img
                                      className="avatar avatar-user"
                                      alt="@jqn"
                                      onError={(ev) =>
                                        (ev.target.src = `${avatar}`)
                                      }
                                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                      width="20"
                                      height="20"
                                    />
                                  </li>
                                );
                              })
                          : ""}
                      </ul>
                    </div>
                    <span className="profile-pooled">
                      {" "}
                      {item.total_people_pooled} {t("post.peoplePooled")}
                    </span>
                  </div>
                  <span style={{ margin: "0 0.5rem" }}> • </span>
                  <img src={pooledCollect} alt="pooledCollect.svg" />

                  <p className="profile-pooled">
                    <span className="profile-user-pooled-value">
                      {" "}
                      {userDetails.country_wallet_currency_sign}
                      {item.total_amount_pooled.toFixed(2)}{" "}
                    </span>
                    {t("post.poolCollected")}
                  </p>
                  {/* <span className="profile-pooled"> Pool collected</span> */}
                </div>
              </div>
              {/* {Contentuserimage()} */}
              <div className="profile-comment-pooled-raised">
                <span className="profile-pooled">
                  {item.totalcomments}{" "}
                  {item.totalcomments > "1"
                    ? t("post.commentPlural")
                    : t("post.commentSingular")}
                </span>
              </div>
            </div>

            <div className="profile-charity">
              <img
                className="profile-charity-logo"
                src={item.cause_image}
                onError={(ev) => (ev.target.src = `${CharityLogo}`)}
              />
              <ul>
                <li className="profile-charityName">{item.cause_name}</li>
                <li className="profile-charityLocation">{item.country_name}</li>
              </ul>
              {charitiesList.map((charity) => {
                if (item.cause_name == charity.cause_name) {
                  return (
                    <>
                      <CharityInfo
                        contentExternalID={item.content_external_id}
                        causeImage={charity.cause_image}
                        causeName={charity.cause_name}
                        causeCountryName={charity.country_name}
                        causeDescription={charity.cause_description}
                        causeURL={charity.cause_url}
                      />
                    </>
                  );
                }
              })}
            </div>
            <EmojiSlider
              causeId={item.cause_external_id}
              contentExternalId={item.content_external_id}
              countryId={userDetails.country_external_id}
              transcationAmt={item.haspooled}
              goldenBuzzer={item.has_golden_buzzered}
            />
            {/* < div className="comments-share" onClick={() => { ShowComments(index) }}> */}
            <div className="comments-share">
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
              />
              {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
            </div>
            {/* <SharingOnMedia url={item.content_url.signedUrl} text={item.content_description} /> */}
          </Card>
        </>
      );
    }
  });

  const showMessage = () => {
    if (loading) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>Please Wait...</div>
      );
    }
    if (error) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>
          Something went wrong. Please try reloading the page or try logging in
          again.
        </div>
      );
    }
    if (!hasMore) {
      return <div style={{ height: "50px", fontWeight: "700" }}></div>;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span className="profile-heading-post">{t("profile.posts")}</span>
        <div style={{ display: "flex" }}>
          <Form.Control
            className="dropdown-btn"
            as="select"
            onChange={(e) => setCharity(e.target.value)}
            value={charity}
            style={{ backgroundColor: "#e5e5e5" }}
          >
            <option value="">{t("profile.filters.charity")}</option>
            {userTaggedCause.map((cause) => {
              return (
                <option
                  value={cause.cause_external_id}
                  key={cause.cause_external_id}
                >
                  {cause.cause_name}
                </option>
              );
            })}
          </Form.Control>
          <Form.Control
            className="dropdown-btn"
            as="select"
            onChange={(e) => setDate(e.target.value)}
            value={date}
            style={{ backgroundColor: "#e5e5e5", marginLeft: "2px" }}
          >
            <option defaultValue>
              {t("profile.filters.duration.placeholder")}
            </option>
            <option value="1">{t("profile.filters.duration.today")}</option>
            <option value="7">{t("profile.filters.duration.thisWeek")}</option>
            <option value="30">
              {t("profile.filters.duration.thisMonth")}
            </option>
            <option value="90">{t("profile.filters.duration.3months")}</option>
          </Form.Control>
        </div>
      </div>
      {/* {showmodal ? <PeoplePooledModal/>:null} */}
      {/* {Modal()} */}
      {activityCards}
      {showMessage()}
      {showmodal ? (
        <PeoplePooledModal contentExternalId={contentExternalId} />
      ) : null}
      {showToast ? (
        <ToastMessage
          message={t("post.delete.deleteSuccessText")}
          image={greenTickMark}
        />
      ) : (
        " "
      )}
    </>
  );
};
export default Post;
