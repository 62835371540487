import { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Image, Card } from "react-bootstrap";

import axiosInstance from "../../axios/axios";
import { BASEURL } from "../../config";

//import components
import ContentSlider from "../Contents/Activity/ContentSlider";
import EmojiSliderApple from "./EmojiSliderApple";
import { findByDisplayValue } from "@testing-library/react";

//import style
import "../../assets/scss/iOS/post.scss";

//import image
import avatar from "../../assets/images/avatar.png";
import iosInfoIcon from "../../assets/svg/iosIcons/ios-info-icon.svg";

const PostApple = (props) => {
  console.log(props);
  const [contents, setContents] = useState("");
  const [showCharityDes, setShowCharityDes] = useState(false);
  const [type, setType] = useState();
  const [causeID, setCauseID] = useState();
  const [contentID, setContentID] = useState();
  const [countryID, setCountryID] = useState();
  const [countryMinAmount, setCountryMinAmount] = useState();
  const [countryCurrency, setCountryCurrency] = useState();

  const location = useLocation();
  const len = 90;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setType(query.get("type"));
    setCauseID(query.get("causeID"));
    setContentID(query.get("contentID"));
    setCountryID(query.get("countryID"));
    setCountryMinAmount(query.get("countryMinAmount"));
    setCountryCurrency(query.get("countryCurrency"));

    console.log(location);
    console.log({ causeID });
    console.log({ contentID });
    console.log({ countryMinAmount });
    console.log({ countryCurrency });

    axiosInstance({
      url: `${BASEURL}/v1/getContentById/${contentID}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        userexternalid: localStorage.getItem("user_external_id"),
      },
      withCredentials: true,
    })
      .then((res) => {
        setContents(res.data.response.data.contents[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  /*Style applied to body element */
  const BodyStyle = () => {
    useEffect(() => {
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  if (!contents) {
    return "Loading...";
  }

  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return <p className="timeline">{month} month ago</p>;
      } else {
        return <p className="timeline">{month} months ago</p>;
      }
    } else if (days) {
      if (days === 1) return <p className="timeline">{days} day ago</p>;
      return <p className="timeline">{days} days ago</p>;
    } else if (hours) {
      if (hours === 1) return <p className="timeline">{hours} hour ago</p>;
      return <p className="timeline">{hours} hours ago</p>;
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return <p className="timeline">{minutes} minute ago</p>;
      return <p className="timeline">{minutes} minutes ago</p>;
    }
  };

  const handleShowCharityDes = () => {
    setShowCharityDes(!showCharityDes);
  };
  let stylesReport = showCharityDes
    ? { display: "block" }
    : { display: "none" };

  return (
    <section id="ios-content-wrapper">
      <Container>
        <Row>
          <Col sm={12} md={10} lg={8} className="mx-auto post-column">
            {/* <Image
              onError={(ev) => (ev.target.src = `${avatar}`)}
              className="profiledashboard-profilepic"
              src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${contents.content_creator_id}.jpg`}
              alt="profile-picture"
              height="56"
              width="56"
              style={{ objectFit: "cover" }}
              roundedCircle
            /> */}
            <div className="post-content-wrapper">
              <div className="image-slider-container">
                <ContentSlider item={contents} />
              </div>
            </div>

            <div className="post-info">
              <div className="post-username-time">
                <span className="post-username">@{contents.username}</span>
                <span style={{ margin: "0 0.5rem" }}>•</span>
                <div
                  style={{
                    marginBottom: "0",
                    fontWeight: "400",
                    display: "inline-block",
                  }}
                >
                  {getPostedTime(contents.content_created_on)}
                </div>
              </div>
              {contents.content_description ? (
                <>
                  {contents.content_description.length <= len && (
                    <p className="post-description">
                      {contents.content_description}
                    </p>
                  )}
                  {/* {contents.content_description.length > len &&
                    <p className="post-description">
                      {contents.content_description.substring(0, len) + "..."}
                      <span
                        style={{ cursor: "pointer", color: "#2077B1" }}
                        onClick={() =>
                          setShowFullDesc({
                            ...showFullDesc,
                            id: contents.content_external_id,
                          })
                        }
                      >
                        {" "}
                          See more
                        </span>
                    </p>
                  }
                  {contents.content_description.length > len &&
                    <p className="post-description">
                      {contents.content_description}
                      <span
                        style={{ cursor: "pointer", color: "#2077B1" }}
                        onClick={() =>
                          setShowFullDesc({ ...showFullDesc, id: "" })
                        }
                      >
                        {" "}
                          See less
                        </span>
                    </p>} */}
                </>
              ) : null}

              {/* <p className="post-description">{contents.content_description}</p> */}
              <div className="post-charity">
                <img src={contents.cause_image} className="post-charity-logo" />
                <div className="post-charity-content">
                  <p className="post-charity-name">{contents.cause_name}</p>
                  <p className="post-charity-location">
                    {contents.country_name}
                  </p>
                </div>
                <img
                  src={iosInfoIcon}
                  alt="info-icon"
                  onClick={handleShowCharityDes}
                />
              </div>
            </div>

            <EmojiSliderApple
              causeID={causeID}
              contentID={contentID}
              countryID={countryID}
              countryMinAmount={countryMinAmount}
              countryCurrency={countryCurrency}
              type={type}
            />
          </Col>
        </Row>
      </Container>
      {showCharityDes && <BodyStyle />}
      {showCharityDes && <div className="modal-backdrop"></div>}
      {showCharityDes && (
        <div
          id="charity-description"
          className="modal in report-modal-container"
          role="dialog"
          style={stylesReport}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header" style={{ textAlign: "center" }}>
                <h4 className="modal-title">{contents.cause_name}</h4>
              </div>
              <div className="modal-body">
                <div>
                  <p>{contents.cause_description}</p>
                </div>
              </div>
              <div className="modal-footer">
                <button onClick={handleShowCharityDes}>OK</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PostApple;
