import { GOOGLE_USER_DATA } from '../../constants/action-types'

const initState = {}
// const initState = [] //in array format store the google responses

const storeGoogleDataReducer = (state = initState, action) => {
    if (action.type === GOOGLE_USER_DATA) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default storeGoogleDataReducer