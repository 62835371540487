import React, { useEffect } from "react";
import x from "../assets/svg/x.svg";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const StorePopup = ({ storePopupShow, handleClose }) => {
  //   const [storePopup, storePopupShow] = useState(false);
  const history = useHistory();

  const BodyStyle = () => {
    useEffect(() => {
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const bannerShow = () => {
    if (navigator.userAgent.match(/Android/i)) {
      return (
        <a
          href="https://play.google.com/store/apps/details?id=com.gopoolit.gopoolit"
          className="btn-sm"
          style={{ background: "#2077b1", color: "white" }}
        >
          Download the App
        </a>
      );
    } else if (navigator.userAgent.match(/iPhone/i)) {
      return (
        <a
          href="#"
          className="btn-sm"
          style={{ background: "#2077b1", color: "white" }}
        >
          App coming soon
        </a>
      );
    } else return;
  };

  const selectStore = () => {
    if (navigator.userAgent.match(/Android/i)) {
      return (
        <div className="modal-body">
          <p>
            Please download our app from the Play Store or Login to continue
          </p>
        </div>
      );
    } else if (navigator.userAgent.match(/iPhone/i)) {
      return (
        <div className="modal-body">
          <p>Please download our app from the App Store or login to continue</p>
        </div>
      );
    } else return;
  };

  let modalStorePopup = storePopupShow
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };

  return (
    <React.Fragment>
      {storePopupShow && <BodyStyle />}
      {storePopupShow && <div className="modal-backdrop"></div>}
      <div
        id="insufficient-balance-modal"
        className="modal in report-modal-container"
        role="dialog"
        style={modalStorePopup}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ textAlign: "left" }}>
                Want a better experience?
              </h4>
              <button className="close" onClick={handleClose}>
                <img src={x} alt="close-button" />
              </button>
            </div>
            {selectStore()}
            {/* <div className="modal-body">
              <p>
                Want a better experience? Download our App from Play Store now
              </p>
            </div> */}
            <div className="modal-footer">
              <Button
                className=""
                size="sm"
                variant="outline-primary"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className=""
                size="sm"
                variant="info"
                type="button"
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
              {bannerShow()}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StorePopup;
