import { USER_AUTHORIZED } from '../constants/action-types'

const initState = {
    isAuthorized: false
}

const UserAuthorizedReducer = (state = initState, action) => {
    if (action.type === USER_AUTHORIZED) {
        return {
            ...state,
            isAuthorized: action.payload
        }
    }
    return state
}
export default UserAuthorizedReducer