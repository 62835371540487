import { FACEBOOK_USER_DATA } from '../../constants/action-types'

const initState = {}

const storeFBDataReducer = ( state = initState, action ) => {
    if (action.type === FACEBOOK_USER_DATA) {
        return {
            ...state,
            ...action.payload
        }
    } 
    return state
}   

export default storeFBDataReducer