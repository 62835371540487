import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ActivityPage from "../Contents/Activity/ActivityPage";
import ContentBar from "./ContentBar";

import "../../assets/scss/Contents/homePage.scss";
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import firebase from "../Notifications/firebase";
import { AddNotificationFcmId } from "../../api/NotificationApi";
// import { GetNotification } from "../../api/NotificationApi";
import UnauthorizedUserActivityPage from "./Activity/UnauthorizedUserActivityPage";
import CookieConsent from "../CookieConsent/CookieConsent";
import { loadCharityNames } from "../../actions/CharityList";
import SearchPost from "../SearchFilter/SearchPost";
import { searchInfo } from "../../actions/searchComponents/searchInfo";

const HomePage = (props) => {
  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );


  const dispatch = useDispatch();
  // console.log("isAuthorized", isAuthorized)

  const searchInfoStatus = useSelector(
    (state) => state.searchInfo.searchInfoStatus
  );


  useEffect(() => {
    dispatch(loadCharityNames());
  }, []);

  const [Firebasetoken, setFirebaseToken] = useState("");
  useEffect(() => {
    const turnOnNotification = async () => {
      // const uid = firebase.auth().currentUser.uid;
      if (isAuthorized) {
        const messaging = firebase.messaging();
        try {
          await messaging.requestPermission();
          const token = await messaging.getToken();
          setFirebaseToken(token);
          // send to server
        } catch (err) {
          if (err.code === "messaging/token-unsubscribe-failed")
            turnOnNotification();
        }
      }
    };
    turnOnNotification();
  }, []);
  useEffect(() => {
    async function fetchData() {
      if (isAuthorized) {
        let NotificationResponse = await AddNotificationFcmId(Firebasetoken);
        // console.log("NotificationResponse", NotificationResponse);
      }
    }
    fetchData();
  }, [isAuthorized, Firebasetoken]);

  return (
    <>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <CookieConsent />
        <div className="header" id="header">
          <ContentNavBar />
          {searchInfoStatus === false ? <ContentBar /> : null}
        </div>
        {isAuthorized ? (
          searchInfoStatus === true ? (
            props.history.push("/search/posts")
          ) : (
              <ActivityPage />
            )
        ) : (
            <UnauthorizedUserActivityPage propsDetails={props} />
          )}
      </div>
    </>
  );
};

export default HomePage;
