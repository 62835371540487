import React, { useState, useRef, createRef, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "../../assets/scss/Contents/contentBar.scss";

//API/action import
import hashTagAPI from "../../api/ActivityPage/hashTagAPI";
import { hashTagInfo } from "../../actions/hashtags/hashTagInfo";
import { storeHashTags } from "../../actions/hashtags/storeHashTags";

const ContentBar = () => {
  const [hashTag, setHashTag] = useState([]);

  const hashTagHighlightId = useSelector(
    (state) => state.hashTagInfo.hashTagHighlightId
  );

  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  const tabRef = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  // Highlighting the hashtag based on the content displayed (on page reload)
  useEffect(() => {
    if (hashTagHighlightId) {
      setTimeout(() => {
        const current = tabRef.current[hashTagHighlightId].current;

        let siblings = [];
        let sibling = current.parentNode.firstChild;
        while (sibling) {
          if (sibling.nodeType === 1 && sibling !== current) {
            siblings.push(sibling);
          }
          sibling = sibling.nextSibling;
        }
        siblings.forEach((sibling) => {
          sibling.style.color = "black";
        });
        current.style.color = "#2077B1";
        // tabRef.current[hashTagHighlightId].current.style.color = "#2077B1";
      }, 1000);
    } else {
      const current = tabRef.current[0].current;

      let siblings = [];
      let sibling = current.parentNode.firstChild;
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== current) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      siblings.forEach((sibling) => {
        sibling.style.color = "black";
      });
      current.style.color = "#2077B1";
      // tabRef.current[hashTagHighlightId].current.style.color = "#000";
      // tabRef.current[0].current.style.color = "#2077B1";
    }
  }, [hashTagHighlightId]);

  //-----//

  const dispatch = useDispatch();

  useEffect(() => {
    const hashTagFunction = async () => {
      let hashTag = await hashTagAPI();
      let data = await hashTag.response.data;

      setHashTag(data);
      dispatch(storeHashTags(data));
    };

    if (hashTag.length === 0 && isAuthorized) {
      hashTagFunction();
    }
  }, []);

  // Highlighting the tab that is clicked
  const changebtnColor = (buttonId) => {
    const current = tabRef.current[buttonId].current;
    const currentElem = hashTag[buttonId - 1];

    if (buttonId === 0) {
      dispatch(hashTagInfo(false, null, 0));
    } else {
      dispatch(hashTagInfo(true, currentElem.hashtag_external_id, buttonId));
    }

    // let siblings = [];
    // let sibling = current.parentNode.firstChild;

    // while (sibling) {
    //   if (sibling.nodeType === 1 && sibling !== current) {
    //     siblings.push(sibling);
    //   }
    //   sibling = sibling.nextSibling;
    // }

    // siblings.forEach((sibling) => {
    //   sibling.style.color = "black";
    // });

    // current.style.color = "#2077B1";
  };

  if (!hashTag)
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );

  const buttonContent = hashTag.map((hashtag, index) => {
    const hashTagWords = {};
    hashTagWords.text = hashtag.hashtag_name;

    hashTagWords.index = index + 1;
    return hashTagWords;
  });

  const allTab = {
    text: "All",
    index: 0,
  };

  const allButtonContent = [allTab, ...buttonContent];
  // const allButtonContent = [...buttonContent];

  let buttonDisplay = allButtonContent.map((button, index) => {
    return (
      <Button
        style={{ backgroundColor: "rgba(226, 226, 226, 0.5)", color: "black" }}
        key={index + 1}
        className="content-button"
        onClick={() => changebtnColor(button.index)}
        ref={tabRef.current[index]}
      >
        {" "}
        {button.text}
      </Button>
    );
  });

  return <div className="content-nav">{buttonDisplay}</div>;
};

export default ContentBar;
