import React, { Component } from "react";

//import image
import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";
import "../../../assets/scss/showBtn.scss";
import ReportComment from "./ReportComment";
// localhost:3001
class ShowBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showdeleteOnhover: -1,
      isMouseInside: false,
      showDropDown: false,
      showReportDropdown: false,
      showReportModal: false,
    };
  }

  mouseEnter = () => {
    // console.log("mouseenter")
    this.setState({
      // showdeleteOnhover: index,
      isMouseInside: true,
    });
  };
  mouseLeave = () => {
    // console.log("mouseleave")
    this.setState({
      isMouseInside: false,
      //showdeleteOnhover: index
    });
  };

  handleClick = (commentExternalId) => {
    // console.log("click");
    this.props.deleteComment(commentExternalId);
    this.setState({
      showDropDown: false
    })
  };
  handleEdit = () => {
    this.props.editComment(this.props.commentMsg, this.props.indexValue);
    this.setState({
      showDropDown: false
    })
  }

  handleUsers = () => {
    if (
      this.props.commentCreatorId === localStorage.getItem("user_external_id")
    ) {
      return this.setState((previousState) => ({
        showDropDown: !previousState.showDropDown,
      }));
    } else {
      return this.setState((previousState) => ({
        showReportDropdown: !previousState.showReportDropdown,
      }));
    }
  };

  handleReportModal = (value) => {
    this.setState({
      showReportModal: value,
    });
    this.setState({
      showReportDropdown: false,
    });
  };

  // handleReplyCommment = () => {
  //   this.props.replyComments(this.props.commentExternalId);
  // };

  render() {
    return (
      <>
        <div
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          className="comment-options"
        >
          <span
            className="comment-more-options"
            onClick={() => this.handleUsers()}
          >
            &#8942;
          </span>
          {/* {this.state.isMouseInside ? (
            <span
              className="comment-more-options"
              onClick={() => this.handleUsers()}
            >
              &#8942;
            </span>
          ) : (
            ""
          )} */}
        </div>
        {this.state.showDropDown ? (
          <div className="comment-dropdown">
            <button
              className="comment-dropdown-button"
              onClick={() => this.handleEdit(this.props.commentMsg, this.props.indexValue)
              }
            >
              Edit
            </button>
            <button
              className="comment-dropdown-button"
              onClick={() => this.handleClick(this.props.commentExternalId)}
            >
              Delete
            </button>

          </div>
        ) : (
          ""
        )}
        {this.state.showReportDropdown ? (
          <div className="comment-dropdown">
            <button
              className="comment-dropdown-button"
              onClick={() => this.handleReportModal(true)}
            >
              Report comment
            </button>
            {/* <button
              className="comment-dropdown-button"
              onClick={this.handleReplyCommment}
            >
              Reply
            </button> */}
          </div>
        ) : (
          ""
        )}
        {this.state.showReportModal ? (
          <ReportComment
            showReportModal={this.state.showReportModal}
            handleReportModal={this.handleReportModal}
            commentExternalId={this.props.commentExternalId}
            commentCreatorId={this.props.commentCreatorId}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
export default ShowBtn;
