import React from 'react'
import { Container, Spinner } from 'react-bootstrap';
import '../assets/scss/Loader/loader.scss';


const Loader = () => {
    return (
        <section className="loading">
        <Container>
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <div className="sr-only">Loading...</div>
                </Spinner>
            </div>
        </Container>
        </section>
    )
}

export default Loader
