import axios from "axios";
import {
    GET_SEARCH_POST,
    GET_SEARCH_USER,
    GET_SEARCH_CHARITY,
} from "../../constants/action-types";
import { BASEURL } from "../../config";

export const searchPosts = (searchValue) => (dispatch) => {
    return axios({
        url: `${BASEURL}/v1/search/posts`,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
            userexternalid: `${localStorage.getItem("user_external_id")}`,
        },
        data: {
            searchParam: searchValue,
        },
    })
        .then((response) =>
            dispatch({
                type: GET_SEARCH_POST,
                payload: response.data.response,
            })
        )
        .catch((err) => err);
};

export const searchUsers = (searchValue) => (dispatch) => {
    return axios({
        url: `${BASEURL}/v1/search/users`,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
            userexternalid: `${localStorage.getItem("user_external_id")}`,
        },
        data: {
            searchParam: searchValue,
        },
    })
        .then((response) =>
            dispatch({
                type: GET_SEARCH_USER,
                payload: response.data.response,
            })
        )
        .catch((err) => err);
};

export const searchCharity = (searchValue) => (dispatch) => {
    return axios({
        url: `${BASEURL}/v1/search/charity`,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
            userexternalid: `${localStorage.getItem("user_external_id")}`,
        },
        data: {
            searchParam: searchValue,
        },
    })
        .then((response) =>
            dispatch({
                type: GET_SEARCH_CHARITY,
                payload: response.data.response,
            })
        )
        .catch((err) => err);
};
