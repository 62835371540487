import { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import { PoolApi, GoldenBuzzerApi } from "../../api/TransactionAPI";

import GoldenHeart from "../../assets/svg/GoldenHeart.svg";
import "../../assets/scss/iOS/emojiSliderApple.scss";

const EmojiSliderApple = ({
  causeID,
  contentID,
  countryID,
  countryMinAmount,
  countryCurrency,
  type,
}) => {
  const [value, setValue] = useState(5);
  const [showPoolPopup, setShowPoolPopup] = useState(false);
  const [showGoldenBuzzerPopup, setShowGoldenBuzzerPopup] = useState(false);
  //Things required to make the API call

  useEffect(() => {
    if (type === "pool") {
      setShowPoolPopup(true);
      setShowGoldenBuzzerPopup(false);
    } else if (type === "goldenBuzzer") {
      setShowGoldenBuzzerPopup(true);
      setShowPoolPopup(false);
    }
  }, []);

  const classList = (...classes) => {
    return classes.filter((item) => !!item).join(" ");
  };

  const BodyStyle = () => {
    useEffect(() => {
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const SuccessButton = () => {
    useEffect(() => {
      document.getElementById("success-button").className =
        "success-pool-button-ios";
      return () => {
        document.getElementById("success-button").className = " ";
      };
    }, []);

    return <></>;
  };

  const genSlideStyle = (value) => {
    return {
      point: {
        left:
          value === 1
            ? `calc(10% - ${5 + 0 * value}px)`
            : `calc(${value * 10}% - ${5 + 0 * value}px)`,
      },
      range: {
        // width: value === 1 ? `0%` : `${value * 10}%`,
        width: `${value * 10}%`,
      },
    };
  };

  const slideStyle = genSlideStyle(value);

  const handleClose = () => {
    setShowGoldenBuzzerPopup(false);
  };

  const handleShowPool = () => {
    setShowPoolPopup(true);
  };

  const handlePayPool = async () => {
    let obj = {
      type: "pool",
      cause_id: causeID,
      content_id: contentID,
      country_id: countryID,
      scale: value,
    };

    let PoolResponse = await PoolApi(obj);
    console.log({ PoolResponse });
  };

  const handleShowBuzzer = () => {
    setShowGoldenBuzzerPopup(true);
  };

  const handlePayBuzzer = async () => {
    let obj = {
      type: "goldenBuzzer",
      cause_id: causeID,
      content_id: contentID,
      country_id: countryID,
    };

    let GBResponse = await GoldenBuzzerApi(obj);
    console.log({ GBResponse });
  };

  let goldenBuzzerPopup = showGoldenBuzzerPopup
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };

  const buttonStyles = {
    width: "50%",
    border: "none",
    backgroundColor: "#fff",
    color: "#2077B1",
    borderRadius: "0px 0px 14px 14px",
  };
  const buttonCancelStyles = {
    width: "50%",
    border: "none",
    backgroundColor: "#fff",
    color: "#2077B1",
    borderRadius: "0px 0px 0px 14px",
    borderRight: "1px solid rgba(60, 60, 67, 0.36)",
  };

  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        <button
          type="button"
          className="emoji-slider-apple-pool"
          onClick={handleShowPool}
        >
          Pool
        </button>
        <img
          src={GoldenHeart}
          alt="golden-heart"
          onClick={handleShowBuzzer}
          className="emoji-slider-apple-golden-buzzer"
        />

        <div id="success-button">Thank you for pooling</div>
      </div>

      {showPoolPopup ? <BodyStyle /> : ""}
      {showPoolPopup ? <SuccessButton /> : ""}
      {showPoolPopup ? (
        <div
          className="modal-backdrop"
          onClick={() => setShowPoolPopup(false)}
        ></div>
      ) : (
        ""
      )}

      {showPoolPopup ? (
        <div className="emoji-slider-apple-pool-popup">
          <div>
            <div className="empty-div"></div>
            <div className="range">
              <span className="bullet" />
              <span className="bullet-1" />
              <span className="bullet-2" />
              <span className="bullet-3" />
              <span className="bullet-4" />
              <span className="bullet-5" />
              <span className="bullet-6" />
              <span className="bullet-7" />
              <span className="bullet-8" />
              <span className="bullet-9" />
              <span
                className="emoji-slider-apple-range-value"
                style={slideStyle.range}
              />
              <span
                className={classList(
                  "circle",
                  // (value < 1) && 'slider1',
                  value >= 1 && "slider1",
                  value >= 2 && "slider2",
                  value >= 3 && "slider3",
                  value >= 4 && "slider4",
                  value >= 5 && "slider5",
                  value >= 6 && "slider6",
                  value >= 7 && "slider7",
                  value >= 8 && "slider8",
                  value >= 9 && "slider9",
                  value >= 10 && "slider10"
                )}
                style={slideStyle.point}
              />
              <input
                className="range-slide"
                name="range"
                type="range"
                min="1"
                max="10"
                value={value}
                step="1"
                onChange={(e) => setValue(e.target.value)}
              />

              <span className="scaleEmojiSlider">
                <span className="slider-scale">1</span>
                <span className="slider-scale">10</span>
              </span>
            </div>
          </div>

          <div className="slide-amount">
            <span className="slide-currency-symbol">{countryCurrency}</span>
            {/* {value * country_min_amount} */}
            {(value * countryMinAmount) % 1 === 0
              ? value * countryMinAmount
              : (value * countryMinAmount).toFixed(2)}
          </div>

          <button
            type="button"
            className="emoji-slider-apple-popup-button"
            onClick={handlePayPool}
          >
            Pool
          </button>
        </div>
      ) : (
        ""
      )}

      {showGoldenBuzzerPopup && <BodyStyle />}
      {showGoldenBuzzerPopup && <div className="modal-backdrop"></div>}

      <div
        className="modal in report-modal-container"
        role="dialog"
        style={goldenBuzzerPopup}
        // onClick={() => setShowGoldenBuzzerPopup(false)}
      >
        <div
          className="modal-dialog"
          style={{
            width: "270px",
            margin: "auto",
            borderRadius: "14px",
            minHeight: "100%",
          }}
        >
          <div className="modal-content">
            <div
              className="modal-body"
              style={{ borderBottom: "1px solid rgba(60, 60, 67, 0.36)" }}
            >
              <h4
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "17px",
                  color: "#000",
                }}
              >
                Are you sure?
              </h4>
              <p
                style={{
                  color: "#000",
                  fontSize: "13px",
                }}
              >
                You want to give Golden Buzzer to this post?
              </p>
            </div>
            <div>
              <Button
                size="sm"
                type="button"
                style={buttonCancelStyles}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                type="button"
                style={buttonStyles}
                onClick={handlePayBuzzer}
              >
                Yes, I'm sure
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmojiSliderApple;
