import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    zIndex: "1",
    width: "100%",
    boxShadow: "0 -1px 1px rgb(0 0 0 / 25%)",
    "& a": {
      color: "#333",

      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "#fff",
    padding: "0 20px",
  },
  tabComponent: {
    backgroundColor: "#f2f2f2",
    minHeight: "100vh",
  },
  tabs: {
    width: "50%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tab: {
    "&:focus": {
      outline: "none",
    },
  },
}));

const SearchUI = ({ activeTabValue }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(parseInt(activeTabValue));

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
          TabIndicatorProps={{ style: { background: "#2077b1" } }}
        >
          <Link to="/search/posts">
            <Tab
              label={t("searchUI.tabs.posts")}
              {...a11yProps(0)}
              style={{}}
              className={classes.tab}
            />
          </Link>

          <Link to="/search/people">
            <Tab
              label={t("searchUI.tabs.people")}
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Link>

          <Link to="/search/charity">
            <Tab
              label={t("searchUI.tabs.charity")}
              {...a11yProps(2)}
              className={classes.tab}
            />
          </Link>
        </Tabs>
      </AppBar>
    </div>
  );
};

export default SearchUI;
