import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input"; // otp input library
// import { Otp } from "react-otp-timer";

//components imports
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import LoginNavbar from "../LandingLearnMore/LoginNavbar";

//actions imports
import { sendOTP, nexmoLogin } from "../../actions/login";

//css imports
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "../../assets/scss/Login/nexmoOTP.scss";
import "../../assets/scss/Login/loginPage.scss";
import Loader from "../Loader";

import { useTranslation } from "react-i18next";

const NexmoOTP = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // const [disabled, setDisabled] = useState(true);
  const [otp, setOtp] = useState("");
  const [disableContinuebtn, setContinueBtn] = useState(true);
  const [message, setMessage] = useState("");
  // const [signedInWithNexmo, setSignedInWithNexmo] = useState(false);
  // const [nexmoVerificationMessage, setnexmoVerificationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const phone = useSelector((state) => state.nexmoPhoneNumber.phone);
  // const otpSent = useSelector(state => state.nexmoOTPsentResponse.response.message);
  //const verified = useSelector((state) => state.nexmoVerificationResponse);
  const nexmoverificationResponse = useSelector(
    (state) => state.nexmoVerificationResponse
  );
  const nexmoOTPsentResponse = useSelector(
    (state) => state.nexmoOTPsentResponse.response
  );

  const [concurrentMessage, setConcurrentMessage] = useState(false);

  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
  const [errormsg, setErrorMsg] = useState("");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    setIsLoading(false);
  }, [nexmoverificationResponse]);

  useEffect(() => {
    // console.log(nexmoOTPsentResponse);
    if (nexmoOTPsentResponse) {
      if (nexmoOTPsentResponse.data.status == "10") {
        setConcurrentMessage(true);
      }
    }
  }, [nexmoOTPsentResponse]);

  const handleSubmit = async () => {
    await dispatch(nexmoLogin(phone, otp));
    setTimeout(() => {
      let data = `${localStorage.getItem("error")}`;
      if (data == 500) {
        setErrorMsg(`${t("login.verify.incorrectOTPMessage")}`);
      }
    }, 3000);

    setIsLoading(true);

    // if (nexmoverificationResponse.statusCode == 201) {
    //   setSignedInWithNexmo(true);
    //   setnexmoVerificationMessage("");
    // }
    // if (nexmoverificationResponse.status == '16') {
    //   return setIsLoading(false);
    // }
  };
  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      setOtp(e);
      setMessage("");
    } else {
      setMessage(`${t("login.numberFieldPlaceholder")}`);
    }
    let value = 6;
    let data = e;
    let otpvalue = parseInt(data.length);

    if (otpvalue == value) {
      setContinueBtn(false);
    } else {
      setContinueBtn(true);
    }
  };

  const handleResentOTP = (e) => {
    e.preventDefault();
    setMinutes(15);
    setSeconds(0);
    // setDisabled(!disabled)
    dispatch(sendOTP(phone));
  };

  let style = {
    otpTimer: {
      margin: "10px",
      color: "#575757",
      fontSize: "19px",
    },
    resendBtn: {
      border: "none",
      color: "#007bff",
      display: "none",
    },
  };

  // const showMesageToUser = () => {
  //   if(nexmoOTPsentResponse.codeResponse)
  // }
  const errorInput = nexmoverificationResponse.status ? "error-otp-input" : "";

  if (!nexmoOTPsentResponse) {
    return <Loader />;
  }

  const handleClearUserDetail = () => {
    window.location.reload(false);
    localStorage.clear();
  };

  return (
    <section className="login-page">
      <LoginNavbar />
      <Container className="login-page-container">
        <Row>
          <Col sm={12} className="login-page-right-content">
            <Card>
              <Card.Header className="h5">
                <p className="login-page-gopoolit">{t("login.verify.title")}</p>
                <Link
                  to="/login"
                  className="login-close"
                  onClick={handleClearUserDetail}
                >
                  &times;
                </Link>
              </Card.Header>
              <Card.Body>
                <p
                  style={{ textAlign: "left", marginBottom: "1rem" }}
                  className="nexmo-otp-maskphone"
                >
                  {t("login.verify.subTitle")} {phone}
                </p>
                <div className="nexmo-otp-screen">
                  <div className={`${errorInput}`}>
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>&nbsp;&nbsp;</span>}
                      required
                    />
                    {concurrentMessage && (
                      <p tyle={{ textAlign: "left" }} className="error-message">
                        {/* {nexmoOTPsentResponse.data.message} */}
                        {t("login.verify.concurrentErrMessage")}
                      </p>
                    )}
                    {/* {nexmoOTPsentResponse ? <p>its true</p> : <p>i m false</p>} */}
                    {/* {nexmoOTPsentResponse.response.codeResponse.hasOwnProperty('result') ? "" : <p style={{ textAlign: "left" }} className="error-message">{nexmoOTPsentResponse.response.codeResponse.message}</p>} */}
                    {/* {(nexmoOTPsentResponse.response.data.message) ? <p style={{ textAlign: "left" }} className="error-message">{nexmoOTPsentResponse.response.data.message}</p> : ""} */}
                    {/* {(nexmoOTPsentResponse.response.codeResponse.result === "OK") ? "" : <p style={{ textAlign: "left" }} className="error-message">{nexmoOTPsentResponse.response.codeResponse.message}</p>} */}
                    {/* {(nexmoOTPsentResponse.response.codeResponse.status === "10") ? <p style={{ textAlign: "left" }} className="error-message">{nexmoOTPsentResponse.response.codeResponse.message}</p> : " "} */}
                    {nexmoverificationResponse.status ? (
                      <p
                        style={{ textAlign: "left" }}
                        className="error-message"
                      >
                        {nexmoverificationResponse.message}
                      </p>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="nexmo-otp-timer">
                    <span style={{ display: "flex", marginTop: "15px" }}>
                      {minutes === 0 && seconds === 0 ? (
                        <a style={{ marginTop: "10px" }}>
                          <span
                            className="nexmo-resend-otp-text"
                            onClick={handleResentOTP}
                            style={{
                              marginTop: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            {t("login.verify.resendOTP")}
                          </span>
                        </a>
                      ) : (
                        <span style={{ marginTop: "10px", color: "#3B3B3B" }}>
                          <a
                            href=""
                            className="disabled"
                            tabIndex="-1"
                            style={{
                              textDecoration: "underline",
                              fontWeight: "500",
                              color: "#bababa",
                            }}
                          >
                            {t("login.verify.resendOTP")}
                          </a>
                          &nbsp; in &nbsp;
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      )}
                    </span>
                  </div>
                  <br />
                  <span style={{ color: "red" }}>{errormsg}</span>
                  <br />
                  <br />

                  <Button
                    className="blue-buttonActive nexmo-otp-continue-btn"
                    variant="secondary"
                    disabled={disableContinuebtn}
                    onClick={handleSubmit}
                  >
                    {t("login.verify.continue")}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* {signedInWithNexmo ? (
          <Redirect to={{ pathname: "/profile-setup" }} />
        ) : (
            ""
          )} */}
        {nexmoverificationResponse.response ? (
          <Redirect to="/profile-setup" />
        ) : (
          ""
        )}
      </Container>
      <LeanmoreFooter />
    </section>
  );
};

export default NexmoOTP;
