import { GET_FOLLOWERS_COUNT } from '../../constants/action-types'

const initState = {}

const GetFollowersCountReducer = (state = initState, action) => {
    if (action.type === GET_FOLLOWERS_COUNT) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state;
}
export default GetFollowersCountReducer;