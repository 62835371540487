import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Card, Button, Container, Form, InputGroup } from "react-bootstrap";

//component imports
import LoginNavbar from "../../LandingLearnMore/LoginNavbar";
import ToastMessage from "../../ToastMessage";
import Loader from "../../Loader";

//action/api imports
import {
  storeUserDetails,
  validateUserName,
} from "../../../actions/storingUserData";
import { CreateNewUser } from "../../../api/CreateNewUser";
import { userAuthorized } from "../../../actions/isAuthorized";

//CSS imports
import "../../../assets/scss/Login/profileSetup.scss";

//image imports
import correctPassword from "../../../assets/svg/correct-password.svg";
import mismatchPassword from "../../../assets/svg/mismatch-password.svg";
import errorButton from "../../../assets/svg/errorButton.svg";
import LeanmoreFooter from "../../LandingLearnMore/LeanmoreFooter";

import { useTranslation } from "react-i18next";

const passwordChecks = {
  lowercase: "",
  uppercase: "",
  number: "",
  special: "",
  notUsername: "",
  eightChars: "",
};

const ProfilePassword = () => {
  const profilesetupUserData = useSelector((state) => state.userProfile);
  const googleResponse = useSelector((state) => state.googleResponse.response);
  const nexmoPhoneNumber = useSelector((state) => state.nexmoPhoneNumber.phone);
  const FBResponse = useSelector((state) => state.FbResponseData.response);
  // const apple = useSelector((state) => state.AppleUserData);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [ReTypedPassword, setReTypedPassword] = useState("");
  const [showPasswordIcon, setPasswordIcon] = useState(false);
  const [showReenterPasswordIcon, setReenterPasswordIcon] = useState(false);
  const [homePage, setHomePage] = useState(false);
  const [message, setMessage] = useState("");
  const [disablebtn, setdisablebtn] = useState(false);
  const [passwordMessage, setpasswordMessage] = useState("");
  const [passwordMatchedmessage, setPasswordMatchedMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  // const [passwordRuleMsg, setPasswordRuleMsg] = useState("Password should be atleast minimum 8 characters,one Lower case,one Upper case,one Number,One Special Character and not same as UserName");
  const [passwordRuleMsg, setPasswordRuleMsg] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [pwdSuccessMsg, setPwdSuccessMsg] = useState("");
  const [togglePasswordOptions, setTogglePasswordOptions] = useState(false);
  const [showPasswordOptions, setShowPasswordOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [validateUsername, setValidateUserName] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [isCorrectPassword, setIsCorrectPassword] = useState(false);
  const [newUserDetails, setNewUserDetails] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  const hidePassword = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L17 17"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const showPassword = (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const myFunction = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      setPasswordIcon(true);
    } else {
      x.type = "password";
      setPasswordIcon(false);
      setReenterPasswordIcon(false);
    }
  };

  const reEnterPassword = () => {
    var x = document.getElementById("ReEnterPasswordInput");
    if (x.type === "password") {
      x.type = "text";
      setReenterPasswordIcon(true);
    } else {
      x.type = "password";
      setReenterPasswordIcon(false);
    }
  };
  const passwordValidate = () => {
    if (UserName.length > 0 && Password.length > 0) {
      var lower = /(?=.*[a-z])/;
      var upper = /(?=.*[A-Z])/;
      var value = /(?=.*\d)/;
      var spclchar = /(?=.*[!@#$%^_&*])/;
      if (Password.length < 8) {
        inputRef.current.focus();
        setPasswordValidation("8 characters");
        setPasswordRuleMsg("");
        // setPwdSuccessMsg("");
        // setValidatePassword(false)
        setIsCorrectPassword(false);
        return false;
      } else if (Password == UserName) {
        setPasswordValidation("UserName");
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setValidatePassword(false)
        setIsCorrectPassword(false);

        // setPwdSuccessMsg("");
        return false;
      } else if (!lower.test(Password)) {
        setPasswordValidation("one LowerCase");
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setValidatePassword(false)
        setIsCorrectPassword(false);

        // setPwdSuccessMsg("");
        return false;
      } else if (!upper.test(Password)) {
        setPasswordValidation("one UpperCase");
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setValidatePassword(false)
        setIsCorrectPassword(false);

        // setPwdSuccessMsg("");
        return false;
      } else if (!value.test(Password)) {
        setPasswordValidation("one Number");
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setValidatePassword(false)
        setIsCorrectPassword(false);

        // setPwdSuccessMsg("");
        return false;
      } else if (!spclchar.test(Password)) {
        setPasswordValidation("special character");
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setValidatePassword(false)
        // setPwdSuccessMsg("");
        setIsCorrectPassword(false);
        return false;
      } else {
        setPasswordValidation("correctPassword");
        setPwdSuccessMsg("Password is strong");
        setPasswordRuleMsg("");
        // setValidatePassword(false)
        setIsCorrectPassword(true);
        return true;
      }
    }
  };

  const handleUserName = (e) => {
    e.preventDefault();
    if (Password !== e.target.value) {
      passwordChecks.notUsername = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.username")}
        </li>
      );
    } else {
      passwordChecks.notUsername = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.username")}
        </li>
      );
    }
    setUserName(e.target.value);
  };

  const handlePwdKeyUp = () => {
    setShowPasswordOptions(true);
    passwordValidate();
    //   let lower = /(?=.*[a-z])/;
    //   let upper = /(?=.*[A-Z])/;
    //   let numbers = /(?=.*\d)/;
    //   let spclchar = /(?=.*[!@#$%^&*])/;

    const lower = /[a-z]/g;
    const spclchar = /([@!#$%^&_*])/g;
    const numbers = /[0-9]/g;
    const upper = /[A-Z]/g;

    //validate lowerCase
    if (inputRef.current.defaultValue.match(lower)) {
      passwordChecks.lowercase = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.lowercase")}
        </li>
      );
    } else {
      passwordChecks.lowercase = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.lowercase")}
        </li>
      );
    }

    //validate Uppercase
    if (inputRef.current.defaultValue.match(upper)) {
      passwordChecks.uppercase = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.uppercase")}
        </li>
      );
    } else {
      passwordChecks.uppercase = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.uppercase")}
        </li>
      );
    }

    // Validate numbers
    if (inputRef.current.defaultValue.match(numbers)) {
      passwordChecks.number = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.number")}
        </li>
      );
    } else {
      passwordChecks.number = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.number")}
        </li>
      );
    }

    // Validate special character
    if (inputRef.current.defaultValue.match(spclchar)) {
      passwordChecks.special = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.specialChar")}
        </li>
      );
    } else {
      passwordChecks.special = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.specialChar")}
        </li>
      );
    }

    //Validate if username is same as password
    if (Password !== UserName) {
      passwordChecks.notUsername = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.username")}
        </li>
      );
    } else {
      passwordChecks.notUsername = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.username")}
        </li>
      );
    }

    //Validate password length
    if (Password.length >= 8) {
      passwordChecks.eightChars = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("profilePassword.formData.passwordHints.characters")}
        </li>
      );
    } else {
      passwordChecks.eightChars = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("profilePassword.formData.passwordHints.characters")}
        </li>
      );
    }

    setTogglePasswordOptions(!togglePasswordOptions); //To render the updated passwordChecks values
  };

  useEffect(() => {
    if (Password === ReTypedPassword && Password.length > 0) {
      setValidatePassword(true);
      // setdisablebtn(false);
      setpasswordMessage("");
      setPasswordMatchedMessage(
        `${t("profilePassword.formData.validations.passwordMatch")}`
      );
    } else if (ReTypedPassword.length > 0) {
      // setdisablebtn(true);
      setValidatePassword(false);
      setpasswordMessage(
        `${t("profilePassword.formData.validations.passwordNotMatch")}`
      );
      setPasswordMatchedMessage("");
    }
  }, [Password, ReTypedPassword]);

  const handleSubmit = async (e, userName, Password) => {
    e.preventDefault();
    setIsLoading(true);
    if (passwordValidate() === true) {
      let obj = profilesetupUserData;
      // dispatch(storeUserDetails(userName, Password, obj, nexmoPhoneNumber, FBResponse));
      //   console.log("submit");
      let response = await CreateNewUser(
        userName,
        Password,
        obj,
        googleResponse,
        nexmoPhoneNumber,
        FBResponse
        // apple
      );
      setNewUserDetails(response.data);
      // console.log(response);
      if (response.statusCode === 201) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem(
          "user_external_id",
          response.data.user_external_id
        );
        // dispatch(storeUserDetails(response.data.userDetails));
        dispatch(storeUserDetails(response.data.user_profile));
        setIsLoading(false);
        setHomePage(true);
        dispatch(userAuthorized(true));
      } else {
        setError(true);
        setIsLoading(false);
        setTimeout(() => {
          return history.push("/"), localStorage.clear();
        }, 5000);
      }
      // console.log(response)
    }
  };

  useEffect(() => {
    const checkUsername = async () => {
      if (UserName.length <= 3 && UserName.length > 0) {
        setMessage(
          `${t("profilePassword.formData.validations.usernameMinChar")}`
        );
        setValidationMessage("");
        setValidateUserName(false);
      }
      // else if(new Regex("^[a-z_]", RegexOptions.IgnoreCase).IsMatch(username))
      else if (UserName.match(/[@#$%^&*./ ()!-]/g)) {
        setMessage(
          `${t("profilePassword.formData.validations.usernameSplChar")}`
        );
        setValidationMessage("");
        setValidateUserName(false);
      } else if (UserName.length > 15) {
        setMessage(
          `${t("profilePassword.formData.validations.usernameMaxChar")}`
        );
        setValidationMessage("");
        setValidateUserName(false);
      } else {
        setValidateUserName(true);
        setMessage("");
        let usernameResponse = await validateUserName(UserName);
        // console.log(usernameResponse.data);
        if (usernameResponse.data) {
          if (usernameResponse.data.response.statusCode === 409) {
            // setdisablebtn(true);
            setValidationMessage(usernameResponse.data.response.message);
          } else {
            // setdisablebtn(false);
            setValidationMessage("");
          }
        }
      }
    };
    checkUsername();
  }, [UserName]);

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="profileSetup-container" style={{ minHeight: "750px" }}>
        <LoginNavbar />
        <Container>
          <div className="row">
            <div className="col-sm-12">
              <Card className="profileSetup-card">
                <Card.Header className="profilesetup-header">
                  {t("profilePassword.title")}
                </Card.Header>
                <Card.Body>
                  <Form
                    className="profilesetup-form"
                    onSubmit={(e) => handleSubmit(e, UserName, Password)}
                  >
                    <Form.Group>
                      <Form.Label className="profilesetup-form-label">
                        {t("profilePassword.formData.username")} *
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder={t(
                          "profilePassword.formData.usernamePlaceholder"
                        )}
                        className={
                          (message != "" || validationMessage != "") &&
                          "error-input"
                        }
                        value={UserName}
                        onChange={(e) => handleUserName(e)}
                        required
                      />
                      {/* onChange={e =>  setUserName(e.target.value) } value={UserName} required /> */}
                      <span className="error-message">{message}</span>
                      <span className="error-message">{validationMessage}</span>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="profilesetup-form-label">
                        {t("profilePassword.formData.password")} *
                      </Form.Label>
                      <div
                        className="input-border"
                        // className={`mb-2 ${
                        //   showPasswordOptions ? "error-input" : "input-border"
                        // }`}
                      >
                        <InputGroup>
                          {/* <Form.Control size="lg" type="password" id="myInput" placeholder="**********" onChange={e => setPassword(e.target.value)} ref={inputRef} value={Password} required /> */}
                          <Form.Control
                            size="lg"
                            type="password"
                            id="myInput"
                            placeholder="**********"
                            onKeyUp={handlePwdKeyUp}
                            onChange={(e) => setPassword(e.target.value)}
                            ref={inputRef}
                            value={Password}
                            required
                          />
                          {/* <input type="password" id="psw" name="psw" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}" title="Please follow the below instructions" required></input> */}
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                              {showPasswordIcon ? (
                                <span onClick={myFunction}>{showPassword}</span>
                              ) : (
                                <span onClick={myFunction}>{hidePassword}</span>
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>

                      <span
                        style={{
                          fontSize: "12px",
                          lineHeight: "18px",
                          display: "block",
                        }}
                      >
                        {passwordRuleMsg}
                      </span>
                      {showPasswordOptions ? (
                        <div className="passwordOptions">
                          <ul>
                            {Object.values(passwordChecks).map((val) => (
                              <span>{val}</span>
                            ))}
                          </ul>

                          {/* <p className="error-message">Use 8 or more characters with a mix of letters, numbers, symbols and not the same as your username</p> */}
                        </div>
                      ) : null}
                      {/* <span className="error-message">{passwordValidation}</span>
                    <span style={{ color: "green" }}>{pwdSuccessMsg}</span> */}
                      {/* <pre style={{ fontSize: "14px", marginBottom: "0" }}>
                                            <strong>Password must contain atleast:</strong><br />
                                            - One number<br />
                                            - One uppercase and lowercase letter<br />
                                            - 8 or more characters<br />
                                            - one special character<br />
                                        </pre> */}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="profilesetup-form-label">
                        {t("profilePassword.formData.passwordRetype")} *
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          size="lg"
                          type="password"
                          placeholder="**********"
                          id="ReEnterPasswordInput"
                          onChange={(e) => setReTypedPassword(e.target.value)}
                          value={ReTypedPassword}
                          required
                        />

                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon3">
                            {showReenterPasswordIcon ? (
                              <span onClick={reEnterPassword}>
                                {showPassword}
                              </span>
                            ) : (
                              <span onClick={reEnterPassword}>
                                {hidePassword}
                              </span>
                            )}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <span className="error-message">{passwordMessage}</span>
                      <span style={{ color: "green" }}>
                        {passwordMatchedmessage}
                      </span>
                    </Form.Group>

                    {validatePassword === true &&
                    validateUsername === true &&
                    isCorrectPassword === true ? (
                      <Button className="profilesetup-button" type="submit">
                        {t("profilePassword.formData.save")}
                      </Button>
                    ) : (
                      <Button
                        className="profilesetup-button"
                        type="submit"
                        disabled
                      >
                        {t("profilePassword.formData.save")}
                      </Button>
                    )}

                    {/* <Button className="profilesetup-button" type="submit">Save</Button> */}
                  </Form>

                  {homePage && (
                    <Redirect
                      to={{
                        pathname: "/home",
                        state: { UserDetails: newUserDetails },
                      }}
                    />
                  )}
                  {homePage ? (
                    localStorage.getItem("content_external_id") ? (
                      <Redirect
                        to={{
                          pathname: `/share/${localStorage.getItem(
                            "content_external_id"
                          )}`,
                        }}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </Container>
        {error ? (
          <ToastMessage
            message="Something went wrong. Please try again."
            image={errorButton}
          />
        ) : (
          " "
        )}
      </div>
      <LeanmoreFooter />
    </>
  );
};
export default ProfilePassword;
