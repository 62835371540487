import axios from "axios";
import axiosInstance from "../axios/axios";
import {
  FACEBOOK_SIGN_IN,
  GOOGLE_SIGN_IN,
  // APPLE_SIGN_IN,
  NEXMO_OTP_SENT,
  NEXMO_SIGN_IN,
  VERIFY_PASSWORD,
  UPDATE_PASSWORD,
} from "../constants/action-types";
import { BASEURL } from "../config";

export const googleSignUp = (token) => (dispatch) => {
  axios({
    url: `${BASEURL}/v1/login`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      googleToken: token,
      login_type: "da5aef7c-7e43-4151-8da5-56bd9e86e455",
    },
  })
    .then((googleUser) =>
      dispatch({
        type: GOOGLE_SIGN_IN,
        payload: googleUser.data,
      })
    )
    .catch((err) => console.log(err));
};

export const facebookSignUp = (input_token) => (dispatch) => {
  axios({
    method: "POST",
    url: `${BASEURL}/v1/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      facebookToken: input_token,
      login_type: "4427d530-6772-4ce2-be7b-ef0c65e95414",
    },
    withCredentials: true,
  })
    .then((fbUser) =>
      dispatch({
        type: FACEBOOK_SIGN_IN,
        payload: fbUser.data,
      })
    )
    .catch((err) => console.log(err));
};

// export const appleLogin = (input_token, apple_id, email) => (dispatch) => {
//   if (email) {
//     axios({
//       method: "POST",
//       url: `${BASEURL}/v1/login`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: {
//         email: email,
//         apple_id: apple_id,
//         login_type: "c6eeec61-d06b-45e7-bfe4-ff12d4876b9e",
//         token: input_token,
//       },
//       withCredentials: true,
//     })
//       .then((appleUser) =>
//         dispatch({
//           type: APPLE_SIGN_IN,
//           payload: appleUser.data,
//         })
//       )
//       .catch((err) => console.error(err));
//   } else {
//     axios({
//       method: "POST",
//       url: `${BASEURL}/v1/login`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: {
//         apple_id: apple_id,
//         login_type: "c6eeec61-d06b-45e7-bfe4-ff12d4876b9e",
//         token: input_token,
//       },
//       withCredentials: true,
//     })
//       .then((appleUser) =>
//         dispatch({
//           type: APPLE_SIGN_IN,
//           payload: appleUser.data,
//         })
//       )
//       .catch((err) => console.error(err));
//   }
// };

export const sendOTP = (phone) => (dispatch) => {
  axios({
    url: `${BASEURL}/v1/login`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      user_mobile_number: phone,
      login_type: "278dddb0-ca63-472e-88fb-5f175ba81659",
    },
  })
    .then((nexmoResponse) =>
      dispatch({
        type: NEXMO_OTP_SENT,
        payload: nexmoResponse,
      })
    )
    .catch((error) => console.error(error));
};

export const nexmoLogin = (phone, code) => (dispatch) => {
  axios({
    method: "POST",
    url: `${BASEURL}/v1/login/verify`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      user_mobile_number: phone,
      code: code,
    },
    withCredentials: true,
  })
    .then((verifyOTP) =>
      dispatch({
        type: NEXMO_SIGN_IN,
        payload: verifyOTP,
      })
    )
    .catch((err) => localStorage.setItem("error", err.response.data.response.statusCode));
};

export const verifyPassword = (Password, External_Id) => (dispatch) => {
  axiosInstance({
    url: `/v1/login/verify/password`,
    method: "POST",
    data: {
      user_password: Password,
      user_external_id: External_Id,
    },
    withCredentials: true,
  })
    .then((response) =>
      dispatch({
        type: VERIFY_PASSWORD,
        payload: response,
      })
    )
    .catch((err) => {
      // throw new Error(err);
      console.log(err)
    });
};
// export const verifyPassword = (Password, External_Id) => dispatch => {
//   axios({
//     method: 'POST',
//     url: `${BASEURL}/v1/login/verify/password`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       user_password: Password,
//       user_external_id: External_Id
//     },
//     withCredentials: true,
//   })
//     .then(passWord =>
//       dispatch({
//         type: VERIFY_PASSWORD,
//         payload: passWord
//       }))
//     .catch(err => console.error(err))
// }

export const updatePassword = (currentPassword, newPassword) => {

  return axios({
    method: "POST",
    url: `${BASEURL}/v1/update/password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      oldPassword: currentPassword,
      newPassword: newPassword,
    },
    // withCredentials: true,
  })
    .then(res => res)
    // dispatch({
    //   type: UPDATE_PASSWORD,
    //   payload: passWord,
    // })

    .catch(err => err);
};