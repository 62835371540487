import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

//component imports
// import Loader from "../../Loader";
import PooledContentPage from "./pooledContent";
import Contents from "./contents";
import { showCreatePost } from "../../../actions/createPost";
// import CreateContentOld from "../../CreateContent/CreateContentOld";

//API Imports
import {
  getSignedUrlForProfilePic,
  uploadProfilePicToS3,
} from "../../../api/signedUrlForProfilePic";

// CSS imports
import "../../../assets/scss/Contents/activityPage.scss";
import HashTagContents from "./HashTagContents";

import {
  searchCharity,
  searchPosts,
  searchUsers,
} from "../../../actions/searchComponents/searchContent";
import { searchInfo } from "../../../actions/searchComponents/searchInfo";

import writeText from "../../../assets/svg/text.svg";

const ActivityPage = () => {
  // const [makeCallToUploadToS3, setMakeCallToUploadToS3] = useState(true);
  // const tokenDetails = useSelector((state) => state.tokenDetails); //get user token details
  const hashTagInfo = useSelector((state) => state.hashTagInfo);
  const userProfilePicture = useSelector((state) => state.userProfilePicture);

  const dispatch = useDispatch();
  const editIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0002 4.11956C17.9308 4.05021 17.8366 4.01168 17.7385 4.01272C17.6408 4.01341 17.5472 4.0516 17.4769 4.11956L12.1998 9.39651L12.1851 9.41139L12.1777 9.41878C12.1705 9.42985 12.1594 9.44093 12.152 9.45189C12.152 9.45558 12.1483 9.45558 12.1483 9.45927C12.1409 9.47024 12.1371 9.47774 12.1298 9.4887C12.126 9.49239 12.126 9.4962 12.1225 9.49977C12.1188 9.51085 12.1152 9.51823 12.1114 9.52931C12.1114 9.533 12.1077 9.533 12.1077 9.53658L11.1679 12.3705C11.1229 12.5024 11.1572 12.6486 11.2565 12.7464C11.3261 12.8151 11.4202 12.8535 11.518 12.8532C11.558 12.8524 11.5978 12.8463 11.636 12.8348L14.4626 11.8915C14.4661 11.8915 14.4661 11.8915 14.4698 11.8878C14.4815 11.8844 14.4928 11.8795 14.503 11.8731C14.5058 11.8726 14.5085 11.8714 14.5104 11.8693C14.5214 11.862 14.5362 11.8546 14.5473 11.8471C14.5582 11.8398 14.5693 11.8287 14.5804 11.8214C14.5842 11.8178 14.5878 11.8178 14.5878 11.814C14.5914 11.8103 14.5988 11.8067 14.6026 11.7994L19.8832 6.51862C19.9523 6.44963 19.991 6.35617 19.991 6.25879C19.991 6.16129 19.9523 6.06772 19.8832 5.99896L18.0002 4.11956ZM12.62 10.34L13.6629 11.3829L12.1003 11.9024L12.62 10.34ZM14.3447 11.0217L12.9811 9.6583L17.7385 4.90078L19.1019 6.26421L14.3447 11.0217Z"
        fill="#757575"
      />
      <path
        d="M17.1302 10.9003C16.9269 10.9009 16.7622 11.0655 16.7617 11.2688V18.1563C16.7599 18.7661 16.2661 19.2599 15.6561 19.2619H5.8465C5.23673 19.2599 4.74268 18.7661 4.74094 18.1563V8.3465C4.74268 7.73673 5.23673 7.24279 5.8465 7.24094H12.7338C12.9374 7.24094 13.1023 7.07595 13.1023 6.87243C13.1023 6.6689 12.9374 6.50391 12.7338 6.50391H5.8465C4.82898 6.50437 4.00437 7.32909 4.00391 8.3465V18.1563C4.00437 19.1737 4.82898 19.9985 5.8465 19.9988H15.6561C16.6736 19.9985 17.4983 19.1737 17.4987 18.1563V11.2688C17.4981 11.0655 17.3335 10.9009 17.1302 10.9003Z"
        fill="#757575"
      />
    </svg>
  );

  const galleryImage = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4286 3.42871H2.57143C1.15127 3.42871 0 4.57998 0 6.00014V18.0001C0 19.4203 1.15127 20.5715 2.57143 20.5715H21.4286C22.8487 20.5715 24 19.4203 24 18.0001V6.00014C24 4.57998 22.8487 3.42871 21.4286 3.42871ZM22.2857 13.3595L16.8917 7.96557C16.557 7.63093 16.0144 7.63093 15.6797 7.96557L8.57144 15.0738L5.74885 12.2512C5.41416 11.9166 4.87155 11.9166 4.53686 12.2512L1.71427 15.0738V6.00014C1.71427 5.52673 2.09802 5.14298 2.57143 5.14298H21.4286C21.902 5.14298 22.2857 5.52673 22.2857 6.00014V13.3595H22.2857Z"
        fill="#1DA8DE"
      />
    </svg>
  );

  const videoCam = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.8037 9.85737V4.8573C18.8037 4.07158 18.1915 3.42871 17.4432 3.42871H1.11642C0.368112 3.42871 -0.244141 4.07158 -0.244141 4.8573V19.1432C-0.244141 19.9289 0.368112 20.5718 1.11642 20.5718H17.4432C18.1915 20.5718 18.8037 19.9289 18.8037 19.1432V14.1431L24.246 19.8575V4.14301L18.8037 9.85737Z"
          fill="#F987D5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  useEffect(() => {
    console.log("Dipatching for default");
    // dispatch(searchCharity(""));
    // dispatch(searchPosts(""));
    // dispatch(searchUsers(""));
    dispatch(searchInfo(false, null));
  }, []);

  useEffect(() => {
    // This had to be an async as we have to wait for the signed url
    const profilePicUploadToS3 = async () => {
      let response = await getSignedUrlForProfilePic(
        userProfilePicture.contentType
      );
      const buffer = await Buffer.from(
        userProfilePicture.picture.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      // console.log(response.data.response.data.signedUrl);
      uploadProfilePicToS3(
        response.data.response.data.signedUrl,
        response.data.response.data.contentType,
        buffer
      );
    };

    //to load page on top
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      if (userProfilePicture.picture !== "") {
        profilePicUploadToS3();
      }
    }
  }, []);

  return (
    <div className="Activitypage-main">
      <Container className="Activitypage-container">
        <Row>
          <Col sm={12} md={8} id="main-content-wrapper">
            <div
              className="start-pool tour-post"
              onClick={() => dispatch(showCreatePost(true))}
            >
              <span className="start-pool-edit-icon"> {editIcon}</span>
              <p className="start-pool-text">Start a pool</p>
              <div className="start-pool-images">
                <span className="start-pool-image">{galleryImage}</span>
                <span className="start-pool-image">{videoCam}</span>
                <img
                  className="start-pool-image"
                  src={writeText}
                  alt="write-text"
                />
              </div>
            </div>
            {/* <CreateContentOld handleNewPostCreated={handleNewPostCreated} /> */}
            <hr />
            {hashTagInfo.hashTagStatus ? (
              <HashTagContents hashTagInfo={hashTagInfo} />
            ) : (
                <Contents />
              )}
          </Col>
          <Col sm={12} md={4} id="most-pooled">
            <PooledContentPage />
          </Col>
        </Row>
      </Container>

      {/* {showCookie ? (
        <div className="cookie-popup" ref={cookieRef.current[0]}>
          <p>
            This site uses cookies to ensure you get the best experience on our
            website.{" "}
            <a onClick={acceptCookie} className="accept-cookie-btn">
              Accept
            </a>
            <a onClick={declineCookie} className="decline-cookie-btn">
              Decline
            </a>
          </p>
        </div>
      ) : null} */}
    </div>
  );
};

export default ActivityPage;
