import axios from "axios";
import { BASEURL } from "../../config";

export const hashTagAPI = () => {
  return axios({
    url: `${BASEURL}/v1/contents/trending/hashtags`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export default hashTagAPI;
