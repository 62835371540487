import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ContentNavBar from "../ContentNavbar/ContentNavbar";
import Loader from "../Loader";
import LearnMoreFooter from "../LandingLearnMore/LeanmoreFooter";
import dropdownButtonArrow from "../../assets/svg/dropdownButtonArrow.svg";
import {
  getLeaderboardData,
  getLeaderboardDefault,
  getLeaderboardForCountry,
  getLeaderboardDuration,
} from "../../api/LeaderboardAPI";

import avatar from "../../assets/images/avatar.png";

//action import
import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";

import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/scss/LeaderBoard/LeaderBoard.scss";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    '& [class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
}));

const LeaderBoard = () => {
  const classes = useStyles();

  // const countriesList = useSelector((state) => state.countriesList);
  const allowedCountries = useSelector((state) => state.allowedCountries);
  const charityNames = useSelector((state) => state.charityNames);
  const loggedUser = useSelector((state) => state.userDetails.username);
  const userDetails = useSelector((state) => state.userDetails); //Converted the profileDetailsById to userDetails

  const language = localStorage.getItem("lang");

  const [countryName, setCountry] = useState("");
  const [countryID, setCountryID] = useState("");
  const [charity, setCharity] = useState("");
  const [charityID, setCharityID] = useState("");
  const [date, setDate] = useState("");
  const [dateValue, setDateValue] = useState("1");
  const [apiResponse, setApiResponse] = useState(false);
  const [apiData, setApiData] = useState("");
  const [data, setData] = useState("");
  const [initLoad, setInitLoad] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [t, i18n] = useTranslation("translate");
  const allowedCountryName = Object.values(allowedCountries);
  const charities = charityNames.data;
  // console.log('Country: '+ countryID + ' || Charity: ' + charityID + ' || date: ' + dateValue);

  // console.log("Token: " + localStorage.getItem("accessToken"));
  // console.log("UserExtId " + localStorage.getItem("user_external_id"));

  //--------------- Dropdown filters handler -----------------//

  const handleFilterCall = () => {
    // console.log(countryName + " || " + charity + " || " + dateValue);
    if (countryName === "" && charity === "" && dateValue !== "") {
      getDataForDuration();
    } else if (countryName !== "" && charity === "" && dateValue !== "") {
      getDataForCountry();
    } else if (countryName !== "" && charity !== "" && dateValue !== "") {
      getData();
    }
  };

  // console.log(countryName + " || " + charity + " || " + dateValue);s

  // const handleCountry = (e) => {
  //   if (e !== null) {
  //     const country = e;
  //     const country_arr = country.split(",");
  //     setCountryID(country_arr[0]);
  //     setCountry(country_arr[1]);
  //     setCharity("");
  //     setCharityID("");
  //   } else {
  //     setCountryID("");
  //     setCountry("");
  //   }
  // };

  const handleCountry = (e, value) => {
    // console.log(value);
    if (value != null) {
      let countryExternalId = value.country_external_id;

      if (countryExternalId !== "") {
        setCountryID(countryExternalId);
        Object.values(allowedCountries).map((country) => {
          if (country.country_external_id === countryExternalId) {
            setCountry(country.country_name);
            setCharity("");
            setCharityID("");
          }
        });
      } else if (countryExternalId === "") {
        setCountryID("");
        setCountry("");
        setCharity("");
        setCharityID("");
      }
    } else if (value == null) {
      setCountryID("");
      setCountry("");
    }
  };

  const handleCharity = (e, value) => {
    // console.log(value);
    if (value != null) {
      let causeExternalId = value.cause_external_id;
      if (causeExternalId !== "") {
        setCharityID(causeExternalId);
        charityNames.data.map((cause) => {
          if (cause.cause_external_id === causeExternalId) {
            setCharity(cause.cause_name);
          }
        });
      } else {
        setCharityID("");
        setCharity("");
      }
    }else{
      setCharity("");
      setCharityID("");
    }
  };

  // const handleCharity = (e) => {
  //   if (e !== null) {
  //     const charity = e;
  //     const name = charity.split(",");
  //     setCharityID(name[0]);
  //     setCharity(name[1]);
  //   } else {
  //     setCharity("");
  //     setCharityID("");
  //   }
  // };

  const handleDate = (e) => {
    setDateValue(e);

    switch (e) {
      case "0":
        setDate(t("leaderboard.filters.duration.lifetime"));
        break;
      case "1":
        setDate(t("leaderboard.filters.duration.today"));
        break;
      case "7":
        setDate(t("leaderboard.filters.duration.thisWeek"));
        break;
      case "30":
        setDate(t("leaderboard.filters.duration.thisMonth"));
        break;
      default:
        setDate(t("leaderboard.filters.duration.today"));
    }
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    // if (initLoad) {
    //   getDefault();
    // } else {
    //   handleFilterCall();
    // }

    handleFilterCall();
  }, [countryName, charity, dateValue]);

  // ---- Making an API call to load default data ----- //
  // useEffect(() => {
  //   getDefault();
  // }, []);

  const getDefault = async () => {
    setIsLoading(true);
    const dataResponse = await getLeaderboardDefault();
    if (dataResponse) {
      setApiData(dataResponse.response.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      // setInitLoad(false);
    }
  };

  //-------- Making the API call on filter change --------//
  useEffect(() => {
    getData();
  }, [countryID, charity, dateValue]);

  const getData = async () => {
    if (countryName !== "" && charity !== "" && dateValue !== "") {
      setIsLoading(true);
      const dataResponse = await getLeaderboardData(
        charityID,
        dateValue,
        1,
        countryID
      );

      setApiResponse(dataResponse);
    }
  };

  // ------ Making an API call based on country and duration ------ //
  // useEffect(() => {
  //   getDataForCountry();
  // }, [countryID, dateValue]);

  const getDataForCountry = async () => {
    if (countryName !== "" && dateValue !== "" && charity === "") {
      setIsLoading(true);
      const dataResponse = await getLeaderboardForCountry(dateValue, countryID);

      setApiResponse(dataResponse);
    }
  };

  // ------ Making an API call based on duration ------ //
  // useEffect(() => {
  //   getDataForDuration();
  // }, [dateValue]);

  const getDataForDuration = async () => {
    if (dateValue !== "" && countryName === "" && charity === "") {
      setIsLoading(true);
      const dataResponse = await getLeaderboardDuration(dateValue);

      setApiResponse(dataResponse);
    }
  };

  //------------------ Preparing the response data -----------------//
  const statusCheck = () => {
    if (apiResponse) {
      if (apiResponse.response.statusCode === 204) {
        setInitLoad(false);
        setApiData("");
        setIsLoading(false);
      } else if (apiResponse.response.statusCode === 200) {
        setApiData(apiResponse.response.data);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    statusCheck();
  }, [apiResponse]);

  useEffect(() => {
    if (apiData) {
      const tempUser = [...apiData];
      const user = tempUser.find((user) => user.username === loggedUser);
      const indexPos = tempUser.findIndex(
        (user) => user.username === loggedUser
      );

      const modifiedUser = { ...user, user: true };

      // console.log(tempUser);
      // console.log([...filteredUser, modifiedUser]);
      // console.log(indexPos);

      if (indexPos !== -1) {
        tempUser.splice(indexPos, 1, modifiedUser);
        // console.log(tempUser);
        setData(tempUser);
      } else if (indexPos === -1) {
        setData(apiData);
      }
    } else {
      setData("");
    }
  }, [apiData, apiResponse]);
  //----------------------------------//

  //------ Truncating the string in dropdown buttons ------//
  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  //-----------------------------------------------------//

  const handleOtherUser = (otherUserExternalId) => {
    // console.log(otherUserExternalId);
    history.push({
      pathname: "/otheruserprofile",
      state: { otherUserExternalId: otherUserExternalId },
    });
    // history.push("/otheruserprofile");
    dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
  };

  const renderData = data ? (
    data.map((contributor, index) => {
      return (
        <div
          key={index}
          className={
            contributor.hasOwnProperty("user")
              ? "d-flex justify-content-between align-items-center leaderboard-contributor user-highlight"
              : "d-flex justify-content-between align-items-center leaderboard-contributor"
          }
        >
          <div className="d-flex align-items-center">
            <div className="leaderboard-rank-content">
              <div className="contributor-rank">{index + 1}</div>
            </div>
            <div className="d-flex align-items-center">
              <span className="image-display-pic mr-3">
                {contributor.user_external_id ===
                  userDetails.user_external_id ? (
                  <img
                    onError={(ev) => (ev.target.src = `${avatar}`)}
                    className="profile-picture"
                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${contributor.user_external_id}.jpg`}
                    roundedCircle
                    width="30"
                    height="30"
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    onError={(ev) => (ev.target.src = `${avatar}`)}
                    onClick={() =>
                      handleOtherUser(contributor.user_external_id)
                    }
                    className="profile-picture"
                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${contributor.user_external_id}.jpg`}
                    roundedCircle
                    width="30"
                    height="30"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </span>
              {contributor.user_external_id === userDetails.user_external_id ? (
                <span className="contributor-name">{contributor.username}</span>
              ) : (
                <span
                  className="contributor-name"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOtherUser(contributor.user_external_id)}
                >
                  {contributor.username}
                </span>
              )}
            </div>
          </div>

          <div>
            <span className="contributor-amount">
              {`${contributor.currency_sign} ${contributor.pooled}`}
            </span>
          </div>
        </div>
      );
    })
  ) : (
    <p className="text-center text-muted">No data to show</p>
  );

  const incomingArrow = (
    <svg
      width="10"
      height="10"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57138 1L5.57138 12.865L1.52278 9.232C1.12907 8.878 0.543134 8.936 0.215346 9.36C-0.11337 9.785 -0.0595131 10.415 0.334204 10.769L5.90567 15.769C5.94931 15.808 6.00038 15.827 6.04867 15.856C6.08767 15.88 6.1211 15.909 6.16381 15.927C6.2706 15.972 6.38296 15.999 6.49624 15.999C6.49717 15.999 6.49903 16 6.49996 16C6.50089 16 6.50274 15.999 6.50367 15.999C6.61696 15.999 6.72932 15.972 6.8361 15.927C6.87882 15.909 6.91225 15.88 6.95125 15.856C6.99953 15.827 7.0506 15.808 7.09425 15.769L12.6657 10.769C12.8858 10.57 13 10.286 13 10C13 9.774 12.9294 9.547 12.7846 9.36C12.4568 8.936 11.8708 8.878 11.4771 9.232L7.42854 12.865L7.42854 1C7.42854 0.448 7.01253 0 6.49996 0C5.98738 0 5.57138 0.448 5.57138 1Z"
        fill="#b32400"
      />
    </svg>
  );

  const outgoingArrow = (
    <svg
      width="10"
      height="10"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42862 15L7.42862 3.135L11.4772 6.768C11.8709 7.122 12.4569 7.064 12.7847 6.64C13.1134 6.215 13.0595 5.585 12.6658 5.231L7.09433 0.231C7.05069 0.192 6.99962 0.173 6.95133 0.144C6.91233 0.12 6.8789 0.0909996 6.83619 0.073C6.7294 0.0279999 6.61704 0.0010004 6.50376 0.0010004C6.50283 0.0010004 6.50097 0 6.50004 0C6.49911 0 6.49726 0.0010004 6.49633 0.0010004C6.38304 0.0010004 6.27068 0.0279999 6.1639 0.073C6.12118 0.0909996 6.08775 0.12 6.04875 0.144C6.00047 0.173 5.9494 0.192 5.90575 0.231L0.334288 5.231C0.114215 5.43 0 5.714 0 6C0 6.226 0.0705719 6.453 0.21543 6.64C0.543218 7.064 1.12915 7.122 1.52287 6.768L5.57146 3.135L5.57146 15C5.57146 15.552 5.98747 16 6.50004 16C7.01262 16 7.42862 15.552 7.42862 15Z"
        fill="#27ae60"
      />
    </svg>
  );

  const incomingArrowForHeader = (
    <svg
      width="10"
      height="10"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57138 1L5.57138 12.865L1.52278 9.232C1.12907 8.878 0.543134 8.936 0.215346 9.36C-0.11337 9.785 -0.0595131 10.415 0.334204 10.769L5.90567 15.769C5.94931 15.808 6.00038 15.827 6.04867 15.856C6.08767 15.88 6.1211 15.909 6.16381 15.927C6.2706 15.972 6.38296 15.999 6.49624 15.999C6.49717 15.999 6.49903 16 6.49996 16C6.50089 16 6.50274 15.999 6.50367 15.999C6.61696 15.999 6.72932 15.972 6.8361 15.927C6.87882 15.909 6.91225 15.88 6.95125 15.856C6.99953 15.827 7.0506 15.808 7.09425 15.769L12.6657 10.769C12.8858 10.57 13 10.286 13 10C13 9.774 12.9294 9.547 12.7846 9.36C12.4568 8.936 11.8708 8.878 11.4771 9.232L7.42854 12.865L7.42854 1C7.42854 0.448 7.01253 0 6.49996 0C5.98738 0 5.57138 0.448 5.57138 1Z"
        fill="#888"
      />
    </svg>
  );

  return (
    <>
      <div className="leaderWrapper">
        <ContentNavBar />
        <section className="leaderBoard-content">
          <Container>
            <Row>
              <Col xs={12} md={9} lg={9} className="mx-auto p-0">
                {/* <h3 className="leaderboard-main-header">Leaderboard</h3> */}
                <h3 className="leaderboard-main-header">
                  {t("leaderboard.title")}
                </h3>
                <div className="leaderboard-filters">
                  {/* ------ Country dropdown ------ */}
                  <div className="dropdown-container dropdown-country">
                    <Autocomplete
                      id="countryFilter"
                      options={allowedCountryName}
                      onChange={(e, value) => handleCountry(e, value)}
                      getOptionLabel={(option) => option.country_name}
                      className={classes.dropdown}
                      renderInput={(params) => {
                        return (
                          <>
                            {userDetails.country_name === null ? (
                              <TextField
                                {...params}
                                variant="outlined"
                                // placeholder="Country"
                                placeholder = {t("leaderboard.filters.country")}
                                fullWidth
                              />
                            ) : (
                              <TextField
                                {...params}
                                variant="outlined"
                                // placeholder={`Country`}
                                // placeholder={`${userDetails.country_name}`}
                                placeholder = {t("leaderboard.filters.country")}
                                fullWidth
                              />
                            )}
                          </>
                        );
                      }}
                    />
                  </div>

                  {/*<img
                      className="dropdown-arrow"
                      src={dropdownButtonArrow}
                      alt="dropdownButtonArrow"
                    />

                     <DropdownButton
                      className="dropdown-btn"
                      onSelect={handleCountry}
                      title={
                        <span className="dropdown-span">
                          {countryName
                            ? text_truncate(countryName, 13)
                            : t("leaderboard.filters.country")}
                        </span>
                      }
                    >
                      <div className="dropdown-options">
                        <Dropdown.Item eventkey=" ">Country</Dropdown.Item> 
                        <Dropdown.Item eventkey=" ">
                          {t("leaderboard.filters.country")}
                        </Dropdown.Item>
                        {Object.values(allowedCountries).map(
                          (countryData, index) => (
                            <Dropdown.Item
                              eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                              key={countryData.country_id}
                            >
                              {countryData.country_name}
                            </Dropdown.Item>
                          )
                        )}
                      </div>
                    </DropdownButton> /*}
                  

                  {/* ------ Charity dropdown ------ */}

                  <div className="dropdown-container">
                    <Autocomplete
                     options={charities.filter(
                      (cause) => cause.country_name === countryName
                    )}
                    getOptionLabel={(option) => option.cause_name}
                    onChange={(e, value) => handleCharity(e, value)}
                      disabled={countryName === "" ? true : false}
                      id="countryFilter"
                     
                      className={classes.dropdown}
                      renderInput={(params) => {
                        return (
                          <div>
                            {userDetails.cause_name === null ? (
                              <TextField
                           
                                {...params}
                                variant="outlined"
                                // placeholder="Cause"
                                placeholder={t("leaderboard.filters.charity")}
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'cause', // disable autocomplete and autofill
                                }}
                              />
                            ) : (
                              <TextField
                              
                                {...params}
                                variant="outlined"
                                placeholder={`${userDetails.cause_name}`}
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'cause', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          </div>
                        );
                      }}
                    />

                    {/* <img
                      className="dropdown-arrow"
                      src={dropdownButtonArrow}
                      alt="dropdownButtonArrow"
                    />

                    <DropdownButton
                      disabled={countryName ? false : true}
                      className="dropdown-btn"
                      onSelect={handleCharity}
                      title={
                        <span className="dropdown-span">
                          {charity
                            ? text_truncate(charity, 25)
                            : t("leaderboard.filters.country")}
                        </span>
                      }
                    >
                      <Dropdown.Item eventkey=" ">
                        {t("leaderboard.filters.charity")}
                      </Dropdown.Item>
                      {charityNames.data.map((cause, index) => {
                        return countryName === cause.country_name ? (
                          <Dropdown.Item
                            eventKey={`${cause.cause_external_id}, ${cause.cause_name}`}
                            key={cause.cause_external_id}
                          >
                            {cause.cause_name}
                          </Dropdown.Item>
                        ) : (
                            ""
                          );
                      })}
                    </DropdownButton> */}
                  </div>

                  {/* ------ Duration/Date dropdown ------ */}
                  <div className="dropdown-container dropdown-month">
                    <img
                      className="dropdown-arrow"
                      src={dropdownButtonArrow}
                      alt="dropdownButtonArrow"
                    />

                    <DropdownButton
                      className="dropdown-btn"
                      onSelect={handleDate}
                      title={
                        <span className="dropdown-span">
                          {date
                            ? date
                            : t("leaderboard.filters.duration.today")}
                        </span>
                      }
                    >
                      <Dropdown.Item eventKey="1">
                        {t("leaderboard.filters.duration.today")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="7">
                        {t("leaderboard.filters.duration.thisWeek")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="30">
                        {t("leaderboard.filters.duration.thisMonth")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="0">
                        {t("leaderboard.filters.duration.lifetime")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                md={9}
                lg={9}
                className="mx-auto leaderboard-list-container"
              >
                <div>
                  <div className="d-flex justify-content-between align-items-center  leaderboard-header-container">
                    <div className="d-flex">
                      <div className="leaderboard-rank-content">
                        <span className="leaderboard-list-title">
                          {t("leaderboard.tableHeaders.rank")}
                        </span>
                      </div>
                      <div>
                        <span className="leaderboard-list-title">
                          {t("leaderboard.tableHeaders.name")}
                        </span>
                      </div>
                    </div>

                    <div>
                      <span className="leaderboard-arrow">
                        {outgoingArrow}
                        {incomingArrowForHeader}
                      </span>
                      <span className="leaderboard-list-title">
                        {t("leaderboard.tableHeaders.poolCollected")}
                      </span>
                    </div>
                  </div>

                  {loading ? <Loader /> : renderData}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <LearnMoreFooter />
      </div>
    </>
  );
};

export default LeaderBoard;
