import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Slide } from '@material-ui/core';
import { showCreatePost } from '../../actions/createPost';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const arr = ["Posted", "Followings", "Pooled", "Followers", "Golden Buzzer"];

const UpcomingBadges = ({ upcomingBadgesList }) => {
    // const [open, setOpen] = useState(false);
    const [orderValue, setOrderValue] = useState([]);
    const dispatch = useDispatch();

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    useEffect(() => {
        var ordered = [];
        const arr = ["Posted", "Followings", "Pooled", "Followers", "Golden Buzzer"];
        for (var j = 0; j < arr.length; j++) {
            for (var i = 0; i < upcomingBadgesList.length; i++) {
                if (arr[j].indexOf(upcomingBadgesList[i].badge_category) > -1) {
                    ordered.push(upcomingBadgesList[i]);
                }
            }
        }
        setOrderValue(ordered);
    }, [upcomingBadgesList])

    const handleBadgeDescription = (upcomingBadges, remaining_range) => {
        if (upcomingBadges.badge_category === "Posted") {
            return <strong>{remaining_range} to go</strong>
        }
        else if (upcomingBadges.badge_category === "Followings") {
            return <strong>{remaining_range} to go</strong>
        }
        else if (upcomingBadges.badge_category === "Followers") {
            return <strong>{remaining_range} {remaining_range > 1 ? "users left" : "user left"}</strong>
        }
        else if (upcomingBadges.badge_category === "Golden Buzzer") {
            return <strong>{remaining_range} golden buzzer to be achieved</strong>
        }
        else {
            return <strong>{remaining_range} remaining</strong>
        }

    }


    return (
        <>
            <div>
                {orderValue.map((upcomingBadges) => {
                    return (
                        <Card className="recent-activity-card-badge">
                            <div style={{ display: "flex", position: "relative" }}>

                                <div className="achieveImageLock">
                                    {/* <div className="overlay"></div> */}
                                    {/* <span>{badge}</span> */}
                                    <img
                                        className="upcomingBadges"
                                        src={upcomingBadges.badge_image}
                                        alt="badge-icon"
                                    />
                                </div>
                                {/* <img src={achievedDetails.badge_image} alt="badge-icon" /> */}
                                <div style={{ display: "inline-block" }}>
                                    <p className="recent-activity-points-earned">
                                        {/* The Seal is broken · 10 Points */}
                                        {upcomingBadges.badge_description} · {upcomingBadges.badge_point} points
                                     </p>
                                    <p className="recent-activity-points-earned-description">
                                        {/* Create 10 posts to get this badge  ·  <strong>10 posts left</strong> */}
                                        {upcomingBadges.badge_message}  ·  {handleBadgeDescription(upcomingBadges, upcomingBadges.remaining_range)}
                                        {/* {achievedDetails.achievement_description} on -{" "}
                                 {dayMonthYear} */}

                                    </p>
                                </div>
                                {upcomingBadges.badge_category === "Followings" ? <Link to="/home" className="achieve-button">
                                    Let's do it
                         </Link> : ""}
                                {upcomingBadges.badge_category === "Posted" ? <Button className="achieve-button" onClick={() => dispatch(showCreatePost(true))}>
                                    Let's do it
                         </Button> : ""}
                                {upcomingBadges.badge_category === "Pooled" ? <Link to="/home" className="achieve-button">
                                    Let's do it
                         </Link> : ""}

                            </div>
                        </Card>

                    )
                })}

            </div>
        </>
    )

}

export default UpcomingBadges
