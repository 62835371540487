import axios from 'axios';
import { BASEURL } from "../config";
import { GET_FOLLOWING_LIST } from "../constants/action-types";

export const getFollowingList = (otherExternalId) => dispatch => {
    if (otherExternalId) {
        axios({
            method: 'GET',
            url: `${BASEURL}/v1/followings`,
            params: {
                user_external_id: otherExternalId,
            },
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                // 'Authorization': `Bearer ${token}`,
                // 'userexternalid': externalId
            },
            withCredentials: true
        })
            .then(followingResponse =>
                // console.log("userProfileResponse", followingResponse))
                dispatch({
                    type: GET_FOLLOWING_LIST,
                    payload: followingResponse.data.response
                }))
            .catch(err => console.error(err))
    }
    else {
        axios({
            method: 'GET',
            url: `${BASEURL}/v1/followings`,
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                // 'Authorization': `Bearer ${token}`,
                // 'userexternalid': externalId
            },
            withCredentials: true
        })
            .then(followingResponse =>
                // console.log("userProfileResponse", followingResponse))
                dispatch({
                    type: GET_FOLLOWING_LIST,
                    payload: followingResponse.data.response
                }))
            .catch(err => console.error(err))
    }

}




