import axiosInstance from "axios";
import { BASEURL } from "../config";

export const loginNewUserName = (userName, password) => {
  return axiosInstance({
    url: `${BASEURL}/v1/login/password`,
    method: "POST",
    data: {
      username: userName,
      user_email_address: "",
      user_mobile_number: "",
      user_password: password,
      login_type: "8fc080f4-f66a-47c3-a65a-718a17dad714",
    },
    withCredentials: true,
  })
    .then(
      (response) => response.data
      // console.log("response", response)
    )
    .catch((err) => {
      // throw new Error(err);
      console.log(err);
    });
};

export const loginNewEmail = (Email, password) => {
  return axiosInstance({
    url: `${BASEURL}/v1/login/password`,
    method: "POST",
    data: {
      username: "",
      user_email_address: Email,
      user_mobile_number: "",
      user_password: password,
      login_type: "8fc080f4-f66a-47c3-a65a-718a17dad714",
    },
    withCredentials: true,
  })
    .then(
      (response) => response.data
      // console.log("response", response)
    )
    .catch((err) => {
      // throw new Error(err);
      console.log(err);
    });
};
