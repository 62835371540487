import { combineReducers } from "redux";

import storingGoogleDataReducer from "./Google/storingGoogleData";
import GoogleResponseReducer from "./Google/GoogleResponse";

import storeFBDataReducer from "./Facebook/storingFBData";
import FBResponseReducer from "./Facebook/FBResponse";

// import storeAppleDataReducer from "./Apple/storingAppleData";
// import AppleResponseReducer from "./Apple/AppleResponse";

import nexmoOTPReducer from "./Nexmo/nexmoOTP";
import storingPhoneNumberReducer from "./Nexmo/storingPhoneNumber";
import NexmoResponseReducer from "./Nexmo/nexmoResponse";

import ProfileSetupReducer from "./ProfileSetup/profile";
import ProfilePicUpdateReducer from "./ProfileSetup/updatePicture";

import ActivityReducer from "./Activity/activityContent";
import ActivityGenerateTokenReducer from "./Activity/activityGenerateToken";
import storeHashTagReducer from "./Activity/storeHashTags";
import hashTagReducer from "./Activity/hashTagContent";

import TempPasswordDataReducer from "./tempPassword";
import CharityNamesReducer from "./CharityNames/CharityNames";
import CountriesListReducer from "./CountriesList/countriesList";
import allowedCountriesListReducer from "./CountriesList/allowedCountryList";

import UserDetailsReducer from "./User/userDetails";
import TokenDetailsReducer from "./User/tokenDetails";
import ValiderUserDetailsReducer from "./User/validateUserDetails";

import VerifyPasswordReducer from "./Password/verifypassword";
import UpdatePasswordReducer from "./Password/updatepassword";

import UpdateProfileDetailsByIdReducer from "./Profile/updateprofiledetailsbyId";
import GetFollowingListReducer from "./Profile/followingList";
import GetFollowersListReducer from "./Profile/followerList";
import GetFollowersCountReducer from "./Profile/followerCount";
import UserProfileDetailsByIdReducer from "./Profile/userProfileDetails";
import UserAuthorizedReducer from "./userAuthorized";
import OtherUserProfileDetailsByIdReducer from "./OtherUserProfile/otherUserProfileDetailsById";
import getProfilePictureReducer from "./ProfileSetup/getProfilePicture";
import RazorPayReducer from "./Razorpay/razorpay";

import storeContentIdReducer from "./storeContentId";

import UserDefaultAmountReducer from "./User/userDefaultAmount";
import ShowBalanceReducer from "./User/showBalance";
import BalanceReducer from "./User/balance";

import {
  searchPostReducer,
  searchCharityReducer,
  searchUserReducer,
} from "./SearchContent/searchPostCharityUser";
import { searchInfoReducer } from "../reducers/SearchContent/searchInfo";

import { LOG_OUT } from "../constants/action-types";
import createPostReducer from "./createPost/createPost.js";
import newPostReducer from "./createPost/newPost.js";

// const rootReducer = combineReducers({
const appReducer = combineReducers({
  isUserAuthorized: UserAuthorizedReducer,
  googleResponse: GoogleResponseReducer,
  googleUserData: storingGoogleDataReducer,
  FbUserData: storeFBDataReducer,
  FbResponseData: FBResponseReducer,
  // AppleUserData: storeAppleDataReducer,
  // AppleResponseData: AppleResponseReducer,

  nexmoPhoneNumber: storingPhoneNumberReducer,
  nexmoOTPsentResponse: nexmoOTPReducer,
  nexmoVerificationResponse: NexmoResponseReducer,
  userContent: ActivityReducer,
  userProfile: ProfileSetupReducer,
  userDetails: UserDetailsReducer,
  contentNewToken: ActivityGenerateTokenReducer,
  tempPassword: TempPasswordDataReducer,
  tokenDetails: TokenDetailsReducer,
  verifyUserDetails: ValiderUserDetailsReducer,
  verifyEnteredPassword: VerifyPasswordReducer,
  resetPassword: UpdatePasswordReducer,
  updateProfileById: UpdateProfileDetailsByIdReducer,
  charityNames: CharityNamesReducer,
  profilePicUpdate: ProfilePicUpdateReducer,
  followingList: GetFollowingListReducer,
  followersList: GetFollowersListReducer,
  followersCounts: GetFollowersCountReducer,
  countriesList: CountriesListReducer,
  allowedCountries: allowedCountriesListReducer,
  userProfileDetails: UserProfileDetailsByIdReducer,
  otherUserProfileDetails: OtherUserProfileDetailsByIdReducer,
  userProfilePicture: getProfilePictureReducer,
  hashTagInfo: hashTagReducer,
  hashTagsData: storeHashTagReducer,
  verifiedPayment: RazorPayReducer,
  shareSheet: storeContentIdReducer,
  userDefaultAmount: UserDefaultAmountReducer,
  ShowBalanceReducer: ShowBalanceReducer,
  BalanceReducer: BalanceReducer,
  searchPostReducer,
  searchCharityReducer,
  searchUserReducer,
  searchInfo: searchInfoReducer,
  CreatePostPopup: createPostReducer,
  newPost: newPostReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
