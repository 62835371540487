import axios from "axios";
import { BASEURL } from "../config";

export const getLeaderboardData = (
  cause_external_id,
  data,
  pageNo,
  country_external_id
) => {
  return axios({
    url: `${BASEURL}/v1/leaderboard`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      cause_external_id,
      data,
      pageNo,
      country_external_id,
    },
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const getLeaderboardDefault = () => {
  return axios({
    url: `${BASEURL}/v1/leaderboard`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const getLeaderboardForCountry = (data, country_external_id) => {
  return axios({
    url: `${BASEURL}/v1/leaderboard`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      data,
      country_external_id,
    },
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const getLeaderboardDuration = (data) => {
  return axios({
    url: `${BASEURL}/v1/leaderboard`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      data,
    },
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};
