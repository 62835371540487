import { useState, useRef, useEffect } from 'react';
import video from '../../assets/images/introVideo.mp4';
import landingiPhoneImage from '../../assets/images/landingiPhoneImage.png';
import landingShareImage from '../../assets/images/landingShareImage.png';
import playButton from '../../assets/images/playButton.png';
import "../../assets/scss/LandingLearnMore/introVideo.scss";


const IntroVideo = () => {
    const [playVideo, setPlayVideo] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
            setPlayVideo(true)
        } else {
            var promise = videoRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // console.log(promise);
                    setPlayVideo(false)
                    videoRef.current.play(); //play video

                }).catch(error => {
                    // console.log('catch');
                    setPlayVideo(true)
                });
            }
        }

        //IntersectionObserver, intersection api
        //     const observer = new IntersectionObserver(
        //         ([entry]) => {
        //             // console.log(entry);
        //             // console.log(videoRef.current)
        //             if (entry.isIntersecting) { //isIntersecting true
        //                 videoRef.current.play(); //play video
        //                 // console.log('It works!')
        //             }
        //             // else {
        //             //     videoRef.current.pause(); //pause video
        //             // }
        //         });
        //     observer.observe(videoRef.current);

    }, [videoRef])

    const handleVideoPlay = () => {
        setPlayVideo(false)
        // console.log('play');
        videoRef.current.play();
    }
    const handleVideoPause = () => {
        setPlayVideo(true)
        // console.log('pause');
        console.log(videoRef.current)
        videoRef.current.pause();
    }

    return (
        <>
            <div className="intro-wrapper">
                <div className="video-share-wrapper">

                    <div className="video-parent-overlay">
                        <video ref={videoRef} src={video} type="video/mp4" controls muted></video>

                        {playVideo && <div className="overlay" onClick={handleVideoPlay}>
                            <img className="playButton" src={playButton} alt="playButton" />
                        </div>}

                    </div>
                    <img className="shareImage" src={landingShareImage} alt="share-social-media" />

                </div>
                <img className="iphoneImage" src={landingiPhoneImage} />

            </div>
        </>
    )
}

export default IntroVideo
