import React, { useState } from "react";
import { Row, Col, Navbar, Nav, NavLink, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

import { BASEURL } from "../../config";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import brandLogo from "../../assets/svg/brandLogo.svg";
import countriesList from '../../assets/CountryCode/Countries.json';
import "../../assets/scss/Contact/Contact.scss";

const Contact = () => {
  // const countriesList = useSelector((state) => state.countriesList);

  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryID, setCountryID] = useState("");
  const [query, setQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showToastMsg, setShowToastMsg] = useState(false);

  // Handlers
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCountry = (e) => {
    setCountryID(e.target.value);
  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios({
      url: `${BASEURL}/v1/support`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        Name: name,
        Email_Address: email,
        Country: countryID,
        Message: query,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setShowToastMsg(true);
          setIsLoading(false);
          setTimeout(() => {
            setShowToastMsg(false);
          }, 5000);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="contact-wrapper">
      <div className="contact-navbar-wrapper">
        <Navbar className="contact-navbar">
          <Navbar.Brand href="/home">
            <img src={brandLogo} width="66" height="48" alt="brand-logo" />
          </Navbar.Brand>

          <Nav className="ml-auto menu-button">
            <Nav.Item>
              {authorizedUser ? (
                <NavLink className="contact-navlink" href="/home">
                  Back to GoPoolit
                </NavLink>
              ) : (
                  <NavLink className="contact-navlink" href="/">
                    Back to GoPoolit
                  </NavLink>
                )}
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>

      <div className="contact-form-container">
        <Row className="mx-0">
          <Col md={5} xs={11} className="contact-form mx-auto">
            <div>
              <Form onSubmit={handleSubmit}>
                <div>
                  <h3>Contact us now</h3>

                  <div>
                    <Form.Group className="section">
                      <Row>
                        <Col md={6}>
                          <Form.Label>
                            Full name<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            placeholder="Full name"
                            value={name}
                            onChange={(e) => handleName(e)}
                            required
                          />
                        </Col>

                        <Col md={6} className="email-input">
                          <Form.Label>
                            Email address<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => handleEmail(e)}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="section">
                      <Row>
                        <Col>
                          <Form.Label>
                            Country<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            // disabled={causeName === null ? true : false}
                            className="dropdown-btn"
                            as="select"
                            onChange={(e) => handleCountry(e)}
                            value={countryID}
                            required
                          >
                            <option value="" disabled>
                              Select country
                            </option>
                            {countriesList.map((countryData, index) => {
                              return (
                                <option
                                >
                                  {countryData.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="section">
                      <Row>
                        <Col>
                          <Form.Label>
                            How can we help you?<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            onChange={(e) => handleQuery(e)}
                            placeholder="Type your message here..."
                            rows={6}
                            value={query}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    {isLoading ? (
                      <button
                        className="btn btn-secondary contact-btn"
                        type="submit"
                        disabled
                      >
                        Send <Spinner animation="border" className="spinner" />
                      </button>
                    ) : (
                        <button
                          className="btn btn-secondary contact-btn"
                          type="submit"
                        >
                          Send
                        </button>
                      )}
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      {showToastMsg ? (
        <ToastMessage
          image={greenTickMark}
          message="Thanks for contacting us. We will revert back to you soon."
        />
      ) : null}
    </div>
  );
};

export default Contact;
