import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
// import { BASEURL } from '../../config';

// import axios from "axios";

//import google-libphonenumber package for mobile verification
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

// import data from "../../assets/CountryCode/Countries.json";

//import actions
import { sendOTP } from "../../actions/login";
import { storePhoneNumber } from "../../actions/storingUserData";
import { storeCountriesList } from "../../actions/countryList";
import { loadCharityNames } from "../../actions/CharityList";

//css imports
import {
  Form,
  Button,
  DropdownButton,
  Dropdown,
  FormControl,
} from "react-bootstrap";
// import "../../assets/scss/Login/nexmo.scss";
import "../../assets/scss/Login/loginPage.scss";
// import '../../dummy.scss';

import dropdownButtonArrow from "../../assets/svg/dropdownButtonArrow.svg";
import Loader from "../Loader";

import { useTranslation } from "react-i18next";

const Nexmo = (props) => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // console.log(props)
  const [iso, setIso] = useState("Choose"); // stores only country code
  const [number, setNumber] = useState(""); //stores mobile number
  const [message, setMessage] = useState(""); //message for valid or invalid number
  const [valid, setValid] = useState(true); // for submit button
  const [state, setState] = useState(false); //to redirect to the next page
  // const [countryLists, setCountryLists] = useState([]);
  const dispatch = useDispatch();

  const countryLists = useSelector((state) => state.countriesList.data);

  // const [names, setNames] = useState([]);
  // const [flags, setFlags] = useState("");
  const [search, setSearch] = useState("");
  // const [countryName, setCountryName] = useState("choose");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
    setIso(`${t("login.choose")}`);
  }, [language]);
  //---------//

  /************************************************************************************************** */
  //fetch user country from IP address
  useEffect(() => {
    if (!countryLists) {
      dispatch(storeCountriesList());
      dispatch(loadCharityNames());
    }
  }, []);

  // const handleFlag = async (e) => {
  //   const response = await axios.get("https://restcountries.eu/rest/v2/all");
  //   const result = response.data;
  //   setNames(result);
  //   // setFlags(result[0].flag);
  //   // console.log(result);
  // }
  /************************************************************************************************** */

  const onNumberChange = (e) => {
    setNumber(e.target.value);
    validatePhoneNumber(`${iso}${e.target.value}`);
  };

  const onSelect = (e) => {
    // console.log(e.target.value)
    setIso(e);
    setValid(true);
    setNumber("");
    setMessage("");
    setSearch("");
  };

  const validatePhoneNumber = (phoneNumber) => {
    // console.log(phoneNumber)
    let checkPhoneNumber = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance(); //The type of the phone returned after a string number has been parsed via PhoneNumberUtil.parse()
      checkPhoneNumber = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      checkPhoneNumber = false;
    }
    if (checkPhoneNumber && iso) {
      setMessage("");
      getValidNumber(phoneNumber);
      setValid(false);
    } else if (iso == "Choose") {
      setMessage("Please select country");
    }
    else {
      setMessage("Please enter a valid phone number");
      setValid(true);
    }
  };

  function getValidNumber(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL); //PhoneNumberFormat.INTERNATIONAL gives the number in international format eg:- 11 111 1111111
  }

  const handleSubmit = (e, iso, number) => {
    e.preventDefault();
    let phone = `${iso}${number}`;
    // console.log(phone);
    dispatch(sendOTP(phone));
    setState(true);
    dispatch(storePhoneNumber(phone));
  };

  const path = props.location.pathname; //gives current path

  const errorInput = message ? "error-input" : "";

  const linkDisabled = valid ? "grey-disableLink" : "blue-activeLink";

  /*************************************** */

  // const onFlagSelect = (countryData) => {
  //   let data = countryData;
  //   console.log(data);
  //   let arr = data.split(',');
  //   console.log(arr);
  //   let arrFirstItem = arr[0];
  //   let arrSecondItem = arr[1];
  //   // console.log(typeof arrSecondItem);
  //   // setCountryName(arrFirstItem);
  //   setIso(arrFirstItem);
  //   setFlags(arrSecondItem);
  //   setSearch("");
  //   // console.log(countryData);
  // }

  // const handleSearch = (e) => {
  //   setSearch(e.target.value)
  // }

  if (!countryLists) return <Loader />;

  const filteredCountries =
    countryLists &&
    countryLists.filter((countryName) => {
      return (
        countryName.country_name.toLowerCase().indexOf(search.toLowerCase()) !==
        -1
      );
    });
  /*************************************** */
  return (
    <div className="mb-2 mb-lg-3 nexmo-login">
      <Form onSubmit={(e) => handleSubmit(e, iso, number)} autoComplete="off">
        <div className={`country-mobile-number ${errorInput}`}>
          {/* <Form.Control
            className="dropdown-button mr-2 "
            as="select"
            value={iso}
            onChange={onSelect}
          >
            <option className="option-item" value="" disabled hidden>
              Country
          </option>

            {countryLists.map((countryData) => (

              < option key={countryData.country_id} value={countryData.iso_code} >
                { countryData.name}
              </option>
            ))}


          </Form.Control> */}

          {/* <div className="parentFlagToggleButton">
            <img className="img-fluid" src={dropdownButtonArrow} alt="dropdownButtonArrow" />

            <DropdownButton className="flagButton" title={<span>{flags && <img src={flags} width="20" height="20" style={{ marginRight: "1rem" }} />}{`${iso}`}</span>} onSelect={onFlagSelect}>


              <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Country"
                onChange={handleSearch}
                value={search}
              />

          <div className="flagList">
            {filteredCountries.map((countryName) => (
              < Dropdown.Item key={countryName.name} eventKey={[countryName.callingCodes[0], countryName.flag]} >
                <img src={countryName.flag} width="20" height="20" />
                <span style={{ color: "#495057", paddingLeft: "0.5rem" }}>{countryName.name}</span>
                <span> ( {countryName.callingCodes[0]} )</span>
              </Dropdown.Item>
            ))}
          </div>


            </DropdownButton>

          </div> */}

          <div className="parentFlagToggleButton">
            <img
              className="img-fluid"
              src={dropdownButtonArrow}
              alt="dropdownButtonArrow"
            />

            <DropdownButton
              className="flagButton"
              title={<span>{`${iso}`}</span>}
              onSelect={onSelect}
              disabled={props.cookiesNotAccepted ? true : false}
            >
              <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder={t("login.country")}
                // onChange={handleSearch}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />

              <div className="flagList">
                {filteredCountries.map((countryName) => (
                  <Dropdown.Item
                    key={countryName.country_name}
                    eventKey={`+${countryName.country_phone_code}`}
                  >
                    {/* <img src={countryName.flag} width="20" height="20" /> */}
                    <span style={{ color: "#495057", paddingLeft: "0.5rem" }}>
                      {countryName.country_name}
                    </span>
                    <span> ( {`+${countryName.country_phone_code}`} )</span>
                  </Dropdown.Item>
                ))}
              </div>
            </DropdownButton>
          </div>

          <Form.Control
            className={`nexmo-input-mobile`}
            type="number"
            value={number}
            onChange={onNumberChange}
            placeholder={t("login.numberFieldPlaceholder")}
            required
            disabled={props.cookiesNotAccepted ? true : false}
          />
        </div>
        {message === "" ? "" : <div className="error-message">{message}</div>}

        <Button
          className="mt-4 blue-buttonActive"
          type="submit"
          disabled={valid}
          style={{ width: "100%" }}
        >
          {t("login.sendOtp")}
        </Button>
      </Form>
      {state ? <Redirect to={{ pathname: "/verify" }} /> : null}
    </div>
  );
};

export default withRouter(Nexmo);
