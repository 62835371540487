import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//stripe element imports
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

//file imports
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import arrowLeft from "../../assets/svg/arrow-left.svg";
import successImage from "../../assets/images/Succesful-animation.gif";
import failImage from "../../assets/images/Payment-failed-animation.gif";
import x from "../../assets/svg/x.svg";

import secureTickMark from "../../assets/svg/secure-tick-mark.svg";
import sheild from "../../assets/svg/sheild.svg";
import stripelogo from "../../assets/svg/stripe.svg";

//css imports
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  InputGroup,
  Alert,
  Image,
} from "react-bootstrap";
import "../../assets/scss/StripePayment/stripePayment.scss";

// import { BASEURL } from "../../config";
import axiosCreateContent from "../../axios/createContentAxios";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import axiosInstance from "../../axios/axios";

import { useTranslation } from "react-i18next";

const CARD_NUMBER_ELEMENT_OPTIONS = {
  showIcon: true, //card icon
  placeholder: "1234 3456 7890 8887",
  style: {
    base: {
      color: "#32325d",
      fontFamily: "DM Sans, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#9E9E9E",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CARD_EXPIRY_ELEMENT_OPTIONS = {
  placeholder: "mm/yy",
  style: {
    base: {
      color: "#32325d",
      fontFamily: "DM Sans, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#757575",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CARD_CVC_ELEMENT_OPTIONS = {
  placeholder: "***",
  style: {
    base: {
      color: "#32325d",
      fontFamily: "DM Sans, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#757575",
      },
    },
  },
};

const BodyStyle = () => {
  useEffect(() => {
    // document.body.classList.add(MODAL_OPEN_CLASS);
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
const CheckoutForm = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const stripe = useStripe();
  const elements = useElements();
  const [processingTo, setProcessingTo] = useState(false);
  const [storePopup, setStorePopup] = useState(false);

  const [cardNumberError, setCardNumberError] = useState();
  const [cardExpiryError, setCardExpiryError] = useState();
  const [cardCvcError, setCardCvcError] = useState();
  const [checkoutError, setCheckoutError] = useState();
  const [badRequest, setBadRequest] = useState(false);
  // const [customer_id, setCustomerId] = useState("cus_Hl6xKfyHWf55vy");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState();
  const [accessToken, setAccessToken] = useState();
  const [userExternalId, setUserExternalId] = useState();
  const [successMessage, setSuccessMessage] = useState(false);
  const [failureMessage, setFailureMessage] = useState();
  const [billingDetails, setBillingDetails] = useState({ name: "" });
  const [paymentMethod, setPaymentMethod] = useState();
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  // const userCountryDetails = useSelector(state => state.countriesList.data)
  const location = useLocation();
  let user_external_id;
  let currencyCode;
  let currencyoftotalPooled;

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    if (location.pathname === "/ios/pay/") {
      const query = new URLSearchParams(location.search);
      // user_external_id = query.get("user_external_id");
      // currencyCode = query.get("currency_sign");
      // currencyoftotalPooled = query.get("currency");
      // accessToken = query.get("accessToken");
      setUserExternalId(query.get("user_external_id"));
      setAccessToken(query.get("accessToken"));
      setAmount(query.get("amount"));
      setCurrency(query.get("currency"));
      console.log(location);
    }
  }, []);

  if (location.pathname === "/pay") {
    user_external_id = userDetails.user_external_id;
    currencyCode = userDetails.country_wallet_currency_sign;
    currencyoftotalPooled = userDetails.currencyoftotalPooled;
  }

  //check min and max load of user
  const handleSetAmount = (e) => {
    // console.log(e.target.value * 1);
    if (e.target.value * 1 < userDetails.country_min_load * 1) {
      // console.log('show')
      e.target.setCustomValidity(
        `${t("paymentGateway.minBalanceUpdateAmt")} ${userDetails.country_min_load
        }.`
      );
      setAmount(e.target.value);
    } else if (e.target.value * 1 > userDetails.country_max_load * 1) {
      e.target.setCustomValidity(
        `${t("paymentGateway.maxBalanceUpdateAmt")} ${userDetails.country_max_load
        }`
      );
      setAmount(e.target.value);
    } else {
      // console.log('hide')
      e.target.setCustomValidity("");
      setAmount(e.target.value);
    }
  };

  const paymentContent = {
    user_external_id,
    amount,
    currency: currencyoftotalPooled,
  };

  const handleCardDetailsChange = (ev) => {
    console.log(ev.error);
    if (ev.error) {
      if (
        ev.error.code === "invalid_number" ||
        ev.error.code === "incomplete_number"
      ) {
        setCardNumberError(ev.error.message);
      } else {
        setCardExpiryError(ev.error.message);
      }
    } else {
      setCardNumberError() || setCardExpiryError();
    }

    // ev.error ? (setCheckoutError(ev.error.message)) : setCheckoutError();
  };
  // const MODAL_OPEN_CLASS = "bodyStyleModal";

  // const addBodyStyleModal = () => {
  //   document.body.classList.add(MODAL_OPEN_CLASS);
  // }
  // const removeBodyStyleModal = () => {
  //   document.body.classList.remove(MODAL_OPEN_CLASS);
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // document.body.classList.add(MODAL_OPEN_CLASS);
    // if (!stripe || !elements) {
    //   // Stripe.js has not yet loaded.
    //   // Make sure to disable form submission until Stripe.js has loaded.
    //   return 'Loading...';
    // }

    // setProcessingTo(true);

    const cardNumberElement = elements.getElement("cardNumber");

    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        console.log(paymentMethodReq.error);
        if (paymentMethodReq.error.code === "incomplete_number") {
          setCardNumberError(paymentMethodReq.error.message);
        } else if (paymentMethodReq.error.code === "incomplete_expiry") {
          setCardExpiryError(paymentMethodReq.error.message);
        } else if (paymentMethodReq.error.code === "incomplete_cvc") {
          setCardCvcError(paymentMethodReq.error.message);
        } else {
          setCheckoutError(paymentMethodReq.error.message);
          setCardCvcError();
        }
        setProcessingTo(false);
        return;
      }
      setPaymentMethod(paymentMethodReq);
      setProcessingTo(true);
      // console.log('i am try from create payment')
    } catch (err) {
      setCheckoutError("createPaymentMethod " + err.message);
      // console.log('i am catch from create payment')
    }
  };
  const handleConfirmation = async () => {
    setLoader(true);
    setDisableButton(true);
    if (location.pathname === "/ios/pay/") {
      try {
        // console.log('i am try from confirm Card Payment')
        const res = await axiosInstance({
          url: `/v1/stripe/payment`,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            userexternalid: `${userExternalId}`,
          },
          data: {
            amount: amount,
            currency: currency,
            user_external_id: userExternalId,
          },
          withCredentials: true,
        });
        const clientSecret = await res.data.response.data.key;
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.paymentMethod.id,
        });
        if (result.error) {
          console.log(result.error.message);
          // setCheckoutError(result.error.message);
          setDisableButton(false);
          setLoader(false);
          // setProcessingTo(false);
          // setSuccessMessage(false);
          setStorePopup(false);
          setFailureMessage(result.error.message);
          return;
        } else {
          // The payment has been processed!
          // console.log("result", result);
          if (result.paymentIntent.status === "succeeded") {
            // alert("Payment Successful");
            // setSuccessMessage(true);
            setStorePopup(true);
            // setTimeout(() => {
            //   setSuccessMessage(true);
            // }, 500);
            // setProcessingTo(false);
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
          }
        }
      } catch (err) {
        // console.log({ err })
        // if (err.response.status === 400) {
        //   setBadRequest(true);
        // }
        if (err) {
          setBadRequest(true);
        }
        setDisableButton(false);
        setLoader(false);
        // setProcessingTo(false);
        // setFailureMessage(err);
        // setCheckoutError("Confirm Card Payment " + err.message);
      }
    } else if (location.pathname === "/pay") {
      try {
        // console.log('i am try from confirm Card Payment')
        const res = await axiosCreateContent({
          url: `/v1/stripe/payment`,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          data: paymentContent,
          withCredentials: true,
        });
        const clientSecret = await res.data.response.data.key;
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.paymentMethod.id,
        });
        if (result.error) {
          console.log(result.error.message);
          // setCheckoutError(result.error.message);
          setDisableButton(false);
          setLoader(false);
          // setProcessingTo(false);
          setSuccessMessage(false);
          setFailureMessage(result.error.message);
          return;
        } else {
          // The payment has been processed!
          // console.log("result", result);
          if (result.paymentIntent.status === "succeeded") {
            // alert("Payment Successful");
            setSuccessMessage(true);
            // setTimeout(() => {
            //   setSuccessMessage(true);
            // }, 500);
            // setProcessingTo(false);
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
          }
        }
      } catch (err) {
        // console.log({ err })

        // if (err.response.status === 400) {
        //   setBadRequest(true);
        // }
        if (err) {
          setBadRequest(true);
        }
        setDisableButton(false);
        setLoader(false);
        // setProcessingTo(false);
        // setFailureMessage(err);
        // setCheckoutError("Confirm Card Payment " + err.message);
      }
    }
  };

  const handleClose = () => {
    setProcessingTo(false);
    setFailureMessage(null);
    setBadRequest(false);
    // document.body.classList.remove(MODAL_OPEN_CLASS);
  };

  const handleModalClose = () => {
    setProcessingTo(false);
    setStorePopup(false);
  };

  const cardNumberInputError = cardNumberError ? "error-input" : "";
  const cardExpiryInputError = cardExpiryError ? "error-input" : "";
  const cardCvcInputError = cardCvcError ? "error-input" : "";

  let styles = processingTo ? { display: "block" } : { display: "none" };
  let stylesSuccessful = successMessage
    ? { display: "block" }
    : { display: "none" };

  let stylesSuccessfulMobile = storePopup
    ? { display: "block" }
    : { display: "none" };
  let styleFailure =
    failureMessage || badRequest ? { display: "block" } : { display: "none" };
  // let bodyStyleModal = processingTo ? "bodyStyleModal" : "";

  return (
    <div style={{ position: "relative" }}>
      {processingTo && <BodyStyle />}
      {/* {successMessage && <BodyStyle />} */}
      {/* {successMessage && <BodyStyle />} */}
      {/* {failureMessage && <BodyStyle />} */}

      <ContentNavBar />
      <section className="stripePayment-content">
        <Container>
          <Row>
            <Col className="back-link mx-auto" sm={12} md={9} lg={9}>
              <Link to="/addbalance" style={{ textDecoration: "none" }}>
                <img src={arrowLeft} alt="arrow-left" />{" "}
                {t("paymentGateway.back")}
              </Link>
            </Col>
            <Col className="mx-auto" sm={12} md={9} lg={9}>
              <div className="stripe-your-pool-page">
                <Row className="stripe-header">
                  <Col>
                    <p>Add Balance to Your Pool</p>
                  </Col>
                </Row>
                <Row className="stripe-body">
                  <Col className="mx-auto" sm={8} md={8} lg={6}>
                    <Form onSubmit={handleSubmit}>
                      <Form.Label>{t("paymentGateway.enterAmount")}</Form.Label>
                      <InputGroup className="">
                        <InputGroup.Prepend>
                          {location.pathname === "/pay" ? (
                            <InputGroup.Text id="amount-addon1">
                              {currencyCode}
                            </InputGroup.Text>
                          ) : (
                              <InputGroup.Text style={{ paddingLeft: "15px" }}>
                                {currency}
                              </InputGroup.Text>
                            )}
                        </InputGroup.Prepend>
                        <FormControl
                          type="number"
                          placeholder="0.00"
                          aria-label="amount"
                          aria-describedby="amount-addon1"
                          aria-required="true"
                          min={`${userDetails.country_min_load}`}
                          max={`${userDetails.country_max_load}`}
                          // min="10"
                          required
                          onChange={(e) => handleSetAmount(e)}
                          value={amount}
                        />
                      </InputGroup>
                      <Form.Label className="mb-2">
                        Choose a payment method
                      </Form.Label>
                      {/* <label className="mb-2">Choose a payment method</label> */}
                      {/* <Form.Check
                      className="mb-4"
                      name="same"
                        type="radio"
                        id="card"
                        label="Bank of America Debit Card"
                        onChange={(e) => console.log("hello")}
                        style={{color: "#212121"}}
                      /> */}

                      <Form.Check
                        className="mb-4"
                        name="same"
                        type="radio"
                        id="card"
                        label={t("paymentGateway.debitOrCreditCard")}
                        defaultChecked
                        style={{ color: "#212121" }}
                      />

                      <div className="stripe-payment-details">
                        {/* <CardElement onChange={handleCardDetailsChange} /> */}
                        <div className="stripe-card-number mb-4">
                          <Form.Label className="mb-2">
                            {t("paymentGateway.cardDetails.cardNumber")}
                          </Form.Label>
                          <div className="stripe-content-style">
                            <CardNumberElement
                              options={CARD_NUMBER_ELEMENT_OPTIONS}
                              onChange={handleCardDetailsChange}
                              className={`stripe-card-element ${cardNumberInputError}`}
                            />
                            <span className="error-message">
                              {cardNumberError}
                            </span>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div className="stripe-card-expiry-date">
                            <Form.Label className="mb-2">
                              {t("paymentGateway.cardDetails.validity")}
                            </Form.Label>
                            <div className="stripe-content-style">
                              <CardExpiryElement
                                options={CARD_EXPIRY_ELEMENT_OPTIONS}
                                onChange={handleCardDetailsChange}
                                className={`stripe-expiry-element ${cardExpiryInputError}`}
                              />
                              <span className="error-message">
                                {cardExpiryError}
                              </span>
                            </div>
                          </div>

                          <div className="stripe-card-cvv">
                            <Form.Label className="mb-2">
                              {t("paymentGateway.cardDetails.cvvCode")}
                            </Form.Label>
                            <div className="stripe-content-style">
                              <CardCvcElement
                                options={CARD_CVC_ELEMENT_OPTIONS}
                                className={`stripe-card-cvv-element ${cardCvcInputError}`}
                              // onChange={handleCardDetailsChange}
                              />
                              <span className="error-message">
                                {cardCvcError}
                              </span>
                            </div>
                          </div>
                        </div>

                        <Form.Group
                          className=" mt-4"
                          controlId="validationFormik01"
                        >
                          <Form.Label>
                            {t("paymentGateway.cardDetails.nameOnCard")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="Name"
                            value={billingDetails.name}
                            onChange={(e) =>
                              setBillingDetails({ name: e.target.value })
                            }
                            aria-required="true"
                            required
                          />
                        </Form.Group>
                        {/*  <Form.Check
                      className="mb-4"
                        type="checkbox"
                        id="card"
                        label="Save card for future payment"
                        onChange={(e) => console.log("hello")}
                        style={{color: "#212121"}}
                      /> */}
                      </div>
                      {checkoutError && (
                        <Alert variant="danger">{checkoutError}</Alert>
                      )}
                      {/* {successMessage && <p className="stripe-success-message">{successMessage}</p>} */}
                      {/* {successMessage && <Redirect to="/home" />} */}

                      <Button
                        className="blue-buttonActive"
                        size="md"
                        variant="primary"
                        type="submit"
                        disabled={processingTo || !stripe}
                      >
                        {processingTo
                          ? "Processing..."
                          : `${t("paymentGateway.proceed")}`}
                      </Button>
                      <Link className="cancel-button" to="/addbalance">
                        {t("paymentGateway.cancel")}
                      </Link>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={secureTickMark} alt="secure" />
                <span>{t("paymentGateway.safePayment")} </span>{" "}
                <img src={stripelogo} alt="stripe" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={sheild} alt="secure" />
                <span style={{ marginRight: "1.5rem" }}>
                  {t("paymentGateway.3dSecure")}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {processingTo && <div className="modal-backdrop"></div>}
      {/* {successMessage && <div className="modal-backdrop"></div>} */}
      {/* {failureMessage && <div className="modal-backdrop"></div>} */}

      <LeanmoreFooter />

      <div id="messageModal" className="modal in" role="dialog" style={styles}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ textAlign: "left" }}>
                {/* Hey there */}
              </h4>
              <button type="button" onClick={handleClose} className="close">
                <img src={x} alt="close-button" style={{ width: "100%" }} />
              </button>
            </div>
            <div className="modal-body">
              <p>{t("paymentGateway.infoTextForAddingMoney")}</p>
            </div>
            <div className="modal-footer">
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={disableButton}
              >
                {t("paymentGateway.cancel")}
              </Button>
              <Button
                variant="primary"
                className="buttonActive"
                disabled={disableButton}
                onClick={handleConfirmation}
              >
                {loader
                  ? `${t("paymentGateway.loading")}`
                  : `${t("paymentGateway.positiveAcknowledgement")}`}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mySuccesfullModal"
        className="modal in message-modal-container"
        role="dialog"
        style={stylesSuccessful}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {localStorage.getItem("content_external_id") !== "undefined" &&
                navigator.userAgent.match(/Android|iPhone/i) ? (
                  <Link
                    type="button"
                    className="close"
                    to={`/share/${localStorage.getItem("content_external_id")}`}
                    style={{
                      position: "absolute",
                      right: "20px",
                    }}
                  >
                    <img src={x} alt="close-button" style={{ width: "100%" }} />
                  </Link>
                ) : (
                  <Link
                    type="button"
                    className="close"
                    to="/addbalance"
                    style={{
                      position: "absolute",
                      right: "20px",
                    }}
                  >
                    <img src={x} alt="close-button" style={{ width: "100%" }} />
                  </Link>
                )}
              <Image src={successImage} />
            </div>
            <div className="confirm-message">
              <div className="modal-body">
                <h4 className="modal-title">
                  {t("paymentGateway.paymentSuccessful")}
                </h4>
                <p>{t("paymentGateway.paymentSuccessfulText")}</p>
              </div>
              <div className="modal-footer">
                {localStorage.getItem("content_external_id") !== "undefined" &&
                  navigator.userAgent.match(/Android|iPhone/i) ? (
                    <Button
                      variant="primary"
                      className="blue-activeLink"
                      href={`/share/${localStorage.getItem(
                        "content_external_id"
                      )}`}
                    >
                      {t("paymentGateway.startPooling")}
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="blue-activeLink"
                      href="/addbalance"
                    >
                      {t("paymentGateway.startPooling")}
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mySuccesfullModal"
        className="modal in message-modal-container"
        role="dialog"
        style={stylesSuccessfulMobile}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span
                type="button"
                className="close"
                onClick={handleModalClose}
                style={{
                  position: "absolute",
                  right: "20px",
                }}
              >
                <img src={x} alt="close-button" style={{ width: "100%" }} />
              </span>
              <Image src={successImage} />
            </div>
            <div className="confirm-message">
              <div className="modal-body">
                <h4 className="modal-title">
                  {t("paymentGateway.paymentSuccessful")}
                </h4>
                <p>{t("paymentGateway.paymentSuccessfulText")}</p>
              </div>
              {/* <div className="modal-footer">
                <Button
                  variant="primary"
                  className="blue-activeLink"
                  href="/addbalance"
                >
                  Start Pooling
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        id="failureModal"
        className="modal in message-modal-container"
        role="dialog"
        style={styleFailure}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <Button
                type="button"
                className="close"
                onClick={handleClose}
                style={{
                  position: "absolute",
                  right: "20px",
                }}
              >
                <img src={x} alt="close-button" style={{ width: "100%" }} />
              </Button>
              <Image src={failImage} />
            </div>
            <div className="confirm-message">
              <div className="modal-body">
                <h4 className="modal-title">
                  {t("paymentGateway.paymentFailure")}
                </h4>
                <p>
                  {failureMessage ? (
                    failureMessage
                  ) : (
                      <span>Something went wrong</span>
                    )}
                </p>
                {/* <p>Please try again</p> */}
                <p>{t("paymentGateway.paymentFailureText")}</p>
              </div>
              <div className="modal-footer">
                <Button
                  variant="primary"
                  className="blue-buttonActive"
                  onClick={handleClose}
                >
                  Try again
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
