import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { BASEURL } from '../../config'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosCreateContent from "../../axios/createContentAxios";

import ContentNavBar from "../ContentNavbar/ContentNavbar";
import arrowLeft from "../../assets/svg/arrow-left.svg";
import x from "../../assets/svg/x.svg";
import "../../assets/scss/StripePayment/stripePayment.scss";

import secureTickMark from "../../assets/svg/secure-tick-mark.svg";
import sheild from "../../assets/svg/sheild.svg";
import rapydlogo from "../../assets/svg/rapyd.svg";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

import { useTranslation } from "react-i18next";

const BodyStyle = () => {
  useEffect(() => {
    // document.body.classList.add(MODAL_OPEN_CLASS);
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};

const RapydCheckout = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [processingTo, setProcessingTo] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  // const userCountryDetails = useSelector(state => state.countriesList.data)

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  //API Call
  const handleSubmit = (e, amount) => {
    e.preventDefault();
    setProcessingTo(true);
    axiosCreateContent({
      url: `/v1/rapyd/checkout`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        // 'Authorization': `Bearer ${tokenDetails.token}`,
        // 'userexternalid': tokenDetails.user_external_id,
      },
      data: {
        amount: amount,
      },
      withCredentials: true,
    })
      .then((res) => {
        setRedirectUrl(res.data.response.data.redirectUrl);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  //check min and max load of user
  const handleSetAmount = (e) => {
    // console.log(e.target.value * 1);
    if (e.target.value * 1 < userDetails.country_min_load * 1) {
      // console.log('show')
      e.target.setCustomValidity(
        `${t("paymentGateway.minBalanceUpdateAmt")} ${userDetails.country_min_load
        }.`
      );
      setAmount(e.target.value);
    } else if (e.target.value * 1 > userDetails.country_max_load * 1) {
      e.target.setCustomValidity(
        `${t("paymentGateway.maxBalanceUpdateAmt")} ${userDetails.country_max_load
        }`
      );
      setAmount(e.target.value);
    } else {
      // console.log('hide')
      e.target.setCustomValidity("");
      setAmount(e.target.value);
    }
  };

  const handleClose = () => {
    setProcessingTo(false);
    setOpen(false);
  };

  //Displaying the approriate currency symbol based on the users country

  let styles = open ? { display: "block" } : { display: "none" };

  return (
    <div style={{ position: "relative" }}>
      {processingTo && <BodyStyle />}
      {processingTo && <div className="modal-backdrop"></div>}

      <ContentNavBar />
      <section className="stripePayment-content">
        <Container>
          <Row>
            <Col className="back-link mx-auto" sm={12} md={9} lg={9}>
              <Link to="/addbalance" style={{ textDecoration: "none" }}>
                <img src={arrowLeft} alt="arrow-left" />{" "}
                {t("paymentGateway.back")}
              </Link>
            </Col>
            <Col className="mx-auto" sm={12} md={9} lg={9}>
              <div className="stripe-your-pool-page">
                <Row className="stripe-header">
                  <Col>
                    <p>Add Balance to Your Pool</p>
                  </Col>
                </Row>
                <Row className="stripe-body">
                  <Col className="mx-auto" sm={8} md={8} lg={6}>
                    <Form onSubmit={(e) => handleSubmit(e, amount)}>
                      <Form.Label>{t("paymentGateway.enterAmount")}</Form.Label>
                      <InputGroup className="">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="amount-addon1">
                            {userDetails.country_wallet_currency_sign}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        {/* {userCountryDetails.map((country_details) => {
                          if (country_details.country_external_id === userDetails.country_external_id) {
                            return (
                              <FormControl
                                type="number"
                                placeholder="0.00"
                                aria-label="amount"
                                aria-describedby="amount-addon1"
                                aria-required="true"
                                required
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                min={`${userDetails.country_min_load}`}
                                max={`${userDetails.country_max_load}`}
                              />
                            )
                          }
                        })} */}
                        <FormControl
                          type="number"
                          placeholder="0.00"
                          aria-label="amount"
                          aria-describedby="amount-addon1"
                          aria-required="true"
                          required
                          onChange={(e) => handleSetAmount(e)}
                          // onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                          min={`${userDetails.country_min_load}`}
                          max={`${userDetails.country_max_load}`}
                        />
                      </InputGroup>
                      <Button
                        className="blue-buttonActive"
                        size="md"
                        variant="primary"
                        type="submit"
                        disabled={!amount}
                      >
                        {processingTo
                          ? "Processing..."
                          : `${t("paymentGateway.proceed")}`}
                      </Button>
                      <Link className="cancel-button" to="/addbalance">
                        {t("paymentGateway.cancel")}
                      </Link>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={secureTickMark} alt="secure" />
                <span>{t("paymentGateway.safePayment")}</span>
                <img src={rapydlogo} alt="stripe" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={sheild} alt="secure" />
                <span style={{ marginRight: "2.8rem" }}>
                  {t("paymentGateway.3dSecure")}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <LeanmoreFooter />

      <div id="messageModal" className="modal in" role="dialog" style={styles}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ textAlign: "left" }}>
                {/* Hey there */}
              </h4>
              <button type="button" className="close" onClick={handleClose}>
                <img src={x} alt="close-button" style={{ width: "100%" }} />
              </button>
            </div>
            <div className="modal-body">
              <p>{t("paymentGateway.infoTextForAddingMoney")}</p>
            </div>
            <div className="modal-footer">
              <Link className="btn btn-secondary" to="/addbalance">
                {t("paymentGateway.cancel")}
              </Link>

              {/* <Button variant="primary" className="buttonActive">
                {loader ? "Loading..." : `Yes, I'm sure`}
              </Button> */}
              <a href={redirectUrl} className="btn btn-primary">
                {t("paymentGateway.positiveAcknowledgement")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <dialog open={error}>
        <p>
          There seems to be a problem. Don't worry your money has not been
          deducted yet.!
        </p>
      </dialog>
    </div>
  );
};

export default RapydCheckout;

// Instantiating ‘checkout’ with parametersInstantiating 'checkout' & setting variables
// let checkout = new window.RapydCheckoutToolkit({
//     pay_button_text: "Click to pay",
//     // Text that appears on the 'Pay' button.
//     // String. Maximum length is 16 characters.
//     // Default is "Place Your Order". Optional.
//     pay_button_color: "blue",
//     // Color of the 'Pay' button. String.
//     // Standard CSS color name or hexadecimal code such as #323fff.
//     // Default is the color that is returned in the 'merchant_color'
//     // field of the response to 'Create Checkout Page'. Optional.
//     id: "checkout_8bfd04c35ff3204a15adda915ed9ee61",
//     // ID of the 'Create Checkout Page' response. String. Required.
//     close_on_complete: true,
//     // Causes the embedded Rapyd Checkout Toolkit window to close
//     // when the payment is complete. Boolean. Default is 'true'. Optional.
//     page_type: 'collection',
//     // Default is "collection". Optional.
// });

// window.addEventListener('checkoutOnSuccess', (event) => {
//     console.log(event.detail)
//     console.log("rapyd")
//     // Returns 'Payment' object.
//     // Client code.
// })

// window.addEventListener('checkoutOnFailed', (event) => {
//     console.error(event.detail.error)
//     // Returns an error message from the API.
//     // Client code.
// })

// window.addEventListener('checkoutOnLoading', (event) => {
//     console.error(event.detail.error)
//     // Client code. Returns true or false depending on the loading state
// })
