import axios from "axios";
import { BASEURL } from "../config";

export const MostPooledApi = (cause_external_id, date, country_external_id) => {
  return axios({
    url: `${BASEURL}/v1/contents/mostpooled`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      cause_external_id,
      data: date,
      country_external_id,
    },
  })
    .then((res) => res.data.response)
    .catch((err) => console.error(err));
};

export const MostPooledDefault = () => {
  return axios({
    url: `${BASEURL}/v1/contents/mostpooled`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data.response)
    .catch((err) => console.error(err));
};

export const MostPooledForCountry = (data, country_external_id) => {
  return axios({
    url: `${BASEURL}/v1/contents/mostpooled`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      data,
      country_external_id,
    },
  })
    .then((res) => res.data.response)
    .catch((err) => console.error(err));
};

export const MostPooledDuration = (data) => {
  return axios({
    url: `${BASEURL}/v1/contents/mostpooled`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      data,
    },
  })
    .then((res) => res.data.response)
    .catch((err) => console.error(err));
};
