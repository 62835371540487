import { useState, useEffect } from "react";
import { BASEURL } from "../../config";
import axios from "axios";

export default function useIncomingPagination(pageNumber, type, fromDate, toDate) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentsArray, setContentsArray] = useState([]);
  const [hasMore, setHasMore] = useState(false);


  useEffect(() => {
    setContentsArray([]);
  }, [type, toDate]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    // let cancel;
    if (fromDate && toDate) {
      axios({
        method: "GET",
        url: `${BASEURL}/v1/incomingAndOutgoingTransactions`,
        params: {
          type: type,
          pageNo: pageNumber,
          // from_date: "2020-12-31T00:00:00.000Z",
          // to_date: "2021-01-12T18:00:00.000Z"
          from_date: fromDate,
          to_date: toDate
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          // 'Authorization': `Bearer ${token}`,
          // 'userexternalid': external_Id
        },
        withCredentials: true,
      })
        .then((response) => {
          setLoading(true);
          console.log(response);
          // console.log('Running UseHashTags')
          setContentsArray((prevContentsArray) => {
            // return [...prevContentsArray, ...response.data.response.data]
            if (response.data.response.statusCode !== 200) {
              return [...prevContentsArray];
            } else {
              return [...prevContentsArray, ...response.data.response.data];
            }
          });
          setHasMore(response.data.response.data.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
    } else {
      axios({
        method: "GET",
        url: `${BASEURL}/v1/incomingAndOutgoingTransactions`,
        params: {
          type: type,
          pageNo: pageNumber,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          // 'Authorization': `Bearer ${token}`,
          // 'userexternalid': external_Id
        },
        withCredentials: true,
      })
        .then((response) => {
          setLoading(true);
          console.log(response);
          // console.log('Running UseHashTags')
          setContentsArray((prevContentsArray) => {
            // return [...prevContentsArray, ...response.data.response.data]

            if (response.data.response.statusCode !== 200) {
              return [...prevContentsArray];
            } else {
              return [...prevContentsArray, ...response.data.response.data];
            }
          });
          setHasMore(response.data.response.data.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
    }

    // return () => cancel();
  }, [pageNumber, type, toDate]);
  return { loading, error, contentsArray, hasMore };
}
