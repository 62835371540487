import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import whiteBrandLogo from "../../assets/svg/white-brandLogo.svg";
import { getPaymentOrderId } from "../../api/RazorpayApi";
import { verifyPayment } from "../../actions/razorPay_action";
import "../../assets/scss/StripePayment/stripePayment.scss";
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import arrowLeft from "../../assets/svg/arrow-left.svg";
import x from "../../assets/svg/x.svg";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
// import PayByRazorPay from "./PayByRazorPay";

import secureTickMark from "../../assets/svg/secure-tick-mark.svg";
import sheild from "../../assets/svg/sheild.svg";
import Razorpay_logo from "../../assets/svg/Razorpay_logo.svg";

import { useTranslation } from "react-i18next";

const BodyStyle = () => {
  useEffect(() => {
    // document.body.classList.add(MODAL_OPEN_CLASS);
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
const RazorpayCheckout = () => {
  let externalId = `${localStorage.getItem("user_external_id")}`;
  const [processingTo, setProcessingTo] = useState(false);
  const [amount, setAmount] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [currency, setCurrency] = useState("INR");
  const [receipt, setReceipt] = useState(externalId);
  const [isredirect, setIsredirect] = useState(false);
  const VerifiedPaymentStatus = useSelector((state) => state.verifiedPayment);
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const history = useHistory();

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  const handleSubmit = (e, amount) => {
    e.preventDefault();
    setProcessingTo(true);
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  };

  const handleSetAmount = (e) => {
    // console.log(e.target.value * 1);
    if (e.target.value * 1 < userDetails.country_min_load * 1) {
      // console.log('show')
      e.target.setCustomValidity(
        `${t("paymentGateway.minBalanceUpdateAmt")} ${userDetails.country_min_load
        }.`
      );
      setAmount(e.target.value);
    } else if (e.target.value * 1 > userDetails.country_max_load * 1) {
      e.target.setCustomValidity(
        `${t("paymentGateway.maxBalanceUpdateAmt")} ${userDetails.country_max_load
        }`
      );
      setAmount(e.target.value);
    } else {
      // console.log('hide')
      e.target.setCustomValidity("");
      setAmount(e.target.value);
    }
  };

  const handleClose = () => {
    setProcessingTo(false);
    setOpen(false);
  };
  const openPayModal = async () => {
    let order = await getPaymentOrderId(amount, currency, receipt);
    setOpen(false);
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: order.response ? order.response.data.amount : null, //  = INR 1
      name: "GoPoolit",
      description: "Social media for good",
      order_id: order.response ? order.response.data.id : null,

      // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
      image: { whiteBrandLogo },
      handler: function (response) {
        dispatch(
          verifyPayment(
            order.response ? order.response.data.id : null,
            response.razorpay_payment_id,
            response.razorpay_signature
          )
        );
        setIsredirect(true);
        setProcessingTo(false);
        // if (VerifiedPaymentStatus.statusCode === 200) {
        //     setIsredirect(true);
        //     setProcessingTo(false);
        // }
        // else if (VerifiedPaymentStatus.statusCode === 409) {
        //     setError(true);
        //     setIsredirect(true);
        //     setProcessingTo(false);
        // }
      },
      modal: {
        ondismiss: function () {
          setProcessingTo(false);
          setIsredirect(true);
        },
      },

      prefill: {
        // name: userDetails.username,
        contact: userDetails.user_mobile_number,
        email: userDetails.user_email_address,
      },
      notes: {
        address: "some address",
      },
      theme: {
        color: "#007bff",
        // hide_topbar: false
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  let styles = open ? { display: "block" } : { display: "none" };
  return (
    <div style={{ position: "relative" }}>
      {processingTo && <BodyStyle />}
      {processingTo && <div className="modal-backdrop"></div>}

      <ContentNavBar />
      <section className="stripePayment-content">
        <Container>
          <Row>
            <Col className="back-link mx-auto" sm={12} md={9} lg={9}>
              <Link to="/addbalance" style={{ textDecoration: "none" }}>
                <img src={arrowLeft} alt="arrow-left" />{" "}
                {t("paymentGateway.back")}
              </Link>
            </Col>
            <Col className="mx-auto" sm={12} md={9} lg={9}>
              <div className="stripe-your-pool-page">
                <Row className="stripe-header">
                  <Col>
                    <p>Add Balance to Your Pool</p>
                  </Col>
                </Row>
                <Row className="stripe-body">
                  <Col className="mx-auto" sm={8} md={8} lg={6}>
                    <Form onSubmit={(e) => handleSubmit(e, amount)}>
                      <Form.Label>{t("paymentGateway.enterAmount")}</Form.Label>
                      <InputGroup className="">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="amount-addon1">
                            {userDetails.country_wallet_currency_sign}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        {/* {userCountryDetails.map((country_details) => {
                          if (country_details.country_external_id === userDetails.country_external_id) {
                            return (
                              <FormControl
                                type="number"
                                placeholder="0.00"
                                aria-label="amount"
                                aria-describedby="amount-addon1"
                                aria-required="true"
                                required
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                min={`${userDetails.country_min_load}`}
                                max={`${userDetails.country_max_load}`}
                              />
                            )
                          }
                        })} */}
                        <FormControl
                          type="number"
                          placeholder="0.00"
                          aria-label="amount"
                          aria-describedby="amount-addon1"
                          aria-required="true"
                          required
                          // onChange={(e) => setAmount(e.target.value)}
                          onChange={(e) => handleSetAmount(e)}
                          value={amount}
                          min={`${userDetails.country_min_load}`}
                          max={`${userDetails.country_max_load}`}
                        />
                      </InputGroup>
                      <Button
                        className="blue-buttonActive"
                        size="md"
                        variant="primary"
                        type="submit"
                        disabled={!amount}
                      >
                        {processingTo
                          ? "Processing..."
                          : `${t("paymentGateway.proceed")}`}
                      </Button>
                      <Link className="cancel-button" to="/addbalance">
                        {t("paymentGateway.cancel")}
                      </Link>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={secureTickMark} alt="secure" />
                <span>{t("paymentGateway.safePayment")}</span>{" "}
                <img src={Razorpay_logo} alt="stripe" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={sheild} alt="secure" />
                <span style={{ marginRight: "4rem" }}>
                  {t("paymentGateway.3dSecure")}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <LeanmoreFooter />

      <div id="messageModal" className="modal in" role="dialog" style={styles}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ textAlign: "left" }}>
                {/* Hey there */}
              </h4>
              <button type="button" className="close" onClick={handleClose}>
                <img src={x} alt="close-button" style={{ width: "100%" }} />
              </button>
            </div>
            <div className="modal-body">
              <p>{t("paymentGateway.infoTextForAddingMoney")}</p>
            </div>
            <div className="modal-footer">
              <Link className="btn btn-secondary" to="/addbalance">
                {t("paymentGateway.cancel")}
              </Link>

              {/* <Button variant="primary" className="buttonActive">
                {loader ? "Loading..." : `Yes, I'm sure`}
              </Button> */}
              <p className="btn btn-primary" onClick={openPayModal}>
                {t("paymentGateway.positiveAcknowledgement")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isredirect ? (
        localStorage.getItem("content_external_id") !== "undefined" &&
          navigator.userAgent.match(/Android|iPhone/i) ? (
            <Redirect
              to={{
                pathname: `/share/${localStorage.getItem("content_external_id")}`,
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/addbalance",
              }}
            />
          )
      ) : (
          ""
        )}
      <dialog open={error}>
        <p>
          There seems to be a problem. Don't worry your money has not been
          deducted yet.!
        </p>
      </dialog>
    </div>
  );
};

export default RazorpayCheckout;
