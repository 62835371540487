import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//File Imports
import ContentNavbar from "../ContentNavbar/ContentNavbar";
import avatar from "../../assets/images/avatar.png";
//CSS Imports
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/scss/Notification/notification.scss";
//import { GetNotification } from '../../api/NotificationApi';
import data from "./notifications_data.json";
import { useTranslation } from "react-i18next";

const Notification = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  // useEffect(() => {
  //     async function fetchNotification() {
  //         let res = await GetNotification();
  //         console.log("notres", res);
  //     }
  //     fetchNotification();
  // }, [])
  return (
    <div className="notification-wrapper">
      <ContentNavbar />
      <Container className="notification-container">
        <Row>
          <Col md={9} className="mx-auto">
            <p className="notification-header">{t("notifications.title")}</p>
            <div className="notification-list">
              {data.map((item, index) => {
                return (
                  <React.Fragment>
                    <div key={index} className="notification-item">
                      <img
                        src={avatar}
                        alt="profile"
                        className="notification-profile"
                      />
                      <div className="notification-details">
                        <p>
                          <span> {item.username} </span>{" "}
                          {t("notifications.and")}{" "}
                          <span>
                            {" "}
                            {item.others_pooled} {t("notifications.others")}{" "}
                          </span>{" "}
                          {t("notifications.pooledToPost")}
                        </p>
                        <p>{item.time} ago</p>
                      </div>
                      <img
                        src={item.post}
                        alt="profile-photo"
                        className="notification-post"
                      />
                    </div>
                    <hr />
                  </React.Fragment>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notification;
