import axios from 'axios'
import { BASEURL } from '../config'


export const userTaggedCauseList = (userExternalId) => {
    return axios({
        method: 'GET',
        url: `${BASEURL}/v1/users/tagged-causes/${userExternalId}`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
    })
        .then(response => response.data.response)
        .catch(e => {
            console.error(e)
        })
}

