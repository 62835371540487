import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const LoginUserPrivateRoute = ({ component: Component, ...rest }) => {

    const LoggedInUserId = useSelector(state => state.userDetails.user_external_id)
    if (navigator.userAgent.match(/Android|iPhone|iPad/i)) {
        return (
            <Route
                {...rest}
                render={props => LoggedInUserId ? (<Component {...props} />) : (<Redirect to="/login" />)}
            />
        )
    } else {
        return (
            <Route
                {...rest}
                render={props => LoggedInUserId ? (<Component {...props} />) : (<Redirect to="/login" />)}
            />
        )
    }

}

export default LoginUserPrivateRoute