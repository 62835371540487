import React, { useState, useEffect } from "react";
import { getFollowersList } from "../../actions/follower_action";
import { FollowUser } from "../../api/FollowUserAPI";
import { getFollowingList } from "../../actions/following_action";
import { UnfollowUser } from "../../api/UnFollowUserAPI";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Image } from "react-bootstrap";
import Loader from "../Loader";
import "../../assets/scss/UserProfile/follow.scss";
import search from "../../assets/svg/search.svg";
import avatar from "../../assets/images/avatar.png";
import follower1 from "../../assets/images/follower1.png";
// const searchSvg = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M6.33333 11.6667C9.27885 11.6667 11.6667 9.27885 11.6667 6.33333C11.6667 3.38781 9.27885 1 6.33333 1C3.38781 1 1 3.38781 1 6.33333C1 9.27885 3.38781 11.6667 6.33333 11.6667Z" stroke="#9E9E9E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
// </svg>
import { useHistory } from "react-router-dom";
import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";
import { useTranslation } from "react-i18next";

const OtherUserProfileFollowers = (props) => {
  const userDetails = useSelector((state) => state.userDetails);
  const followersDetails = useSelector((state) => state.followersList.data);
  const [searchValue, setSearchValue] = useState("");
  const [searchResponseMsg, setSearchResponseMsg] = useState("");
  const [SearchArray, setSearchArray] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const lowerCased = searchValue.toLowerCase();
    let searchFilter = followersDetails.filter((item) =>
      item.username.toLowerCase().startsWith(lowerCased)
    );
    setSearchArray(searchFilter);
  }, [searchValue, followersDetails]);

  const followUnFollowFunction = async (
    e,
    user_following_externalId,
    index,
    flag
  ) => {
    e.preventDefault();
    if (flag === true) {
      await UnfollowUser(user_following_externalId);
      dispatch(getFollowersList(props.otherUserExternalId));
      dispatch(getFollowingList(props.otherUserExternalId));
    }
    if (flag === false) {
      await FollowUser(user_following_externalId);
      dispatch(getFollowersList(props.otherUserExternalId));
      dispatch(getFollowingList(props.otherUserExternalId));
    }
  };

  const handleOtherUser = (otherUserExternalId) => {
    if (userDetails.user_external_id !== otherUserExternalId) {
      history.push({
        pathname: "/otheruserprofile",
        state: { otherUserExternalId: otherUserExternalId },
      });
      // history.push("/otheruserprofile");
      dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
    }
  };

  if (!followersDetails) return <Loader />;
  let followersList = null;
  if (SearchArray.length === 0) {
    followersList = null;
  } else {
    followersList = SearchArray.map((item, index) => {
      return (
        <div>
          <div className="followers-following-card">
            <div className="followers-following-image-username">
              {/* <span className="image-display-pic"> */}
              <span>
                <Image
                  className="image"
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`}
                  roundedCircle
                  style={{ width: "51px", height: "51px", cursor: "pointer" }}
                  onClick={() => handleOtherUser(item.user_external_id)}
                />
              </span>

              {/* </span> */}

              <div style={{ display: "inline-block" }}>
                <p className="user-caption">{item.username}</p>
                <p className="user-Name">{item.user_full_name}</p>
              </div>
            </div>
            {item.user_external_id !== userDetails.user_external_id ? (
              item.is_return_follow ? (
                <Button
                  className="button-following"
                  onClick={(e) => {
                    followUnFollowFunction(
                      e,
                      item.user_external_id,
                      index,
                      item.is_return_follow
                    );
                  }}
                >
                  <span className="button-name">{t("profile.following")}</span>
                </Button>
              ) : (
                <Button
                  className="button-follow"
                  style={{ backgroundColor: "#2077B1", width: "102px" }}
                  onClick={(e) => {
                    followUnFollowFunction(
                      e,
                      item.user_external_id,
                      index,
                      item.is_return_follow
                    );
                  }}
                >
                  <span className="button-name-follow">
                    {t("profile.follow")}
                  </span>
                </Button>
              )
            ) : null}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {SearchArray.length ? (
          <span className="followers">
            {SearchArray.length} {t("profile.followers")}
          </span>
        ) : (
          <span className="followers">0 {t("profile.followers")}</span>
        )}
        <span className="search-people">
          <img src={search} />
          <span>{searchResponseMsg}</span>
          <input
            type="text"
            className="search-people-text"
            placeholder="Search with Username"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </span>
      </div>
      <Card style={{ padding: "1rem" }}>{followersList}</Card>
    </>
  );
};
export default OtherUserProfileFollowers;
