import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector } from "react-redux";
import { Navbar, Nav, NavLink } from "react-bootstrap";
// import { FaLinkedinIn } from "react-icons/fa";
// import { FiMail } from "react-icons/fi";

import brandLogo from "../../assets/svg/brandLogo.svg";
import profilePlaceholder from "../../assets/images/profile_placeholder.png";

// import Matt from "../../assets/images/people/Matt Turner.png";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

import data from "./Data.json";
import "../../assets/scss/People/People.scss";

const People = () => {
  const [targetMenu, setTargetMenu] = useState("1");

  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  let menuRefs = useRef([createRef(), createRef(), createRef()]);

  const menuList = [
    { text: "Founders", id: "1" },
    { text: "Communications & Marketing", id: "2" },
    { text: "Business Development", id: "3" },
  ];

  useEffect(() => {
    menuRefs.current[0].current.style.color = "#2077B1";
    menuRefs.current[0].current.style.borderBottom = "2px solid #2077B1";
  }, []);

  const changebtnColor = (buttonId) => {
    const current = menuRefs.current[buttonId - 1].current;
    setTargetMenu(buttonId);

    let siblings = [];
    let sibling = current.parentNode.firstChild;

    // console.log("buttonId: " + buttonId);
    // console.log(current);
    // console.log(sibling);

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== current) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }

    // console.log(siblings);

    siblings.forEach((sibling) => {
      sibling.style.color = "black";
      sibling.style.borderBottom = "2px solid transparent";
    });

    current.style.color = "#2077B1";
    current.style.borderBottom = "2px solid #2077B1";
  };

  const renderFounders = data.founders.map((person, index) => {
    return (
      <div className="person-item-wrapper">
        <div className="col-left">
          <img
            src={
              person.hasOwnProperty("image")
                ? `people/${person.image}.png`
                : `${profilePlaceholder}`
            }
            alt="user"
            className="person-profile-img"
          />
          {/* <div className="social-link social-link-lg">
            <div className="linked-in">
              <FaLinkedinIn />
            </div>

            <div className="mail">
              <FiMail />
            </div>
          </div> */}
        </div>

        <div className="col-right">
          <p className="name">{person.name}</p>
          <p className="position">{person.position}</p>
          <p className="about">{person.about}</p>

          {/* <div className="social-link social-link-sm">
            <div className="linked-in">
              <FaLinkedinIn />
            </div>

            <div className="mail">
              <FiMail />
            </div>
          </div> */}
        </div>
      </div>
    );
  });

  const renderComm = data.comm.map((person, index) => {
    return (
      <div className="person-item-wrapper">
        <div className="col-left">
          <img
            src={
              person.hasOwnProperty("image")
                ? `people/${person.image}.png`
                : `${profilePlaceholder}`
            }
            alt="user"
            className="person-profile-img"
          />
          {/* <div className="social-link">
            <div className="linked-in">
              <FaLinkedinIn />
            </div>

            <div className="mail">
              <FiMail />
            </div>
          </div> */}
        </div>

        <div className="col-right">
          <p className="name">{person.name}</p>
          <p className="position">{person.position}</p>
          <p className="about">{person.about}</p>

          {/* <div className="social-link social-link-sm">
            <div className="linked-in">
              <FaLinkedinIn />
            </div>

            <div className="mail">
              <FiMail />
            </div>
          </div> */}
        </div>
      </div>
    );
  });

  const renderBusiness = data.business.map((person, index) => {
    return (
      <div className="person-item-wrapper">
        <div className="col-left">
          <img
            src={
              person.hasOwnProperty("image")
                ? `people/${person.image}.png`
                : `${profilePlaceholder}`
            }
            alt="user"
            className="person-profile-img"
          />
          {/* <div className="social-link">
            <div className="linked-in">
              <FaLinkedinIn />
            </div>

            <div className="mail">
              <FiMail />
            </div>
          </div> */}
        </div>

        <div className="col-right">
          <p className="name">{person.name}</p>
          <p className="position">{person.position}</p>
          <p className="about">{person.about}</p>
          {person.aboutpara ? (
            <p className="about">{person.aboutpara}</p>
          ) : null}

          {/* <div className="social-link social-link-sm">
            <div className="linked-in">
              <FaLinkedinIn />
            </div>

            <div className="mail">
              <FiMail />
            </div>
          </div> */}
        </div>
      </div>
    );
  });

  return (
    <div className="people-wrapper">
      <div className="people-navbar-wrapper">
        <Navbar className="people-navbar">
          <Navbar.Brand href="/home">
            <img src={brandLogo} width="66" height="48" alt="brand-logo" />
          </Navbar.Brand>

          <Nav className="ml-auto">
            <Nav.Item>
              <NavLink className="people-navlink" href="/">
                Back to GoPoolit
              </NavLink>
              {/* {authorizedUser ? (
                <NavLink className="people-navlink" href="/home">
                  Back to GoPoolit
                </NavLink>
              ) : (
                  <NavLink className="people-navlink" href="/login">
                    Back to GoPoolit
                  </NavLink>
                )} */}
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>

      <div className="people-menu-wrapper">
        <div className="people-menu col-md-9 mx-auto">
          {menuList.map((item, index) => (
            <p
              style={{
                backgroundColor: "#fff",
                color: "black",
              }}
              key={index + 1}
              className="menuTab"
              onClick={() => changebtnColor(item.id)}
              ref={menuRefs.current[index]}
            >
              {item.text}
            </p>
          ))}
        </div>
      </div>

      <div className="people-body" style={{ minHeight: "755px" }}>
        {targetMenu === "1"
          ? renderFounders
          : targetMenu === "2"
          ? renderComm
          : renderBusiness}
      </div>
      <LeanmoreFooter />
    </div>
  );
};

export default People;
