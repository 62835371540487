import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";

import axios from "axios";
// import { BASEURL } from "../../config";

//api imports
import { walletApi } from "../../api/WalletAPI";

//component imports
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import RazorPay from "../RazorPay/PayByRazorPay";

//action imports
// import { storeUserCountryData } from '../../actions/storingUserData'

//css imports
import "../../assets/scss/Razorpay/razorpay.scss";
import "../../assets/scss/AddBalance/addBalance.scss";

//image imports
import transactionUpArrow from "../../assets/svg/transaction-up-arrow.svg";
import { TransactionDetails } from "..";
import arrowLeft from "../../assets/svg/arrow-left.svg";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
// import transactionDownArrow from "../../assets/svg/transaction-down-arrow.svg";
import { useTranslation } from "react-i18next";

const AddBalance = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // const [transactionDetails, setTransactionDetails] = useState(details.transactions);

  // const [active, setActive] = useState("INCOMING");
  // const [key, setKey] = useState("INCOMING");
  const [balance, setBalance] = useState("");
  const [countryCurrencySign, setCountryCurrencySign] = useState("");

  // const tokenDetails = useSelector(state => state.tokenDetails)
  // const countriesList = useSelector(state => state.countriesList)
  // const userDetails = useSelector(state => state.profileDetailsById)

  // const userCountryDetails = useSelector(state => state.userCountryDetails)

  // let currencyCode = userCountryDetails[0].country_wallet_currency_sign

  // const dispatch = useDispatch();
  const history = useHistory();
  let currencySign;
  let walletBalance;

  useEffect(() => {
    const fetchBalance = async () => {
      const response = await walletApi();
      // console.log(response);
      currencySign = response.response.data.currency_sign;
      walletBalance = response.response.data.wallet_balance;
      setCountryCurrencySign(currencySign);
      setBalance(walletBalance);
    };

    // console.log(balance);
    fetchBalance();

    // console.log(transactionDetails);
  }, []);

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  return (
    <>
      <div className="addBalance-content-wrapper">
        <ContentNavBar />
        <section className="addBalance-content">
          <Container>
            <Row>
              <Col sm={12} md={9} lg={9} className="mx-auto">
                <div>
                  {/* {window.innerWidth <= 800 && window.innerHeight <= 1024 ? (
                    <span
                      onClick={() =>
                        history.push(
                          `/share/${localStorage.getItem(
                            "content_external_id"
                          )}`
                        )
                      }
                    >
                      <img src={arrowLeft} alt="arrow-left" />
                      <span style={{ color: "#2077B1" }}> Back</span>
                    </span>
                  ) : (
                    ""
                  )} */}
                  <Card className="pin-fav">
                    <Card.Body>
                      <div>
                        <Card.Text style={{ margin: "0" }}>
                          {t("addBalance.poolBalance")}
                        </Card.Text>
                        {/* <Card.Text>{userCountryDetails[0].country_currency_sign} {balance}</Card.Text> */}
                        <Card.Text>
                          {countryCurrencySign} {balance}
                        </Card.Text>
                      </div>
                      <Link to="/pay">{t("addBalance.addBalance")}</Link>
                      {/* <RazorPay /> */}
                    </Card.Body>
                  </Card>

                  {/* <Row className="my-3">
                    <Col>
                      <span style={{ fontSize: "20px", fontWeight: "500" }}>Recent Transactions</span>
                    </Col>
                  </Row> */}

                  {/* <div className="transaction-details">
                    <Row className="transaction-header">
                      <Col className="transaction">
                        <div className="transaction-detail-container">
                    { incomingTransactionDetails }
                        </div>
              </Col>
            </Row>
                  </div> */}

                  {/* <Link className="transactionButton" to="/transactiondetails">See All Transactions</Link> */}
                </div>
              </Col>
            </Row>
            <TransactionDetails countryCurrencySign={countryCurrencySign} />
          </Container>
        </section>
        <LeanmoreFooter />
      </div>
    </>
  );
};

export default withRouter(AddBalance);
