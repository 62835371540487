import React, { Component } from "react";

//import image
import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";
import "../../../assets/scss/showBtn.scss";
import { Image, Button } from "react-bootstrap";
import avatar from "../../../assets/images/avatar.png";
// localhost:3001
class ShowReplyBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            replyCommentarr: props.replyComment,
            toggleViewBtn: false,
            viewCommentUi: false,
            showRepliedComment: props.indexvalue,
            showReply: -1,
            showRecentComment: false,
            showAllReplies: false,
            userExternalId: `${localStorage.getItem("user_external_id")}`,
            showdeleteOnhover: -1,
            isMouseInside: false,
            showDropDown: false,
            editedComment: props.editedreplycomment,
            showEditBtn: -1,
            replyCommentExternalId: null,
            SaveCommentbtn: -1,
            showButton: -1,
            rowstoDisplayReplies: props.replyComment.length > 1 ? 2 : 1,
            showMoreBtn: false,
            showMessage: "",
            isReplied: props.isReplied
        };
    }
    mouseEnter = () => {
        this.setState({
            // showdeleteOnhover: index,
            isMouseInside: true,
        });
    };
    mouseLeave = () => {
        // console.log("mouseleave")
        this.setState({
            isMouseInside: false,
            //showdeleteOnhover: index
        });
    };
    handleUsers = (replyUserExternalId, index) => {
        this.setState({
            showButton: index
        })
        if (
            replyUserExternalId === localStorage.getItem("user_external_id")
        ) {
            return this.setState((previousState) => ({
                showDropDown: !previousState.showDropDown,
            }));
        }
        // else {
        //     return this.setState((previousState) => ({
        //         showReportDropdown: !previousState.showReportDropdown,
        //     }));
        // }
    };
    ViewRepliedComment = () => {
        this.setState({
            toggleViewBtn: !this.state.toggleViewBtn,
            showReply: this.state.showRepliedComment,
        })
        // console.log(this.state.toggleViewBtn);
        if (!this.state.toggleViewBtn && this.state.replyCommentarr.length) {
            this.setState({
                showRecentComment: false,
                showAllReplies: true,
                showMoreBtn: true
            })
        }
        else {
            this.setState({
                showRecentComment: false,
                showAllReplies: false,
                showMoreBtn: false
            })
        }
    }

    handledeleteReply = (replyCommentExternalId) => {
        this.props.deleteReply(replyCommentExternalId, this.state.replyCommentarr);
        this.setState({
            showDropDown: false
        })
    }
    commentedDate = (createdDate) => {
        let date1 = new Date(createdDate);
        let date2 = new Date();
        let delta = Math.abs(date2 - date1) / 1000;
        let days = Math.floor(delta / 86400);
        delta -= days * 86400;
        let hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        let minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        let month = Math.floor(days / 30);

        if (month) {
            return <span className="timeline">{month}mth</span>;
        } else if (days) {
            return <span className="timeline">{days}d</span>;
        } else if (hours) {
            return <span className="timeline">{hours}h</span>;
        } else if (days === 0 && hours === 0) {
            return <span className="timeline">{minutes}m</span>;
        }
    }

    handleEditedComment = (e) => {
        this.setState({
            editedComment: e.target.value,
        });
    };

    handleEditReply = (index, replyCommentExternalId, repliedComment) => {
        document.getElementById("replyinpText").focus();
        this.setState({
            replyCommentExternalId: replyCommentExternalId,
            showEditBtn: index,
            editedComment: repliedComment,
            showEditButton: true,
            showDropDown: false
        });
    }
    handleSaveEditedComment = (e, index, replyCommentExternalId) => {
        e.preventDefault();
        this.setState({
            SaveCommentbtn: index,
            showEditButton: false,
            editedComment: this.state.editedComment
        });
        this.props.saveReply(replyCommentExternalId, this.state.editedComment, this.state.replyCommentarr);
    }
    componentDidMount() {
        // this.setState({
        //     showRecentComment: true
        // })
        if (this.state.replyCommentarr.length) {
            this.setState({
                toggleViewBtn: false,
            })
        }
        if (this.state.postreply) {
            this.setState({
                showAllReplies: true
            })
        }
    }
    showMoreReplies = () => {
        this.setState({
            rowstoDisplayReplies: this.state.rowstoDisplayReplies + 3,
            //showMoreBtn: true
        })
        if (this.state.rowstoDisplayReplies >= this.state.replyCommentarr.length - this.state.rowstoDisplayReplies) {
            this.setState({
                showMessage: "you have no more replies",
                showMoreBtn: false,
                //showNextComment: false,
                rowstoDisplayReplies: this.state.replyCommentarr.length
            });
        }
    }
    handleCancelEditedComment = () => {
        this.setState({
            showEditButton: false,
            editedComment: "",
        });
    }

    render() {
        const value = this.state.rowstoDisplayReplies
        // const showComments = this.state.replyCommentarr.length > 0
        //     ? this.state.replyCommentarr.slice(-3).map((item, index) =>
        // <>
        //     <div className="comment-section-reply-comment">
        //         <div
        //             style={{
        //                 display: "flex",
        //                 justifyContent: "space-between",
        //                 position: "relative",
        //             }}
        //         >
        //             <div>
        //                 <Image
        //                     onError={(ev) => (ev.target.src = `${avatar}`)}
        //                     src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.reply_user_external_id}.jpg`}
        //                     roundedCircle
        //                     width="32"
        //                     height="32"
        //                     style={{ objectFit: "cover", cursor: "pointer" }}
        //                     onClick={() => {
        //                         this.handleCommentUser(item.reply_user_external_id);
        //                     }}
        //                 />
        //                 <div style={{ display: "inline-block" }}>
        //                     <span className="commented-name">
        //                         {item.reply_username}
        //                     </span>
        //                 </div>
        //             </div>
        //             {item.reply_user_external_id === this.state.userExternalId ? <div
        //                 onMouseEnter={this.mouseEnter}
        //                 onMouseLeave={this.mouseLeave}
        //                 className="comment-options"
        //             >
        //                 <span
        //                     className="comment-more-options"
        //                     onClick={() => this.handleUsers(item.reply_user_external_id, index)}
        //                 >
        //                     &#8942;
        //                </span>
        //             </div> : ""}
        //             {this.state.showDropDown && this.state.showButton == index ?
        //                 //&&
        //                 // item.reply_user_external_id === this.state.userExternalId ? 
        //                 (
        //                     <div className="comment-dropdown">
        //                         <button
        //                             className="comment-dropdown-button"
        //                             onClick={() => this.handledeleteReply(item.reply_comment_external_id)}
        //                         >
        //                             Delete comment
        //                       </button>
        //                         <button
        //                             className="comment-dropdown-button"
        //                             onClick={() => this.handleEditReply(index, item.reply_comment_external_id, item.reply_comment)
        //                             }
        //                         >
        //                             Edit comment
        //                     </button>
        //                     </div>) : ""}
        //         </div>
        //         {this.state.showEditBtn == index &&
        //             this.state.showEditButton ? (
        //             <textarea
        //                 ref={this.inpTextRef}
        //                 id="replyinpText"
        //                 onClick={this.handleEditTextareaComment}
        //                 type="text"
        //                 className="comment-section-comments"
        //                 value={this.state.editedComment}
        //                 onChange={this.handleEditedComment}
        //                 autoFocus
        //             />
        //         ) : (
        //             // : <textarea ref={this.inpTextReadOnlyRef} id="inpText" onKeyUp={this.handleKeyUp} className="comment-section-comments" type="text" value={message.comment} readOnly />
        //             <p id="replyinpText" className="comment-section-comments">
        //                 {item.reply_comment} <span style={{ paddingLeft: "7px" }}>{this.commentedDate(item.reply_comment_created_on)}</span>
        //             </p>

        //         )}
        //         {/* <p className="comment-section-comments">{item.reply_comment}</p> */}
        //     </div>

        //     {this.state.showEditBtn == index &&
        //         this.state.showEditButton ? (
        //         <>
        //             <Button
        //                 variant="outline-info"
        //                 style={{ marginLeft: "20px", padding: "0.2rem 0.6rem" }}
        //                 onClick={(e) => {
        //                     this.handleSaveEditedComment(
        //                         e,
        //                         index,
        //                         item.reply_comment_external_id
        //                     );
        //                 }}
        //             >
        //                 Save
        //                </Button>{" "}
        //              &nbsp;
        //             <Button
        //                 variant="outline-info"
        //                 style={{ padding: "0.2rem 0.6rem" }}
        //                 onClick={this.handleCancelEditedComment}
        //             >
        //                 Cancel
        //                 </Button>
        //             <br />
        //             <br />
        //         </>
        //     ) : (
        //         ""
        //     )}
        // </>
        //     ) : null;
        const ViewRepliesUi = this.state.replyCommentarr.length > 0
            ? this.state.replyCommentarr.slice(0, value).map((item, index) =>
                // <>
                //     <div className="comment-section-reply-comment">
                //         <div
                //             style={{
                //                 display: "flex",
                //                 justifyContent: "space-between",
                //                 position: "relative",
                //             }}
                //         >
                //             <div>
                //                 <Image
                //                     onError={(ev) => (ev.target.src = `${avatar}`)}
                //                     src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.reply_user_external_id}.jpg`}
                //                     roundedCircle
                //                     width="32"
                //                     height="32"
                //                     style={{ objectFit: "cover", cursor: "pointer" }}
                //                     onClick={() => {
                //                         this.handleCommentUser(item.reply_user_external_id);
                //                     }}
                //                 />
                //                 <div style={{ display: "inline-block" }}>
                //                     <span className="commented-name">
                //                         {item.reply_username}
                //                     </span>
                //                 </div>
                //             </div>


                //         </div>
                // <p className="comment-section-comments">{item.reply_comment}
                //     <span style={{ paddingLeft: "7px" }}>{this.commentedDate(item.reply_comment_created_on)}</span>
                // </p> 
                //     </div>
                // </>
                <>
                    <div className="comment-section-reply-comment">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                position: "relative",
                            }}
                        >
                            <div>
                                <Image
                                    onError={(ev) => (ev.target.src = `${avatar}`)}
                                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.reply_user_external_id}.jpg`}
                                    roundedCircle
                                    width="32"
                                    height="32"
                                    style={{ objectFit: "cover", cursor: "pointer" }}
                                    onClick={() => {
                                        this.handleCommentUser(item.reply_user_external_id);
                                    }}
                                />
                                <div style={{ display: "inline-block" }}>
                                    <span className="commented-name">
                                        {item.reply_username}
                                    </span>
                                    <span style={{ paddingLeft: "7px", fontSize: "12px" }}>{this.commentedDate(item.reply_comment_created_on)}</span>

                                </div>
                            </div>
                            {item.reply_user_external_id === this.state.userExternalId ? <div
                                onMouseEnter={this.mouseEnter}
                                onMouseLeave={this.mouseLeave}
                                className="comment-options"
                            >
                                <span
                                    className="comment-more-options"
                                    onClick={() => this.handleUsers(item.reply_user_external_id, index)}
                                >
                                    &#8942;
                       </span>
                            </div> : ""}
                            {this.state.showDropDown && this.state.showButton == index ?
                                //&&
                                // item.reply_user_external_id === this.state.userExternalId ? 
                                (
                                    <div className="comment-dropdown">
                                        <button
                                            className="comment-dropdown-button"
                                            onClick={() => this.handleEditReply(index, item.reply_comment_external_id, item.reply_comment)
                                            }
                                        >
                                            Edit
                            </button>
                                        <button
                                            className="comment-dropdown-button"
                                            onClick={() => this.handledeleteReply(item.reply_comment_external_id)}
                                        >
                                            Delete
                              </button>

                                    </div>) : ""}
                        </div>
                        {this.state.showEditBtn == index &&
                            this.state.showEditButton ? (
                            <textarea
                                ref={this.inpTextRef}
                                id="replyinpText"
                                onClick={this.handleEditTextareaComment}
                                type="text"
                                className="comment-section-comments"
                                value={this.state.editedComment}
                                onChange={this.handleEditedComment}
                                autoFocus
                            />
                        ) : (
                            // : <textarea ref={this.inpTextReadOnlyRef} id="inpText" onKeyUp={this.handleKeyUp} className="comment-section-comments" type="text" value={message.comment} readOnly />
                            <p id="replyinpText" className="comment-section-comments">
                                {item.reply_comment}
                            </p>


                        )}
                        {/* <p className="comment-section-comments">{item.reply_comment}</p> */}
                    </div>

                    {this.state.showEditBtn == index &&
                        this.state.showEditButton ? (
                        <>
                            <Button
                                variant="outline-info"
                                style={{ marginLeft: "20px", padding: "0.2rem 0.6rem" }}
                                onClick={(e) => {
                                    this.handleSaveEditedComment(
                                        e,
                                        index,
                                        item.reply_comment_external_id
                                    );
                                }}
                            >
                                Save
                       </Button>{" "}
                     &nbsp;
                            <Button
                                variant="outline-info"
                                style={{ padding: "0.2rem 0.6rem" }}
                                onClick={this.handleCancelEditedComment}
                            >
                                Cancel
                        </Button>
                            <br />
                            <br />
                        </>
                    ) : (
                        ""
                    )}
                </>
            )
            : null;

        return (
            <>
                {this.state.toggleViewBtn || this.props.postreply ? <button className="toggleViewBtn" onClick={this.ViewRepliedComment}>Hide {this.state.replyCommentarr.length} {this.state.replyCommentarr.length > 1 ? 'replies' : 'reply'}</button> : <button className="toggleViewBtn" onClick={this.ViewRepliedComment}>View {this.state.replyCommentarr.length}  {this.state.replyCommentarr.length > 1 ? 'replies' : 'reply'}</button>}
                {/* {this.state.toggleViewBtn || this.props.postreply ? <button className="toggleViewBtn" onClick={this.ViewRepliedComment}>Hide {this.state.replyCommentarr.length} replies</button> : <button className="toggleViewBtn" onClick={this.ViewRepliedComment}>View {this.state.replyCommentarr.length} replies</button>} */}
                {/* {this.state.replyCommentarr.length <= 3 ?
                    this.state.toggleViewBtn ? <button className="toggleViewBtn" onClick={this.ViewRepliedComment}>hide {this.state.replyCommentarr.length} replies</button> : <button className="toggleViewBtn" onClick={this.ViewRepliedComment}>View {this.state.replyCommentarr.length} replies</button> : ""} */}
                {/* {this.state.showRecentComment ? showComments : ""} */}

                {this.state.showAllReplies || this.props.postreply ? ViewRepliesUi : ""}
                {this.state.showMoreBtn && this.state.replyCommentarr.length > 2 ? <button className="showmorBtn" onClick={this.showMoreReplies}>show more Replies</button> : ""}
            </>
        );
    }
}
export default ShowReplyBtn;
