import { GET_USER_PROFILE_PICTURE } from "../../constants/action-types";

const initState = {
  picture: "",
};

const getProfilePictureReducer = (state = initState, action) => {
  if (action.type === GET_USER_PROFILE_PICTURE) {
    return {
      ...state,
      picture: action.payload.picture,
      ...action.payload,
    };
  }
  return state;
};
export default getProfilePictureReducer;
