import axios from "axios";
import axiosInstance from ".././axios/axios";
import { BASEURL } from ".././config";

export const getSignedUrlForProfilePic = (contentType) => {
  return axios({
    method: "POST",
    url: `${BASEURL}/v1/signedUrlForProfilePicture`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': `Bearer ${token}`,
      // 'userexternalid': `${eid}`
    },
    withCredentials: true,
    data: {
      contentType,
    },
  })
    .then((response) => response)
    .catch((e) => console.error(e));
};

export const uploadProfilePicToS3 = (signedURL, contentType, buffer) => {
  return axiosInstance({
    url: `${signedURL}`,
    method: "PUT",
    headers: {
      "Content-Type": `${contentType}`,
      ContentEncoding: "base64",
    },
    data: buffer,
    withCredentials: true,
  })
    .then((res) => res)
    .catch((err) => console.error(err));
};
