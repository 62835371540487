// import axiosInstance from "../axios/axios";
import axios from "axios";
import { BASEURL } from "../config";

export const userPooledPosts = (contentExternalId) => {
  return axios({
    method: "GET",
    url: `${BASEURL}/v1/peoplePooled`,
    params: {
      content_external_id: contentExternalId,
    },
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  })
    .then((response) => response.data.response)
    .catch((e) => {
      console.error(e);
    });
};
