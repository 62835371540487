import { NEW_POST_CREATE } from "../../constants/action-types";

const initState = {
  newPost: false,
};

const newPostReducer = (state = initState, action) => {
  if (action.type === NEW_POST_CREATE) {
    return {
      ...state,
      newPost: action.payload,
    };
  }
  return state;
};
export default newPostReducer;
