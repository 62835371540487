// import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Image,
  Form,
  Alert,
  Col,
  InputGroup,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
// import { BASEURL } from '../../config';
import { updatePassword } from "../../actions/login";

//components imports
// import { GoogleLogin, FacebookLogin, Nexmo } from "../index";
//css imports
// import "../../assets/scss/Login/loginPage.scss";

//CSS imports
// import '../../assets/scss/CharityLogin/loginPage.scss';
import "../../assets/scss/SettingsPrivacy/changePassword.scss";

// import x from '../../assets/svg/x.svg';

import correctPassword from "../../assets/svg/correct-password.svg";
import mismatchPassword from "../../assets/svg/mismatch-password.svg";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import errorButton from "../../assets/svg/errorButton.svg";
import { useTranslation } from "react-i18next";

// import { UPDATE_PASSWORD } from '../../constants/action-types'
// import axiosInstance from '../../../axios/axios';

const passwordChecks = {
  lowercase: "",
  uppercase: "",
  number: "",
  special: "",
  eightChars: "",
  isUsername: "",
  isCurrent: "",
};

const ChangePassword = () => {
  const updatePasswordResponse = useSelector(
    (state) => state.resetPassword.data
  );
  const [NewPassword, SetNewPassword] = useState("");
  const [CurrentPassword, setCurrentPassword] = useState("");
  const [showPasswordIcon, setPasswordIcon] = useState(false);
  const [showReenterPasswordIcon, setReenterPasswordIcon] = useState(false);
  const [showCurrentPasswordIcon, setShowCurrentPasswordIcon] = useState(false);
  const [passwordMatchedmessage, setPasswordMatchedMessage] = useState("");
  const [ReTypedPassword, setReTypedPassword] = useState("");
  const [passwordMessage, setpasswordMessage] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [changePasswordBtn, setChangePasswordBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const [passwordRuleMsg, setPasswordRuleMsg] = useState(
    t("changePassword.passwordRuleMsg")
  );
  const [passwordValidation, setPasswordValidation] = useState("");
  const [pwdSuccessMsg, setPwdSuccessMsg] = useState("");

  const [showPasswordOptions, setShowPasswordOptions] = useState(false);
  const [togglePasswordOptions, setTogglePasswordOptions] = useState(false);
  const [showToastMsg, setShowToastMsg] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const inputRef = useRef();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const showPassword = (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const hidePassword = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 1L17 17"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (NewPassword === ReTypedPassword && NewPassword.length > 0) {
      setChangePasswordBtn(true);
      setIsLoading(false);
      setpasswordMessage("");
      setPasswordMatchedMessage(
        t("changePassword.formData.validations.pwdMatch")
      );
      setDisableSubmit(false);
    } else if (ReTypedPassword.length > 0) {
      setChangePasswordBtn(false);
      setIsLoading(false);
      setpasswordMessage(t("changePassword.formData.validations.pwdNotMatch"));
      setPasswordMatchedMessage("");
    }
  }, [NewPassword, ReTypedPassword]);

  useEffect(() => {
    if (NewPassword === ReTypedPassword && NewPassword.length > 0) {
      setChangePasswordBtn(true);
    }
  }, [NewPassword, ReTypedPassword]);

  const myFunction = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      setPasswordIcon(true);
    } else {
      x.type = "password";
      setPasswordIcon(false);
    }
  };

  const handleUserCurrentPassword = (e) => {
    e.preventDefault();
    setPasswordValidationMsg("");

    if (NewPassword !== e.target.value) {
      passwordChecks.isCurrent = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} />
          {t("changePassword.formData.validations.notCurrentPwd")}
        </li>
      );
    } else {
      passwordChecks.isCurrent = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} />
          {t("changePassword.formData.validations.notCurrentPwd")}
        </li>
      );
    }
    setCurrentPassword(e.target.value);
  };

  const handleCurrentPassword = () => {
    var x = document.getElementById("myOldPassword");
    if (x.type === "password") {
      x.type = "text";
      setShowCurrentPasswordIcon(true);
    } else {
      x.type = "password";
      setPasswordIcon(false);
      setShowCurrentPasswordIcon(false);
    }
  };

  const reEnterPassword = () => {
    var x = document.getElementById("ReEnterPasswordInput");
    if (x.type === "password") {
      x.type = "text";
      setReenterPasswordIcon(true);
    } else {
      x.type = "password";
      setReenterPasswordIcon(false);
    }
  };

  const passwordValidate = () => {
    if (NewPassword.length > 0) {
      var lower = /(?=.*[a-z])/;
      var upper = /(?=.*[A-Z])/;
      var value = /(?=.*\d)/;
      var spclchar = /(?=.*[!@#$%^&*])/;
      if (NewPassword.length < 8) {
        inputRef.current.focus();
        setPasswordValidation(
          t("changePassword.formData.validations.pwdMinChars")
        );
        setPasswordRuleMsg("");
        // setPwdSuccessMsg("");
        return false;
      } else if (!lower.test(NewPassword)) {
        setPasswordValidation(
          t("changePassword.formData.validations.pwdLowercase")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (!upper.test(NewPassword)) {
        setPasswordValidation(
          t("changePassword.formData.validations.pwdUppercase")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (!value.test(NewPassword)) {
        setPasswordValidation(
          t("changePassword.formData.validations.pwdNumber")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (!spclchar.test(NewPassword)) {
        setPasswordValidation(
          t("changePassword.formData.validations.pwdSplChars")
        );
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else if (userDetails.username === NewPassword) {
        setPasswordRuleMsg("");
        inputRef.current.focus();
        // setPwdSuccessMsg("");
        return false;
      } else {
        setPasswordValidation("");
        setPwdSuccessMsg(t("changePassword.formData.validations.pwdStrong"));
        setPasswordRuleMsg("");
        return true;
      }
    }
  };

  const handlePwdKeyUp = () => {
    setShowPasswordOptions(true);
    setPasswordRuleMsg("");
    //   let lower = /(?=.*[a-z])/;
    //   let upper = /(?=.*[A-Z])/;
    //   let numbers = /(?=.*\d)/;
    //   let spclchar = /(?=.*[!@#$%^&*])/;

    const lower = /[a-z]/g;
    const spclchar = /([@!#$%^&_*-])/g;
    const numbers = /[0-9]/g;
    const upper = /[A-Z]/g;

    //validate lowerCase
    if (inputRef.current.defaultValue.match(lower)) {
      passwordChecks.lowercase = (
        <li className={"correctPassword"}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.lowercase")}
        </li>
      );
    } else {
      passwordChecks.lowercase = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.lowercase")}
        </li>
      );
    }

    //validate Uppercase
    if (inputRef.current.defaultValue.match(upper)) {
      passwordChecks.uppercase = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.uppercase")}
        </li>
      );
    } else {
      passwordChecks.uppercase = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.uppercase")}
        </li>
      );
    }

    // Validate numbers
    if (inputRef.current.defaultValue.match(numbers)) {
      passwordChecks.number = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.number")}
        </li>
      );
    } else {
      passwordChecks.number = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.number")}
        </li>
      );
    }

    // Validate special character
    if (inputRef.current.defaultValue.match(spclchar)) {
      passwordChecks.special = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.specialChar")}
          character
        </li>
      );
    } else {
      passwordChecks.special = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.specialChar")}
          character
        </li>
      );
    }

    //Validate password length
    if (NewPassword.length >= 8) {
      passwordChecks.eightChars = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.characters")}
        </li>
      );
    } else {
      passwordChecks.eightChars = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.characters")}
        </li>
      );
    }

    //Validate if password is same as username
    if (inputRef.current.defaultValue !== userDetails.username) {
      passwordChecks.isUsername = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.username")}
        </li>
      );
    } else {
      passwordChecks.isUsername = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.username")}
        </li>
      );
    }
    //CurrentPassword
    if (inputRef.current.defaultValue !== CurrentPassword) {
      passwordChecks.isCurrent = (
        <li className={"correctPassword "}>
          {" "}
          <img src={correctPassword} alt="correct-password" />
          {t("changePassword.formData.passwordHints.password")}
        </li>
      );
    } else {
      passwordChecks.isCurrent = (
        <li className={"passwordNotSatisfy "}>
          {" "}
          <img src={mismatchPassword} alt="mismatch-password" />
          {t("changePassword.formData.passwordHints.password")}
        </li>
      );
    }
    setTogglePasswordOptions(!togglePasswordOptions); //To render the updated passwordChecks values
  };

  // useEffect(() => {
  //   // console.log({ updatePasswordResponse });
  //   if (updatePasswordResponse) {
  //     if (updatePasswordResponse.response.statusCode === 200) {
  //       // console.log("Password changes successfully");
  //     } else {
  //       // console.log("Failed to change password");
  //     }
  //   }
  // }, [updatePasswordResponse]);

  const handleSubmit = async (e, CurrentPassword, NewPassword) => {
    e.preventDefault();
    if (passwordValidate() === true) {
      // setIsLoading(true);
      const res = await updatePassword(CurrentPassword, NewPassword);
      if (res && res.data.response.statusCode === 200) {
        setPasswordValidationMsg("");
        setUpdateSuccess(true);
        setShowToastMsg(true);
        setTimeout(() => {
          setShowToastMsg(false);
        }, 3000);
      } else if (res.data.response.statusCode === 409) {
        //  setUpdateSuccess(false)
        //   setShowToastMsg(true);
        setPasswordValidationMsg(
          t("changePassword.formData.passwordHints.invalidPwd")
        );
        // setTimeout(() => {
        //   setShowToastMsg(false);
        // }, 3000);
      }
    }
  };

  return (
    <div className="new-password-container">
      <Container>
        <Card className="changepwd-card">
          <Card.Header className="change-password-header">
            {t("changePassword.changePasswordText")}
          </Card.Header>
          <Card.Body style={{ padding: "1rem 3rem" }}>
            {/* <Form className="password-form" onSubmit={(e) => sendLink(e, Email)}> */}
            <Form
              className="password-form"
              onSubmit={(e) => handleSubmit(e, CurrentPassword, NewPassword)}
            >
              {/* <Form.Control size="lg" type="email" id="myInput" placeholder="Email address" onChange={e => setEmail(e.target.value)} value={Email} /> */}
              <Form.Group className="password">
                <Form.Label className="password-form-label">
                  {t("changePassword.formData.currentPwd")}
                </Form.Label>

                <InputGroup className="mb-3">
                  <Form.Control
                    size="lg"
                    type="password"
                    id="myOldPassword"
                    placeholder="**********"
                    required
                    onChange={(e) => handleUserCurrentPassword(e)}
                    // onChange={(e) => setCurrentPassword(e.target.value)}
                    value={CurrentPassword}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon1">
                      {showCurrentPasswordIcon ? (
                        <span onClick={handleCurrentPassword}>
                          {showPassword}
                        </span>
                      ) : (
                        <span onClick={handleCurrentPassword}>
                          {hidePassword}
                        </span>
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <span style={{ fontSize: "14px" }} className="error-message">
                  {passwordValidationMsg}
                </span>
              </Form.Group>
              <p className="forgotten-password">
                <a href="/send-password-link">
                  {t("changePassword.formData.forgottenPwd")}
                </a>
              </p>
              <Form.Label className="password-form-label">
                {t("changePassword.formData.newPwd")}
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  size="lg"
                  type="password"
                  id="myInput"
                  placeholder="**********"
                  onKeyUp={handlePwdKeyUp}
                  onChange={(e) => SetNewPassword(e.target.value)}
                  ref={inputRef}
                  value={NewPassword}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon1">
                    {showPasswordIcon ? (
                      <span onClick={myFunction}>{showPassword}</span>
                    ) : (
                      <span onClick={myFunction}>{hidePassword}</span>
                    )}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <span
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  display: "block",
                  marginBottom: "1rem",
                }}
              >
                {passwordRuleMsg}
              </span>
              {showPasswordOptions ? (
                <div className="passwordOptions">
                  <ul>
                    {Object.values(passwordChecks).map((val) => (
                      <span>{val}</span>
                    ))}
                  </ul>
                  {/* <p className="error-message">Use 8 or more characters with a mix of letters, numbers, symbols and not the same as your username</p> */}
                </div>
              ) : null}
              <Form.Label className="password-form-label">
                {t("changePassword.formData.reTypePwd")}
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  size="lg"
                  type="password"
                  placeholder="**********"
                  id="ReEnterPasswordInput"
                  onChange={(e) => setReTypedPassword(e.target.value)}
                  value={ReTypedPassword}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">
                    {showReenterPasswordIcon ? (
                      <span onClick={reEnterPassword}>{showPassword}</span>
                    ) : (
                      <span onClick={reEnterPassword}>{hidePassword}</span>
                    )}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <span className="msg" style={{ color: "red" }}>
                {passwordMessage}
              </span>
              <span className="msg" style={{ color: "green" }}>
                {passwordMatchedmessage}
              </span>

              <Button className="submit-btn" type="submit">
                {t("changePassword.formData.changePwd")}
              </Button>
            </Form>
          </Card.Body>
        </Card>
        {showToastMsg && updateSuccess && (
          <ToastMessage
            image={greenTickMark}
            message={t("changePassword.formData.pwdUpdateSuccessText")}
          />
        )}
      </Container>
    </div>
  );
};

export default ChangePassword;
