import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight, FaInfoCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Linkify from "linkifyjs/react";

//action import
import { getOtherUserDetailsByUserId } from "../../../actions/otherUserProfile/otherUserProfile_action";

//component import
import MostPooledSlider from "./MostPooledSlider";
// import ReportMostPooledPost from "../ReportMostPooledPost";
import ReportPost from "../ReportPost";

//css import
import "../../../assets/scss/Contents/activityPage.scss";

//image import
import CharityLogo from "../../../assets/images/charity logo.png";
import avatar from "../../../assets/images/avatar.png";
import pooledCollect from "../../../assets/svg/pooledCollect.svg";

import SharingOnMedia from "../SharingOnMedia";
import EmojiSlider from "../../EmojiSlider/EmojiSlider";
import CharityInfo from "../../CharityInfo/CharityInfo";
import { useTranslation } from "react-i18next";
// import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";

const PooledModal = ({ data, targetItem, show, hide }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showCharityDes, setShowCharityDes] = useState();

  const [myTarget, setMyTarget] = useState("");
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [Commentsection, setCommentSection] = useState(-1);

  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const len = 90;

  const dispatch = useDispatch();
  const history = useHistory();

  const userDetails = useSelector((state) => state.userDetails);
  const charitiesList = useSelector((state) => state.charityNames.data);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const reportpost = (
    <svg
      className="social-media-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3156 4H8.68444L4 8.68444V15.3156L8.68444 20H15.3156L20 15.3156V8.68444L15.3156 4ZM12 16.7111C11.36 16.7111 10.8444 16.1956 10.8444 15.5556C10.8444 14.9156 11.36 14.4 12 14.4C12.64 14.4 13.1556 14.9156 13.1556 15.5556C13.1556 16.1956 12.64 16.7111 12 16.7111ZM12.8889 12.8889H11.1111V7.55556H12.8889V12.8889Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  useEffect(() => {
    setMyTarget(targetItem);
    const postIndex = getCurrentPostIndex(targetItem.content_external_id);
    // console.log(data)
    if (postIndex === 0) {
      setLeftArrow(false);
      setRightArrow(true);
    } else if (postIndex + 1 === data.length) {
      setRightArrow(false);
      setLeftArrow(true);
    } else {
      setLeftArrow(true);
      setRightArrow(true);
    }
  }, [data, targetItem]);

  const showModal = () => {
    setOpenModal(true);
  };

  const hideShow = () => {
    setOpenModal(false);
    setShowButton(false);
  };

  const handleShowButton = () => {
    // console.log('hello?')
    setShowButton(!showButton);
  };

  const showArrows = (index) => {
    if (index === 0) {
      setLeftArrow(false);
      setRightArrow(true);
    } else if (index + 1 === data.length) {
      setRightArrow(false);
      setLeftArrow(true);
    } else {
      setLeftArrow(true);
      setRightArrow(true);
    }
  };

  //  Getting the index position of the selected post
  const getCurrentPostIndex = (id) => {
    const currentPostIndex =
      data &&
      data
        .map((item) => {
          return item.content_external_id;
        })
        .indexOf(id);
    return currentPostIndex;
  };

  const nextClickHandler = (id) => {
    setShowButton(false);

    let currentPostIndex = getCurrentPostIndex(id);
    let nextPost = data[currentPostIndex + 1];

    // console.log(id);
    // console.log(currentPostIndex);
    // console.log(nextPost);

    if (currentPostIndex === data.length - 1) {
      // setMyTarget(data[0]);
      showArrows(currentPostIndex);
    } else {
      setMyTarget(nextPost);
      showArrows(currentPostIndex + 1);
    }
  };

  const prevClickHandler = (id) => {
    const currentPostIndex = getCurrentPostIndex(id);
    const prevPost = data[currentPostIndex - 1];
    setShowButton(false);
    if (currentPostIndex - 1 < 0) {
      // setMyTarget(data[data.length - 1]);
      showArrows(currentPostIndex);
    } else {
      setMyTarget(prevPost);
      showArrows(currentPostIndex - 1);
    }
  };

  /*********************************************** */
  //On modal, disable scroll on background body
  const BodyStyle = () => {
    useEffect(() => {
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };
  /*********************************************** */

  const modalStyle = show ? { display: "block" } : { display: "none" };

  // const showReport = (contentExternalId) => {
  //   return <ReportPost contentExternalId={contentExternalId} />

  // }

  const ShowComments = (targetItem) => {
    setCommentSection(targetItem);
  };

  const getPostedTime = (createdDate, creatorId) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return (
          <p className="timeline">
            {month} {t("post.monthAgo")}
          </p>
        );
      } else {
        return (
          <p className="timeline">
            {month} {t("post.monthsAgo")}
          </p>
        );
      }
    } else if (days) {
      if (days === 1)
        return (
          <p className="timeline">
            {days} {t("post.dayAgo")}
          </p>
        );
      return (
        <p className="timeline">
          {days} {t("post.daysAgo")}
        </p>
      );
    } else if (hours) {
      if (hours === 1)
        return (
          <p className="timeline">
            {hours} {t("post.hourAgo")}
          </p>
        );
      return (
        <p className="timeline">
          {hours} {t("post.hoursAgo")}
        </p>
      );
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return (
          <p className="timeline">
            {minutes} {t("post.minuteAgo")}
          </p>
        );
      return (
        <p className="timeline">
          {minutes} {t("post.minutesAgo")}
        </p>
      );
    }
  };

  const handleOtherUser = (otherUserExternalId) => {
    // console.log(otherUserExternalId)
    history.push({
      pathname: "/otheruserprofile",
      state: { otherUserExternalId: otherUserExternalId },
    });
    // history.push("/otheruserprofile");
    dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
  };

  const handleCharityInfo = (contentExternalID) => {
    setShowCharityDes(contentExternalID);
    // console.log(`${description}`)
  };

  const showReport = (contentExternalId) => {
    return <ReportPost contentExternalId={contentExternalId} />;
  };
  return (
    <>
      {show && <BodyStyle />}
      {show && <div className="modal-backdrop"></div>}

      <div
        id="activity-modal"
        className="modal in report-modal-container"
        role="dialog"
        style={modalStyle}
      >
        {leftArrow && (
          <div
            onClick={() => prevClickHandler(myTarget.content_external_id)}
            className="prev-arrow"
          >
            <FaChevronLeft />
          </div>
        )}
        {rightArrow && (
          <div
            onClick={() => nextClickHandler(myTarget.content_external_id)}
            className="next-arrow"
          >
            <FaChevronRight />
          </div>
        )}
        {myTarget && (
          <div className="modal-dialog" style={{ maxWidth: "680px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-container">
                  {/* <span className="image-display-pic" style={{ verticalAlign: "top" }}> */}
                  <span>
                    <img
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      onClick={() =>
                        handleOtherUser(myTarget.content_external_id)
                      }
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${myTarget.content_creator_id}.jpg`}
                      alt=""
                      className="modal-profile-pic"
                    />
                  </span>

                  {/* </span> */}
                  {/* {item.user_profile_pic !== null ? <Image className="profile-picture" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userexternalid}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                                  <Image src={avatar} alt="profile-photo" className="profile-picture" roundedCircle style={{ width: "51px", height: "51px" }} />} */}
                  {/* <Image className="profile-picture" src={avatar} roundedCircle /> */}
                  <ul>
                    <li
                      onClick={() =>
                        handleOtherUser(myTarget.content_external_id)
                      }
                      className="modal-name"
                    >
                      {myTarget.username}
                    </li>
                    <li className="postcontent-duration">
                      {getPostedTime(myTarget.content_created_on)}
                    </li>
                  </ul>
                  {/* <h5
                    onClick={() => handleOtherUser(myTarget.content_creator_id)}
                    className="modal-name">{myTarget.username}</h5> */}
                </div>
                <div className="closeAndReportButton">
                  <div onClick={hide} className="modal-close-btn">
                    <MdClose />
                  </div>
                  {myTarget.content_creator_id === userDetails.user_external_id
                    ? ""
                    : showReport(myTarget.content_external_id)}
                  {/* {showButton ? <button
                  onClick={showModal}
                >
                  {reportpost}
                      Report this Post
                    </button> : null} */}
                </div>
              </div>
              <div className="most-pooled-modal-body">
                <p className="post-modal-content">
                  {/* {myTarget.content_description} */}
                  {myTarget.content_description ? (
                    <>
                      {" "}
                      {myTarget.content_description.length <= len && (
                        <Linkify tagName="span">
                          {myTarget.content_description}
                        </Linkify>
                      )}
                      {myTarget.content_description.length > len &&
                        showFullDesc.id !== myTarget.content_external_id && (
                          <>
                            <Linkify tagName="span">
                              {myTarget.content_description.substring(0, len) +
                                "..."}
                            </Linkify>
                            <span
                              style={{ cursor: "pointer", color: "#2077B1" }}
                              onClick={() =>
                                setShowFullDesc({
                                  ...showFullDesc,
                                  id: myTarget.content_external_id,
                                })
                              }
                            >
                              {" "}
                              {t("post.seeMore")}
                            </span>
                          </>
                        )}
                      {myTarget.content_description.length > len &&
                        showFullDesc.id === myTarget.content_external_id && (
                          <>
                            <Linkify tagName="span">
                              {myTarget.content_description}
                            </Linkify>
                            <span
                              style={{ cursor: "pointer", color: "#2077B1" }}
                              onClick={() =>
                                setShowFullDesc({ ...showFullDesc, id: "" })
                              }
                            >
                              {" "}
                              {t("post.seeLess")}
                            </span>
                          </>
                        )}
                    </>
                  ) : null}
                </p>
                <div className="image-slider-container">
                  <MostPooledSlider item={myTarget} />
                </div>
              </div>

              <div className="post-info">
                <div>
                  <div>
                    <div className="profile-comment-pooled-raised">
                      {/* <div onClick={() => { PeoplePooled(item.content_external_id) }} style={{ display: "inline-block", cursor: "pointer" }}> */}
                      <div
                        // onClick={() => {
                        //   handleModal(item.content_external_id);
                        // }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div className="userImageWrapper">
                          <ul className="hx_flex-avatar-stack">
                            {/* {item.total_people_pooled > 0
                          ? item.people_pooled_for_content
                            .slice(0, 4)
                            .map((people_pooled, index) => {
                              return (
                                <li className="hx_flex-avatar-stack-item">
                                  <img
                                    className="avatar avatar-user"
                                    alt="@jqn"
                                    onError={(ev) =>
                                      (ev.target.src = `${avatar}`)
                                    }
                                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${people_pooled.user_external_id}.jpg`}
                                    width="20"
                                    height="20"
                                  />
                                </li>
                              );
                            })
                          : ""} */}
                          </ul>
                        </div>
                        <span className="profile-pooled">
                          {" "}
                          {myTarget.total_people_pooled}{" "}
                          {t("post.peoplePooled")}
                        </span>
                      </div>
                      <span style={{ margin: "0 0.5rem" }}> • </span>
                      <img src={pooledCollect} alt="pooledCollect.svg" />

                      <p className="profile-pooled">
                        <span className="profile-user-pooled-value">
                          {" "}
                          {userDetails.country_wallet_currency_sign}
                          {myTarget.total_amount_pooled_converted}{" "}
                        </span>
                        {t("post.poolCollected")}
                      </p>
                      {/* <span className="profile-pooled"> Pool collected</span> */}
                    </div>
                  </div>

                  <div className="profile-comment-pooled-raised"></div>
                </div>

                {/* {Contentuserimage()} */}
                <div className="profile-comment-pooled-raised">
                  <span className="profile-pooled">
                    {myTarget.totalcomments}{" "}
                    {myTarget.totalcomments > "1"
                      ? t("post.commentPlural")
                      : t("post.commentSingular")}
                  </span>
                </div>
              </div>

              <div className="charity-info-container">
                <div className="charity-info">
                  <div>
                    <img
                      src={myTarget.cause_image}
                      alt="charity logo"
                      className="charity-logo"
                    />
                  </div>

                  <div className="mx-3">
                    <p className="charity-name">{myTarget.cause_name}</p>
                    <p className="charity-country">
                      {myTarget.charity_country}
                    </p>
                  </div>
                  {charitiesList.map((charity) => {
                    if (myTarget.cause_name == charity.cause_name) {
                      return (
                        <>
                          <CharityInfo
                            contentExternalID={myTarget.content_external_id}
                            causeImage={charity.cause_image}
                            causeName={charity.cause_name}
                            causeCountryName={charity.country_name}
                            causeDescription={charity.cause_description}
                            causeURL={charity.cause_url}
                          />
                        </>
                      );
                    }
                  })}
                </div>
                <EmojiSlider
                  causeId={myTarget.cause_external_id}
                  contentExternalId={myTarget.content_external_id}
                  countryId={userDetails.country_external_id}
                  transcationAmt={myTarget.haspooled}
                  // goldenBuzzer={item.is_goldenbuzzer}
                />
                <div
                  className="comments-share"
                  onClick={() => {
                    ShowComments(targetItem);
                  }}
                >
                  {/* < div className="comments-share"> */}
                  <SharingOnMedia
                    url={myTarget.content_url.signedUrl}
                    text={myTarget.content_description}
                    contentExternalId={myTarget.content_external_id}
                    Commentcount={myTarget.totalcomments}
                  />
                  {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
                </div>
              </div>

              {/* <div className="modal-footer">
              <Button
                className="white-buttonActive"
                size="md"
                variant="primary"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Link className="blue-activeLink" to="/addbalance">
                Add Balance
              </Link>
            </div> */}
            </div>
          </div>
        )}
      </div>

      {/* {openModal ? <ReportMostPooledPost showReportModal={openModal} hideShow={hideShow} contentExternalId={myTarget.content_external_id} /> : null} */}
    </>
  );
};

export default PooledModal;
