import axios from 'axios'
import { BASEURL } from '../config'

export const UnfollowUser = (user_following_externalId) => {
    return axios({
        method: 'POST',
        url: `${BASEURL}/v1/unfollowing`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': externalId
        },
        withCredentials: true,
        data: {
            followerExternalId: user_following_externalId
        }
    })
        .then(response => response.data.response)
        .catch(e => {
            console.error(e)
        })
}