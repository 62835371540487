import { SHOW_BALANCE } from '../../constants/action-types'

const initState = {
    show: null
}

const ShowBalanceReducer = (state = initState, action) => {
    if (action.type === SHOW_BALANCE) {
        return {
            ...state,
            show : action.payload
        }

    }
    return state
}
export default ShowBalanceReducer