import { HASH_TAG_INFO } from '../../constants/action-types';

const initState = {}

const hashTagReducer = (state = initState, action) => {
    if (action.type === HASH_TAG_INFO) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default hashTagReducer;