import React, { useState } from "react";
import { Row, Col, Container, Form, Spinner, Nav, Navbar, NavLink } from "react-bootstrap";
import { useSelector } from 'react-redux';
import axios from "axios";

import { BASEURL } from "../../config";
import ToastMessage from "../ToastMessage";
import blueBrandLogo from "../../assets/svg/blue-brandLogo.svg";

import greenTickMark from "../../assets/svg/greenTickMark.svg";
import countriesList from '../../assets/CountryCode/Countries.json';

import "../../assets/scss/Contact/Contact.scss";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

const CharityContact = () => {


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organisationName, setOrganisationName] = useState("");

  const [countryID, setCountryID] = useState("");
  const [query, setQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showToastMsg, setShowToastMsg] = useState(false);

  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  // Handlers
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCountry = (e) => {
    setCountryID(e.target.value);
  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios({
      url: `${BASEURL}/v1/support/`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        Name: `${name} from Organisation: ${organisationName}`,
        Email_Address: email,
        Country: countryID,
        Message: query,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setShowToastMsg(true);
          setIsLoading(false);

          setName("");
          setEmail("");
          setCountryID("");
          setQuery("");

          setTimeout(() => {
            setShowToastMsg(false);
          }, 5000);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section className="charity-form-page">
      {/* {isFacebookApp()} */}
      <div className="charity-navbar-wrapper">
        <Navbar className="charity-navbar">
          <Navbar.Brand href="/home">
            <img src={blueBrandLogo} alr="brand-logo" />
          </Navbar.Brand>

          <Nav className="ml-auto menu-button">
            <Nav.Item>
              {authorizedUser ? (
                <NavLink className="charity-navlink" href="/home">
                  Back to GoPoolit
                </NavLink>
              ) : (
                  <NavLink className="charity-navlink" href="/">
                    Back to GoPoolit
                  </NavLink>
                )}
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>
      <Container className="charity-form-page-container">
        <Row>
          <Col md={8} lg={7} xs={11}>
            <Form className="charityForm" onSubmit={handleSubmit}>
              <div>
                <h3>Get in touch</h3>

                <div>
                  <Form.Group className="section">
                    <Row>
                      <Col md={6}>
                        <Form.Label>
                          Full name<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          placeholder="Full name"
                          value={name}
                          onChange={(e) => handleName(e)}
                          required
                        />
                      </Col>

                      <Col md={6} className="charity-email-input">
                        <Form.Label>
                          Name of the organisation<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Name of the organisation"
                          value={organisationName}
                          onChange={(e) => setOrganisationName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="section">
                    <Row>
                      <Col md={6}>
                        <Form.Label>
                          Email address<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => handleEmail(e)}
                        />
                      </Col>
                      <Col md={6} className="charity-email-input">
                        <Form.Label>
                          Select Country<sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          // disabled={causeName === null ? true : false}
                          className="dropdown-btn"
                          as="select"
                          onChange={(e) => handleCountry(e)}
                          value={countryID}
                          required
                        >
                          <option value="" disabled>
                            Select country
                            </option>
                          {/* {countriesList.data.map((countryData, index) => {
                              return (
                                <option
                                  value={countryData.country_external_id}
                                  key={countryData.country_external_id}
                                >
                                  {countryData.country_name}
                                </option>
                              );
                            })} */}
                          {countriesList.map((countryData, index) => {
                            return (
                              <option
                              >
                                {countryData.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="section">
                    <Row>
                      <Col>
                        <Form.Label>
                          Message
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          onChange={(e) => handleQuery(e)}
                          placeholder="Type your message here..."
                          rows={6}
                          value={query}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {isLoading ? (
                    <button
                      className="btn btn-secondary contact-btn"
                      type="submit"
                      disabled
                    >
                      Send <Spinner animation="border" className="spinner" />
                    </button>
                  ) : (
                      <button
                        className="btn btn-secondary contact-btn"
                        type="submit"
                      >
                        Send
                      </button>
                    )}
                </div>
              </div>
            </Form>

          </Col>
        </Row>
      </Container>
      <LeanmoreFooter />
      {showToastMsg ? (
        <ToastMessage
          image={greenTickMark}
          message="Thanks for contacting us. We will revert back to you soon."
        />
      ) : null}
    </section>
  );
};

export default CharityContact;