import { STORE_CONTENT_ID } from "../constants/action-types";

const initState = {};

const storeContentIdReducer = (state = initState, action) => {
  if (action.type === STORE_CONTENT_ID) {
    return {
      ...state,
      contentExternalId: action.payload,
    };
  }
  return state;
};

export default storeContentIdReducer;
