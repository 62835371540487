import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useSearchUsers } from "../../actions/customHooks/useSearchData";
import {
    Container,
    Box,
    Grid,
    Typography,
    CircularProgress,
    Avatar,
    CardHeader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContentSlider from "../Contents/Activity/ContentSlider";
import SearchTabs from "./SearchTabs";
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import { Card } from "react-bootstrap";
import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";

const useStyles = makeStyles((theme) => ({
    media: {
        width: "100%",
    },
    wrapper: {
        backgroundColor: "#f1f1f1",
        minHeight: "100vh",
        padding: "20px 0",
        marginTop: "20px",
    },
}));

const SearchUser = () => {
    const classes = useStyles();

    const searchInfo = useSelector((state) => state.searchInfo);
    const userDetails = useSelector((state) => state.userDetails);
    const { searchItem } = searchInfo;

    const { loading, error, responseData, hasMore } = useSearchUsers(searchItem);
    const history = useHistory();

    const dispatch = useDispatch();

    const handleOtherUser = (otherUserExternalId) => {
        history.push({
            pathname: "/otheruserprofile",
            state: { otherUserExternalId: otherUserExternalId },
        });
        // history.push("/otheruserprofile");
        dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
    };

    const renderData =
        responseData.statusCode === 200 ? (
            responseData.data.map((item, index) => (
                <div key={index}>
                    <Box mt={3}>
                        <Container maxWidth="sm">
                            <Grid container>
                                <Grid item className={classes.media}>
                                    <Card>
                                        {item.user_external_id === userDetails.user_external_id ? (
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        aria-label="recipe"
                                                        className={classes.avatar}
                                                        className="profile-picture"
                                                        src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`}
                                                        roundedCircle
                                                        width="56"
                                                        height="56"
                                                    ></Avatar>
                                                }
                                                title={`${item.user_full_name}`}
                                                subheader={`${item.username}`}
                                            />
                                        ) : (
                                                <CardHeader
                                                    onClick={() =>
                                                        handleOtherUser(item.user_external_id)
                                                    }
                                                    avatar={
                                                        <Avatar
                                                            aria-label="recipe"
                                                            className={classes.avatar}
                                                            className="profile-picture"

                                                            src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`}
                                                            roundedCircle
                                                            width="56"
                                                            height="56"
                                                        ></Avatar>
                                                    }
                                                    title={`${item.user_full_name}`}
                                                    subheader={`${item.username}`}
                                                />
                                            )}
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
            ))
        ) : responseData.statusCode === 204 ? (
            <Box textAlign="center" mt={3}>
                <Typography variant="subtitle1">No user found!</Typography>
            </Box>
        ) : null;

    return (
        <div>
            <ContentNavBar />
            <SearchTabs activeTabValue="1" />
            <div className={classes.wrapper}>
                {loading ? (
                    <Box textAlign="center" my={3}>
                        <CircularProgress style={{ color: "#2077b1" }} />
                    </Box>
                ) : (
                        renderData
                    )}
            </div>
        </div>
    );
};

export default SearchUser;
