import { GET_SEARCH_POST, GET_SEARCH_CHARITY, GET_SEARCH_USER } from "../../constants/action-types";

const initState = [];

export const searchPostReducer = (state = initState, action) => {
    if (action.type === GET_SEARCH_POST) {
        return {
            ...state,
            ...action.payload.data,
        };
    }
    return state;
};


export const searchCharityReducer = (state = initState, action) => {
    if (action.type === GET_SEARCH_CHARITY) {
        return {
            ...state,
            ...action.payload.data,
        };
    }
    return state;
};

export const searchUserReducer = (state = initState, action) => {
    if (action.type === GET_SEARCH_USER) {
        return {
            ...state,
            ...action.payload.data,
        };
    }
    return state;
};
