import React, { Component, createRef } from "react";
import { Image } from "react-bootstrap";
//image import
// import image from "../../../assets/images/commentPhoto.png";
import avatar from "../../../assets/images/avatar.png";
import ToastMessage from "../../ToastMessage";

import { COMMENTS_BASE_URL } from "../../../config";

//import socket for comments
import io from "socket.io-client";
import ShowBtn from "./ShowCommentButtton";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import ShowReplyBtn from "./ShowReply";
import ReplyCommentUi from "./replyCommentUi";

// const ENDPOINT = "https://api.gopoolit.online/";
const ENDPOINT = `${COMMENTS_BASE_URL}`;
// localhost:3001
class Comments extends Component {
  constructor(props) {
    super(props);
    this.textareaRef = createRef();
    this.inpTextRef = createRef();
    this.inpTextReadOnlyRef = createRef();

    this.state = {
      messages: [],
      newMessage: "",
      count: "",
      // contentId: this.props.contentId,
      contentId: this.props.contentExternalId,
      newComment: "",
      profilePic: this.props.userprofilePic,
      rowsToDisplay: 2,
      rowsToDisplayReplyComments: 2,
      showMessage: "",
      showMore: true,
      deleteComment: false,
      deleteReplyComment: false,
      showEditBtn: -1,
      SaveCommentbtn: -1,
      comment_ExternalId: null,
      editedComment: "",
      editedreplycomment: "",
      showEditButton: false,
      showReplyBtn: -1,
      //new replyComment
      isReply: true,
      isViewComments: true,
      isViewReplybtn: -1,
      viewReplyCommentsbtn: true,
      replyComment: [],
      userExternalId: `${localStorage.getItem("user_external_id")}`,
      arrayIndex: null,
      comment: [],
      rowsToDisplayPreviousComments: 5,
      count: 0,
      showprevBtn: false,
      showMoreBtn: false,
      previousComment: [],
      prevarrayIndex: null,
      commentarray: [],
      loggedInUserComment: null,
      showloggedInUserComment: false,
      rowsToDisplayComments: 3,
      postreply: false
    };

    let queries = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      userexternalid: `${localStorage.getItem("user_external_id")}`,
    };
    this.socket = io(ENDPOINT, {
      query: queries,
      //DEV - polling is required
      //PROD - Polling not required
      // transports: ["polling", "websocket"],
      transports: ["websocket"],
    });
    this.isLoaded = false;

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleEditTextareaComment = this.handleEditTextareaComment.bind(this);
    this.handleUserKeyPress = this.handleUserKeyPress.bind(this);
  }

  // socketConnection = () => {
  //   let queries = {
  //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //     userexternalid: `${localStorage.getItem("user_external_id")}`,
  //   };

  //   this.socket = io(ENDPOINT, {
  //     query: queries,
  //     transports: ["polling", "websocket"],
  //   });
  //   console.log(this.socket);
  // };

  // count = () => {
  //   let comment;
  //   comment = {
  //     contentExternalId: this.props.contentExternalId,
  //   };

  //   this.socket.emit("comment-count", comment);
  //   this.socket.on("refresh-comment-count", (msg) => {
  //     this.setState({ count: msg.count });
  //   });
  // };

  show = (contentExternalId) => {
    const comment = {
      contentExternalId: contentExternalId, //This will come from the props from contents.js
    };
    this.socket.emit("show-comments", comment);
    this.socket.on("refresh-show-comments", (msg) => {
      this.setState({
        editedComment: localStorage.getItem("userComment"),
        messages: []

      });
      this.setState({ count: msg.length });
      let arr = [];
      this.setState({
        commentarray: [],
      });
      msg.forEach((item, index) => {
        const dict = {
          value: index + 1,
          data: item,
        };
        arr.push(dict);
        this.setState({
          commentarray: arr,
        });
      });
      if (arr.length > 3) {
        let comments = arr.reverse();
        console.log("this.state.commentarray", this.state.commentarray);
        //let recentdata = comments.slice(0, 3);
        // console.log("recentdata", recentdata);
        //let recentdata = comments.slice(arr.length - 3, arr.length);

        //let value = recentdata[0].value;
        this.setState({
          messages: comments,
          // arrayIndex: value,
          showMoreBtn: true,
        });
      } else {
        let comments = arr.reverse();
        this.setState({
          messages: comments,
          showprevBtn: false,
          showMoreBtn: false,
        });
      }
      //if (arr.length) {
      // if (
      //   recentComment.data.user_external_id === this.state.userExternalId
      // ) {
      //   userReplyComments.push(recentComment);
      // }
      // if (userReplyComments.length) {
      //   max = userReplyComments.reduce(function (prev, current) {
      //     return prev.value > current.value ? prev : current;
      //   });

      //   if (arr.length > 5) {
      //     if (max.value < 5) {
      //       let showPreviousComments = arr.slice(0, max.value);
      //       let previouscommentvalue = showPreviousComments[0].value;
      //       let previouscommentlastValue = showPreviousComments.length;
      //       if (showPreviousComments.length) {
      //         this.setState({
      //           showprevBtn: true,
      //         });
      //       }
      //       let showmorecomments = arr.slice(previouscommentlastValue, previouscommentlastValue + 5 < arr.length ? previouscommentlastValue + 5 : arr.length);
      //       if (showmorecomments.length) {
      //         this.setState({
      //           showMoreBtn: true,
      //         });
      //       }
      //       this.setState({
      //         messages: userReplyComments,
      //         arrayIndex: previouscommentvalue,
      //         prevarrayIndex: previouscommentlastValue
      //       })
      //     }
      //     recentcomments = arr.slice(max.value - 5, max.value);
      //     if (recentcomments.length) {
      //       let value = recentcomments[0].value;
      //       let lastValue = recentcomments[4].value;
      //       let showmorecomments = arr.slice(lastValue, arr.length);
      //       if (showmorecomments.length) {
      //         this.setState({
      //           showMoreBtn: true,
      //         });
      //       } else {
      //         this.setState({
      //           showMoreBtn: false,
      //         });
      //       }
      //       let showPreviousComments = arr.slice(0, value);
      //       if (showPreviousComments.length) {
      //         this.setState({
      //           showprevBtn: true,
      //         });
      //       }
      //       this.setState({
      //         messages: recentcomments,
      //         arrayIndex: value,
      //         prevarrayIndex: lastValue,
      //       });
      //     }
      //   }
      //   else {
      //     if (max.value < 5) {
      //       recentcomments = arr.slice(0, max.value);
      //       //if (recentcomments.length >= 5) {
      //       let value = recentcomments[0].value;
      //       // let lastValue = recentcomments[4].value;
      //       let lastValue = max.value;
      //       // let showmorecomments = arr.slice(lastValue, arr.length); //This will show the whole array
      //       let showmorecomments = arr.slice(
      //         lastValue,
      //         lastValue + 5 < arr.length ? lastValue + 5 : arr.length
      //       );
      //       if (showmorecomments.length) {
      //         this.setState({
      //           showMoreBtn: true,
      //         });
      //       } else {
      //         this.setState({
      //           showMoreBtn: false,
      //         });
      //       }
      //       let showPreviousComments = arr.slice(0, value);
      //       if (showPreviousComments.length) {
      //         this.setState({
      //           showprevBtn: true,
      //         });
      //       }
      //       this.setState({
      //         messages: arr,
      //         arrayIndex: value,
      //         prevarrayIndex: lastValue,
      //         showMoreBtn: false,
      //         showprevBtn: false
      //       });
      //     }
      //   }
      // //}
      // //if there is no logged in user Comment
      // else {

      // }
      //}
      //});
    });

    //Why do we have an event for the error? wont we get the error response in the other event calls if it fails?
    this.socket.on("error", function (msg) {
      console.error(msg);
    });
  };
  // showPreviousComment = () => {
  //   let previousComment = null;
  //   if (this.state.arrayIndex > 5) {
  //     previousComment = this.state.commentarray.slice(this.state.arrayIndex - 6, this.state.commentarray.length);
  //     let data = previousComment[0].value;
  //     this.setState({
  //       messages: previousComment,
  //       arrayIndex: data,
  //       showprevBtn: true,
  //       showMoreBtn: true,
  //     });
  //   } else {
  //     this.setState({
  //       messages: this.state.commentarray,
  //       showprevBtn: false,
  //       showMoreBtn: true,
  //     });
  //   }
  // };
  showNextComments = () => {
    this.setState({
      rowsToDisplayComments: this.state.rowsToDisplayComments + 5,
      showMoreBtn: true,
    });
    if (
      this.state.rowsToDisplayComments >=
      this.state.messages.length - this.state.rowsToDisplayComments
    ) {
      this.setState({
        //showMessage: "you have no more replies",
        showMoreBtn: false,
        //showNextComment: false,
        rowsToDisplayComments: this.state.messages.length,
      });
    }
  };
  // showNextComments = () => {
  //   let nextComment = null;
  //   nextComment = this.state.commentarray.slice(
  //     this.state.prevarrayIndex,
  //     this.state.prevarrayIndex + 5
  //   );
  //   if (nextComment.length >= 5) {
  //     let value = nextComment[4].value;
  //     let nextIndexvalue = nextComment[0].value;
  //     this.setState({
  //       messages: nextComment,
  //       prevarrayIndex: value,
  //       arrayIndex: nextIndexvalue,
  //       showMoreBtn: true,
  //       showprevBtn: true,
  //     });
  //     if (this.state.prevarrayIndex == this.state.commentarray.length) {
  //       this.setState({
  //         showMoreBtn: false,
  //       });
  //     }
  //   } else {
  //     nextComment = this.state.commentarray.slice(
  //       this.state.prevarrayIndex,
  //       this.state.commentarray.length
  //     );
  //     if (nextComment.length) {
  //       let value = this.state.prevarrayIndex;
  //       let lastIndex = this.state.commentarray.length;
  //       this.setState({
  //         messages: nextComment,
  //         arrayIndex: value,
  //         prevarrayIndex: lastIndex,
  //         showMoreBtn: false,
  //         showprevBtn: true,
  //       });
  //     }
  //   }
  // };

  handleComment = (e) => {
    this.setState({
      newComment: e.target.value,
    });
  };

  addComment = (e) => {
    e.preventDefault();
    const comment = {
      contentExternalId: this.props.contentExternalId,
      comment: this.state.newComment,
    };
    this.socket.emit("add-comment", comment);
    this.setState({
      newComment: "",
    });
    this.show(this.props.contentExternalId);

    var box = this.textareaRef.current;
    // let height = box.offsetHeight;

    box.style.height = "auto";
    //this.count();
  };

  deleteComment = async (commentExternalId) => {
    const deleteComment = {
      contentExternalId: this.props.contentExternalId,
      commentExternalId: commentExternalId,
      commentIsActive: false,
    };
    // let isDeleted=false
    await this.socket.emit("delete-comment", deleteComment);
    await this.socket.on("refresh-delete-comment", async (msg) => {
      if (msg) {
        this.state.messages.forEach((element, index) => {
          if (element.comment_external_id === commentExternalId) {
            this.state.messages.splice(index, 1);
          }
        });
      }
      this.setState({
        deleteReplyComment: msg,
      });
      // this.deleteShow(this.state.deleteComment)
      await msg;
    });
    this.socket.on("error", function (msg) {
      console.error(msg);
    });
  };

  editComment = (msg, index) => {
    document.getElementById("inpText").focus();
    this.setState({
      showEditBtn: index,
      editedComment: msg,
      showEditButton: true,
    });
  };

  handleEditedComment = (e) => {
    this.setState({
      editedComment: e.target.value,
    });
  };
  SaveEditedComment = (e, index, commentExternalId) => {
    e.preventDefault();
    this.setState({
      SaveCommentbtn: index,
      showEditButton: false,
    });
    const editComment = {
      contentExternalId: this.props.contentExternalId,
      commentExternalId: commentExternalId,
      comment: this.state.editedComment,
    };
    this.socket.emit("edit-comment", editComment);
    // this.socket.on("refresh-edit-comment", (msg) => {
    //   console.log("edit comments", msg);
    // });
    this.socket.on("refresh-edit-comment", (msg) => {
      this.setState({
        editedComment: msg,
      });
    });
    this.show(this.props.contentExternalId);
  };

  CancelEditComment = () => {
    this.setState({
      showEditButton: -1,
      showEditButton: false,
    });
  };
  handleCommentUser = (otherUserExternalId) => {
    let userExternalId = `${localStorage.getItem("user_external_id")}`;
    if (userExternalId !== otherUserExternalId) {
      this.props.history.push({
        pathname: `/otheruserprofile`,
        state: { otherUserExternalId: otherUserExternalId },
      });
      window.location.reload();
    }
  };
  commentedDate = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();
    let delta = Math.abs(date2 - date1) / 1000;
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      return <span className="timeline">{month}mth</span>;
    } else if (days) {
      return <span className="timeline">{days}d</span>;
    } else if (hours) {
      return <span className="timeline">{hours}h</span>;
    } else if (days === 0 && hours === 0) {
      return <span className="timeline">{minutes}m</span>;
    }
  }

  // var hours = date.getHours(); //UTC give you the hours without the timezone offset.
  // var minutes = date.getMinutes();
  // var ampm = hours >= 12 ? "pm" : "am";
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // var strTime = hours + ":" + minutes + " " + ampm;
  // let dayMonthYear =
  //   finalDate[2] +
  //   " " +
  //   finalDate[1] +
  //   " " +
  //   finalDate[3] +
  //   "," +
  //   " " +
  //   strTime;
  // return dayMonthYear


  componentDidMount() {
    if (this.props.showComments) {
      this.show(this.props.contentExternalId);
      //this.count();
    } else if (!this.props.showComments) {
      this.socket.close();
    }
  }
  componentWillUnmount() {
    this.socket.disconnect();
  }
  showReplyComments = (index, commentExternalId) => {
    this.setState({
      isReply: !this.state.isReply,
      showReplyBtn: index,
      comment_ExternalId: commentExternalId,
    });
  };
  replyComments = (newRepliedComments, commentExternalId, isreplyPost) => {
    this.setState({
      postreply: isreplyPost
    })
    let newComment = newRepliedComments;
    const comment = {
      replyComment: newComment,
      commentExternalId: commentExternalId,
    };
    this.socket.emit("reply-comment", comment);
    newComment = "";
    // this.socket.on("refresh-reply-comment", (msg) => {
    //   console.log("reply-comment", msg);
    // });
  };
  viewReplies = (index, replyComment) => {
    this.setState({
      isViewComments: !this.state.isViewComments,
      isViewReplybtn: index,
      viewReplyCommentsbtn: !this.state.viewReplyCommentsbtn,
    });
  };

  deletReplyComment = async (replyCommentExternalId, replyComments) => {
    const deleteComment = {
      contentExternalId: this.props.contentExternalId,
      replyCommentExternalId: replyCommentExternalId,
      replyCommentIsActive: false,
    };
    // let isDeleted=false
    this.socket.emit("replyDelete-comment", deleteComment);
    await this.socket.on("refresh-replyDelete-comment", async (msg) => {
      if (msg) {
        replyComments.forEach((element, index) => {
          if (element.reply_comment_external_id === replyCommentExternalId) {
            replyComments.splice(index, 1);
          }
        });
      }
      this.setState({
        deleteComment: msg,
      });
      // this.deleteShow(this.state.deleteComment)
      await msg;
    });
    this.socket.on("error", function (msg) {
      console.error(msg);
    });
  };

  saveReplyComment = (
    replyCommentExternalId,
    editedComment,
    replyCommentarr
  ) => {
    const editComment = {
      contentExternalId: this.props.contentExternalId,
      replyComment: editedComment,
      replyCommentExternalId: replyCommentExternalId,
    };
    this.socket.emit("replyEdit-comment", editComment);
    // this.socket.on("refresh-edit-comment", (msg) => {
    //   console.log("edit comments", msg);
    // });
    this.socket.on("refresh-replyEdit-comment", (msg) => {
      this.setState({
        editedreplycomment: msg,
      });
    });
    this.show(this.props.contentExternalId);
  };

  handleKeyUp() {
    var box = this.textareaRef.current;
    let height = box.offsetHeight;

    if (height < box.scrollHeight) {
      box.style.height = box.scrollHeight + "px";
    }
  }

  handleUserKeyPress = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      // e.preventDefault();
      this.addComment(e); // this won't be triggered
    }
  };


  handleEditTextareaComment() {
    var box2 = this.inpTextRef.current;
    let height2 = box2.offsetHeight;

    if (height2 < box2.scrollHeight) {
      box2.style.height = box2.scrollHeight + "px";
    }
  }
  render() {
    let value = this.state.rowsToDisplayComments;
    const loadCount = this.state.loadMore ? this.state.messages.length : 3;

    //showMoreComments
    const showComments =
      this.state.messages.length > 0
        ? this.state.messages.slice(0, value).map((message, index) =>
          this.props.contentExternalId ===
            message.data.content_external_id ? (
            <>
              <div
                className="comment-section-post-comment"
                key={message.data.comment_external_id}
              >
                {/* <img src={message.user_profile_pic} height="30px" width="30px" alt="No Profile pic" /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div>
                    <Image
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${message.data.user_external_id}.jpg`}
                      roundedCircle
                      width="32"
                      height="32"
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      onClick={() => {
                        this.handleCommentUser(message.data.user_external_id);
                      }}
                    />
                    <div style={{ display: "inline-block" }}>
                      <span className="commented-name">
                        {message.data.username}
                      </span>
                      <span style={{ paddingLeft: "7px", fontSize: "12px" }}>{this.commentedDate(message.data.comment_created_on)}</span>

                    </div>
                  </div>
                  <ShowBtn
                    deleteComment={this.deleteComment}
                    editComment={this.editComment}
                    //replyComments={this.replyComments}
                    commentCreatorId={message.data.user_external_id}
                    commentExternalId={message.data.comment_external_id}
                    commentMsg={message.data.comment}
                    indexValue={index}
                  />
                </div>
                {/* <p className="comment-section-comments">{message.comment}</p> */}
                {/* <button onClick={() => { this.editComment(index, message.comment) }}>edit</button> */}
                {this.state.showEditBtn == index &&
                  this.state.showEditButton ? (
                  <textarea
                    ref={this.inpTextRef}
                    id="inpText"
                    onClick={this.handleEditTextareaComment}
                    type="text"
                    className="comment-section-comments"
                    value={this.state.editedComment}
                    onChange={this.handleEditedComment}
                    onKeyPress={this.handleUserKeyPress}
                    autoFocus
                  />
                ) : (
                  // : <textarea ref={this.inpTextReadOnlyRef} id="inpText" onKeyUp={this.handleKeyUp} className="comment-section-comments" type="text" value={message.comment} readOnly />
                  <p id="inpText" className="comment-section-comments">
                    {message.data.comment}
                  </p>
                )}
                {message.data.user_external_id ===
                  this.props.user_external_id ? (
                  <button
                    onClick={() => {
                      this.deleteComment(message.data.comment_external_id);
                    }}
                  >
                    delete Comment
                  </button>
                ) : (
                  ""
                )}
                {this.props.isUserComments ? (
                  <button
                    onClick={() => {
                      this.deleteComment(message.data.comments.comment_id);
                    }}
                  >
                    delete Comment
                  </button>
                ) : (
                  ""
                )}
              </div>
              <button
                type="button"
                className="reply-button"
                onClick={() => {
                  this.showReplyComments(
                    index,
                    message.data.comment_external_id,
                    message.data.replyComment
                  );
                }}
              >
                Reply
                </button>
              &nbsp;
              {message.data.replyComment.length > 0 ? (
                <ShowReplyBtn
                  replyComment={message.data.replyComment}
                  indexvalue={index}
                  deleteReply={this.deletReplyComment}
                  saveReply={this.saveReplyComment}
                  editedreplycomment={this.state.editedreplycomment}
                  postreply={this.state.postreply}
                />
              ) : (
                ""
              )}
              {this.state.showReplyBtn === index && this.state.isReply ? (
                <ReplyCommentUi
                  replyComment={message.data.replyComment}
                  PostReplyComment={this.replyComments}
                  showComment={this.show}
                  commentExternalId={this.state.comment_ExternalId}
                  contentExternalId={message.data.content_external_id}
                  userName={message.data.username}
                />
              ) : (
                ""
              )}
              {this.state.showEditBtn == index &&
                this.state.showEditButton ? (
                <>
                  <Button
                    variant="outline-info"
                    style={{ marginLeft: "20px", padding: "0.2rem 0.6rem" }}
                    onClick={(e) => {
                      this.SaveEditedComment(
                        e,
                        index,
                        message.data.comment_external_id
                      );
                    }}
                  >
                    Save
                    </Button>{" "}
                    &nbsp;
                  <Button
                    variant="outline-info"
                    style={{ padding: "0.2rem 0.6rem" }}
                    onClick={this.CancelEditComment}
                  >
                    Cancel
                    </Button>
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )
        )
        : null;

    // const loggedInUserComments = this.state.loggedInUserComment ?
    //   <>
    //     <div
    //       className="comment-section-post-comment"
    //       key={this.state.loggedInUserComment.data.comment_external_id}
    //     >
    //       {/* <img src={message.user_profile_pic} height="30px" width="30px" alt="No Profile pic" /> */}
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           position: "relative",
    //         }}
    //       >
    //         <div>
    //           {/* <Image
    //             onError={(ev) => (ev.target.src = `${avatar}`)}
    //             src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${this.state.loggedInUserComment.data.user_external_id}.jpg`}
    //             roundedCircle
    //             width="32"
    //             height="32"
    //             style={{ objectFit: "cover", cursor: "pointer" }}
    //             onClick={() => {
    //               this.handleCommentUser(this.state.loggedInUserComment.data.user_external_id);
    //             }}
    //           /> */}
    //           <div style={{ display: "inline-block" }}>
    //             <span className="commented-name">
    //               {this.state.loggedInUserComment.data.username}
    //             </span>
    //           </div>
    //         </div>
    //         <ShowBtn
    //           deleteComment={this.deleteComment}
    //           editComment={this.editComment}
    //           //replyComments={this.replyComments}
    //           commentCreatorId={this.state.loggedInUserComment.data.user_external_id}
    //           commentExternalId={this.state.loggedInUserComment.data.comment_external_id}
    //           commentMsg={this.state.loggedInUserComment.data.comment}
    //         // indexValue={index}
    //         />
    //       </div>
    //       {/* <p className="comment-section-comments">{message.comment}</p> */}
    //       {/* <button onClick={() => { this.editComment(index, message.comment) }}>edit</button> */}
    //       {
    //         this.state.showEditButton ? (
    //           <textarea
    //             ref={this.inpTextRef}
    //             id="inpText"
    //             onClick={this.handleEditTextareaComment}
    //             type="text"
    //             className="comment-section-comments"
    //             value={this.state.editedComment}
    //             onChange={this.handleEditedComment}
    //             autoFocus
    //           />
    //         ) : (
    //             // : <textarea ref={this.inpTextReadOnlyRef} id="inpText" onKeyUp={this.handleKeyUp} className="comment-section-comments" type="text" value={message.comment} readOnly />
    //             <p id="inpText" className="comment-section-comments">
    //               {this.state.loggedInUserComment.data.comment}
    //             </p>
    //           )}
    //       {this.state.loggedInUserComment.data.user_external_id ===
    //         this.props.user_external_id ? (
    //           <button
    //             onClick={() => {
    //               this.deleteComment(this.state.loggedInUserComment.data.comment_external_id);
    //             }}
    //           >
    //             delete Comment
    //           </button>
    //         ) : (
    //           ""
    //         )}
    //       {this.props.isUserComments ? (
    //         <button
    //           onClick={() => {
    //             this.deleteComment(this.state.loggedInUserComment.data.comments.comment_id);
    //           }}
    //         >
    //           delete Comment
    //         </button>
    //       ) : (
    //           ""
    //         )}
    //     </div>
    //     <button
    //       type="button"
    //       className="reply-button"
    //       onClick={() => {
    //         this.showReplyComments(
    //           this.state.loggedInUserComment.data.comment_external_id,
    //           this.state.loggedInUserComment.data.replyComment
    //         );
    //       }}
    //     >
    //       Reply
    //             </button>
    //   &nbsp;
    //     {
    //       this.state.loggedInUserComment.data.replyComment.length > 0 ? (
    //         <ShowReplyBtn
    //           replyComment={this.state.loggedInUserComment.data.replyComment}
    //         />
    //       ) : (
    //           ""
    //         )
    //     }
    //     {
    //       this.state.isReply ? (
    //         <ReplyCommentUi
    //           replyComment={this.state.loggedInUserComment.data.replyComment}
    //           PostReplyComment={this.replyComments}
    //           showComment={this.show}
    //           commentExternalId={this.state.comment_ExternalId}
    //           contentExternalId={this.state.loggedInUserComment.data.content_external_id}
    //           userName={this.state.loggedInUserComment.data.username}
    //         />
    //       ) : (
    //           ""
    //         )
    //     }
    //     {
    //       this.state.showEditButton ? (
    //         <>
    //           <Button
    //             variant="outline-info"
    //             style={{ marginLeft: "20px", padding: "0.2rem 0.6rem" }}
    //             onClick={(e) => {
    //               this.SaveEditedComment(
    //                 e,
    //                 this.state.loggedInUserComment.data.comment_external_id
    //               );
    //             }}
    //           >
    //             Save
    //                 </Button>{" "}
    //                 &nbsp;
    //           <Button
    //             variant="outline-info"
    //             style={{ padding: "0.2rem 0.6rem" }}
    //             onClick={this.CancelEditComment}
    //           >
    //             Cancel
    //                 </Button>
    //           <br />
    //           <br />
    //         </>
    //       ) : (
    //           ""
    //         )
    //     }
    //   </> : ""

    const showCounts = this.state.count;
    return (
      <div>
        {/* <p style={{ marginBottom: "0.5rem" }}>{this.state.messages ? this.state.messages.length : 0} comments</p> */}
        <div
          // style={{
          //   display: "flex",
          //   justifyContent: "space-around",
          //   alignItems: "center",
          // }}
          className="comment-section-post-item"
        >
          {/* <span className="comment-section-post"> */}
          {/* <img src={this.state.profilePic} alt="no profile pic" /> */}
          <Image
            onError={(ev) => (ev.target.src = `${avatar}`)}
            src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${localStorage.getItem(
              "user_external_id"
            )}.jpg`}
            roundedCircle
            width="32"
            height="32"
            style={{ verticalAlign: "top" }}
          />
          {/* </span> */}
          <form onSubmit={(e) => this.addComment(e)}>
            <textarea
              ref={this.textareaRef}
              onKeyUp={this.handleKeyUp}
              rows={1}
              value={this.state.newComment}
              placeholder="Write your comment here"
              className="comment-section-input"
              onChange={this.handleComment}
            />
            <button type="submit" className="comment-post-btn">
              Post
            </button>
            {/* <div></div> */}
          </form>
        </div>
        {/* <button
          type="button"
          onClick={() =>
            this.replyComments(this.state.messages.comment_external_id)
          }
        >
          Reply
        </button> */}
        {/* <p style={{ marginBottom: "0.5rem" }}>Showing:{this.state.rowsToDisplay} of {showCounts}</p> */}
        {/* {this.state.commentarray.length ? <p className="showCommentsCount">{`Showing ${this.state.messages.length} of ${this.state.commentarray.length} ${this.state.commentarray.length > 1 ? "comments" : "comment"}`} </p> : ""} */}
        {/* {this.state.showprevBtn ? (
          <button className="toggleViewBtn" onClick={this.showPreviousComment} style={{ fontSize: "18px", textDecoration: "underline" }}>see previous comment</button>
        ) : (
          ""
        )} */}
        {showComments}
        <br />
        {this.state.showMoreBtn ? (
          <button
            className="toggleViewBtn"
            onClick={this.showNextComments}
            style={{ fontSize: "15px", textDecoration: "underline" }}
          >
            see more comment
          </button>
        ) : (
          ""
        )}
        {/* {loggedInUserComments} */}
        {/* {this.state.showMoreBtn ? (
          <button className="toggleViewBtn" onClick={this.showNextComments} style={{ fontSize: "18px", textDecoration: "underline" }}>show more comment</button>
        ) : (
            ""
          )} */}
        {this.state.showMessage}
        {/* {this.state.showMore && this.state.count > 2 ? (
          <span onClick={this.showMore} style={{ fontWeight: "500" }}>
            Show more
          </span>
        ) : (
            ""
          )} */}
        {/* {this.state.commentDeleted ? (
          <ToastMessage image={null} message="comment deleted" />
        ) : (
          ""
        )} */}
      </div>
    );
  }
}

export default withRouter(Comments);
