import { BALANCE_AMT } from '../../constants/action-types'

const initState = {
    currencySymbol:"",
    balance: 0
}

const BalanceReducer = (state = initState, action) => {
    if (action.type === BALANCE_AMT) {
        if(action.payload){
            return {
                ...state,
                currencySymbol: action.payload.currency_sign,
                balance : action.payload.wallet_balance
            }
        }
        

    }
    return state
}
export default BalanceReducer