import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";

const UserExternalIdPrivateRoute = ({ component: Component, ...rest }) => {
  const googleUserData = useSelector((state) => state.googleUserData);
  const FbUserData = useSelector((state) => state.FbUserData);
  // const AppleUserData = useSelector((state) => state.AppleUserData);
  const phoneNumber = useSelector((state) => state.nexmoPhoneNumber.phone);

  // console.log(googleResponse);

  const user_external_id = () => {
    // if (googleUserData[5] || FbUserData.id || phoneNumber || AppleUserData.authorization.code) {
    // console.log(FbUserData);
    if (googleUserData[5] || FbUserData.id || phoneNumber) {
      return true;
    } else {
      return false;
    }
  };

  if (!googleUserData[5] && !FbUserData.id && !phoneNumber) return <Loader />;

  const userExternalID = user_external_id();
  // console.log(userExternalID)
  return (
    <Route
      {...rest}
      render={(props) =>
        userExternalID ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default UserExternalIdPrivateRoute;
