import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/LandingLearnMore/learnmoreFooter.scss";
import { Navbar, Nav } from "react-bootstrap";
import x from "../../assets/svg/x.svg";
import { useTranslation } from "react-i18next";
import infoIcon from "../../assets/svg/infoIcon.svg";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import Info from "../../CharityInfo/CharityInfo";

const useStyles = makeStyles((theme) => ({
  headerBorder: {
    boxShadow: "0px 0px 2px 0px",
  },
  CardWrapper: {
    position: "absolute",
    bottom: "2rem",
    left: "-11rem",
    width: "21rem",
    zIndex: "1",
  },
  charityInfoWrapper: {
    position: "relative",
  },
  arrowInfo: {
    color: "#fff",
    textShadow: "0px 2px 2px #b5b8ba",
    position: "absolute",
    bottom: "17px",
    left: "4px",
    zIndex: "1",
  },
  charityLogo: {
    maxWidth: "4rem",
    minWidth: "2rem",
    height: "40px",
    objectFit: "contain",
  },
  paraMargin: {
    marginTop: "0.6rem",
  },
  charityHeader: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#3B3B3B",
    fontFamily: "DM Sans",
  },
  charityURL: {
    color: "#2077B1",
    fontWeight: "500",
  },
}));

const LeanmoreFooter = () => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(false);
  const [highlight, setHighlight] = useState("");

  const [t, i18n] = useTranslation("translate");

  const language = localStorage.getItem("lang");
  const dispatch = useDispatch();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("lang");

    switch (selectedLanguage) {
      case "en":
        setHighlight("en");
        break;
      case "es":
        setHighlight("es");
        break;
      case "pt":
        setHighlight("pt");
        break;
      case "fr":
        setHighlight("fr");
        break;
      default:
        setHighlight("en");
    }
  }, []);

  const languageChange = (language) => {
    localStorage.setItem("lang", language);
    window.location.reload();
  };

  const handleBetaInfo = () => {
    setShowInfo(true);
  };

  const handleClose = () => {
    setShowInfo(false);
  };

  return (
    <div className="footer-wrapper">
      <div className="footer-nav">
        <a
          // href="#"
          // href="javascript:void(0)"
          className={
            highlight === "en"
              ? "footer-nav-link highlight "
              : "footer-nav-link"
          }
          onClick={() => languageChange("en")}
        // onClick={() => localStorage.setItem("lang", "en")}
        >
          English
        </a>
        <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
          •
        </span>{" "}
        <a
          className={
            highlight === "es"
              ? "footer-nav-link highlight "
              : "footer-nav-link"
          }
          onClick={() => languageChange("es")}
        >
          Spanish
        </a>
        <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
          •
        </span>{" "}
        <a
          onClick={() => languageChange("pt")}
          className={
            highlight === "pt" ? "footer-nav-link highlight" : "footer-nav-link"
          }
        >
          Portuguese
        </a>
        <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
          •
        </span>{" "}
        {/* <a href="#" className="footer-nav-link">
          German
        </a> */}
        {/* <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
          •
          </span>{" "} */}
        <a
          onClick={() => languageChange("fr")}
          className={
            highlight === "fr"
              ? "footer-nav-link highlight "
              : "footer-nav-link"
          }
        >
          French
        </a>
        {/* <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
          •
          </span>{" "} */}
        {/* <a href="#" className="footer-nav-link">
          Italian
        </a> */}
        <span className={classes.charityInfoWrapper}>
          {showInfo ? (
            <Card className={classes.CardWrapper}>
              <CardHeader
                className={classes.headerBorder}
                subheader="We are working on getting the entire platform translated into your language. Please bear with us while we do this. Some parts of the platform may still be in English"
                action={
                  <IconButton onClick={handleClose}>
                    <img
                      className="charity-close-button"
                      src={x}
                      alt="close-button"
                    />
                  </IconButton>
                }
              />
            </Card>
          ) : (
            ""
          )}
          {showInfo ? <span className={classes.arrowInfo}>▼</span> : ""}
          <span style={{ color: "red" }}>*</span>
          <span className="beta"> <em>Beta</em></span>
          <img src={infoIcon} alt="info-icon" onClick={handleBetaInfo} />
        </span>
      </div>
      <div className="footer-navbar">
        <div className="footer-nav">
          {/* <a href="#" className="footer-nav-link" href="/ourPeople">
            {t("components.learnMoreFooter.ourPeople")}
          </a>{" "}
          <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
            •
          </span>{" "} */}
          <a href="#" className="footer-nav-link" href="/charityContact">
            {t("components.learnMoreFooter.areYouNonProfit")}
          </a>{" "}
          <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
            •
          </span>{" "}
          <a href="#" className="footer-nav-link" href="/contact">
            {t("components.learnMoreFooter.contact")}
          </a>{" "}
          <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
            •
          </span>{" "}
          <a href="#" className="footer-nav-link" href="/faqs">
            {t("components.learnMoreFooter.faq")}
          </a>{" "}
          <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
            •
          </span>{" "}
          <a href="#" className="footer-nav-link" href="/privacy">
            {t("components.learnMoreFooter.policies")}
          </a>{" "}
          <span className="footerDot" style={{ margin: "auto 0.5rem" }}>
            •
          </span>{" "}
          <a href="#" className="footer-nav-link" href="/terms">
            {t("components.learnMoreFooter.terms")}
          </a>
        </div>
        <Nav className="footer-wrapper-copyright ml-auto">
          <span>&#169; 2020 GoPoolit Ltd. All rights reserved</span>
        </Nav>
      </div>
      {/* <Navbar>
        <Nav className="footer-wrapper-items">
          <Nav.Link href="/ourPeople">Our People</Nav.Link>{" "}
          <span className="footerDot" style={{ margin: "0 0.5rem" }}>
            •
            </span>{" "}
          <Nav.Link href="/charityContact">
            Are you a non-profit?
            </Nav.Link>{" "}
          <span className="footerDot" style={{ margin: "0 0.5rem" }}>
            •
            </span>{" "}
          <Nav.Link href="/contact">Contact</Nav.Link>{" "}
          <span className="footerDot" style={{ margin: "0 0.5rem" }}>
            •
            </span>{" "}
          <Nav.Link href="/faqs">FAQ</Nav.Link>{" "}
          <span className="footerDot" style={{ margin: "0 0.5rem" }}>
            •
            </span>{" "}
          <Nav.Link href="/privacy">Policies</Nav.Link>{" "}
          <span className="footerDot" style={{ margin: "0 0.5rem" }}>
            •
            </span>{" "}
          <Nav.Link href="/terms">Terms</Nav.Link>
        </Nav>
        <Nav className="footer-wrapper-copyright ml-auto">
          <span>&#169; 2020 GoPoolit Ltd. All rights reserved</span>
        </Nav>

      </Navbar>
     */}
    </div>
  );
};
export default LeanmoreFooter;
