import axios from "axios";
import { BASEURL } from "../config";

export const reportTypeProfileApi = () => {
  return axios({
    url: `${BASEURL}/v1/reportProfileType`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const reportProfileApi = (
  report_type_id,
  user_id,
  report_description
) => {
  return axios({
    url: `${BASEURL}/v1/reportProfile`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      report_profile_type_id: `${report_type_id}`,
      reported_user_id: `${user_id}`,
      report_profile_description: report_description,
    },
    withCredentials: true,
  })
    .then((res) => res)
    .catch((err) => console.error(err));
};
