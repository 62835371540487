import React, { Component } from 'react';
import '../assets/scss/ErrorBoundary.scss';
import loading_animation from '../assets/images/loading_animation.gif';

class ErrorBoundary extends Component {

  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    }
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  clearStore = () => {
    localStorage.clear()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-container'>
          <div>
            {/* <h3>Oops!! Something went wrong. </h3> */}
            <img src={loading_animation} />
            <p>Please refresh the page or <a href='/' onClick={this.clearStore}>click here</a></p>

          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary;