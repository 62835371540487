import axios from "axios";
import { BASEURL } from "../config";
import axiosCreateContent from "../axios/createContentAxios";


export const PoolApi = (obj) => {
  //let dummyContentExternlId = "a16b3356-3567-4a8a-b877-6656ae69f24a"
  return axiosCreateContent({
    method: "POST",
    url: `${BASEURL}/v1/transactions`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': `Bearer ${token}`,
      // 'userexternalid': external_Id
    },
    withCredentials: true,
    data: {
      type: obj.type,
      cause_id: obj.cause_id,
      content_id: obj.content_id,
      country_id: obj.country_id,
      scale: obj.scale,
    },
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);
  // console.error(e.response.data)
};
export const GoldenBuzzerApi = (obj) => {
  //let dummyContentExternlId = "a16b3356-3567-4a8a-b877-6656ae69f24a"
  return axiosCreateContent({
    method: "POST",
    url: `${BASEURL}/v1/transactions`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': `Bearer ${token}`,
      // 'userexternalid': external_Id
    },
    withCredentials: true,
    data: {
      type: obj.type,
      cause_id: obj.cause_id,
      content_id: obj.content_id,
      country_id: obj.country_id,
      //scale: obj.scale
    },
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
