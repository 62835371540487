import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import appleStore from "../../assets/svg/appstore_1.svg";
import googleStore from "../../assets/svg/google-play-badge_2.svg";
import flame from "../../assets/svg/flame.svg";

// import iphone from "../../assets/images/iphone.png";
// import android from "../../assets/images/android.png";
// import downArrow from "../../assets/images/down-arrow.png";

import LoginNavbar from "./LoginNavbar";
import LeanmoreFooter from "./LeanmoreFooter";
import "../../assets/scss/LandingLearnMore/landing.scss";
// import tabImage from "../../assets/images/tabImage.png";

import IntroVideo from "./IntroVideo";
import PasswordScreen from "./PasswordScreen";
import CookieConsent from "../CookieConsent/CookieConsent";

import { storeCountriesList } from "../../actions/countryList";
// import { loadCharityNames } from "../../actions/CharityList";

import { useTranslation } from "react-i18next";

const LandingPage = (props) => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const [showLoginPage, setShowLoginPage] = useState(false);
  const dispatch = useDispatch();
  const tempPassword = useSelector((state) => state.tempPassword);

  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    if (isAuthorized) {
      props.history.push("/home");
    }
  }, [isAuthorized, props.history]);

  useEffect(() => {
    // dispatch(loadCharityNames());
    dispatch(storeCountriesList());
  }, []);

  useEffect(() => {
    if (tempPassword.statusCode !== 200) {
      setShowLoginPage(false);
    } else {
      setShowLoginPage(true);
    }
  }, [tempPassword.statusCode]);
  return (
    <>
      {/* { showLoginPage ? */}
      <section id="learn-more" className="learn-more-page-vertical-center">
        <CookieConsent />
        <LoginNavbar />

        <Container className="learn-more-container" fluid>
          <Row className="landing-row">
            <Col className="landing-col introVideo mx-auto" xs={12}>
              <IntroVideo />
              <div className="buttonLandingPage">
                <a href="/home" className="jump-to-content-button">
                  <img src={flame} width="17" height="20" alt="flame" />
                  <span>{t("landingPage.diveIn")}</span>
                </a>

                {/* <div className="intro-appStore">


                </div> */}
              </div>
              {/* <div className="intro-text"> */}
              {/* <div>
                  {/* <p>We are available on</p>
                  <a href="https://play.google.com/store/apps/details?id=com.gopoolit.gopoolit">
                    <img
                      className="google-play"
                      src={googleStore}
                      alt="android"
                    />
                  </a>
                 <p>{t("landingPage.iosComingSoon")}</p>
                </div> */}
              <p style={{ color: '#212121', fontWeight: "500", fontSize: "1.3rem", marginBottom: "1rem" }}>We are available on</p>
              <div className="intro-text">
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.gopoolit.gopoolit">
                    <img
                      className="google-play"
                      src={googleStore}
                      alt="android"
                    />
                  </a>
                  {/* <p>iOS app coming soon...</p> */}
                </div>
                <div>
                  <a href="https://apps.apple.com/in/app/gopoolit/id1520681643">


                    {/* <p>iOS app coming soon...</p> */}
                    <img
                      className="apple-button"
                      src={appleStore}
                      alt="iphone"
                    />
                  </a>
                </div>
              </div>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
        <LeanmoreFooter />
      </section>
      {/* : <PasswordScreen /> */}
      {/* } */}
    </>
  );
};

export default LandingPage;
