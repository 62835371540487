import axios from 'axios'
import { BASEURL } from '../config'


export const updateUserDetailsByUserId = (obj) => {
    return axios({
        method: 'POST',
        url: `${BASEURL}/v1/updateProfile`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': obj.externalId
        },
        data: {
            name: obj.name,
            gender: obj.gender,
            // dob: "1986-12-07T18:30:00.000Z",
            dob: obj.dob,
            state: obj.states,
            email_address: obj.email,
            mobile_number: obj.phone,
            preferred_cause: obj.charity,
            country: obj.countryExternalId
        },
        withCredentials: true,
    })
        .then(response => response.data.response)
        .catch(e => {
            console.error(e)
        })
}