import {GOOGLE_SIGN_IN} from '../../constants/action-types';

const initState ={}

const GoogleResponseReducer = (state = initState, action) => {
    if(action.type === GOOGLE_SIGN_IN){
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default GoogleResponseReducer
