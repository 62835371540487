import React, { useEffect, useState } from "react";
import { Image, Card, Container, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import axiosInstance from "../../../axios/axios";
import SharingOnMedia from "../SharingOnMedia";
import EmojiSlider from "../../EmojiSlider/EmojiSlider";
import ContentSlider from "./ContentSlider";

import CharityLogo from "../../../assets/images/charity logo.png";
import avatar from "../../../assets/images/avatar.png";
import Linkify from 'linkifyjs/react';

import "../../../assets/scss/Contents/unauthorizedUserActivityPage.scss";

import { BASEURL } from "../../../config";
import Charity from "../../Charity/Charity";

const commentSvg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4C16.9626 4 21 7.61235 21 12.0526C21 13.6047 20.5062 15.1082 19.5696 16.4082C19.7469 18.3578 20.2221 19.8052 20.9121 20.4909C21.0033 20.5816 21.0261 20.7202 20.9685 20.8348C20.9175 20.9371 20.8125 21 20.7 21C20.6862 21 20.6724 20.9991 20.6583 20.997C20.5368 20.98 17.7144 20.5762 15.6729 19.4047C14.5137 19.8696 13.2789 20.1053 12 20.1053C7.03739 20.1053 3 16.4929 3 12.0526C3 7.61235 7.03739 4 12 4ZM16.2 13.2456C16.8618 13.2456 17.4 12.7106 17.4 12.0526C17.4 11.3947 16.8618 10.8596 16.2 10.8596C15.5382 10.8596 15 11.3947 15 12.0526C15 12.7106 15.5382 13.2456 16.2 13.2456ZM12 13.2456C12.6618 13.2456 13.2 12.7106 13.2 12.0526C13.2 11.3947 12.6618 10.8596 12 10.8596C11.3382 10.8596 10.8 11.3947 10.8 12.0526C10.8 12.7106 11.3382 13.2456 12 13.2456ZM7.79999 13.2456C8.46179 13.2456 8.99999 12.7106 8.99999 12.0526C8.99999 11.3947 8.46179 10.8596 7.79999 10.8596C7.1382 10.8596 6.6 11.3947 6.6 12.0526C6.6 12.7106 7.1382 13.2456 7.79999 13.2456Z"
      fill="#3B3B3B"
    />
  </svg>
);

const UnauthorizedUserActivityPage = ({ propsDetails }) => {
  const [contents, setContents] = useState("");
  // const [emptyResponse, setEmptyResponse] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );
  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const len = 90;
  useEffect(() => {
    axiosInstance({
      method: "GET",
      url: `${BASEURL}/v1/contents/samples`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
    })
      // .then((response) => setContents(response.data))
      .then((response) => {
        if (response.data.response.statusCode === 204) {
          setContents("");
          setIsLoading(false);
          // setEmptyResponse(true);
        } else if (response.data.response.statusCode === 200) {
          setContents(response.data);
          setIsLoading(false);
          // setEmptyResponse(false);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return <p className="timeline">{month} month ago</p>;
      } else {
        return <p className="timeline">{month} months ago</p>;
      }
    } else if (days) {
      if (days === 1) return <p className="timeline">{days} day ago</p>;
      return <p className="timeline">{days} days ago</p>;
    } else if (hours) {
      if (hours === 1) return <p className="timeline">{hours} hour ago</p>;
      return <p className="timeline">{hours} hours ago</p>;
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return <p className="timeline">{minutes} minute ago</p>;
      return <p className="timeline">{minutes} minutes ago</p>;
    }
  };

  // if (!contents) return "Loading...";

  const handleShowComments = () => {
    if (!authorizedUser) {
      propsDetails.history.push("/login");
    }
  };

  // if (contents.response.data.contents === []) return null;

  const contentsArray =
    contents !== "" ? (
      contents.response.data.contents.map((item, index) => {
        return (
          <Card className="Activitypage-card" key={item.content_external_id}>
            <Card.Header className="activitypage-header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* {item.content_creator_id === userDetails.user_external_id ? ( */}
                  <Image
                    onError={(ev) => (ev.target.src = `${avatar}`)}
                    className="profile-picture"
                    src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                    roundedCircle
                    width="56"
                    height="56"
                  />
                  {/* ) : (
                <Image
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                  onClick={() => handleOtherUser(item.content_creator_id)}
                  className="profile-picture"
                  src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.content_creator_id}.jpg`}
                  roundedCircle
                  width="56"
                  height="56"
                />
              )} */}

                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    <span>{item.username}</span>
                    {/* {item.content_creator_id === userDetails.user_external_id ? (
                  ""
                ) : (
                  <>
                    {item.is_following ? (
                      <>
                        <span style={{ margin: "0 0.5rem" }}>•</span>{" "}
                        <span style={{ color: "#5b5b5b", fontWeight: "400" }}>
                          {" "}
                          Following
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )} */}
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>
                      {getPostedTime(item.content_created_on)}
                    </div>
                  </div>
                </div>
                {/* {item.content_creator_id === userDetails.user_external_id ? (
              ""
            ) : (
              <ReportPost contentExternalId={item.content_external_id} />
            )} */}
                {/* <ReportPost /> */}
              </div>
            </Card.Header>
            {item.content_description ? (
              <>
                {" "}
                <Card.Body>
                
                  {item.content_description.length <= len && (
                    <Card.Text><Linkify tagName="span">{item.content_description}</Linkify></Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">{ item.content_description.substring(0, len) + "..."}</Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          See more
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        <Linkify tagName="span">{ item.content_description}</Linkify>
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          See less
                        </span>
                      </Card.Text>
                    )}
                </Card.Body>
              </>
            ) : null}

            <div className="image-slider-container">
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>

            <div
              style={{ backgroundColor: "rgba(32, 119, 177, 0.08)" }}
              className="activity-page-charity"
            >
              <img
                src={item.cause_image}
                style={{ height: "40px", width: "40px" }}
                // onError={(ev) => (ev.target.src = `${Charity}`)}
              />
              <ul className="activity-page-content">
                <li className="activity-page-charityName">{item.cause_name}</li>
                <li className="activity-page-charityLocation">
                  {item.country_name}
                </li>
              </ul>
            </div>

            {/**Slider starts***************************************** */}
            <EmojiSlider
              contentExternalId={item.content_external_id}
              propsDetails={propsDetails}
            />
            {/*Slider ends *********************************************/}
            <div className="comments-share">
              <SharingOnMedia
                url={item.content_url.signedUrl}
                text={item.content_description}
                contentExternalId={item.content_external_id}
                propsDetails={propsDetails}
              />
              {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
            </div>
          </Card>
        );
      })
    ) : (
      <p>No data to show</p>
    );
  return (
    <div className="sample-contents">
      <Container>
        <Row>
          <Col sm={12} md={8} className="mx-auto">
            {isLoading ? (
              <p className="text-center py-2">Loading...</p>
            ) : (
              contentsArray
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UnauthorizedUserActivityPage;
