import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { getUserDetailsByUserId } from "../../actions/userProfile_action";
import { BASEURL } from "../../config";

import horizontalDots from "../../assets/svg/moreDot-horizontal.svg";
import { useTranslation } from "react-i18next";

const DeleteContent = ({
  item,
  handleContentExternalID,
  handleContentEditable,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const dispatch = useDispatch();

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleDeletePost = async (content_external_id) => {
    axios({
      url: `${BASEURL}/v1/deleteContent/${content_external_id}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        if (res.data.response.statusCode === 200) {
          handleContentExternalID(content_external_id);
          setShowDropDown(false);
          dispatch(getUserDetailsByUserId());
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEditPost = (content_external_id, content_description) => {
    handleContentEditable(true, content_external_id, content_description);
    setShowDropDown(false);
  };

  return (
    <React.Fragment>
      <img
        src={horizontalDots}
        onClick={() => setShowDropDown(!showDropDown)}
        style={{ cursor: "pointer", float: "right" }}
      />
      {showDropDown ? (
        <div>
          <span
            onClick={() =>
              handleEditPost(item.content_external_id, item.content_description)
            }
            className="dropdown-content"
          >
            {t("post.edit.editText")}
          </span>
          <span
            onClick={() => handleDeletePost(item.content_external_id)}
            className="dropdown-content"
          >
            {t("post.delete.deleteText")}
          </span>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default DeleteContent;
