import axios from 'axios'
import { BASEURL } from '../config'

export const getPaymentOrderId = (amount, currency, receiptId) => {
    return axios({
        method: 'POST',
        url: `${BASEURL}/v1/razorpayorderid`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true,
        data: {
            amount: amount,
            currency: currency,
            receipt: receiptId
        }
    })
        .then(response => response.data)
        .catch(e => {
            console.error(e)
        })
}
