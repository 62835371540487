import React, { useState, useRef, createRef, useEffect } from "react";
import { Card } from "react-bootstrap";

// Slick slider imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//image imports
// import whiteBrandLogo from "../../../assets/svg/white-brandLogo.svg";
import blueLogo from "../../../assets/svg/blue-logo.svg";
import unsupport from "../../../assets/images/unsupport.png";

const ContentSlider = ({ item }) => {
  const [imgCount, setImgCount] = useState(1);
  // const videoRef = useRef(null);
  // const [isEnded, setIsEnded] = useState(false);

  let videos = [];
  item.content_url.map((media) => {
    if (
      media.contentType !== "image/jpeg" &&
      media.contentType !== "image/png"
    ) {
      videos.push(createRef());
    } else {
      videos.push(null);
    }
  });

  let videoRef = useRef(videos);

  useEffect(() => {
    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     // console.log(entry.intersectionRatio, entry.isIntersecting)
    //     if (entry.isIntersecting && entry.intersectionRatio >= 0.6 && videoRef && videoRef.current) {
    //       videoRef.current.play();
    //     }
    //     else if (entry.intersectionRatio < 0.6 && videoRef && videoRef.current) {
    //       videoRef.current.pause(); //pause video
    //     }
    //   }, { threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });
    // if (videoRef.current) {
    //   observer.observe(videoRef.current);
    // }

    for (let i = 0; i <= item.content_url.length; i++) {
      let videoEnded = false;
      if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
        const observer = new IntersectionObserver(([entry]) => {
          if (videoRef.current[i].current) {
            if (videoRef.current[i].current.muted === true) {
              videoRef.current[i].current.muted = false;
            }
          }

          if (
            entry.intersectionRatio < 0.6 &&
            videoRef &&
            videoRef.current[i].current
          ) {
            videoRef.current[i].current.pause(); //pause video
          }
        });
        if (videoRef.current[i]) {
          if (videoRef.current[i].current !== null) {
            // console.log(videoRef.current[i].current);
            // console.log(videoRef.current[i]);
            observer.observe(videoRef.current[i].current);
          }
        }
      } else {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (
              entry.isIntersecting &&
              entry.intersectionRatio >= 0.6 &&
              videoRef &&
              videoRef.current[i].current &&
              videoEnded === true
            ) {
              videoRef.current[i].current.pause(); //pause video
            } else if (
              entry.isIntersecting &&
              entry.intersectionRatio >= 0.6 &&
              videoRef &&
              videoRef.current[i].current &&
              videoEnded === false
            ) {
              // console.log("Video in the view");
              videoRef.current[i].current.play();
              videoRef.current[i].current.addEventListener("ended", (e) => {
                videoEnded = true;
                videoRef.current[i].current.pause();
              });
            } else if (
              entry.intersectionRatio < 0.6 &&
              videoRef &&
              videoRef.current[i].current
            ) {
              videoRef.current[i].current.pause(); //pause video
            }
          },

          { threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
        );
        if (videoRef.current[i]) {
          if (videoRef.current[i].current !== null) {
            // console.log(videoRef.current[i].current);
            // console.log(videoRef.current[i]);
            observer.observe(videoRef.current[i].current);
          }
        }
      }
    }
  }, [videoRef.current]);

  const handleVideoEnd = () => {
    console.log("ended");
    // setIsEnded(true)
    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     console.log(entry)
    // videoRef.current.pause();
    //   });
    // if (videoRef.current) {
    //   observer.observe(videoRef.current);
    // }
  };
  //-------------- Slick slider --------------//
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div className="image-count">
        {item.content_url.length > 1 &&
          imgCount + "/" + item.content_url.length}
      </div>

      <Slider
        {...settings}
        afterChange={(currentSlide) => {
          setImgCount(currentSlide + 1);
        }}
      >
        {item.content_url.map((media, index) => {
          if (
            media.contentType === "image/jpeg" ||
            media.contentType === "image/png"
          ) {
            return (
              <div key={index} className="media-wrapper">
                <Card.Img
                  onError={(ev) => (ev.target.src = `${unsupport}`)}
                  className="activity-image"
                  src={media.signedUrl}
                />
              </div>
            );
          } else {
            return (
              <div key={index} className="media-wrapper">
                {media.isWebUpload ? (
                  <img
                    src={blueLogo}
                    alr="brand-logo"
                    className="video-brand-logo"
                  />
                ) : null}

                {/* <video
                  className="Activitypage-contentvideo"
                  id="myVideo"
                  src={media.signedUrl}
                  controls
                  autoPlay
                  muted
                ></video> */}
                {/* <video ref={videoRef} src={media.signedUrl} className="Activitypage-contentvideo" type="video/mp4" controls muted ></video> */}
                <video
                  ref={videoRef.current[index]}
                  src={media.signedUrl}
                  className="Activitypage-contentvideo"
                  type="video/mp4,video/quicktime"
                  controls
                  muted
                  preload="auto"
                ></video>
                {/* <video ref={videoMobileRef} src={media.signedUrl} className="Activitypage-contentmobilevideo" type="video/mp4" controls muted preload="auto"></video> */}
              </div>
            );
          }
        })}
      </Slider>
    </div>
  );
};

export default ContentSlider;
