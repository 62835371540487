import { STORE_USER_DATA } from '../../constants/action-types'

const initState = {}

const UserDetailsReducer = (state = initState, action) => {
    if (action.type === STORE_USER_DATA) {
        return {
            ...action.payload
        }

    }
    return state
}
export default UserDetailsReducer