import React, { useEffect, useState, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Button,
  Container,
  Image,
  Form,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
// import { DateTime, Interval } from "luxon";
import heic2any from "heic2any";

// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
//File imports
import {
  setupProfileData,
  getUserProfilePicture,
} from "../../../actions/storingUserData";
// import { getUserProfilePic } from "../../../api/getUserProfilePic";
// import { storeUserDetails } from '../../../actions/storingUserData';
// import data from "../../../assets/CountryCode/Countries.json";
// import FooterNavbar from '../Footer';
import Loader from "../../Loader";
// import LoginNavbar from '../LandingLearnMore/LoginNavbar';
import LoginNavbar from "../../LandingLearnMore/LoginNavbar";

//image import
import avatar from "../../../assets/images/avatar.png";
// import calenderImg from "../../../assets/svg/calenderIcon.svg";

//CSS imports
import "../../../assets/scss/Login/profileSetup.scss";

//DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "react-date-picker/dist/DatePicker.css";

// import DatePicker from "react-date-picker";

import statesOfCanada from "./StatesListCanada.json";
import statesOfUS from "./StatesListUS.json";
import LeanmoreFooter from "../../LandingLearnMore/LeanmoreFooter";
import { TrainOutlined } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

const ProfileSetup = () => {
  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [emailShow, setEmailShow] = useState();
  // const [CountryName, setCountryName] = useState("");
  // const [country, setCountry] = useState("");
  const [countryExternalId, setCountryExternalId] = useState("");

  // const [countryExternalId, countryExternalId] =
  // const [Dob, setDob] = useState("");
  const [startDate, setStartDate] = useState(null);
  // const [birthDate, setBirthDate] = useState(null);

  const [selectedProfile, setSelectedProfile] = useState(""); //Picture
  const [displayEditIcon, setDisplayEditIcon] = useState(false); //Edit icon
  // const [socialMediaProfilePic, setSocialMediaProfilePic] = useState()
  const [photo, setPhoto] = useState();

  const [message, setMessage] = useState(""); //A msg for the user
  // const [validationMessage, setValidationMessage] = useState("");
  const [validateAge, setValidateAge] = useState(false);
  const [Gender, setGender] = useState("");
  const [userState, setUserState] = useState("");
  const [passwordscreen, setPasswordScreen] = useState(false);
  const [contentType, setContentType] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const googleUserData = useSelector((state) => state.googleUserData);
  const FbUserData = useSelector((state) => state.FbUserData);
  // const AppleUserData = useSelector((state) => state.AppleUserData);

  const googleResponse = useSelector((state) => state.googleResponse.response);
  const FBResponse = useSelector((state) => state.FbResponseData.response);
  // const AppleResponse = useSelector((state) => state.AppleResponseData.response);

  const nexmoVerificationResponse = useSelector(
    (state) => state.nexmoVerificationResponse.response
  );
  const countriesList = useSelector((state) => state.countriesList); //country API

  const uploadedImageRef = useRef();
  const canvasRef = useRef();

  // console.log(DateTime.local());
  // const nexmoPhoneNumber = useSelector(state => state.nexmoPhoneNumber.phone);
  // const tokenDetails = useSelector((state) => state.tokenDetails);

  const dispatch = useDispatch();
  const history = useHistory();
  // const countryLists = data;

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  useEffect(() => {
    if (nexmoVerificationResponse && nexmoVerificationResponse.data) {
      setFullName("");
      setEmail("");
      setEmailShow(true);
    } else if (googleUserData && googleUserData[1]) {
      setFullName(googleUserData[1]);
      setEmail(googleUserData[5]);
      setEmailShow(false);
    } else if (FbUserData && !FbUserData.email) {
      setEmailShow(true);
      setFullName(FbUserData.name);
    } else if (FbUserData && FbUserData.name) {
      setFullName(FbUserData.name);
      setEmail(FbUserData.email);
      setEmailShow(false);
    }

    // else if (AppleUserData) {
    //   setFullName(`${AppleUserData.user.name.firstName}${AppleUserData.user.name.lastName}`);
    //   setEmail(AppleUserData.user.email);
    //   setEmailShow(false);
    // }
    // }, [FbUserData, googleUserData, nexmoVerificationResponse, AppleUserData, AppleResponse]);
  }, [googleUserData, FbUserData, nexmoVerificationResponse]);

  //SVG's Used
  const editIcon = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="8.5" fill="#2077B1" stroke="white" />
      <path
        d="M11 6.27614C11.0875 6.18859 11.1915 6.11915 11.3059 6.07177C11.4203 6.02439 11.5429 6 11.6667 6C11.7905 6 11.9131 6.02439 12.0275 6.07177C12.1419 6.11915 12.2458 6.18859 12.3333 6.27614C12.4209 6.36369 12.4903 6.46762 12.5377 6.58201C12.5851 6.6964 12.6095 6.819 12.6095 6.94281C12.6095 7.06662 12.5851 7.18922 12.5377 7.30361C12.4903 7.41799 12.4209 7.52193 12.3333 7.60948L7.83333 12.1095L6 12.6095L6.5 10.7761L11 6.27614Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  //fetch user country from IP address

  // useEffect(() => {
  //   const getPicFromGoogle = async () => {
  //     const googleProfilePic = await getUserProfilePic(`${googleUserData[4]}`)
  //     let base64image = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(googleProfilePic)))
  //     setSocialMediaProfilePic(base64image)
  //   }

  //   const getPicFromFacebook = async () => {
  //     const facebookProfilePic = await getUserProfilePic(`${FbUserData.picture}`)
  //     let base64image = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(facebookProfilePic)))
  //     setSocialMediaProfilePic(base64image)
  //   }

  //   if (googleUserData) {
  //     getPicFromGoogle()
  //   }
  //   else if (FbUserData) {
  //     getPicFromFacebook()
  //   }
  //   else if (nexmoVerificationResponse) {
  //     setSocialMediaProfilePic(avatar)
  //   }
  // }, [])

  // Truncating the string in dropdown buttons
  // const text_truncate = (str, length, ending) => {
  //   if (length == null) {
  //     length = 100;
  //   }
  //   if (ending == null) {
  //     ending = '...';
  //   }
  //   if (str.length > length) {
  //     return str.substring(0, length - ending.length) + ending;
  //   } else {
  //     return str;
  //   }
  // };

  const fileChangedHandler = async (e) => {
    const file = e.target.files[0];
    setIsLoading(true);

    if (file.type === "" || file.type === "image/heic") {
      let convertedBlob = await heicToJpg(file);
      let src = await getBase64(convertedBlob).then((base64) => {
        return base64;
      });
      // console.log(src);
      await setPhoto(src);
      setTimeout(async () => {
        let resizedImage = await resizeUploadedImage();
        setContentType("image/jpeg");
        setSelectedProfile(resizedImage);
        setMessage("");
        setIsLoading(false);
      }, 2000);
    } else if (file.type == "image/jpeg" || file.type == "image/png") {
      let src = await getBase64(file).then((base64) => {
        return base64;
      });
      await setPhoto(src);
      setTimeout(async () => {
        let resizedImage = await resizeUploadedImage();
        // console.log(resizedImage)
        setContentType("image/jpeg");
        setSelectedProfile(resizedImage);
        // setContentType(file.type)
        setMessage("");
        setIsLoading(false);
      }, 2000);
    } else {
      setSelectedProfile("");
      setDisplayEditIcon(!displayEditIcon);
      setMessage("Please select an image");
      setContentType("");
    }
  };

  const heicToJpg = (file) => {
    // fetching the heic image
    return heic2any({
      blob: file,
      toType: "image/jpeg",
    })
      .then((conversionResult) => {
        // conversionResult is an array of BLOBs that are PNG formatted images
        return conversionResult;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resizeUploadedImage = () => {
    let userImage = uploadedImageRef.current;
    let canvas = canvasRef.current;

    canvas.width = userImage.width;
    canvas.height = userImage.height;

    let [maxHeight, maxWidth, ratio] = [200, 200, 0];
    let [width, height] = [userImage.width, userImage.height];

    if (width > maxWidth) {
      ratio = maxWidth / width;
      userImage.style.width = maxWidth;
      userImage.style.height *= ratio;
      height *= ratio;
      width *= ratio;
    }

    if (height > maxHeight) {
      ratio = maxHeight / height;
      userImage.style.height = maxHeight;
      userImage.style.width *= ratio;
      height *= ratio;
      width *= ratio;
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(userImage, 0, 0, canvas.width, canvas.height);

    let dataURL = canvas.toDataURL("image/jpeg");
    return dataURL;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  function underAgeValidate(birthday) {
    // setDob(birthday);
    setStartDate(birthday); //user DOB

    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    // var optimizedBirthday = birthday.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(birthday); // user DOB

    var DOB =
      myBirthday.getFullYear() +
      "-" +
      (myBirthday.getMonth() + 1) +
      "-" +
      myBirthday.getDate(); //yyyy-mm-dd format
    // console.log(DOB);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
    // console.log(currentDate)

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);
    // console.log(myAge);

    if (myAge < 13) {
      // setValidationMessage("You need to be atleast 13 to sign up");
      setValidateAge(true);
    } else {
      setValidateAge(false);
    }
  }

  const handleSubmit = (
    e,
    FullName,
    Email,
    Dob,
    CountryName,
    selectedProfile,
    Gender,
    userState
  ) => {
    e.preventDefault();
    // console.log(CountryName);
    // var date_of_birth = Dob.toJSON().slice(0, 10) //yyyy-mm-dd format
    var country_Name = CountryName.split(",");
    // var data = selectedProfile.split(",");

    // let userProfilePicToUpload

    // The selected image will be uploaded to S3
    // { selectedProfile ? userProfilePicToUpload = selectedProfile : userProfilePicToUpload = socialMediaProfilePic }
    dispatch(getUserProfilePicture(selectedProfile, contentType));

    const obj = {
      fullname: FullName,
      email: Email,
      date_of_birth: Dob,
      countryName: country_Name[0],
      countryExternalId: country_Name[1],
      // countryExternalId: country_Name[1],
      // SelectedProfile: userProfilePicToUpload,
      gender: Gender,
      State: userState,
    };
    // console.log(obj)
    dispatch(setupProfileData(obj));
    setPasswordScreen(true);

    // console.log(obj);
    // console.log(obj.SelectedProfile);
    // dispatch(storeUserDetails(obj, nexmoPhoneNumber, FBResponse));
    // dispatch(storeProfilePicture(selectedProfile))
    //email verification?
  };

  const image = () => {
    if (googleResponse) {
      if (googleResponse.data.message) {
        return (
          <img
            className="profilesetup-profilepic"
            src={photo ? photo : avatar}
          />
        );
      } else if (googleResponse.data.user_external_id) {
        // return <Redirect to="/password" />;
        return (
          <Redirect
            to={{
              pathname: "/signup",
              state: "existing_user",
            }}
          />
        );
      }
    }
    if (FBResponse) {
      if (FBResponse.data.message) {
        return (
          <img
            className="profilesetup-profilepic"
            src={photo ? photo : avatar}
          />
        );
      } else if (FBResponse.data.user_external_id) {
        // return <Redirect to="/password" />;
        return (
          <Redirect
            to={{
              pathname: "/signup",
              state: "existing_user",
            }}
          />
        );
      }
    }

    // if (AppleResponse) {
    //   if (AppleResponse.data.message) {
    //     return (
    //       <img
    //         className="profilesetup-profilepic"
    //         src={photo ? photo : avatar}
    //       />
    //     );
    //   } else if (AppleResponse.data.user_external_id) {
    //     return <Redirect to="/password" />;
    //   }
    // }

    if (nexmoVerificationResponse) {
      if (nexmoVerificationResponse.data.user_external_id) {
        // return <Redirect to="/password" />;
        return (
          <Redirect
            to={{
              pathname: "/signup",
              state: "existing_user",
            }}
          />
        );
      } else if (nexmoVerificationResponse.data.message) {
        return (
          <img
            className="profilesetup-profilepic"
            src={photo ? photo : avatar}
          />
        );
      }
    }
  };

  //if (!googleResponse && !FBResponse && !nexmoVerificationResponse && !AppleResponse)
  if (!googleResponse && !FBResponse && !nexmoVerificationResponse)
    return <Loader />;

  // var USACountry = countryExternalId.split(",");
  // var USAExternalId = USACountry[1];
  const selectedCountry = countryExternalId.split(",");
  const selectedCountryExtenalId = selectedCountry[1];

  return (
    <>
      <div className="profileSetup-container">
        <LoginNavbar />
        <Container>
          <div className="row">
            <div className="col-sm-12">
              <Card className="profileSetup-card">
                <Card.Header className="profilesetup-header">
                  {t("profileSetup.title")}
                </Card.Header>
                <Card.Body>
                  <div>
                    {/* The function is needed to display the image to the user as the profile setup page loads */}
                    {image()}
                    {isLoading ? (
                      <span
                        style={{
                          position: "relative",
                          left: "50%",
                          right: "50%",
                          top: "-50px",
                        }}
                      >
                        <Spinner animation="border" variant="light" />{" "}
                      </span>
                    ) : null}
                    <img
                      src={photo}
                      ref={uploadedImageRef}
                      style={{ display: "none" }}
                    />
                    <canvas
                      id="mycanvas"
                      ref={canvasRef}
                      style={{ display: "none" }}
                    />

                    <div className="profilesetup-profile">
                      {t("profileSetup.profilePic")}
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <label htmlFor="files" className="btn">
                        <span className="profilesetup-editIcon">
                          {editIcon}
                        </span>
                      </label>
                      <input
                        id="files"
                        style={{ visibility: "hidden", width: "100%" }}
                        type="file"
                        accept="image/*"
                        onChange={fileChangedHandler}
                      />
                    </div>
                    <span className="profilesetup-message">{message}</span>
                  </div>

                  <Form
                    className="profilesetup-form"
                    onSubmit={(e) =>
                      handleSubmit(
                        e,
                        FullName,
                        Email,
                        startDate,
                        countryExternalId,
                        selectedProfile,
                        Gender,
                        userState
                      )
                    }
                    autoComplete="off"
                  >
                    <Form.Group>
                      <Form.Label className="profilesetup-form-label">
                        {t("profileSetup.formData.fullName")} *
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder={t("profileSetup.formData.fullName")}
                        onChange={(e) => setFullName(e.target.value)}
                        value={FullName || ""}
                        maxLength="30"
                        required
                      />
                      <span style={{ fontSize: "10px" }}>
                        {t("profileSetup.formData.fullNameHint")}
                      </span>
                    </Form.Group>
                    {/* <Form.Row> */}
                    <Row>
                      <Col sm={6}>
                        <Form.Group
                          as={Col}
                          controlId="formGridEmail"
                          className="px-1"
                        >
                          <Form.Label className="profilesetup-form-label ">
                            {t("profileSetup.formData.identity.text")}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            size="lg"
                            value={Gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                          >
                            <option value="" defaultValue disabled hidden>
                              {t("profileSetup.formData.identity.select")}
                            </option>
                            <option value="male">Male</option>
                            <option value="Female">Female</option>
                            <option value="me">Me</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col sm={6} className="">
                        <Form.Group as={Col} className="px-1">
                          <Form.Label className="profilesetup-form-label ">
                            {t("profileSetup.formData.dob")} *
                          </Form.Label>
                          {/* <Form.Control required size="lg" type="date" placeholder="dd/mm/yy" onChange={e => underAgeValidate(e.target.value)} value={Dob} /> */}
                          {/* <div className={`date-picker-div form-control-lg ${validateAge && "error-input"}`}> */}
                          <div
                            className={`form-control-lg datePicker ${validateAge && "error-input"
                              }`}
                          >
                            {/* <DatePicker
                            // calendarIcon={
                            //   <img
                            //     className="dropdown-calIcon"
                            //     src={calenderImg}
                            //     alt="dropdownButtonArrow"
                            //   />
                            // }
                            onChange={(date) => underAgeValidate(date)}
                            // clearIcon={null}
                            format="d/M/yyyy"
                            maxDate={new Date()}
                            minDate={new Date("1890-04-11T10:20:30Z")}
                            value={birthDate}
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            yearPlaceholder="yyyy"
                            showMonthDropdown
                            showYearDropdown
                            required
                            // className={`date-picker-div form-control-lg ${validateAge && "error-input"}`}
                          /> */}

                            <DatePicker
                              selected={startDate}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => underAgeValidate(date)}
                              dayPlaceholder="dd"
                              monthPlaceholder="mm"
                              yearPlaceholder="yyyy"
                              peekNextMonth
                              showMonthDropdown
                              maxDate={new Date()}
                              minDate={new Date("1890-04-11T10:20:30Z")}
                              showYearDropdown
                              dropdownMode="select"
                              required
                              placeholderText="dd/mm/yy"
                            />
                          </div>
                          {validateAge ? (
                            <p
                              className={`profilesetup-message ${validateAge && "error-message"
                                }`}
                            >
                              You need to be atleast 13 to sign up
                            </p>
                          ) : (
                              ""
                            )}

                          {/* <DatePicker selected={startDate} onChange={date => underAgeValidate(date)} placeholderText="dd/mm/yy" maxDate={new Date()} className={`form-control-lg ${validateAge && "error-input"}`} dateFormat="dd/MM/yyyy" /> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* </Form.Row> */}

                    {/* <Form.Row>
                    
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="profile-edit-label">Country *</Form.Label>
                      <Form.Control as="select" size="lg" placeholder="country code" value={CountryName} onChange={e => setCountryName(e.target.value)} placeholder="country code">
                        <option value="" disabled hidden>Choose Country </option>
                        {countryLists.map((countryData) => (
                          <option key={countryData.countryCode} value={countryData.name}>
                            {countryData.name}
                          </option>))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label className="profile-edit-label">state *</Form.Label>
                      <Form.Control size="lg" type="text" placeholder="state" onChange={e => setStates(e.target.value)} value={State} required />
                    </Form.Group>
                  </Form.Row> */}
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label className="profilesetup-form-label">
                          {t("profileSetup.formData.country")} *
                        </Form.Label>

                        <Form.Control
                          as="select"
                          size="lg"
                          value={countryExternalId}
                          onChange={(e) => setCountryExternalId(e.target.value)}
                          required
                        >
                          <option
                            value=""
                            defaultValue
                            style={{ display: "none" }}
                          >
                            {t("profileSetup.formData.countryPlaceholder")}
                          </option>
                          {countriesList.data.map((countryData, index) => (
                            <option
                              value={`${countryData.country_name},${countryData.country_external_id}`}
                              key={countryData.country_external_id}
                            >
                              {countryData.country_name}
                            </option>
                          ))}
                        </Form.Control>

                        {/* <DropdownButton className="dropdown-btn" onSelect={e => setCountry(e)} title={<span className='dropdown-span'>{CountryName ? text_truncate(CountryName, 18) : 'Country'}</span>}>
                        <div className='dropdown-options'>
                          {
                            countriesList.data.map((countryData, index) => (
                              <Dropdown.Item eventKey={countryData.country_name} key={countryData.country_id}>{countryData.country_name}</Dropdown.Item>
                            ))
                          }
                        </div>
                      </DropdownButton> */}

                        {/* <CountryDropdown
                        value={CountryName}
                        onChange={(val) => setCountryName(val)} className="countryStateField form-control-lg" required /> */}
                      </Form.Group>
                    </Form.Row>

                    {/* The state field should come only for USA */}

                    {selectedCountryExtenalId ===
                      "fb30100a-d3a5-4562-9bf7-cbb3a8b56737" ? (
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label className="profilesetup-form-label">
                              {t("profileSetup.formData.state")} *
                          </Form.Label>

                            <Form.Control
                              as="select"
                              size="lg"
                              value={userState}
                              onChange={(e) => setUserState(e.target.value)}
                              required
                            >
                              <option
                                value=""
                                defaultValue
                                style={{ display: "none" }}
                              >
                                {t("profileSetup.formData.statePlaceholder")}
                              </option>
                              {statesOfUS.map((stateData, index) => (
                                <option
                                  value={`${stateData.state}`}
                                  key={stateData.id}
                                >
                                  {stateData.state}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                      ) : selectedCountryExtenalId ===
                        "b5b46806-86b2-4f8e-98ec-8652fe74a6fc" ? (
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                              <Form.Label className="profilesetup-form-label">
                                {t("profileSetup.formData.state")} *
                          </Form.Label>

                              <Form.Control
                                as="select"
                                size="lg"
                                value={userState}
                                onChange={(e) => setUserState(e.target.value)}
                                required
                              >
                                <option
                                  value=""
                                  defaultValue
                                  style={{ display: "none" }}
                                >
                                  {t("profileSetup.formData.statePlaceholder")}
                                </option>
                                {statesOfCanada.map((stateData, index) => (
                                  <option
                                    value={`${stateData.state}`}
                                    key={stateData.id}
                                  >
                                    {stateData.state}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                        ) : null}

                    <Form.Group>
                      <Form.Label className="profilesetup-form-label">
                        {t("profileSetup.formData.email")} *
                      </Form.Label>
                      {emailShow ? (
                        <Form.Control
                          size="lg"
                          type="email"
                          placeholder={t("profileSetup.formData.email")}
                          onChange={(e) => setEmail(e.target.value)}
                          value={Email}
                          required
                        />
                      ) : (
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder={t("profileSetup.formData.email")}
                            onChange={(e) => setEmail(e.target.value)}
                            value={Email}
                            required
                            readOnly
                          />
                        )}
                      <Form.Text className="profilesetup-email-text">
                        {t("profileSetup.formData.emailhint")}
                      </Form.Text>
                    </Form.Group>
                    {validateAge ? (
                      <Button
                        className="profilesetup-button"
                        type="submit"
                        disabled
                      >
                        {t("profileSetup.formData.next")}
                      </Button>
                    ) : (
                        <Button className="profilesetup-button" type="submit">
                          {t("profileSetup.formData.next")}
                        </Button>
                      )}
                  </Form>

                  {passwordscreen && (
                    <Redirect
                      to={{
                        pathname: "/profile-password",
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </Container>
        <LeanmoreFooter />
      </div>
    </>
  );
};

export default ProfileSetup;
