import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BASEURL } from "../../config";
import axios from "axios";

export default function useHashtags(pageNumber, hashTagInfo) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentsArray, setContentsArray] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  // console.log(hashtag_external_id)
  let hashtag_external_id = hashTagInfo.hashTagExtId;

  useEffect(() => {
    setContentsArray([]);
  }, [hashtag_external_id]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: `${BASEURL}/v1/contents/trending/${hashtag_external_id}`,
      params: {
        pageNo: pageNumber,
      },
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((response) => {
        setLoading(true);
        // console.log(response);
        // console.log('Running UseHashTags')
        setContentsArray((prevContentsArray) => {
          // return [...prevContentsArray, ...response.data.response.data]

          if (response.data.response.statusCode !== 200) {
            return [...prevContentsArray];
          } else {
            return [...prevContentsArray, ...response.data.response.data];
          }
        });
        setHasMore(response.data.response.data.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [pageNumber, hashtag_external_id]);
  return { loading, error, contentsArray, hasMore };
}
