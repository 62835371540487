import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Image, Row, Col, Container } from "react-bootstrap";
import "../../assets/scss/UserProfile/profile.scss";
import avatar from "../../assets/images/avatar.png";
import { Redirect } from "react-router-dom";
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import Post from "../UserProfile/Post";
import Following from "../UserProfile/Following";
import Followers from "../UserProfile/Followers";
import { getUserDetailsByUserId } from "../../actions/userProfile_action";
import { getFollowingList } from "../../actions/following_action";
import { getFollowersList } from "../../actions/follower_action";
import { userProfileDetails } from "../../actions/userProfile_action";
import { userPosts } from "../../../src/api/UserPosts";
import Loader from ".././Loader";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const followingCount = useSelector((state) => state.followingList.data);
  const followersCount = useSelector((state) => state.followersList.data);
  const userProfileData = useSelector((state) => state.userProfileDetails.data);
  const passwordDetails = useSelector(
    (state) => state.verifyEnteredPassword.data
  ); // to get country_currency_symbol
  const userDetails = useSelector((state) => state.userDetails); //Converted the profileDetailsById to userDetails

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const [editProfile, seteditProfile] = useState(false);
  const [followers, setFollowers] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followingCounts, setFollowingCount] = useState(null);
  const [followersCounts, setFollowersCount] = useState(null);
  const [pooledCounts, setPooledCount] = useState(null);
  const [post, setPost] = useState(true);
  // const [userPostCount, setUserPostCount] = useState(null);
  const dispatch = useDispatch();

  // let country_wallet_currency_sign = passwordDetails.response.data.user_profile.country_wallet_currency_sign

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  //svg to show  location icon.
  const locationsvg = (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.07027 0C2.27451 0 0 2.27451 0 5.07025C0 8.53985 4.5374 13.6334 4.73058 13.8486C4.91204 14.0507 5.22884 14.0503 5.40997 13.8486C5.60315 13.6334 10.1405 8.53985 10.1405 5.07025C10.1405 2.27451 7.86601 0 5.07027 0ZM5.07027 7.62123C3.66366 7.62123 2.51932 6.47687 2.51932 5.07025C2.51932 3.66363 3.66368 2.51929 5.07027 2.51929C6.47687 2.51929 7.6212 3.66366 7.6212 5.07028C7.6212 6.47689 6.47687 7.62123 5.07027 7.62123Z"
        fill="#575757"
      />
    </svg>
  );

  // useEffect(() => {
  //   async function fetchData() {
  //     let UserPostResponse = await userPosts();
  //     setUserPostCount(UserPostResponse.data.length);
  //   }
  //   fetchData();

  // }, [])
  useEffect(() => {
    dispatch(getFollowingList(null));
    dispatch(getFollowersList(null));
    dispatch(userProfileDetails());
    dispatch(getUserDetailsByUserId());
  }, []);

  useEffect(() => {
    let folwingCount = followingCount ? followingCount.length : 0;
    let follwerCount = followersCount ? followersCount.length : 0;
    // let pooledAmount = userProfileData ? userProfileData.total_amount_pooled : 0
    // let pooledAmount = passwordDetails
    //   ? passwordDetails.response.data.user_profile.totalPooled
    //   : 0;
    let pooledAmount = userDetails.totalPooled;
    setFollowingCount(folwingCount);
    setFollowersCount(follwerCount);
    setPooledCount(pooledAmount);
  }, [followingCount, followersCount, userProfileData]);

  //to load page on top
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  //function to display profile pic based on login type in dashboard section
  const image = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <img className="profiledashboard-profilepic" src={userDetails ? userDetails.user_profile_pic : ""} roundedCircle /> */}
        <span>
          <Image
            className="profiledashboard-profilepic"
            onError={(ev) => (ev.target.src = `${avatar}`)}
            src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`}
            alt="profile-picture"
            roundedCircle
          />
        </span>
        <div className="profiledashboard-wrapper">
          <p className="profiledashboard-user">
            {userDetails ? userDetails.user_full_name : ""}
          </p>
          <div style={{ display: "flex" }}>
            <span className="profile-dashboard-userName-location">
              {userDetails ? userDetails.username : ""}
            </span>
            <span className="vertical-line">|</span>
            <span className="profile-dashboard-userName-location">
              {locationsvg}&nbsp;{userDetails ? userDetails.country_name : ""}
            </span>
          </div>
        </div>
      </div>
    );
  };
  //onclick function on edit profile
  const editProfileClick = () => {
    seteditProfile(true);
    // dispatch(getUserDetailsByUserId(tokenDetails.token, tokenDetails.user_external_id))
  };
  //function to display only post
  const handlePost = () => {
    setPost(true);
    setFollowers(false);
    setFollowing(false);
  };
  //function to display only Followers
  const handleFollowers = () => {
    setPost(false);
    setFollowers(true);
    setFollowing(false);
  };
  //function to display only Following
  const handleFollowing = () => {
    setPost(false);
    setFollowers(false);
    setFollowing(true);
  };

  // if (!followersCount.data) return <Loader />

  return (
    <section className="profile-post-contribute-raised">
      {/* code to display navbar by importing Component */}
      <ContentNavBar />
      {/* code to display dashboard of user  */}
      <Card>
        <Container style={{ paddingTop: "2.5rem" }}>
          <Row>
            <Col sm={12} md={10} lg={8} className="mx-auto profile-header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{image()}</div>
                <Button
                  className="edit-profile-button"
                  variant="outline-info"
                  onClick={editProfileClick}
                >
                  {t("profile.editProfile")}
                </Button>
              </div>

              <div className="flex-container">
                <div onClick={handlePost}>
                  <span className="profile-dashboard-noOfPost">
                    {userDetails.totalposts}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {t("profile.posts")}
                  </p>
                </div>
                <div>
                  <span className="profile-dashboard-noOfPost">
                    {userDetails.country_wallet_currency_sign}
                    {Number(userDetails.totalPooled).toFixed(2)}
                    {/* {Math.abs(userDetails.totalPooled) > 999
                      ? Math.sign(userDetails.totalPooled) *
                      (Math.abs(userDetails.totalPooled) / 1000).toFixed(
                        2
                      ) +
                      "k"
                      : Math.sign(userDetails.totalPooled) *
                      Math.abs(userDetails.totalPooled)} */}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {t("profile.poolCollected")}
                  </p>
                </div>

                {/* <Link to="/following"> */}
                <div onClick={handleFollowers}>
                  <span className="profile-dashboard-noOfPost">
                    {followersCounts}
                  </span>
                  {/* {followersCount.length ? <span className="profile-dashboard-noOfPost">{followersCount.length}</span> : 0} */}

                  <p className="profile-dashboard-Poststext">
                    {t("profile.followers")}
                  </p>
                </div>
                {/* </Link> */}

                <div onClick={handleFollowing}>
                  {/* {followingCount.length ? <span className="profile-dashboard-noOfPost">{followingCount.length}</span> : 0} */}
                  <span className="profile-dashboard-noOfPost">
                    {followingCounts}
                  </span>
                  <p className="profile-dashboard-Poststext">
                    {t("profile.following")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Card>

      {/* code to render post,followers,following ui based on condition */}
      <Container>
        <Row style={{ paddingTop: "1rem" }}>
          <Col sm={12} md={10} lg={8} className="mx-auto">
            {following ? <Following /> : ""}
            {followers ? <Followers /> : ""}
            {/* {post ? <Post user_country={userProfileData.country_name} /> : ''} */}
            {post ? <Post user_country={userDetails.country_name} /> : ""}
          </Col>
        </Row>
      </Container>

      {editProfile ? (
        <Redirect
          to={{
            pathname: "/settingAndPrivacy",
          }}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default Profile;
