import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const JWTPrivateRoute = ({ component: Component, ...rest }) => {
    //   const tempPassword = useSelector((state) => state.tempPassword);

    const JWTtoken = useSelector(state => state.tempPassword.tempToken)

    return (
        <Route
            {...rest}
            render={props => JWTtoken ? (<Component {...props} />) : (<Redirect to="/" />)}
        />
    )
}

export default JWTPrivateRoute