import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//File Imports
import { loadFbLoginApi } from "../sdk/FaceBookSDK";
import { userAuthorized } from "../actions/isAuthorized";

import "../assets/scss/Logout.scss";
import { logoutUser } from "../actions/storingUserData.js";
import { useTranslation } from "react-i18next";

const Logout = () => {
  //Setting a logout variable in the state
  const [logout, setLogout] = useState(false);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  //Fetching info from the redux-store
  const signedInWithGoogle = useSelector((state) => state.googleUserData);
  const signedInWithFB = useSelector((state) => state.FbUserData);

  const dispatch = useDispatch();
  const history = useHistory();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  //fetch user country from IP address
  useEffect(() => {
    insertGapiScript(); //Google Log in
    loadFbLoginApi(); //Facebook Log in
  }, []);

  // Appending the script for the Google API
  const insertGapiScript = () => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => {
      initializeGoogleSignIn();
    };
    document.body.appendChild(script);
  };

  const initializeGoogleSignIn = () => {
    window.gapi.load("auth2", () => {
      window.gapi.auth2.init({
        client_id:
          "324133888500-4nka470k9oki5ne49t9b66l63qomrgvu.apps.googleusercontent.com",
      });
    });
  };

  //Signing out the user from Google
  const signOut = () => {
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      // console.log("User signed out from Google");
      setLogout(true);
    });
  };

  //Logging out the user from FaceBook
  const faceBookLogout = () => {
    window.FB.logout(() => {
      setLogout(true);
    });
  };

  // Function which runs when the user clicks on the logout button
  const handleLogout = () => {
    if (signedInWithFB) {
      // faceBookLogout();
      localStorage.clear(); //clear data from localstorage
      dispatch(logoutUser());
      dispatch(userAuthorized(false));
      // setLogout(true)
      history.push("/");
      window.location.reload(true);
    } else if (signedInWithGoogle) {
      signOut();
      localStorage.clear(); //clear data from localstorage
      dispatch(logoutUser());
      dispatch(userAuthorized(false));
      // setLogout(true)
      history.push("/");
      window.location.reload(true);
    } else {
      localStorage.clear(); //clear data from localstorage
      dispatch(logoutUser());
      dispatch(userAuthorized(false));
      // setLogout(true)
      history.push("/");
      window.location.reload(true);
    }
  };

  // Conditionally loading the SDK's for better load speed
  if (signedInWithFB) {
    loadFbLoginApi();
  } else if (signedInWithGoogle) {
    insertGapiScript();
  }

  const logoutIcon = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7295 10.9638C21.6912 10.8723 21.6365 10.789 21.5667 10.7193L19.3174 8.46987C19.0242 8.17736 18.5502 8.17736 18.2569 8.46987C17.9637 8.76313 17.9637 9.2379 18.2569 9.53042L19.2267 10.5002H15.0371C14.6224 10.5002 14.2871 10.8362 14.2871 11.2502C14.2871 11.6643 14.6224 12.0003 15.0371 12.0003H19.2267L18.2569 12.9701C17.9637 13.2633 17.9637 13.7381 18.2569 14.0306C18.4031 14.1776 18.5951 14.2504 18.7872 14.2504C18.9792 14.2504 19.1712 14.1776 19.3174 14.0306L21.5667 11.7812C21.6365 11.7122 21.6912 11.629 21.7295 11.5367C21.8052 11.3538 21.8052 11.1468 21.7295 10.9638Z"
        fill="#55A3C6"
      />
      <path
        d="M16.5392 13.5006C16.1244 13.5006 15.7892 13.8366 15.7892 14.2506V18.0008H12.7892V6.00014C12.7892 5.66938 12.5717 5.37686 12.2544 5.28162L9.64961 4.50009H15.7892V8.2503C15.7892 8.66432 16.1244 9.00032 16.5392 9.00032C16.9539 9.00032 17.2892 8.66432 17.2892 8.2503V3.75006C17.2892 3.33601 16.9539 3 16.5392 3H4.53906C4.51206 3 4.48805 3.01125 4.46182 3.01424C4.42656 3.018 4.39432 3.02398 4.36057 3.03224C4.28182 3.05249 4.21056 3.08325 4.14383 3.12523C4.12734 3.13575 4.10709 3.13648 4.09134 3.14847C4.08529 3.15301 4.08304 3.16127 4.07703 3.16577C3.99529 3.23025 3.92705 3.309 3.87755 3.40277C3.86704 3.42302 3.86479 3.44478 3.85656 3.46577C3.83255 3.52277 3.80632 3.57828 3.79732 3.64128C3.79356 3.66378 3.80031 3.68477 3.79957 3.70654C3.79884 3.72155 3.78906 3.73505 3.78906 3.75003V18.7508C3.78906 19.1086 4.0418 19.4161 4.39207 19.4859L11.8921 20.986C11.9409 20.9965 11.9904 21.001 12.0391 21.001C12.2109 21.001 12.3796 20.9417 12.5146 20.8307C12.6879 20.6882 12.7891 20.4759 12.7891 20.2509V19.5009H16.5392C16.9539 19.5009 17.2892 19.1649 17.2892 18.7509V14.2506C17.2892 13.8366 16.9539 13.5006 16.5392 13.5006Z"
        fill="#55A3C6"
      />
    </svg>
  );

  return (
    <div className="logout-button">
      {/* {console.log(logout)} */}
      {/* {logout && <Redirect to="/login" />} */}
      <div onClick={handleLogout}>
        {logoutIcon}
        <span>{t("contentNavBar.logout")}</span>
      </div>
    </div>
  );
};

export default Logout;
