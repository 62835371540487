import React from "react";
import { Container, Row, Col, Navbar, Nav, NavLink } from "react-bootstrap";

import "../../assets/scss/SettingsPrivacy/termsAndConditions.scss";
import brandLogo from "../../assets/svg/brandLogo.svg";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div className="privacy-terms-container">
        <div className="privacy-terms-navbar-wrapper">
          <Navbar>
            <Navbar.Brand href="/home">
              <img src={brandLogo} width="66" height="48" alt="brand-logo" />
            </Navbar.Brand>

            <Nav className="ml-auto">
              <Nav.Item>
                <NavLink className="privacy-terms-navlink" href="/">
                  Back to GoPoolit
                </NavLink>
              </Nav.Item>
            </Nav>
          </Navbar>
        </div>

        <Container>
          <Row>
            <Col>
              <h3 className="terms-header">
                Data Protection and Privacy Policy
              </h3>
              <p className="terms-update-text">
                Last updated on 12 December 2020.
              </p>
              <p>
                <span className="terms-span">
                  This Data Protection Agreement and Privacy Policy covers the
                  Platform and the Service provided by GoPoolit (Company, we,
                  us, our). We are registered in England and Wales have our
                  registered office at 110a Hawshaw Lane, Hoyland, Barnsley,
                  England, S74 9ES. Definitions used here have the same meaning
                  attributed in our Services Agreement. As Company processes
                  Personally Identifiable Information (PII) as a “Data
                  Processor” for clients in addition to Processing PII on our
                  own behalf as “Data Controller”; We have registered with the
                  ICO under reference number: ZA806316.
                </span>
                <span className="terms-span">
                  Your privacy is very important to us. This policy applies to
                  the Platform and Services (as defined in our Services
                  Agreement). This document explains how Company uses the
                  information provided and the procedures and processes around
                  personal data supplied to us. The Data Protection Act 2018,
                  the General Data Protection Regulation, and other applicable
                  national data privacy laws (together, Data Privacy Laws) place
                  obligations on us in relation to the personal data that we
                  collect and hold. This Data Protection and Privacy Policy
                  (together with our Website Terms and Conditions and (if
                  applicable) our Service Terms and Conditions) sets out how we
                  comply with Data Privacy Laws, including detailing the basis
                  on which we may process your personal data and information in
                  connection with our Programmes.
                </span>
                <span className="terms-span">
                  This Data Protection Agreement and Privacy Policy is to be
                  made part of and incorporated into the Services Agreement. You
                  acknowledge and agree that the Services in such circumstances
                  would incorporate certain data processing activities by
                  GoPoolit (and its subsidiaries and subcontractors) under the
                  General Data Protection Regulation 2016/679 (hereinafter
                  referred to as the “GDPR”) and applicable national data
                  protection laws of EU/EEA Member States (collectively with
                  GDPR, the “Data Protection Laws”); that the European
                  Commission has adopted, by Commission Decision of 5 February
                  2010 (2010/87/EU), a new set of standard contractual clauses
                  for the transfer of personal data to processors established in
                  non-EU/EEA countries (hereinafter referred to as the
                  “Clauses”); and that these Clauses are considered by the
                  European Commission and the Data Protection Laws to offer
                  adequate safeguards with respect to the protection of the
                  privacy and fundamental rights and freedoms of individuals;
                  and that it is intended that the Clauses apply: (i) to
                  contractual data processing where the Processor transfers data
                  from a country which is determined by the EU commission to
                  have an adequate level of data protection to a third country
                  where an adequate level of protection is not ensured, and (ii)
                  where the Processor uses a Sub-processor for the provisioning
                  of the Services and/or processing of data.
                </span>
                <span className="terms-span">
                  By visiting our sites and using our Services you are doing so
                  on the basis of the rules in this Policy. We may process
                  information, including personal data, about you that you
                  provide to us. We will process your data for the sole purpose
                  of providing the Services as permitted by the Services
                  Agreement. You acknowledge and agree that this Policy and the
                  Services Agreement collectively contain your instructions for
                  the processing of your data. This data may include information
                  provided by you at the time of registering to sign up to our
                  Services or when updating any of the information provided at
                  the time of registering, at the time of subscribing to our
                  Services or posting material to our sites; if you subscribe to
                  any of our mailing lists; if you complete any surveys or
                  feedback that we may invite you to participate in; and in
                  connection with your other communications with us.
                </span>
                <span className="terms-span">
                  If You choose to create an account with us, you are required
                  to provide certain personal and non-personal data. We collect
                  personal data such as your name, username, password, and
                  e-mail address. Additionally, we may also require your phone
                  number, date of birth and country of residence. Your name and
                  username is always public, and you can choose to use your real
                  name or a pseudonym. We use your contact information such as
                  your phone number or email address to authenticate your
                  account and keep it safe and secure from fraud or abuse. You
                  may need to provide us with some of this information in order
                  to be able to receive the Services. By using our Services, you
                  provide your specific consent for us to process your personal
                  data.
                </span>
                <span className="terms-span">
                  We may collect and process technical information about your
                  computer, including (where available) your Internet Protocol
                  address, login information, operating system and browser type
                  and version. We collect this information for system
                  administration purposes and to report aggregate information on
                  usage to other organisations. We collect this information by
                  using cookies (see below for further details) and other
                  similar technologies.
                </span>
                <span className="terms-span">
                  We will only use your personal data when the law allows us to.
                  We may use personal data that we hold about you to provide the
                  Services, to record your consent, to contact you in relation
                  to Services (including by email, SMS, post or telephone); to
                  contact you in relation to other projects (including by email,
                  SMS, post or telephone), in connection with any agreements or
                  other arrangements between you and us (including the Website
                  Terms and Conditions);· to notify you about changes to our
                  organisation or services; and to personalise your experience
                  on our sites.
                </span>
                <span className="terms-span">
                  We will process your personal data on the basis of your
                  consent, if requested and obtained, and/or our legitimate
                  interests (which include but are not limited to (i) matching
                  donations with charities to ensure the success of our
                  Services; (ii) enabling the public to engage with, evaluate,
                  promote and allow us to donate to the charities; (iii) the
                  administration, improvement and promotion of our Services and
                  our Platform; (iv) management of relationships with our
                  donors, charities, Vendors and other third parties; and (v)
                  for compliance with applicable laws, rules and regulations).
                  Additional legitimate Interests would include analytics
                  (aggregate or use on a personal level, customer analysis,
                  profiling and direct marketing combining information from
                  multiple sources), research (investigate the product roadmap
                  and ensure our services and products are developed in
                  accordance with demand), due diligence (prevent fraud when
                  subscribing to certain products or services, we may need to
                  conduct further investigations around fraud, bribery and
                  corruption), marketing (we will contact you via telephone and
                  email if you have subscribed to our services for additional
                  offerings, administration and research), personalisation (we
                  will personalise, enhance or improve our communications,
                  products and services to our customers for their benefit),
                  performance of a contract (related to the Services you
                  subscribe to). When our processing is based on your consent,
                  and not any other legal basis, you have the right to withdraw
                  your consent at any time. This withdrawal will not affect the
                  lawfulness of processing prior to the withdrawal. We will
                  never disclose or sell your data to any third parties for any
                  purpose. However, we may disclose or share your data with our
                  affiliates, subsidiaries, Vendors and service providers for
                  the purpose of providing the Services, including affiliates
                  and subsidiaries outside the UK. We may provide your data to
                  our Vendors. We may share your data with law enforcement
                  agencies or in response to any legal requests that we receive
                  from any government authority.
                </span>
                <span className="terms-span">
                  Before or at the time of collecting personal data we will
                  identify the purposes for which any information is being
                  collected ensuring it is both fair and lawful ensuring we only
                  ask you for the information that is required, we will then use
                  this information in relation to fulfilling those purposes
                  specified by us and for other compatible purposes, retaining
                  the personal information as long as necessary for the
                  fulfilment of those purposes.
                </span>
                <span className="terms-span">
                  We will protect personal information by reasonable security
                  safeguards against loss or theft, as well as unauthorised
                  access, disclosure, copying, use or modification. We will make
                  readily available to you information about our policies and
                  practices relating to the management of personal information.
                </span>
                <span className="terms-span">
                  We are committed to conducting our business in accordance with
                  these principles to ensure that the confidentiality of
                  personal information is protected and maintained. We will only
                  use your personal data for the purposes for which we collected
                  it, unless we reasonably consider that we need to use it for
                  another reason and that reason is compatible with the original
                  purpose. If you wish to get an explanation as to how the
                  processing for the new purpose is compatible with the original
                  purpose, please contact us. Please note that we may process
                  your personal data without your knowledge or consent, in
                  compliance with the above rules, where this is required or
                  permitted by law.
                </span>
                <span className="terms-span">
                  Though we do not share any personal data with third parties
                  (e.g. marketing companies) for commercial, marketing or
                  unsolicited communication purposes, we do need to share
                  certain data in order to maximise the benefits of the
                  Services. Except as otherwise required or permitted by law, we
                  do not disclose information about identifiable individuals to
                  third parties without the relevant individual’s consent.
                  However, we may provide third parties with anonymised,
                  aggregate information about our users and beneficiaries for
                  monitoring participation in and the success of the Services,
                  or for research with the purpose of improving our Services. We
                  may from time to time engage third parties to perform part of
                  the Services (including the processing of personal data) on
                  our behalf, such as hosting our data (including your personal
                  data) and websites; providing webinar services; sending emails
                  and other communications relating to our products and/or
                  services; providing analytic services, such as tracking usage
                  of our operational sites or websites; or performing other
                  administrative services for us.
                </span>
                <span className="terms-span">
                  We will not share your information with parties outside of the
                  EEA unless we are legally permitted or required to do so. All
                  information you provide to us is stored on secure cloud-based
                  servers provided by a third party Vendor. Any transfer of
                  personal data outside of the EEA shall be in accordance with
                  the Data Protection Laws. You hereby consent to such transfer,
                  access and processing: (a) pursuant to and in accordance with
                  the Data Importer’s obligations set out in the Clauses for the
                  transfer of personal data to data processors established in
                  third countries set out in the Commission Decision of 5
                  February 2010 (C (2010) 593), as amended by EU Commission
                  Implementing Decision 2016/2297 of 16 December 2016 which are
                  hereby incorporated into and form part of this Agreement. Our
                  third party Vendor is contractually obliged to put in place
                  protection before exporting or processing any of your personal
                  data. Although we will do our best to protect your personal
                  data, we cannot guarantee the security of the information
                  transmitted to our sites. Any transmission will be at your own
                  risk. Once we have received your information, we will use
                  appropriate security features and procedures to try to prevent
                  unauthorised access. Where we have given you (or where you
                  have chosen) a password which allows you to access certain
                  parts of our sites, we ask you not to share this password with
                  any other person. We have put in place procedures to deal with
                  any suspected personal data breach and will notify you and any
                  applicable regulator of a breach where we are legally required
                  to do so. From submitting or uploading information on our
                  sites or platform you are agreeing to the storage, processing
                  and possible transfer of this data. Company will ensure that
                  data processing agreements and contracts are set up with data
                  processors.
                </span>
                <span className="terms-span">
                  Your personal data will be destroyed or erased from our
                  systems when it is no longer required for the relevant
                  specified purpose that it was collected for, provided that we
                  may retain personal data in order to comply with applicable
                  laws, regulations and rules. As a general rule, this means we
                  will retain your personal data for the duration of your
                  involvement with us and for up to six years afterwards.
                  However, retention and destruction of personal data will be
                  considered on a case-by-case basis.
                </span>
                <span className="terms-span">
                  If you have any questions or concerns regarding our use of
                  your personal data or our Data Protection and Privacy Policy,
                  please contact us at{" "}
                  <a href="mailto:dataprotection@gopoolit.com">
                    dataprotection@gopoolit.com
                  </a>
                  . Our sites may, from time to time, contain links to and from
                  the websites of our partners and affiliates. If you follow a
                  link to any of these websites, please note that they may have
                  their own data protection and privacy policies and that we do
                  not accept any responsibility or liability for them.
                </span>
                <span className="terms-span">
                  Any changes we may make to our data protection and privacy
                  policy in the future will be posted on the Platform and, where
                  appropriate, notified to you. Please check the website or your
                  email or the Platform frequently to see any updates or changes
                  to our Data Protection and Privacy Policy. Your continued use
                  of our Services and the Platform signifies your agreement with
                  any changes.
                </span>
                <span className="terms-span">
                  We may on occasion work with selected third parties, data
                  could be transferred to organisations such as Facebook,
                  Twitter or LinkedIn or other organisations that “enhance” data
                  the processes that may ensure may include wealth tagging,
                  obtaining new contact details, social matching,
                  geo-demographics; generation of look-a-like audiences or
                  targeted advertisements. We might also share information you
                  have provided with selected third parties, charities you
                  engage with to provide you with information on products and/or
                  services that may be of interest or relevant to yourselves;
                  only if you have given us permission to do so via an opt-in
                  mechanism. If you do not wish for your data to be used in this
                  manor then please opt-out by sending an email at{" "}
                  <a href="mailto:dataprotection@gopoolit.com">
                    dataprotection@gopoolit.com
                  </a>
                  .
                </span>
                <span className="terms-span">
                  Like virtually all web sites, we store data on visits in
                  server log files which contain IP addresses of machines used
                  to access this site, and the pages requested. ‘Cookies’ are
                  small files on your computer, phones and other devices that
                  stores IDs such as user id, session id, when you browse the
                  web. We also use pixels, which are small codes on web pages or
                  email notifications, that assist in learning about your
                  interactions with the web. By using our Platform, you consent
                  to our use of cookies, pixels and related technology. We use
                  Google Analytics to collect information about how people use
                  this site. Google Analytics stores information about what
                  pages you visit, how long you are on the site, how you got
                  here and what you click on. We do not allow Google to use or
                  share our analytics data. We use these technologies when you
                  create an account with us. It helps us to improve our Platform
                  and services to help you log into your account securely, to
                  help us detect and fight spam, abuse, and other activities
                  that violate our Terms and Conditions of Service and other
                  policies, to prevent unauthorized access to your account, to
                  help us show you content that you may be interested in
                  viewing. Knowing your preferences help us remember your
                  browser information, preferred language and remember the
                  country you reside in. We may customise content based on your
                  country, age, content you like and content that is trending in
                  your country, or withhold certain content based on the laws of
                  your country. Cookies also help us understand when and how
                  often you use our Platform and what links you click on. We may
                  use these technologies when you use one device to personalize
                  your experience on our Platform when you use another device.
                  When you log into our Platform from a device, we associate
                  that device with your account and we may receive information
                  about your device, IP address, etc. We use these technologies
                  on our Platform, and mobile apps. Third parties may also use
                  these technologies when you click on their links that may be
                  available on our Platform, view or interact with third-party
                  content on our Platform, or visit third-party websites. We
                  advise you to familiarize yourself with the cookie policy of
                  such third parties.
                </span>
                <span className="terms-span">
                  We will always respect your privacy choices and therefore, we
                  want to give you options on how you can control or limit how
                  we, our Vendors, service providers, partners, and other third
                  parties use cookies. If you do not want us to show you content
                  based on your interests, You can adjust your settings. To
                  control advertising from third-party advertising partners
                  based on your interests, you can opt out of receiving such
                  ads. You can also opt out of Google Analytics by installing
                  Google’s opt-out browser add-on and opt out of interest-based
                  Google ads using Google’s Ads Settings. You can control
                  cookies on your web browsers by accepting or denying cookies
                  each time you visit our Platform or use our apps and our
                  Platform or apps attempt to use a cookie. While it is entirely
                  your choice whether You want to use technologies or not while
                  you are on our Platform or using our apps, you will have
                  access to our Platform if you deny the use of such
                  technologies. However, if you wish to have a better and more
                  personalized experience on our Platform, we advise you to
                  accept our Cookie policy so we can provide better services to
                  you and to truly enjoy your experience on our Platform.
                </span>
                <span className="terms-span">
                  If Company is bought out or its assets are acquired by a third
                  party personal data held about its clients and users could
                  possibly be one of the transferred assets.
                </span>
                <span className="terms-span">
                  We may also gather and store publicly available information
                  about you. This may include information found in places such
                  as Companies House and information that has been published in
                  articles/, newspapers and on social media. This information
                  will be used to supplement information that Company currently
                  holds or used to identify or target prospects or currently
                  known individuals for marketing for our goods or services.
                  Also, your name might have been sourced from the Charity
                  Commission Database or other available sources.
                </span>
                <span className="terms-span">
                  Except as denoted in this policy, Company will not disclose,
                  distribute or sell personal data (sensitive or non) to any
                  other organisation without prior consent/contractual
                  obligation unless we have a legal obligation or right to do
                  so.
                </span>
                <span className="terms-span">
                  Our Data Protection Officer (DPO) is Petronella Cragwell. If
                  you have any queries on this policy, wish to contact the DPO
                  or know further details on how Company uses personal data
                  please contact us at{" "}
                  <a href="mailto:dataprotection@gopoolit.com">
                    dataprotection@gopoolit.com
                  </a>
                  . If you wish to opt-out of something specific; then please
                  use the communication preference centre.
                </span>
                <span className="terms-span">
                  For all other matters please refer to our Services Agreement
                  and Policies. This Data Protection and Privacy Policy shall be
                  governed by the law of England & Wales.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
        <LeanmoreFooter />
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
