import { VERIFY_RAZORPAY_PAYMENT } from '../../constants/action-types'

const initState = {}

const RazorPayReducer = (state = initState, action) => {
    if (action.type === VERIFY_RAZORPAY_PAYMENT) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state
}
export default RazorPayReducer