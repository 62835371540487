import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//css import
import { Toast } from "react-bootstrap";
import "../../assets/scss/Contents/sharingOnMedia.scss";

// import { storeContentId } from "../../actions/storeContentId";
import StorePopup from "../StorePopup";

//slider package
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Comments from "./Activity/comments";

//svg
import x from "../../assets/svg/x.svg";

//react share package
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import whatsappIcon from "../../assets/images/whatsapp-icon.png";
import facebookIcon from "../../assets/images/facebook-icon.png";
import facebookMessengerIcon from "../../assets/images/facebook-messenger-icon.png";
import twitterIcon from "../../assets/images/twitter-icon.png";
import telegramIcon from "../../assets/images/telegram-icon.png";
import linkedinIcon from "../../assets/images/linkedin-icon.png";
import { SHAREURL } from "../../config";

import { useTranslation } from "react-i18next";

const commentSvg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4C16.9626 4 21 7.61235 21 12.0526C21 13.6047 20.5062 15.1082 19.5696 16.4082C19.7469 18.3578 20.2221 19.8052 20.9121 20.4909C21.0033 20.5816 21.0261 20.7202 20.9685 20.8348C20.9175 20.9371 20.8125 21 20.7 21C20.6862 21 20.6724 20.9991 20.6583 20.997C20.5368 20.98 17.7144 20.5762 15.6729 19.4047C14.5137 19.8696 13.2789 20.1053 12 20.1053C7.03739 20.1053 3 16.4929 3 12.0526C3 7.61235 7.03739 4 12 4ZM16.2 13.2456C16.8618 13.2456 17.4 12.7106 17.4 12.0526C17.4 11.3947 16.8618 10.8596 16.2 10.8596C15.5382 10.8596 15 11.3947 15 12.0526C15 12.7106 15.5382 13.2456 16.2 13.2456ZM12 13.2456C12.6618 13.2456 13.2 12.7106 13.2 12.0526C13.2 11.3947 12.6618 10.8596 12 10.8596C11.3382 10.8596 10.8 11.3947 10.8 12.0526C10.8 12.7106 11.3382 13.2456 12 13.2456ZM7.79999 13.2456C8.46179 13.2456 8.99999 12.7106 8.99999 12.0526C8.99999 11.3947 8.46179 10.8596 7.79999 10.8596C7.1382 10.8596 6.6 11.3947 6.6 12.0526C6.6 12.7106 7.1382 13.2456 7.79999 13.2456Z"
      fill="#3B3B3B"
    />
  </svg>
);

const shareArrow = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2222 4.53333V0L20 7.93333L12.2222 15.8667V11.22C6.66667 11.22 2.77778 13.0333 0 17C1.11111 11.3333 4.44444 5.66667 12.2222 4.53333Z"
      fill="#3B3B3B"
    />
  </svg>
);

// const leftArrow = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <g filter="url(#filter0_d)">
//     <rect x="28" y="28" width="24" height="24" rx="12" transform="rotate(-180 28 28)" fill="white" />
//     <path d="M17 12L13 16L17 20" stroke="#2077B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//   </g>
//   <defs>
//     <filter id="filter0_d" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
//       <feFlood floodOpacity="0" result="BackgroundImageFix" />
//       <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
//       <feOffset />
//       <feGaussianBlur stdDeviation="2" />
//       <feColorMatrix type="matrix" values="0 0 0 0 0.12549 0 0 0 0 0.466667 0 0 0 0 0.694118 0 0 0 0.16 0" />
//       <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
//       <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
//     </filter>
//   </defs>
// </svg>

/*Style applied to body element */
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
/*end**********************/

var settings = {
  // dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    // {
    //   breakpoint: 1024,
    //   settings: {
    //     slidesToShow: 7,
    //     slidesToScroll: 7,
    //     infinite: true,
    //     // dots: true
    //   }
    // },
    // {
    //   breakpoint: 600,
    //   settings: {
    //     slidesToShow: 6,
    //     slidesToScroll: 6,
    //     initialSlide: 6
    //   }
    // },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

const SharingOnMedia = (props) => {
  // console.log("sharing media", props);
  const [show, setShow] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [storePopup, setStorePopup] = useState(false);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  const authorizedUser = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  const history = useHistory();
  const dispatch = useDispatch();

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const copyUrl = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#999999" />
      <path
        d="M18.3346 9.58337H11.3346C10.693 9.58337 10.168 10.1084 10.168 10.75V18.9167H11.3346V10.75H18.3346V9.58337ZM20.0846 11.9167H13.668C13.0263 11.9167 12.5013 12.4417 12.5013 13.0834V21.25C12.5013 21.8917 13.0263 22.4167 13.668 22.4167H20.0846C20.7263 22.4167 21.2513 21.8917 21.2513 21.25V13.0834C21.2513 12.4417 20.7263 11.9167 20.0846 11.9167ZM20.0846 21.25H13.668V13.0834H20.0846V21.25Z"
        fill="white"
      />
    </svg>
  );
  // console.log(props.contentExternalId);

  let url = `${SHAREURL}/share/${props.contentExternalId}`;
  const copyURL = (url) => {
    var textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    setShow(true);
    setShowShare(false);
  };

  const handleShowShare = (e, contentExternalId) => {
    if (authorizedUser) {
      e.stopPropagation(); //Propagation means bubbling(passing event) up to parent elements or capturing down to child elements. stop passing of onclick function from sharing button to comments
      setShowShare(true);
    } else {
      if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
        setStorePopup(true);
        localStorage.setItem("content_external_id", contentExternalId);
      } else {
        history.push("/login");
        // dispatch(storeContentId(contentExternalId));
        localStorage.setItem("content_external_id", contentExternalId);
      }
    }
  };
  const showComments = (e, contentExternalId) => {
    if (authorizedUser) {
      e.stopPropagation(); //Propagation means bubbling(passing event) up to parent elements or capturing down to child elements. stop passing of onclick function from sharing button to comments
      setShowComment(!showComment);
    } else {
      if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
        setStorePopup(true);
        localStorage.setItem("content_external_id", contentExternalId);
      } else {
        history.push("/login");
        // dispatch(storeContentId(contentExternalId));
        localStorage.setItem("content_external_id", contentExternalId);
      }
    }
  };
  // let commentsValue=null
  // const showComments = (e) => {
  //  console.log("clicked comments");

  //  return(<Comments contentExternalId={props.content_external_id} contentCreatorId={props.content_creator_id} /> )
  // }

  const handleCloseShare = (e) => {
    e.stopPropagation(); //Propagation means bubbling(passing event) up to parent elements or capturing down to child elements. stop passing of events onclick function from sharing button to comments
    setShowShare(false);
  };

  const handleClose = () => {
    setStorePopup(false);
    localStorage.removeItem("content_external_id");
  };
  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  // Math.easeInOutQuad = function (t, b, c, d) {
  //   t /= d / 2;
  //   if (t < 1) return c / 2 * t * t + b;
  //   t--;
  //   return -c / 2 * (t * (t - 2) - 1) + b;
  // };

  let stylesShare = showShare ? { display: "block" } : { display: "none" };
  // console.log("propsis", props);
  let commentsData = showComment ? (
    <Comments
      contentExternalId={props.contentExternalId}
      contentCreatorId={props.contentCreatorId}
      showComments={showComment}
    />
  ) : (
    ""
  );

  return (
    <>
      {show ? (
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="copyurl-toast"
        >
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM19.2005 8.52709C19.4907 8.23157 19.4864 7.75672 19.1909 7.46648C18.8954 7.17623 18.4205 7.18051 18.1303 7.47603L9.4987 16.2645L5.86712 12.5669C5.57687 12.2714 5.10202 12.2671 4.8065 12.5574C4.51098 12.8476 4.5067 13.3225 4.79695 13.618L8.96361 17.8604C9.10463 18.004 9.29744 18.0849 9.4987 18.0849C9.69995 18.0849 9.89276 18.004 10.0338 17.8604L19.2005 8.52709Z"
                fill="#1BAB58"
              />
            </svg>
            <span className="copyurl-toast-text">
              {t("post.share.copyUrlMsg")}
            </span>
          </div>
        </Toast>
      ) : (
        ""
      )}
      <div style={{ display: "flex" }}>
        <button
          onClick={(e) => showComments(e, props.contentExternalId)}
          className="comments-share-button"
        >
          &nbsp;{commentSvg}
          {t("post.comments.commentText")}
          {/* &nbsp;{commentSvg}
          {props.Commentcount} {props.Commentcount > 0 ? "Comments" : "Comment"} */}
        </button>

        <button
          onClick={(e) => handleShowShare(e, props.contentExternalId)}
          className="comments-share-button"
        >
          {shareArrow}
          {t("post.share.shareText")}
        </button>
      </div>
      <div>{commentsData}</div>

      {/*Report Modal*********************************************** */}
      {showShare && <BodyStyle />}
      {showShare && <div className="modal-backdrop"></div>}

      {storePopup ? (
        <StorePopup storePopupShow={storePopup} handleClose={handleClose} />
      ) : (
        " "
      )}

      <div
        id="shareModal"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesShare}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title">Share Post</p>
              <button
                className="close-share"
                onClick={(e) => handleCloseShare(e)}
              >
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="report-message">
              <div className="modal-body">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* <Slider {...settings} className="scroll-menu"> */}
                  <div className="share-content" style={{ marginTop: "7px" }}>
                    <span onClick={() => copyURL(url)}>
                      {copyUrl}
                      <span
                        id="pwd_spn"
                        style={{ display: "none" }}
                        className="password-span"
                      >
                        {url}
                      </span>
                      <span>{t("post.share.copyUrl")}</span>
                    </span>
                  </div>
                  <div className="share-content">
                    <WhatsappShareButton url={url} title="">
                      <img src={whatsappIcon} alt="whatsappIcon" />
                      <span>WhatsApp</span>
                    </WhatsappShareButton>
                  </div>
                  <div className="share-content">
                    <FacebookShareButton url={url} quote="">
                      <img src={facebookIcon} alt="facebook-icon" />
                      <span>Facebook</span>
                    </FacebookShareButton>
                  </div>
                  <div className="share-content">
                    <FacebookMessengerShareButton
                      appId="646324019294890"
                      url={url}
                      quote=""
                    >
                      <img
                        src={facebookMessengerIcon}
                        alt="facebookMessengerIcon"
                      />
                      <span>Messenger</span>
                    </FacebookMessengerShareButton>
                  </div>
                  <div className="share-content">
                    <TwitterShareButton url={url} title="">
                      <img src={twitterIcon} alt="twitterIcon" />
                      <span>Twitter</span>
                    </TwitterShareButton>
                  </div>
                  <div className="share-content">
                    <TelegramShareButton url={url} title="">
                      <img src={telegramIcon} alt="telegramIcon" />
                      <span>Telegram</span>
                    </TelegramShareButton>
                  </div>
                  <div className="share-content">
                    <LinkedinShareButton url={url} title="">
                      <img src={linkedinIcon} alt="linkedinIcon" />
                      <span>LinkedIn</span>
                    </LinkedinShareButton>
                  </div>
                  {/* </Slider> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharingOnMedia;
