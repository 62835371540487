import axios from "axios";
import { BASEURL } from "../config";

export const achievementApi = () => {
  return axios({
    url: `${BASEURL}/v1/achievements`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': `Bearer ${token}`,
      // 'userexternalid': user_external_Id,
      // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      // 'userexternalid': `${localStorage.getItem('user_external_id')}`
    },
    withCredentials: true,
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};
