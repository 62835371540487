import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Image } from "react-bootstrap";

//api import
import { userPooledPosts } from "../../../src/api/UsersPooledAPI";
import { FollowUser } from "../../api/FollowUserAPI";
import { UnfollowUser } from "../../api/UnFollowUserAPI";

//action import
import { getFollowersList } from "../../actions/follower_action";
import { getFollowingList } from "../../actions/following_action";

//image import
import x from "../../assets/svg/x.svg";
import avatar from "../../assets/images/avatar.png";
import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";
import { useHistory } from "react-router-dom";

import Loader from "../Loader";
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
const PeoplePooledModal = (props) => {
  const [showReport, setShowReport] = useState(true);
  const [usersPooledData, setUserPooledData] = useState([]);
  const [pooledCount, setPooledCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userexternalid = `${localStorage.getItem("user_external_id")}`;
  const userDetails = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  const history = useHistory();

  async function fetchData() {
    let userpooledData = await userPooledPosts(props.contentExternalId);
    if (userpooledData.data.length > 0) {
      setUserPooledData(userpooledData.data);
      setPooledCount(userpooledData.data.length);
      setIsLoading(false);
    } else {
      setUserPooledData([]);
      setPooledCount(0);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handlePooledUser = (otherUserExternalId) => {
    if (userexternalid !== otherUserExternalId) {
      setShowReport(false);
      history.push({
        pathname: "/otheruserprofile",
        state: { otherUserExternalId: otherUserExternalId },
      });
      dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
    }
  };
  const followUnFollowFunction = async (e, user_following_externalId, flag) => {
    e.preventDefault();
    if (flag === true) {
      await UnfollowUser(user_following_externalId);
      dispatch(getFollowersList(null));
      fetchData();
    } else if (flag === false) {
      await FollowUser(user_following_externalId);
      dispatch(getFollowingList(null));
      fetchData();
    }
  };

  let showModalUsersPooled = null;
  if (usersPooledData.length === 0) {
    showModalUsersPooled = (
      <p className="user-caption">
        Let's get people pooling to your post.....Why don't you share it?{" "}
      </p>
    );
  } else {
    showModalUsersPooled = usersPooledData.map((item) => {
      return (
        <div className="followers-following-card">
          <div className="followers-following-image-username">
            <span>
              <Image
                className="image"
                onError={(ev) => (ev.target.src = `${avatar}`)}
                src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`}
                roundedCircle
                style={{ width: "51px", height: "51px", cursor: "pointer" }}
                onClick={() => { handlePooledUser(item.user_external_id) }}
              />
            </span>

            {/* {item.user_profile_pic !== null ? <Image className="image" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> :
                            <Image src={avatar} alt="profile-photo" className="image" roundedCircle style={{ width: "51px", height: "51px" }} />} */}
            {/* <Image className="image" src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${item.user_external_id}.jpg`} roundedCircle style={{ width: "51px", height: "51px" }} /> */}
            {/* <img src={item.user_profile_pic} className="image" /> */}
            <div style={{ display: "inline-block" }}>
              <p className="user-caption">{item.user_full_name}</p>
              <p className="user-Name">{item.username}</p>
              <p className="userPooledAmount" >Amount pooled: {userDetails.country_wallet_currency_sign}{item.pooled}</p>
            </div>
          </div>

          {userexternalid !== item.user_external_id ? (
            item.isfollowing === true ? (
              <Button
                className="button-following"
                onClick={(e) => {
                  followUnFollowFunction(e, item.user_external_id, true);
                }}
              >
                <span className="button-name">Following</span>
              </Button>
            ) : (
                <Button
                  className="button-follow"
                  onClick={(e) => {
                    followUnFollowFunction(e, item.user_external_id, false);
                  }}
                >
                  <span className="button-name-follow">Follow</span>
                </Button>
              )
          ) : null}
        </div>
      );
    });
  }
  const closeModal = () => {
    setShowReport(false);
    setUserPooledData([]);
    setPooledCount(null);
  };

  let stylesReport = showReport
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };
  return (
    <>
      {/*Report Modal*********************************************** */}
      {showReport && <BodyStyle />}
      {showReport && <div className="modal-backdrop"></div>}

      <div
        id="modal-followers-following"
        className="modal in report-modal-container"
        role="dialog"
        style={stylesReport}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {pooledCount} People Pooled
              </h4>
              <button className="close" onClick={closeModal}>
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="modal-followers-following-message">
              <div className="modal-body">
                <div className="modal-followers-following-body">
                  {isLoading ? <Loader /> : showModalUsersPooled}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeoplePooledModal;
