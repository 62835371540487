import { STORE_USER_DEFAULT_AMT } from '../../constants/action-types'

const initState = {
    sliderDefaultValue:null
}

const UserDefaultAmountReducer = (state = initState, action) => {
    if (action.type === STORE_USER_DEFAULT_AMT) {
        return {
            ...state,
            sliderDefaultValue : action.payload.sliderDefaultValue
        }

    }
    return state
}
export default UserDefaultAmountReducer