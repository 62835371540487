import React, { useState, useEffect } from "react";
import { BASEURL } from "../../config";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
import axiosInstance from "../../axios/axios";
import ContentSlider from "../Contents/Activity/ContentSlider";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import Linkify from "linkifyjs/react";

import EmojiSlider from "../EmojiSlider/EmojiSlider";
import SharingOnMedia from "../Contents/SharingOnMedia";
import ContentNavbar from "../ContentNavbar/ContentNavbar";
import StorePopup from "../StorePopup";
import "../../assets/scss/CreateContent/shareSheet.scss";
import avatar from "../../assets/images/avatar.png";
import pooledCollect from "../../assets/svg/pooledCollect.svg";

import { getOtherUserDetailsByUserId } from "../../actions/otherUserProfile/otherUserProfile_action";
import { storeContentId } from "../../actions/storeContentId";

// Slick slider imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ShareSheet = (props) => {
  const [contents, setContents] = useState("");
  const [storePopup, setStorePopup] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );
  const userDetails = useSelector((state) => state.userDetails);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(props.match.params);
    if (isAuthorized) {
      axiosInstance({
        url: `${BASEURL}/v1/getContentById/${props.match.params.content_external_id}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          userexternalid: localStorage.getItem("user_external_id"),
        },
        withCredentials: true,
      })
        .then((res) => {
          setStatusCode(res.status);
          // console.log(res.status);
          setContents(res.data.response.data.contents[0]);
        })
        .catch((err) => console.log(err));
    } else {
      axiosInstance({
        url: `${BASEURL}/v1/getContentById/${props.match.params.content_external_id}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
        .then((res) => {
          setStatusCode(res.status);
          setContents(res.data.response.data.contents[0]);
        })
        .catch((err) => console.log(err));
    }

    // if (navigator.userAgent.match(/Android/i)) {
    //   setTimeout(function () {
    //     window.location = "market://details?id=com.gopoolit.gopoolit";
    //   }, 500);
    //   window.location = `gpi://${props.match.params.content_external_id}`;
    // }
  }, []);

  const addMetaTags = () => {
    let url = document.createElement("meta");
    url.setAttribute("property", "og:url");
    url.content = document.location;
    document.getElementsByTagName("head")[0].appendChild(url);

    let fileType = contents.content_url[0].contentType.split("/")[0];

    if (fileType === "image") {
      let type = document.createElement("meta");
      type.setAttribute("property", "og:type");
      type.content = contents.content_url[0].contentType.split("/")[0];
      document.getElementsByTagName("head")[0].appendChild(type);

      let image = document.createElement("meta");
      image.setAttribute("property", "og:image");
      image.content = contents.content_url[0].signedUrl;
      document.getElementsByTagName("head")[0].appendChild(image);

      let image_url = document.createElement("meta");
      image_url.setAttribute("property", "og:image:url");
      image_url.content = contents.content_url[0].signedUrl;
      document.getElementsByTagName("head")[0].appendChild(image_url);

      let image_secure_url = document.createElement("meta");
      image_secure_url.setAttribute("property", "og:image:secure_url");
      image_secure_url.content = contents.content_url[0].signedUrl;
      document.getElementsByTagName("head")[0].appendChild(image_secure_url);

      let image_type = document.createElement("meta");
      image_type.setAttribute("property", "og:image:type");
      image_type.content = contents.content_url[0].contentType;
      document.getElementsByTagName("head")[0].appendChild(image_type);

      let image_width = document.createElement("meta");
      image_width.setAttribute("property", "og:image:width");
      image_width.content = "1280";
      document.getElementsByTagName("head")[0].appendChild(image_width);

      let image_height = document.createElement("meta");
      image_height.setAttribute("property", "og:image:height");
      image_height.content = "720";
      document.getElementsByTagName("head")[0].appendChild(image_height);

      let twitter_image = document.createElement("meta");
      twitter_image.setAttribute("property", "twitter:image");
      twitter_image.content = contents.content_url[0].signedUrl;
      document.getElementsByTagName("head")[0].appendChild(twitter_image);
    } else if (fileType === "video") {
      // let video = document.createElement("meta");
      // video.setAttribute("property", "og:video");
      // video.content = contents.content_url[0].signedUrl;
      // document.getElementsByTagName("head")[0].appendChild(video);

      let type = document.createElement("meta");
      type.setAttribute("property", "og:type");
      type.content = "video.other";
      document.getElementsByTagName("head")[0].appendChild(type);

      let image = document.createElement("meta");
      image.setAttribute("property", "og:image");
      // image.content =
      //   "https://gpi-public-data.s3-eu-west-1.amazonaws.com/assets/video_placeholder.png";
      image.content = contents.content_thumbnail_url;
      document.getElementsByTagName("head")[0].appendChild(image);

      let image_url = document.createElement("meta");
      image_url.setAttribute("property", "og:image:url");
      // image_url.content =
      //   "https://gpi-public-data.s3-eu-west-1.amazonaws.com/assets/video_placeholder.png";
      image_url.content = contents.content_thumbnail_url;
      document.getElementsByTagName("head")[0].appendChild(image_url);

      let image_secure_url = document.createElement("meta");
      image_secure_url.setAttribute("property", "og:image:secure_url");
      // image_secure_url.content =
      //   "https://gpi-public-data.s3-eu-west-1.amazonaws.com/assets/video_placeholder.png";
      image_secure_url.content = contents.content_thumbnail_url;
      document.getElementsByTagName("head")[0].appendChild(image_secure_url);

      // let video_type = document.createElement("meta");
      // video_type.setAttribute("property", "og:video:type");
      // video_type.content = "text/html";
      // document.getElementsByTagName("head")[0].appendChild(video_type);

      let video_width = document.createElement("meta");
      video_width.setAttribute("property", "og:video:width");
      video_width.content = "1280";
      document.getElementsByTagName("head")[0].appendChild(video_width);

      let video_height = document.createElement("meta");
      video_height.setAttribute("property", "og:video:height");
      video_height.content = "720";
      document.getElementsByTagName("head")[0].appendChild(video_height);

      let twitter_video = document.createElement("meta");
      twitter_video.setAttribute("property", "og:twitter:player");
      twitter_video.content = contents.content_url[0].signedUrl;
      document.getElementsByTagName("head")[0].appendChild(twitter_video);

      let twitter_player_width = document.createElement("meta");
      twitter_player_width.setAttribute("property", "og:twitter:player:width");
      twitter_player_width.content = "1280";
      // console.log(contents.content_url[0].signedUrl)
      document
        .getElementsByTagName("head")[0]
        .appendChild(twitter_player_width);

      let twitter_player_height = document.createElement("meta");
      twitter_player_height.setAttribute(
        "property",
        "og:twitter:player:height"
      );
      twitter_player_height.content = "720";
      // console.log(contents.content_url[0].signedUrl)
      document
        .getElementsByTagName("head")[0]
        .appendChild(twitter_player_height);
    }

    let secureImage = document.createElement("meta");
    secureImage.setAttribute("property", "og:image:secure");
    secureImage.content = contents.content_url[0].signedUrl;
    // console.log(contents.content_url[0].signedUrl)
    document.getElementsByTagName("head")[0].appendChild(secureImage);

    let secureImageUrl = document.createElement("meta");
    secureImageUrl.setAttribute("property", "og:image:secure_url");
    secureImageUrl.content = contents.content_url[0].signedUrl;
    // console.log(contents.content_url[0].signedUrl)
    document.getElementsByTagName("head")[0].appendChild(secureImageUrl);
    
    let description = document.createElement("meta");
    description.setAttribute("property", "og:description");
    description.content = contents.content_description;
    document.getElementsByTagName("head")[0].appendChild(description);

    let title = document.createElement("meta");
    title.setAttribute("property", "og:title");
    title.content = `GoPoolit - ${contents.cause_name}`;
    document.getElementsByTagName("head")[0].appendChild(title);

    let android_app_name = document.createElement("meta");
    android_app_name.setAttribute("property", "al:android:app_name");
    android_app_name.content = "GoPoolit";
    document.getElementsByTagName("head")[0].appendChild(android_app_name);

    let twitter_app_name = document.createElement("meta");
    twitter_app_name.setAttribute("property", "twitter:app:name");
    twitter_app_name.content = "GoPoolit";
    document.getElementsByTagName("head")[0].appendChild(twitter_app_name);

    let android_package = document.createElement("meta");
    android_package.setAttribute("property", "al:android:package");
    android_package.content = "com.gopoolit.gopoolit";
    document.getElementsByTagName("head")[0].appendChild(android_package);

    let android_url = document.createElement("meta");
    android_url.setAttribute("property", "al:android:url");
    android_url.content = "";
    document.getElementsByTagName("head")[0].appendChild(android_url);

    let twitter_google_play = document.createElement("meta");
    twitter_google_play.setAttribute("property", "twitter:app:name:googleplay");
    twitter_google_play.content = "GoPoolit";
    document.getElementsByTagName("head")[0].appendChild(twitter_google_play);

    let ios_url = document.createElement("meta");
    ios_url.setAttribute("property", "al:ios:url");
    ios_url.content = "";
    document.getElementsByTagName("head")[0].appendChild(ios_url);

    let fallback = document.createElement("meta");
    fallback.setAttribute("property", "al:web:should_fallback");
    fallback.content = "true";
    document.getElementsByTagName("head")[0].appendChild(fallback);

    let twitter_site = document.createElement("meta");
    twitter_site.setAttribute("property", "twitter:site");
    twitter_site.content = "@gopoolit";
    document.getElementsByTagName("head")[0].appendChild(twitter_site);

    let twitter_title = document.createElement("meta");
    twitter_title.setAttribute("property", "twitter:title");
    twitter_title.content = "Sharing from GoPoolit";
    document.getElementsByTagName("head")[0].appendChild(twitter_title);

    let twitter_description = document.createElement("meta");
    twitter_description.setAttribute("property", "twitter:description");
    twitter_description.content = contents.content_description;
    document.getElementsByTagName("head")[0].appendChild(twitter_description);
  };

  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30);

    if (month) {
      if (month === 1) {
        return <p className="timeline">{month} month ago</p>;
      } else {
        return <p className="timeline">{month} months ago</p>;
      }
    } else if (days) {
      if (days === 1) return <p className="timeline">{days} day ago</p>;
      return <p className="timeline">{days} days ago</p>;
    } else if (hours) {
      if (hours === 1) return <p className="timeline">{hours} hour ago</p>;
      return <p className="timeline">{hours} hours ago</p>;
    } else if (days === 0 && hours === 0) {
      if (minutes === 1)
        return <p className="timeline">{minutes} minute ago</p>;
      return <p className="timeline">{minutes} minutes ago</p>;
    }
  };

  const redirectUser = (contentExternalId) => {
    if (isAuthorized) return;
    else {
      history.push("/login");
      // dispatch(storeContentId(contentExternalId));
      localStorage.setItem("content_external_id", contentExternalId);
    }
  };

  const userProfileRedirect = (otherUserExternalId, contentExternalId) => {
    if (isAuthorized) {
      if (!navigator.userAgent.match(/Android|iPhone/i)) {
        handleOtherUser(otherUserExternalId);
      } else return;
    } else {
      if (navigator.userAgent.match(/Mobile|Android|iPhone/i)) {
        setStorePopup(true);
        localStorage.setItem("content_external_id", contentExternalId);
      } else {
        history.push("/login");
        // dispatch(storeContentId(contentExternalId));
        localStorage.setItem("content_external_id", contentExternalId);
      }
    }
  };

  const handleOtherUser = (otherUserExternalId) => {
    if (otherUserExternalId === localStorage.getItem("user_external_id")) {
      history.push("/profile");
    } else {
      history.push({
        pathname: "/otheruserprofile",
        state: { otherUserExternalId: otherUserExternalId },
      });
      // history.push("/otheruserprofile");
      localStorage.setItem("content_external_id", otherUserExternalId);
      // dispatch(getOtherUserDetailsByUserId(otherUserExternalId));
    }
  };

  const handleClose = () => {
    setStorePopup(false);
    localStorage.removeItem("content_external_id");
  };

  //-------------- Slick slider --------------//
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (statusCode === 204) {
    return "Content not found";
  }

  if (!contents) {
    return "Loading...";
  }

  return (
    <React.Fragment>
      {/* <Helmet>
                <meta property="og:url" content="https://gopoolit.com/share/a518b855-7a2e-4f02-99dd-5d8d1633c6e0" />
                <meta property="og:type" content="image" />
                <meta property="og:image" content={content.content_url[0].signedUrl} />
                <meta name="og:description" content={content.content_description} />
                <meta property="og:title" content="Sharing Post from GoPoolit" />
                <title>GoPoolit - Share Post</title>
            </Helmet> */}
      {addMetaTags()}
      <ContentNavbar />
      <Container
        fluid
        style={{ backgroundColor: "#f1f1f1", minHeight: "100vh" }}
      >
        <Row className="justify-content-md-center">
          <Col sm={10} md={10} lg={6} className="mx-auto">
            <Card
              className="share-post-card"
              key={contents.content_external_id}
            >
              <Card.Header className="share-post-header">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    aligncontents: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      aligncontents: "center",
                    }}
                  >
                    <Image
                      onError={(ev) => (ev.target.src = `${avatar}`)}
                      onClick={() =>
                        userProfileRedirect(
                          contents.content_creator_id,
                          contents.content_external_id
                        )
                      }
                      className="profiledashboard-profilepic"
                      src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${contents.content_creator_id}.jpg`}
                      alt="profile-picture"
                      height="56"
                      width="56"
                      style={{ objectFit: "cover" }}
                      roundedCircle
                    />
                    <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                      <span>{contents.username}</span>
                      {/* <span style={{ margin: "0 0.5rem" }}>•</span> */}
                      <div style={{ marginBottom: "0", fontWeight: "400" }}>
                        {getPostedTime(contents.content_created_on)}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Card.Text>
                  <Linkify tagName="span">
                    {contents.content_description}
                  </Linkify>
                </Card.Text>
              </Card.Body>

              <div className="image-slider-container">
                <ContentSlider item={contents} />
              </div>

              {/* <div className="post-info">
                <div>
                  <div className="profile-comment-pooled-raised">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="userImageWrapper">
                        <ul className="hx_flex-avatar-stack">
                        </ul>
                      </div>
                      <span className="profile-pooled">
                        {" "}
                        {contents.total_people_pooled} People pooled
                      </span>
                    </div>
                    <span style={{ margin: "0 0.5rem" }}> • </span>
                    <img src={pooledCollect} alt="pooledCollect.svg" />

                    <p className="profile-pooled">
                      <span className="profile-user-pooled-value">
                        {" "}
                        {userDetails.country_wallet_currency_sign}{" "}
                        {contents.total_amount_pooled_converted}{" "}
                      </span>
                      Pool collected
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="activity-page-charity">
                <img
                  src={contents.cause_image}
                  style={{
                    height: "40px",
                    width: "40px",
                    objectFit: "cover",
                  }}
                />
                <div className="activity-page-content">
                  <p className="activity-page-charityName">
                    {contents.cause_name}
                  </p>
                  <p className="activity-page-charityLocation">
                    {contents.country_name}
                  </p>
                </div>
              </div>

              <EmojiSlider
                causeId={contents.cause_external_id}
                contentExternalId={contents.content_external_id}
                countryId={userDetails.country_external_id}
                transcationAmt={contents.transaction_amount}
              />
              {/**Slider starts***************************************** */}
              {/*Slider ends *********************************************/}
              <div
                className="comments-share"
                // onClick={() => redirectUser(contents.content_external_id)}
              >
                <SharingOnMedia
                  url={contents.content_url.signedUrl}
                  text={contents.content_description}
                  contentExternalId={contents.content_external_id}
                  contentCreatorId={contents.content_creator_id}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {storePopup ? (
        <StorePopup storePopupShow={storePopup} handleClose={handleClose} />
      ) : (
        " "
      )}
    </React.Fragment>
  );
};

export default ShareSheet;
