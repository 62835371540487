import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

// Slick slider imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//image imports
// import whiteBrandLogo from "../../../assets/svg/white-brandLogo.svg";
import blueLogo from '../../../assets/svg/blue-logo.svg'
import unsupport from '../../../assets/images/unsupport.png';
const MostPooledSlider = ({ item }) => {
  const [imgCount, setImgCount] = useState(1);

  //-------------- Slick slider --------------//
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="mostpooled">
      <div className='image-count'>{item.content_url.length > 1 && imgCount + '/' + item.content_url.length}</div>

      <Slider {...settings} afterChange={
        (currentSlide) => {
          setImgCount(currentSlide + 1)
        }
      }>
        {
          item.content_url.map((media, index) => {
            if (media.contentType === "image/jpeg" || media.contentType === "image/png") {
              return (
                <div key={index}>
                  <Card.Img onError={(ev) => (ev.target.src = `${unsupport}`)} className="activity-image" src={media.signedUrl} />

                </div>
              )

            } else {
              return (
                <div key={index}>
                  {/* <img src={blueLogo} alr="brand-logo" className="video-brand-logo" /> */}
                  <video className="Activitypage-contentvideo" src={media.signedUrl} controls autoPlay="autoplay" muted></video>
                </div>
              )
            }
          })
        }
      </Slider>
    </div>
  )
}

export default MostPooledSlider;
