import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Link } from "react-router-dom";

import {
  Card,
  Button,
  Container,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import { verifyPassword } from "../../../actions/login";
import { getTokenDetails } from "../../../actions/getTokenDetails";
import { storeUserDetails } from "../../../actions/storingUserData";
import { userAuthorized } from "../../../actions/isAuthorized";
import { logoutUser } from "../../../actions/storingUserData";

//CSS imports
import "../../../assets/scss/Login/enterPassword.scss";
import LoginNavbar from "../../LandingLearnMore/LoginNavbar";
import LeanmoreFooter from "../../LandingLearnMore/LeanmoreFooter";

import x from "../../../assets/svg/x.svg";

const EnterPassword = (props) => {
  const passwordDetails = useSelector(
    (state) => state.verifyEnteredPassword.data
  );
  const googleResponse = useSelector((state) => state.googleResponse.response);
  const FBResponse = useSelector((state) => state.FbResponseData.response);
  const shareSheet = useSelector((state) => state.shareSheet);

  // const newUserToken = useSelector(state => state.newUserToken.data);
  const nexmoVerificationResponse = useSelector(
    (state) => state.nexmoVerificationResponse
  );
  const [Password, setPassword] = useState("");
  const [showPasswordIcon, setPasswordIcon] = useState(false);
  const [homePage, setHomePage] = useState(false);
  const [loginbtn, setLoginBtn] = useState(false);
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userExternalId, setUserExternalId] = useState("");
  const [newUserDetails, setNewUserDetails] = useState([]);

  const dispatch = useDispatch();

  //svg to show show & hide password icon
  const hidePassword = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L17 17"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const showPassword = (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  //enable and disable password icon
  const myFunction = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      setPasswordIcon(true);
    } else {
      x.type = "password";
      setPasswordIcon(false);
    }
  };

  useEffect(() => {
    if (FBResponse) {
      setIsLoading(false);
      // let user_external_id = FBResponse.data.user_external_id ? FBResponse.data.user_external_id : newUserToken.response.data.user_external_id
      setUserExternalId(FBResponse.data.user_external_id);
      // let token = FBResponse.response.data.login_type ? newUserToken.response.data.accessToken : FBResponse.response.data.accessToken
      // dispatch(getTokenDetails(user_external_id));
    } else if (googleResponse) {
      setIsLoading(false);
      // let user_external_id = googleResponse.data.user_external_id ? googleResponse.data.user_external_id : newUserToken.response.data.user_external_id
      setUserExternalId(googleResponse.data.user_external_id);
      // let token = googleResponse.data.login_type ? newUserToken.response.data.accessToken : googleResponse.data.accessToken
      // dispatch(getTokenDetails(user_external_id));
    } else if (nexmoVerificationResponse.response) {
      setIsLoading(false);
      // let user_external_id = nexmoVerificationResponse.response.data.login_type ? newUserToken.response.data.user_external_id : nexmoVerificationResponse.response.data.user_external_id
      setUserExternalId(
        nexmoVerificationResponse.response.data.user_external_id
      );
      // let token = nexmoVerificationResponse.response.data.login_type ? newUserToken.response.data.accessToken : nexmoVerificationResponse.response.data.accessToken
      // dispatch(getTokenDetails(user_external_id));
    }
  }, []);

  //function to enable login btn onload
  useEffect(() => {
    if (Password.length > 0) {
      setLoginBtn(true);
    } else {
      setLoginBtn(false);
    }
  }, [Password]);

  useEffect(() => {
    if (passwordDetails) {
      if (passwordDetails.response.statusCode === 200) {
        setNewUserDetails(passwordDetails.response.data)
        dispatch(
          getTokenDetails(
            passwordDetails.response.data.accessToken,
            passwordDetails.response.data.refreshToken,
            passwordDetails.response.data.user_profile.user_external_id
          )
        );
        localStorage.setItem(
          "accessToken",
          passwordDetails.response.data.accessToken
        );
        localStorage.setItem(
          "refreshToken",
          passwordDetails.response.data.refreshToken
        );
        localStorage.setItem(
          "user_external_id",
          passwordDetails.response.data.user_profile.user_external_id
        );
        dispatch(storeUserDetails(passwordDetails.response.data.user_profile));

        dispatch(userAuthorized(true));
        setIsLoading(false);
        setLoginBtn(true);
        setHomePage(true);
        setPasswordValidationMsg("");
      } else {
        setIsLoading(false);
        setLoginBtn(false);
        setHomePage(false);
        setPasswordValidationMsg("Invalid Password");
        // setPasswordValidationMsg(passwordDetails.response.data.message);
      }
    }
  }, [passwordDetails]);

  //function to dispatch password and external_Id
  const handleSubmit = (e, Password) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(verifyPassword(Password, userExternalId));
  };

  const passwordSet = (e) => {
    setPassword(e.target.value);
    // setPasswordValidationMsg(" ")
  };

  const handleClearUserDetail = () => {
    // localStorage.clear(); //clear data from localstorage
    dispatch(logoutUser());
    props.history.push("/login");
    window.location.reload(true);

    // window.location.reload(false);
  };
  //function to render password screen Ui
  const EnterPasswordScreen = () => {
    return (
      <div
        className="password-container"
        style={{ backgroundColor: "#E4E6EB", position: "relative" }}
      >
        <LoginNavbar />

        <Container>
          <div className="row">
            <div className="col-sm-12">
              <Card className="password-card">
                <Card.Header className="password-header">
                  Enter Password
                  <Link
                    to="/login"
                    className="password-close"
                    onClick={handleClearUserDetail}
                  >
                    <img src={x} alt="close-button" />
                  </Link>
                </Card.Header>
                <Card.Body>
                  <Form
                    className="password-form"
                    onSubmit={(e) => handleSubmit(e, Password)}
                  >
                    <Form.Group>
                      <Form.Label className="password-form-label">
                        Enter Password
                      </Form.Label>
                      <InputGroup
                        className={
                          passwordValidationMsg == ""
                            ? "mb-1"
                            : "mb-1  error-input"
                        }
                      >
                        <Form.Control
                          size="lg"
                          type="password"
                          id="myInput"
                          placeholder="Enter password"
                          onChange={(e) => passwordSet(e)}
                          value={Password}
                        />

                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2">
                            {showPasswordIcon ? (
                              <span onClick={myFunction}>{showPassword}</span>
                            ) : (
                                <span onClick={myFunction}>{hidePassword}</span>
                              )}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <span className="error-message">
                        {passwordValidationMsg}
                      </span>

                      <Link
                        to="/send-password-link"
                        className="forgotten-password"
                      >
                        Forgot password ?
                      </Link>
                    </Form.Group>
                    {/* {loginbtn ? <Button className="password-enable-button" type="submit">Login</Button> : <Button className="password-button" type="submit">Login</Button>} */}
                    {isLoading ? (
                      <Button className="password-enable-button" type="submit">
                        Login <Spinner animation="border" />
                      </Button>
                    ) : (
                        <Button
                          className="password-enable-button"
                          type="submit"
                          disabled={!loginbtn}
                        >
                          Login
                        </Button>
                      )}
                  </Form>
                  {homePage && (
                    <Redirect
                      to={{
                        pathname: "/home",
                        state: { UserDetails: newUserDetails }
                      }}
                    />
                  )}
                  {homePage ? (
                    localStorage.getItem("content_external_id") ? (
                      <Redirect
                        to={{
                          pathname: `/share/${localStorage.getItem(
                            "content_external_id"
                          )}`,
                        }}
                      />
                    ) : (
                        ""
                      )
                  ) : (
                      ""
                    )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </Container>
        <LeanmoreFooter />
      </div>
    );
  };
  return <>{EnterPasswordScreen()}</>;
};
export default EnterPassword;