import React from "react";
import { Container, Row, Col, Navbar, Nav, NavLink } from "react-bootstrap";

import brandLogo from "../../assets/svg/brandLogo.svg";
import "../../assets/scss/SettingsPrivacy/termsAndConditions.scss";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <div className="privacy-terms-container">
        <div className="privacy-terms-navbar-wrapper">
          <Navbar>
            <Navbar.Brand href="/home">
              <img src={brandLogo} width="66" height="48" alt="brand-logo" />
            </Navbar.Brand>

            <Nav className="ml-auto">
              <Nav.Item>
                <NavLink className="privacy-terms-navlink" href="/">
                  Back to GoPoolit
                </NavLink>
              </Nav.Item>
            </Nav>
          </Navbar>
        </div>

        <Container>
          <Row>
            <Col>
              <h3 className="terms-header">
                GoPoolit Service Agreement Terms & Conditions
              </h3>
              <p className="terms-update-text">
                Last updated on 12 December 2020.
              </p>
              <p>
                <span className="terms-span">
                  These terms and conditions (“Terms”) govern your use of the
                  Services provided by the Company (also GoPoolit, Company, we,
                  us, our) through the Company website (“Website”) and the
                  application for mobile and handheld devices (“App”). The
                  Website and the App are jointly referred to as the “Platform”.
                  Wherever used in these Terms of Service, “you”, “your”,
                  “Customer“ or similar terms means the entity subscribing this
                  Agreement and the person or legal entity accessing or using
                  the Services. Your subscription shall only be deemed to be
                  accepted when Company issues a written acceptance of the
                  subscription. Subject to and conditioned on your payment of
                  fees and compliance with all other terms and conditions of
                  this Agreement, Company hereby grants you a revocable,
                  non-exclusive, non-transferable, non-sublicensable, limited
                  right to access and use the Services during the term solely
                  for your internal business operations by individuals who are
                  directly accessing the Services authorized by you and to whom
                  we have supplied user identifications and passwords, which may
                  include your employees, consultants, contractors, agents or
                  other designees, but shall not include any employee or agent
                  of any Company competitor. (“Authorized Users”) in accordance
                  with the terms and conditions herein. You acknowledge that the
                  rights granted to you under this Agreement are non-exclusive,
                  and nothing in this Agreement will be interpreted or construed
                  to prohibit or in any way restrict Company’s right to license,
                  sell or otherwise make available the Services to any third
                  party or perform any Services for any third party. You shall
                  not, and shall not permit any Authorized Users to, use the
                  Services, or any component of the Services, for any purposes
                  beyond the scope of the access granted in this Agreement. You
                  shall not at any time, directly or indirectly, and shall not
                  permit any Authorized Users to copy, modify, or create
                  derivative works of the Services, any component of the
                  Services, in whole or in part, rent, lease, lend, sell,
                  license, sublicense, assign, distribute, publish, transfer, or
                  otherwise make available the Services except as expressly
                  permitted under this Agreement, reverse engineer, disassemble,
                  decompile, decode, adapt, or otherwise attempt to derive or
                  gain access to any component of the Services, in whole or in
                  part, remove any proprietary notices from the Services, use
                  the Services in any manner or for any purpose that infringes,
                  misappropriates, or otherwise violates any intellectual
                  property right or other right of any person, or that violates
                  any applicable law, regulation, or rule. You must not make the
                  Services available to anyone other than to your Authorized
                  Users; allow more than one individual Authorized User to use
                  each individual account; sell, trade or otherwise transfer
                  your accounts to another party; use the Services to store or
                  transmit any content, that may be infringing, defamatory,
                  threatening, harmful or otherwise tortious or unlawful,
                  including any content that may violate intellectual property,
                  privacy, rights of publicity, other laws, send spam or other
                  unsolicited messages in violation of applicable law; upload to
                  or transmit from, the Services any data, file, software or
                  link that contains or redirects to a virus, trojan horse, worm
                  or other harmful component; access the Services in order to
                  build a competitive product or service or copy any ideas,
                  features, functions or graphics of the Services; attempt to
                  gain unauthorized access to the Services or its related
                  systems or networks; or authorize, permit or encourage any
                  third party to do any of the actions prohibited by this
                  Agreement. Company reserves all rights not expressly granted
                  to you in this Agreement. Except for the limited rights and
                  licenses expressly granted under this Agreement, nothing in
                  this Agreement grants, by implication, waiver, or otherwise,
                  to you or any third party any intellectual property rights or
                  other right, title, or interest in Company property.
                </span>
                <span className="terms-span">
                  The Services may not be used for unlawful, fraudulent,
                  offensive, or obscene activity, as further described and set
                  forth in Company's acceptable use policy ("AUP") and other
                  Policies published on our Website as may be amended from time
                  to time, which are incorporated to these Terms by reference.
                  You will comply with the Terms, all applicable laws, rules,
                  and regulations, and all guidelines, standards, and
                  requirements that may be posted on our Website, as amended
                  from time to time, including the AUP. Your continued usage of
                  the Services shall constitute your acceptance of the amended
                  Terms. You are responsible and liable for all uses of the
                  Services resulting from access provided by you, directly or
                  indirectly, whether such access or use is permitted by or in
                  violation of this Agreement, use best efforts to prevent
                  unauthorized access to or use of the Services, including
                  ensuring that Authorized Users keep their password and user
                  name confidential and not permitting any third party to access
                  or use your user name, password or account for the Services.
                  Without limiting the generality of the foregoing, you are
                  responsible for all acts and omissions of Authorized Users,
                  and any act or omission by an Authorized User that would
                  constitute a breach of this Agreement if taken by you will be
                  deemed a breach of this Agreement by you. You shall use
                  reasonable efforts to make all Authorized Users aware of this
                  Agreement's provisions as applicable to such Authorized User's
                  use of the Services and shall cause Authorized Users to comply
                  with such provisions. You shall be responsible for your and
                  your Authorized Users’ compliance with the Policies issued by
                  Company, you shall be solely responsible for the accuracy,
                  quality, integrity, legality and ethics of your activities and
                  those of the Authorized Users. You shall promptly notify
                  Company if you become aware of or reasonably suspect any
                  security breach, including any loss, theft or unauthorized
                  disclosure or use of your (or any Authorized User’s) user
                  name, password or account. Company may monitor your use of the
                  Services, collect, and compile data and information related to
                  your use of the Services, to compile statistical and
                  performance information related to the provision and operation
                  of the Services ("Aggregated Statistics"). As between Company
                  and you, all right, title, and interest in Aggregated
                  Statistics, and all intellectual property rights therein,
                  belong to and are retained solely by Company. You hereby grant
                  to Company a royalty-free, worldwide license to reproduce,
                  distribute, and otherwise use and display your data and
                  perform all acts with respect to your data as may be
                  necessary, and a perpetual, irrevocable, royalty-free,
                  worldwide license to reproduce, distribute, modify, and
                  otherwise use and display your data incorporated within the
                  Aggregated Statistics. You will ensure that your data and any
                  Authorized User's use of your data will not violate any policy
                  or terms referenced in or incorporated into this Agreement or
                  any applicable law. You are solely responsible for the
                  development, content, operation, maintenance, and use of your
                  data. You understand that by using the Services you may be
                  exposed to third party content, information that might be
                  unlawful, offensive, harmful, inaccurate, indecent, offensive,
                  inaccurate, unlawful or otherwise inappropriate or
                  objectionable. Company does not own, control or review third
                  party content and Company has no obligation to preview,
                  verify, flag, modify, filter or remove any such third-party
                  content, even if requested to do so, although Company may do
                  so in its sole discretion.
                </span>
                <span className="terms-span">
                  Company reserves the right to amend or update the Services and
                  that such updates may result in changes in the appearance
                  and/or functionality of the Services (including the addition,
                  modification or removal of functionality, features or
                  content). Excluding the addition of wholly new products,
                  Company shall provide, implement, configure, install, support
                  and maintain any and all updates, upgrades, enhancements,
                  improvements, releases, corrections, bug fixes, patches and
                  modifications to the Services. You acknowledge that the
                  Services operate on several sources and that the Services
                  provided are highly dependent on the availability of such
                  sources, including the internet. If at any time any sources
                  cease to operate, Company may cease to provide such features
                  to you without entitling you to refund, credit or other
                  compensation. We cannot guarantee that Services will always be
                  available however and we may need to take the Service and site
                  down from time to time in order to undertake important
                  maintenance.
                </span>
                <span className="terms-span">
                  Your registration is subject to providing such documentation
                  and other evidence as is requested by Company to carry out and
                  be satisfied with the results of all necessary KYC/KYB or
                  other checks which Company deems necessary or is required to
                  carry out under any applicable law or legislation or by any
                  regulatory authority in relation to the Services contemplated
                  by this Agreement and to the identity of any parties to this
                  Agreement and their directors, officers, shareholders and
                  ultimate beneficial owners. Upon registration, you must
                  provide information, data to the Company that is true and
                  factually correct which if found fraudulent or misleading, can
                  result in termination of your account. If the Company has
                  reasonable grounds to suspect violation of these Terms or of
                  any law or regulation or that the registration information you
                  have provided is untrue, inaccurate, outdated, or incomplete,
                  the Company reserves the right to terminate your account and
                  refuse future use of the Services and the Platform. You
                  acknowledge that the Company is not responsible for any loss
                  suffered by you in the event your account is terminated. Your
                  account name and password are confidential and should be
                  protected by you for as long as you have an account with us.
                  You acknowledge that you are solely responsible for your
                  account and that you alone have access to it.
                </span>
                <span className="terms-span">
                  You agree and acknowledge that between the parties, Company is
                  the donor of the amounts delivered to you, and not the
                  individual users of our Services. As compensation for the
                  Services, you shall pay Company a ten percent (10%) share (the
                  “Fee”) of all amounts (“gross revenue”) that we receive from
                  individuals earmarked for you. The Fee and other expenses
                  shall be deducted from the gross revenue. You shall reimburse
                  Company for the expenses related to the transactions required
                  to provide the Service. After all deductions are made, Company
                  shall make the donation and deliver any remaining amounts to
                  you. You shall make all Fee and reimbursement payments
                  hereunder in the currency indicated by Company, on or before
                  the due date. You are responsible for paying all Taxes
                  associated with your purchase of Services. If Company has the
                  legal obligation to pay or collect Taxes for which you are
                  responsible under this section, the appropriate amount shall
                  be invoiced to and paid by you. Any and all payments such as
                  or on account of the Fees or donation or reimbursement of
                  expenses or Vendor costs made by Company to you, and payable
                  by you under this Agreement shall be made free and clear of
                  and without deduction or withholding for any Taxes. If you are
                  required to deduct or withhold any Taxes from such payments,
                  then the sum payable by you shall be increased as necessary so
                  that, after making all required deductions or withholdings,
                  Company receives an amount equal to the sum it would have
                  received had no such deduction or withholding been made. Taxes
                  shall include all assessments, charges, fees and levies that
                  may be levied or based upon the sale or license of goods
                  and/or services, as the case may be, including all sales, use,
                  goods and services, value added and taxes, custom duties and
                  assessments together with any instalments with respect to any
                  interest, fines and penalties imposed by any governmental
                  authority (including federal, state, provincial, municipal or
                  foreign governmental authorities). If you fail to make any
                  payment when due, without limiting Company's other rights and
                  remedies: (i) Company may charge the highest rate permitted
                  under applicable law; (ii) You shall reimburse Company for all
                  costs incurred by Company in collecting any late payments or
                  interest, including attorneys' fees, court costs, and
                  collection agency fees; and (iii) if such failure continues
                  for thirty (30) days or more, Company may suspend your and all
                  other Authorized Users' access to any portion or all of the
                  Services until such amounts are paid in full. All Fees and
                  other amounts payable by you under this Agreement are
                  exclusive of taxes and similar assessments. You are
                  responsible for all sales, use, and excise taxes, and any
                  other similar taxes, duties, and charges of any kind imposed
                  by any governmental or regulatory authority on any amounts
                  payable by you hereunder, other than any taxes imposed on
                  Company's income. Company shall not be required to provide a
                  tax exemption certificate by any taxing authority
                  certification of any tax exemption in order to make the
                  donation to you. The Company will provide you with a report of
                  the total donations you have received when you withdraw the
                  donations from your account with our Vendor. The report will
                  show you all the deductions that were made.
                </span>
                <span className="terms-span">
                  You understand that donation (s) via Company’s Website, App or
                  Platform it involves two steps i.e., Step 1 “Loaded”. As &
                  when you load the fund (s) to initiate transaction through the
                  Company Website, App or Platform (as defined herein) Step 2
                  “Allocation”. Further, you agree that when you decide or
                  select the charity or recipient of the loaded fund (s),
                  Company shall entertain requests for refund of fund (s) only
                  when:
                  <li className="terms-list">
                    {" "}
                    a. the written refund request is received within fourteen
                    (14) days from the date of Loaded;
                  </li>
                  <li className="terms-list">
                    b. no amount whatsoever has been utilized by the Company
                    from the transacted or donated fund (s)
                  </li>
                </span>
                <span className="terms-span">
                  Notwithstanding anything contrary contained herein, Company
                  reserves all rights regarding the refund (s) and Company’s
                  decision in this case shall be conclusive and binding.
                </span>
                <span className="terms-span">
                  The Company maintains accounts with certain third parties and
                  Vendors (“Vendors”). For example, a Vendor may provide
                  accounts in which funds (other than the interest accruing on
                  such funds) are held for the charity and do not belong to the
                  Company. A Vendor will charge fees for collecting the payment
                  which will be reflected in your final invoice. You acknowledge
                  that the Services will require you to interact with and/or
                  purchase third party services from Vendors. When you access
                  such Vendor services, you do so at your own risk. Any use of
                  Vendor services is governed solely by the terms and conditions
                  of such Vendors, and any contract entered into or any
                  transaction completed via any Vendor is between you and the
                  relevant third party and not Company. Company makes no
                  representation and shall have no liability or obligation
                  whatsoever in relation to the content, use of or
                  correspondence with any such Vendor services or any
                  transactions completed, or any contract entered into between
                  you with any such third party. You agree that the Company
                  shall not be responsible for the services provided by the
                  Vendor or their use of your information. You must comply with
                  any applicable terms of Agreement of any/all Vendors engaged
                  by the Company when using their services in connection with
                  the Services provided by the Company.
                </span>
                <span className="terms-span">
                  The Company may terminate the Agreement at any time and for
                  any reason by terminating your account. The Company may place
                  your account in an 'inactive' status if in a period of 12
                  consecutive months, you have not withdrawn the donations, not
                  posted any advertisements on your profile page or haven’t had
                  any web traffic on your profile page. Termination of your
                  account includes removal of access to all offerings within the
                  Services; deletion of your password, Registration Information,
                  profile page and all related information, files, data and
                  content associated with your account (or any part thereof);
                  the money in your account with the Vendor will be paid out to
                  you within 30 days from termination; and barring further use
                  of the Services. We reserve the right to retain your
                  Registration Information and not delete your account
                  immediately to investigate or identify illegal activity or
                  breaches of our Terms and Policies (for example, to identify
                  or investigate misuse of our Services or systems) or comply
                  with a legal obligation, or a request of a judicial or
                  administrative authority, law enforcement or a government
                  agency. you agree to not disparage the Services, the Company,
                  donors and/or Vendor whether your account was terminated for
                  cause or not. Upon termination of this Agreement for any
                  reason you will immediately cease all use of the Services; and
                  you will have no further access to your account and you will
                  pay all unpaid amounts owing to Company and Vendors.
                </span>
                <span className="terms-span">
                  You agree to indemnify the Company from and against and in
                  respect of any or all claims, demands, actions, proceedings,
                  liabilities, losses, charges and expenses including legal
                  fees, costs, loss of profits, exemplary and special damages,
                  loss of donations, loss of revenue, goodwill which the Company
                  may incur or sustain directly or indirectly from or by any
                  reason of or in relation to the use or proposed use of the
                  Services or Platform or from your violation of any law
                  prevailing in the country of your residence or elsewhere
                  including copyright and trademark laws, or the terms of your
                  charity license or approvals or any other law in force in your
                  country or elsewhere that may be applicable to the Services,
                  or from breach of your representations and warranties, or
                  breach of this Terms, negligence, acts or omissions and you
                  shall pay such sums on demand by the Company. However, you
                  agree and acknowledge that the Company can pursue all remedies
                  against you for any breach of the laws, rules, regulations, or
                  guidelines in force in your country or elsewhere. Nothing in
                  this Agreement shall limit your payment obligations under this
                  Agreement. You assume sole responsibility for results obtained
                  from the use of the Services.
                </span>
                <span className="terms-span">
                  Company shall have no liability for any damage caused by
                  errors or omissions in any information, instructions or data
                  provided to Company in connection with the Services, or any
                  actions taken by Company at your direction. You understand and
                  accept that the Services is being provided on an “as is” and
                  “as available” basis and Company, except as expressly provided
                  under this Agreement, to the greatest extent permitted by
                  applicable law, specifically disclaims all warranties, whether
                  express, implied, statutory, or otherwise, regarding its
                  Services and those provided by Vendors. Company specifically
                  disclaims all implied warranties, including but not limited to
                  fitness for a particular purpose, title, and non-infringement,
                  and all warranties arising from course of dealing, usage, or
                  trade practice. Company makes no warranty of any kind that the
                  Services, or any products or results of the use thereof, will
                  meet your or any other person's or entity's requirements,
                  operate without interruption, achieve any intended result, be
                  compatible or work with any of your or any third party's
                  software, system, or other services, or be secure, accurate,
                  complete, free of harmful code, or error-free, or that any
                  errors or defects can or will be corrected. Your use of the
                  Services is at your sole risk. The Company does not guarantee
                  continuous, uninterrupted, error or virus free or secure
                  access to the Services. Company does not make any
                  representations or guarantees regarding uptime or availability
                  of the Services. The Services may be under constant upgrades,
                  and some functions and features may not be fully operational.
                  You acknowledge that Company does not control the electronic
                  distribution of information, and that there may be delays,
                  omissions, or inaccuracies in the content provided or delay or
                  errors in functionality of the Services. As a result, the
                  Company does not represent that the information posted is
                  correct in every case. No advice or information, whether oral
                  or written, obtained from Company or elsewhere will create any
                  warranty or condition not expressly stated in this Agreement.
                  These limitations shall apply notwithstanding the failure of
                  essential purpose of any limited remedy provided under this
                  Agreement. Company disclaims all responsibility or liability
                  in relation to the content made available through the
                  services, including any content or services provided by third
                  parties. Company does not control and is not responsible for
                  what users post, transmit or share on or through the Services.
                  Company is not responsible or liable in any manner for any
                  Vendor services or any sources associated with or utilized in
                  connection with the Services, including the failure of any
                  such Vendor services or sources. Company expressly denies any
                  responsibility resulting from hacking, tampering or other
                  unauthorized access or use of the services or your account or
                  the information contained therein. If you are dissatisfied or
                  harmed by Company or anything related to Company, you may
                  cancel your Company account and terminate this Agreement and
                  such termination shall be your sole and exclusive remedy (and
                  Company’s sole and exclusive liability). The Platform contains
                  links to third party websites. The Company does not make any
                  representations or warrant the quality of the services
                  provided by such Vendors or third parties nor does the Company
                  have any control over the content or availability of such
                  sites.
                </span>
                <span className="terms-span">
                  Nothing in these Terms of Service shall limit or exclude
                  Company’s liability for death or personal injury caused by its
                  negligence, or the negligence of its employees, agents or
                  subcontractors or for fraud or fraudulent misrepresentation.
                  You have certain legal rights implied by law. Nothing in this
                  Agreement is intended to affect your right to certain legal
                  remedies if we breach any of these rights or other rights to
                  which you may also be entitled. Company shall not be liable
                  whether in tort (including for negligence or breach of
                  statutory duty), contract, misrepresentation, restitution or
                  otherwise for any loss of profits, loss of sales or business,
                  loss of agreements or contracts, loss of anticipated savings,
                  loss of use or corruption of software, data or information,
                  damage to or depletion of goodwill and/or similar losses, or
                  pure economic loss, or for any special, indirect or
                  consequential loss, costs, damages, charges or expenses
                  however arising under this Agreement. Company shall not be
                  liable for losses not caused by Company’s breach, indirect
                  losses, or due to causes beyond our reasonable control
                  including but not limited to any category of force majeure,
                  whether defined as such or not in this Agreement, and also
                  including, but not limited to, Acts of God, pandemics,
                  earthquakes, cyclones, storms, flooding, fire, disease, fog,
                  snow or frost or other natural calamities or disasters;
                  unexpected circumstances including (but not limited to) war,
                  accidents, acts of public enemies, strikes, embargoes, perils
                  of the air, local disputes or civil commotions; interruption
                  in the services of networks or any other mechanical problem;
                  and any reason or cause beyond the Company’s control. If for
                  any reason Company is found in breach, we shall only be liable
                  for losses which are a reasonably foreseeable consequence of
                  such a breach. Losses are deemed foreseeable where they could
                  be contemplated by you and Company at the time of entering
                  into this Agreement.
                </span>
                <span className="terms-span">
                  Company’s total aggregate liability in contract, tort
                  (including negligence or breach of statutory duty),
                  misrepresentation, restitution or otherwise, arising in
                  connection with this agreement shall be limited to the total
                  Fees paid by you during the six (6) months immediately
                  preceding the date on which the claim arose.
                </span>
                <span className="terms-span">
                  From time to time during the Term, Company may disclose or
                  make available to you information about its business affairs,
                  products, confidential intellectual property, trade secrets,
                  third-party confidential information, and other sensitive or
                  proprietary information, whether orally or in written,
                  electronic, or other form or media/in written or electronic
                  form or media, whether or not marked, designated, or otherwise
                  identified as "confidential" at the time of disclosure
                  (collectively, "Confidential Information"). You shall not
                  disclose Company’s Confidential Information to any person or
                  entity, except to your employees who have a need to know the
                  Confidential Information for you to exercise your rights or
                  perform your obligations. You further agree that you will not
                  try to access or secure access to the Company’s systems or
                  network that hosts our Platform, and Services; alter, damage
                  or delete any materials appearing on the Platform, or use any
                  automated or other means to “scrape” information from the
                  Platform; download, copy or extract any data, computer data
                  base or information from the Company’s system or network
                  including information or data; introduce any virus into the
                  Platform or Company’s network; damage or cause any damage to
                  the Platform or Company’s network, data, computer data base or
                  any other programs residing in the Platform or Company’s
                  network; disrupt or cause disruption of the Platform or
                  Company’s network; deny or cause the denial of access to the
                  Platform or Company’s network to the Company’s employees,
                  donors, other charities, Vendors, agents by any means; charge
                  the commission payable by you to the account of another
                  charity by tampering with or manipulating the Platform or
                  Company’s network; destroy, delete or alter any information
                  residing in the Platform or Company’s network or diminish its
                  value or utility by any means; steal, conceal, destroy or
                  alter or cause any person to steal, conceal, destroy or alter
                  any computer source code used by the Company with an intention
                  to cause damage; impersonate another charity, or a donor or
                  third party; claim any rights over the Company’s intellectual
                  property rights such as, copyrights and/or trademark rights
                  and/or patent rights over the Website, Platform, Services, App
                  nor over the intellectual property rights belonging to another
                  donor, charity, Vendor, or third party; disrupt the normal
                  flow of communication on the Company’s system through "spam"
                  email or by other means; claim a relationship with or speak
                  for any business, organization, or person for which you are
                  not authorized to claim such a relationship; claim to be an
                  agent of the Company or make any decisions on the Company’s
                  behalf; violate any operating rule, policy or guideline of our
                  internet access provider or online service. The Company
                  reserves the right to prohibit or restrict conduct within an
                  interactive area, and reserve the right to edit, refuse to
                  post, or to remove any content, in whole or in part, which has
                  been brought to our attention and which in our sole discretion
                  violates our Code of Conduct, or is otherwise objectionable.
                  Company complies with its privacy policy available within the
                  Platform ("Data Protection and Privacy Policy"), in providing
                  the Services. The Data Protection and Privacy Policy is
                  subject to change as described therein. By accessing, using,
                  and providing information to or through the Services, you
                  acknowledge that you have reviewed and accepted our Data
                  Protection and Privacy Policy, and you consent to all actions
                  taken by us with respect to your information in compliance
                  with the then-current version of our Data Protection and
                  Privacy Policy.
                </span>
                <span className="terms-span">
                  These Terms together with the Acceptable Use Policy, Data
                  Protection and Privacy Policy, Code of Conduct, and other
                  mandatory Policies issued by the Company from time to time
                  (“Policies”) constitute the entire Agreement between you and
                  the Company. No other terms whether expressed or implied shall
                  form part of this Agreement. In the event of any conflict
                  between these Terms and any other document, the Terms in this
                  document shall prevail. No delay or failure on the Company’s
                  part to enforce its rights or remedies under these Terms shall
                  constitute a waiver on the Company’s part of such rights or
                  remedies unless such waiver is confirmed in writing.
                </span>
                <span className="terms-span">
                  The Company is the owner or the licensee of all intellectual
                  property rights (including but not limited to patents,
                  trademarks, service marks, trade secrets, copyrights and other
                  intellectual property) in the Services, Platform, the software
                  used in providing Services and in the material published on
                  it. These works are protected by copyright laws and treaties
                  around the world. All such rights are reserved with the
                  Company. You acknowledge and agree that the Company and its
                  licensors retain ownership of all intellectual property rights
                  (including applicable copyrights, trademarks and other
                  proprietary rights) of any kind on the Platform, and Services.
                  The Company does not grant any license to you regarding any
                  intellectual property rights by virtue of these Terms. The
                  Company reserves all rights that are not explicitly granted to
                  you in under these Terms. The Company’s trademarks, logos,
                  service marks, images, trade names, designs, page headers,
                  button icons, scripts and other distinctive branding features
                  constitute the Company’s intellectual property and may not be
                  copied, imitated, or used, in whole or in part. you agree not
                  to infringe any intellectual property rights when you use the
                  Platform and you indemnify the Company from any claims which
                  may arise on the Company in this regard. By using the
                  Services, you agree to grant the Company, its affiliates,
                  subsidiaries, Vendors, partners, a non-exclusive, worldwide
                  and royalty-free license to use your charity’s name and logo
                  in the connection with the promotion of the Services.
                </span>
                <span className="terms-span">
                  You shall not use or access the Services if you are located in
                  any jurisdiction in which the provision of the Services is
                  prohibited under any applicable laws or regulations (a
                  “Prohibited Jurisdiction”) and you shall not provide access to
                  the Services to any government, entity or individual located
                  in any Prohibited Jurisdiction. You represent, warrant and
                  covenant that you are not named on any government list of
                  persons or entities prohibited from transaction with any
                  Canadian or U.S. person, that you are not a national of or a
                  company registered in, any Prohibited Jurisdiction, that you
                  shall not allow Authorized Users to access or use the Services
                  in violation of any applicable export embargoes, prohibitions
                  or restrictions; and that you shall comply with all applicable
                  laws regarding the transmission of data exported from the
                  country in which you (or your Authorized Users) are located.
                  Both parties will comply with all applicable requirements of
                  any data protection legislation. If Company processes any
                  personal data on your behalf when performing its obligations
                  under this Agreement, you will be considered the controller
                  and Company will be considered the processor for the purposes
                  of such legislation. Any personal data may be transferred or
                  stored outside the EEA or the country where the you and your
                  Authorized Users are located in order to carry out the
                  Services and Company’s other obligations under this Agreement.
                  You will ensure that you have all necessary appropriate
                  consents and notices in place to enable lawful transfer of any
                  personal data to Company for the duration and purposes of this
                  Agreement so that Company may lawfully use, process and
                  transfer the personal data in accordance with this agreement
                  on your behalf. You authorize Company to transfer any personal
                  data outside of the EEA. You consent to Company appointing any
                  third party processor of personal data under this Agreement.
                </span>
                <span className="terms-span">
                  No waiver of any provision of this Agreement is binding unless
                  it is in writing and signed by all parties to this Agreement.
                  The failure to exercise or the delay in exercising, any right
                  or remedy under this Agreement will not be deemed to be a
                  waiver of that right or remedy. No agency, partnership, joint
                  venture or employment relationship is created as a result of
                  this Agreement and neither party has any authority of any kind
                  to bind the other in any respect. If any of these Terms should
                  be determined to be illegal, invalid or otherwise
                  unenforceable by reason of the laws of any state or country in
                  which these Terms are intended to be effective, then to the
                  extent and within the jurisdiction which that term is illegal,
                  invalid or unenforceable, it shall be severed and deleted and
                  the remaining Terms shall survive, remain in full force and
                  effect and continue to be binding and enforceable. You may not
                  assign or otherwise transfer any of your rights or obligations
                  under this Agreement, whether by merger, sale of assets,
                  change of control, operation of law or otherwise without the
                  prior written consent of Company, and any attempted assignment
                  or transfer without such consent will be invalid. Company may
                  freely assign or delegate all rights and obligations under
                  this Agreement fully or partially without notice to you.
                  Company may also substitute, by way of unilateral novation,
                  effective upon notice to you, for any third party that assumes
                  our rights and obligations under this Agreement. Each party
                  irrevocably agrees that the courts of England and Wales shall
                  have exclusive jurisdiction to settle any dispute or claim
                  (including non-contractual disputes or claims) arising out of
                  or in connection with the Agreement or its subject matter or
                  formation. The arbitration proceedings shall be held at
                  Sheffield and conducted by an arbitrator appointed by the
                  Company and whose decision shall be final and binding upon the
                  parties. The arbitration proceedings shall be in the English
                  language and each party shall bear its own costs and expenses.
                  The Company reserves the right to modify or discontinue,
                  temporarily or permanently, these Terms (or any part thereof)
                  with notice. your continued access or use of the Services
                  shall be deemed your conclusive acceptance of the modified
                  Terms. These Terms have been signed in counterparts as
                  necessary, each of which shall be deemed to be an original,
                  and all of which together shall constitute one and the same
                  instrument. The Company’s rights under these Terms are
                  independent, cumulative and without prejudice to all other
                  rights available to it, and the exercise or non-exercise of
                  any such rights shall not prejudice or constitute a waiver of
                  any other rights of the Company, whether under these Terms or
                  otherwise. For purposes of service messages and notices about
                  the Services, we may place a notice across our Platform to
                  alert you to certain changes such as modifications to this
                  Agreement. Alternatively, notice may consist of an email from
                  us to an email address associated with your Account. You agree
                  that we may communicate with you through your account or
                  through other means including email, mobile number, telephone
                  or delivery address including the postal address provided in
                  connection to your account or Services associated with us. You
                  must provide legal notice to Company via email with a
                  duplicate copy sent via registered mail to 110a Hawshaw Lane,
                  Hoyland, Barnsley, England, S74 9ES.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
        <LeanmoreFooter />
      </div>
    </React.Fragment>
  );
};

export default TermsAndConditions;
