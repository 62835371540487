import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";

//components imports
import { GoogleLogin, FacebookLogin, Nexmo } from "../index";
// import { AppleLogin } from "./AppleLogin";

import { logoutUser } from "../../actions/storingUserData.js";

import LoginNavbar from "../LandingLearnMore/LoginNavbar";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import CookieConsent from "../CookieConsent/CookieConsent";
//css imports
import "../../assets/scss/Login/loginPage.scss";
import x from "../../assets/svg/x.svg";
import { loginNewUserName, loginNewEmail } from "../../api/LoginNewAPI";
import { storeCountriesList } from "../../actions/countryList";
import { storeUserDetails } from "../../actions/storingUserData";
import { userAuthorized } from "../../actions/isAuthorized";
import { useTranslation } from "react-i18next";

let countryCodeMessage = "";

const LoginPageNew = (props) => {
  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [validationMsg, setValidationMsg] = useState("");
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  // const [countryCodeMessage,setCountryCodeMessage] =  useState("")
  const history = useHistory();
  const handleClick = () => {
    props.history.push("/home");
    window.location.reload(true);
  };
  const [loading, setLoading] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(true);
  const [isFBLogin, setIsFBLogin] = useState(true);

  const [cookiesNotAccepted, setCookiesNotAccepted] = useState(false);
  const [fromLogin, setFromLogin] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState("");

  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );

  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // function HeaderComponent() {
  //   return (
  //     <div>
  //       <button onClick={() => i18n.changeLanguage("es")}>Spanish</button>
  //       <button onClick={() => i18n.changeLanguage("en")}>English</button>
  //     </div>
  //   );
  // }

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  const handleSubmit = async (e, UserName, Password) => {
    e.preventDefault();
    setLoading(true);
    // setValidationMsg("Please wait...")

    const validateEmail = (UserName) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(UserName);
    };
    if (validateEmail(UserName)) {
      let res = await loginNewEmail(UserName, Password); //email login
      if (res && res.response && res.response.statusCode === 401) {
        setLoading(false);
        setValidationMsg("Invalid Username or Password");
      }

      if (res.response.statusCode === 200) {
        setLoading(false);
        localStorage.setItem("accessToken", res.response.data.accessToken);
        localStorage.setItem("refreshToken", res.response.data.refreshToken);
        localStorage.setItem(
          "user_external_id",
          res.response.data.user_profile.user_external_id
        );
        dispatch(storeUserDetails(res.response.data.user_profile));
        dispatch(userAuthorized(true));
        props.history.push("/home");
      }
    } else {
      let res = await loginNewUserName(UserName, Password); //username login
      if (res && res.response && res.response.statusCode === 401) {
        setLoading(false);
        setValidationMsg("Invalid Username or Password");
      }

      if (res.response.statusCode === 200) {
        setLoading(false);
        localStorage.setItem("accessToken", res.response.data.accessToken);
        localStorage.setItem("refreshToken", res.response.data.refreshToken);
        localStorage.setItem(
          "user_external_id",
          res.response.data.user_profile.user_external_id
        );
        dispatch(storeUserDetails(res.response.data.user_profile));
        //   dispatch(storeUserDetails(response.data.user_profile));
        //   setIsLoading(false);
        //   setHomePage(true);
        dispatch(userAuthorized(true));
        // } else {
        //   setError(true);
        //   setIsLoading(false);
        // setTimeout(() => {
        //   return history.push("/"), localStorage.clear();
        // }, 5000);

        props.history.push("/home");
      }
    }
    // let res = await loginNew(Phone, Password);//phone
    // console.log(res)

    // props.history.push('/home')
    // dispatch(loginNew(UserName, Password));
  };

  const cookieStorage = {
    getItem: (key) => {
      const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[key];
    },
    deleteItem: () => {
      let multiple = document.cookie.split(";");
      for (let i = 0; i < multiple.length; i++) {
        let key = multiple[i].split("=");
        document.cookie =
          key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }
    },
  };

  useEffect(() => {
    if (
      cookieStorage.getItem("cookie_consent") === "false" ||
      cookieStorage.getItem("cookie_consent") === undefined
    ) {
      setCookiesNotAccepted(true);
    } else {
      setCookiesNotAccepted(false);
    }

    // dispatch(loadCharityNames());
    dispatch(storeCountriesList());
    dispatch(logoutUser());
    if (isAuthorized) {
      props.history.push("/home");
    }
  }, []);

  useEffect(() => {
    if (redirectUrl === "/") {
      props.history.push("/");
    }
  }, [redirectUrl]);

  const handleCookieMessage = () => {
    // cookieStorage.deleteItem();
    props.history.push("/");
  };

  const cookieMessageModal = () => {
    return (
      <div className="cookie-message-modal">
        <div className="cookie-message">
          <p>Please accept the cookies in order to login to the application.</p>

          <button
            type="button"
            className="btn btn-primary mt-3 float-right"
            onClick={handleCookieMessage}
          >
            Ok
          </button>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();

  const hidePassword = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L17 17"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const showPassword = (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleUserName = (e) => {
    e.preventDefault();
    setValidationMsg("");
    setUserName(e.target.value);
    // let regx = /^\d+$/;
    // if (regx.test(e.target.value)) {
    //   countryCodeMessage = "Please enter the country code along with the number"
    // } else {
    //   countryCodeMessage = ""
    // }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    setValidationMsg("");
    setPassword(e.target.value);
  };

  const myFunction = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      setShowPasswordIcon(true);
    } else {
      x.type = "password";
      setShowPasswordIcon(false);
    }
  };

  return (
    <>
      {/* {cookiesNotAccepted ? cookieMessageModal() : null} */}
      {cookiesNotAccepted ? (
        <CookieConsent fromLogin={fromLogin} setRedirectUrl={setRedirectUrl} />
      ) : null}
      <section className="login-page">
        <LoginNavbar />
        <Container className="login-page-container">
          <Row>
            <Col sm={12} className="login-page-right-content">
              <Card>
                <Card.Header className="h5">
                  <p className="login-page-gopoolit ">{t("login.loginText")}</p>
                  <Link
                    to="/home"
                    className="login-close"
                    onClick={handleClick}
                  >
                    <img src={x} alt="close-button" />
                  </Link>
                </Card.Header>
                <Card.Body>
                  {/* <Card.Title>Login with Social media</Card.Title> */}

                  <Form
                    onSubmit={(e) => handleSubmit(e, UserName, Password)}
                    autoComplete="off"
                  >
                    <Form.Group>
                      {/* <Form.Label className="profilesetup-form-label">
                      Username *
                    </Form.Label> */}
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder={t("login.emailFieldPlaceholder")}
                        // className={
                        //   (message != "" || validationMessage != "") &&
                        //   "error-input"
                        // }
                        value={UserName}
                        onChange={(e) => handleUserName(e)}
                        required
                        disabled={cookiesNotAccepted ? true : false}
                      />
                      {/* onChange={e =>  setUserName(e.target.value) } value={UserName} required /> */}
                      {/* <span className="error-message">{message}</span>
                    <span className="error-message">{validationMessage}</span> */}
                    </Form.Group>
                    <span
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        marginLeft: "5px",
                      }}
                      className="error-message"
                    >
                      {countryCodeMessage}
                    </span>

                    <Form.Group>
                      {/* <Form.Label className="profilesetup-form-label">
                      Password *
                    </Form.Label> */}
                      <div
                        className="input-border"
                        // className={`mb-2 ${
                        //   showPasswordOptions ? "error-input" : "input-border"
                        // }`}
                      >
                        <InputGroup>
                          {/* <Form.Control size="lg" type="password" id="myInput" placeholder="**********" onChange={e => setPassword(e.target.value)} ref={inputRef} value={Password} required /> */}
                          <Form.Control
                            size="lg"
                            type="password"
                            id="myInput"
                            placeholder="**********"
                            // onKeyUp={handlePwdKeyUp}
                            onChange={(e) => handlePassword(e)}
                            // ref={inputRef}
                            value={Password}
                            required
                            disabled={cookiesNotAccepted ? true : false}
                          />
                          {/* <input type="password" id="psw" name="psw" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}" title="Please follow the below instructions" required></input> */}
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                              {showPasswordIcon ? (
                                <span onClick={myFunction}>{showPassword}</span>
                              ) : (
                                <span onClick={myFunction}>{hidePassword}</span>
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <span
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            marginLeft: "5px",
                          }}
                          className="error-message"
                        >
                          {validationMsg}
                        </span>
                        <span
                          style={{
                            display: "block",
                            fontSize: "14px",
                            textAlign: "right",
                          }}
                        >
                          <Link
                            to="/send-password-link"
                            className="forgotten-password"
                            style={{ color: "#2077b1" }}
                          >
                            {t("login.forgottenPassword")}
                          </Link>
                        </span>
                      </div>

                      {loading ? (
                        <Button
                          className="mt-4 blue-buttonActive"
                          type="submit"
                          // disabled={valid}
                          style={{ width: "100%" }}
                          disabled
                        >
                          Please wait ...
                        </Button>
                      ) : (
                        <Button
                          className="mt-4 blue-buttonActive"
                          type="submit"
                          // disabled={valid}
                          style={{ width: "100%" }}
                          disabled={cookiesNotAccepted ? true : false}
                        >
                          {t("login.loginText")}
                        </Button>
                      )}

                      <div className="login-page-or-content">
                        <hr />
                        <span>or</span>
                        <hr />
                      </div>
                    </Form.Group>
                  </Form>
                  <Card.Text className="mb-3">
                    {t("login.loginWithSocialMedia")}
                    {/* <p>{t("login_with_social_media")}</p> */}
                  </Card.Text>
                  <div className="login-page-social-media">
                    <GoogleLogin
                      isLogin={isGoogleLogin}
                      cookiesNotAccepted={cookiesNotAccepted}
                    />
                    <FacebookLogin
                      isfblogin={isFBLogin}
                      cookiesNotAccepted={cookiesNotAccepted}
                    />
                  </div>

                  {/* <Nexmo /> */}
                </Card.Body>
                {/* <AppleLogin /> */}
                <Card.Footer className="text-muted">
                  <p className="login-page-terms-condition-footer">
                    {t("login.noAccount")}
                    <a href="/signup">
                      <span> {t("login.signUpText")}</span>
                    </a>
                  </p>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
        <LeanmoreFooter />
      </section>
    </>
  );
};

export default LoginPageNew;
