import { GET_OTHER_USER_PROFILE_DETAILS_BY_ID } from '../../constants/action-types'

const initState = {}

const OtherUserProfileDetailsByIdReducer = (state = initState, action) => {
    if (action.type === GET_OTHER_USER_PROFILE_DETAILS_BY_ID) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state;
}
export default OtherUserProfileDetailsByIdReducer;