import { FACEBOOK_SIGN_IN } from '../../constants/action-types'

const initState = {}

const FBResponseReducer = ( state = initState, action ) => {
    if (action.type === FACEBOOK_SIGN_IN) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default FBResponseReducer