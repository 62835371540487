import { HASH_TAG_INFO } from "../../constants/action-types";

export const hashTagInfo = (
  hashTagStatus,
  hashTagExtId,
  hashTagHighlightId
) => ({
  type: HASH_TAG_INFO,
  payload: {
    hashTagExtId,
    hashTagStatus,
    hashTagHighlightId,
  },
});
