import axios from "axios";
import { BASEURL } from "../config";

export const reportTypePostApi = () => {
  return axios({
    url: `${BASEURL}/v1/reportType`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const reportPostApi = (
  report_type_id,
  content_id,
  report_description
) => {
  return axios({
    url: `${BASEURL}/v1/report`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      report_type_id: `${report_type_id}`,
      content_id: `${content_id}`,
      report_description: report_description,
    },
    withCredentials: true,
  })
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getReportCommentType = () => {
  return axios({
    url: `${BASEURL}/v1/reportcommenttype`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  })
    .then((res) => res.data.response.data)
    .catch((err) => console.log(err));
};

export const reportComment = (
  reportTypeExternalId,
  commentExternalId,
  commentCreatorId,
  reportDescription
) => {
  return axios({
    url: `${BASEURL}/v1/reportcomment`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      report_type_external_id: reportTypeExternalId,
      comment_external_id: commentExternalId,
      comment_creator_user_id: commentCreatorId,
      report_comment_description: reportDescription,
    },
    withCredentials: true,
  })
    .then((res) => res.data.response)
    .catch((err) => console.log(err));
};
