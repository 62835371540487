// import axiosInstance from "../axios/axios";
import axios from 'axios';
import { GET_CHARITY_NAMES } from "../constants/action-types";
import { BASEURL } from "../config";

export const loadCharityNames = () => (dispatch) => {
  return axios({
    url: `${BASEURL}/v1/listCharity`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      userexternalid: `${localStorage.getItem("user_external_id")}`,
    },
  })
    .then((response) =>
      dispatch({
        type: GET_CHARITY_NAMES,
        payload: response.data.response,
      })
    )
    .catch((err) => err);
};
