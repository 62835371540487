import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  FormControl,
  Form,
  Spinner,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
// import { fabric } from "fabric";
import axiosInstance from "../../axios/axios";
import axiosCreateContent from "../../axios/createContentAxios";
import { v4 as uuidv4 } from "uuid";
import heic2any from "heic2any";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import { createPost } from "../../api/CreateContentAPI";
import { BASEURL } from "../../config";
// import Loader from "../Loader";
import ToastMessage from ".././ToastMessage";
import { getUserDetailsByUserId } from "../../actions/userProfile_action";
import Loader from ".././Loader";
// import { newPostCreated } from "../../actions/createPost";

import logo from "../../assets/images/logo.svg";
// import blackLogo from "../../assets/images/black-logo.svg"
import blueLogo from "../../assets/svg/blue-logo.svg";
// import greenTickMark from "../../assets/svg/greenTickMark.svg";
import errorButton from "../../assets/svg/errorButton.svg";
import addTextSVG from "../../assets/svg/addTextSVG.svg";
import navigationrightArrow from "../../assets/svg/navigationrightArrow.svg";
import navigationleftArrow from "../../assets/svg/navigationleftArrow.svg";
import LoaderGif from "../../assets/images/loader.gif";
// import CharityLogo from "../../assets/images/charity logo.png";
// import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg'
import avatar from "../../assets/images/avatar.png";
import { useTranslation } from "react-i18next";

import "../../assets/scss/CreateContent/createContent.scss";

//import slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostCreatedModal from "./PostCreatedModal";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    '& [class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
}));

const CreateContentOld = ({ handleHide }) => {
  const classes = useStyles();

  const userDetails = useSelector((state) => state.userDetails);
  const showCreateContent = useSelector(
    (state) => state.CreatePostPopup.showPopup
  );

  // const [buttonActive, setButtonActive] = useState(false);
  // const [show, setShow] = useState(false);
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");
  //-----------------//

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  //---------//

  const [charityLogo, setCharityLogo] = useState();

  const [caption, setCaption] = useState("");
  const [photo, setPhoto] = useState();
  // const [video, setVideo] = useState();
  const [createPostMedia, setCreatePostMedia] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [countryID, setCountryID] = useState();
  const [causeName, setCauseName] = useState(userDetails.cause_name);
  const [causeID, setCauseID] = useState(userDetails.cause_external_id);
  const [remainingCharacters, setRemainingCharacters] = useState(600);
  const [isLoading, setIsLoading] = useState();
  const [albumId, setAlbumId] = useState("");
  const [disableNext, setDisableNext] = useState(true);

  const [maxUploadLimitReached, setMaxUploadLimitReached] = useState(false);
  const [error, setError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [maxFileSizeReached, setMaxFileSizeReached] = useState(false);
  const [postCreatedSuccessfully, setPostCreatedSuccessfully] = useState(false);
  const [uploadToS3Message, setUploadToS3Message] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState("");
  const [filesArraySize, setFilesArraySize] = useState("");
  const [contentExternalID, setContentExternalID] = useState();
  const [disablePost, setDisablePost] = useState(false);
  const [videosUploadedCount, setVideosUploadedCount] = useState(0);
  const [maxVideosUpload, setMaxVideosUpload] = useState(false);

  const [writeTextActive, setWriteTextActive] = useState(false);
  const [addMediaActive, setAddMediaActive] = useState(false);

  const [search, setSearch] = useState();

  const fileInput = useRef();
  const enterDetailsRef = useRef();
  const selectCharityRef = useRef();
  const canvasRef = useRef();
  const logoRef = useRef();
  const writeTextLogoRef = useRef();
  // const blackLogoRef = useRef()
  const userImageRef = useRef();
  const userVideoRef = useRef();
  const formRef = useRef();
  const myCanvasRef = useRef();
  const writeTextRef = useRef();
  const textAreaRef = useRef();
  const ArrowRef = useRef(null);

  const charityData = useSelector((state) => state.charityNames.data);
  // const countryData = useSelector((state) => state.countriesList.data);
  const hashTagsData = useSelector((state) => state.hashTagsData);
  const allowedCountries = useSelector((state) => state.allowedCountries);

  const dispatch = useDispatch();

  // const buttonActiveStyle = buttonActive ? "create-active-button" : "";

  //On modal, disable scroll on background body
  const BodyStyle = () => {
    useEffect(() => {
      // document.body.classList.add(MODAL_OPEN_CLASS);
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const handleClose = () => {
    handleHide();
    // handleHideCreatePost();
    // setButtonActive(false);
    setCaption("");
    setPhoto("");
    setDisablePost(false);
    setFilesArraySize(0);
    // setVideo("");
    setCreatePostMedia([]);
    setUploadedFiles([]);
    setCountryName("");
    setCountryID("");
    setCauseName("");
    setCauseID("");
    setCharityLogo();
    setAlbumId("");
    setDisableNext(true);
    setWriteTextActive(false);
    setAddMediaActive(false);
    setRemainingCharacters(600);
    setError(false);
    setUploadError(false);
    setUrlError(false);
    setMaxFileSizeReached(false);
    setMaxUploadLimitReached(false);
    setUploading(false);
    setUploadingPercentage(0);
    setVideosUploadedCount(0);
    setIsLoading(false);
    enterDetailsRef.current.style.display = "block";
    selectCharityRef.current.style.display = "none";
    writeTextRef.current.style.display = "none";
    formRef.current.style.display = "block";
    textAreaRef.current.style.display = "block";
  };

  const handlePostCreatedStatus = () => {
    setPostCreatedSuccessfully(false);
  };

  //SVG's used in the component
  const addPhotoSVG = (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4286 0.428711H2.57143C1.15127 0.428711 0 1.57998 0 3.00014V15.0001C0 16.4203 1.15127 17.5715 2.57143 17.5715H21.4286C22.8487 17.5715 24 16.4203 24 15.0001V3.00014C24 1.57998 22.8487 0.428711 21.4286 0.428711ZM22.2857 10.3595L16.8917 4.96557C16.557 4.63093 16.0144 4.63093 15.6797 4.96557L8.57144 12.0738L5.74885 9.25122C5.41416 8.91658 4.87155 8.91658 4.53686 9.25122L1.71427 12.0738V3.00014C1.71427 2.52673 2.09802 2.14298 2.57143 2.14298H21.4286C21.902 2.14298 22.2857 2.52673 22.2857 3.00014V10.3595H22.2857Z"
        fill="#2077B1"
      />
    </svg>
  );

  const closeButton = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const backArrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 12H4.5"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19.5L4.5 12L12 4.5"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const closeButtonForWriteText = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="2" fill="black" fillOpacity="0.5" />
      <path
        d="M17 7L7 17"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7L17 17"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (createPostMedia.length === 0) {
      setDisableNext(true);
      setAddMediaActive(false);
      setWriteTextActive(false);
      setCauseID("");
      setCountryID("");
      setCountryName("");
      setCauseName("");
      setCharityLogo();
    } else if (createPostMedia.length > 0) {
      setAddMediaActive(true);
    }
  }, [createPostMedia]);

  useEffect(() => {
    if (caption && writeTextActive) {
      setDisableNext(false);
    } else if (caption.length === 0 && createPostMedia.length === 0) {
      setDisableNext(true);
    }
  }, [caption, writeTextActive]);

  // const uploadedFileHandler = async (e) => {
  //   let file = e.target.files[0];
  //   console.log(file);
  //   if (!file) return ""; //If the user cancels the popup

  //   let contentType = file.type;
  //   let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
  //   const fileSizeInMB = Math.floor(file.size / (1024 * 1024));
  //   let signedURL;

  //   if (contentType === "" || contentType === "image/heic") {
  //     //Changing it here to get the signedURl based on the watermarked image
  //     contentType = "image/jpeg";
  //     fileName = uuidv4() + `.${contentType.split("/")[1]}`;
  //     setIsLoading(true);
  //     let convertedBlob = await heicToJpg(file);
  //     let src = await getBase64(convertedBlob).then((base64) => {
  //       return base64;
  //     });
  //     console.log(src);
  //     await setPhoto(src);
  //     setUploadedFiles(uploadedFiles.concat({ fileName, contentType }));
  //     setTimeout(async () => {
  //       let watermarkedSRC = await handleImage();
  //       console.log(watermarkedSRC);
  //       const buffer = Buffer.from(
  //         watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
  //         "base64"
  //       );
  //       if (createPostMedia.length === 0) {
  //         return (
  //           (signedURL = await getSignedURL(contentType, fileName)),
  //           await setAlbumId(signedURL.album_id),
  //           setCreatePostMedia(
  //             createPostMedia.concat({
  //               buffer,
  //               fileName,
  //               contentType,
  //               watermarkedSRC,
  //               ...signedURL,
  //             })
  //           ),
  //           setDisableNext(false)
  //         );
  //       } else if (createPostMedia.length >= 1 && createPostMedia.length <= 9) {
  //         return (
  //           (signedURL = await getSignedURLforAlbum(
  //             contentType,
  //             fileName,
  //             albumId
  //           )),
  //           setCreatePostMedia(
  //             createPostMedia.concat({
  //               buffer,
  //               fileName,
  //               contentType,
  //               watermarkedSRC,
  //               ...signedURL,
  //             })
  //           )
  //         );
  //       } else if (createPostMedia.length >= 10) {
  //         setIsLoading(false);
  //         setMaxUploadLimitReached(true);
  //       }
  //     }, 1000);
  //   } else if (contentType === "image/png" || contentType === "image/jpeg") {
  //     setIsLoading(true);
  //     let src = await getBase64(file).then((base64) => {
  //       return base64;
  //     });
  //     // await setPhoto(src);
  //     let local = await setPhoto(src);
  //     console.log({ photo });
  //     setUploadedFiles(uploadedFiles.concat({ fileName, contentType }));
  //     setTimeout(async () => {
  //       let watermarkedSRC = await handleImage();
  //       const buffer = Buffer.from(
  //         watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
  //         "base64"
  //       );
  //       if (createPostMedia.length === 0) {
  //         return (
  //           (signedURL = await getSignedURL(contentType, fileName)),
  //           await setAlbumId(signedURL.album_id),
  //           setCreatePostMedia(
  //             createPostMedia.concat({
  //               buffer,
  //               fileName,
  //               contentType,
  //               watermarkedSRC,
  //               ...signedURL,
  //             })
  //           ),
  //           setDisableNext(false)
  //         );
  //       } else if (createPostMedia.length >= 1 && createPostMedia.length <= 9) {
  //         return (
  //           (signedURL = await getSignedURLforAlbum(
  //             contentType,
  //             fileName,
  //             albumId
  //           )),
  //           setCreatePostMedia(
  //             createPostMedia.concat({
  //               buffer,
  //               fileName,
  //               contentType,
  //               watermarkedSRC,
  //               ...signedURL,
  //             })
  //           )
  //         );
  //       } else if (createPostMedia.length >= 10) {
  //         setIsLoading(false);
  //         setMaxUploadLimitReached(true);
  //       }
  //     }, 1000);
  //   } else if (
  //     contentType === "video/mp4" ||
  //     contentType === "video/quicktime"
  //   ) {
  //     setIsLoading(true);
  //     if (fileSizeInMB > 200) {
  //       setMaxFileSizeReached(true);
  //       setIsLoading(false);
  //       setTimeout(() => {
  //         setMaxFileSizeReached(false);
  //       }, 3000);
  //     } else {
  //       let src = await getBase64(file).then((base64) => {
  //         return base64;
  //       });
  //       // await setVideo(src);
  //       setUploadedFiles(
  //         uploadedFiles.concat({ fileName, contentType, isWebUpload: true })
  //       );
  //       // const buffer = Buffer.from(src.replace(/^data:image\/\w+;base64,/, ""), 'binary');
  //       const buffer = file;
  //       if (createPostMedia.length === 0) {
  //         return (
  //           (signedURL = await getSignedURL(contentType, fileName)),
  //           await setAlbumId(signedURL.album_id),
  //           await setCreatePostMedia(
  //             createPostMedia.concat({
  //               contentType,
  //               buffer,
  //               src,
  //               fileName,
  //               ...signedURL,
  //             })
  //           ),
  //           setDisableNext(false)
  //         );
  //       } else if (createPostMedia.length >= 1 && createPostMedia.length <= 9) {
  //         return (
  //           (signedURL = await getSignedURLforAlbum(
  //             contentType,
  //             fileName,
  //             albumId
  //           )),
  //           await setCreatePostMedia(
  //             createPostMedia.concat({
  //               contentType,
  //               buffer,
  //               src,
  //               fileName,
  //               ...signedURL,
  //             })
  //           )
  //         );
  //       } else if (createPostMedia.length >= 10) {
  //         setIsLoading(false);
  //         setMaxUploadLimitReached(true);
  //       }
  //     }
  //   } else {
  //     alert(
  //       "Please choose the right file type. File types allowed are .jpg/.jpeg, .png"
  //     );
  //   }
  // };

  // const multipleFileUploader = async (e) => {
  //   let mediaArr = [];
  //   let uploadedFilesArr = [];
  //   // let album = "";
  //   let file = e.target.files;
  //   // console.log(file);
  //   if (!file) return ""; //If the user cancels the popup
  //   let fileSizeInMB = Math.floor(file.size / (1024 * 1024));
  //   let signedURL;
  //   let fileArray = [];
  //   fileArray = Object.values(file);
  //   // console.log(fileArray);
  //   if (fileArray.length > 10 || uploadedFiles.length + fileArray.length > 10) {
  //     setMaxUploadLimitReached(true);
  //   } else {
  //     setFilesArraySize(fileArray.length);
  //     setIsLoading(true);
  //     setMaxUploadLimitReached(false);
  //     for (let item of fileArray) {
  //       // if (item.type === "" || item.type === "image/heic") {
  //       //   let convertedBlob = await heicToJpg(item);
  //       //   let src = await getBase64(convertedBlob).then((base64) => {
  //       //     return base64;
  //       //   });
  //       //   await setPhoto(src);
  //       //   // console.log("1");
  //       // }

  //       // if (item.type === "image/png" || item.type === "image/jpeg") {
  //       //   let src = await getBase64(item).then((base64) => {
  //       //     return base64;
  //       //   });
  //       //   await setPhoto(src);
  //       // }

  //       // if(item.type === "video/mp4" || item.type === "video/quicktime"){
  //       //   let src = await getBase64(item).then((base64) => {
  //       //     return base64;
  //       //   });
  //       //   await setVideo(src);
  //       // }
  //       let contentType = item.type;
  //       if (contentType === "" || contentType === "image/heic") {
  //         //Changing it here to get the signedURl based on the watermarked image
  //         setIsLoading(true);
  //         contentType = "image/jpeg";
  //         let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
  //         let convertedBlob = await heicToJpg(item);
  //         let src = await getBase64(convertedBlob).then((base64) => {
  //           return base64;
  //         });

  //         await setPhoto(src);
  //         uploadedFilesArr = [
  //           ...uploadedFilesArr.concat({ fileName, contentType }),
  //         ];
  //         setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
  //         setTimeout(async () => {
  //           let watermarkedSRC = await handleImage();
  //           // console.log(watermarkedSRC);
  //           const buffer = Buffer.from(
  //             watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
  //             "base64"
  //           );
  //           if (createPostMedia.length === 0) {
  //             setIsLoading(true);
  //             signedURL = await getSignedURL(contentType, fileName);
  //             setAlbumId(signedURL.album_id);
  //             mediaArr = [
  //               ...mediaArr.concat({
  //                 buffer,
  //                 fileName,
  //                 contentType,
  //                 watermarkedSRC,
  //                 ...signedURL,
  //               }),
  //             ];
  //             setCreatePostMedia([...createPostMedia, ...mediaArr]);
  //             setDisableNext(false);
  //           } else if (albumId && createPostMedia.length > 0) {
  //             setIsLoading(true);
  //             signedURL = await getSignedURLforAlbum(
  //               contentType,
  //               fileName,
  //               albumId
  //             );
  //             mediaArr = [
  //               ...mediaArr.concat({
  //                 buffer,
  //                 fileName,
  //                 contentType,
  //                 watermarkedSRC,
  //                 ...signedURL,
  //               }),
  //             ];
  //             setCreatePostMedia([...createPostMedia, ...mediaArr]);
  //             setDisableNext(false);
  //             setPhoto("");
  //           }
  //         }, 2000);
  //       } else if (
  //         contentType === "image/png" ||
  //         contentType === "image/jpeg"
  //       ) {
  //         setIsLoading(true);

  //         setTimeout(async () => {
  //           let src = await getBase64(item).then((base64) => {
  //             return base64;
  //           });
  //           await setPhoto(src);

  //           let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
  //           uploadedFilesArr = [
  //             ...uploadedFilesArr.concat({ fileName, contentType }),
  //           ];
  //           setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
  //           // setUploadedFiles(uploadedFiles.concat({ fileName, contentType }));
  //           let watermarkedSRC = await handleImage();
  //           // console.log(watermarkedSRC)
  //           const buffer = Buffer.from(
  //             watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
  //             "base64"
  //           );
  //           if (createPostMedia.length === 0) {
  //             signedURL = await getSignedURL(contentType, fileName);
  //             setAlbumId(signedURL.album_id);
  //             mediaArr = [
  //               ...mediaArr.concat({
  //                 buffer,
  //                 fileName,
  //                 contentType,
  //                 watermarkedSRC,
  //                 ...signedURL,
  //               }),
  //             ];
  //             setCreatePostMedia([...createPostMedia, ...mediaArr]);
  //             setDisableNext(false);
  //           } else if (albumId && createPostMedia.length > 0) {
  //             signedURL = await getSignedURLforAlbum(
  //               contentType,
  //               fileName,
  //               albumId
  //             );
  //             mediaArr = [
  //               ...mediaArr.concat({
  //                 buffer,
  //                 fileName,
  //                 contentType,
  //                 watermarkedSRC,
  //                 ...signedURL,
  //               }),
  //             ];
  //             setCreatePostMedia([...createPostMedia, ...mediaArr]);
  //             setDisableNext(false);
  //             setPhoto("");
  //           }
  //         }, 2000);
  //       } else if (
  //         contentType === "video/mp4" ||
  //         contentType === "video/quicktime"
  //       ) {
  //         setIsLoading(true);
  //         if (fileSizeInMB > 200) {
  //           setMaxFileSizeReached(true);
  //           setIsLoading(false);
  //           setTimeout(() => {
  //             setMaxFileSizeReached(false);
  //           }, 3000);
  //         } else {
  //           let src = await getBase64(item).then((base64) => {
  //             return base64;
  //           });
  //           // await setVideo(src);
  //           let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
  //           // setUploadedFiles(
  //           //   uploadedFiles.concat({ fileName, contentType, isWebUpload: true })
  //           // );

  //           uploadedFilesArr = [
  //             ...uploadedFilesArr.concat({
  //               fileName,
  //               contentType,
  //               isWebUpload: true,
  //             }),
  //           ];
  //           setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
  //           // setTimeout(async () => {
  //           // const buffer = Buffer.from(src.replace(/^data:image\/\w+;base64,/, ""), 'binary');
  //           const buffer = item;
  //           if (createPostMedia.length === 0) {
  //             signedURL = await getSignedURL(contentType, fileName);
  //             setAlbumId(signedURL.album_id);
  //             mediaArr = [
  //               ...mediaArr.concat({
  //                 buffer,
  //                 fileName,
  //                 contentType,
  //                 src,
  //                 ...signedURL,
  //               }),
  //             ];
  //             setCreatePostMedia([...createPostMedia, ...mediaArr]);
  //             setDisableNext(false);
  //           } else if (albumId && createPostMedia.length > 0) {
  //             signedURL = await getSignedURLforAlbum(
  //               contentType,
  //               fileName,
  //               albumId
  //             );
  //             mediaArr = [
  //               ...mediaArr.concat({
  //                 buffer,
  //                 fileName,
  //                 contentType,
  //                 src,
  //                 ...signedURL,
  //               }),
  //             ];
  //             setCreatePostMedia([...createPostMedia, ...mediaArr]);
  //             setDisableNext(false);
  //           }
  //           // }, 1000);
  //           //  if(fileArray.length >=10 || createPostMedia.length >= 10) {
  //           //     setIsLoading(false);
  //           //     setMaxUploadLimitReached(true);
  //           //   }
  //         }
  //       } else {
  //         alert(
  //           "Please choose the right file type. File types allowed are .jpg/.jpeg, .png"
  //         );
  //       }
  //     }
  //   }
  // };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  // const getBase64 = async (file) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     console.log(reader.result)
  //     // resolve(reader.result);
  //     return reader.result
  //     };
  //     reader.onerror = (error) => console.log(error);
  //     reader.readAsDataURL(file);
  // }

  //THE NEW MULTI FILE UPLOAD
  const multipleFileUploader = async (e) => {
    let file = e.target.files;
    if (!file) return ""; //If the user cancels the popup
    let signedURL;
    let mediaArr = [];
    let uploadedFilesArr = [];
    let fileArray = [];
    fileArray = Object.values(file);
    let album = albumId;
    let numberOfVideoFiles = 0;
    for (let i = 0; i < fileArray.length; i++) {
      let contentType = fileArray[i].type;
      if (contentType === "video/mp4" || contentType === "video/quicktime") {
        numberOfVideoFiles++;
        setVideosUploadedCount((prevState) => prevState + 1);
      }
    }
    setFilesArraySize(fileArray.length);
    console.log(fileArray);
    if (navigator.userAgent.match(/iPhone/i)) {
      if (createPostMedia.length + fileArray.length > 10) {
        setMaxUploadLimitReached(true);
        setTimeout(() => {
          setMaxUploadLimitReached(false);
        }, 3000);
      } else {
        if (videosUploadedCount + numberOfVideoFiles < 3) {
          for (let i = 0; i < fileArray.length; i++) {
            let contentType = fileArray[i].type;
            console.log(contentType);
            let fileSizeInMB = Math.floor(fileArray[i].size / (1024 * 1024));
            if (i === 0 && createPostMedia.length === 0) {
              // setTimeout(async () => {
              if (contentType === "image/jpeg" || contentType === "image/png") {
                setIsLoading(true);
                // let src= await getBase64(fileArray[i])
                let src = await getBase64(fileArray[i]).then((base64) => {
                  return base64;
                });
                await setPhoto(src);
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({ fileName, contentType }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                // let watermarkedSRC = src;
                signedURL = await getSignedURL(contentType, fileName);
                album = signedURL.album_id;
                setAlbumId(signedURL.album_id);
                let watermarkedSRC = await handleImage();
                console.log(watermarkedSRC);
                const buffer = Buffer.from(
                  watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                  "base64"
                );
                setPhoto();
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    watermarkedSRC,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              } else if (contentType === "" || contentType === "image/heic") {
                setIsLoading(true);
                contentType = "image/jpeg";
                let convertedBlob = await heicToJpg(fileArray[i]);
                let src = await getBase64(convertedBlob).then((base64) => {
                  return base64;
                });
                await setPhoto(src);
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({ fileName, contentType }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                // let watermarkedSRC = src;
                signedURL = await getSignedURL(contentType, fileName);
                album = signedURL.album_id;
                setAlbumId(signedURL.album_id);
                let watermarkedSRC = await handleImage();
                const buffer = Buffer.from(
                  watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                  "base64"
                );
                setPhoto();
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    watermarkedSRC,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              } else if (
                contentType === "video/mp4" ||
                contentType === "video/quicktime"
              ) {
                setIsLoading(true);
                if (fileSizeInMB > 200) {
                  setMaxFileSizeReached(true);
                  setIsLoading(false);
                  setTimeout(() => {
                    setMaxFileSizeReached(false);
                  }, 3000);
                } else {
                  let src = await getBase64(fileArray[i]).then((base64) => {
                    return base64;
                  });
                  // let src = fileArray[i];
                  let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                  uploadedFilesArr = [
                    ...uploadedFilesArr.concat({
                      fileName,
                      contentType,
                      isWebUpload: true,
                    }),
                  ];
                  setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                  const buffer = fileArray[i];
                  signedURL = await getSignedURL(contentType, fileName);
                  album = signedURL.album_id;
                  setAlbumId(signedURL.album_id);
                  mediaArr = [
                    ...mediaArr.concat({
                      buffer,
                      fileName,
                      contentType,
                      src,
                      ...signedURL,
                    }),
                  ];
                  setCreatePostMedia([...createPostMedia, ...mediaArr]);
                  setDisableNext(false);
                }
              } else {
                setFileTypeError(true);
                setTimeout(() => {
                  setFileTypeError(false);
                }, 3000);
              }
              // }, 1000);
            }
            //If the user selects image for the 2nd time
            else if (i === 0 && createPostMedia.length > 0) {
              // setTimeout(async () => {
              if (contentType === "image/jpeg" || contentType === "image/png") {
                setIsLoading(true);
                let src = await getBase64(fileArray[i]).then((base64) => {
                  return base64;
                });
                await setPhoto(src);
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({ fileName, contentType }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                signedURL = await getSignedURLforAlbum(
                  contentType,
                  fileName,
                  album
                );
                let watermarkedSRC = await handleImage();
                // let watermarkedSRC = src;
                const buffer = Buffer.from(
                  watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                  "base64"
                );
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    watermarkedSRC,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              } else if (contentType === "" || contentType === "image/heic") {
                setIsLoading(true);
                contentType = "image/jpeg";
                let convertedBlob = await heicToJpg(fileArray[i]);
                let src = await getBase64(convertedBlob).then((base64) => {
                  return base64;
                });
                await setPhoto(src);
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({ fileName, contentType }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                signedURL = await getSignedURLforAlbum(
                  contentType,
                  fileName,
                  album
                );
                let watermarkedSRC = await handleImage();
                // let watermarkedSRC = src;
                const buffer = Buffer.from(
                  watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                  "base64"
                );
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    watermarkedSRC,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              } else if (
                contentType === "video/mp4" ||
                contentType === "video/quicktime"
              ) {
                setIsLoading(true);
                if (fileSizeInMB > 200) {
                  setMaxFileSizeReached(true);
                  setIsLoading(false);
                  setTimeout(() => {
                    setMaxFileSizeReached(false);
                  }, 3000);
                } else {
                  let src = await getBase64(fileArray[i]).then((base64) => {
                    return base64;
                  });
                  let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                  uploadedFilesArr = [
                    ...uploadedFilesArr.concat({
                      fileName,
                      contentType,
                      isWebUpload: true,
                    }),
                  ];
                  setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                  const buffer = fileArray[i];
                  signedURL = await getSignedURLforAlbum(
                    contentType,
                    fileName,
                    album
                  );
                  mediaArr = [
                    ...mediaArr.concat({
                      buffer,
                      fileName,
                      contentType,
                      src,
                      ...signedURL,
                    }),
                  ];
                  setCreatePostMedia([...createPostMedia, ...mediaArr]);
                  setDisableNext(false);
                }
              } else {
                setFileTypeError(true);
                setTimeout(() => {
                  setFileTypeError(false);
                }, 3000);
              }
              // }, 1000);
            } else if (i > 0) {
              // setTimeout(async () => {
              if (contentType === "image/jpeg" || contentType === "image/png") {
                setIsLoading(true);
                let src = await getBase64(fileArray[i]).then((base64) => {
                  return base64;
                });
                await setPhoto(src);
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({ fileName, contentType }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                signedURL = await getSignedURLforAlbum(
                  contentType,
                  fileName,
                  album
                );
                let watermarkedSRC = await handleImage();
                // let watermarkedSRC = src;
                const buffer = Buffer.from(
                  watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                  "base64"
                );
                setPhoto();
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    watermarkedSRC,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
              } else if (contentType === "" || contentType === "image/heic") {
                setIsLoading(true);
                contentType = "image/jpeg";
                let convertedBlob = await heicToJpg(fileArray[i]);
                let src = await getBase64(convertedBlob).then((base64) => {
                  return base64;
                });
                await setPhoto(src);
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({ fileName, contentType }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                signedURL = await getSignedURLforAlbum(
                  contentType,
                  fileName,
                  album
                );
                let watermarkedSRC = await handleImage();
                // let watermarkedSRC = src;
                const buffer = Buffer.from(
                  watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                  "base64"
                );
                setPhoto();
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    watermarkedSRC,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              } else if (
                contentType === "video/mp4" ||
                contentType === "video/quicktime"
              ) {
                setIsLoading(true);
                if (fileSizeInMB > 200) {
                  setMaxFileSizeReached(true);
                  setIsLoading(false);
                  setTimeout(() => {
                    setMaxFileSizeReached(false);
                  }, 3000);
                } else {
                  let src = await getBase64(fileArray[i]).then((base64) => {
                    return base64;
                  });
                  let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                  uploadedFilesArr = [
                    ...uploadedFilesArr.concat({
                      fileName,
                      contentType,
                      isWebUpload: true,
                    }),
                  ];
                  setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                  const buffer = fileArray[i];
                  signedURL = await getSignedURLforAlbum(
                    contentType,
                    fileName,
                    album
                  );
                  mediaArr = [
                    ...mediaArr.concat({
                      buffer,
                      fileName,
                      contentType,
                      src,
                      ...signedURL,
                    }),
                  ];
                  setCreatePostMedia([...createPostMedia, ...mediaArr]);
                  setDisableNext(false);
                }
              } else {
                setFileTypeError(true);
                setTimeout(() => {
                  setFileTypeError(false);
                }, 3000);
              }
              // }, 1000);
            }
          }
        } else {
          setMaxVideosUpload(true);
          setTimeout(() => {
            setMaxVideosUpload(false);
          }, 3000);
        }
      }
    } else {
      if (createPostMedia.length + fileArray.length > 10) {
        setMaxUploadLimitReached(true);
        setTimeout(() => {
          setMaxUploadLimitReached(false);
        }, 3000);
      } else {
        for (let i = 0; i < fileArray.length; i++) {
          let contentType = fileArray[i].type;
          let fileSizeInMB = Math.floor(fileArray[i].size / (1024 * 1024));
          if (i === 0 && createPostMedia.length === 0) {
            // setTimeout(async () => {
            if (contentType === "image/jpeg" || contentType === "image/png") {
              setIsLoading(true);
              // let src= await getBase64(fileArray[i])
              let src = await getBase64(fileArray[i]).then((base64) => {
                return base64;
              });
              await setPhoto(src);
              let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
              uploadedFilesArr = [
                ...uploadedFilesArr.concat({ fileName, contentType }),
              ];
              setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
              // let watermarkedSRC = src;
              signedURL = await getSignedURL(contentType, fileName);
              album = signedURL.album_id;
              setAlbumId(signedURL.album_id);
              let watermarkedSRC = await handleImage();
              console.log(watermarkedSRC);
              const buffer = Buffer.from(
                watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              setPhoto();
              mediaArr = [
                ...mediaArr.concat({
                  buffer,
                  fileName,
                  contentType,
                  watermarkedSRC,
                  ...signedURL,
                }),
              ];
              setCreatePostMedia([...createPostMedia, ...mediaArr]);
              setDisableNext(false);
            } else if (contentType === "" || contentType === "image/heic") {
              setIsLoading(true);
              contentType = "image/jpeg";
              let convertedBlob = await heicToJpg(fileArray[i]);
              let src = await getBase64(convertedBlob).then((base64) => {
                return base64;
              });
              await setPhoto(src);
              let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
              uploadedFilesArr = [
                ...uploadedFilesArr.concat({ fileName, contentType }),
              ];
              setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
              // let watermarkedSRC = src;
              signedURL = await getSignedURL(contentType, fileName);
              album = signedURL.album_id;
              setAlbumId(signedURL.album_id);
              let watermarkedSRC = await handleImage();
              const buffer = Buffer.from(
                watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              setPhoto();
              mediaArr = [
                ...mediaArr.concat({
                  buffer,
                  fileName,
                  contentType,
                  watermarkedSRC,
                  ...signedURL,
                }),
              ];
              setCreatePostMedia([...createPostMedia, ...mediaArr]);
              setDisableNext(false);
            } else if (
              contentType === "video/mp4" ||
              contentType === "video/quicktime"
            ) {
              setIsLoading(true);
              if (fileSizeInMB > 200) {
                setMaxFileSizeReached(true);
                setIsLoading(false);
                setTimeout(() => {
                  setMaxFileSizeReached(false);
                }, 3000);
              } else {
                let src = await getBase64(fileArray[i]).then((base64) => {
                  return base64;
                });
                // let src = fileArray[i];
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({
                    fileName,
                    contentType,
                    isWebUpload: true,
                  }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                const buffer = fileArray[i];
                signedURL = await getSignedURL(contentType, fileName);
                album = signedURL.album_id;
                setAlbumId(signedURL.album_id);
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    src,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              }
            } else {
              setFileTypeError(true);
              setTimeout(() => {
                setFileTypeError(false);
              }, 3000);
            }
            // }, 1000);
          }
          //If the user selects image for the 2nd time
          else if (i === 0 && createPostMedia.length > 0) {
            // setTimeout(async () => {
            if (contentType === "image/jpeg" || contentType === "image/png") {
              setIsLoading(true);
              let src = await getBase64(fileArray[i]).then((base64) => {
                return base64;
              });
              await setPhoto(src);
              let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
              uploadedFilesArr = [
                ...uploadedFilesArr.concat({ fileName, contentType }),
              ];
              setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
              signedURL = await getSignedURLforAlbum(
                contentType,
                fileName,
                album
              );
              let watermarkedSRC = await handleImage();
              // let watermarkedSRC = src;
              const buffer = Buffer.from(
                watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              mediaArr = [
                ...mediaArr.concat({
                  buffer,
                  fileName,
                  contentType,
                  watermarkedSRC,
                  ...signedURL,
                }),
              ];
              setCreatePostMedia([...createPostMedia, ...mediaArr]);
              setDisableNext(false);
            } else if (contentType === "" || contentType === "image/heic") {
              setIsLoading(true);
              contentType = "image/jpeg";
              let convertedBlob = await heicToJpg(fileArray[i]);
              let src = await getBase64(convertedBlob).then((base64) => {
                return base64;
              });
              await setPhoto(src);
              let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
              uploadedFilesArr = [
                ...uploadedFilesArr.concat({ fileName, contentType }),
              ];
              setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
              signedURL = await getSignedURLforAlbum(
                contentType,
                fileName,
                album
              );
              let watermarkedSRC = await handleImage();
              // let watermarkedSRC = src;
              const buffer = Buffer.from(
                watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              mediaArr = [
                ...mediaArr.concat({
                  buffer,
                  fileName,
                  contentType,
                  watermarkedSRC,
                  ...signedURL,
                }),
              ];
              setCreatePostMedia([...createPostMedia, ...mediaArr]);
              setDisableNext(false);
            } else if (
              contentType === "video/mp4" ||
              contentType === "video/quicktime"
            ) {
              setIsLoading(true);
              if (fileSizeInMB > 200) {
                setMaxFileSizeReached(true);
                setIsLoading(false);
                setTimeout(() => {
                  setMaxFileSizeReached(false);
                }, 3000);
              } else {
                let src = await getBase64(fileArray[i]).then((base64) => {
                  return base64;
                });
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({
                    fileName,
                    contentType,
                    isWebUpload: true,
                  }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                const buffer = fileArray[i];
                signedURL = await getSignedURLforAlbum(
                  contentType,
                  fileName,
                  album
                );
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    src,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              }
            } else {
              setFileTypeError(true);
              setTimeout(() => {
                setFileTypeError(false);
              }, 3000);
            }
            // }, 1000);
          } else if (i > 0) {
            // setTimeout(async () => {
            if (contentType === "image/jpeg" || contentType === "image/png") {
              setIsLoading(true);
              let src = await getBase64(fileArray[i]).then((base64) => {
                return base64;
              });
              await setPhoto(src);
              let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
              uploadedFilesArr = [
                ...uploadedFilesArr.concat({ fileName, contentType }),
              ];
              setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
              signedURL = await getSignedURLforAlbum(
                contentType,
                fileName,
                album
              );
              let watermarkedSRC = await handleImage();
              // let watermarkedSRC = src;
              const buffer = Buffer.from(
                watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              setPhoto();
              mediaArr = [
                ...mediaArr.concat({
                  buffer,
                  fileName,
                  contentType,
                  watermarkedSRC,
                  ...signedURL,
                }),
              ];
              setCreatePostMedia([...createPostMedia, ...mediaArr]);
            } else if (contentType === "" || contentType === "image/heic") {
              setIsLoading(true);
              contentType = "image/jpeg";
              let convertedBlob = await heicToJpg(fileArray[i]);
              let src = await getBase64(convertedBlob).then((base64) => {
                return base64;
              });
              await setPhoto(src);
              let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
              uploadedFilesArr = [
                ...uploadedFilesArr.concat({ fileName, contentType }),
              ];
              setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
              signedURL = await getSignedURLforAlbum(
                contentType,
                fileName,
                album
              );
              let watermarkedSRC = await handleImage();
              // let watermarkedSRC = src;
              const buffer = Buffer.from(
                watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              setPhoto();
              mediaArr = [
                ...mediaArr.concat({
                  buffer,
                  fileName,
                  contentType,
                  watermarkedSRC,
                  ...signedURL,
                }),
              ];
              setCreatePostMedia([...createPostMedia, ...mediaArr]);
              setDisableNext(false);
            } else if (
              contentType === "video/mp4" ||
              contentType === "video/quicktime"
            ) {
              setIsLoading(true);
              if (fileSizeInMB > 200) {
                setMaxFileSizeReached(true);
                setIsLoading(false);
                setTimeout(() => {
                  setMaxFileSizeReached(false);
                }, 3000);
              } else {
                let src = await getBase64(fileArray[i]).then((base64) => {
                  return base64;
                });
                let fileName = uuidv4() + `.${contentType.split("/")[1]}`;
                uploadedFilesArr = [
                  ...uploadedFilesArr.concat({
                    fileName,
                    contentType,
                    isWebUpload: true,
                  }),
                ];
                setUploadedFiles([...uploadedFiles, ...uploadedFilesArr]);
                const buffer = fileArray[i];
                signedURL = await getSignedURLforAlbum(
                  contentType,
                  fileName,
                  album
                );
                mediaArr = [
                  ...mediaArr.concat({
                    buffer,
                    fileName,
                    contentType,
                    src,
                    ...signedURL,
                  }),
                ];
                setCreatePostMedia([...createPostMedia, ...mediaArr]);
                setDisableNext(false);
              }
            } else {
              setFileTypeError(true);
              setTimeout(() => {
                setFileTypeError(false);
              }, 3000);
            }
            // }, 1000);
          }
        }
      }
    }
  };

  const heicToJpg = (file) => {
    // fetching the heic image
    return heic2any({
      blob: file,
      toType: "image/jpeg",
    })
      .then((conversionResult) => {
        // conversionResult is an array of BLOBs that are PNG formatted images
        return conversionResult;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //API CALLS
  const getSignedURL = (contentType, fileName) => {
    return axiosCreateContent({
      url: `${BASEURL}/v1/signedUrlForContents`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        fileName: fileName,
        contentType: contentType,
      },
      withCredentials: true,
    })
      .then((res) => res.data)
      .catch((err) => {
        return console.log(err), setUrlError(true);
      });
  };

  const getSignedURLforAlbum = (contentType, fileName, albumId) => {
    return axiosCreateContent({
      url: `${BASEURL}/v1/signedUrlForContents`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        fileName: fileName,
        contentType: contentType,
        album_id: albumId,
      },
      withCredentials: true,
    })
      .then((res) => res.data)
      .catch((err) => {
        return console.log(err), setUrlError(true);
      });
  };

  const uploadFileToS3 = () => {
    // console.log(createPostMedia.length);
    createPostMedia.map((file) => {
      axiosInstance({
        url: `${file.signedUrl}`,
        method: "PUT",
        headers: {
          "Content-Type": `${file.contentType}`,
          ContentEncoding: "base64",
        },
        onUploadProgress: (progressEvent) => {
          let percentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
            "%"
          );

          setUploadingPercentage(percentage);

          if (percentage !== 100) {
            setUploading(true);
          } else if (percentage === 100) {
            setUploading(false);
          }
        },
        data: file.buffer,
        withCredentials: true,
      })
        .then((res) => setUploadToS3Message(res))
        .catch((err) => {
          return console.log(err), setUploadError(true);
        });
    });
  };

  const uploadWriteTextToS3 = (signedUrl, contentType, buffer) => {
    axiosInstance({
      url: `${signedUrl}`,
      method: "PUT",
      headers: {
        "Content-Type": `${contentType}`,
        ContentEncoding: "base64",
      },
      data: buffer,
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        let percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
          "%"
        );
        setUploadingPercentage(percentage);
        if (percentage !== 100) {
          setUploading(true);
        } else if (percentage === 100) {
          setUploading(false);
        }
      },
    })
      .then((res) => setUploadToS3Message(res))
      .catch((err) => {
        return console.log(err), setUploadError(true);
      });
  };

  const deleteFileFromS3 = (albumId, fileName) => {
    return axiosInstance({
      url: `${BASEURL}/v1/deleteFileBeforeUpload`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      params: {
        albumId,
        fileName,
      },
      withCredentials: true,
    })
      .then((res) => console.log(res.status))
      .catch((err) => {
        return console.log(err);
      });
  };

  const deleteFiles = async (albumId, fileName) => {
    if (causeID && countryID) {
      await deleteFileFromS3(albumId, fileName);
    }
    setCreatePostMedia(
      createPostMedia.filter((item) => item.fileName !== fileName)
    );
    setUploadedFiles(
      uploadedFiles.filter((item) => item.fileName !== fileName)
    );
  };

  //Functions
  const handleNext = async () => {
    selectCharityRef.current.style.display = "block";
    enterDetailsRef.current.style.display = "none";

    //NO IDEA :P
    if (userDetails.cause_name !== null) {
      setCountryName(userDetails.country_name);
      setCountryID(userDetails.country_external_id);
      setCauseName(userDetails.cause_name);
      setCauseID(userDetails.cause_external_id);
      setCharityLogo(userDetails.cause_image);
    }

    //This is for Write Text Upload
    if (writeTextActive && createPostMedia.length === 0 && caption.length > 0) {
      textAreaRef.current.style.display = "none";
      let watermarkedSRC = await writeTextOnCanvas();
      const buffer = Buffer.from(
        watermarkedSRC.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      let contentType = "image/jpeg";
      let fileName = "image";
      let signedURL = await getSignedURL(contentType, fileName);
      await setAlbumId(signedURL.album_id);
      //   setCaption("");
      setUploadedFiles([{ fileName, contentType }]);
      uploadWriteTextToS3(signedURL.signedUrl, contentType, buffer);
    }

    if (createPostMedia.length) {
      uploadFileToS3();
      // console.log(uploadToS3Message);
    }
  };

  const handleNext2 = () => {
    selectCharityRef.current.style.display = "none";
    enterDetailsRef.current.style.display = "block";
  };

  const handleBack = () => {
    selectCharityRef.current.style.display = "none";
    enterDetailsRef.current.style.display = "block";

    // if (writeTextActive) {
    // writeTextRef.current.style.display = "none";
    // formRef.current.style.display = "block";
    // textAreaRef.current.style.display = "block";
    // setWriteTextActive(false);
    // setDisableNext(true);
    // }
  };

  const handlePost = async () => {
    setDisablePost(true);
    const dataToPost = {
      user_external_id: userDetails.user_external_id,
      cause_id: causeID,
      country_id: countryID,
      description: caption,
      album_id: albumId,
      files: uploadedFiles,
    };

    let CreatePostResponse = await createPost(dataToPost);

    if (CreatePostResponse.statusCode === 201) {
      setContentExternalID(CreatePostResponse.data.content_external_id);
      setPostCreatedSuccessfully(true);
      dispatch(getUserDetailsByUserId());
      // dispatch(newPostCreated(true));
      handleClose();
    } else {
      setError(true);
    }
  };

  const closeWriteText = () => {
    writeTextRef.current.style.display = "none";
    formRef.current.style.display = "block";
    textAreaRef.current.style.display = "block";
    setWriteTextActive(false);
    setAddMediaActive(false);
    setCauseID("");
    setCountryID("");
    setCountryName("");
    setCauseName("");
    setCharityLogo();
    setDisableNext(true);
    setUploadedFiles([]);
    // setCaption("");
  };

  const openWriteText = () => {
    writeTextRef.current.style.display = "block";
    formRef.current.style.display = "none";
    setWriteTextActive(true);
    handleText();
  };

  const handleImage = () => {
    const canvas = canvasRef.current;
    const watermark = logoRef.current;
    // const blackWatermark = blackLogoRef.current;
    let userImage = userImageRef.current;

    // console.log(canvas);

    let [maxHeight, maxWidth, ratio] = [640, 680, 0];
    let [width, height] = [userImage.width, userImage.height];

    if (width > maxWidth) {
      ratio = maxWidth / width;
      userImage.style.width = maxWidth;
      userImage.style.height *= ratio;
      height *= ratio;
      width *= ratio;
    }

    if (height > maxHeight) {
      ratio = maxHeight / height;
      userImage.style.height = maxHeight;
      userImage.style.width *= ratio;
      height *= ratio;
      width *= ratio;
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(userImage, 0, 0, canvas.width, canvas.height);
    ctx.drawImage(watermark, 10, 10, 60, 44);

    //Calculating the brightness of the image to show the correct colored watermark
    // let imgData = ctx.getImageData(10, 20, 25, 25)
    // let rgb = imgData.data.slice(0, 3)
    // let brightness = Math.sqrt((.241 * rgb[0] * rgb[0]) + (.691 * rgb[1] * rgb[1]) + (.068 * rgb[2] * rgb[2]))

    // if (brightness === 0) {
    //   ctx.drawImage(blackWatermark, 10, 10, 60, 44)
    // } else if (brightness < 130) {
    //   ctx.drawImage(watermark, 10, 10, 60, 44)
    // } else if (brightness > 130) {
    //   ctx.drawImage(blackWatermark, 10, 10, 60, 44)
    // }
    let dataURL = canvas.toDataURL("image/jpeg");
    // console.log(dataURL);
    return dataURL;
    // setWatermarkImage(dataURL)
  };

  const handleText = () => {
    // const canvas = canvasRef.current;
    const canvas = myCanvasRef.current;
    const watermark = writeTextLogoRef.current;
    let [w, h] = [0, 0];
    if (navigator.userAgent.match(/Android|iPhone/i)) {
      [w, h] = [310, 400];
    } else {
      [w, h] = [485, 460];
    }
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#2077B1";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#fff";
    ctx.font = "18px DM Sans";
    ctx.textAlign = "center";
    ctx.drawImage(watermark, 10, 10, 60, 44);
  };

  const writeTextOnCanvas = () => {
    // const canvas = canvasRef.current;
    const canvas = myCanvasRef.current;
    const watermark = writeTextLogoRef.current;
    const [w, h] = [485, 460];
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#2077B1";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#fff";
    ctx.font = "18px DM Sans";
    ctx.textAlign = "center";

    let maxWidth = 400;
    let lineHeight = 25;
    const minAddedHeight = 70; //minimum margin which needs to be added from the top when the max chars are entered (13 lines)
    const height_interval = 15;
    // adding margin to the minAddedHeight based on the number of lines.
    // ex- for 12 lines the margin-top would be minAddedHeight + height_interval. This will make the text look aligned centrally
    const interval_points = 13; //Max number of lines in the canvas
    let captionLength = caption.length; // The text the user has typed
    let captionLine = Math.ceil(captionLength / 46); // 46 - max characters in one line (approximately)
    //captionLine - Gives the number of lines the text will take up on the canvas
    // captionLine = Math.ceil(100/46) = Math.ceil(2.17) = 3 lines
    let intervalsToAdd = interval_points - captionLine; // 13 - 3 = 10
    let total_intervals = height_interval * intervalsToAdd; // 15 * 10 = 150
    let calc_y = minAddedHeight + total_intervals; // 70 + 150 = 220
    let x = canvas.width / 2; //485 / 2 = 242.5
    let y = calc_y; //220

    wrapText(ctx, caption, x, y, maxWidth, lineHeight);

    ctx.drawImage(watermark, 10, 10, 60, 44);
    let dataURL = canvas.toDataURL("image/jpeg");
    // console.log(dataURL);
    return dataURL;
    // setConvertedImage(dataURL);
  };

  function wrapText(context, text, x, y, maxWidth, lineHeight) {
    let words = text.split(" ");
    let line = "";

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + " ";
      let metrics = context.measureText(testLine);
      let testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }

  //Calculating the remaining characters
  const handleChange = (e) => {
    setCaption(e.target.value);
    let input = e.target.value;
    setRemainingCharacters(600 - input.length);
  };

  // //To scroll left
  // const handleScrollLeft = () => {
  //   scrollLeft(rightArrowRef.current, -200, 1000);
  //   //1st parameter refer div wrapper, 2nd parameter distance cover, 3rd parameter duration
  // };

  // //to scroll right
  // const handleScrollRight = () => {
  //   scrollLeft(rightArrowRef.current, 200, 1000);
  // };

  // function scrollLeft(element, change, duration) {
  //   var start = element.scrollLeft,
  //     currentTime = 0,
  //     increment = 20;

  //   var animateScroll = function () {
  //     currentTime += increment;
  //     var val = Math.easeInOutQuad(currentTime, start, change, duration);
  //     element.scrollLeft = val;
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };
  //   animateScroll();
  // }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  // Math.easeInOutQuad = function (t, b, c, d) {
  //   t /= d / 2;
  //   if (t < 1) return (c / 2) * t * t + b;
  //   t--;
  //   return (-c / 2) * (t * (t - 2) - 1) + b;
  // };

  const autoHeight = () => {
    let x = document.getElementById("textarea");
    x.style.height = "5px";
    x.style.height = 15 + x.scrollHeight + "px";
  };

  // if (!charityData) return <Loader />
  if (!charityData) return <Loader />;
  if (!allowedCountries) return <Loader />;
  // if (!countryData) return null
  // if (!userDetails) return null
  // if (!hashTagsData) return <Loader />;
  // const adjustTextArea = (element) => {
  //   element.style.height = "1px";
  //   element.style.height = (25 + element.scrollHeight) + "px";
  // }

  // console.log(caption);

  const handleCharityClose = () => {
    setCountryID("");
    setCauseID("");
    setCountryName("");
    setCauseName("");
    setCharityLogo();
    setDisableNext(false);
    // setAddMediaActive(false);
    // setWriteTextActive(false);
  };

  var hashtagSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2
      //   }
      // }
    ],
  };

  const handleLoader = (index) => {
    if (index + 1 === filesArraySize) {
      setIsLoading(false);
    }
    if (
      filesArraySize < createPostMedia.length &&
      index + 1 === createPostMedia.length
    ) {
      setIsLoading(false);
    }
  };

  const handleHashtag = (hashtag, e) => {
    if (remainingCharacters <= 0) return;
    else {
      setCaption(caption + hashtag.hashtag_name + " ");
      setRemainingCharacters(remainingCharacters - hashtag.hashtag_name.length);
    }
  };

  //First Modal Page
  const enterDetails = () => {
    return (
      <div style={{ display: "block" }} ref={enterDetailsRef}>
        {/* {googleUserData ? <Image src={googleUserData[4]} className="user-image" /> : <Image src={FbUserData.picture} className="user-image" />} */}
        <img
          onError={(ev) => (ev.target.src = `${avatar}`)}
          className="user-image"
          src={`https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`}
          roundedCircle
        />
        {userDetails ? (
          <p className="user-name">{userDetails.username}</p>
        ) : null}

        <div ref={formRef}>
          <FormControl
            as="textarea"
            className="create-post-caption"
            placeholder={t("createPost.caption")}
            onChange={(e) => handleChange(e)}
            value={caption}
            maxLength={600}
          />

          {remainingCharacters <= 0 ? (
            <p className="character-count red">
              {t("createPost.reachedCharacterLimit")}
            </p>
          ) : (
            <p className="character-count">
              {t("createPost.remainingCharacters")}: {remainingCharacters}
            </p>
          )}

          <div className="suggestions-div">
            <span> {t("createPost.suggestions")} </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => ArrowRef.current.slickPrev()}
            >
              <img src={navigationleftArrow} alt="leftArrow" />
            </span>
            <div className="scroll-menu">
              <Slider {...hashtagSettings} ref={ArrowRef} arrows={false}>
                {Object.values(hashTagsData).map((hashtag, index) => {
                  return (
                    <div>
                      <p
                        key={index}
                        onClick={(e) => handleHashtag(hashtag, e)}
                        className="suggestion"
                      >
                        {hashtag.hashtag_name}
                      </p>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => ArrowRef.current.slickNext()}
            >
              <img src={navigationrightArrow} alt="rightArrow" />
            </span>
            {/* <div ref={rightArrowRef} className="scroll-menu">
              {Object.values(hashTagsData).map((hashtag, index) => {
                return (
                  <p
                    key={index}
                    onClick={() =>
                      setCaption(caption + hashtag.hashtag_name + " ")
                    }
                    className="suggestion"
                  >
                    {hashtag.hashtag_name}
                  </p>
                );
              })}
            </div> */}
          </div>
        </div>
        {console.log(createPostMedia)}

        <div className="display-uploaded-media">
          {createPostMedia.map((media, index) => {
            if (
              media.contentType === "image/png" ||
              media.contentType === "image/jpeg"
            ) {
              return (
                <React.Fragment key={index}>
                  <img
                    src={media.watermarkedSRC}
                    alt="uploaded-image"
                    width="130"
                    height="130"
                    onLoad={() => handleLoader(index)}
                    style={{ marginTop: "1rem", objectFit: "contain" }}
                  />
                  <span
                    onClick={() => deleteFiles(albumId, media.fileName)}
                    className="remove-button"
                  >
                    {closeButtonForWriteText}
                  </span>
                </React.Fragment>
              );
            } else if (
              media.contentType === "video/mp4" ||
              media.contentType === "video/quicktime"
            ) {
              return (
                <React.Fragment key={index}>
                  <video
                    controls
                    width="130"
                    height="130"
                    // onCanPlayThrough={() => handleLoader(index)}
                    onLoadedData={() => handleLoader(index)}
                    style={{ marginTop: "1rem" }}
                  >
                    <source src={media.src} type="video/mp4" />
                    <source src={media.src} type="video/quicktime" />
                  </video>
                  <span
                    onClick={() => deleteFiles(albumId, media.fileName)}
                    className="remove-button"
                  >
                    {closeButtonForWriteText}
                  </span>
                </React.Fragment>
              );
            }
          })}
          {isLoading ? <Spinner animation="border" variant="dark" /> : null}
        </div>

        {/* Canvas for writing a text */}
        <div
          ref={writeTextRef}
          style={{ display: "none" }}
          className="canvas-area"
        >
          <canvas
            id="mycanvas"
            ref={myCanvasRef}
            style={{ display: "block" }}
          />
          <span className="write-text-remove-button" onClick={closeWriteText}>
            {closeButtonForWriteText}
          </span>

          <textarea
            placeholder="Type something here..."
            className="canvas-text-input"
            onKeyUp={() => autoHeight()}
            id="textarea"
            onChange={(e) => handleChange(e)}
            value={caption}
            maxLength={600}
            ref={textAreaRef}
            style={{ display: "block" }}
          ></textarea>

          {/* <div className="canvas-text-input">
            <p>Type something</p>
            <p className="text-pos">hello</p>
          </div> */}
        </div>

        <div>
          {countryID && causeID ? (
            <div className="selected-charity">
              <div>
                <img
                  src={charityLogo}
                  // style={{ height: "40px", width: "40px" }}
                  onError={(ev) => (ev.target.src = `${avatar}`)}
                />
                <ul>
                  <li>{causeName}</li>
                  <li>{countryName}</li>
                </ul>
              </div>
              <span onClick={handleCharityClose}>{closeButton}</span>
            </div>
          ) : (
            ""
          )}
        </div>

        {fileTypeError ? (
          <ToastMessage
            image={errorButton}
            message={t("createPost.rightFileType")}
          />
        ) : null}

        {maxVideosUpload ? (
          <ToastMessage
            image={errorButton}
            message="You can only add upto 2 videos per post"
          />
        ) : null}

        {maxUploadLimitReached ? (
          <ToastMessage
            image={errorButton}
            message={t("createPost.uploadMaxPost")}
          />
        ) : null}
        {error ? (
          <ToastMessage
            image={errorButton}
            message={t("createPost.wentWrong")}
          />
        ) : null}
        {urlError ? (
          <ToastMessage
            image={errorButton}
            message={t("createPost.errorMessage")}
          />
        ) : // "There was an error while reading the file. Please Try again later"

        null}
        {uploadError ? (
          <ToastMessage
            image={errorButton}
            message={t("createPost.uploadFails")}
            // "The file upload failed. Please Try again later"
          />
        ) : null}
        {maxFileSizeReached ? (
          <ToastMessage
            image={errorButton}
            message={t("createPost.resizeMessage")}

            // message="Please resize your file to be less than 200MB and try again"
          />
        ) : null}

        {/* {!uploadToS3Message.status ? (
          <ToastMessage
            // image={}
            message="Please wait while we upload your photo to the cloud"
          />
        ) : null} */}

        <input
          type="file"
          accept="image/png,image/jpeg,image/heic,video/mp4,video/quicktime"
          // accept="video/*,image/*"
          ref={fileInput}
          // onChange={(e) => uploadedFileHandler(e)}
          onChange={(e) => multipleFileUploader(e)}
          style={{ display: "none" }}
          multiple
        />
        <img
          src={photo}
          ref={userImageRef}
          alt="uploaded-image"
          style={{ display: "none" }}
        />
        {/* <video
          src={video}
          ref={userVideoRef}
          style={{ display: "none", width: "130px", height: "130px" }}
        ></video> */}

        {/* Adding a watermark to the images */}
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <img
          ref={logoRef}
          src={blueLogo}
          alt="watermark"
          style={{ display: "none" }}
        />
        <img
          ref={writeTextLogoRef}
          src={logo}
          alt="white-logo"
          style={{ display: "none" }}
        />
        {/* <img ref={blackLogoRef} src={blackLogo} alt="black-watermark" style={{ display: "none" }} /> */}

        {!causeID && !countryID ? (
          <React.Fragment>
            <Button
              className="add-media"
              onClick={() => fileInput.current.click()}
              disabled={writeTextActive}
              // style={{ width: "100%" }}
            >
              {addPhotoSVG} {t("createPost.addPhotoVideo")}
            </Button>
            <Button
              className="add-text"
              onClick={openWriteText}
              disabled={addMediaActive}
            >
              <img src={addTextSVG} /> {t("createPost.writeText")}
            </Button>
          </React.Fragment>
        ) : (
          ""
        )}
        {countryID && causeID ? postButton : nextButton}
      </div>
    );
  };

  const handleCountries = (e, value) => {
    e.stopPropagation();
    console.log(value);
    console.log(e);
    if (value !== null) {
      setCountryID(value.country_external_id);
      setCountryName(value.country_name);
    } else {
      setCountryID(null);
      setCountryName(null);
    }
  };

  const handleCharity = (e, value) => {
    if (value !== null) {
      setCauseID(value.cause_external_id);
      setCauseName(value.cause_name);
      charityData.map((cause) => {
        if (cause.cause_external_id === value.cause_external_id) {
          setCharityLogo(cause.cause_image);
        }
      });
    } else {
      setCauseID(null);
      setCauseName(null);
    }

    if (writeTextActive) {
      setCaption("");
      setRemainingCharacters(600);
    }
  };

  //Second Modal Page
  const selectCharity = () => {
    return (
      <div
        style={{ display: "none", paddingTop: "1rem", minHeight: "425px" }}
        ref={selectCharityRef}
        className="create-content-dropdowns"
      >
        <p onClick={handleBack} className="back-button">
          {backArrow}
        </p>
        <div>
          <Autocomplete
            id="countryFilter"
            options={Object.values(allowedCountries)}
            onChange={(e, value) => handleCountries(e, value)}
            getOptionLabel={(option) => option.country_name}
            className={classes.dropdown}
            renderInput={(params) => {
              return (
                <>
                  {params.inputProps.value === causeName}
                  {countryName === null ? (
                    <TextField
                      {...params}
                      variant="outlined"
                      // placeholder="Country"
                      placeholder={t("createPost.country")}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      {...params}
                      variant="outlined"
                      // placeholder={`${countryName}`}
                      placeholder={t("createPost.country")}
                      fullWidth
                    />
                  )}
                </>
              );
            }}
          />
        </div>

        <div style={{ padding: "1rem 0" }}>
          <Autocomplete
            options={charityData.filter(
              (cause) => cause.country_name === countryName
            )}
            onChange={(e, value) => handleCharity(e, value)}
            getOptionLabel={(option) => option.cause_name}
            className={classes.dropdown}
            renderInput={(params) => {
              return (
                <div>
                  {params.inputProps.value === causeName}
                  {causeName === null ? (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={t("createPost.selectCharity")}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      {...params}
                      variant="outlined"
                      // placeholder={`${causeName}`}
                      placeholder={t("createPost.selectCharity")}
                      fullWidth
                    />
                  )}
                </div>
              );
            }}
          />
        </div>
        <p>
          {t("createPost.selectionQues")}

          {/* Not sure which charity to choose? */}
          <a href="/charity" target="_blank">
            {" "}
            {t("createPost.charityList")}
            {/* Take a look at the charity page */}
          </a>
        </p>

        <Button
          className="next-button-2"
          onClick={handleNext2}
          disabled={!causeID || !countryID}
        >
          {t("createPost.next")}
        </Button>
      </div>
    );
  };

  // if (countryID && causeID) {
  //   selectCharityRef.current.style.display = "none";
  //   enterDetailsRef.current.style.display = "block";
  // }
  let nextButton = (
    <Button
      className="next-button"
      onClick={handleNext}
      disabled={disableNext || isLoading}
    >
      {t("createPost.next")}
    </Button>
  );

  // let postButton = (
  //   <Button
  //     className="next-button"
  //     onClick={handlePost}
  //     disabled={error || !uploadToS3Message.status}
  //   >
  //     {uploadToS3Message.status !== 200 ? "Uploading your file" : "Post"}
  //   </Button>
  // );

  let postButton = (
    <Button
      className="next-button"
      onClick={handlePost}
      disabled={error || urlError || uploadError || uploading || disablePost}
    >
      {/* {uploading ? `Uploading ${uploadingFileNumber}/${createPostMedia.length} file. ${uploadingPercentage} %` : "Post"} */}
      {uploading ? (
        <span>
          Uploading <img src={LoaderGif} alt="loader" />
        </span>
      ) : disablePost ? (
        "Posting..."
      ) : (
        "Post"
      )}
    </Button>
  );

  let styles = showCreateContent
    ? { display: "block", overflow: "auto" }
    : { display: "none" };

  return (
    <>
      {/* <Nav.Item
        className={`create-hover-button ${buttonActiveStyle}`}
        onClick={handleShow}
      >
        {createContentButton}
        <span className="tooltiptext">Create Post</span>
      </Nav.Item> */}
      {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered className="create-content-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {enterDetails()}
          {selectCharity()}
        </Modal.Body>
      </Modal> */}
      {showCreateContent && <BodyStyle />}
      <div id="createContentModal">
        <div className="modal in modal-backdrop" role="dialog" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Create Post</h4>
                <button type="button" onClick={handleClose} className="close">
                  {closeButton}
                </button>
              </div>
              <div className="modal-body">
                {enterDetails()}
                {selectCharity()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {postCreatedSuccessfully ? (
        <PostCreatedModal
          showModal={postCreatedSuccessfully}
          hideModal={handlePostCreatedStatus}
          contentExternalID={contentExternalID}
        />
      ) : null}

      {/* <Toast onClose={() => setPostCreatedSuccessfully(false)} show={postCreatedSuccessfully} delay={3000} autohide animation className="copyurl-toast" >
        <div> {greenTickMark} <span className="toast-message">Post Created Succesfully.!</span> </div>
      </Toast> */}
    </>
  );
};

export default CreateContentOld;
