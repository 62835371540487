import React, { useState, useEffect } from "react";
import "../../assets/scss/Login/helpAndSupport.scss";
import {
  Navbar,
  Nav,
  Card,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import ContentNavbar from "../ContentNavbar/ContentNavbar";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASEURL } from "../../config";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import { useTranslation } from "react-i18next";

const HelpAndSupport = () => {
  const countriesList = useSelector((state) => state.countriesList);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryID, setCountryID] = useState("");
  const [query, setQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showToastMsg, setShowToastMsg] = useState(false);

  // Language translation
  const [t, i18n] = useTranslation("translate");
  const language = localStorage.getItem("lang");

  // Handlers
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCountry = (e) => {
    setCountryID(e.target.value);
  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  // Change Language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios({
      url: `${BASEURL}/v1/support`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        Name: name,
        Email_Address: email,
        Country: countryID,
        Message: query,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setShowToastMsg(true);
          setIsLoading(false);
          setTimeout(() => {
            setShowToastMsg(false);
          }, 5000);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="helpAndSupport" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="header" id="header">
          <ContentNavbar />
        </div>
        <Container>
          <Row>
            <Col>
              <p className="contact">
                {t("helpAndSupport.infoTitle")}{" "}
                <a href="mailto:poolersupport@gopoolit.com">
                  poolersupport@gopoolit.com
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="contact">or</p>
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={11} className="contact-form mx-auto">
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "1rem 2rem",
                  borderRadius: "15px",
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <div>
                    <h4>{t("helpAndSupport.formData.formTitle")}</h4>

                    <div style={{ textAlign: "left" }}>
                      <Form.Group className="section">
                        <Row>
                          <Col md={6}>
                            <Form.Label>
                              {t("helpAndSupport.formData.fullName")}
                              <sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              placeholder={t(
                                "helpAndSupport.formData.fullName"
                              )}
                              value={name}
                              onChange={(e) => handleName(e)}
                              required
                            />
                          </Col>

                          <Col md={6} className="email-input">
                            <Form.Label>
                              {t("helpAndSupport.formData.email")}
                              <sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              required
                              type="email"
                              placeholder={t("helpAndSupport.formData.email")}
                              value={email}
                              onChange={(e) => handleEmail(e)}
                            />
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="section">
                        <Row>
                          <Col>
                            <Form.Label>
                              {t("helpAndSupport.formData.country")}
                              <sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              // disabled={causeName === null ? true : false}
                              className="dropdown-btn"
                              as="select"
                              onChange={(e) => handleCountry(e)}
                              value={countryID}
                              required
                            >
                              <option value="" disabled>
                                {t(
                                  "helpAndSupport.formData.countryPlaceholder"
                                )}
                              </option>
                              {countriesList.data.map((countryData, index) => {
                                return (
                                  <option
                                    value={countryData.country_external_id}
                                    key={countryData.country_external_id}
                                  >
                                    {countryData.country_name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="section">
                        <Row>
                          <Col>
                            <Form.Label>
                              {t("helpAndSupport.formData.txtArea")}
                              <sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              onChange={(e) => handleQuery(e)}
                              placeholder={t(
                                "helpAndSupport.formData.txtAreaPlaceholder"
                              )}
                              rows={6}
                              value={query}
                              required
                            />
                          </Col>
                        </Row>
                      </Form.Group>

                      {isLoading ? (
                        <button
                          className="btn btn-secondary contact-btn"
                          type="submit"
                          disabled
                        >
                          {t("helpAndSupport.formData.send")}{" "}
                          <Spinner animation="border" className="spinner" />
                        </button>
                      ) : (
                        <button
                          className="btn btn-secondary contact-btn"
                          type="submit"
                        >
                          {t("helpAndSupport.formData.send")}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <LeanmoreFooter />
      </div>
      {showToastMsg ? (
        <ToastMessage
          image={greenTickMark}
          message={t("helpAndSupport.formData.thankYouMsg")}
        />
      ) : null}
    </>
  );
};
export default HelpAndSupport;
