import { ALLOWED_COUNTRY_LIST } from "../../constants/action-types";

const initState = [];

const allowedContriesListReducer = (state = initState, action) => {
  if (action.type === ALLOWED_COUNTRY_LIST) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default allowedContriesListReducer;
