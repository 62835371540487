import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import heic2any from "heic2any";
import { useTranslation } from "react-i18next";

//component import
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import ToastMessage from "../ToastMessage";
import {
  Card,
  Container,
  Image,
  Form,
  Button,
  Row,
  Col,
  Spinner,
  // Dropdown,
  // DropdownButton,
} from "react-bootstrap";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Loader from "../Loader";
import { Link } from "react-router-dom";

// action/api import
import { updateUserDetailsByUserId } from "../../api/UpdateUserProfileAPI";
import {
  getSignedUrlForProfilePic,
  uploadProfilePicToS3,
} from "../../api/signedUrlForProfilePic";
import { getUserDetailsByUserId } from "../../actions/userProfile_action";

//css imports
import "../../assets/scss/Contents/sharingOnMedia.scss";
import "../../assets/scss/UserProfile/profileEdit.scss";

//images import
// import arrowLeft from "../../assets/svg/arrow-left.svg";
// import avatar from "../../assets/images/user.png";
import avatar from "../../assets/images/avatar.png";

import greenTickMark from "../../assets/svg/greenTickMark.svg";
import errorButton from "../../assets/svg/errorButton.svg";
import Preferences from "./Preferences";
import DeleteAccount from "./DeleteAccount";
import ChangePassword from "./ChangePassword";
// import calenderImg from "../../assets/svg/calenderIcon.svg";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

const SettingPrivacy = () => {
  const googleUserData = useSelector((state) => state.googleUserData);
  const FbUserData = useSelector((state) => state.FbUserData);
  const googleResponse = useSelector((state) => state.googleResponse.data);
  const FBResponse = useSelector((state) => state.FbResponseData.data);
  const nexmoVerificationResponse = useSelector(
    (state) => state.nexmoVerificationResponse
  );
  // console.log("profiledetails", profiledetails);
  const charityData = useSelector((state) => state.charityNames.data);
  const countriesList = useSelector((state) => state.countriesList);
  const userDetails = useSelector((state) => state.userDetails);
  const userProfile = useSelector((state) => state.userProfile);
  const resetPassword = useSelector((state) => state.resetPassword);

  const [photo, setPhoto] = useState();
  const [FullName, setFullName] = useState("");
  const [UserName, setUserName] = useState("");
  const [Email, setEmail] = useState("");
  // const [Dob, setDob] = useState("");
  // const [CountryName, setCountryName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [birthDate, setBirthDate] = useState(null);

  const [countryExternalId, setCountryExternalId] = useState("");
  // const [country, setCountry] = useState("");
  const [Phone, setPhone] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [contentType, setContentType] = useState("image/jpeg");
  const [signedUrlResponse, setSignedUrlResponse] = useState();
  // const [States, setStates] = useState("");
  const [userState, setUserState] = useState("");
  const [charityExternalID, setCharityExternalID] = useState(null);

  const [gender, setGender] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [validateAge, setValidateAge] = useState(false);
  const [Message, setMessage] = useState("");
  const [redirectProfile, setredirectProfile] = useState(false);
  const [showToastMsg, setShowToastMsg] = useState(false);
  const [error, setError] = useState(false);
  const [changingProfilePicture, setChangingProfilePicture] = useState(false);

  const [countryName, setCountryName] = useState("");
  const [charityName, setCharityName] = useState("");
  const [charityCountryName, setCharityCountryName] = useState("");
  const [phnValidationMsg, setPhnValidationMsg] = useState("");

  const [showEdit, setShowEdit] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photoSelected, setPhotoSelected] = useState(false);

  const [t, i18n] = useTranslation("translate");

  const language = localStorage.getItem("lang");


  const uploadedImageRef = useRef();
  const canvasRef = useRef();
  const fileInputRef = useRef();
  const editProfileRef = createRef();
  const changePwdRef = createRef();
  const preferenceRef = createRef();
  const deleteAccRef = createRef();

  const dispatch = useDispatch();

  const navArrow = (
    <svg
      className="navArrow"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33203 17.3333L14.6654 12L9.33203 6.66666"
        stroke="#575757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  //function to set data onload
  useEffect(() => {
    let newDOB = new Date(userDetails.user_date_of_birth);
    // var Dobarray = dob.slice(0, 10);
    setProfilePicture(
      `https://gpi-public-data.s3-eu-west-1.amazonaws.com/images/profiles/${userDetails.user_external_id}.jpg`
    );
    setFullName(userDetails.user_full_name);
    setUserName(userDetails.username);
    setEmail(userDetails.user_email_address);
    setUserState(userDetails.user_state);
    setPhone(userDetails.user_mobile_number);
    setGender(userDetails.user_gender);
    setCountryName(userDetails.country_name);
    setCountryExternalId(userDetails.country_external_id);
    setCharityExternalID(userDetails.cause_external_id);
    // setDob(Dobarray);
    setStartDate(newDOB);
    setCharityCountryName(userDetails.cause_name);
  }, [userDetails]);

  //function to select profile pic .
  const fileChangedHandler = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    console.log(file);
    // console.log(file);
    if (!file) return;
    // alert(file.type);
    if (file.type === "" || file.type === "image/heic") {
      let convertedBlob = await heicToJpg(file);
      let src = await getBase64(convertedBlob).then((base64) => {
        return base64;
      });
      // console.log(src);
      await setPhoto(src);
      setTimeout(async () => {
        let resizedImage = await resizeUploadedImage();
        await setProfilePicture(resizedImage);
        // alert(profilePicture);
        let response = await getSignedUrlForProfilePic(contentType);
        setSignedUrlResponse(response);
        setChangingProfilePicture(true);
        setIsLoading(false);
        setPhotoSelected(true);
        setTimeout(() => {
          setPhotoSelected(false);
        }, 3000);
      }, 2000);
    } else if (file.type == "image/jpeg" || file.type == "image/png") {
      let src = await getBase64(file).then((base64) => {
        return base64;
      });
      await setPhoto(src);
      setTimeout(async () => {
        let resizedImage = await resizeUploadedImage();
        await setProfilePicture(resizedImage);
        let response = await getSignedUrlForProfilePic(contentType);
        setSignedUrlResponse(response);
        setChangingProfilePicture(true);
        setIsLoading(false);
        setPhotoSelected(true);
        setTimeout(() => {
          setPhotoSelected(false);
        }, 5000);
      }, 2000);
    } else {
      setProfilePicture("");
      setMessage("Please select image");
    }
  };

  const heicToJpg = (file) => {
    // fetching the heic image
    return heic2any({
      blob: file,
      toType: "image/jpeg",
    })
      .then((conversionResult) => {
        // conversionResult is an array of BLOBs that are PNG formatted images
        return conversionResult;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //function to send profile pic in base 64 format.
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const resizeUploadedImage = async () => {
    let userImage = uploadedImageRef.current;
    let canvas = canvasRef.current;

    canvas.width = userImage.width;
    canvas.height = userImage.height;

    let [maxHeight, maxWidth, ratio] = [200, 200, 0];
    let [width, height] = [userImage.width, userImage.height];

    if (width > maxWidth) {
      ratio = maxWidth / width;
      userImage.style.width = maxWidth;
      userImage.style.height *= ratio;
      height *= ratio;
      width *= ratio;
    }

    if (height > maxHeight) {
      ratio = maxHeight / height;
      userImage.style.height = maxHeight;
      userImage.style.width *= ratio;
      height *= ratio;
      width *= ratio;
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(userImage, 0, 0, canvas.width, canvas.height);

    let dataURL = await canvas.toDataURL("image/jpeg");
    return dataURL;
  };

  //function to validate age should be greater than 13 years old.
  // function underAgeValidate(birthday) {
  //   setDob(birthday);
  //   // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
  //   var optimizedBirthday = birthday.replace(/-/g, "/");

  //   //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
  //   var myBirthday = new Date(optimizedBirthday);

  //   // set current day on 01:00:00 hours GMT+0100 (CET)
  //   var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";

  //   // calculate age comparing current date and borthday
  //   var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

  //   if (myAge < 13) {
  //     setValidationMessage("You need to be atleast 13 to sign up");
  //     setValidateAge(true);
  //   } else {
  //     setValidateAge(false);
  //   }
  // }

  function underAgeValidate(birthday) {
    // setDob(birthday);
    setStartDate(birthday); //user DOB

    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    // var optimizedBirthday = birthday.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(birthday); // user DOB

    var DOB =
      myBirthday.getFullYear() +
      "-" +
      (myBirthday.getMonth() + 1) +
      "-" +
      myBirthday.getDate(); //yyyy-mm-dd format
    // console.log(DOB);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
    // console.log(currentDate)

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);
    // console.log(myAge);

    if (myAge < 13) {
      // setValidationMessage("You need to be atleast 13 to sign up");
      setValidateAge(true);
    } else {
      setValidateAge(false);
    }
  }

  //function to dispatch profile obj based on externalId
  const handleSubmit = async (
    e,
    fullName,
    Gender,
    Dob,
    CountryExternalId,
    State,
    Email,
    Phone,
    Charity
  ) => {
    e.preventDefault();
    // window.location.reload(false);
    // var date = new Date(Dob);
    // var year = date.getFullYear();
    // var month = date.getMonth() + 1;
    // var dt = date.getDate();

    // if (dt < 10) {
    //   dt = "0" + dt;
    // }
    // if (month < 10) {
    //   month = "0" + month;
    // }

    // var birthday = `${year}-${month}-${dt}`;

    // setStartDate(birthday);
    let errorOnUpload = false;

    const obj = {
      name: fullName,
      gender: Gender,
      dob: Dob,
      states: State,
      email: Email,
      phone: Phone,
      charity: Charity,
      countryExternalId: CountryExternalId,
    };
    // console.log(obj)
    // setProfilePicture(profilePicture);
    if (changingProfilePicture) {
      const buffer = await Buffer.from(
        profilePicture.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      let response = await uploadProfilePicToS3(
        signedUrlResponse.data.response.data.signedUrl,
        signedUrlResponse.data.response.data.contentType,
        buffer
      );
      // if (response) {
      //   if (response.status !== 200) {
      //     setError(true);
      //     errorOnUpload = true;
      //   }
      // }
      if (response) {
        if (response.status === 200) {
          setShowToastMsg(true);
          setTimeout(() => {
            setredirectProfile(true);
            setShowToastMsg(false);
            setError(false);
            window.location.reload(true);
          }, 3000);
        }
      } else {
        setError(true);
      }
    }

    let updateUserDetails = await updateUserDetailsByUserId(obj);
    if (updateUserDetails.statusCode === 200) {
      console.log({ errorOnUpload });
      if (!errorOnUpload) {
        setShowToastMsg(true);
      }
      dispatch(getUserDetailsByUserId());
      setTimeout(() => {
        setShowToastMsg(false);
        setError(false);
        setredirectProfile(true);
      }, 3000);
    } else if (updateUserDetails.statusCode === 409) {
      setPhnValidationMsg(
        "Phone number already exists,try with different phone number"
      );
    } else {
      setError(true);
    }
  };

  //function to render profilepic based on login type
  // const image = () => {
  //   if (googleResponse) {
  //     if (googleUserData) {
  //       return (
  //         <Image
  //           className="profile-edit-profilepic"
  //           src={profilePicture ? profilePicture : googleUserData.OJ}
  //           roundedCircle
  //         ></Image>
  //       );
  //     }
  //   }

  //   if (FBResponse) {
  //     if (FbUserData) {
  //       return (
  //         <Image
  //           className="profile-edit-profilepic"
  //           src={profilePicture ? profilePicture : FbUserData.picture}
  //           roundedCircle
  //         ></Image>
  //       );
  //     }
  //   }

  //   if (nexmoVerificationResponse) {
  //     if (nexmoVerificationResponse) {
  //       return (
  //         <Image
  //           className="profile-edit-profilepic"
  //           src={profilePicture ? profilePicture : avatar}
  //           roundedCircle
  //         ></Image>
  //       );
  //     }
  //   }
  // };

  if (!FBResponse && !googleResponse && !nexmoVerificationResponse)
    return <Loader />;

  // const handleCountries = (e) => {
  //   const country = e;
  //   const name = country.split(",");
  //   setCountryName(name[0]);
  //   setCountryExternalId(name[1]);
  //   // console.log(name[1]);
  //   setCharityName("");
  // };

  // const handleCharity = (e) => {
  //   const charity = e;
  //   const name = charity.split(",");
  //   // console.log(`${name}${name[0]}`)
  //   setCharityName(name[0]);
  //   setCharityExternalID(name[1]);
  //   setCharityCountryName(name[2]);
  // };

  const handleNavigation = (str) => {
    str === "editProfile"
      ? editProfileRef.current.classList.add("nav-active")
      : editProfileRef.current.classList.remove("nav-active");
    str === "changePassword"
      ? changePwdRef.current.classList.add("nav-active")
      : changePwdRef.current.classList.remove("nav-active");
    str === "preferences"
      ? preferenceRef.current.classList.add("nav-active")
      : preferenceRef.current.classList.remove("nav-active");
    // str === "deleteAccount"
    //   ? deleteAccRef.current.classList.add("nav-active")
    //   : deleteAccRef.current.classList.remove("nav-active");
  };

  const handleNav = (str) => {
    handleNavigation(str);
    switch (str) {
      case "editProfile":
        setShowEdit(true);
        setShowChangePassword(false);
        setShowDeleteAccount(false);
        setShowPreferences(false);
        break;
      case "changePassword":
        setShowEdit(false);
        setShowChangePassword(true);
        setShowDeleteAccount(false);
        setShowPreferences(false);
        break;
      case "deleteAccount":
        setShowEdit(false);
        setShowChangePassword(false);
        setShowDeleteAccount(true);
        setShowPreferences(false);
        break;
      case "preferences":
        setShowEdit(false);
        setShowChangePassword(false);
        setShowDeleteAccount(false);
        setShowPreferences(true);
        break;
      default:
        setShowEdit(false);
        setShowChangePassword(false);
        setShowDeleteAccount(false);
        setShowPreferences(false);
    }
  };

  return (
    <div className="profile-edit-wrapper">
      <ContentNavBar />
      <div style={{ minHeight: "86vh", backgroundColor: "#f2f2f2" }}>
        <Container>
          <Row>
            <Col className="title" md={4} lg={3}>
              <p style={{ textDecoration: "none" }}>
                {t("settingsAndPrivacy.settingsTitle")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={3}>
              <ul className="navigationButton">
                <li>
                  <div
                    className="nav-active"
                    ref={editProfileRef}
                    onClick={() => handleNav("editProfile")}
                  >
                    {t("settingsAndPrivacy.tabNames.tabEditProfile")} {navArrow}
                  </div>
                </li>
                <li>
                  <div
                    ref={changePwdRef}
                    onClick={() => handleNav("changePassword")}
                  >
                    {t("settingsAndPrivacy.tabNames.tabChangePassword")} {navArrow}
                  </div>
                </li>
                <li>
                  <div
                    ref={preferenceRef}
                    onClick={() => handleNav("preferences")}
                  >
                    {t("settingsAndPrivacy.tabNames.tabPreferences")} {navArrow}
                  </div>
                </li>
                {/* <li><div ref={deleteAccRef} onClick={() => handleNav("deleteAccount")}>Deactivate Account {navArrow}</div></li> */}
              </ul>
            </Col>
            <Col
              className="content"
              sm={12}
              md={8}
              lg={7}
              style={{ marginBottom: "1rem" }}
            >
              {showEdit && (
                <Card className="profile-edit-card">
                  <Card.Header className="heading">
                    {t("settingsAndPrivacy.userDetailForm.editProfileTitle")}
                  </Card.Header>
                  <Card.Body>
                    <div style={{ textAlign: "center" }}>
                      {isLoading ? (
                        <span
                          style={{
                            position: "absolute",
                            top: "150px",
                            left: "50%",
                          }}
                        >
                          <Spinner animation="border" variant="dark" />
                        </span>
                      ) : (
                          ""
                        )}
                      <Image
                        onError={(ev) => (ev.target.src = `${avatar}`)}
                        className="profilesetup-profilepic"
                        src={profilePicture}
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        roundedCircle
                      />
                      <img
                        src={photo}
                        ref={uploadedImageRef}
                        style={{ display: "none" }}
                      />
                      <canvas
                        id="mycanvas"
                        ref={canvasRef}
                        style={{ display: "none" }}
                      />
                      <div>
                        <label className="btn">
                          <p
                            className="profile-edit-text"
                            style={{ display: "block" }}
                            onClick={() => fileInputRef.current.click()}
                          >
                            {t("settingsAndPrivacy.userDetailForm.changeProfilePic")}
                          </p>
                        </label>
                        <input
                          // id="files"
                          // style={{ display: "none" }}
                          ref={fileInputRef}
                          style={{
                            opacity: "0",
                            position: "relative",
                            width: "100%",
                          }}
                          type="file"
                          accept="image/*"
                          onChange={fileChangedHandler}
                        />
                      </div>

                      {/* <div>
                        <button
                          className="profile-edit-text"
                          style={{ top: "-20px" }}
                        >
                          <a href="javascript: void(0)">
                            Change Profile Picture
                          </a>
                        </button>
                        <input
                          type="file"
                          id="upload_input"
                          accept="image/*"
                          onChange={fileChangedHandler}
                          name="upload"
                          style={{
                            fontSize: "50px",
                            width: "120px",
                            opacity: "0",
                            filter: "alpha(opacity=0)",
                            position: "relative",
                            top: "-40px",
                            left: "-20px",
                          }}
                        />
                      </div> */}
                    </div>
                    <div className="col-md-9 mx-auto profile-edit-form-wrapper">
                      <Form
                        className="profile-edit-form"
                        onSubmit={(e) =>
                          handleSubmit(
                            e,
                            FullName,
                            gender,
                            startDate,
                            countryExternalId,
                            userState,
                            Email,
                            Phone,
                            charityExternalID
                          )
                        }
                      >
                        <Form.Group>
                          <Form.Label className="profile-edit-label">
                            {t("settingsAndPrivacy.userDetailForm.fullName")} *

                          </Form.Label>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Full name"
                            onChange={(e) => setFullName(e.target.value)}
                            value={FullName || ""}
                            maxLength="30"
                            required
                          />
                          <span style={{ fontSize: "10px" }}>
                            {t("settingsAndPrivacy.userDetailForm.note")}

                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="profile-edit-label">
                            {t("settingsAndPrivacy.userDetailForm.userName")}
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="User name"
                            onChange={(e) => setUserName(e.target.value)}
                            value={UserName}
                            readOnly
                          />
                        </Form.Group>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            className="col-6"
                          // controlId="formGridEmail"
                          >
                            <Form.Label className="profile-edit-label">
                              {t("settingsAndPrivacy.userDetailForm.defineMyself.defineMyselfLabel")} *
                            </Form.Label>
                            <Form.Control
                              as="select"
                              size="lg"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                              required
                            >
                              <option value="" defaultValue disabled hidden>
                                Select
                              </option>
                              <option value="male">Male</option>
                              <option value="Female">Female</option>
                              <option value="me">Me</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} className="col-6">
                            <Form.Label className="profile-edit-label">
                              {t("settingsAndPrivacy.userDetailForm.dob")} *

                            </Form.Label>
                            {/* <Form.Control
                            size="lg"
                            type="date"
                            placeholder="dd/mm/yy"
                            onChange={(e) => underAgeValidate(e.target.value)}
                            value={Dob}
                            required
                          />
                          {validateAge ? (
                            <span className="error-message">
                              {validationMessage}
                            </span>
                          ) : (
                              ""
                            )} */}
                            <div
                              className={`form-control-lg datePicker ${validateAge && "error-input"
                                }`}
                            >
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={(date) => underAgeValidate(date)}
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="yyyy"
                                peekNextMonth
                                showMonthDropdown
                                maxDate={new Date()}
                                minDate={new Date("1890-04-11T10:20:30Z")}
                                showYearDropdown
                                dropdownMode="select"
                                required
                                placeholderText="dd/mm/yy"
                              />
                            </div>
                            {validateAge ? (
                              <p
                                className={`profilesetup-message ${validateAge && "error-message"
                                  }`}
                              >
                                {t("settingsAndPrivacy.userDetailForm.noteAge")}

                              </p>
                            ) : (
                                ""
                              )}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label className="profile-edit-label">
                              {t("settingsAndPrivacy.userDetailForm.country")} *
                            </Form.Label>
                            <Form.Control
                              size="lg"
                              type="text"
                              placeholder="State"
                              value={countryName}
                              required
                              readOnly
                            />
                            {/* <Form.Control
                            as="select"
                            size="lg"
                            value={countryExternalId} onChange={e => setCountryExternalId(e.target.value)}
                            required>
                            <option defaultValue style={{ display: "none" }}>Select Country</option>
                            {
                              countriesList.data.map((countryData, index) => (
                                <option value={`${countryData.country_external_id}`} key={countryData.country_external_id}>{countryData.country_name}</option>
                              ))

                            }
                          </Form.Control> */}
                            {/* <DropdownButton
                          className="dropdown-button"
                          onSelect={handleCountries}
                          title={
                            <span className="dropdown-span">
                              {countryName ? countryName : "Select Country"}
                            </span>
                          }
                        >
                          <div className="dropdown-options">
                            {countriesList.data.map((country, index) => (
                              <Dropdown.Item
                                eventKey={`${country.country_name},${country.country_external_id}`}
                                key={country.country_external_id}
                              >
                                {country.country_name}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </DropdownButton> */}
                          </Form.Group>
                        </Form.Row>
                        {countryName == "United States" ? (
                          <Form.Row>
                            {" "}
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label className="profile-edit-label">
                                {t("settingsAndPrivacy.userDetailForm.state")} *
                              </Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="State"
                                value={userState}
                                onChange={(e) => setUserState(e.target.value)}
                                required
                                readOnly
                              />
                            </Form.Group>{" "}
                          </Form.Row>
                        ) : null}
                        <Form.Group>
                          <Form.Label className="profile-edit-label">
                            {t("settingsAndPrivacy.userDetailForm.emaiAddress")} *
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                            value={Email}
                            required
                            readOnly
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="profile-edit-label">
                            {t("settingsAndPrivacy.userDetailForm.phone")}
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            type="tel"
                            placeholder="phone number"
                            onChange={(e) => setPhone(e.target.value)}
                            value={Phone || ""}
                            maxLength="12"
                          />
                          <span style={{ color: "red" }}>
                            {phnValidationMsg}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="profile-edit-label">
                            Set default Charity
                          </Form.Label>
                          <Form.Control
                            as="select"
                            size="lg"
                            value={charityExternalID || ""}
                            onChange={(e) =>
                              setCharityExternalID(e.target.value)
                            }
                          >
                            <option defaultValue style={{ display: "none" }}>
                              {t("settingsAndPrivacy.userDetailForm.selectCharity")}
                            </option>
                            {charityData.map((cause, index) => {
                              if (
                                userDetails.country_name === cause.country_name
                              )
                                return (
                                  <option
                                    value={cause.cause_external_id}
                                    key={cause.cause_external_id}
                                  >
                                    {cause.cause_name}
                                  </option>
                                );
                            })}
                          </Form.Control>
                          {/* <DropdownButton
                        className="dropdown-button"
                        onSelect={handleCharity}
                        title={
                          <span className="dropdown-span">
                            {charityCountryName
                              ? charityCountryName
                              : "Select Charity"}
                          </span>
                        }
                      >
                        {charityData.map((cause) => {
                          return countryName === cause.country_name ? (
                            <Dropdown.Item
                              eventKey={`${cause.country_name},${cause.cause_external_id},${cause.cause_name}`}
                              key={cause.cause_external_id}
                            >
                              {cause.cause_name}
                            </Dropdown.Item>
                          ) : (
                              ""
                            );
                        })}
                      </DropdownButton> */}
                        </Form.Group>
                        {/* {disableButton ? <Button className="profile-edit-savebtn" type="submit" disabled>SAVE</Button> : <Button className="profile-edit-savebtn" type="submit">SAVE</Button>} */}
                        <Button
                          className="profile-edit-savebtn mt-4"
                          type="submit"
                        >
                          {t("settingsAndPrivacy.userDetailForm.save")}

                        </Button>
                      </Form>
                    </div>
                  </Card.Body>
                </Card>
              )}
              {showChangePassword && <ChangePassword />}
              {showPreferences && <Preferences />}
              {/* {showDeleteAccount && <DeleteAccount />} */}
            </Col>
          </Row>
        </Container>
      </div>
      {error ? (
        <ToastMessage
          image={errorButton}
          message={t("settingsAndPrivacy.userDetailForm.errorUpdate")}
        />
      ) : null}
      {showToastMsg ? (
        <ToastMessage
          image={greenTickMark}
          message={t("settingsAndPrivacy.userDetailForm.succuessUpdate")}
        />
      ) : null}
      {photoSelected ? (
        <ToastMessage
          image={greenTickMark}
          message="Photo selected. Don't forget to save the changes"
        />
      ) : null}

      {redirectProfile ? (
        <Redirect
          to={{
            pathname: "/profile",
          }}
        />
      ) : (
          ""
        )}
      <LeanmoreFooter />
    </div>
  );
};
export default SettingPrivacy;
