import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSearchPosts } from "../../actions/customHooks/useSearchData";
import {
    Container,
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContentSlider from "../Contents/Activity/ContentSlider";
import SearchTabs from "./SearchTabs";
import ContentNavBar from "../ContentNavbar/ContentNavbar";
import Linkify from "linkifyjs/react";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTypography-body2": {
            fontFamily: "DM Sans",
            fontSize: "1rem",
            color: "#3B3B3B",
        },
    },
    media: {
        width: "100%",
    },
    wrapper: {
        backgroundColor: "#f1f1f1",
        minHeight: "100vh",
        padding: "20px 0",
        marginTop: "20px",
    },
}));

const SearchPost = () => {
    const classes = useStyles();
    const history = useHistory();

    const searchInfo = useSelector((state) => state.searchInfo);
    const { searchItem } = searchInfo;

    const { loading, error, responseData, hasMore } = useSearchPosts(searchItem);
    const [showFullDesc, setShowFullDesc] = useState({ id: "" });

    let len = 90;

    const showContent = (e, contentExternalId) => {
        e.stopPropagation();
        history.push(`/posts/${contentExternalId}`);
        // window.location.reload();
    };

    const renderData =
        responseData.statusCode === 200 ? (
            responseData.data.map((item, index) => (
                <div key={index}>
                    <Box mt={3}>
                        <Container maxWidth="sm">
                            <Grid container>
                                <Grid item className={classes.media}>
                                    <Card className="Card-wrapper"
                                        onClick={(e) => showContent(e, item.content_external_id)}
                                    >
                                        <CardContent>
                                            {item.content_description ? (
                                                <>
                                                    {" "}
                                                    {item.content_description.length <= len && (
                                                        <div>
                                                            <Linkify tagName="span">
                                                                {item.content_description}
                                                            </Linkify>
                                                        </div>
                                                    )}
                                                    {item.content_description.length > len &&
                                                        showFullDesc.id !== item.content_external_id && (
                                                            <div variant="body2">
                                                                <Linkify tagName="span">
                                                                    {item.content_description.substring(0, len) +
                                                                        "..."}
                                                                </Linkify>

                                                                {/* {item.content_description.substring(0, len) + "..."} */}
                                                                <span
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#2077B1",
                                                                    }}
                                                                    onClick={() =>
                                                                        setShowFullDesc({
                                                                            ...showFullDesc,
                                                                            id: item.content_external_id,
                                                                        })
                                                                    }
                                                                >
                                                                    {" "}
                                  See more
                                </span>
                                                            </div>
                                                        )}
                                                    {item.content_description.length > len &&
                                                        showFullDesc.id === item.content_external_id && (
                                                            <div>
                                                                <Linkify tagName="span">
                                                                    {item.content_description}
                                                                </Linkify>
                                                                {/* {item.content_description} */}
                                                                <span
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#2077B1",
                                                                    }}
                                                                    onClick={() =>
                                                                        setShowFullDesc({ ...showFullDesc, id: "" })
                                                                    }
                                                                >
                                                                    {" "}
                                  See less
                                </span>
                                                            </div>
                                                        )}
                                                </>
                                            ) : null}
                                        </CardContent>
                                        <div
                                            className="image-slider-container"
                                        >
                                            {/*----- Multiple content slider -----*/}
                                            <ContentSlider item={item} />
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
            ))
        ) : responseData.statusCode === 204 ? (
            <Box textAlign="center" mt={3}>
                <Typography variant="subtitle1">No post found!</Typography>
            </Box>
        ) : null;

    return (
        <div>
            <ContentNavBar />
            <SearchTabs activeTabValue="0" />
            <div className={classes.wrapper}>
                {loading ? (
                    <Box textAlign="center" my={3}>
                        <CircularProgress style={{ color: "#2077b1" }} />
                    </Box>
                ) : (
                        renderData
                    )}
            </div>
        </div>
    );
};

export default SearchPost;
