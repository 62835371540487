import axiosInstance from 'axios'
import { BASEURL } from '../config'


export const userGoogleLogin = (token) => {
    return axiosInstance({
        url: `${BASEURL}/v1/login/new`,
        method: "POST",
        data: {
            googleToken: token,
            login_type: "da5aef7c-7e43-4151-8da5-56bd9e86e455",
        },
        withCredentials: true,
    })
        .then((response => response.data)
            // console.log("response", response)
        )
        .catch((err) => {
            // throw new Error(err);
            return err.response.data
        });
};

export const userFBLogin = (input_token) => {
    return axiosInstance({
        url: `${BASEURL}/v1/login/new`,
        method: "POST",
        data: {
            facebookToken: input_token,
            login_type: "4427d530-6772-4ce2-be7b-ef0c65e95414",
        },
        withCredentials: true,
    })
        .then((response => response.data)
            // console.log("response", response)
        )
        .catch((err) => {
            // throw new Error(err);
            return err.response.data
        });
};