import axios from 'axios'
import { BASEURL } from '../config'

export const setPreferences = (defaultAmount, showBalance) => {
    return axios({
        method: 'POST',
        url: `${BASEURL}/v1/settings`,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': externalId
        },
        withCredentials: true,
        data: { 
            "settings_pooling_amount":defaultAmount, 
             "settings_wallet_icon":showBalance 
            } 
    })
        .then(response => {
            if(response){
                return response.data
            }
        })  .catch(err =>  console.error(err))
}


export const getPreferences = () => {
    return axios({
        method: 'GET',
        url: `${BASEURL}/v1/settings`,
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': externalId
        },
        withCredentials: true,
    })
    .then(response =>   {
    if(response)
    return response.data
    })
    .catch(err =>  console.error(err))
}