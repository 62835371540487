import axiosInstance from "../axios/axios";
import axios from "axios";
import {
  All_COUNTRY_LIST,
  ALLOWED_COUNTRY_LIST,
} from "../constants/action-types";
import { BASEURL } from "../config";

export const storeCountriesList = () => (dispatch) => {
  axiosInstance
    .get(`/v1/getcountry`)
    .then((allCountryList) => {
      dispatch({
        type: All_COUNTRY_LIST,
        payload: allCountryList.data.response,
      });
    })
    .catch((err) => console.error(err));
};

export const storeAllowedCountriesList = () => (dispatch) => {
  axios({
    url: `${BASEURL}/v1/countries/allowedCountries `,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) =>
      dispatch({
        type: ALLOWED_COUNTRY_LIST,
        payload: response.data.response.data,
      })
    )
    .catch((error) => console.error(error));
};
