import { CREATE_POST_POPUP } from "../../constants/action-types";

const initState = {
  showPopup: false,
};

const createPostReducer = (state = initState, action) => {
  if (action.type === CREATE_POST_POPUP) {
    return {
      ...state,
      showPopup: action.payload,
    };
  }
  return state;
};
export default createPostReducer;
