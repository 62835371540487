import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Slide } from '@material-ui/core';


const achieveedMark = (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.494 7.00001C12.494 10.116 9.96794 12.642 6.85193 12.642C3.73593 12.642 1.20991 10.116 1.20991 7.00001C1.20991 3.88401 3.73593 1.35799 6.85193 1.35799C9.96794 1.35799 12.494 3.88401 12.494 7.00001Z" fill="#17B774" stroke="white" stroke-width="1.49795" />
    <path d="M4.25 6.6386L6.16838 8.79518L9.44129 5.2019" stroke="white" stroke-width="1.04857" strokeLinecap="round" strokeLinejoin="round" />
</svg>
)

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AchievedBadges = ({ achievedBadgesList }) => {
    // const [open, setOpen] = useState(false);
    const [orderValue, setOrderValue] = useState([]);
    const [viewShowMore, setViewShowMore] = useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };
    const handleShowMore = () => {
        var ordered = [];
        const arr = ["Posted", "Followings", "Pooled", "Followers", "Golden Buzzer"];
        for (var j = 0; j < arr.length; j++) {
            for (var i = 0; i < achievedBadgesList.length; i++) {
                if (arr[j].indexOf(achievedBadgesList[i].badge_category) > -1) {
                    ordered.push(achievedBadgesList[i]);
                }
            }
        }
        setOrderValue(ordered);
        setViewShowMore(false)
    }

    useEffect(() => {
        var ordered = [];
        const arr = ["Posted", "Followings", "Pooled", "Followers", "Golden Buzzer"];
        for (var j = 0; j < arr.length; j++) {
            for (var i = 0; i < achievedBadgesList.length; i++) {
                if (arr[j].indexOf(achievedBadgesList[i].badge_category) > -1) {
                    ordered.push(achievedBadgesList[i]);
                }
            }
        }
        const unique = ordered.filter((elem, index) => ordered.findIndex(obj => obj.badge_category === elem.badge_category) === index)
        setOrderValue(unique);
        { unique.length ? setViewShowMore(true) : setViewShowMore(false) }
    }, [achievedBadgesList])


    return (
        <div>
            {orderValue.map((achievedBadge) => {
                let transactionDuration = achievedBadge.achieved_on;
                let date = new Date(transactionDuration);
                let dateFormat = date.toDateString();
                let finalDate = dateFormat.split(" ");

                var hours = date.getHours(); //UTC give you the hours without the timezone offset.
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? "pm" : "am";
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? "0" + minutes : minutes;
                var strTime = hours + ":" + minutes + " " + ampm;

                var dayMonthYearAchievedEachBadge =
                    finalDate[2] +
                    " " +
                    finalDate[1] +
                    " " +
                    finalDate[3] +
                    "," +
                    " " +
                    strTime;
                return (
                    <Card className="recent-activity-card-badge">
                        <div style={{ display: "flex" }}>

                            <div className="achieveImage">
                                <span className="achieveedMark">{achieveedMark}</span>
                                <img
                                    className="upcomingBadges"
                                    src={achievedBadge.badge_image}
                                    alt="badge-icon"
                                />
                            </div>


                            {/* <img src={achievedBadge.badge_image} alt="badge-icon" /> */}
                            <div style={{ display: "inline-block" }}>
                                <p className="recent-activity-points-earned">
                                    {achievedBadge.achievement_name}
                                </p>
                                <p className="recent-activity-points-earned-description">
                                    {achievedBadge.badge_message} on -{" "}
                                    {dayMonthYearAchievedEachBadge}

                                </p>
                            </div>
                        </div>
                    </Card>
                )
            })}
            { viewShowMore ? <span style={{ fontWeight: "500", cursor: "pointer" }} onClick={handleShowMore}> Show more</span> : "you have no more achieved badges"}



        </div>
    )
}

export default AchievedBadges
