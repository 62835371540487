import { SEARCH_INFO } from "../../constants/action-types";

const initState = {};

export const searchInfoReducer = (state = initState, action) => {
  if (action.type === SEARCH_INFO) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};
