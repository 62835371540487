import axios from "axios";
import { BASEURL } from ".././config";

export const CreateNewUser = (
  userName,
  Password,
  obj,
  google,
  nexmoPhoneNumber,
  facebook,
  // apple
) => {
  if (google) {
    // console.log('google')
    return axios({
      url: `${BASEURL}/v1/users`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        user_email_address: obj.email,
        user_full_name: obj.fullname,
        user_country: obj.countryName,
        user_date_of_birth: obj.date_of_birth,
        login_type: "da5aef7c-7e43-4151-8da5-56bd9e86e455",
        country_id: obj.countryExternalId,
        username: userName,
        user_gender: obj.gender,
        user_state: obj.State,
        user_password: Password,
        // user_profile_pic: obj.SelectedProfile
      },
      // withCredentials: true,
    })
      .then((response) => response.data.response)
      .catch((err) => err);
  } else if (facebook) {
    // console.log('facebook')
    return axios({
      url: `${BASEURL}/v1/users`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        user_email_address: obj.email,
        user_full_name: obj.fullname,
        user_country: obj.countryName,
        user_date_of_birth: obj.date_of_birth,
        login_type: "4427d530-6772-4ce2-be7b-ef0c65e95414",
        user_facebook_id: facebook.data.user_facebook_id,
        country_id: obj.countryExternalId,
        username: userName,
        user_gender: obj.gender,
        user_state: obj.State,
        user_password: Password,
        user_profile_pic: obj.SelectedProfile,
      },
      // withCredentials: true,
    })
      .then((response) => response.data.response)
      .catch((err) => err);
  }
  // else if (apple) {
  //   // console.log('apple')
  //   return axios({
  //     url: `${BASEURL}/v1/users`,
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     data: {
  //       user_email_address: obj.email,
  //       user_full_name: obj.fullname,
  //       user_country: obj.countryName,
  //       user_date_of_birth: obj.date_of_birth,
  //       login_type: "c6eeec61-d06b-45e7-bfe4-ff12d4876b9e",
  //       apple_id: apple.authorization.code,
  //       country_id: obj.countryExternalId,
  //       username: userName,
  //       user_gender: obj.gender,
  //       user_state: obj.State,
  //       user_password: Password,
  //       user_profile_pic: obj.SelectedProfile,
  //     },
  //     // withCredentials: true,
  //   })
  //     .then((response) => response.data.response)
  //     .catch((err) => err);
  // }
  else if (nexmoPhoneNumber) {
    // console.log('nexmo')
    return axios({
      url: `${BASEURL}/v1/users`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        user_email_address: obj.email,
        user_full_name: obj.fullname,
        user_country: obj.countryName,
        user_date_of_birth: obj.date_of_birth,
        login_type: "278dddb0-ca63-472e-88fb-5f175ba81659",
        country_id: obj.countryExternalId,
        username: userName,
        user_gender: obj.gender,
        user_state: obj.State,
        user_password: Password,
        user_mobile_number: nexmoPhoneNumber,
        user_profile_pic: obj.SelectedProfile,
      },
      // withCredentials: true,
    })
      .then((response) => response.data.response)
      .catch((err) => err);
  }
};
