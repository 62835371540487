import { NEXMO_SIGN_IN } from '../../constants/action-types';

const initState = {}

const NexmoResponseReducer = (state = initState, action) => {
    if (action.type === NEXMO_SIGN_IN) {
        return {
            ...state,
            ...action.payload.data
        }
    }
    return state
}

export default NexmoResponseReducer
