import React, { useState, useRef, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"

const IdleTimerContainer = (props) => {
    const [isLogged, setIsLogged] = useState(false);
    // const sessionTimeoutRef = useRef(null);
    const history = useHistory();

    const isAuthorized = useSelector(state => state.isUserAuthorized.isAuthorized)

    useEffect(() => {
        if (isAuthorized) {
            setIsLogged(true)
        }
    }, [isAuthorized])

    const idleTimerRef = useRef();
    const onIdle = () => {
        return (
            // console.log('what is going on')
            history.push('/login'),
            window.location.reload(),
            localStorage.clear()
        )

        // sessionTimeoutRef.current = setTimeout(logOut, 500)
    }

    // const logOut = () => {
    //     return (
    //         clearTimeout(sessionTimeoutRef.current),
    //         window.location.reload(),
    //         localStorage.clear()
    //     )
    // }

    return (
        <div>
            {isLogged ? <IdleTimer
                // ref={idleTimerRef}
                timeout={1000 * 60 * 180}
                onIdle={onIdle}>

            </IdleTimer> : ""}

        </div>
    )
}

export default IdleTimerContainer
