import { GET_TOKEN_DETAILS } from '../../constants/action-types'

const initState = {}

const TokenDetailsReducer = (state = initState, action) => {
    if (action.type === GET_TOKEN_DETAILS) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state
}
export default TokenDetailsReducer